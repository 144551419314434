import type {
    AuthorizationEntity,
    GlobalAuthenticationType,
    GroupAuthorizationAPI,
} from '../../types';
import { isNullOrEmpty } from '../../utils/guard';

export type RuntimeAuthorizationAction =
    | {
          type: 'setAuthorization';
          payload: GroupAuthorizationAPI;
      }
    | { type: 'updateConnector'; payload: { connectorId: string } }
    | {
          type: 'updateAccess';
          payload: { authenticationType: GlobalAuthenticationType };
      }
    | { type: 'updateGroups'; payload: { groups: AuthorizationEntity[] } }
    | { type: 'updateUsers'; payload: { users: AuthorizationEntity[] } };

export type DispatchRuntimeAuthorization = React.Dispatch<RuntimeAuthorizationAction>;

export const reducer = (
    authorization: GroupAuthorizationAPI | null,
    { type, payload }: RuntimeAuthorizationAction,
): GroupAuthorizationAPI => {
    if (type === 'setAuthorization') {
        return payload;
    }

    if (authorization === null) {
        throw 'GroupAuthorizationAPI cannot be null.';
    }

    switch (type) {
        case 'updateConnector': {
            return {
                ...authorization,
                globalAuthenticationType:
                    isNullOrEmpty(payload.connectorId) ||
                    isNullOrEmpty(authorization.globalAuthenticationType)
                        ? 'PUBLIC'
                        : authorization.globalAuthenticationType,
                serviceElementId: payload.connectorId,
                groups: [],
                users: [],
            };
        }

        case 'updateAccess': {
            return {
                ...authorization,
                globalAuthenticationType: payload.authenticationType,
            };
        }

        case 'updateGroups': {
            return {
                ...authorization,
                groups: payload.groups,
            };
        }

        case 'updateUsers': {
            return {
                ...authorization,
                users: payload.users,
            };
        }
    }
};

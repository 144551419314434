import FormGroup from '../../generic/FormGroup';
import translations from '../../../translations';
import BasicAuthConfiguration from '../authentication/BasicAuthConfiguration';
import type { NewServiceData } from '../contextProviders/ServicesProvider';
import type { ServiceElementResponseAPI } from '../../../types/service';
import { isNullOrEmpty } from '../../../utils/guard';

interface NewCustomServiceProps {
    newServiceData: NewServiceData;
    setNewServiceDetails: (newServiceData: NewServiceData) => void;
    hasSubmitted: boolean;
}

/**
 * Allow the user to install a Custom Flow service and enter anything into the url field, and optionally provide basic authentication
 * @param {Object} newServiceData = {} - Object containing all the data about the new service being created
 * @param {Function} setNewServiceDetails = {} - Function on ServiceController that allows us to update the newServiceData
 * @param {Boolean} hasSubmitted
 */
const NewCustomService = ({
    newServiceData,
    setNewServiceDetails,
    hasSubmitted,
}: NewCustomServiceProps) => (
    <>
        <h1>New Custom Connector</h1>
        <p>Here you can install a custom connector for Flow</p>
        <FormGroup
            isRequired
            htmlFor="connector-name"
            label="Name"
            validationMessage={translations.SC_name_field_validation_message}
            isValid={!isNullOrEmpty(newServiceData.name)}
            showValidation={hasSubmitted}
        >
            <input
                id="connector-name"
                className="form-control form-control-long"
                onChange={({ target }) =>
                    setNewServiceDetails({
                        ...newServiceData,
                        name: target.value,
                    })
                }
                value={newServiceData?.name ? newServiceData.name : ''}
            />
        </FormGroup>
        <FormGroup
            isRequired
            htmlFor="connector-url"
            label="Url"
            validationMessage={translations.SC_url_field_validation_message}
            isValid={!isNullOrEmpty(newServiceData.url)}
            showValidation={hasSubmitted}
        >
            <input
                id="connector-url"
                className="form-control form-control-long"
                onChange={({ target }) =>
                    setNewServiceDetails({
                        ...newServiceData,
                        url: target.value,
                    })
                }
                value={newServiceData.url ? newServiceData.url : ''}
            />
        </FormGroup>
        <BasicAuthConfiguration
            userName={newServiceData.httpAuthenticationUsername}
            password={newServiceData.httpAuthenticationPassword}
            authenticationType={'BASIC'}
            setNewServiceDetails={setNewServiceDetails}
            serviceData={newServiceData as ServiceElementResponseAPI}
        />
    </>
);

export default NewCustomService;

import { useEffect, useRef, useState } from 'react';
import TabItem from './TabItem';
import { useAuth } from '../AuthProvider';
import type { Tab } from '../../types';
import { useNavigate } from 'react-router-dom';
import { generateRouteUrl } from '../../utils/routing';

interface Props {
    tabs: Tab[];
}

const Tabs = ({ tabs }: Props) => {
    const { tenant } = useAuth();

    const wrapperRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);

    const [hideCloseButtons, setHideCloseButtons] = useState(false);
    const [hideTitles, setHideTitles] = useState(false);

    const navigate = useNavigate();

    const openTab = (key: string) => {
        const tab = tabs.find((e) => e.key === key);

        if (tenant?.id) {
            const route = generateRouteUrl({
                tenantId: tenant.id,
                tabType: tab?.type ?? '',
                options: {
                    tabKey: key,
                    elementId: tab?.elementId ?? '',
                },
            });

            navigate(route);
        }
    };

    const recalculateHidingCloseButtons = () => {
        const wrapperWidth = wrapperRef.current?.clientWidth;

        if (wrapperWidth) {
            const elements = document.getElementsByClassName('tab-header-item');

            // if wrapper width can't fit the elements x100px
            // then hide the close button for tabs other than the active
            setHideCloseButtons(elements.length * 100 >= wrapperWidth);

            // if wrapper width can't fit the elements x75px
            // then hide the title and icons for all tabs
            setHideTitles(elements.length * 75 >= wrapperWidth);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const onResize = () => recalculateHidingCloseButtons();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        recalculateHidingCloseButtons();
    }, [tabs]);

    const onSelect = (tabKey: string) => {
        openTab(tabKey);
    };

    return (
        <div ref={wrapperRef} className="tab-header-items-wrapper">
            <div ref={innerRef} className="tab-header-items">
                {tabs?.map((tab) => (
                    <TabItem
                        key={tab.key}
                        tab={tab}
                        onSelect={onSelect}
                        hideCloseButtons={hideCloseButtons}
                        hideTitles={hideTitles}
                    />
                ))}
            </div>
        </div>
    );
};

export default Tabs;

import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const Toggle = ({ label, isRequired }: { label: string; isRequired: boolean }) => {
    return (
        <div data-testid="component-preview-toggle" className="component-preview-input">
            <div className="form-group">
                <span role="checkbox" aria-checked={false} className="toggle" />
                <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            </div>
        </div>
    );
};

export default Toggle;

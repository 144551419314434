import { curry } from 'ramda';
import actionTypes from '../actions/reduxActions/actionTypes';
import { tabDetailsObjects } from '../../ts/config/admin';
import { ALWAYS_OPEN_NEW_TAB, TAB_TYPES } from '../../ts/constants';
import { isNullOrEmpty } from '../../ts/utils/guard';
import { generateTabKey } from '../../ts/utils/routing';

/**
    Example tab
    {
        title: 'Flows', 
        type: 'flows', 
        icon: <SVGElement>,
        elementId: '1', // Represents a page ID or flow ID for example
        key: 'ABC123',
        isActive: false,
    }
 */

const createTab = (partialTab) => {
    const tab = {
        title: '',
        type: '',
        icon: '',
        elementId: null,
        isActive: true,
        showConfirmation: false,
        ...partialTab,
    };

    if (isNullOrEmpty(tab.key)) {
        tab.key = generateTabKey();
    }

    return tab;
};

const addTab = (partialTab, existingTabs) => {
    const newTab = createTab(partialTab);
    const inactiveTabs = setActiveTab(newTab.key, existingTabs);

    return [...inactiveTabs, newTab];
};

const setActiveTab = curry((key, tabs) => {
    const updatedTabs = tabs.map((tab) => {
        if (tab.key === key && tab.isActive === false) {
            return {
                ...tab,
                isActive: true,
            };
        }

        if (tab.key !== key && tab.isActive === true) {
            return {
                ...tab,
                isActive: false,
            };
        }

        return tab;
    });

    return updatedTabs;
});

const setTitle = curry((title, key, tab) => {
    if (tab.key === key) {
        return {
            ...tab,
            // If we're given a name make sure the tab name matches
            title,
        };
    }

    return tab;
});

const updateTab = curry((payload, key, tab) => {
    if (tab.key === key) {
        return {
            ...tab,
            ...payload,
        };
    }

    return tab;
});

const createInitialState = () => {
    const { title, type, icon } = tabDetailsObjects.flows;
    return [
        createTab({
            title,
            type,
            icon,
        }),
    ];
};

export const findMatchingTab = ({ key, type, elementId }, tabs) => {
    // Attempt to match by key
    const matchedByKey = typeof key === 'string' && tabs.find((tab) => tab.key === key);

    if (matchedByKey) {
        return matchedByKey;
    }

    // Attempt to match by elementId
    if (elementId) {
        return tabs.find((tab) => tab.elementId === elementId);
    }

    // This type should always open a new tab when not matched by key
    if (ALWAYS_OPEN_NEW_TAB.includes(type)) {
        return false;
    }

    // Any other type of tab should be a singleton
    return tabs.find((tab) => tab.type === type);
};

const openTab = ({ key, type, title, icon, elementId }, tabs) => {
    const matchingTab = findMatchingTab({ key, type, elementId }, tabs);

    if (matchingTab) {
        const updatedTabs = tabs.map((tab) => {
            // The tab type can change. Eg when a "newPage" becomes a "page"
            if (tab === matchingTab && !isNullOrEmpty(type) && tab.type !== type) {
                let updatedTab = {
                    ...tab,
                    type,
                };
                //In the case of a new flow tab to a regular flow tab more can change at the same time as the type
                if (type === TAB_TYPES.flow) {
                    updatedTab = {
                        ...updatedTab,
                        elementId,
                    };
                }
                return updatedTab;
            }
            return tab;
        });

        return setActiveTab(matchingTab.key, updatedTabs);
    }

    return addTab(
        {
            key,
            title,
            type,
            icon,
            elementId,
        },
        tabs,
    );
};

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const tabsReducer = (state = createInitialState(), { type, payload }) => {
    switch (type) {
        case actionTypes.TAB_OPEN: {
            const updatedTabs = openTab(payload, state);
            return updatedTabs;
        }

        case actionTypes.TAB_CLOSE: {
            const { key } = payload;

            const closingTabIndex = state.findIndex((tab) => tab.key === key);
            const activeTabIndex = state.findIndex((tab) => tab.isActive);
            const isClosingActiveTab = closingTabIndex === activeTabIndex;

            if (isClosingActiveTab) {
                const nextActiveTab = state[activeTabIndex + 1] ?? state[activeTabIndex - 1];
                const updatedTabs = setActiveTab(nextActiveTab.key, state);
                return updatedTabs.filter((tab) => tab.key !== payload.key);
            }

            return state.filter((tab) => tab.key !== payload.key);
        }

        case actionTypes.TAB_CLOSE_ALL: {
            const { isHomeActive } = payload;
            const homeTab = state[0];
            return [
                {
                    ...homeTab,
                    isActive: isHomeActive,
                },
            ];
        }

        case actionTypes.TAB_SET_TITLE: {
            return state.map(setTitle(payload.title, payload.key));
        }

        case actionTypes.TAB_UPDATE: {
            return state.map(updateTab(payload, payload.key));
        }

        default:
            return state;
    }
};

export default tabsReducer;

import translations from '../../translations';
import { useAnomalyDashboard } from './AnomalyProvider';
import { type ElementRef, useEffect, useRef } from 'react';
import { Chart, type ChartConfiguration } from 'chart.js/auto';
import type { ChartDataPoint, ChartPie } from '../../types/dashboard';

const AnomalyDonutChart = () => {
    const { chartData } = useAnomalyDashboard();

    const canvasRef = useRef<ElementRef<'canvas'>>(null);

    useEffect(() => {
        const doughnutChartData = chartData.map((cd) =>
            cd.data.reduce((accu, currentValue: ChartDataPoint) => accu + currentValue.y, 0),
        );

        const doughnutData: ChartPie[] = [];

        doughnutData.push({
            data: [...doughnutChartData],
            backgroundColor: ['#C73D58', '#6dc79f', '#6ebdf5'],
        });

        const context = canvasRef.current?.getContext('2d');

        if (!context) {
            return;
        }

        const chartConfig: ChartConfiguration<'doughnut', number[]> = {
            type: 'doughnut',
            data: {
                datasets: doughnutData,
                labels: [
                    translations.ANOMALY_type_error,
                    translations.ANOMALY_type_time,
                    translations.ANOMALY_type_usage,
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: { position: 'top', labels: { boxWidth: 35 } },
                },
            },
        };

        const chart = new Chart(context, chartConfig);

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            chart.destroy();
        };
    }, [chartData]);

    return (
        <div className="pie-chart-section">
            <h3>{translations.ANOMALY_donut_chart_title}</h3>
            <div className="insights-pie-chart">
                <canvas ref={canvasRef} data-testid="anomaly-doughnut-chart-canvas" />
            </div>
        </div>
    );
};

export default AnomalyDonutChart;

import type { ComponentMetadataAPI, ProcessPropertiesMetadataAPI } from '../types/integration';
import { fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export const getAllProcesses = (name: string | null) => {
    let url = '/api/draw/1/integration/process';

    if (name) {
        url += `?name=${name}`;
    }

    return fetchAndParse<ComponentMetadataAPI[]>({
        url,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getProcessMetadata = (accountId: string, id: string) =>
    fetchAndParse<ComponentMetadataAPI | null>({
        url: `/api/draw/1/integration/process/${accountId}/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getAllProcessNames = (processes: Partial<ComponentMetadataAPI>[] | undefined) =>
    fetchAndParse<ComponentMetadataAPI[]>({
        url: '/api/draw/1/integration/process/name',
        method: 'POST',
        body: processes,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getAllProcessProperties = (accountId: string) =>
    fetchAndParse<ComponentMetadataAPI[]>({
        url: `/api/draw/1/integration/${accountId}/processproperties`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getAllProcessPropertiesNames = (
    accountId: string,
    processProperties: Partial<ComponentMetadataAPI>[],
) =>
    fetchAndParse<ComponentMetadataAPI[]>({
        url: `/api/draw/1/integration/${accountId}/processproperties/name`,
        method: 'POST',
        body: processProperties,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getProcessProperties = (accountId: string, id: string) =>
    fetchAndParse<ProcessPropertiesMetadataAPI>({
        url: `/api/draw/1/integration/${accountId}/processproperties/${id}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

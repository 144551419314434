import classnames from 'classnames';
import { useState } from 'react';
import { connect } from 'react-redux';
import { editNote } from '../../../../../actions/reduxActions/canvasNotes';
import { useGraph } from '../../../GraphProvider';
import { getDateDisplay } from '../../../../../../ts/utils/date';

/**
 *
 * @param {String} subject The note subject heading
 * @param {String} content The text content to be displayed
 * @param {String} email The email of the note creator
 * @param {String} lastUpdated The timestamp of when the note was last modified
 * @param {String} id The note ID
 * @param {Function} edit Toggles whether the note is read only
 *
 * @description Displays the read only note content
 */
const NoteDisplay = ({ subject, content, email, lastUpdated, id, edit }) => {
    const [dropDownIsOpen, toggleDropdown] = useState(false);
    const { deleteMapElement, onTab, setDynamicNoteFocus } = useGraph();

    const onKeyDown = (event, step = null) => {
        if (event.key === 'Tab') {
            onTab(event);
        }
        // Custom focusing depending on where Enter/Space was hit.
        else if ((event.key === ' ' || event.key === 'Enter') && step !== null) {
            if (step === 'name' || step === 'edit' || step === 'content') {
                edit(id);
            }

            requestAnimationFrame(() => setDynamicNoteFocus(id, step));
        }
    };

    const dropdownClasses = classnames({
        'note-controls-dropdown': true,
        open: dropDownIsOpen,
    });

    return (
        <>
            <div className="note-inner">
                <div className="note-header">
                    <button
                        id={`${id}-name`}
                        className="btn-no-appearance-left"
                        onClick={() => edit(id)}
                        onKeyDown={(e) => onKeyDown(e, 'name')}
                        tabIndex={-1}
                        type="button"
                    >
                        <h1>{subject}</h1>
                    </button>
                    <div className="note-controls">
                        <button
                            id={`${id}-actions`}
                            onClick={() => toggleDropdown(!dropDownIsOpen)}
                            title="Actions"
                            className="note-controls-cog"
                            onKeyDown={onKeyDown}
                            tabIndex={-1}
                            type="button"
                        >
                            <span className="glyphicon glyphicon-cog" />
                        </button>
                        <ul
                            id={`${id}-options-menu`}
                            aria-hidden={!dropDownIsOpen}
                            className={dropdownClasses}
                        >
                            <li>
                                <button
                                    id={`${id}-edit`}
                                    className="note-control-btn"
                                    onClick={() => edit(id)}
                                    onKeyDown={(e) => onKeyDown(e, 'edit')}
                                    tabIndex={-1}
                                    type="button"
                                >
                                    Edit
                                </button>
                            </li>
                            <li>
                                <button
                                    id={`${id}-delete`}
                                    className="note-control-btn"
                                    onClick={() => deleteMapElement(id)}
                                    onKeyDown={onKeyDown}
                                    tabIndex={-1}
                                    type="button"
                                >
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <button
                    id={`${id}-content`}
                    className="note-content btn-no-appearance-left"
                    onClick={() => edit(id)}
                    onKeyDown={(e) => onKeyDown(e, 'content')}
                    tabIndex={-1}
                    type="button"
                >
                    <p>{content}</p>
                </button>
            </div>
            <div className="note-footer">
                <span className="footer-column">{email}</span>
                <span className="footer-column created-at">{getDateDisplay(lastUpdated)}</span>
            </div>
        </>
    );
};

export default connect(null, { edit: editNote })(NoteDisplay);

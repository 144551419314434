import FileDrop from '../../../generic/upload/FileDrop';
import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const FileUpload = ({ label, isRequired }: { label: string; isRequired: boolean }) => {
    return (
        <div data-testid="component-preview-file-upload" className="component-preview-file-upload">
            <PreviewComponentLabel
                className="component-preview-file-upload-label"
                labelText={label}
                isRequired={isRequired}
            />
            <FileDrop
                className="component-preview-file-upload-drop-zone"
                placeholder="Upload File"
            />
        </div>
    );
};

export default FileUpload;

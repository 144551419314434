import type { DependencyAPI, Filter, ItemCollectionResponse } from '../types';
import { fetchAndParse, filterToQuery } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export default {
    getAll: ({ id, filters }: { id: string; filters: Filter }) => {
        const query = filterToQuery(filters);
        return fetchAndParse<ItemCollectionResponse<DependencyAPI>>({
            url: `/api/draw/1/dependents/${id}${query}`,
            method: 'GET',
            headers: {
                ManyWhoTenant: getTenantId(),
            },
        });
    },
};

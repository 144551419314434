export const icon = (
    // biome-ignore lint/a11y/noSvgWithoutTitle: Including a title breaks tests, requires dedicated refactor
    <svg width="17" height="17" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 22C4 12.058 12.058 4 22 4C31.942 4 40 12.058 40 22C40 31.942 31.942 40 22 40C12.058 40 4 31.942 4 22ZM22 32V36.0001C29.72 36.0001 36.0001 29.72 36.0001 22C36.0001 14.28 29.72 7.99991 22 7.99991V12C16.478 12 12 16.478 12 22C12 27.522 16.478 32 22 32ZM22 32C27.522 32 32 27.522 32 22C32 16.478 27.522 12 22 12V32Z"
            fill="currentColor"
        />
    </svg>
);

export const REGIONS = {
    us: {
        name: 'North America (US)',
        summary: 'North Virginia, USA',
        url: 'https://us.flow-prod.boomi.com',
    },
    ca: {
        name: 'North America (CA)',
        summary: 'Montreal, Canada',
        url: 'https://ca.flow-prod.boomi.com',
    },
    eu: {
        name: 'Europe (EU)',
        summary: 'Frankfurt, Germany',
        url: 'https://eu.flow-prod.boomi.com',
    },
    gb: {
        name: 'Europe (GB)',
        summary: 'London, United Kingdom',
        url: 'https://gb.flow-prod.boomi.com',
    },
    au: {
        name: 'Asia Pacific (AU)',
        summary: 'Sydney, Australia',
        url: 'https://au.flow-prod.boomi.com',
    },
    sg: {
        name: 'Asia Pacific (SG)',
        summary: 'Singapore, Singapore',
        url: 'https://sg.flow-prod.boomi.com',
    },
};

import ConfirmModal from '../generic/modal/ConfirmModal';
import translations from '../../translations';
import Table, { type TableColumnList } from '../generic/Table';
import PageHeader from '../generic/PageHeader';
import Sortable from '../generic/Sortable';
import { stringReplace } from '../../utils/string';
import { useEffect, useState, useRef, type ElementRef } from 'react';
import { SYSTEM_TYPES } from '../../constants';
import { Trash } from '@phosphor-icons/react';
import { useTypes } from './TypesProvider';
import type { OrderDirection, TypeElementResponseAPI } from '../../types';

const TypesList = () => {
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

    const {
        types,
        fetchTypes,
        createNewType,
        importProfile,
        editType,
        paging,
        setTypeToDelete,
        deleteType,
        typeToDelete,
        notifyError,
        typesLoading,
    } = useTypes();

    const { pageSize, page, total, search, orderBy, orderDirection } = paging;

    const modalContainerRef = useRef<ElementRef<'div'>>(null);

    useEffect(() => {
        fetchTypes({
            limit: pageSize,
            page,
            search,
            orderBy,
            orderDirection,
        });
    }, [fetchTypes, pageSize, page, search, orderBy, orderDirection]);

    const onDelete = (type: TypeElementResponseAPI) => {
        if (SYSTEM_TYPES.includes(type.developerName ?? '')) {
            notifyError(translations.TYPES_delete_system_type_error_message);
        } else {
            setTypeToDelete(type);
            setDeleteModalIsVisible(true);
        }
    };

    const onPage = (updatedPage: number) => {
        fetchTypes({
            limit: pageSize,
            page: updatedPage,
            search,
            orderBy,
            orderDirection,
        });
    };

    const onRefresh = () => {
        fetchTypes({
            limit: pageSize,
            page,
            search,
            orderBy,
            orderDirection,
        });
    };

    const onSort = ({ orderBy, direction }: { orderBy: string; direction: OrderDirection }) => {
        fetchTypes({
            limit: pageSize,
            page,
            search,
            orderBy,
            orderDirection: direction,
        });
    };

    const onFilter = (searchQuery: string) => {
        fetchTypes({
            limit: pageSize,
            page: 1,
            search: searchQuery,
            orderBy,
            orderDirection,
        });
    };

    const columns: TableColumnList<TypeElementResponseAPI> = [
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection="ASC"
                    direction={paging.orderBy === 'developerName' ? paging.orderDirection : null}
                    onSort={(direction) => onSort({ orderBy: 'developerName', direction })}
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }) => (
                <button
                    className="link-emulate overflow-ellipsis"
                    onClick={() => editType(item)}
                    title={`${translations.COMMON_edit} ${item.developerName}`}
                    aria-label={`${translations.COMMON_edit} ${item.developerName}`}
                    type="button"
                >
                    {item.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection="ASC"
                    direction={paging.orderBy === 'dateModified' ? paging.orderDirection : null}
                    onSort={(direction) => onSort({ orderBy: 'dateModified', direction })}
                >
                    {translations.COMMON_TABLE_last_modified}
                </Sortable>
            ),
            renderCell: ({ item }) =>
                SYSTEM_TYPES.includes(item.developerName ?? '')
                    ? null
                    : new Date(item.dateModified).toLocaleString(undefined, {
                          dateStyle: 'medium',
                          timeStyle: 'short',
                      }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_connector,
            renderCell: ({ item }) => (
                <div className="overflow-ellipsis">{item.serviceElementDeveloperName}</div>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item }) => <div>{item.developerSummary}</div>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) =>
                SYSTEM_TYPES.includes(item.developerName ?? '') ? (
                    // Need to maintain row height that the buttons provide.
                    <div className="table-icon" />
                ) : (
                    <div className="action-btn-wrapper">
                        <button
                            title={`${translations.COMMON_delete} ${item.developerName}`}
                            className="table-icon table-icon-delete"
                            aria-label={`${translations.COMMON_delete} ${item.developerName}`}
                            onClick={() => onDelete(item)}
                            type="button"
                        >
                            <Trash />
                        </button>
                    </div>
                ),
            size: '5rem',
        },
    ];

    const customButtons = [{ name: translations.TYPES_import_profile, onClick: importProfile }];

    return (
        <div className="admin-page flow-wrapper" ref={modalContainerRef}>
            <ConfirmModal
                show={deleteModalIsVisible}
                title={translations.TYPE_delete_confirmation_title}
                messages={[
                    stringReplace(translations.TYPE_delete_confirmation_message, {
                        typeName: typeToDelete?.developerName ?? '',
                    }),
                    translations.GENERAL_cannot_be_undone,
                ]}
                buttonStyle="danger"
                buttonCaption={translations.COMMON_delete}
                onCancel={() => setDeleteModalIsVisible(false)}
                onConfirm={() => {
                    deleteType();
                    setDeleteModalIsVisible(false);
                }}
                container={modalContainerRef.current}
            />
            <PageHeader
                title={translations.TYPES_types_list_title}
                onAdd={createNewType}
                onRefresh={onRefresh}
                onSearch={onFilter}
                searchQuery={paging.search}
                searchPlaceholderText={translations.COMMON_search}
                addText={translations.TYPES_new_type_button_label}
                refreshTitle={translations.TYPES_refresh_results_button_label}
                customButtons={customButtons}
            />
            <Table
                wrapperClassName="margin-top"
                columns={columns}
                items={types}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: onPage,
                }}
                isLoading={typesLoading}
            />
        </div>
    );
};

export default TypesList;

export const FRIENDLY_DEPENDENCY_NAMES = {
    FLOW: 'Flow',
    GROUP_ELEMENT: 'Group Element',
    MACRO_ELEMENT: 'Macro',
    MAP_ELEMENT: 'Map Element',
    NAVIGATION_ELEMENT: 'Navigation',
    NAVIGATION_ITEM: 'Navigation Item',
    PAGE: 'Page',
    PAGE_ELEMENT: 'Page Layout',
    SERVICE_ELEMENT: 'Service',
    TAG_ELEMENT: 'Tag',
    TYPE: 'Type',
    TYPE_ELEMENT: 'Type',
    TYPE_ELEMENT_BINDING: 'Binding',
    TYPE_ELEMENT_PROPERTY: 'Property',
    VALUE_ELEMENT: 'Value',
} as const;

import { ExStructuredListCol } from '@boomi/exosphere';
import type { ResponseHeaders } from '../../../../../types';

interface Props {
    headers: ResponseHeaders;
}

const RootFaultResponseHeaders = ({ headers }: Props) => {
    return (
        <>
            {Object.keys(headers).map((headerKey) => {
                return (
                    <ExStructuredListCol key={headerKey}>
                        <div>
                            <b>{headerKey}</b>
                        </div>
                        <div className="ex-fs-micro">{headers[headerKey]}</div>
                    </ExStructuredListCol>
                );
            })}
        </>
    );
};

export default RootFaultResponseHeaders;

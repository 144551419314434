import type { GroupBase, StylesConfig } from 'react-select';

export const getSharedStyles = <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
    const sharedStyles: StylesConfig<Option, IsMulti, Group> = {
        control: (baseStyles) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderColor: '#ccc',
            borderRadius: '0.4rem',
            ':hover': {
                ...baseStyles[':hover'],
                borderColor: '#ccc',
            },
        }),
        noOptionsMessage: (baseStyles) => ({
            ...baseStyles,
            textAlign: 'left',
        }),
    };

    return sharedStyles;
};

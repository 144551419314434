import classNames from 'classnames';
import type { ReactNode } from 'react';

interface Props {
    className?: string;
    label?: string;
    labelId?: string;
    labelPosition?: 'before' | 'after';
    htmlFor?: string;
    isRequired?: boolean;
    isValid?: boolean;
    validationMessage?: string;
    showValidation?: boolean;
    requiredIndicator?: '*' | '(required)';
    children?: ReactNode;
}

const FormGroup = ({
    className = '',
    label = '',
    labelId: labelIdProp = '',
    labelPosition = 'before',
    htmlFor = '',
    isRequired = false,
    isValid = false,
    validationMessage = '',
    showValidation = false,
    requiredIndicator = '*',
    children,
}: Props) => {
    const containerClassName = classNames('form-group', className, {
        'has-error': !isValid && showValidation,
    });

    const labelId = labelIdProp ?? `${htmlFor}-input-label`;

    const labelElement =
        (label && (
            <label id={labelId} data-testid={labelId} htmlFor={htmlFor}>
                {label}
            </label>
        )) ||
        null;

    return (
        <div className={containerClassName}>
            {labelPosition === 'before' && labelElement}
            {isRequired && (
                <span data-testid={`${htmlFor}-input-required`} className="input-required">
                    {' '}
                    {requiredIndicator}
                </span>
            )}
            {children}
            {labelPosition === 'after' && labelElement}
            {!isValid && showValidation && validationMessage && (
                <span data-testid={`${htmlFor}-validation`} className="help-block error-state">
                    {validationMessage}
                </span>
            )}
        </div>
    );
};

export default FormGroup;

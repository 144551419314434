import { useState } from 'react';
import SearchInput from '../../../components/generic/SearchInput';
import Table from '../../generic/Table';
import translations from '../../../translations';
import type { ServiceActionRequestAPI } from '../../../types/service';
import { stringContains } from '../../../utils/string';

interface ExistingActionsProps {
    actions: ServiceActionRequestAPI[];
}

const ExistingActions = ({ actions }: ExistingActionsProps) => {
    const [search, setSearch] = useState('');

    const filterResults = () => {
        const queryResult = actions.filter((action) =>
            stringContains(action.developerName, search, false),
        );
        return queryResult;
    };

    return (
        <>
            <h2>
                Actions
                <small>{` Total: ${actions.length ?? 0}`}</small>
            </h2>
            <div className="flex">
                <SearchInput onChange={setSearch} value={search} />
            </div>
            <Table
                wrapperClassName="margin-top"
                columns={[
                    {
                        renderHeader: () => translations.COMMON_TABLE_name,
                        renderCell: ({ item }) => item.developerName,
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_uri,
                        renderCell: ({ item }) => item.uriPart,
                    },
                ]}
                items={filterResults()}
                pagination={true}
            />
        </>
    );
};

export default ExistingActions;

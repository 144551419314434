import type { ChangeEventHandler } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import {
    changeSAMLSetting,
    changeSecuritySetting,
} from '../../../../js/actions/reduxActions/currentTenant';
import { RUNTIME_URI } from '../../../constants';
import translations from '../../../translations';
import type { SecuritySettingsAPI, TenantResponseAPI } from '../../../types/Tenant';
import FormGroup from '../../generic/FormGroup';
import ClipboardInput from '../../inputs/ClipboardInput';
import Toggle from '../../inputs/Toggle';
import SAMLCookieSettings from './SAMLCookieSettings';
import SAMLSettingsGroups from './SAMLSettingsGroups';

const algorithms = [
    { key: 'http://www.w3.org/2001/04/xmldsig-more#rsa-sha256', value: 'SHA256' },
    { key: 'http://www.w3.org/2001/04/xmldsig-more#rsa-sha384', value: 'SHA384' },
    { key: 'http://www.w3.org/2001/04/xmldsig-more#rsa-sha512', value: 'SHA512' },
    { key: 'http://www.w3.org/2000/09/xmldsig#rsa-sha1', value: 'SHA1' },
];

const mapStateToProps = (state: { currentTenant: TenantResponseAPI }) => ({
    tenantId: state.currentTenant.id,
});

const mapDispatchToProps = {
    changeSecuritySetting,
    changeSAMLSetting,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SAMLSettingsProps = {
    isActive: boolean;
    isSso: boolean;
    settings: SecuritySettingsAPI['samlSettings'];
} & ConnectedProps<typeof connector>;

const SAMLSettings = ({
    isActive,
    settings,
    tenantId,
    isSso,
    changeSAMLSetting,
    changeSecuritySetting,
}: SAMLSettingsProps) => {
    const onChangeCertificate: ChangeEventHandler<HTMLTextAreaElement> = ({
        target: { value },
    }) => {
        changeSAMLSetting({
            settingPath: ['certificate'],
            value,
        });
    };

    const onChangeRedirectUri: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        changeSAMLSetting({
            settingPath: ['redirectUri'],
            value,
        });
    };

    const onChangeAlgorithm: ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
        changeSAMLSetting({
            settingPath: ['algorithm'],
            value,
        });
    };

    const onChangeLogoutUri: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        changeSAMLSetting({
            settingPath: ['logoutUri'],
            value,
        });
    };

    const onChangeFirstNameClaim: ChangeEventHandler<HTMLInputElement> = ({
        target: { value },
    }) => {
        changeSAMLSetting({
            settingPath: ['claims', 'firstName'],
            value,
        });
    };

    const onChangeLastNameClaim: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        changeSAMLSetting({
            settingPath: ['claims', 'lastName'],
            value,
        });
    };

    const onChangeEmailClaim: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        changeSAMLSetting({
            settingPath: ['claims', 'emailAddress'],
            value,
        });
    };

    const onChangeGroupClaim: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        changeSAMLSetting({
            settingPath: ['groupSettings', 'groupAttribute'],
            value,
        });
    };

    const onToggleEnabled = () => {
        changeSecuritySetting({
            settingName: 'isSamlEnabled',
            value: !isActive,
        });
    };

    return (
        <>
            <FormGroup
                htmlFor="enable-saml-toggle"
                label={translations.SAML_enable_label}
                labelPosition="after"
            >
                <Toggle
                    isOn={isActive}
                    onChange={onToggleEnabled}
                    className="enable-SAML"
                    testId="enable-SAML"
                    id="enable-saml-toggle"
                />
            </FormGroup>
            {isActive && (
                <div className="SAML-settings" data-testid="SAML-settings">
                    {isSso && (
                        <div className="alert alert-warning">
                            <strong>{translations.SAML_warning_1}</strong>
                            {translations.SAML_warning_2}
                        </div>
                    )}
                    <FormGroup
                        htmlFor="copy-saml-login-url"
                        label={translations.SAML_login_url_label}
                    >
                        <ClipboardInput
                            value={`${RUNTIME_URI}/api/draw/1/authentication/saml/${tenantId}`}
                            buttonTitle={translations.copy_saml_login_url_title}
                            inputId="copy-saml-login-url"
                        />
                        <p className="help-block">
                            {translations.SAML_login_url_help_1}
                            <br />
                            {translations.SAML_login_url_help_2}
                        </p>
                    </FormGroup>
                    <FormGroup
                        htmlFor="SAMLCert"
                        label={translations.SAML_certificate_label}
                        isRequired
                    >
                        <textarea
                            id="SAMLCert"
                            className="form-control certificate"
                            onChange={onChangeCertificate}
                            rows={12}
                            value={settings?.certificate ?? ''}
                        />
                        <p className="help-block">{translations.SAML_certificate_help}</p>
                    </FormGroup>
                    <FormGroup htmlFor="saml-algorithm" label={translations.SAML_algorithm_label}>
                        <select
                            id="saml-algorithm"
                            className="form-control form-control-dynamic"
                            value={settings?.algorithm ?? ''}
                            onChange={onChangeAlgorithm}
                        >
                            {algorithms.map((algorithm) => (
                                <option key={algorithm.key} value={algorithm.key}>
                                    {algorithm.value}
                                </option>
                            ))}
                        </select>
                        <p className="help-block">{translations.SAML_algorithm_help}</p>
                    </FormGroup>
                    <FormGroup
                        htmlFor="saml-redirect-uri"
                        label={translations.SAML_redirect_url_label}
                        isRequired
                    >
                        <input
                            id="saml-redirect-uri"
                            className="form-control saml-redirect-uri"
                            onChange={onChangeRedirectUri}
                            type="text"
                            value={settings?.redirectUri ?? ''}
                        />
                        <p className="help-block">{translations.SAML_redirect_url_help}</p>
                    </FormGroup>
                    <FormGroup htmlFor="saml-logout-uri" label={translations.SAML_logout_url_label}>
                        <input
                            id="saml-logout-uri"
                            className="form-control saml-logout-uri"
                            onChange={onChangeLogoutUri}
                            type="text"
                            value={settings?.logoutUri ?? ''}
                        />
                        <p className="help-block">{translations.SAML_logout_url_help}</p>
                    </FormGroup>
                    <section>
                        <h4>Configuring SAML claims</h4>
                        <FormGroup
                            htmlFor="saml-firstname-claim"
                            label={translations.SAML_firstname_label}
                        >
                            <input
                                id="saml-firstname-claim"
                                className="form-control saml-firstname-claim"
                                onChange={onChangeFirstNameClaim}
                                type="text"
                                value={settings?.claims?.firstName ?? ''}
                            />
                            <p className="help-block">
                                {translations.SAML_firstname_help_1}
                                <br />
                                {translations.SAML_firstname_help_2}
                            </p>
                        </FormGroup>
                        <FormGroup
                            htmlFor="saml-lastname-claim"
                            label={translations.SAML_lastname_label}
                        >
                            <input
                                id="saml-lastname-claim"
                                className="form-control saml-lastname-claim"
                                onChange={onChangeLastNameClaim}
                                type="text"
                                value={settings?.claims?.lastName ?? ''}
                            />
                            <p className="help-block">
                                {translations.SAML_lastname_help_1}
                                <br />
                                {translations.SAML_lastname_help_2}
                            </p>
                        </FormGroup>
                        <FormGroup htmlFor="saml-email-claim" label={translations.SAML_email_label}>
                            <input
                                id="saml-email-claim"
                                className="form-control saml-email-claim"
                                onChange={onChangeEmailClaim}
                                type="text"
                                value={settings?.claims?.emailAddress ?? ''}
                            />
                            <p className="help-block">
                                {translations.SAML_email_help_1}
                                <br />
                                {translations.SAML_email_help_2}
                            </p>
                        </FormGroup>
                        <FormGroup
                            htmlFor="saml-group-claim"
                            label={translations.SAML_groupname_label}
                        >
                            <input
                                id="saml-group-claim"
                                className="form-control saml-group-claim"
                                onChange={onChangeGroupClaim}
                                type="text"
                                value={settings?.groupSettings?.groupAttribute ?? ''}
                            />
                            <p className="help-block">{translations.SAML_groupname_help}</p>
                        </FormGroup>
                        <SAMLSettingsGroups settings={settings} />
                        <SAMLCookieSettings settings={settings} />
                    </section>
                </div>
            )}
        </>
    );
};

export default connector(SAMLSettings);

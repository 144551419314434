import classNames from 'classnames';
import Select from 'react-select';
import './editor.less';
import { getSharedStyles } from '../../../utils/select';

export interface EditorDocumentSelectProps {
    id: string;
    className?: string | undefined;
    label: string;
    handleChange: (option: SelectOption | undefined | null) => void;
    options: SelectOption[];
    value?: SelectOption | undefined;
}

interface SelectOption {
    value: string;
    label: string;
}

const EditorDocumentSelect = ({
    id,
    className,
    label,
    handleChange,
    options,
    value,
}: EditorDocumentSelectProps) => {
    const selectClasses = classNames('document-select', className);

    return (
        <div className="document-select-container">
            <label htmlFor={id} className="select-label editor-header-label">
                {label}
            </label>
            <Select
                inputId={id}
                className={selectClasses}
                styles={getSharedStyles<SelectOption | undefined>()}
                onChange={handleChange}
                options={options}
                value={value}
            />
        </div>
    );
};

export default EditorDocumentSelect;

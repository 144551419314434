import actionTypes from '../actions/reduxActions/actionTypes';
import { omit, merge } from 'ramda';

/**
 * @param {Object} filters Initial filters state
 * @param {Object} action Object containing the action type, payload, error and meta
 * @description Reducer that creates and maintains the valuePickerFilters property on the state object.
 */
// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const valuePickerFilters = (filters = {}, { type, payload }) => {
    switch (type) {
        case actionTypes.VALUE_PICKER_FILTER_SET:
            return {
                ...filters,
                [payload.tabId]: merge(filters[payload.tabId], { [payload.filter]: payload.value }),
            };

        case actionTypes.VALUE_PICKER_FILTER_REMOVE:
            return { ...filters, [payload.tabId]: omit([payload.filter], filters[payload.tabId]) };

        case actionTypes.VALUE_PICKER_CLEAR_FILTERS:
            return omit([payload.tabId], filters);

        default:
            return filters;
    }
};

export default valuePickerFilters;

import type {
    RuntimeListResponse,
    TenantRuntimeListResponse,
    RuntimeResponse,
    RuntimeCreateResponse,
    RuntimeCreateRequest,
    RuntimeUpdateRequest,
} from '../types/runtime';
import { fetchAndParse, getTenantId } from '../utils';

// Fetching an orgs local runtime entities
export const getRuntimes = () => {
    return fetchAndParse<RuntimeListResponse[]>({
        url: '/api/admin/1/organization/runtimes',
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

// Fetching a tenants local runtime entities
export const getRuntimesForTenant = () => {
    return fetchAndParse<TenantRuntimeListResponse[]>({
        url: '/api/admin/1/tenant/runtimes',
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

// Fetching a single runtime entity
export const getRuntime = ({ id }: { id: string }) => {
    return fetchAndParse<RuntimeResponse>({
        url: `/api/admin/1/organization/runtimes/${id}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

// Creating a new local runtime
export const createRuntime = (request: RuntimeCreateRequest) => {
    return fetchAndParse<RuntimeCreateResponse>({
        url: '/api/admin/1/organization/runtimes',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body: request,
    });
};

// Creating a new local runtime
export const updateRuntime = (request: RuntimeUpdateRequest, runtimeId: string) => {
    return fetchAndParse<RuntimeResponse>({
        url: `/api/admin/1/organization/runtimes/${runtimeId}`,
        method: 'PUT',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body: request,
    });
};

// Deleting a local runtime
export const deleteRuntime = ({ runtimeId }: { runtimeId: string }) => {
    return fetchAndParse<void>({
        url: `/api/admin/1/organization/runtimes/${runtimeId}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
    });
};

import {
    exportFlow,
    getActivated,
    getActivatedForEnvironment,
    getFlows,
    getLatest,
    importFlow,
    importSharedFlow,
    setLatest,
    shareFlow,
    deleteFlow as deleteFlowSource,
} from '../../../ts/sources/flow';
import translations from '../../../ts/translations';
import { TAB_TYPES, NOTIFICATION_TYPES } from '../../../ts/constants';
import actionTypes from './actionTypes';
import { addNotification, notifyError } from './notification';
import { stringReplace } from '../../../ts/utils/string';

export const closeFlow = (flowId) => ({
    type: actionTypes.FLOW_CLOSE,
    payload: flowId,
});

export const setFlowDeleting = (isDeleting) => ({
    type: actionTypes.FLOW_DELETING,
    payload: isDeleting,
});

export const setActivatedFlows = (activatedFlows) => ({
    type: actionTypes.FLOWS_ACTIVATED_RESPONSE,
    payload: activatedFlows,
});

export const fetchActivatedFlows = (tenantId, environmentId) => async (dispatch) => {
    try {
        let activatedFlows = null;

        if (environmentId) {
            activatedFlows = await getActivatedForEnvironment(tenantId, environmentId);
        } else {
            activatedFlows = await getActivated(tenantId);
        }

        dispatch(setActivatedFlows(activatedFlows));
    } catch (error) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            }),
        );
    }
};

export const setAllFlows = (allFlows) => ({
    type: actionTypes.FLOW_LIST_RESPONSE,
    payload: allFlows,
});

export const setFlowLabels = (payload) => async (dispatch) => {
    try {
        const newFlow = {
            ...payload.flow,
            tags: payload.labels,
        };
        dispatch({
            type: actionTypes.FLOW_UPDATE_LABELS,
            payload: newFlow,
        });

        const flowData = await getLatest(payload.flow.id.id, payload.tenantId);

        const newEditFlow = {
            ...flowData,
            tags: payload.labels,
        };
        await setLatest(newEditFlow, payload.tenantId);
    } catch (error) {
        // Catch error and notify
        notifyError(error.message);
    }
};

export const setFlowListLoading = () => ({
    type: actionTypes.FLOW_LIST_LOADING,
});

export const fetchAllFlows = (tenantId) => async (dispatch) => {
    dispatch(setFlowListLoading());

    try {
        const allFlows = await getFlows(tenantId);
        dispatch(setAllFlows(allFlows));
    } catch (error) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            }),
        );
    }
};

export const deleteFlow = (id, tenantId) => async (dispatch) => {
    dispatch(setFlowDeleting(true));

    try {
        await deleteFlowSource(id, tenantId);
    } catch (error) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: stringReplace(translations.FLOW_unable_to_delete, {
                    flowName: error.message,
                }),
                isPersistent: true,
            }),
        );
    }

    dispatch(setFlowDeleting(false));
};

/**
 * Select this flow ready for export
 *
 * @param {string} flowId flow ID
 */
export const selectExport = (flowId) => ({
    type: actionTypes.FLOW_SELECT_EXPORT,
    payload: flowId,
});

/**
 * Add the escaped JSON flow package to the store
 *
 * @param {string} flowEscapedJSON Escaped JSON flow package
 */
export const setExportedFlow = (flowEscapedJSON) => ({
    type: actionTypes.FLOW_SET_EXPORTED_ESCAPED_JSON,
    payload: flowEscapedJSON,
});

/**
 * Export escaped JSON for this flow
 *
 * @param {string} flowId flow ID
 * @param {string} tenantId tenant ID
 
 * @param {boolean} includePasswords true to include passwords in the export package, otherwise passwords are removed.
 */
export const exportPackage =
    (id, tenantId, includePasswords, environmentId) => async (dispatch) => {
        try {
            const flowEscapedJSON = await exportFlow(id, tenantId, includePasswords, environmentId);
            dispatch(setExportedFlow(flowEscapedJSON));
        } catch (error) {
            dispatch(setExportedFlow(null));
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: error.message,
                    isPersistent: true,
                }),
            );
        }
    };

/**
 * Toggle the inclusion of passwords in the export
 *
 * @param {boolean} includePasswords true include passwords in export, otherwise false
 */
export const exportWithPasswords = (includePasswords) => ({
    type: actionTypes.FLOW_INCLUDE_PASSWORDS,
    payload: includePasswords,
});

/**
 * Add sharing token to store
 *
 * @param {string} sharingToken
 */
export const setSharingToken = (sharingToken) => ({
    type: actionTypes.FLOW_SET_GENERATED_SHARED_TOKEN,
    payload: sharingToken,
});

/**
 * Generate a sharing token for this flow
 *
 * @param {string} flowId flow ID
 * @param {string} tenantId tenant ID
 
 * @param {boolean} includePasswords true to include passwords in the export package, otherwise passwords are removed.
 */
export const sharePackage = (id, tenantId, includePasswords, environmentId) => async (dispatch) => {
    try {
        const sharingToken = await shareFlow(id, tenantId, includePasswords, environmentId);
        dispatch(setSharingToken(sharingToken));
    } catch (error) {
        dispatch(setSharingToken({ token: null }));
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            }),
        );
    }
};

/**
 * Add the import sharing token to the store
 */
export const setImportToken = (importToken) => ({
    type: actionTypes.FLOW_SET_IMPORT_TOKEN,
    payload: importToken,
});

/**
 * Toggle display of the import confirmation
 *
 * @param {boolean} show true show dialog, otherwise don't
 */
export const showImportConfirmation = (show) => ({
    type: actionTypes.FLOW_SHOW_IMPORT_CONFIRMATION,
    payload: show,
});

/**
 * Add package to store
 *
 * @param {string} importedJSON usually read from a file drag-n-dropped
 */
export const setImportedPackage = (importedJSON) => ({
    type: actionTypes.FLOW_SET_IMPORTED_PACKAGE,
    payload: importedJSON,
});

/**
 * Indicate to component that the previous import was successful
 */
export const importSuccess = (result) => ({
    type: actionTypes.FLOW_IMPORT_SUCCESS,
    payload: result,
});

/**
 * Indicate to component that the previous import had a conflict, i.e. it will overwrite some shared elements.
 *
 * @param {Object} Object containing lists of dependent shared elements that are in conflict. Typically this
 *                        object looks like,
 *
 * {
 *  "existingElements": {
 *     "flows": [
 *       {
 *         "developerName": "Date Pick Test",
 *         "id": "3a8e11dc-f224-42f2-b977-2b7f4c0d899f"
 *       }
 *     ],
 *     "groupElements": [],
 *     "macroElements": [],
 *     "mapElements": [
 *       {
 *         "developerName": "Date",
 *         "id": "508a8887-bc14-42e7-a406-bbb36c3541ae"
 *       },
 *       {
 *         "developerName": "Start",
 *         "id": "676eaa8c-cdf6-4e90-b7da-3bf42fc251c0"
 *       }
 *     ],
 *     "navigationElements": [],
 *     "pageElements": [
 *       {
 *         "developerName": "Date page",
 *         "id": "2d42ecbf-3d42-4ef2-8113-9473cfb0b3bc"
 *       }
 *     ],
 *     "serviceElements": [],
 *     "tagElements": [],
 *     "typeElements": [],
 *     "valueElements": [
 *       {
 *         "developerName": "date",
 *         "id": "78dbebe0-6409-4024-9241-00f6f59c97f3"
 *       }
 *     ]
 *   }
 * }
 *
 */
export const importConflict = (hasConflict, conflictingDependents = {}) => ({
    type: actionTypes.FLOW_IMPORT_CONFLICT,
    payload: { hasConflict, conflictingDependents },
});

/**
 * All done importing, reset
 */
export const finishImport = () => ({
    type: actionTypes.FLOW_IMPORT_SUCCESS,
    payload: false,
});

/**
 * Import a JSON package
 *
 * @param {string} json the JSON package
 * @param {string} tenantId tenant ID
 
 * @param {boolean} force if true allow overwriting an existing package
 */
export const importPackage = (json, tenantId, force) => async (dispatch, getState) => {
    try {
        const result = await importFlow(json, tenantId, force);

        // Check for an open tab tab that has a matching id and ensure the name is correct
        // An import will rename a flow if the ids match but the names are different
        const { tabs } = getState();

        const existingFlowEditor = tabs.find(
            (tab) => tab.type === TAB_TYPES.flow && tab.elementId === result.id.id,
        );

        if (existingFlowEditor && existingFlowEditor.title !== result.developerName) {
            const { key } = existingFlowEditor;
            dispatch({
                type: actionTypes.TAB_SET_TITLE,
                payload: { key, title: result.developerName },
            });
        }

        dispatch(importSuccess(result));
    } catch (error) {
        if (error.wasConflict) {
            dispatch(importConflict(true, error.conflictingDependents));
        } else {
            dispatch(finishImport());
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: error.message,
                    isPersistent: true,
                }),
            );
        }
    }
};

/**
 * Import a package via a sharing token
 *
 * @param {string} sharingToken the sharing token
 * @param {string} tenantId tenant ID
 
 * @param {boolean} force if true allow overwriting an existing package
 */
export const importSharedPackage = (sharingToken, tenantId, force) => async (dispatch) => {
    try {
        const result = await importSharedFlow(sharingToken, tenantId, force);
        dispatch(fetchAllFlows(tenantId));
        dispatch(importSuccess(result));
    } catch (error) {
        if (error.wasConflict) {
            dispatch(importConflict(true, error.conflictingDependents));
        } else {
            dispatch(finishImport());
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: error.message,
                    isPersistent: true,
                }),
            );
        }
    }
};

import { useState, createContext, useContext, type ReactNode, useEffect } from 'react';
import { useMapElement } from '../../../../../js/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import type {
    DynamicProcessProperty,
    Process,
    ProcessScreens,
    UseMapElement,
    ValueElementIdAPI,
} from '../../../../types';
import type { ProcessProperties } from '../../../../types/integration';
import { omit } from 'ramda';
import { getAllProcessNames } from '../../../../sources/integration';

interface Props {
    defaultScreen: ProcessScreens;
    children: ReactNode;
}

interface Context {
    processToEdit: ProcessToEdit;
    processPropertiesToEdit: ProcessPropertiesToEdit;
    processNames: Record<string, string>;
    onCreateProcess: () => void;
    onEditProcess: (process: Process, index: number) => void;
    onDeleteProcess: (index: number) => void;
    onProcessAndAccountChange: (processId: string, accountId: string) => void;
    onWaitForProcessToFinishChange: (waitForProcessToFinish: boolean) => void;
    onDisabledChange: (disabled: boolean) => void;
    onOrderChange: (order: number) => void;
    onDynamicProcessPropertiesChange: (dynamicProcessProperties: DynamicProcessProperty[]) => void;
    onAddProcessProperties: () => void;
    onEditProcessProperties: (componentId: string) => void;
    onRemoveProcessProperties: (componentId: string) => void;
    onProcessPropertiesChange: (ProcessProperties: ProcessProperties) => void;
    onApplyProcessProperties: () => void;
    onApplyProcess: (index: number) => void;
    onOutputChange: (value: ValueElementIdAPI | null) => void;
}

interface ProcessToEdit {
    process: Process;
    index: number;
    isEditing: boolean;
}

interface ProcessPropertiesToEdit {
    processProperties: ProcessProperties | null;
    isEditing: boolean;
}

const Context = createContext<Context | undefined>(undefined);

const ProcessProvider = ({ defaultScreen, children }: Props) => {
    const {
        mapElement,
        setMapElement,
        onSwitchScreen,
        currentScreen,
    }: UseMapElement<ProcessScreens> = useMapElement();

    const [processNames, setProcessNames] = useState<Record<string, string>>({});

    const initialProcess: Process = {
        processId: '',
        accountId: '',
        order: 0,
        disabled: false,
        waitForProcessToFinish: true,
        outputs: [],
        processProperties: [],
        dynamicProcessProperties: [],
    };

    const [processToEdit, setProcessToEdit] = useState<ProcessToEdit>({
        process: initialProcess,
        index: 0,
        isEditing: false,
    });

    const [processPropertiesToEdit, setProcessPropertiesToEdit] = useState<ProcessPropertiesToEdit>(
        {
            isEditing: false,
            processProperties: null,
        },
    );

    const loadProcessNames = async () => {
        const result = await getAllProcessNames(
            mapElement.processes?.map((process) => ({
                accountId: process.accountId,
                Id: process.processId,
            })),
        );

        if (result) {
            setProcessNames(
                result.reduce(
                    (previousValue, currentValue) =>
                        Object.assign(previousValue, {
                            [currentValue.id]: currentValue.name,
                        }),
                    {},
                ),
            );
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (
            currentScreen === 'process' &&
            mapElement.processes &&
            mapElement.processes.filter(
                (process) => process.processId && !processNames[process.processId],
            ).length > 0
        ) {
            loadProcessNames();
        }
    }, [mapElement.processes]);

    const onCreateProcess = () => {
        const totalProcesses = mapElement.processes?.length ?? 0;

        setProcessToEdit({
            process: { ...initialProcess, order: totalProcesses },
            index: totalProcesses + 1,
            isEditing: false,
        });

        onSwitchScreen('processDetails');
    };

    const onEditProcess = (process: Process, index: number) => {
        setProcessToEdit({ process: omit(['selected'], process), index, isEditing: true });
        onSwitchScreen('processDetails');
    };

    const onDeleteProcess = (index: number) => {
        setMapElement({
            ...mapElement,
            processes: mapElement.processes?.filter((_, i) => i !== index) ?? null,
        });
    };

    const onProcessAndAccountChange = (processId: string | undefined, accountId: string) => {
        setProcessToEdit({
            ...processToEdit,
            process: { ...processToEdit.process, processId, accountId },
        });
    };

    const onWaitForProcessToFinishChange = (waitForProcessToFinish: boolean) => {
        setProcessToEdit({
            ...processToEdit,
            process: { ...processToEdit.process, waitForProcessToFinish },
        });
    };

    const onDisabledChange = (disabled: boolean) => {
        setProcessToEdit({
            ...processToEdit,
            process: { ...processToEdit.process, disabled },
        });
    };

    const onOrderChange = (order: number) => {
        setProcessToEdit({
            ...processToEdit,
            process: { ...processToEdit.process, order },
        });
    };

    const onDynamicProcessPropertiesChange = (
        dynamicProcessProperties: DynamicProcessProperty[],
    ) => {
        setProcessToEdit({
            ...processToEdit,
            process: { ...processToEdit.process, dynamicProcessProperties },
        });
    };

    const onAddProcessProperties = () => {
        onSwitchScreen('processProperties');
        setProcessPropertiesToEdit({
            isEditing: false,
            processProperties: null,
        });
    };

    const onEditProcessProperties = (componentId: string) => {
        setProcessPropertiesToEdit({
            isEditing: true,
            processProperties:
                processToEdit.process.processProperties.find(
                    (processProperties) => processProperties.componentId === componentId,
                ) || null,
        });
        onSwitchScreen('processProperties');
    };

    const onRemoveProcessProperties = (componentId: string) => {
        setProcessToEdit({
            ...processToEdit,
            process: {
                ...processToEdit.process,
                processProperties: processToEdit.process.processProperties.filter(
                    (processProperties) => processProperties.componentId !== componentId,
                ),
            },
        });
    };

    const onProcessPropertiesChange = (processProperties: ProcessProperties) => {
        setProcessPropertiesToEdit({
            ...processPropertiesToEdit,
            processProperties,
        });
    };

    const onApplyProcessProperties = () => {
        let newProcessProperties: ProcessProperties[] = [];

        if (processPropertiesToEdit.isEditing) {
            newProcessProperties = processToEdit.process.processProperties.map(
                (processProperties) => {
                    return processProperties.componentId ===
                        processPropertiesToEdit.processProperties?.componentId
                        ? processPropertiesToEdit.processProperties
                        : processProperties;
                },
            );
        } else {
            newProcessProperties = [...processToEdit.process.processProperties];

            if (processPropertiesToEdit.processProperties) {
                newProcessProperties.push(processPropertiesToEdit.processProperties);
            }
        }

        setProcessToEdit({
            ...processToEdit,
            process: {
                ...processToEdit.process,
                processProperties: newProcessProperties,
            },
        });

        onSwitchScreen('processDetails');
    };

    const onOutputChange = (value: ValueElementIdAPI | null) => {
        let outputs: ValueElementIdAPI[] = [];

        if (value) {
            outputs = [value];
        }

        setProcessToEdit({
            ...processToEdit,
            process: {
                ...processToEdit.process,
                outputs,
            },
        });
    };

    const onApplyProcess = (index: number) => {
        const processExists = mapElement.processes
            ? mapElement.processes.find((_, i) => i === index)
            : null;

        const processes = processExists
            ? mapElement.processes?.map((existingProcess, i) =>
                  i === index ? processToEdit.process : existingProcess,
              ) ?? null
            : [...(mapElement.processes ?? []), processToEdit.process];

        setMapElement({ ...mapElement, processes });
        onSwitchScreen(defaultScreen);
    };

    const contextValue = {
        processToEdit,
        processPropertiesToEdit,
        processNames,
        onCreateProcess,
        onEditProcess,
        onDeleteProcess,
        onProcessAndAccountChange,
        onWaitForProcessToFinishChange,
        onDisabledChange,
        onOrderChange,
        onDynamicProcessPropertiesChange,
        onAddProcessProperties,
        onEditProcessProperties,
        onRemoveProcessProperties,
        onProcessPropertiesChange,
        onApplyProcessProperties,
        onApplyProcess,
        onOutputChange,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useProcess = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useProcess must be used within a ProcessProvider');
    }
    return context;
};

export { ProcessProvider, useProcess };

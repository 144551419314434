import type { User } from '../../collaboration/CollaborationProvider';
import { initials, name } from './utils';

interface Props {
    user: User | null;
    index: number;
    onUserDoubleClick?: ((userId: string) => void) | undefined;
}

const UserCircle = ({ user, index, onUserDoubleClick }: Props) => (
    <div
        className={`collaboration-user collaboration-user-${index + 1}`}
        title={name(user)}
        onDoubleClick={onUserDoubleClick && user?.id ? () => onUserDoubleClick(user.id) : undefined}
    >
        {initials(user)}
    </div>
);

export default UserCircle;

import '../../../../css/buttons.less';
import translations from '../../translations';
import type { RecentlyAddedUser } from '../../types';
import FormGroup from '../generic/FormGroup';

interface RecentlyAddedUsersListProps {
    users: RecentlyAddedUser[];
    numberToDisplays?: number;
}

const RecentlyAddedUsersList = ({ users, numberToDisplays = 20 }: RecentlyAddedUsersListProps) => {
    const usersToDisplay = users
        .slice(0, numberToDisplays)
        .map((user, index) => (
            <div
                // biome-ignore lint/suspicious/noArrayIndexKey: Added users lack a unique ID, refactor required
                key={index}
                data-testid={`recently-added-user-${index}`}
                className="recently-added-user"
            >
                <div className="recently-added-user-detail">
                    {`${user.firstName} ${user.lastName}`}
                </div>
                <div className="recently-added-user-detail">{user.email}</div>
                <div className="recently-added-user-detail">{user.role.friendlyName}</div>
                <div className="recently-added-user-detail">{user.comments}</div>
            </div>
        ))
        .reverse();

    if (users.length === 0) {
        return (
            <div className="recently-added-users-message">
                {translations.TENANT_add_users_modal_recently_added_users_list_no_users_message}
            </div>
        );
    }

    return (
        <FormGroup
            label={translations.TENANT_add_users_modal_recently_added_users_list_label}
            className="recently-added-users-list-container"
        >
            <div data-testid="recently-added-users-list" className="recently-added-users-list">
                {usersToDisplay}
            </div>
        </FormGroup>
    );
};

export default RecentlyAddedUsersList;

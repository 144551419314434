import { Chart, type ChartData, type ChartOptions, type ChartType } from 'chart.js/auto';
import { type ComponentProps, type ElementRef, useEffect, useRef } from 'react';

interface GenericChartProps<TChart extends ChartType>
    extends Pick<ComponentProps<'canvas'>, 'width' | 'height'> {
    title: string;
    type: TChart;
    data: ChartData<TChart>;
    options: ChartOptions<TChart>;
}

/**
 * @param data datasets to be passed to chart instance
 * @param title Used to identify a specific chart
 * @param options Describes the chart config
 * @param type Defines the chart type e.g. line bar etc
 * @param height Chart height in pixels
 * @description A SFC component that renders ChartJS visualisations
 */
const GenericChart = <TChart extends ChartType>({
    data,
    title,
    options,
    type,
    height,
}: GenericChartProps<TChart>) => {
    const canvasRef = useRef<ElementRef<'canvas'>>(null);

    useEffect(() => {
        const context = canvasRef.current?.getContext('2d');

        if (!context) {
            return;
        }

        const chart = new Chart(context, {
            type,
            data,
            options,
        });

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            chart.destroy();
        };
    }, [data, options, type]);

    return (
        <>
            <h2>{title}</h2>
            <div style={{ position: 'relative', height }}>
                <canvas ref={canvasRef} data-testid="chart-canvas" />
            </div>
        </>
    );
};

export default GenericChart;

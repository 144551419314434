import type {
    Page,
    Tab,
    PageCondition,
    ValueElementIdReferenceAPI,
    PreviewMode,
    PageEditorState,
} from '../../../types';
import type { AuthenticatedUser } from '../../../types/auth';

export type PageAction =
    | {
          type: 'set_page';
          payload: Page;
      }
    | {
          type: 'set_page_preview';
          payload: PreviewMode | null;
      }
    | {
          type: 'set_page_tab';
          payload: Tab;
      }
    | {
          type: 'set_page_user';
          payload: AuthenticatedUser;
      }
    | {
          type: 'set_show_page_loader';
          payload: boolean;
      }
    | {
          type: 'set_show_custom_component_loader';
          payload: boolean;
      }
    | {
          type: 'set_show_metadata_editor';
          payload: boolean;
      }
    | {
          type: 'set_show_page_conditions_panel';
          payload: boolean;
      }
    | {
          type: 'set_page_conditions';
          payload: PageCondition[];
      }
    | {
          type: 'set_modified_page_condition';
          payload: boolean;
      }
    | {
          type: 'set_start_component_suggest';
          payload: ValueElementIdReferenceAPI | null;
      }
    | {
          type: 'set_stop_component_suggest';
      };

const PageEditorReducer = (state: PageEditorState, action: PageAction): PageEditorState => {
    switch (action.type) {
        case 'set_page':
            return { ...state, page: action.payload };

        case 'set_page_preview':
            return { ...state, preview: action.payload };

        case 'set_page_tab':
            return { ...state, tab: { ...state.tab, ...action.payload } };

        case 'set_page_user':
            return { ...state, user: { ...state.user, ...action.payload } };

        case 'set_show_page_loader':
            return { ...state, showPageLoader: action.payload };

        case 'set_show_custom_component_loader':
            return { ...state, showCustomPageComponentsLoader: action.payload };

        case 'set_show_metadata_editor':
            return { ...state, showMetadataEditor: action.payload };

        case 'set_show_page_conditions_panel':
            return { ...state, showPageConditions: action.payload };

        case 'set_page_conditions':
            return {
                ...state,
                page: {
                    ...state.page,
                    pageConditions: [...action.payload],
                },
            };

        case 'set_start_component_suggest':
            return {
                ...state,
                showComponentSuggest: true,
                componentSuggestValue: action.payload,
            };

        case 'set_stop_component_suggest':
            return {
                ...state,
                showComponentSuggest: false,
                componentSuggestValue: null,
            };

        case 'set_modified_page_condition':
            return {
                ...state,
                modifiedPageCondition: action.payload,
            };

        default:
            return state;
    }
};

export default PageEditorReducer;

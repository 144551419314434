import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const CheckboxList = ({ label, isRequired }: { label: string; isRequired: boolean }) => {
    return (
        <div
            data-testid="component-preview-checkbox-list"
            className="component-preview-input component-preview-checkbox-list"
        >
            <div className="form-group">
                <PreviewComponentLabel labelText={label} isRequired={isRequired} />
                <div className="checkbox">
                    <label>
                        <input type="checkbox" name="example checkbox" />
                        <span>Checkbox 1</span>
                    </label>
                </div>
                <div className="checkbox">
                    <label>
                        <input type="checkbox" name="example checkbox" />
                        Checkbox 2
                    </label>
                </div>
                <div className="checkbox">
                    <label>
                        <input type="checkbox" name="example checkbox" />
                        Checkbox 3
                    </label>
                </div>
            </div>
        </div>
    );
};

export default CheckboxList;

import type { ServiceElementFeatures } from '../../../types/service';

interface FeatureListProps {
    serviceFeatures: ServiceElementFeatures;
}

const featureDisplayTextMap = {
    providesAutoBinding: 'Auto Binding',
    providesDatabase: 'Database',
    providesFiles: 'Files',
    providesIdentity: 'Identity',
    providesLocation: 'Location',
    providesLogic: 'Messages',
    providesViews: 'Views',
} as const;

/**
 * @param {Object} serviceFeatures = {} - Object containing all SERVICE_FEATURES keys with boolean values
 */
const FeatureList = ({ serviceFeatures = {} as ServiceElementFeatures }: FeatureListProps) => (
    <ul className="inline-list">
        {Object.entries(featureDisplayTextMap).map(([key, value]) => (
            <li key={key}>
                {serviceFeatures[key as keyof ServiceElementFeatures] ? (
                    <span className="glyphicon glyphicon-check" />
                ) : (
                    <span className="glyphicon glyphicon-unchecked" />
                )}
                <span className="margin-left-sml">{value}</span>
            </li>
        ))}
    </ul>
);

export default FeatureList;

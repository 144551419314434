import classNames from 'classnames';
import { getElementStyles } from '../../elementStyles';

/**
 *
 * @param {Object} mapElement The map element metadata
 * @param {Object} styles Defined element styling
 *
 * @description The draggable element that appears on the Flow canvas to represent a note
 */
const NoteElement = ({
    mapElement = {},
    styles,
    isSelected,
    isDragging,
    startDrag,
    onSelect,
    onDoubleClick,
    keyboardDragging,
    isColorFillMode,
    selectedElementIds,
}) => {
    const { id } = mapElement;

    const elementStyles = styles || getElementStyles(mapElement.elementType);

    const selectNote = (e) => {
        onSelect(e, selectedElementIds);
    };

    return (
        <g
            id={id}
            data-testid={`element-${id}`}
            onMouseUp={selectNote}
            onDoubleClick={onDoubleClick}
            onMouseDown={startDrag}
        >
            {isSelected && (!isDragging || keyboardDragging) && (
                <rect {...elementStyles.outBorder} data-testid="map-element-selected" />
            )}
            <rect {...elementStyles.border} className="map-element-border" />
            <rect {...elementStyles.fill} id={`element-inner-${id}`} className="map-element-fill" />
            {
                <foreignObject {...elementStyles.content}>
                    <span
                        title="Double-click to show/hide contents"
                        className={classNames({
                            'safari-fix': true,
                            'map-element-content': true,
                            'note-element-content': true,
                            'color-white': isColorFillMode,
                        })}
                    >
                        <elementStyles.icon />
                    </span>
                </foreignObject>
            }
        </g>
    );
};

export default NoteElement;

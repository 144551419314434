import actionTypes from './actionTypes';

export const addNote = (note, flowId, newNoteData) => ({
    type: actionTypes.NOTE_ADD,
    payload: { note, flowId, newNoteData },
});

export const updateNote = (note) => ({
    type: actionTypes.NOTE_UPDATE,
    payload: note,
});

export const editNote = (id) => ({
    type: actionTypes.NOTE_EDIT,
    payload: { id },
});

export const toggleNoteVisibility = (id) => ({
    type: actionTypes.NOTE_TOGGLE_VISIBILITY,
    payload: { id },
});

export const setAllNotes = (items, flowId) => ({
    type: actionTypes.NOTE_SET_ALL,
    payload: { items, flowId },
});

export const deleteNote = (id) => ({
    type: actionTypes.NOTE_DELETE,
    payload: id,
});

export const deleteMultipleNotes = (ids) => ({
    type: actionTypes.NOTE_DELETE_MULTIPLE,
    payload: ids,
});

import { memo } from 'react';
import type { Point } from '../../types';

interface Props extends Point {
    id: string;
}

const Background = ({ x, y, id }: Props) => {
    return (
        <>
            <defs>
                <pattern id={id} patternUnits="userSpaceOnUse" width="10" height="10">
                    <rect x="0" y="0" width="10" height="10" fill="white" />
                    <circle cx="0" cy="0" r="1" fill="#ddd" />
                    <circle cx="0" cy="10" r="1" fill="#ddd" />
                    <circle cx="10" cy="0" r="1" fill="#ddd" />
                    <circle cx="10" cy="10" r="1" fill="#ddd" />
                </pattern>
            </defs>
            <rect fill={`url(#${id})`} x={x} y={y} width="100%" height="100%" />
        </>
    );
};

export default memo(Background);

import { COLOUR_MAPPINGS } from '../../../../js/components/graph/elements/elementStyles';

interface Props {
    x: number;
    y: number;
    value: number | undefined;
    isLoading: boolean | undefined;
}

const OutcomeTrafficPercentage = ({ x, y, value, isLoading }: Props) => {
    if (!isLoading && value === undefined) {
        return null;
    }
    return (
        <text className="outcome-text" x={x} y={y + 20} fill={COLOUR_MAPPINGS.outcome}>
            {isLoading ? 'Loading...' : `${value || 0}% traffic`}
        </text>
    );
};
export default OutcomeTrafficPercentage;

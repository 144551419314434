import type { ReleasedSnapshot } from '../../../types';
import type { ReactNode } from 'react';
import classNames from 'classnames';
import { getCreator } from './utils';
import UserCircle from '../../collaboration/UserCircle';
import EnvironmentBadge from './EnvironmentBadge';
import type { Environment } from '../../../types/environment';
import ReleaseDate from './ReleaseDate';

export interface UserOrder {
    id: string;
    index: number;
}

interface Props {
    snapshot: ReleasedSnapshot;
    environments: Environment[];
    isDefault?: boolean;
    selected?: boolean;
    userList: string[];
    isCompareMode: boolean;
    selectForCompare: (snapshot: ReleasedSnapshot) => void;
    children: ReactNode;
}

const SnapshotCard = ({
    snapshot,
    environments,
    userList,
    isCompareMode,
    selectForCompare,
    isDefault = false,
    selected = false,
    children,
}: Props) => {
    const creator = getCreator(snapshot);
    const snapshotContents = (
        <>
            <UserCircle
                user={creator}
                index={creator ? userList.indexOf(creator.id) : userList.length}
            />
            <span className="content">
                {/* Release name and environment pill */}
                <span className="release-name-and-environment">
                    <b>{snapshot.releaseName}</b>
                    <EnvironmentBadge environments={environments} snapshot={snapshot} />
                </span>
                {/* snapshot date and time ago */}
                <span className="summary" title={snapshot.comment ?? ''}>
                    <ReleaseDate snapshot={snapshot} />
                </span>
                {/* Buttons (they disappear when comparing) */}
                {!isCompareMode && children}
            </span>
        </>
    );

    return isCompareMode ? (
        <button
            data-testid={`compare-button-${snapshot.releaseId}`}
            type="button"
            className={classNames({
                snapshot: true,
                release: true,
                comparable: true,
                selected,
                default: isDefault,
            })}
            onClick={() => selectForCompare(snapshot)}
        >
            {snapshotContents}
        </button>
    ) : (
        <div
            data-testid={`snapshot-${snapshot.releaseId}`}
            className={classNames({
                snapshot: true,
                release: true,
                default: isDefault,
            })}
        >
            {snapshotContents}
        </div>
    );
};

export default SnapshotCard;

/**
 * In the store a canvas note takes the following structure;
 
    {
        id, // Map element ID
        meta, // The actual map element metadata
        flowId, // The flow the note is a member of
        isOpen, // Determines whether the note content is displayed
        readOnly, // Determines whether the note content can be modified
    }

 */

import actionTypes from '../actions/reduxActions/actionTypes';
import { getByID } from '../../ts/utils/collection';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const canvasNotes = (notes = [], { type, payload }) => {
    switch (type) {
        // biome-ignore lint/suspicious/noFallthroughSwitchClause: Falling through expected
        case actionTypes.MAP_ELEMENT_MOVE: {
            const { elementId, x, y } = payload;
            const movedNote = getByID(elementId, notes);
            if (!movedNote) {
                return notes;
            }
            payload = { ...movedNote.meta, x, y };
        }
        case actionTypes.NOTE_UPDATE:
        case actionTypes.MAP_ELEMENT_SET: {
            // Listening for both events to keep notes store in sync
            const note = payload;
            if (note.elementType === 'note') {
                return notes.map((n) =>
                    n.id === note.id
                        ? {
                              ...n,
                              isNew: false,
                              meta: note,

                              // This makes sure the note goes back to read only view when saved
                              readOnly: type === actionTypes.MAP_ELEMENT_SET ? n.readOnly : true,
                          }
                        : n,
                );
            }

            return notes;
        }

        case actionTypes.NOTE_ADD: {
            const { note, flowId, newNoteData } = payload;
            const newNote = {
                id: note.id,
                flowId,
                isOpen: true,
                isNew: true,
                readOnly: false,
                ...newNoteData,
                meta: { ...note, ...newNoteData.meta },
            };
            //If the note id is already in the list make sure to filter it out before adding this note.
            return [...notes.filter((x) => x.id !== note.id), newNote];
        }

        case actionTypes.NOTE_SET_ALL: {
            const { items, flowId } = payload;
            const notesToSet = items.map((item) => ({
                id: item.id,
                meta: item,
                flowId,
                isNew: false,
                isOpen: false,
                readOnly: true,
            }));

            return [...notes.filter((note) => note.flowId !== flowId), ...notesToSet];
        }

        case actionTypes.NOTE_EDIT: {
            const { id } = payload;
            return notes.map((note) =>
                note.id === id ? { ...note, readOnly: !note.readOnly } : note,
            );
        }

        case actionTypes.NOTE_TOGGLE_VISIBILITY: {
            const { id } = payload;
            return notes.map((note) => (note.id === id ? { ...note, isOpen: !note.isOpen } : note));
        }

        case actionTypes.NOTE_DELETE:
            return notes.filter((note) => note.id !== payload);

        case actionTypes.NOTE_DELETE_MULTIPLE:
            return notes.filter((note) => !payload.includes(note.id));

        default:
            return notes;
    }
};

export default canvasNotes;

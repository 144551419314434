import { useEffect, useRef } from 'react';
import Loader from '../../../../../ts/components/loader/Loader';
import translations from '../../../../../ts/translations';
import ConfigPanel from './composer/config-panel/ConfigPanel';
import Toolbar from '../../../../../ts/components/page-editor/components/gui/toolbar/Toolbar';
import Metadata from '../../../../../ts/components/page-editor/components/gui/toolbar/Metadata';
import { useAuth } from '../../../../../ts/components/AuthProvider';
import ComponentSuggest from '../../../../../ts/components/page-editor/components/gui/componentSuggest/ComponentSuggest';
import Composer from './composer/Composer';
import { usePageEditor } from '../../../../../ts/components/page-editor/components/PageEditorProvider';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { Tab } from '../../../../types';
import { useDocumentKeyDown } from '../../../../hooks';

const Main = ({ tabConfig }: { tabConfig: Tab | null }) => {
    const { state, setPageTab, setPageUser, onSave, isLoading, setShowMetadataEditor } =
        usePageEditor();

    const { user } = useAuth();

    const { page, tab, showMetadataEditor, showComponentSuggest } = state;
    const { showConfirmation, elementId, isActive } = tab || {};

    const mainContainerRef = useRef(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        setPageUser({ ...user });
    }, [user]);

    // Save a copy of the tab for the same reason, so we can get our hands on the key and elementId
    // without going to Redux or passing the tab config down several layers deep.
    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (tabConfig) {
            setPageTab(tabConfig);
        }
    }, [tabConfig]);

    const handleKeyDown = () => {
        // Save the currently active page when there are changes and user triggers the CTRL+S hotkey.
        page.id === elementId && isActive && showConfirmation && onSave(page);
    };

    useDocumentKeyDown('CTRL+S', mainContainerRef.current, handleKeyDown);

    if (isLoading) {
        return <Loader message={translations.PAGE_BUILDER_loading_page} />;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <main ref={mainContainerRef} className="main-wrapper" data-testid="main-wrapper">
                <Toolbar />
                <Composer />
                <ConfigPanel ref={mainContainerRef} />
                {showMetadataEditor && page.id && (
                    <Metadata showModal={setShowMetadataEditor} pageId={page.id} />
                )}
                {showComponentSuggest && <ComponentSuggest />}
            </main>
        </DndProvider>
    );
};

export default Main;

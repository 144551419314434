import screens from '../common/screens';
import { MapElementProvider } from '../contextProviders/MapElementProvider';
import { WaitProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/WaitProvider';
import WaitConfiguration from './WaitConfiguration';
import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';

/**
 * The wait config screen
 */
const Wait = (props) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.wait}
            elementType={MAP_ELEMENT_TYPES.wait}
        >
            <MapElementModal>
                <WaitProvider>
                    <WaitConfiguration />
                </WaitProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Wait;

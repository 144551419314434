import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import freeze from 'redux-freeze';
import thunk from 'redux-thunk';
// biome-ignore lint/style/noNamespaceImport: All exports are used, so tree-shaking isn't applicable
import * as reducers from '../reducers';

let store = null;

// Creates and exports the application store from all reducers
// All redux middleware is added here
// The store state should not be accessed or changed directly
// Use the getState method to view the current state
// All changes to state must go through an action and reducer

const reduxMiddleware = [thunk];

// This freezes the entire state object to ensure all state mutations are made immutably
reduxMiddleware.push(freeze);

export const reducer = combineReducers(reducers);
export const enhancers = composeWithDevTools(applyMiddleware(...reduxMiddleware));

export const createStoreWithInitialState = (initialState) =>
    createStore(reducer, initialState, enhancers);

export default store ||
    (() => {
        // First run so create and return it
        store = createStore(reducer, enhancers);
        return store;
    })();

import type { DispatchFlowConfiguration } from './reducer';

interface Props {
    isHistoricalNavigationEnabled: boolean;
    allowJumping: boolean;
    dispatchFlowConfiguration: DispatchFlowConfiguration;
}

const Navigation = ({
    isHistoricalNavigationEnabled,
    allowJumping,
    dispatchFlowConfiguration,
}: Props) => {
    return (
        <>
            <h2>Navigation</h2>
            <label htmlFor="properties-historic-nav">
                <input
                    id="properties-historic-nav"
                    className="checkbox-margin-left"
                    type="checkbox"
                    checked={isHistoricalNavigationEnabled}
                    onChange={({ target }) => {
                        dispatchFlowConfiguration({
                            type: 'updateHistoricalNavigation',
                            payload: { isEnabled: target.checked },
                        });
                    }}
                />
                Enable historical breadcrumb navigation
            </label>
            <br />
            <label htmlFor="properties-allow-jumping">
                <input
                    id="properties-allow-jumping"
                    className="checkbox-margin-left"
                    type="checkbox"
                    checked={allowJumping}
                    onChange={({ target }) => {
                        dispatchFlowConfiguration({
                            type: 'updateAllowJumping',
                            payload: { isEnabled: target.checked },
                        });
                    }}
                />
                Allow the user to move to any Map Element location in the Flow
            </label>
        </>
    );
};

export default Navigation;

import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const Text = ({
    label,
    isRequired,
    isEditable,
}: {
    label: string;
    isRequired: boolean;
    isEditable: boolean;
}) => {
    return (
        <div data-testid="component-preview-textarea" className="component-preview-textarea">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            <textarea rows={5} disabled={!isEditable} />
        </div>
    );
};

export default Text;

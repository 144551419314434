import { useMapElement } from '../../../../../js/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import translations from '../../../../translations';
import type { Process, ProcessScreens, UseMapElement } from '../../../../types';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import Glyphicon from '../../../generic/Glyphicon';
import { useProcess } from '../contextProviders/ProcessProvider';
import { Trash } from '@phosphor-icons/react';
import DraggableTable from '../../../generic/DraggableTable';

const ProcessList = () => {
    const { mapElement, setMapElement }: UseMapElement<ProcessScreens> = useMapElement();
    const { processNames, onCreateProcess, onEditProcess, onDeleteProcess } = useProcess();

    const onReorderProcesses = (processes: Process[]) => {
        // JSON patch doesn't handle re-ordering complex objects in an array (it creates a lot of operations and
        // the c# backend throws an exception), we only change the order property on the existing
        // array items instead

        const orders: Record<string, number> = processes.reduce(
            (previous, current, index) => {
                previous[current.processId as string] = index;

                return previous;
            },
            {} as Record<string, number>,
        );

        setMapElement({
            ...mapElement,
            processes:
                mapElement.processes?.map((process) => {
                    return {
                        ...process,
                        order: orders[process.processId as string],
                    } as Process;
                }) ?? null,
        });
    };

    const columns = [
        {
            renderHeader: () => translations.PROCESS_list_column_name,
            renderCell: ({ item, rowIndex }: { item: Process; rowIndex: number }) => (
                <button
                    className="link-emulate overflow-ellipsis"
                    onClick={() => onEditProcess(item, rowIndex)}
                    type="button"
                >
                    {item.processId ? processNames[item.processId] : ''}
                </button>
            ),
        },
        {
            renderHeader: () => translations.PROCESS_list_column_accountId,
            renderCell: ({ item }: { item: Process }) => item.accountId ?? '',
        },
        {
            renderHeader: () => translations.PROCESS_list_column_order,
            renderCell: ({ item }: { item: Process }) => item.order ?? '',
            size: '5rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item, rowIndex }: { item: Process; rowIndex: number }) => {
                const processName = item.processId ? processNames[item.processId] || '' : '';

                return (
                    <div className="action-btn-wrapper">
                        <button
                            title={`${translations.COMMON_delete} ${processName}`}
                            className="table-icon table-icon-delete"
                            onClick={() => onDeleteProcess(rowIndex)}
                            aria-label={`${translations.COMMON_delete} ${processName}`}
                            type="button"
                        >
                            <Trash />
                        </button>
                    </div>
                );
            },
            size: '8rem',
        },
    ];

    return (
        <>
            <h4>{translations.PROCESS_list_heading}</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onCreateProcess}>
                    <Glyphicon glyph="plus" />
                    {translations.PROCESS_list_add}
                </ButtonPrimary>
            </div>
            <DraggableTable<Process>
                columns={columns}
                tableClass="generic-table margin-top"
                items={mapElement.processes ?? []}
                testId="process-list"
                tableId="process-list"
                onReorder={onReorderProcesses}
            />
        </>
    );
};

export default ProcessList;

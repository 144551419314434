import {
    ExIcon,
    ExPagination,
    ExStructuredList,
    ExStructuredListBody,
    ExStructuredListCol,
    ExStructuredListRow,
    IconVariant,
} from '@boomi/exosphere';
import translations from '../../translations';
import { useAnomalyDashboard } from './AnomalyProvider';
import { ANOMALY_TYPE_ERROR, ANOMALY_TYPE_TIME, ANOMALY_TYPE_USAGE } from '../../constants/anomaly';
import CopyableText from '../generic/CopyableText';
import { generateRouteUrl, getTenantId } from '../../utils';
import { TAB_TYPES } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { timeIcon, usageIcon, errorIcon } from './AnomalyIcons';
import GenericModal from '../generic/modal/GenericModal';
import SyntaxHighlighter from '../generic/SyntaxHighlighter';
import FooterButtons from '../generic/modal/FooterButtons';
import { useState } from 'react';
import type { AnomalyResponse } from '../../sources/anomalyEvents';
import { convertDateToUnixTime } from '../../utils/dashboard';

interface ExDetail {
    selectedPage?: number;
    pageSize?: number;
}

export const getAnomalyDetails = (anomaly: AnomalyResponse) => {
    const anomalyDetails =
        anomaly.anomalyType === ANOMALY_TYPE_ERROR
            ? anomaly.latestErrorMessage
            : anomaly.anomalyType === ANOMALY_TYPE_TIME
              ? `${anomaly.eventValue}${translations.ANOMALY_detail_time_spent}`
              : anomaly.anomalyType === ANOMALY_TYPE_USAGE
                ? `${anomaly.eventValue}${translations.ANOMALY_detail_usage}`
                : '';

    const deviationAbsolute = Math.abs(anomaly.deviation);
    const deviationPositive = anomaly.deviation > 0;
    const deviationWord = deviationPositive
        ? translations.ANOMALY_reason_more
        : translations.ANOMALY_reason_less;
    const reasonMiddleWords =
        anomaly.anomalyType === ANOMALY_TYPE_ERROR
            ? translations.ANOMALY_reason_errors
            : anomaly.anomalyType === ANOMALY_TYPE_TIME
              ? translations.ANOMALY_reason_time
              : anomaly.anomalyType === ANOMALY_TYPE_USAGE
                ? translations.ANOMALY_reason_usage
                : '';

    // e.g. 50% more usage than normal
    const anomalyDeviationText = `${deviationAbsolute}% ${deviationWord} ${reasonMiddleWords}${translations.ANOMALY_reason_than_average}`;
    return {
        anomalyDeviationText,
        anomalyDetails,
        deviationAbsolute,
        deviationPositive,
    };
};

const AnomalyOverview = ({ container }: { container: HTMLElement | null }) => {
    const { filteredData, dateIndexFilter, paginatedData, pageSize, page, changePagination } =
        useAnomalyDashboard();

    const [viewingError, setViewingError] = useState<AnomalyResponse>();

    const navigate = useNavigate();

    return (
        <>
            <h2>
                {translations.ANOMALY_table_title}{' '}
                {
                    [
                        translations.ANOMALY_day,
                        translations.ANOMALY_3_days,
                        translations.ANOMALY_7_days,
                    ][dateIndexFilter]
                }
            </h2>
            <span className="table-header">
                <span>{translations.ANOMALY_table_header_type}</span>
                <span>{translations.ANOMALY_table_header_details}</span>
                <span>{translations.COMMON_TABLE_state_id}</span>
                <span>{translations.COMMON_TABLE_flow_name}</span>
                <span>{translations.ANOMALY_table_header_map_element}</span>
                <span>{translations.ANOMALY_date_time}</span>
            </span>
            <ExStructuredList data-testid="anomalyList">
                <ExStructuredListBody>
                    {paginatedData
                        .sort(
                            (dA, dB) =>
                                convertDateToUnixTime(dB.dateTime) -
                                convertDateToUnixTime(dA.dateTime),
                        )
                        .map((d) => {
                            const errorViewButton = d.anomalyType === ANOMALY_TYPE_ERROR && (
                                <button
                                    className="link-emulate"
                                    onClick={() => setViewingError(d)}
                                    type="button"
                                >
                                    {translations.COMMON_view}
                                </button>
                            );

                            const {
                                anomalyDeviationText,
                                anomalyDetails,
                                deviationAbsolute,
                                deviationPositive,
                            } = getAnomalyDetails(d);

                            return (
                                <ExStructuredListRow key={JSON.stringify(d)}>
                                    <ExStructuredListCol>
                                        <span className="type-icon">
                                            {d.anomalyType === ANOMALY_TYPE_ERROR
                                                ? errorIcon
                                                : d.anomalyType === ANOMALY_TYPE_TIME
                                                  ? timeIcon
                                                  : d.anomalyType === ANOMALY_TYPE_USAGE
                                                    ? usageIcon
                                                    : null}
                                        </span>
                                    </ExStructuredListCol>
                                    <ExStructuredListCol>
                                        <span className="details-section">
                                            <span className="details-summary">
                                                <span
                                                    className="details-deviation"
                                                    title={anomalyDeviationText}
                                                >
                                                    {deviationPositive ? '+' : '-'}
                                                    {deviationAbsolute}%
                                                    <ExIcon
                                                        className="margin-left-sml"
                                                        label={anomalyDeviationText}
                                                        icon={
                                                            deviationPositive
                                                                ? 'Up caret'
                                                                : 'Down caret'
                                                        }
                                                        variant={
                                                            deviationPositive
                                                                ? IconVariant.SECONDARY
                                                                : IconVariant.DANGER
                                                        }
                                                    />
                                                </span>
                                                <span
                                                    className="details-text quiet"
                                                    title={anomalyDetails}
                                                >
                                                    {anomalyDetails}
                                                </span>
                                            </span>
                                            {errorViewButton}
                                        </span>
                                    </ExStructuredListCol>
                                    <ExStructuredListCol>
                                        <CopyableText copyableText={d.stateId} />
                                    </ExStructuredListCol>
                                    <ExStructuredListCol>
                                        <button
                                            className="link-emulate"
                                            onClick={() => {
                                                const route = generateRouteUrl({
                                                    tabType: TAB_TYPES.flow,
                                                    tenantId: getTenantId(),
                                                    options: {
                                                        elementId: d.flowId,
                                                    },
                                                });

                                                if (!route) {
                                                    return;
                                                }

                                                navigate(route);
                                            }}
                                            type="button"
                                        >
                                            {d.flowName}
                                        </button>
                                        <CopyableText
                                            copyableText={d.flowId}
                                            textClassName="quiet"
                                        />
                                    </ExStructuredListCol>
                                    <ExStructuredListCol>
                                        {d.mapElementName}
                                        <CopyableText
                                            copyableText={d.mapElementId}
                                            textClassName="quiet"
                                        />
                                    </ExStructuredListCol>
                                    <ExStructuredListCol>
                                        <CopyableText
                                            copyableText={new Date(d.dateTime).toLocaleString(
                                                undefined,
                                                {
                                                    dateStyle: 'medium',
                                                    timeStyle: 'medium',
                                                },
                                            )}
                                        />
                                    </ExStructuredListCol>
                                </ExStructuredListRow>
                            );
                        })}
                </ExStructuredListBody>
            </ExStructuredList>
            <ExPagination
                totalItems={filteredData.length}
                pageSize={pageSize ?? 15}
                selectedPage={page}
                onChange={(e: CustomEvent | undefined) => changePagination(e?.detail as ExDetail)}
                pageSizeOptions={[15, 25, 50, 100]}
            />
            <GenericModal
                className="metadata-editor"
                show={!!viewingError}
                onHide={() => setViewingError(undefined)}
                title={translations.ANOMALY_error_modal_title}
                renderBody={() => (
                    <SyntaxHighlighter
                        content={
                            viewingError
                                ? JSON.stringify(
                                      {
                                          uri: viewingError.latestErrorUri,
                                          message: viewingError.latestErrorMessage,
                                          statusCode: viewingError.latestErrorStatusCode,
                                      },
                                      null,
                                      '\t',
                                  )
                                : ''
                        }
                        language="json"
                    />
                )}
                renderFooter={() => (
                    <FooterButtons
                        cancel={() => setViewingError(undefined)}
                        cancelButtonText={translations.COMMON_close}
                    />
                )}
                container={container}
            />
        </>
    );
};

export default AnomalyOverview;

import classNames from 'classnames';
import translations from '../../../translations';

interface Props {
    confirm?: () => void;
    confirmButtonText?: string;
    confirmButtonClasses?: string;
    cancel?: () => void;
    cancelButtonText?: string;
    cancelButtonClasses?: string;
}

const FooterButtons = ({
    confirm,
    confirmButtonText,
    confirmButtonClasses = 'btn-primary',
    cancel,
    cancelButtonText,
    cancelButtonClasses = 'btn-default',
}: Props) => {
    const confirmClasses = classNames('margin-left btn', confirmButtonClasses);
    const cancelClasses = classNames('flex-child-right btn', cancelButtonClasses);

    return (
        <>
            {cancel ? (
                <button className={cancelClasses} onClick={cancel} type="button">
                    {cancelButtonText ?? translations.GRAPH_config_panel_cancel}
                </button>
            ) : null}

            {confirm ? (
                <button className={confirmClasses} onClick={confirm} type="button">
                    {confirmButtonText ?? translations.GRAPH_config_panel_save}
                </button>
            ) : null}
        </>
    );
};

export default FooterButtons;

import { useEffect } from 'react';
import { type Root, createRoot } from 'react-dom/client';
import type { ThemeEdit, ThemeProperties } from '../../../types';
import Table from './components/Table';
import Input from './components/Input';

class PreviewElement extends HTMLElement {
    reactRoot: Root | null = null;
    stylesheetLoaded = false;
    stylesheetError = false;

    static get observedAttributes() {
        return ['properties', 'logourl'];
    }

    propertiesStrToObj(propertiesStr: string) {
        const propertiesObj = propertiesStr?.split(';').reduce((obj, propStr) => {
            const [key, value] = propStr.split(':');
            if (key !== '') {
                obj[key as keyof ThemeProperties] = value;
            }
            return obj;
        }, {} as ThemeProperties);

        return propertiesObj;
    }

    attributeChangedCallback() {
        this.render();
    }

    connectedCallback() {
        const shadowRoot = this.attachShadow({ mode: 'open' });

        const mountPoint = document.createElement('div');
        shadowRoot.appendChild(mountPoint);

        const linkElem = document.createElement('link');
        linkElem.setAttribute('rel', 'stylesheet');
        linkElem.setAttribute(
            'href',
            'https://us-assets.flow-prod.boomi.com/runtime/nextgen/assets/flow.css',
        );
        linkElem.addEventListener('load', () => this.onStylesheetLoaded.call(this));
        linkElem.addEventListener('error', () => this.onStylesheetError.call(this));

        shadowRoot.appendChild(linkElem);

        this.reactRoot = createRoot(mountPoint);

        this.render();
    }

    onStylesheetLoaded() {
        this.stylesheetLoaded = true;
        this.render();
        return null;
    }

    onStylesheetError() {
        this.stylesheetError = true;
        this.render();
        return null;
    }

    render() {
        const propertiesStr = this.getAttribute('properties');
        const propertiesObj = this.propertiesStrToObj(propertiesStr ?? '');

        const logoURL = this.getAttribute('logourl');
        const hasLogo = typeof logoURL === 'string' && logoURL.length > 0;

        if (this.reactRoot !== null) {
            if (!this.stylesheetLoaded) {
                this.reactRoot.render(
                    <div style={{ padding: '30px', fontSize: '18px', textAlign: 'center' }}>
                        Loading preview
                    </div>,
                );
            } else if (this.stylesheetError) {
                this.reactRoot.render(
                    <div style={{ padding: '30px', fontSize: '18px', textAlign: 'center' }}>
                        Could not load stylesheet
                    </div>,
                );
            } else {
                this.reactRoot.render(
                    <div
                        className="flow nav-horizontal"
                        style={propertiesObj as React.CSSProperties}
                    >
                        {hasLogo && (
                            <header className="page-header">
                                <img src={logoURL} alt="" className="header-image" />
                            </header>
                        )}
                        <section className="content">
                            <div className="container container-vertical">
                                <Input />
                                <Table />
                            </div>
                            <div className="outcomes ">
                                <button className="button button-outcome" type="button">
                                    Secondary
                                    <span className="loader" />
                                </button>
                                <button className="button button-outcome save" type="button">
                                    Primary
                                    <span className="loader" />
                                </button>
                            </div>
                        </section>
                    </div>,
                );
            }
        }
    }
}

const Preview = ({ theme }: { theme: ThemeEdit }) => {
    useEffect(() => {
        if (!customElements.get('runtime-preview')) {
            customElements.define('runtime-preview', PreviewElement);
        }
    }, []);

    const propertiesStr = Object.entries(theme.properties ?? {}).reduce((str, [key, value]) => {
        return `${str}${key}:${value as string};`;
    }, '');

    return (
        <runtime-preview
            properties={propertiesStr}
            logourl={theme.logoURL ?? ''}
            data-testid="runtime-preview"
        />
    );
};

export default Preview;

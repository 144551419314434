import { useState } from 'react';
import Select, { type SingleValue } from 'react-select';
import FormGroup from '../../../../../../../../generic/FormGroup';
import ConfigSection from '../../../ConfigSection';
import FilterWhereList from './FilterWhereList';
import FilterWhereEditor from './FilterWhereEditor';
import ScreenRouter from '../../../../../../../../generic/ScreenRouter';
import type { ListFilter, ColumnOption } from '../../../../../../../../../types';
import { getSharedStyles } from '../../../../../../../../../utils/select';

const DETAIL_VIEW = 'DETAIL_VIEW';
const LIST_VIEW = 'LIST_VIEW';

const compareOptions: { value: string; label: string }[] = [
    { value: 'AND', label: 'AND' },
    { value: 'OR', label: 'OR' },
];

interface Props {
    columns: ColumnOption[];
    dataSourceFilter: ListFilter | null;
    updateDataSourceFilter: (filter: ListFilter) => void;
}

const FilterWhereConditionConfig = ({
    columns,
    dataSourceFilter,
    updateDataSourceFilter,
}: Props) => {
    const listFilter = dataSourceFilter;
    const whereFilter = listFilter?.where;
    const compareOption = listFilter?.comparisonType
        ? compareOptions.filter((ct) => ct.value === listFilter?.comparisonType)[0]
        : null;

    const [editedConditionIndex, setEditedConditionIndex] = useState<number | null>(null);
    const [screen, setScreen] = useState(LIST_VIEW);

    const updateCompare = (selectedCompare: SingleValue<{ value: string; label: string }>) => {
        if (listFilter) {
            const updated = {
                ...listFilter,
                comparisonType: selectedCompare?.value as string,
            };
            updateDataSourceFilter(updated);
        }
    };

    const listEdit = (conditionIndex: number) => {
        setScreen(DETAIL_VIEW);
        setEditedConditionIndex(conditionIndex);
    };

    const listCreate = () => {
        setScreen(DETAIL_VIEW);
    };

    const listDelete = (conditionIndex: number) => {
        if (listFilter) {
            const updated = {
                ...listFilter,
                where: whereFilter?.filter((_, index) => index !== conditionIndex) || null,
            };
            updateDataSourceFilter(updated);
        }
    };

    const editorCancel = () => {
        setScreen(LIST_VIEW);
        editedConditionIndex !== null && setEditedConditionIndex(null);
    };

    return (
        <>
            <ConfigSection>
                <ScreenRouter currentScreen={screen}>
                    <FilterWhereList
                        screen={LIST_VIEW}
                        listItems={dataSourceFilter?.where || null}
                        editItem={listEdit}
                        deleteItem={listDelete}
                        createItem={listCreate}
                    />
                    <FilterWhereEditor
                        screen={DETAIL_VIEW}
                        dataSourceFilter={dataSourceFilter}
                        selectedItemIndex={editedConditionIndex}
                        columnOptions={columns}
                        cancel={editorCancel}
                        save={updateDataSourceFilter}
                    />
                </ScreenRouter>

                {/* Compare select. Let users pick between AND/OR when we have 2 or more conditions,
                otherwise the default will be AND. */}
                {whereFilter && whereFilter?.length > 1 ? (
                    <FormGroup
                        label="Combine multiple WHERE conditions using"
                        htmlFor="filter-compare"
                    >
                        <Select
                            inputId="filter-compare"
                            className="select-field"
                            styles={getSharedStyles<{ label: string; value: string }>()}
                            options={compareOptions}
                            onChange={(selectedCompare) => updateCompare(selectedCompare)}
                            placeholder="Select comparison type"
                            value={compareOption}
                            noOptionsMessage={() => 'No results found'}
                        />
                    </FormGroup>
                ) : (
                    <></>
                )}
            </ConfigSection>
        </>
    );
};

export default FilterWhereConditionConfig;

import ConfigSection from '../../ConfigSection';
import DataSets from './DataSets';
import ScreenRouter from '../../../../../../../generic/ScreenRouter';
import NewDataSet from './NewDataSet';
import FormGroup from '../../../../../../../generic/FormGroup';
import translations from '../../../../../../../../translations';
import ChartErrors from './ChartErrors';

import { useComposer } from '../../../ComposerProvider';
import { useState, useEffect, useRef } from 'react';
import { isNullOrEmpty } from '../../../../../../../../utils/guard';
import { COMPONENT_CONFIGURATION_PATH as cfgPath } from '../../../../../../constants';
import type { PageContainer } from '../../../../../../../../types';

const LIST_VIEW = 'LIST_VIEW';
const CREATE_VIEW = 'CREATE_VIEW';

const ChartSection = ({ container }: { container: PageContainer; screen?: string }) => {
    const {
        dragDropElements,
        onPageElementDrop,
        onSetPageElementActive,
        onPageElementUpdate,
        setElementToDelete,
    } = useComposer();

    const [view, setView] = useState(LIST_VIEW);

    const nameRef = useRef<HTMLInputElement>(null);

    const dataSets = dragDropElements.filter(
        (pageElement) => pageElement.parentId === container.id,
    );

    const editDataSet = (id: string | null) => {
        if (id) {
            onSetPageElementActive(id);
        }
    };

    const switchToAddDatasetView = () => {
        // Check if an existing dataset already exists and extract the chart type
        const componentType = dataSets.length > 0 ? dataSets[0].componentType : null;
        if (componentType) {
            // The chart type needs to be the same as the existing,
            // so the new dataset can inherit it
            addNewDataSet(componentType);
        } else {
            // This must be the initial dataset
            // so we want to specify the chart type
            setView(CREATE_VIEW);
        }
    };

    const addNewDataSet = (componentType: string) => {
        onPageElementDrop({
            id: null,
            targetId: container.id,
            order: dataSets.length,
            componentType,
            pageElementType: 'COMPONENT',
            select: false,
        });
    };

    const cancelDataSetCreate = () => {
        setView(LIST_VIEW);
    };

    const { developerName, label } = container;
    const id = container.id as string;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        nameRef?.current?.focus();
        setView(LIST_VIEW);
    }, [id]);

    return (
        <>
            <ConfigSection title="General" dataTestId="general-container-section">
                <FormGroup
                    label="Name"
                    htmlFor="container-name"
                    isRequired
                    isValid={!isNullOrEmpty(developerName)}
                    validationMessage={translations.PAGE_BUILDER_name_field_validation_message}
                    showValidation={true}
                >
                    <input
                        className="form-control"
                        id="container-name"
                        type="text"
                        value={developerName || ''}
                        onChange={({ target }) =>
                            onPageElementUpdate(id, cfgPath['NAME'], target.value)
                        }
                        title="Container name"
                        ref={nameRef}
                    />
                </FormGroup>
                <FormGroup label="Label" htmlFor="container-label">
                    <input
                        className="form-control"
                        id="container-label"
                        type="text"
                        value={label || ''}
                        onChange={({ target }) =>
                            onPageElementUpdate(id, cfgPath['LABEL'], target.value)
                        }
                        title="Container label"
                    />
                </FormGroup>
            </ConfigSection>
            <ConfigSection title="Datasets" dataTestId="chart-container-section">
                <ChartErrors dataSets={dataSets}>
                    <ScreenRouter currentScreen={view}>
                        <DataSets
                            screen={LIST_VIEW}
                            dataSets={dataSets}
                            editDataSet={editDataSet}
                            addNewDataSet={switchToAddDatasetView}
                            deleteDataSet={setElementToDelete}
                        />
                        <NewDataSet
                            screen={CREATE_VIEW}
                            onSave={addNewDataSet}
                            onCancel={cancelDataSetCreate}
                        />
                    </ScreenRouter>
                </ChartErrors>
            </ConfigSection>
        </>
    );
};

export default ChartSection;

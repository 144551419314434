import { type ConnectedProps, connect } from 'react-redux';
import { fetchReceivedUserInvitations } from '../../../js/actions/reduxActions/organizations';
import {
    type OrganizationInvite,
    acceptUserInvite,
    rejectUserInvite,
} from '../../sources/organization';
import translations from '../../translations';
import { useAuth } from '../AuthProvider';
import Loader from '../loader/Loader';
import ReceivedInvites from './ReceivedInvites';
import { stringReplace } from '../../utils';

type CurrentUserInviteList = ConnectedProps<typeof connector>;

const mapStateToProps = (state: { organizationReceivedUserInvitations: OrganizationInvite[] }) => ({
    invitations: state.organizationReceivedUserInvitations,
});

const mapDispatchToProps = {
    fetchReceivedUserInvitations,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const CurrentUserInviteList = ({
    invitations,
    fetchReceivedUserInvitations,
}: CurrentUserInviteList) => {
    const { user, fetchUser } = useAuth();

    const matchUserInvite = (invite: OrganizationInvite) => {
        if (!user?.id) {
            return false;
        }

        if (invite.type !== 'user') {
            return false;
        }

        if (invite.user.id !== user.id) {
            return false;
        }

        return true;
    };

    const handleAccept = async (id: string) => {
        await acceptUserInvite({
            organizationId: id,
        });
    };

    const handleReject = async (id: string) => {
        await rejectUserInvite({
            organizationId: id,
        });
    };

    const handleRefresh = async () => {
        await Promise.all([fetchUser(), fetchReceivedUserInvitations()]);
    };

    if (!user) {
        return <Loader />;
    }

    return (
        <>
            <p className="description">{translations.FORG_user_invitations_description}</p>
            <ReceivedInvites
                caption={stringReplace(translations.FORG_received_user_invites_table_caption, {
                    userEmail: user.email,
                })}
                invitations={invitations}
                loadInvitations={fetchReceivedUserInvitations}
                matchInvite={matchUserInvite}
                onAccept={handleAccept}
                onReject={handleReject}
                onRefresh={handleRefresh}
            />
        </>
    );
};

export default connector(CurrentUserInviteList);

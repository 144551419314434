import type { Notification as NotificationObject } from '../../types';
import Notification from './Notification';

export type PersistentNotificationProps = {
    show: boolean;
} & Pick<NotificationObject, 'message'>;

const PersistentNotification = ({ show, message }: PersistentNotificationProps) => {
    return show ? (
        // Shows a persistent notification that cannot be closed
        // Doesn't use the global notifications area but its own instance so that this notification only appears in the current tab
        <div className="toast-notifications">
            <Notification
                id={`persistent-info-${String(message)}`}
                message={message}
                type={'info'}
                // isPersistent is false so that the close button doesn't appear, but this notification will not get cleared
                isPersistent={false}
            />
        </div>
    ) : null;
};

export default PersistentNotification;

import { useState } from 'react';
import SearchInput from '../../../components/generic/SearchInput';
import Table from '../../generic/Table';
import translations from '../../../translations';
import { stringContains } from '../../../utils/string';
import type { TypeElementRequestAPI } from '../../../types';

interface ExistingTypesProps {
    typeElements: TypeElementRequestAPI[];
}

const ExistingTypes = ({ typeElements }: ExistingTypesProps) => {
    const [search, setSearch] = useState('');

    const filterResults = () => {
        const queryResult = typeElements.filter((typeElement) =>
            stringContains(typeElement.developerName, search, false),
        );
        return queryResult;
    };

    return (
        <>
            <h2>
                Types
                <small>{` Total: ${typeElements.length ?? 0}`}</small>
            </h2>
            <div className="flex">
                <SearchInput onChange={setSearch} value={search} />
            </div>
            <Table
                wrapperClassName="margin-top"
                columns={[
                    {
                        renderHeader: () => translations.COMMON_TABLE_name,
                        renderCell: ({ item }) => item.developerName,
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_summary,
                        renderCell: ({ item }) => item.developerSummary,
                    },
                ]}
                items={filterResults()}
                pagination={true}
            />
        </>
    );
};

export default ExistingTypes;

import { type ReactElement, type ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import './css/table.less';
import translations from '../../translations';
import Loader from '../loader/Loader';
import { move } from 'ramda';
import Pagination, { type PaginationData } from './Pagination';
import DraggableTableRow from './DraggableTableRow';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface Props<TItem> {
    items: TItem[];
    columns: TableColumnList<TItem>;
    wrapperClassName?: string;
    tableClass?: string;
    rowClassName?: (item: TItem, rowIndex: number) => string;
    isLoading?: boolean;
    children?: ReactElement;
    testId?: string;
    caption?: string;
    onRowSelect?: (rowIndex: number) => void;
    pagination?: PaginationData | boolean;
    renderExpandedRow?: (item: TItem) => ReactNode;
    onReorder?: (items: TItem[]) => void;
    tableId?: string; // Used for preventing drag drop interactions between tables
}

interface TableColumn<TItem> {
    renderHeader: () => ReactNode;
    renderCell: ({
        item,
        rowIndex,
        cellIndex,
    }: {
        item: TItem;
        rowIndex: number;
        cellIndex: number;
    }) => ReactNode;
    headerClassName?: string | undefined;
    cellClassName?: string;
    size?: string;
}

export type TableColumnList<TItem> = TableColumn<TItem>[];

const DraggableTable = <TItem,>({
    items = [],
    columns = [],
    wrapperClassName = '',
    tableClass = 'generic-table',
    rowClassName = () => 'generic-row',
    isLoading = false,
    children,
    testId = '',
    caption = '',
    onRowSelect,
    pagination,
    // paginationHasPageSelector = true,
    renderExpandedRow,
    onReorder,
    tableId,
}: Props<TItem>) => {
    // If specified, onRowSelect will override this
    // and so tracking the selected row index will
    // be delegated back to the parent component.
    // This fulfills use cases such as sort ordering rows.
    const [selected, setSelected] = useState<number | null>(null);

    const [itemsToDisplay, setItemsToDisplay] = useState<TItem[]>([]);

    const onReorderRows = (currentIndex: number, newIndex: number) => {
        if (onReorder) {
            const orderedRows = move(currentIndex, newIndex, items);
            onReorder(orderedRows);
            setSelected(newIndex);
        }
    };

    useEffect(() => {
        // Pagination is not enabled so set itemsToDisplay to all items.
        if (!pagination) {
            setItemsToDisplay(items);
        }
    }, [items, pagination]);

    const wrapperClasses = classnames({
        'generic-table-wrapper': true,
        loading: isLoading,
        [wrapperClassName]: wrapperClassName.length > 0,
    });

    const showNoResultsMessage = isLoading === false && itemsToDisplay.length === 0;

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={wrapperClasses}>
                {isLoading && <Loader message="Loading table data..." />}
                <table
                    className={`${tableClass}${items.length === 0 ? ' table-no-results' : ''}`}
                    data-testid={testId}
                >
                    {caption ? <caption>{caption}</caption> : null}
                    <colgroup>
                        {/* Add an extra <col> to account for expandable rows being enabled */}
                        {renderExpandedRow && <col className="header-expandable-table" />}
                        {columns.map((column, index) => (
                            // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
                            <col key={index} span={1} style={{ width: column.size }} />
                        ))}
                    </colgroup>
                    <thead>
                        <tr>
                            {/* Add an extra <th> to account for expandable rows being enabled */}
                            {renderExpandedRow && (
                                <th className="generic-header generic-header-start" />
                            )}
                            {columns.map((column, index) => {
                                const columnClasses = classnames(
                                    column.headerClassName ?? 'generic-header',
                                    {
                                        // If there is an expandable row then don't make the first column rounded.
                                        'generic-header-start': index === 0 && !renderExpandedRow,
                                        'generic-header-end': index === columns.length - 1,
                                    },
                                );
                                return (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
                                    <th key={index} className={columnClasses}>
                                        {column.renderHeader()}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {children
                            ? children
                            : itemsToDisplay.map((item, rowIndex) => {
                                  const rowClasses = classnames({
                                      highlight: selected === rowIndex,
                                      'draggable-row': !!onReorder,
                                  });

                                  const onRowClick = () => {
                                      if (onRowSelect) {
                                          onRowSelect(rowIndex);
                                      } else {
                                          setSelected(rowIndex !== selected ? rowIndex : null);
                                      }
                                  };

                                  const isSelected = selected === rowIndex;

                                  return (
                                      <DraggableTableRow
                                          // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
                                          key={rowIndex}
                                          renderExpandedRow={renderExpandedRow}
                                          rowIndex={rowIndex}
                                          classes={`${rowClassName(item, rowIndex)} ${rowClasses}`}
                                          isSelected={isSelected}
                                          columns={columns}
                                          item={item}
                                          ordering={{
                                              canOrder: !!onReorder && !!tableId,
                                              onOrder: onReorderRows,
                                          }}
                                          onClick={onRowClick}
                                          totalItems={items.length}
                                          tableId={tableId}
                                      />
                                  );
                              })}
                    </tbody>
                </table>
                {showNoResultsMessage ? (
                    <div className="table-no-results-message">
                        {translations.COMMON_TABLE_no_results}
                    </div>
                ) : null}
                {isLoading ? null : pagination ? (
                    <Pagination
                        pagination={pagination}
                        items={items}
                        itemsToDisplay={itemsToDisplay}
                        setItemsToDisplay={setItemsToDisplay}
                    />
                ) : null}
            </div>
        </DndProvider>
    );
};

export default DraggableTable;

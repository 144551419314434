const Pdf = ({ label }: { label: string }) => {
    return (
        <button
            data-testid="component-preview-pdf"
            className="btn-link component-preview-pdf"
            type="button"
        >
            {label}
        </button>
    );
};

export default Pdf;

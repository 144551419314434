import {
    COMPONENT_PROPERTIES as componentProperties,
    SUMMARY_CONTEXT as summaryContext,
    TOGGLE_CATEGORY_OPTIONS as toggleCategoryOptions,
    TOGGLE_TRIGGER_OPTIONS as toggleTriggerOptions,
} from '../../../../constants';
import { getValueList } from '../../../../../../sources/value';
import { RULE_CRITERIA, RULE_CRITERIA_OPTIONS } from './criteria';
import { has } from 'ramda';
import type {
    Assignment,
    ContentType,
    CriteriaType,
    OperationSummaryData,
    PageComponent,
    PageCondition,
    PageOperation,
    PageRule,
    RuleCriteria,
    RuleSummaryData,
    ValueElementIdReferenceAPI,
} from '../../../../../../types';
import ConditionSummary from './ConditionSummary';
import RuleSummary from './RuleSummary';
import OperationSummary from './OperationSummary';
import type { SingleValue } from 'react-select';

/**
 * Convert object keys to uppercase for easier comparison
 */
const objKeysToUppercase = (obj: { [key: string]: RuleCriteria[] }) => {
    const entries = Object.entries(obj);
    const uppercaseEntries = entries.map((entry) => [entry[0].toUpperCase(), entry[1]]);
    return Object.fromEntries(uppercaseEntries) as { [key: string]: RuleCriteria[] };
};

/**
 * Update a rule/operation component configuration
 */
export const updateComponent = (
    baseConfig: Assignment,
    component: SingleValue<{ label: string; value: string }>,
): Assignment => ({
    ...baseConfig,
    pageObjectReferenceDeveloperName: component?.label || null,
    pageObjectReferenceId: component?.value || null,
});

/**
 * Update a rule/operation property configuration
 */
export const updateProperty = (
    baseConfig: Assignment,
    property: SingleValue<{ label: string; value: string }>,
) => ({
    ...baseConfig,
    metadataType: property?.value || null,
});

/**
 * Update a rule/operation value configuration
 */
export const updateValue = (baseConfig: Assignment, value: ValueElementIdReferenceAPI | null) => ({
    ...baseConfig,
    metadataType: value ? 'VALUE' : null,
    typeElementPropertyId: value?.typeElementPropertyId || null,
    valueElementToReferenceId: value ? { ...value } : null,
});

/**
 * Get selected component option
 */
export const getSelectedComponent = (
    selected: Assignment | null | undefined,
    components: PageComponent[] | null,
) =>
    getPageComponentOptions(components).find(
        (comp) => comp.value === selected?.pageObjectReferenceId,
    );

/**
 * Get selected component property
 */
export const getSelectedProperty = (selected: Assignment | undefined | null) =>
    componentProperties.find((prop) => prop.value === selected?.metadataType);

/**
 * Get page components as an array of select options
 */
export const getPageComponentOptions = (components: PageComponent[] | null) => {
    return components
        ? components.map((comp) => ({
              label: comp.developerName || '',
              value: comp.id || '',
          }))
        : [];
};

/**
 * Get the criteria/comparison options based on the left content type
 */
export const getRuleCriteriaOptions = (leftContentType: string | null | undefined) => {
    // Convert all the bits to uppercase, so it's easier to match them up.
    const options = objKeysToUppercase(RULE_CRITERIA_OPTIONS);
    const contentType = leftContentType?.toUpperCase();

    return contentType
        ? options[contentType].map((rc: RuleCriteria) => ({
              value: rc.criteria,
              label: rc.label,
          }))
        : [];
};

/**
 * Return a user-friendly criteria phrase
 */
export const getCriteriaPhrase = (criteria: string | null | undefined) => {
    const label = Object.values(RULE_CRITERIA).find((rc) => rc.criteria === criteria)?.label;
    // If we have a label return that, if a label cannot be found for the given criteria then return the criteria as-is,
    // or UNKNOWN if we got nothing.
    return label ? label.toUpperCase() : criteria || 'UNKNOWN';
};

/**
 * Figure out what item category we are dealing with in a rule or operation
 */
export const getItemCategoryOption = (
    item: Assignment | undefined | null,
    preferComponent: boolean,
) => {
    const haveComponent = !!item?.pageObjectReferenceId;
    const haveValue = !!item?.valueElementToReferenceId?.id;

    // Nothing is configured, so we can't decide if component or value.
    if (!(haveComponent || haveValue)) {
        return null;
    }

    // Operation left side (assignee) is a bit of an edge case and has a preference towards components
    // (because we can't set values), so if we have a component and a value in the operation assignee
    // then we'll return component as the category...
    if (haveComponent && preferComponent) {
        return toggleCategoryOptions['COMPONENT'];
    }

    // ...in all other cases the value has precedence, so if we have a component and a value
    // we should return value as the category.
    return haveValue ? toggleCategoryOptions['VALUE'] : toggleCategoryOptions['COMPONENT'];
};

/**
 * Get just the value or property developer name
 */
export const getLocalValueDeveloperName = (
    valueId: string | undefined | null,
    propId: string | undefined | null,
    values: ValueElementIdReferenceAPI[] | null,
) => {
    if (valueId && propId) {
        const item = values?.find((v) => v.id === valueId && v.typeElementPropertyId === propId);
        return item ? `${item.developerName} / ${item.typeElementPropertyDeveloperName}` : null;
    }

    if (valueId) {
        const item = values?.find((v) => v.id === valueId);
        return item ? item.developerName : null;
    }

    return null;
};

/**
 * Get just the value or property content type
 */
export const getLocalValueContentType = (
    valueId: string | null | undefined,
    propId: string | null | undefined,
    values: Partial<ValueElementIdReferenceAPI>[] | null,
) => {
    if (valueId && propId) {
        return values?.find((v) => v.id === valueId && v.typeElementPropertyId === propId)
            ?.contentType;
    }

    if (valueId) {
        return values?.find((v) => v.id === valueId)?.contentType;
    }

    return null;
};

/**
 * Get all value IDs from rules
 */
export const getIDsFromRules = (rules: PageRule[] | null) => {
    const ids = rules?.length
        ? rules.flatMap((rule) => [
              rule?.left?.valueElementToReferenceId?.id,
              rule?.right?.valueElementToReferenceId?.id,
          ])
        : [];

    // Filter the array and only keep IDs that are not null/undefined.
    return ids.filter((item) => !!item);
};

/**
 * Get all value IDs from operations
 */
export const getIDsFromOperations = (operations: PageOperation[] | null) => {
    const ids = operations?.length
        ? operations.flatMap((operation) => [
              operation?.assignment?.assignor?.valueElementToReferenceId?.id,
              operation?.assignment?.assignee?.valueElementToReferenceId?.id,
          ])
        : [];

    // Filter the array and only keep IDs that are not null/undefined.
    return ids.filter((item) => !!item);
};

/**
 * Get all value IDs from page components
 */
export const getIDsFromPageComponents = (pageComponents: PageComponent[] | null) => {
    const ids = pageComponents?.length
        ? pageComponents.map((component) => component.valueElementValueBindingReferenceId?.id)
        : [];

    // Filter the array and only keep IDs that are not null/undefined.
    return ids.filter((item) => !!item);
};

/**
 * Get all value IDs from page conditions
 */
export const getIDsFromPageConditions = (conditions: PageCondition[] | null) => {
    return conditions?.length
        ? conditions.flatMap((pc) => {
              const idsFromRules = getIDsFromRules(pc?.pageRules);
              const idsFromOperations = getIDsFromOperations(pc?.pageOperations);
              return [...idsFromRules, ...idsFromOperations];
          })
        : [];
};

/**
 * Check if any new values have been added with the new/updated rule or operation
 */
export const checkForNewIDs = (
    condition: PageCondition | null,
    config: PageRule | PageOperation,
) => {
    const isOperationConfig = has('assignment', config);
    const oldIDs = getIDsFromPageConditions(condition ? [condition] : null);
    const newIDs = isOperationConfig ? getIDsFromOperations([config]) : getIDsFromRules([config]);
    return newIDs.filter((id) => oldIDs.includes(id) === false)?.length > 0;
};

/**
 * Get id, name and content type for all relevant values
 */
export const getAllPageConditionValues = async (valueIDs: string[]) => {
    if (valueIDs?.length) {
        // If we have more IDs than the max num allowed, that will create a too long request URI and cause a 414,
        // so in that case we'll request all value references and then filter them down to match our IDs.
        // Max number of IDs is based on this:
        // https://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers
        // max URI length of 2000 chars / guid length of 36 chars = 55.555... IDs,
        // -5 to have a bit more breathing room => max ~50 IDs
        const aboveMaxNumOfIDs = valueIDs?.length > 50;
        const requestIDs = aboveMaxNumOfIDs ? null : { id: [...valueIDs] };
        const allValues = await getValueList(requestIDs);

        // Return relevant value data including the names and contentTypes.
        return aboveMaxNumOfIDs
            ? allValues.filter((value) => (value.id ? valueIDs.includes(value.id) : false))
            : allValues;
    }
    return [];
};

/**
 * Generate a partial summary data for a single rule/operation
 */
export const generatePartialSummaryData = (
    item: Assignment | undefined | null,
    values: ValueElementIdReferenceAPI[] | null,
    preferComponent: boolean,
) => {
    // What property/metadataType is selected.
    const property = getSelectedProperty(item);

    // Are we working with a component or a value?
    const category = getItemCategoryOption(item, preferComponent); // comp / value / null

    // Figure out the summary item name.
    // In case of a value, using item?.valueElementToReferenceId.developerName does not really
    // work, we have to GET the value to get its developerName because of the value data format
    // difference between the picker and what is stored in the engine.
    const name =
        category === toggleCategoryOptions['COMPONENT']
            ? item?.pageObjectReferenceDeveloperName
            : category === toggleCategoryOptions['VALUE']
              ? getLocalValueDeveloperName(
                    item?.valueElementToReferenceId?.id,
                    item?.valueElementToReferenceId?.typeElementPropertyId,
                    values,
                )
              : null;

    return {
        category,
        name,
        property: category === toggleCategoryOptions['COMPONENT'] ? property?.label : null,
    };
};

/**
 * Generate a summary data object for a single operation
 */
export const generateOperationSummaryData = (
    item: PageOperation,
    values: ValueElementIdReferenceAPI[] | null,
    pageComponents: PageComponent[],
): OperationSummaryData => {
    const assignee = item.assignment?.assignee;
    const assignor = item.assignment?.assignor;

    const left = generatePartialSummaryData(assignee, values, true);
    const right = generatePartialSummaryData(assignor, values, false);

    // Check if we got a sus operation with incompatible content types.
    const { leftContentType, rightContentType } = getOperationContentTypes(
        item,
        values,
        pageComponents,
    );
    const isIncompatibleContentTypes =
        leftContentType && rightContentType && leftContentType !== rightContentType;

    return {
        left,
        right,
        isIncompatibleContentTypes,
    };
};

/**
 * Return the page condition trigger (WHEN)
 */
export const getRuleTriggerOption = (ruleLeft: Assignment | null) => {
    const haveComponent = !!ruleLeft?.pageObjectReferenceId;
    const haveValue = !!ruleLeft?.valueElementToReferenceId?.id;

    // Is the page condition trigger a page load or a component change?
    // The only valid page load setup is when we have a value and no component.
    // If we have a component then it's naturally a component change regardless
    // if a value is present or not. If we have nothing (new empty rule), we'll
    // also treat that as component change, so we can preselect that option just
    // as a starting point for a new rule setup.
    const isTriggerPageLoad = !haveComponent && haveValue;

    return isTriggerPageLoad ? toggleTriggerOptions['LOAD'] : toggleTriggerOptions['CHANGE'];
};

/**
 * Checking if given content types in a rule are incompatible
 */
export const checkContentTypeCompatibility = (
    leftContentType: ContentType | undefined,
    rightContentType: ContentType | undefined,
    criteria: string | null,
) => {
    const isIncompatibleContentTypes =
        criteria === 'IS_EMPTY'
            ? rightContentType !== 'ContentBoolean'
            : leftContentType && rightContentType && leftContentType !== rightContentType;
    return isIncompatibleContentTypes;
};

/**
 * Generate a summary data object for a single rule
 */
export const generateRuleSummaryData = (
    rule: PageRule,
    values: ValueElementIdReferenceAPI[] | null,
    pageComponents: PageComponent[],
): RuleSummaryData => {
    const { left, right, criteriaType } = rule;

    const leftProp = getSelectedProperty(left);

    const leftComponentName = left?.pageObjectReferenceDeveloperName || null;

    const valueId = left?.valueElementToReferenceId?.id;
    const propId = left?.valueElementToReferenceId?.typeElementPropertyId;
    const leftValueName = getLocalValueDeveloperName(valueId, propId, values);

    const ruleTrigger = getRuleTriggerOption(left);
    const isComponentChange = ruleTrigger === toggleTriggerOptions['CHANGE'];
    const isPageLoad = ruleTrigger === toggleTriggerOptions['LOAD'];

    const criteriaPhrase = getCriteriaPhrase(criteriaType);

    const rightSummaryData = generatePartialSummaryData(right, values, false);

    // Check if we got a sus rule with incompatible criteria and let the user know if we do.
    const { leftContentType, rightContentType } = getRuleContentTypes(rule, values, pageComponents);
    const isIncompatibleCriteria = !checkCriteriaCompatibility(criteriaType, leftContentType);
    const isIncompatibleContentTypes = checkContentTypeCompatibility(
        leftContentType,
        rightContentType,
        criteriaType,
    );

    return {
        flags: {
            isComponentChange,
            isPageLoad,
            isIncompatibleCriteria,
            isIncompatibleContentTypes,
        },
        left: {
            componentName: leftComponentName,
            valueName: leftValueName,
            property: leftProp?.label,
        },
        criteriaPhrase,
        right: rightSummaryData,
    };
};

/**
 * Generate a summary data object for a single page condition
 */
export const generateConditionSummaryData = (
    pageCondition: PageCondition,
    values: ValueElementIdReferenceAPI[] | null,
    pageComponents: PageComponent[],
) => {
    const { pageRules, pageOperations, comparisonType } = pageCondition;

    const ruleData = [];
    const operationData = [];

    if (pageRules) {
        for (const rule of pageRules) {
            const data = generateRuleSummaryData(rule, values, pageComponents);
            ruleData.push(data);
        }
    }

    if (pageOperations) {
        for (const operation of pageOperations) {
            const data = generateOperationSummaryData(operation, values, pageComponents);
            operationData.push(data);
        }
    }

    return {
        comparisonType,
        ruleData,
        operationData,
    };
};

/**
 * Generate a formatted summary for a condition/rule/operation based on given summary data
 */
export const getFormattedSummary = (
    data: PageCondition[] | PageRule[] | PageOperation[],
    values: ValueElementIdReferenceAPI[] | null,
    pageComponents: PageComponent[],
    context: string,
) => {
    const isCondition = context === summaryContext['CONDITIONS'];
    const isRule = context === summaryContext['RULES'];
    const isOperation = context === summaryContext['OPERATIONS'];

    // Generate summary data from raw item(s).
    const ruleSummaryDataSet = [];
    const conditionSummaryDataSet = [];
    const operationSummaryDataSet = [];

    for (const item of data) {
        if (isCondition) {
            conditionSummaryDataSet.push(
                <ConditionSummary
                    {...generateConditionSummaryData(item as PageCondition, values, pageComponents)}
                />,
            );
        }

        if (isRule) {
            ruleSummaryDataSet.push(
                <RuleSummary
                    {...generateRuleSummaryData(item as PageRule, values, pageComponents)}
                />,
            );
        }

        if (isOperation) {
            operationSummaryDataSet.push(
                <OperationSummary
                    {...generateOperationSummaryData(item as PageOperation, values, pageComponents)}
                />,
            );
        }
    }

    const summarySet = isCondition
        ? conditionSummaryDataSet
        : isRule
          ? ruleSummaryDataSet
          : isOperation
            ? operationSummaryDataSet
            : null;

    return summarySet;
};

/**
 * Return the content type of the given page condition config item (rule or condition)
 */
export const getItemContentType = (
    item: Assignment | undefined | null,
    values: Partial<ValueElementIdReferenceAPI>[] | null,
    pageComponents: PageComponent[] | null,
    preferComponent: boolean,
) => {
    // Item category can be COMPONENT or VALUE based on the item's metadata.
    const itemCategory = getItemCategoryOption(item, preferComponent);
    // Selected property is Visible, Editable, etc.
    const isMetadataTypeProperty = item?.metadataType && item?.metadataType !== 'VALUE';
    // Selected property is Value.
    const isMetadataTypeValue = item?.metadataType && item?.metadataType === 'VALUE';
    const hasValueId = !!item?.valueElementToReferenceId?.id;

    // Item is a component with a boolean property.
    // (all component properties are boolean by default)
    if (itemCategory === toggleCategoryOptions['COMPONENT'] && isMetadataTypeProperty) {
        return 'ContentBoolean';
    }

    // Item is a component with a value property.
    // Because we can have a component and a value at the same time configured in a rule (left side),
    // here we will go with the component if the category matches, and we don't have a proper value ID.
    // This is to make sure we get the correct contentType (value linked to the component).
    if (
        itemCategory === toggleCategoryOptions['COMPONENT'] &&
        isMetadataTypeValue &&
        !hasValueId &&
        pageComponents
    ) {
        // Automatically select the value that the component is using.
        const component = pageComponents.find((comp) => comp.id === item.pageObjectReferenceId);
        const componentValue = component?.valueElementValueBindingReferenceId;
        return getLocalValueContentType(
            componentValue?.id,
            componentValue?.typeElementPropertyId,
            values,
        );
    }

    // Item is a value.
    // Because we can have a component and a value at the same time configured in a rule (left side),
    // here we will go with the value if the category matches, or we have a proper value ID.
    // This is to make sure we get the correct contentType (value not linked to the component).
    if (itemCategory === toggleCategoryOptions['VALUE'] || hasValueId) {
        return getLocalValueContentType(
            item?.valueElementToReferenceId?.id,
            item?.valueElementToReferenceId?.typeElementPropertyId,
            values,
        );
    }

    return null;
};

/**
 * Return the actual rule content types based on used components, values and component state values
 */
export const getRuleContentTypes = (
    rule: PageRule | null | undefined,
    values: Partial<ValueElementIdReferenceAPI>[] | null,
    pageComponents: PageComponent[] | null,
) => {
    const { left, right } = rule || {};

    return {
        leftContentType: getItemContentType(left, values, pageComponents, false),
        rightContentType: getItemContentType(right, values, pageComponents, false),
    };
};

/**
 * Check if a rule has incompatible criteria and content type
 */
export const checkCriteriaCompatibility = (
    criteriaType: CriteriaType | null,
    ruleContentType: string | null | undefined,
) =>
    criteriaType
        ? getRuleCriteriaOptions(ruleContentType)
              .map((option) => option.value)
              .includes(criteriaType)
        : false;

/**
 * Return operation left and right content types
 */
export const getOperationContentTypes = (
    operation: PageOperation,
    values: ValueElementIdReferenceAPI[] | null,
    pageComponents: PageComponent[] | null,
) => {
    const { assignee, assignor } = operation?.assignment || {};

    return {
        leftContentType: getItemContentType(assignee, values, pageComponents, true),
        rightContentType: getItemContentType(assignor, values, pageComponents, false),
    };
};

/**
 * Check if rule is configured
 */
export const isRuleConfigured = (rule: PageRule) => {
    const { left, right, criteriaType } = rule;
    const isLeftConfigured =
        (left?.metadataType && left?.pageObjectReferenceId) || left?.valueElementToReferenceId;
    const isRightConfigured =
        (right?.metadataType && right?.pageObjectReferenceId) || right?.valueElementToReferenceId;
    const isCriteriaConfigured = !!criteriaType;

    return isLeftConfigured && isRightConfigured && isCriteriaConfigured;
};

/**
 * Check if operation is configured
 */
export const isOperationConfigured = (operation: PageOperation) => {
    const { assignee, assignor } = operation?.assignment || {};
    const isAssigneeConfigured =
        (assignee?.metadataType && assignee?.pageObjectReferenceId) ||
        assignee?.valueElementToReferenceId;
    const isAssignorConfigured =
        (assignor?.metadataType && assignor?.pageObjectReferenceId) ||
        assignor?.valueElementToReferenceId;

    return isAssigneeConfigured && isAssignorConfigured;
};

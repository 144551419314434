import Modal from '../../../../generic/modal/GenericModal';
import FormGroup from '../../../../generic/FormGroup';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import ButtonDefault from '../../../../buttons/ButtonDefault';

import { useRef } from 'react';
import { usePageEditor } from '../../PageEditorProvider';
import type { Page } from '../../../../../types';

interface Props {
    show: boolean;
    showModal: (show: boolean) => void;
    onSave: (page: Page) => void;
    pageData: Page;
    shouldClone: boolean;
    title: string;
}

const PageSettings = ({ show, showModal, onSave, pageData, shouldClone, title }: Props) => {
    const { container } = usePageEditor();
    const nameRef = useRef<HTMLInputElement | null>(null);
    const labelRef = useRef<HTMLInputElement | null>(null);
    const summaryRef = useRef<HTMLTextAreaElement | null>(null);
    const hiddenInputsRef = useRef<HTMLInputElement | null>(null);

    const saveSettings = () => {
        const settings: Page = {
            ...pageData,
            label: labelRef.current?.value ?? null,
            developerSummary: summaryRef.current?.value ?? null,
            saveHiddenInputs: hiddenInputsRef.current?.checked ?? false,
        };

        if (shouldClone) {
            settings.developerName = nameRef.current?.value ?? null;
        }

        onSave(settings);
        showModal(false);
    };

    const { developerName, label, developerSummary, saveHiddenInputs } = pageData;

    return (
        <Modal
            container={container}
            show={show}
            className="confirm-modal config-modal"
            title={title}
            onHide={() => showModal(false)}
            renderBody={() => (
                <>
                    {shouldClone ? (
                        <FormGroup htmlFor="name" label="Name">
                            <input
                                ref={nameRef}
                                className="form-control"
                                type="text"
                                defaultValue={`${developerName || ''} (copy)`}
                                id="name"
                                title="Page name"
                            />
                        </FormGroup>
                    ) : null}
                    <FormGroup htmlFor="label" label="Label">
                        <input
                            ref={labelRef}
                            className="form-control"
                            type="text"
                            defaultValue={label || ''}
                            id="label"
                            title="Page label"
                        />
                    </FormGroup>
                    <FormGroup htmlFor="summary" label="Summary">
                        <textarea
                            ref={summaryRef}
                            className="form-control"
                            defaultValue={developerSummary || ''}
                            id="summary"
                            title="Page summary"
                        />
                    </FormGroup>

                    <div className="form-group">
                        <label>
                            <input
                                ref={hiddenInputsRef}
                                type="checkbox"
                                defaultChecked={saveHiddenInputs}
                            />
                            Enable hidden components to save content value to state
                        </label>
                    </div>
                </>
            )}
            renderFooter={() => (
                <>
                    <ButtonDefault title="Cancel Settings" onClick={() => showModal(false)}>
                        Cancel
                    </ButtonDefault>
                    <ButtonPrimary onClick={saveSettings} title="Save current page settings">
                        Save
                    </ButtonPrimary>
                </>
            )}
        />
    );
};

export default PageSettings;

import FormGroup from '../../generic/FormGroup';
import translations from '../../../translations';
import type { AttributeMappings, IdentityProviderAPI } from '../../../types';
import Table, { type TableColumnList } from '../../generic/Table';

interface Props {
    item: IdentityProviderAPI;
    header: string;
    onChange: (name: string, value: string) => void;
}

const IdentityProviderAttributeMappings = ({ item, header, onChange }: Props) => {
    const attributeMappingColumns: TableColumnList<AttributeMappings> = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item['label'],
            size: '20rem',
        },
        {
            renderHeader: () => header,
            renderCell: ({ item }) => (
                <input
                    type="text"
                    className="form-control"
                    value={item['value'] ?? ''}
                    onChange={(e) => onChange(item['id'] ?? '', e.currentTarget.value)}
                    data-testid={`attribute-${item['id'] ?? ''}`}
                />
            ),
        },
    ];

    const attributeMappings: { label: string; id: string; value: string }[] = Object.keys(
        item.attributeMappings ?? {},
    )
        .sort()
        .map((key) => {
            const label = (translations as Record<string, string>)[
                `IDENTITY_PROVIDER_attributes_${key}`
            ];

            return {
                label: label ?? '',
                id: key,
                value: item.attributeMappings[key] ?? '',
            };
        });

    return (
        <FormGroup label="Custom Attribute Mappings">
            <Table<AttributeMappings>
                wrapperClassName="margin-top"
                columns={attributeMappingColumns}
                items={attributeMappings}
            />
        </FormGroup>
    );
};

export default IdentityProviderAttributeMappings;

import classnames from 'classnames';
import {
    Crosshair as ZoomToFitIcon,
    Gear as SettingsIcon,
    Info as HelpIcon,
    MagnifyingGlass as SearchIcon,
    MagnifyingGlassMinus as ZoomOutIcon,
    MagnifyingGlassPlus as ZoomInIcon,
    X as CloseIcon,
    MagicWand as AutoArrangeIcon,
} from '@phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { setSearch as setSearchAction } from '../../actions/reduxActions/graphEditor';
import { safeToLower } from '../../../ts/utils/string';
import ButtonIcon from '../../../ts/components/buttons/ButtonIcon';
import { useGraph } from './GraphProvider';
import GraphUserSettings from './GraphUserSettings';
import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import translations from '../../../ts/translations';
import ButtonPrimary from '../../../ts/components/buttons/ButtonPrimary';
import { fitQuery, zoomToFit } from './utils';
import GraphHelpMenu from '../../../ts/components/graph/GraphHelpMenu';
import debounce from 'lodash.debounce';

const eventKeys = {
    ENTER: 'Enter',
    ESCAPE: 'Escape',
};

const GraphActionMenu = ({ flowId, canvasNotes, graphElement, zoom, zoomViewBox, setSearch }) => {
    const {
        mapElements,
        groupElements,
        blockHotkeys,
        setIsSearchFocused,
        setHighlightedElementIds,
        setIsLoading,
        isLoading,
        isPreviewingAutoArrange,
        setIsPreviewingAutoArrange,
        refreshFlow,
        saveElements,
        autoArrangeGraph,
    } = useGraph();

    const cancelArrangePreview = () => {
        setIsLoading(true);
        setIsPreviewingAutoArrange(false);
        refreshFlow(graphElement, zoomViewBox);
    };

    const acceptArrangePreview = async () => {
        setIsLoading(true);
        setIsPreviewingAutoArrange(false);
        await saveElements({});
        setIsLoading(false);
    };

    const toggleAutoArrange = () => {
        if (isPreviewingAutoArrange) {
            cancelArrangePreview();
            return;
        }
        autoArrangeGraph({ graphElement, zoomViewBox });
    };

    const onKeyDown = (event) => {
        if (event.key === eventKeys.ESCAPE) {
            setShowHelp(false);
            setShowSearch(false);
            if (isPreviewingAutoArrange) {
                cancelArrangePreview();
            }
        }

        if (blockHotkeys) {
            return;
        }

        if (event.ctrlKey && event.key === 'f') {
            // prevent browser search
            event.preventDefault();

            if (inputRef.current) {
                inputRef.current.focus();
            } else {
                setShowSearch(true);
            }
        }

        if (event.ctrlKey === false && event.key === 'F') {
            zoomToFit(mapElements, groupElements, graphElement, zoomViewBox);
        }

        if (event.ctrlKey === false && event.key === 'A') {
            toggleAutoArrange();
        }

        if (event.ctrlKey && event.key === 'h') {
            // prevent browser history
            event.preventDefault();

            setShowHelp(!showHelp);
        }

        if (event.key === '.') {
            setShowSettings(!showSettings);
        }

        if (event.key === eventKeys.ESCAPE) {
            setShowHelp(false);
            setShowSearch(false);
        }
    };

    const onInputKeyUp = (event) => {
        switch (event.key) {
            case eventKeys.ENTER: {
                fitQuery(
                    inputRef,
                    mapElements,
                    groupElements,
                    canvasNotes,
                    graphElement,
                    zoomViewBox,
                );
                break;
            }
            default:
                break;
        }
    };

    const [showHelp, setShowHelp] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const inputRef = useRef(null);
    const [showSearch, setShowSearch] = useState(false);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [
        blockHotkeys,
        inputRef,
        mapElements,
        groupElements,
        showHelp,
        showSettings,
        isPreviewingAutoArrange,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!showSearch) {
            setIsSearchFocused(false);
        }
        // clear query for a new search or when search is closed
        setSearch({ flowId, query: null });
    }, [showSearch]);

    const searchCanvas = () => {
        setSearch({ flowId, query: safeToLower(inputRef.current.value) });
        setHighlightedElementIds([]);
    };
    const debounceSearchCanvas = debounce(searchCanvas, 250);

    const toggleArrangeButtonClasses = classnames('graph-action', {
        dark: isPreviewingAutoArrange,
    });

    const toggleHelpButtonClasses = classnames('graph-action', {
        dark: showHelp,
    });

    const toggleSettingsButtonClasses = classnames('graph-action', {
        dark: showSettings,
    });

    return (
        <>
            {showHelp && <GraphHelpMenu />}
            {showSettings && <GraphUserSettings />}
            {isPreviewingAutoArrange && (
                <div className="settings-menu">
                    <div className="settings-body">
                        <h4 className="settings-heading">Auto Arrange</h4>
                        <p>The canvas has been re-arranged and is currently in a read-only view.</p>
                        <p>Are you sure you want to save these changes?</p>
                    </div>
                    <div className="settings-footer">
                        <ButtonDefault disabled={isLoading} onClick={cancelArrangePreview}>
                            {translations.GRAPH_config_panel_revert}
                        </ButtonDefault>
                        <ButtonPrimary disabled={isLoading} onClick={acceptArrangePreview}>
                            {translations.GRAPH_config_panel_save}
                        </ButtonPrimary>
                    </div>
                </div>
            )}
            <div className="graph-actions-menu floating-panel flex">
                {showSearch ? (
                    <>
                        <input
                            type="text"
                            className="search-input"
                            ref={inputRef}
                            onKeyUp={onInputKeyUp}
                            onChange={() => {
                                debounceSearchCanvas();
                            }}
                            onFocus={() => setIsSearchFocused(true)}
                            onBlur={() => setIsSearchFocused(false)}
                            autoFocus
                            placeholder="Search"
                        />
                        <ButtonIcon
                            onClick={() => setShowSearch(!showSearch)}
                            className="graph-action"
                            title="Toggle search"
                        >
                            <CloseIcon />
                        </ButtonIcon>
                    </>
                ) : (
                    <ButtonIcon
                        onClick={() => setShowSearch(!showSearch)}
                        className="graph-action"
                        title="Toggle search"
                    >
                        <SearchIcon />
                    </ButtonIcon>
                )}
                <ButtonIcon onClick={() => zoom(0.9)} className="graph-action" title="Zoom in">
                    <ZoomInIcon />
                </ButtonIcon>
                <ButtonIcon onClick={() => zoom(1.1)} className="graph-action" title="Zoom out">
                    <ZoomOutIcon />
                </ButtonIcon>
                <ButtonIcon
                    onClick={() => zoomToFit(mapElements, groupElements, graphElement, zoomViewBox)}
                    className="graph-action"
                    title="Zoom to fit graph"
                >
                    <ZoomToFitIcon />
                </ButtonIcon>
                <ButtonIcon
                    onClick={toggleAutoArrange}
                    className={toggleArrangeButtonClasses}
                    title="Auto Arrange"
                >
                    <AutoArrangeIcon />
                </ButtonIcon>
                <ButtonIcon
                    onClick={() => setShowHelp(!showHelp)}
                    className={toggleHelpButtonClasses}
                    title="Help"
                >
                    <HelpIcon />
                </ButtonIcon>
                <ButtonIcon
                    onClick={() => setShowSettings(!showSettings)}
                    className={toggleSettingsButtonClasses}
                    title="Settings"
                >
                    <SettingsIcon />
                </ButtonIcon>
            </div>
        </>
    );
};

export default connect(({ canvasNotes }) => ({ canvasNotes }), {
    setSearch: setSearchAction,
})(GraphActionMenu);

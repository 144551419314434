import classnames from 'classnames';
import { stringReplace } from '../../../../../../utils/string';
import translations from '../../../../../../translations';
import { RUNTIME_VERSION, RUNTIME_LABELS } from '../../../../constants';
import type { RuntimeVersion } from '../../../../../../types';

interface Props {
    runtime: RuntimeVersion;
    miniMarker: boolean;
}

const RuntimeMarker = ({ runtime, miniMarker }: Props) => {
    const classes = classnames({
        'runtime-marker': true,
        mini: miniMarker,
        next: runtime === RUNTIME_VERSION['NEXT'],
        legacy: runtime === RUNTIME_VERSION['LEGACY'],
    });
    const label = RUNTIME_LABELS[runtime];

    return !runtime || runtime === RUNTIME_VERSION['BOTH'] ? null : (
        <span
            className={classes}
            title={stringReplace(translations.RUNTIME_title_text, {
                runtime: label?.toLowerCase(),
            })}
        >
            {!miniMarker && label}
        </span>
    );
};

export default RuntimeMarker;

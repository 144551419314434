import { useRef } from 'react';
import { connect } from 'react-redux';
import '../../../../css/tabs.less';
import {
    addNotification,
    notifyError,
    notifySuccess,
} from '../../../js/actions/reduxActions/notification';
import {
    updateTab,
    updateUrlAndTab,
    setTabTitle,
    closeTab,
} from '../../../js/actions/reduxActions/tabs';
import Admin from '../../../js/components/admin';
import { default as FlowEditorLegacy } from '../../../js/components/flow/flow-editor';
import ErrorBoundary from '../generic/ErrorBoundary';
import { GraphProvider } from '../../../js/components/graph/GraphProvider';
import { SYSTEM_ROLES } from '../../constants';
import { useTabRouting } from '../../hooks';
import { ClipBoardProvider } from '../../ClipBoardProvider';
import type { NotifyError, Tab } from '../../types';
import { useAuth } from '../AuthProvider';
import Tabs from './Tabs';
import PageEditor from '../page-editor/components/gui/PageEditor';
import FlowEditorStoreProvider, {
    type FlowEditorDispatch,
} from '../graphv2/render/FlowEditorStoreProvider';
import { getFlag } from '../../utils/flags';
import { DebugConfigProvider } from '../flow/debug/DebugConfigProvider';
import type { NavigateFunction } from 'react-router-dom';
import FlowEditor from '../graphv2/render/FlowEditor';
import FlowEditorSharedWrapper, {
    type FlowEditorSharedData,
} from '../graphv2/render/FlowEditorSharedWrapper';

interface Props {
    adminTenantId: string;
    tabs: Tab[];
    addNotification: () => void;
    updateTab: () => void;
    updateUrlAndTab: () => void;
    setTabTitle: (payload: { title: string; key: string }) => void;
    closeTab: (key: string, tenantId: string, navigate: NavigateFunction) => void;
    isTenantSelectorOpen: boolean;
    notifyError: NotifyError;
}

const TabContent = ({
    tabs,
    addNotification,
    updateTab,
    updateUrlAndTab,
    setTabTitle,
    closeTab,
    isTenantSelectorOpen,
    notifyError,
}: Props) => {
    const sideBarRef = useRef(null);

    const { tenant } = useAuth();

    const role = tenant?.role;
    const environmentsIsOn = tenant?.tenantSettings?.environments;
    const isAdministrator = role === SYSTEM_ROLES.administrator.developerName;

    useTabRouting(addNotification, role, environmentsIsOn ?? false, isAdministrator);

    const isGraphV3Enabled = getFlag('GRAPH3');

    const getTabComponent = (tab: Tab) => {
        let isNew = false;
        if (tab) {
            switch (tab.type) {
                // biome-ignore lint/suspicious/noFallthroughSwitchClause: Falling through is expected
                case 'newFlow':
                    isNew = true;
                case 'flow':
                    return (
                        <ErrorBoundary key={tab.key}>
                            {isGraphV3Enabled ? (
                                <FlowEditorStoreProvider>
                                    {({ dispatch }: FlowEditorDispatch) => (
                                        <FlowEditorSharedWrapper
                                            flowId={tab.elementId}
                                            notifyError={notifyError}
                                        >
                                            {({
                                                releases,
                                                environments,
                                                loadReleases,
                                                isLoadingReleases,
                                            }: FlowEditorSharedData) => (
                                                <FlowEditor
                                                    dispatch={dispatch}
                                                    flowId={tab.elementId}
                                                    tabKey={tab.key}
                                                    setTabTitle={setTabTitle}
                                                    isNew={isNew}
                                                    isActive={tab.isActive}
                                                    notifyError={notifyError}
                                                    notifySuccess={notifySuccess}
                                                    addNotification={addNotification}
                                                    updateUrlAndTab={updateUrlAndTab}
                                                    closeTab={closeTab}
                                                    releases={releases}
                                                    environments={environments}
                                                    loadReleases={loadReleases}
                                                    isLoadingReleases={isLoadingReleases}
                                                />
                                            )}
                                        </FlowEditorSharedWrapper>
                                    )}
                                </FlowEditorStoreProvider>
                            ) : (
                                <GraphProvider
                                    flowId={tab.elementId}
                                    isActive={tab.isActive}
                                    isNew={isNew}
                                    addNotification={addNotification}
                                    isTenantSelectorOpen={isTenantSelectorOpen}
                                >
                                    <DebugConfigProvider>
                                        <FlowEditorSharedWrapper
                                            flowId={tab.elementId}
                                            notifyError={notifyError}
                                        >
                                            {({
                                                releases,
                                                environments,
                                                loadReleases,
                                                isLoadingReleases,
                                            }: FlowEditorSharedData) => (
                                                <FlowEditorLegacy
                                                    flowId={tab.elementId}
                                                    sideBarRef={sideBarRef}
                                                    tabKey={tab.key}
                                                    isNew={isNew}
                                                    setTabTitle={setTabTitle}
                                                    releases={releases}
                                                    environments={environments}
                                                    loadReleases={loadReleases}
                                                    isLoadingReleases={isLoadingReleases}
                                                />
                                            )}
                                        </FlowEditorSharedWrapper>
                                    </DebugConfigProvider>
                                </GraphProvider>
                            )}
                        </ErrorBoundary>
                    );
                // biome-ignore lint/suspicious/noFallthroughSwitchClause: Falling through is expected
                case 'newPage':
                    isNew = true;
                case 'page':
                    return (
                        <PageEditor
                            tabConfig={tab}
                            addNotification={addNotification}
                            updateTab={updateTab}
                            updateUrlAndTab={updateUrlAndTab}
                            key={tab.key}
                        />
                    );
                default:
                    return <Admin isActive={tab.isActive} tabType={tab.type} key={tab.key} />;
            }
        }

        return null;
    };

    return (
        <div className="tabs flex-column">
            <ClipBoardProvider>
                <Tabs tabs={tabs} />
                <div className="tab-content-wrapper">{tabs.map(getTabComponent)}</div>
            </ClipBoardProvider>
        </div>
    );
};

const mapStateToProps = ({ tabs }: { tabs: Tab[] }) => {
    return {
        tabs,
    };
};

const mapDispatchToProps = {
    addNotification,
    updateTab,
    setTabTitle,
    updateUrlAndTab,
    closeTab,
    notifyError,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabContent);

import { format, addMinutes } from 'date-fns';
import type { Wait } from '../../../../../types';
import { isNullOrEmpty } from '../../../../../utils/guard';

interface Props {
    wait: Wait | null | undefined;
    className?: string;
}

/**
 * @description Displays the duration set on a wait map element
 */
const WaitDescription = ({ wait, className }: Props) => {
    const getWaitElementDescription = () => {
        if (isNullOrEmpty(wait) || !wait) {
            return '';
        }

        if (
            (wait.days as number) > 0 ||
            (wait.hours as number) > 0 ||
            (wait.minutes as number) > 0 ||
            (wait.seconds as number) > 0
        ) {
            return `${wait.days as number}d ${wait.hours as number}h ${wait.minutes as number}m ${
                wait.seconds as number
            }s`;
        }

        if (wait && !isNullOrEmpty(wait.absoluteDate)) {
            const date = new Date(wait?.absoluteDate);
            const utcDate = addMinutes(date, date.getTimezoneOffset());
            //date-fns shows 00:00 as 24:00 which is confusing
            if (format(utcDate, 'kk') === '24') {
                return format(utcDate, 'dd LLL y kk:mm').replace('24:', '00:');
            }
            return format(utcDate, 'dd LLL y kk:mm');
        }

        if (wait?.valueDeveloperName) {
            return wait.valueDeveloperName;
        }

        return wait.relative;
    };
    const waitElementDescription = getWaitElementDescription();

    return <div className={className}>{waitElementDescription}</div>;
};

export default WaitDescription;

import type { ValueElementIdReferenceAPI } from '../../../types/value';
import translations from '../../../translations';
import { getValueName, getValueType, isNonEditableValue } from './value-selector-utils';
import { useState } from 'react';
import AutoMappingMap from '../../types/AutoMappingMap';
import { ButtonFlavor, ButtonType, ExButton, ExIcon } from '@boomi/exosphere';

interface Props {
    value: ValueElementIdReferenceAPI;
    sourceTypeElementId?: string | undefined | null;
    onClick: (value: ValueElementIdReferenceAPI) => void;
    onEdit: (value: ValueElementIdReferenceAPI) => void;
}

const ValueSelectorValuesItem = ({ value, sourceTypeElementId, onClick, onEdit }: Props) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const getTargetTypeElementId = () => {
        if (value.typeElementPropertyTypeElementId) {
            return value.typeElementPropertyTypeElementId;
        }

        return value.typeElementId;
    };

    const targetTypeElementId = getTargetTypeElementId();

    return (
        <li>
            <button
                className="value-selector-values-option"
                onClick={() => onClick(value)}
                type="button"
            >
                <span className="value-selector-values-option-name">{getValueName(value)}</span>
                <span className="value-selector-values-option-meta">{getValueType(value)}</span>
            </button>
            {value.confidence !== null ? (
                <ExButton
                    type={ButtonType.TERTIARY}
                    flavor={ButtonFlavor.BRANDED}
                    title={translations.VALUE_auto_mapping_confidence_title}
                    onClick={() => setExpanded(!expanded)}
                >
                    <ExIcon
                        icon={expanded ? 'direction-arrowhead-down' : 'direction-arrowhead-right'}
                        slot="prefix"
                    />
                    {`${Math.ceil(value.confidence * 100)}% ${
                        translations.VALUE_auto_mapping_confidence_label
                    }`}
                </ExButton>
            ) : null}
            {isNonEditableValue(value) ? null : (
                <button
                    className="value-selector-values-option-edit"
                    onClick={() => onEdit(value)}
                    type="button"
                >
                    {translations.VALUE_SELECTOR_edit}
                </button>
            )}
            {expanded && sourceTypeElementId && targetTypeElementId ? (
                <AutoMappingMap
                    sourceTypeElementId={sourceTypeElementId}
                    targetTypeElementId={targetTypeElementId}
                />
            ) : null}
        </li>
    );
};

export default ValueSelectorValuesItem;

import classnames from 'classnames';

import { ELEMENT_EDGES } from '../../../../constants';
import { ArrowFatLeft, ArrowFatRight, ArrowFatUp, ArrowFatDown } from '@phosphor-icons/react';
import type { Edge } from '../../../dnd-utils';

const OuterArrows = ({ edge, isOverCurrent }: { edge: Edge | null; isOverCurrent: boolean }) => {
    const topClasses = classnames({
        'page-element-arrow': true,
        'page-element-arrow-top': true,
        active: edge === ELEMENT_EDGES['top'] && isOverCurrent,
    });

    const bottomClasses = classnames({
        'page-element-arrow': true,
        'page-element-arrow-bottom': true,
        active: edge === ELEMENT_EDGES['bottom'] && isOverCurrent,
    });

    const leftClasses = classnames({
        'page-element-arrow': true,
        'page-element-arrow-left': true,
        active: edge === ELEMENT_EDGES['left'] && isOverCurrent,
    });

    const rightClasses = classnames({
        'page-element-arrow': true,
        'page-element-arrow-right': true,
        active: edge === ELEMENT_EDGES['right'] && isOverCurrent,
    });

    return (
        <>
            <ArrowFatUp weight="fill" className={topClasses} />
            <ArrowFatDown weight="fill" className={bottomClasses} />
            <ArrowFatLeft weight="fill" className={leftClasses} />
            <ArrowFatRight weight="fill" className={rightClasses} />
        </>
    );
};

export default OuterArrows;

import { useRef } from 'react';
import { connect } from 'react-redux';
import { editNote } from '../../../../../actions/reduxActions/canvasNotes';
import { useGraph } from '../../../GraphProvider';

/**
 *
 * @param {Object} note The map element metadata
 * @param {Function} remove Deletes the note
 * @param {Function} edit Toggles whether the note is read only
 *
 * @description Form component for modifying a note
 */
const NoteForm = ({ note, edit, isNew }) => {
    const { saveNote, deleteMapElement, onTab, setDynamicNoteFocus } = useGraph();
    const subjectRef = useRef(null);
    const contentRef = useRef(null);

    const { developerName, userContent, id } = note;

    const onSave = () => {
        const updatedNote = {
            ...note,
            developerName: subjectRef.current.value,
            userContent: contentRef.current.value,
        };
        saveNote(updatedNote);
    };

    const onKeyDown = (event, step = null) => {
        if (event.key === 'Tab') {
            onTab(event);
        }
        // Custom focusing depending on where Enter/Space was hit.
        else if ((event.key === ' ' || event.key === 'Enter') && step !== null) {
            if (step === 'save') {
                onSave(id);
            } else if (step === 'cancel') {
                isNew ? deleteMapElement(id) : edit(id);
            }

            requestAnimationFrame(() => setDynamicNoteFocus(id, step));
        }
    };

    return (
        <div className="note-inner note-inner-form">
            <div className="form-group">
                <input
                    id={`${id}-name-input`}
                    placeholder="Add subject"
                    className="form-control"
                    ref={subjectRef}
                    defaultValue={developerName}
                    type="text"
                    tabIndex={-1}
                    onKeyDown={onKeyDown}
                />
            </div>
            <div className="form-group">
                <textarea
                    id={`${id}-content-input`}
                    placeholder="Add Note"
                    className="form-control"
                    ref={contentRef}
                    defaultValue={userContent}
                    tabIndex={-1}
                    onKeyDown={onKeyDown}
                />
            </div>
            <div className="note-form-footer">
                <button
                    id={`${id}-cancel`}
                    className="btn btn-sm btn-default"
                    onClick={isNew ? () => deleteMapElement(id) : () => edit(id)}
                    tabIndex={-1}
                    onKeyDown={(e) => onKeyDown(e, 'cancel')}
                    type="button"
                >
                    Cancel
                </button>
                <button
                    id={`${id}-save`}
                    className="btn btn-sm btn-primary"
                    onClick={onSave}
                    tabIndex={-1}
                    onKeyDown={(e) => onKeyDown(e, 'save')}
                    type="button"
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default connect(null, {
    edit: editNote,
})(NoteForm);

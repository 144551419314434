import boomiLogo from '../../../../img/boomi-logo.svg';
import { HOSTS } from '../../constants';

import '../../../../css/gob-agreement.less';

/**
 * @param {Function} acceptGovernmentPolicy It is called when the user agree with the policy
 * @description This component shows a government notice to be accepted by the user
 */
const GovernmentPolicy = ({ acceptGovernmentPolicy }: { acceptGovernmentPolicy: () => void }) => {
    return (
        <div className="container-full boomi-agreement-background">
            <div className="flex boomi-agreement-row">
                <div className="boomi-agreement-content">
                    <div className="text-center">
                        <img src={boomiLogo} width={207} height={75} alt="Boomi" />
                        <div className="mw-bs boomi-agreement-body center-block">
                            <h2 className="mw-bs boomi-agreement-title">
                                You are accessing a federal account
                            </h2>
                            <div className="boomi-agreement-text center-block text-left">
                                <p>
                                    This is a U.S. government service. Your use indicates your
                                    consent to monitoring, recording, and no expectation of privacy.
                                </p>
                                <p>
                                    <a href={`https://${HOSTS.federal}`}>{HOSTS.federal}</a>
                                    {` is a U.S. General Services Administration federal government service, for official
                                    use by U.S. government employees and contractors working on U.S. government digital services.`}
                                </p>
                                <p>
                                    {'All use of '}
                                    <a href={`https://${HOSTS.federal}`}>{HOSTS.federal}</a>
                                    {` may be monitored, recorded, and subject to audit, by ${HOSTS.federal} operations
                                    staff and other federal government authorities.
                                    There is no expectation of privacy for users of this system. By continuing to use this system,
                                    you consent to your use being monitored and recorded.`}
                                </p>
                                <p>Unauthorized use is prohibited.</p>
                                <button
                                    className="btn btn-primary btn-default margin-top"
                                    onClick={acceptGovernmentPolicy}
                                    type="button"
                                >
                                    Agree and continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="boomi-agreement-background" />
            </div>
        </div>
    );
};

export default GovernmentPolicy;

import ConfigSection from '../../ConfigSection';
import FormGroup from '../../../../../../../generic/FormGroup';

interface Props {
    className: string | null;
    updateClassName: (className: string) => void;
}

const StylesConfig = ({ className, updateClassName }: Props) => {
    return (
        <ConfigSection dataTestId="styles-section" title="Styles">
            <FormGroup label="CSS Classes" htmlFor="classname">
                <input
                    className="form-control"
                    id="classname"
                    value={className ?? ''}
                    onChange={({ target: { value } }) => updateClassName(value)}
                />
            </FormGroup>
        </ConfigSection>
    );
};

export default StylesConfig;

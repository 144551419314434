import { Component } from 'react';
import { pipe, filter, slice, sort, negate } from 'ramda';
import { getFlows } from '../../../ts/sources/dashboard';
import { TIME_FILTER, TIME_INTERVAL } from '../../../ts/components/dashboard/constants';
import { getTimeRange } from './getTimeRange';
import GenericSelectBox from '../../../ts/components/generic/GenericSelectBox';
import SearchInput from '../../../ts/components/generic/SearchInput';
import { exportToCsv } from '../../../ts/utils/csv';
import FlowLaunchesChart from './FlowLaunchesChart';
import Loader from '../../../ts/components/loader/Loader';
import Sortable from '../../../ts/components/generic/Sortable';
import Table from '../../../ts/components/generic/Table';
import translations from '../../../ts/translations';
import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import { DownloadSimple } from '@phosphor-icons/react';
import { addDays } from '../../../ts/utils/date';
import { safeToLower } from '../../../ts/utils/string';

const initialState = {
    allFlows: [],
    topFive: [],
    visibleFlows: [],
    isLoading: false,
    orderBy: 'count',
    orderDirection: 'DESC',
    page: 1,
    pageSize: 50,
    total: 0,
    filter: {
        dates: {
            from: null,
            to: null,
            groupBy: 'hourly',
            interval: TIME_INTERVAL.lastTwentyFourHours,
        },
    },
};

// creates a sorting function with ordering properties partially applied
export const createSorter =
    ({ orderBy, orderDirection }) =>
    (a, b) => {
        const result = safeToLower(a[orderBy]) > safeToLower(b[orderBy]) ? 1 : -1;

        return orderDirection === 'DESC' ? negate(result) : result;
    };

// queries the given collection of flows by supplied params
// returns an object containing total number matching the search query
// and filtered/paged collection of results
export const queryFlows = ({ allFlows, page, pageSize, query, orderBy, orderDirection }) => {
    const startAt = (page - 1) * pageSize;
    const endAt = startAt + pageSize;

    const filteredFlows = filter(
        (item) =>
            item.developerName
                .toString()
                .toUpperCase()
                .includes((query || '').toUpperCase()),
        allFlows,
    );

    const total = filteredFlows.length;

    const visibleFlows = pipe(
        sort(createSorter({ orderBy, orderDirection })),
        slice(startAt, endAt),
    )(filteredFlows);

    const topFive = visibleFlows.slice(0, 5);

    return {
        visibleFlows,
        topFive,
        total,
    };
};

export const fetchData = ({ orderBy, orderDirection, fromDate, toDate }) => {
    const urlParams = {
        orderBy,
        orderDirection,
        pageSize: 1000, // get all from API (API limit is 1000)
        page: 1,
    };

    urlParams.from = fromDate;
    urlParams.to = toDate;

    return getFlows(urlParams);
};

export const onReceiveFlows = (state, params) => {
    const { visibleFlows, topFive, total } = queryFlows({
        allFlows: params.allFlows,
        page: state.page,
        pageSize: state.pageSize,
        query: state.query,
        orderBy: state.orderBy,
        orderDirection: state.orderDirection,
    });

    return {
        allFlows: params.allFlows,
        isLoading: false,
        visibleFlows,
        topFive,
        total,
    };
};

export const onPage = (state, params) => {
    const { visibleFlows, total } = queryFlows({
        allFlows: state.allFlows,
        page: params.page,
        pageSize: state.pageSize,
        query: state.query,
        orderBy: state.orderBy,
        orderDirection: state.orderDirection,
    });

    return {
        page: params.page,
        visibleFlows,
        total,
    };
};

export const onSort = (state, params) => {
    const { visibleFlows, total } = queryFlows({
        allFlows: state.allFlows,
        page: 1,
        pageSize: state.pageSize,
        query: state.query,
        orderBy: params.orderBy,
        orderDirection: params.orderDirection,
    });

    return {
        page: 1,
        orderBy: params.orderBy,
        orderDirection: params.orderDirection,
        visibleFlows,
        total,
    };
};

export const onSearch = (state, params) => {
    const { visibleFlows, topFive, total } = queryFlows({
        allFlows: state.allFlows,
        page: 1,
        pageSize: state.pageSize,
        query: params.query,
        orderBy: state.orderBy,
        orderDirection: state.orderDirection,
    });

    return {
        query: params.query,
        page: 1,
        visibleFlows,
        topFive,
        total,
    };
};

export const onFilter = (_state, params) => {
    return {
        filter: {
            dates: params,
        },
        isLoading: true,
    };
};

export const reduce = (state, action, params) => {
    switch (action) {
        case 'loadFlows':
            return { isLoading: true };

        case 'receiveFlows':
            return onReceiveFlows(state, params);

        case 'page':
            return onPage(state, params);

        case 'sort':
            return onSort(state, params);

        case 'search':
            return onSearch(state, params);

        case 'filter':
            return onFilter(state, params);
    }
};

/**

 * @param {Function} onFlowClick Called when clicking a flow in the flow list. Passed the selected flow item data.
 * @description Controls and shows information about the flows within the current users tenant
 */
class FlowList extends Component {
    constructor() {
        super();
        this.state = initialState;

        this.update = this.update.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.exportResultsToCSV = this.exportResultsToCSV.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    async componentDidMount() {
        const { tenantId } = this.props;
        const { orderBy, orderDirection } = this.state;

        this.update('loadFlows');

        try {
            const { items: allFlows } = await fetchData({
                tenantId,
                orderBy,
                orderDirection,
                toDate: new Date().toISOString(),
                fromDate: addDays(new Date(), -1).toISOString(),
            });

            this.update('receiveFlows', { allFlows });
        } catch (_error) {
            this.setState(initialState);
        }
    }

    componentDidUpdate(_prevProps, prevState) {
        // Only make another request to the api if the date filter has changed
        if (
            prevState.filter.dates.from !== this.state.filter.dates.from ||
            prevState.filter.dates.to !== this.state.filter.dates.to
        ) {
            const { tenantId } = this.props;
            const { orderBy, orderDirection } = this.state;
            const fromDate = this.state.filter.dates.from;
            const toDate = this.state.filter.dates.to;

            fetchData({ tenantId, orderBy, orderDirection, fromDate, toDate })
                .then(({ items: allFlows }) => this.update('receiveFlows', { allFlows }))
                .catch(() => this.setState(initialState));
        }
    }

    onPage(page) {
        this.update('page', { page });
    }

    onSort({ orderBy, direction: orderDirection, page = 1 }) {
        this.update('sort', { orderBy, orderDirection, page });
    }

    onSearch(query) {
        this.update('search', { query });
    }

    onFilter({ target }) {
        this.update('filter', getTimeRange(target));
    }

    exportResultsToCSV() {
        const { allFlows, orderBy, orderDirection, query } = this.state;
        const { visibleFlows } = queryFlows({
            allFlows,
            query,
            orderBy,
            orderDirection,
            page: 1,
            pageSize: 1000, // get all from API (API limit is 1000)
        });

        const headerRow = ['Flow Name', 'Number of States'];
        const bodyRows = visibleFlows.map((flow) => [flow.developerName, flow.count]);
        const allRows = [headerRow, ...bodyRows];

        exportToCsv('flows.csv', allRows);
    }

    // The only method that alters state
    // Passes current state, an action name and params to a reducer
    // The pure reducer function returns a new section of state
    // with any state modifications
    update(action, params) {
        this.setState(reduce(this.state, action, params));
    }

    render() {
        const columns = [
            {
                renderHeader: () => (
                    <Sortable
                        defaultDirection={'ASC'}
                        direction={
                            this.state.orderBy === 'developerName'
                                ? this.state.orderDirection
                                : null
                        }
                        onSort={(direction) => this.onSort({ orderBy: 'developerName', direction })}
                    >
                        {translations.COMMON_TABLE_flow_name}
                    </Sortable>
                ),
                renderCell: ({ item }) => (
                    <button
                        className="link-emulate"
                        onClick={() => this.props.onFlowClick(item)}
                        type="button"
                    >
                        {item.developerName}
                    </button>
                ),
            },
            {
                renderHeader: () => (
                    <Sortable
                        defaultDirection={'DESC'}
                        direction={
                            this.state.orderBy === 'count' ? this.state.orderDirection : null
                        }
                        onSort={(direction) => this.onSort({ orderBy: 'count', direction })}
                    >
                        {translations.COMMON_TABLE_number_of_states}
                    </Sortable>
                ),
                renderCell: ({ item }) => item.count,
            },
        ];

        return (
            <div className="dashboard-flows">
                <h1>Dashboard</h1>
                <div className="flow-actions">
                    <GenericSelectBox
                        onFilter={this.onFilter}
                        selected={TIME_FILTER.lastTwentyFourHours}
                    >
                        <option value={TIME_FILTER.lastYear}>Last Year</option>
                        <option value={TIME_FILTER.lastThirtyDays}>Last 30 Days</option>
                        <option value={TIME_FILTER.lastSevenDays}>Last 7 Days</option>
                        <option value={TIME_FILTER.lastTwentyFourHours}>Last 24 Hours</option>
                    </GenericSelectBox>

                    <ButtonDefault
                        title="Download results in CSV format"
                        className="download-flows"
                        disabled={this.state.visibleFlows.length === 0}
                        onClick={this.exportResultsToCSV}
                    >
                        <DownloadSimple />
                    </ButtonDefault>

                    <SearchInput
                        value={this.state.query}
                        onChange={this.onSearch}
                        className="margin-left"
                    />
                </div>
                <FlowLaunchesChart
                    tenantId={this.props.tenantId}
                    title="Flow Launches"
                    flows={this.state.topFive}
                    interval={this.state.filter.dates.interval}
                />
                <Table
                    columns={columns}
                    items={this.state.visibleFlows}
                    isLoading={this.state.isLoading}
                    pagination={{
                        page: this.state.page,
                        total: this.state.total,
                        pageSize: this.state.pageSize,
                        changePage: this.onPage,
                    }}
                    rowClassName={() => 'generic-row generic-row-tall'}
                />
                {this.state.isLoading && <Loader />}
            </div>
        );
    }
}

export default FlowList;

import { useState, useEffect } from 'react';

import { SERIES_COLOURS } from '../../../ts/components/dashboard/constants';
import { getLaunchesByFlow } from '../../../ts/sources/dashboard';
import GenericChart from '../../../ts/components/generic/GenericChart';
import Loader from '../../../ts/components/loader/Loader';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';

const flowLaunchesChartOptions = {
    layout: {
        padding: {
            bottom: 30,
        },
    },
    tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
            label: (tooltipItem) => `${tooltipItem.yLabel} flow launches`,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            ticks: {
                callback: (value) => (Number.isInteger(value) ? value : null), // just show integer ticks
            },
        },
    },
    legend: {
        position: 'left',
    },
};

export const formatData = (response) => {
    const formattedChartData = [];

    response.forEach((dataSet, index) => {
        if (dataSet.flowLaunchTotal) {
            const formattedData = dataSet.flowLaunchData.map((dp) => ({
                x: dp.bucket,
                y: dp.counter,
            }));

            formattedChartData.push({
                data: formattedData,
                label: dataSet.flowName,
                backgroundColor: SERIES_COLOURS[index],
                borderColor: SERIES_COLOURS[index],
            });
        }
    });

    return formattedChartData;
};

const FlowLaunchesChart = ({ title, flows, interval }) => {
    const [chartErrors, setChartErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);

    const fetchData = async () => {
        setIsLoading(true);

        const flowDataRequests = flows.map((flow) => getLaunchesByFlow(flow.id, interval));

        try {
            const responses = await Promise.all(flowDataRequests);

            // Pull out buckets from first data set so they can be used as labels in the chart.
            const labels = responses[0].flowLaunchData.map((dp) => dp.bucket);
            // Re-format the data so the chart library can use it.
            const data = formatData(responses);

            setChartLabels(labels);
            setChartData(data);
            setIsLoading(false);
        } catch (error) {
            const currErrors = [...chartErrors];
            currErrors.push(`Error: ${error.message}`);
            setChartErrors(currErrors);
            setIsLoading(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (flows.length && !isLoading) {
            fetchData();
        }
    }, [flows, interval]);

    const renderErrors = () =>
        chartErrors.map((error, index) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: Requires refactor to add unique ids to errors
            <ExAlertBanner key={index} type={AlertBannerType.ERROR} open data-testid="alert">
                {error}
            </ExAlertBanner>
        ));

    const renderChart = () => {
        const chartProps = {
            options: flowLaunchesChartOptions,
            type: 'bar',
            height: '360px',
            title,
            data: {
                labels: chartLabels,
                datasets: chartData,
            },
        };

        return chartData.length && !isLoading ? (
            <GenericChart {...chartProps} />
        ) : chartErrors.length ? null : (
            <div className="table-no-results-message">No Launches To Display</div>
        );
    };

    return (
        <div style={{ position: 'relative', minHeight: '360px' }}>
            {isLoading ? (
                <Loader message="Loading Flow Launches" />
            ) : (
                <>
                    {renderErrors()}
                    {renderChart()}
                </>
            )}
        </div>
    );
};

export default FlowLaunchesChart;

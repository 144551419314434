import '../../../../css/toggle.less';
import classnames from 'classnames';

interface Props {
    isOn: boolean;
    onChange: (args: { event: React.ChangeEvent<HTMLInputElement>; isOn: boolean }) => void;
    className?: string;
    testId?: string;
    isDisabled?: boolean;
    id?: string;
}

const Toggle = ({
    isOn,
    onChange,
    className = '',
    testId = '',
    isDisabled = false,
    id = '',
}: Props) => {
    const classes = classnames({
        toggle: true,
        [className]: true,
        'is-on': isOn,
        disabled: isDisabled,
    });

    const onToggleChangedClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isDisabled) {
            onChange({ event, isOn: !isOn });
        }
    };

    const conditionalProps: { id?: string } = {};

    if (id !== '') {
        conditionalProps.id = id;
    }

    return (
        <input
            {...conditionalProps}
            type="checkbox"
            className={classes}
            data-testid={testId}
            checked={isOn}
            disabled={isDisabled}
            onChange={onToggleChangedClick}
        />
    );
};

export default Toggle;

import ButtonIcon from '../buttons/ButtonIcon';
import classnames from 'classnames';
import ButtonsReorder from '../buttons/ButtonsReorder';

import { type ReactNode, useRef } from 'react';
import { CaretDown, CaretRight } from '@phosphor-icons/react';
import type { TableColumnList } from './Table';
import { isNullOrWhitespace } from '../../utils/guard';

interface RowOrdering {
    canOrder: boolean;
    onOrder: (currentIndex: number, newIndex: number) => void;
}

export interface TableRowProps<TItem> {
    rowIndex: number;
    columns: TableColumnList<TItem>;
    isSelected: boolean;
    renderExpandedRow: undefined | ((item: TItem) => ReactNode);
    item: TItem;
    ordering: RowOrdering;
    classes: string;
    onClick: () => void;
    totalItems: number;
    tableId: string | undefined;
}

const TableRow = <TItem,>({
    rowIndex,
    renderExpandedRow,
    columns,
    isSelected,
    item,
    ordering,
    classes,
    onClick,
    totalItems,
}: TableRowProps<TItem>) => {
    const ref = useRef<HTMLTableRowElement>(null);

    const { canOrder = false, onOrder } = ordering;

    return (
        <>
            <tr
                ref={ref}
                data-testid={`Row ${rowIndex + 1}`}
                className={classes}
                onClick={onClick}
                onKeyDown={onClick}
            >
                {renderExpandedRow && (
                    <td>
                        <ButtonIcon
                            className="btn-expand"
                            onClick={onClick}
                            title={isSelected ? 'Collapse row' : 'Expand row'}
                        >
                            {isSelected ? <CaretDown size={20} /> : <CaretRight size={20} />}
                        </ButtonIcon>
                    </td>
                )}
                {columns.map((column, cellIndex) => (
                    <td
                        className={classnames({
                            'generic-cell': isNullOrWhitespace(column?.cellClassName),
                            [column.cellClassName as string]: !isNullOrWhitespace(
                                column?.cellClassName,
                            ),
                            'flex-end-content': cellIndex === columns.length - 1 && canOrder,
                        })}
                        // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index here
                        key={cellIndex}
                    >
                        {cellIndex === columns.length - 1 && canOrder && (
                            <ButtonsReorder
                                totalRows={totalItems}
                                rowIndex={rowIndex}
                                onReorder={onOrder}
                            />
                        )}
                        {column.renderCell({
                            item,
                            rowIndex,
                            cellIndex,
                        })}
                    </td>
                ))}
            </tr>
            {renderExpandedRow && isSelected && (
                <tr className="table-row-details" data-testid="table-row-details">
                    <td className="table-data-details" colSpan={columns.length + 1}>
                        {renderExpandedRow(item)}
                    </td>
                </tr>
            )}
        </>
    );
};

export default TableRow;

import { X } from '@phosphor-icons/react';
import Modal from '../generic/modal/GenericModal';
import type { PlayerName } from '../../sources/player';
import translations from '../../translations';
import ButtonDefault from '../buttons/ButtonDefault';
import './player-editor.less';
import type { Flow } from './PlayerEditor';
import { stringReplace } from '../../utils/string';

interface Props {
    tenantId: string;
    selectedFlow: Flow | undefined;
    selectedPlayerName: PlayerName;
    showPreview: boolean;
    onClose: () => void;
}

const PlayerPreviewModal = ({
    tenantId,
    selectedFlow,
    selectedPlayerName,
    showPreview,
    onClose: handleClose,
}: Props) => {
    if (!selectedFlow) {
        return null;
    }

    const title = stringReplace(translations.PLAYER_preview_modal_title, {
        flowName: selectedFlow.name,
        playerName: selectedPlayerName,
    });

    const show = showPreview && !!selectedFlow;

    return (
        <Modal
            onHide={handleClose}
            className="player-preview-modal"
            bodyClassName="player-preview-modal-body"
            title={title}
            show={show}
            renderBody={() => (
                <iframe
                    className="player-preview"
                    title={translations.PLAYER_preview_iframe_title}
                    key={`${selectedPlayerName}-preview`}
                    seamless
                    src={`/${tenantId}/play/${selectedPlayerName}?flow-id=${selectedFlow.id}&flow-version-id=${selectedFlow.versionId}`}
                />
            )}
            renderFooter={() => (
                <ButtonDefault onClick={handleClose} testId="player-preview-modal-cancel-button">
                    <X size={16} weight="bold" className="player-button-icon" />
                    {translations.PLAYER_preview_close_button_label}
                </ButtonDefault>
            )}
        />
    );
};

export default PlayerPreviewModal;

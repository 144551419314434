import classnames from 'classnames';
import { CloudArrowUp } from '@phosphor-icons/react';
import {
    type ChangeEventHandler,
    type DragEventHandler,
    type KeyboardEventHandler,
    useEffect,
    useRef,
    useState,
} from 'react';
import { getFileExtension } from '../../../utils/file';
import Loader from '../../loader/Loader';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import translations from '../../../translations';
import './css/file-drop.less';
import { guid } from '../../../utils/guid';

interface Props {
    onChange?: (files: File[]) => void;
    className?: string;
    onError?: (files: File[]) => void;
    isEnabled?: boolean;
    fileTypes?: string;
    /* Max size in MB */
    maxSize?: number;
    isUploading?: boolean;
    placeholder?: string;
}

const FileDrop = ({
    onChange: onChangeHandler,
    className,
    onError = () => undefined,
    isEnabled = true,
    fileTypes = '',
    maxSize,
    isUploading = false,
    placeholder = 'Drag and drop, or browse your files',
}: Props) => {
    const [showUploadError, setShowUploadError] = useState(false);
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const elementId = guid();

    const clearFiles = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const onChange = (files: FileList) => {
        const fileList = Array.from(files);

        if (!isEnabled || files === null || files.length === 0) {
            return;
        }

        setShowUploadError(false);

        const allowedFiles = fileList.filter((file) => {
            const extension = (getFileExtension as (str: string) => string)(file.name);

            if (maxSize && file.size / 1024 / 1024 > maxSize) {
                return false;
            }

            if (
                fileTypes.length > 0 &&
                !fileTypes
                    .split(',')
                    .map((str) => str.replace('.', '').toLowerCase())
                    .includes(extension.toLowerCase())
            ) {
                return false;
            }

            return true;
        });

        // If we now have no files one or more must have been disallowed.
        if (allowedFiles.length < fileList.length) {
            setShowUploadError(true);
            onError(fileList);
        } else if (onChangeHandler) {
            onChangeHandler(fileList);
        }

        clearFiles();
    };

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const { files: newFiles } = event.target;

        if (!isEnabled || newFiles === null || newFiles.length === 0) {
            return;
        }

        onChange(newFiles);
    };

    const onDrop: DragEventHandler = (event) => {
        const { files: newFiles } = event.dataTransfer;

        if (!isEnabled || newFiles === null || newFiles.length === 0) {
            return;
        }

        onChange(newFiles);
    };

    const onDragEnter = () => {
        setIsDraggingOver(true);
    };

    const onDragLeave = () => {
        setIsDraggingOver(false);
    };

    const onDropTargetClick = () => {
        inputRef.current?.click();
    };

    const onDropTargetKeyDown: KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
        if (key === 'Enter') {
            inputRef.current?.click();
        }
    };

    const dropTargetClassName = classnames('file-drop-target', {
        active: isEnabled && isDraggingOver,
    });

    useEffect(() => {
        /** Prevent the browser opening the dropped file in a new tab */
        const preventDefault = (event: DragEvent) => {
            event.preventDefault();
        };

        window.addEventListener('dragover', preventDefault);
        window.addEventListener('drop', preventDefault);

        return () => {
            window.removeEventListener('dragover', preventDefault);
            window.removeEventListener('drop', preventDefault);
        };
    }, []);

    return (
        <div className={classnames('file-drop', className, { disabled: !isEnabled })}>
            {isUploading && <Loader message="Uploading files" />}
            <input
                data-testid="file-drop-input"
                ref={inputRef}
                id={elementId}
                type="file"
                multiple
                disabled={!isEnabled}
                onChange={onInputChange}
                accept={fileTypes}
            />
            <div
                data-testid="file-drop-target"
                className={dropTargetClassName}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                onClick={onDropTargetClick}
                onKeyDown={onDropTargetKeyDown}
                role="button"
                tabIndex={0}
                aria-controls={elementId}
            >
                <CloudArrowUp width="100%" height="100%" className="file-drop-target-image" />
                {placeholder && <span className="file-drop-target-message">{placeholder}</span>}
            </div>
            <span className="quiet">
                {fileTypes.length > 0 && (
                    <span>Allowed file types: {fileTypes.replace(/,\./g, ', .')}. </span>
                )}
                {maxSize && <span>Max size: {maxSize}MB</span>}
            </span>
            {showUploadError && (
                <ExAlertBanner
                    open
                    type={AlertBannerType.ERROR}
                    className="block margin-top"
                    data-testid="file-disallowed-error"
                >
                    {translations.ASSET_upload_not_accepted}
                </ExAlertBanner>
            )}
        </div>
    );
};

export default FileDrop;

import { type ComponentPropsWithoutRef, useState } from 'react';
import { connect } from 'react-redux';
import { updateDeveloperName } from '../../../js/actions/reduxActions/currentTenant';
import { SUBTENANT_PARENT_NAME_SEPARATOR, TENANT_NAME_PREFIX } from '../../constants';
import translations from '../../translations';
import { getTenantNameValidityMessage } from '../../utils/validation';
import TenantNameInput from './TenantNameInput';

interface DeveloperNameProps {
    developerName: string;
    parentTenantName?: string;
    updateDeveloperName: (updatedName: string) => void;
    setIsConfigValid: (isValid: boolean) => void;
}

// Remove the tenant name prefix and parent name suffix
const getIsolatedTenantName = (name: string, isolatedParentName: string) =>
    name
        .replace(TENANT_NAME_PREFIX, '')
        .replace(`${SUBTENANT_PARENT_NAME_SEPARATOR}${isolatedParentName}`, '');

const DeveloperName = ({
    developerName,
    parentTenantName = '',
    updateDeveloperName,
    setIsConfigValid,
}: DeveloperNameProps) => {
    const isolatedParentName = parentTenantName.replace(TENANT_NAME_PREFIX, '');
    const isolatedDeveloperName = getIsolatedTenantName(developerName, isolatedParentName);
    const isSubtenant = !!parentTenantName;

    const [tenantName, setTenantName] = useState(isolatedDeveloperName);

    const handleChange: ComponentPropsWithoutRef<typeof TenantNameInput>['onChange'] = ({
        value,
    }) => {
        setTenantName(value);

        const isValid = getTenantNameValidityMessage(value).length === 0;
        setIsConfigValid(isValid);

        const nameToSave = isValid
            ? value
            : getIsolatedTenantName(developerName, isolatedParentName);

        const formattedName = isSubtenant
            ? `${nameToSave}${SUBTENANT_PARENT_NAME_SEPARATOR}${isolatedParentName}`
            : nameToSave;

        updateDeveloperName(formattedName);
    };

    return (
        <>
            <TenantNameInput
                labelText={translations.TENANT_name_input_label}
                inputId="tenant-developer-name"
                value={tenantName}
                onChange={handleChange}
                parentTenantName={parentTenantName}
                isRequired={false}
            />
            <span className="help-block">
                {isSubtenant ? null : translations.TENANT_subtenant_rename_warning}
            </span>
        </>
    );
};

const mapStateToProps = ({
    currentTenant: { developerName, parentTenant },
}: {
    currentTenant: {
        developerName: string;
        parentTenant: {
            developerName: string;
        } | null;
    };
}) => ({
    developerName,
    parentTenantName: parentTenant?.developerName ?? '',
});

const mapDispatchToProps = {
    updateDeveloperName,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperName);

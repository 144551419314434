import classNames from 'classnames';
import { Copy } from '@phosphor-icons/react';
import '../../../../css/clipboard.less';
import ComponentWithTooltip from '../generic/tooltip/ComponentWithTooltip';

interface Props {
    value: string;
    buttonTitle?: string;
    placeholder?: string;
    inputClassName?: string;
    inputId?: string;
}

const ClipboardInput = ({
    value,
    buttonTitle = 'Click to Copy',
    placeholder,
    inputClassName,
    inputId,
}: Props) => {
    const inputClasses = classNames(inputClassName, {
        'grouped-control-input': true,
    });

    return (
        <div className="grouped-control" data-testid="clipboard-input">
            <input
                id={inputId}
                className={inputClasses}
                readOnly
                onClick={({ currentTarget }) => currentTarget.select()}
                value={value}
                size={60}
                placeholder={placeholder}
            />
            <ComponentWithTooltip
                trigger={'click'}
                hideDelay={1000}
                fadeTime={0.2}
                tooltipContent="Copied"
            >
                <button
                    id="clipboard-input-button"
                    className="grouped-control-button clipboard-copy"
                    title={buttonTitle}
                    onClick={() => {
                        navigator.clipboard.writeText(value);
                    }}
                    type="button"
                >
                    <Copy />
                </button>
            </ComponentWithTooltip>
        </div>
    );
};

export default ClipboardInput;

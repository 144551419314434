import {
    createContext,
    useContext,
    type ReactNode,
    useReducer,
    type Reducer,
    useState,
} from 'react';
import type { ComposerElement, Page, PageContainer, AddNotification } from '../../../../../types';
import { usePageEditor } from '../../PageEditorProvider';
import { clone, assocPath } from 'ramda';
import { TAB_TYPES } from '../../../../../constants';
import { useAuth } from '../../../../AuthProvider';
import { guid } from '../../../../../utils/guid';
import { PAGE_ELEMENT_TYPES } from '../../../constants';
import { useClipBoard } from '../../../../../ClipBoardProvider';

import ComposerReducer, { type ComposerAction } from './ComposerReducer';
import translations from '../../../../../translations';
import { useNavigate, type NavigateFunction } from 'react-router-dom';
import type { ContainerType } from '../../dnd-utils';

export interface ElementSelection {
    id?: string | null;
    targetId: string | null;
    order?: number;
    containerType?: string | undefined | null;
    pageElementType?: string;
    componentType?: string | undefined;
    select?: boolean;
    edit?: boolean;
    partialElement?: Partial<ComposerElement>;
    title?: string;
    message?: string;
}

interface ComposerProviderContext {
    dragDropElements: ComposerElement[];
    elementToDelete: ElementSelection | null;
    setElementToDelete: (selection: ElementSelection | null) => void;
    toggleSelectedElement: (id: string) => void;
    generateDragDropElements: () => void;
    onSavePage: (updatedPage: Page) => void;
    onPageElementDrop: (args: ElementSelection) => void;
    onPageElementCopy: (selection: ElementSelection) => void;
    onPageElementPaste: (selection: ElementSelection) => void;
    onPageElementRemove: (selection: ElementSelection | null) => void;
    onSetPageElementActive: (id: string | null) => void;
    onPageContainerTypeUpdate: (containerId: string, containerType: ContainerType) => void;
    onPageElementUpdate: (id: string, path: string[], value: unknown) => void;
    elementToEdit: string | null;
    setElementToEdit: (elementId: string) => void;
    elementSelected: string | null;
    addNotification: AddNotification;
}

const Context = createContext<ComposerProviderContext | undefined>(undefined);

interface ComposerProviderProps {
    updateUrlAndTab: (
        {
            key,
            type,
            title,
            elementId,
            tenantId,
        }: {
            key: string;
            type: string;
            title: string | null;
            elementId: string;
            tenantId: string;
        },
        navigate: NavigateFunction,
    ) => void;
    children: ReactNode;
}

const ComposerProvider = ({ updateUrlAndTab, children }: ComposerProviderProps) => {
    const { tenant } = useAuth();
    const { copy, paste } = useClipBoard();

    const navigate = useNavigate();

    const {
        state: pageBuilderState,
        updateTab,
        onSave,
        updatePage,
        addNotification,
    } = usePageEditor();
    const { tab, page } = pageBuilderState;

    const [dragDropElements, dispatch] = useReducer<Reducer<ComposerElement[], ComposerAction>>(
        ComposerReducer,
        [],
    );

    const [elementToDelete, setElementToDelete] = useState<ElementSelection | null>(null);
    const [elementToEdit, setElementToEdit] = useState<string | null>(null);
    const [elementSelected, setElementSelected] = useState<string | null>(null);

    const update = (action: ComposerAction, hasUnsavedChanges = true) => {
        dispatch(action);

        if (tab !== null) {
            const { key, showConfirmation } = tab;

            // Update the confirmation flag in the Tabs Redux store
            // only when there are unsaved changes as well the flag
            // not already set to true.
            if (hasUnsavedChanges && !showConfirmation) {
                updateTab({
                    ...tab,
                    key,
                    showConfirmation: true,
                });
            }
        }
    };

    const toggleSelectedElement = (id: string) => {
        setElementSelected(id === elementSelected ? null : id);
    };

    const generateDragDropElements = () => {
        const flattendContainers = [] as ComposerElement[];

        const clonedPage = clone(page);

        const flattenPageContainers = (
            containers: PageContainer[],
            parentContainerId: string | null = null,
        ) => {
            containers.forEach((container) => {
                if (container.pageContainers) {
                    flattenPageContainers(container.pageContainers, container.id);
                }

                // Inclusion of nested containers
                // would cause data duplication
                container.pageContainers = null;

                const element = container as ComposerElement;

                // The parent container ID is used as a foreign key
                element.parentId = parentContainerId;
                flattendContainers.push(element);
            });
        };

        const { pageComponents, pageContainers } = clonedPage;

        flattenPageContainers(pageContainers || []);

        const formattedPageComponents = pageComponents
            ? (pageComponents.map((pageComponent) => ({
                  ...pageComponent,
                  parentId: pageComponent.pageContainerId,
              })) as ComposerElement[])
            : [];

        update(
            {
                type: 'set_composer_elements',
                payload: flattendContainers.concat(formattedPageComponents),
            },
            false,
        );
    };

    const generateNestedContainers = (
        flattenedPageContainers: ComposerElement[],
        parent: string | null | undefined,
    ) => {
        const containersNested: PageContainer[] = [];
        flattenedPageContainers.forEach((pageContainer) => {
            if (pageContainer.parentId === parent) {
                const children = generateNestedContainers(
                    flattenedPageContainers,
                    pageContainer.id || '',
                );

                const containerWithChildren: ComposerElement = assocPath(
                    ['pageContainers'],
                    children.length ? children : null,
                    pageContainer,
                );

                delete containerWithChildren.parentId;
                containersNested.push(containerWithChildren);
            }
        });

        return containersNested;
    };

    const generatePageComponents = (pageElements: ComposerElement[]) => {
        return pageElements
            .filter((element) => Object.prototype.hasOwnProperty.call(element, 'componentType'))
            .map((pageComponent) => {
                const formattedPageComponent = {
                    ...pageComponent,
                    pageContainerId: pageComponent.parentId,
                    pageContainerDeveloperName: pageElements.find(
                        (pe) => pe.id === pageComponent.parentId,
                    )?.developerName,
                };
                delete formattedPageComponent.parentId;
                return formattedPageComponent;
            });
    };

    const onSavePage = async (updatedPage: Page) => {
        const pageExists = updatedPage.id;

        const root = dragDropElements.find((childContainer) => childContainer.parentId === null);
        const nestedPageContainers = generateNestedContainers(
            dragDropElements.filter((container) =>
                Object.prototype.hasOwnProperty.call(container, 'containerType'),
            ),
            root?.parentId,
        );

        const pageComponents = generatePageComponents(dragDropElements);
        // biome-ignore lint/style/noParameterAssign: Requires dedicated refactor
        updatedPage = assocPath(['pageContainers'], nestedPageContainers, updatedPage);
        // biome-ignore lint/style/noParameterAssign: Requires dedicated refactor
        updatedPage = assocPath(
            ['pageComponents'],
            pageComponents.length > 0 ? pageComponents : null,
            updatedPage,
        );

        const isRenamed = updatedPage.developerName !== page.developerName;
        const savedPage = await onSave(updatedPage);

        if (savedPage && tab) {
            if (!pageExists || isRenamed) {
                updateUrlAndTab(
                    {
                        key: tab?.key || '',
                        type: TAB_TYPES.page,
                        title: savedPage.developerName,
                        elementId: savedPage.id || '',
                        tenantId: tenant?.id || '',
                    },
                    navigate,
                );
            } else {
                updateTab({
                    ...tab,
                    key: tab.key,
                    showConfirmation: false,
                });
            }

            updatePage(savedPage);
        }
    };

    const onPageElementDrop = ({
        id,
        targetId,
        order,
        containerType,
        pageElementType,
        componentType,
        select = true,
        edit = !id,
        partialElement = {} as Partial<ComposerElement>,
    }: ElementSelection) => {
        const elementId = id || guid();

        if (containerType && pageElementType === PAGE_ELEMENT_TYPES['container']) {
            update({
                type: 'drop_composer_container',
                payload: {
                    id: elementId,
                    parentId: targetId,
                    order: order as number,
                    containerType,
                    partialElement,
                },
            });
        }

        if (componentType && pageElementType === PAGE_ELEMENT_TYPES['component']) {
            update({
                type: 'drop_composer_component',
                payload: {
                    id: elementId,
                    parentId: targetId,
                    order: order as number,
                    componentType,
                    partialElement,
                },
            });
        }

        if (edit) {
            setElementToEdit(elementId);
            if (select) {
                setElementSelected(elementId);
            }
        }
    };

    const onPageElementCopy = ({ targetId }: ElementSelection) => {
        const clonedElements = [];

        const findChildElements = (parentId: string | null) => {
            const childElements = dragDropElements.filter((dde) => dde.parentId === parentId);
            if (childElements.length > 0) {
                childElements.forEach((ce) => {
                    findChildElements(ce.id);
                    clonedElements.push(ce);
                });
            }
        };

        const elementToClone = dragDropElements.find(
            (dde) => dde.id === targetId,
        ) as ComposerElement;

        findChildElements(elementToClone.id);

        clonedElements.push({
            ...elementToClone,
            parentId: null,
        });

        copy(clonedElements);

        addNotification({
            type: 'success',
            message: translations.PAGE_element_copy_success,
            isPersistent: false,
        });
    };

    const onPageElementPaste = ({ targetId }: ElementSelection) => {
        const elementToPasteInto = dragDropElements.find((dde) => dde.id === targetId);

        if (Object.prototype.hasOwnProperty.call(elementToPasteInto, 'containerType')) {
            try {
                const elementsToPaste = paste() as ComposerElement[];

                if (elementsToPaste.length === 0) {
                    throw Error(translations.PAGE_element_paste_error);
                }

                const clonedElements = [];

                const findChildElementsAndReplaceIds = (
                    parentId: string | null,
                    newParentId: string,
                ) => {
                    const childElements = elementsToPaste.filter(
                        (dde) => dde.parentId === parentId,
                    );
                    if (childElements.length > 0) {
                        childElements.forEach((ce) => {
                            const newChildId = guid();
                            findChildElementsAndReplaceIds(ce.id, newChildId);
                            clonedElements.push({ ...ce, id: newChildId, parentId: newParentId });
                        });
                    }
                };

                const elementToClone = elementsToPaste.find(
                    (dde) => dde.parentId === null,
                ) as ComposerElement;

                const newId = guid();

                findChildElementsAndReplaceIds(elementToClone.id, newId);

                const rootChildElements = dragDropElements.filter(
                    (dde) => dde.parentId === targetId,
                );
                clonedElements.push({
                    ...elementToClone,
                    id: newId,
                    parentId: targetId,
                    order: rootChildElements.length,
                });

                update({
                    type: 'set_composer_elements',
                    payload: dragDropElements.concat(clonedElements),
                });
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        }
    };

    const onPageElementRemove = (selection: ElementSelection | null) => {
        update({
            type: 'remove_composer_element',
            payload: {
                id: selection?.targetId || '',
            },
        });
    };

    const onSetPageElementActive = (id: string | null) => {
        setElementToEdit(id === elementToEdit ? null : id);
    };

    const onPageContainerTypeUpdate = (containerId: string, containerType: ContainerType) => {
        update({
            type: 'set_composer_container_type',
            payload: {
                containerId,
                containerType,
            },
        });
    };

    const onPageElementUpdate = (id: string, path: string[], value: unknown) => {
        update({
            type: 'set_composer_element',
            payload: {
                id,
                path,
                value,
            },
        });
    };

    const contextValue = {
        dragDropElements,
        elementToDelete,
        setElementToDelete,
        toggleSelectedElement,
        generateDragDropElements,
        onSavePage,
        onPageElementDrop,
        onPageElementCopy,
        onPageElementPaste,
        onPageElementRemove,
        onSetPageElementActive,
        onPageContainerTypeUpdate,
        onPageElementUpdate,
        elementToEdit,
        setElementToEdit,
        elementSelected,
        addNotification,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useComposer = (): ComposerProviderContext => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useComposer must be used within a ComposerProvider');
    }
    return context;
};

export { ComposerProvider, useComposer };

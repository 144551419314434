import DataSourceListConfig from './DataSourceListConfig';
import DataSourceConnectorConfig from './DataSourceConnectorConfig';
import ConfirmModal from '../../../../../../../generic/modal/ConfirmModal';
import translations from '../../../../../../../../translations';
import ConfigSection from '../../ConfigSection';

import { usePageEditor } from '../../../../../PageEditorProvider';
import { type ChangeEvent, useState } from 'react';

import type {
    ValueElementIdAPI,
    ObjectDataRequest,
    column,
    ComponentConfigurationOptions,
    Confirmation,
} from '../../../../../../../../types';

import {
    COMPONENT_CONFIGURATION_LABELS as componentConfigurationLabels,
    DATA_SOURCE as sourceOptions,
} from '../../../../../../constants';

interface Props {
    dataSourceList: ValueElementIdAPI | null;
    dataSourceConnector: ObjectDataRequest | null;
    updateDataSourceList: (value: ValueElementIdAPI | null) => void;
    updateDataSourceConnector: (connector: ObjectDataRequest | null) => void;
    updateDataPresentation: (columns: column[] | null) => void;
    requiredConfiguration: ComponentConfigurationOptions;
}

const DataSourceConfig = ({
    dataSourceList,
    dataSourceConnector,
    updateDataSourceList,
    updateDataSourceConnector,
    updateDataPresentation,
    requiredConfiguration,
}: Props) => {
    const { container } = usePageEditor();

    // Figure out the data source when clicking a component in the page.
    const currentDataSource =
        dataSourceList && !dataSourceConnector
            ? sourceOptions['LIST']
            : !dataSourceList && dataSourceConnector
              ? sourceOptions['CONNECTOR']
              : null;

    const [confirmProps, setConfirmProps] = useState<Confirmation | null>(null); // confirmation modal

    // Changing what will be the data source between List and Connector so reset the current setup
    // to the default values for whatever option was picked, and reset the other source type config
    // to null.
    const resetDataSource = (clickedOption: string) => {
        if (clickedOption === sourceOptions['LIST']) {
            updateDataSourceList({ id: null, typeElementPropertyId: null, command: null });
            updateDataSourceConnector(null);
        } else {
            updateDataSourceList(null);
            updateDataSourceConnector({
                typeElementBindingId: null,
                typeElementId: null,
                listFilter: null,
                command: null,
                typeElementDeveloperName: null,
            });
        }

        updateDataPresentation(null);
    };

    const updateDataSourceConnectorAndClearColumns = (dataSourceConnector: ObjectDataRequest) => {
        updateDataSourceConnector(dataSourceConnector);
        updateDataPresentation(null);
    };

    const changeDataSource = (event: ChangeEvent<HTMLInputElement>) => {
        const clickedOption = event.target.value;

        // If no source exists on the component then just (pre)set it to the clicked option.
        if (!currentDataSource) {
            resetDataSource(clickedOption);
        }

        // If a source exists and is potentially configured, but we want to change to another
        // source option then any existing Data Source config will be lost/reset!
        if (currentDataSource && clickedOption !== currentDataSource) {
            setConfirmProps({
                show: true,
                title: translations.PAGE_BUILDER_data_source_modal_title,
                messages: [
                    'Changing the Data Source will reset any existing data source and data source filter configuration.',
                ],
                buttonStyle: 'danger',
                buttonCaption: 'OK',
                onCancel: () => setConfirmProps(null),
                onConfirm: () => {
                    resetDataSource(clickedOption);
                    setConfirmProps(null);
                },
                container,
            });
        }
    };

    const renderConfig = () =>
        currentDataSource === sourceOptions['LIST'] ? (
            <DataSourceListConfig
                dataSourceList={dataSourceList}
                updateDataSourceList={updateDataSourceList}
                requiredConfiguration={requiredConfiguration}
            />
        ) : (
            <DataSourceConnectorConfig
                dataSourceConnector={dataSourceConnector}
                updateDataSourceConnectorType={updateDataSourceConnectorAndClearColumns}
                updateDataSourceConnectorBinding={updateDataSourceConnector}
                requiredConfiguration={requiredConfiguration}
            />
        );

    return (
        <>
            <ConfigSection dataTestId="data-source-config" title="Data source">
                <fieldset className="radio-button-group">
                    <label className="radio-button-label" htmlFor={sourceOptions['LIST']}>
                        <input
                            className="radio-button-input"
                            type="radio"
                            id={sourceOptions['LIST']}
                            name="dataSource"
                            value={sourceOptions['LIST']}
                            onChange={changeDataSource}
                            checked={currentDataSource === sourceOptions['LIST']}
                        />
                        {componentConfigurationLabels['DATA_SOURCE_LIST']}
                    </label>
                    <label className="radio-button-label" htmlFor={sourceOptions['CONNECTOR']}>
                        <input
                            className="radio-button-input"
                            type="radio"
                            id={sourceOptions['CONNECTOR']}
                            name="dataSource"
                            value={sourceOptions['CONNECTOR']}
                            onChange={changeDataSource}
                            checked={currentDataSource === sourceOptions['CONNECTOR']}
                        />
                        {componentConfigurationLabels['DATA_SOURCE_CONNECTOR']}
                    </label>
                </fieldset>

                {currentDataSource ? renderConfig() : <p>Select a data source option...</p>}
            </ConfigSection>
            {confirmProps ? <ConfirmModal {...confirmProps} /> : null}
        </>
    );
};

export default DataSourceConfig;

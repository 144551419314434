import { ExSegmentedControls, ExSegmentedControl } from '@boomi/exosphere';
import type { Outcome, AddNotification } from '../../../types';
import { useState } from 'react';
import type { TimePeriod } from '../../../sources/outcomeinsights';

import OutcomeInsightsChart from './OutcomeInsightsChart';
import GenericModal from '../../generic/modal/GenericModal';
import ButtonDefault from '../../buttons/ButtonDefault';
import FormGroup from '../../generic/FormGroup';

import './insights.less';

interface Props {
    outcomes: Partial<Outcome>[] | null;
    isOpen: boolean;
    container?: HTMLElement;
    mapElementId: string;
    flowId: string;
    onClose: () => void;
    addNotification: AddNotification;
}

const OutcomeInsights = ({
    outcomes,
    isOpen,
    container,
    mapElementId,
    flowId,
    onClose,
    addNotification,
}: Props) => {
    const outcomesToDisplay = outcomes ?? [];
    const initialOutcome = outcomesToDisplay[0];

    const [timePeriod, setTimePeriod] = useState<TimePeriod>('day');
    const [selectedOutcomeId, setSelectedOutcomeId] = useState<string | null>(
        initialOutcome?.id ?? null,
    );

    const selectedOutcome = outcomesToDisplay.find((outcome) => outcome.id === selectedOutcomeId);

    const onOutcomeSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setSelectedOutcomeId(e.target.value);
    };

    const renderHeader = () => <h4 className="modal-title">Insights</h4>;

    const renderBody = () => (
        <div id="outcome-insights">
            {selectedOutcome ? (
                <>
                    <div className="ex-container">
                        <div className="ex-row">
                            <div className="ex-col-xs-12 ex-col-sm-9 ex-col-md-10 ex-col-lg-9 align-center flex">
                                <div className="ex-box-row">
                                    <ExSegmentedControls>
                                        <ExSegmentedControl
                                            selected={timePeriod === 'day'}
                                            onClick={() => setTimePeriod('day')}
                                            label="Daily"
                                        />
                                        <ExSegmentedControl
                                            onClick={() => {
                                                setTimePeriod('week');
                                            }}
                                            selected={timePeriod === 'week'}
                                            label="Weekly"
                                        />
                                        <ExSegmentedControl
                                            onClick={() => setTimePeriod('month')}
                                            selected={timePeriod === 'month'}
                                            label="Monthly"
                                        />
                                    </ExSegmentedControls>
                                </div>
                            </div>
                            <div className="ex-col-xs-12 ex-col-sm-3 ex-col-md-2 ex-col-lg-3">
                                <div className="ex-box-row">
                                    <FormGroup label="Outcome" htmlFor="outcome-select">
                                        <select
                                            id="outcome-select"
                                            value={selectedOutcomeId ?? ''}
                                            onChange={onOutcomeSelect}
                                            className="form-control"
                                        >
                                            {outcomesToDisplay.map((outcome) => (
                                                <option
                                                    key={outcome.id as string}
                                                    value={outcome.id as string}
                                                >
                                                    {outcome.developerName}
                                                </option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OutcomeInsightsChart
                        outcomeId={selectedOutcome?.id}
                        outcomeName={selectedOutcome?.developerName}
                        mapElementId={mapElementId}
                        flowId={flowId}
                        timePeriod={timePeriod}
                        addNotification={addNotification}
                    />
                </>
            ) : (
                'There are no insights for this map element'
            )}
        </div>
    );

    const renderFooter = () => (
        <ButtonDefault title="Back" onClick={onClose}>
            Close
        </ButtonDefault>
    );

    return (
        <GenericModal
            renderHeader={renderHeader}
            renderBody={renderBody}
            renderFooter={renderFooter}
            show={isOpen}
            container={container}
            onHide={onClose}
            backdropClassName="no-zindex"
        />
    );
};
export default OutcomeInsights;

import { useEffect, useState, type FormEvent } from 'react';
import Table from '../../generic/Table';
import translations from '../../../translations';
import type { Environment, EnvironmentIntegrationAccount } from '../../../types/environment';
import type { TenantResponseAPI, IntegrationAccountEnvironments } from '../../../types/Tenant';
import { getAllIntegrationAccountEnvironments, getCurrentTenant } from '../../../sources/tenant';
import { NOTIFICATION_TYPES } from '../../../constants';

interface Props {
    environment: Environment;
    updateEnvironment: (environment: Environment) => void;
    addNotification: (notification: unknown) => void;
}

const IntegrationEnvironments = ({ environment, updateEnvironment, addNotification }: Props) => {
    const [tenant, setTenant] = useState<TenantResponseAPI | null>(null);
    const [environments, setEnvironments] = useState<IntegrationAccountEnvironments | null>(null);
    const [loading, setLoading] = useState(true);

    const loadIntegrationEnvironments = async () => {
        setLoading(true);

        try {
            setTenant(await getCurrentTenant(environment.tenantId));
            setEnvironments(await getAllIntegrationAccountEnvironments(environment.tenantId));
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        } finally {
            setLoading(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        loadIntegrationEnvironments();
    }, [environment.id]);

    if (!(environment && tenant)) {
        return null;
    }

    const onIntegrationEnvironmentChange = (accountId: string, environmentId: string) => {
        let integrationAccounts = environment.integrationAccounts;

        if (!integrationAccounts) {
            integrationAccounts = [];
        }

        if (environment.integrationAccounts.find((account) => account.accountId === accountId)) {
            integrationAccounts = environment.integrationAccounts.map((account) => {
                if (account.accountId === accountId) {
                    return { accountId, environmentId };
                }

                return account;
            });
        } else {
            integrationAccounts.push({ accountId, environmentId });
        }

        updateEnvironment({
            ...environment,
            integrationAccounts,
        });
    };

    const accounts: EnvironmentIntegrationAccount[] =
        tenant?.tenantSettings?.integrationAccounts?.map((account) => {
            return {
                accountId: account.accountId,
                environmentId:
                    environment.integrationAccounts?.find(
                        (environmentAccount) => environmentAccount.accountId === account.accountId,
                    )?.environmentId || '',
            };
        }) ?? [];

    const columns = [
        {
            renderHeader: () => translations.TENANT_integration_accounts_account_id as string,
            renderCell: ({ item }: { item: EnvironmentIntegrationAccount }) => item.accountId,
        },
        {
            renderHeader: () => translations.ENVIRONMENT_integration_account_environment,
            renderCell: ({ item }: { item: EnvironmentIntegrationAccount }) => {
                return (
                    <select
                        className="form-control"
                        disabled={loading}
                        value={item.environmentId}
                        onChange={(e: FormEvent<HTMLSelectElement>) =>
                            onIntegrationEnvironmentChange(item.accountId, e.currentTarget.value)
                        }
                        aria-label={`${item.accountId} Environments`}
                    >
                        <option>
                            {translations.ENVIRONMENT_select_integration_account_environment}
                        </option>
                        {environments?.[item.accountId]?.map((env) => (
                            <option value={env.id} key={env.id}>
                                {env.name}
                            </option>
                        ))}
                    </select>
                );
            },
        },
    ];

    return (
        <Table<EnvironmentIntegrationAccount>
            tableClass="generic-table margin-top"
            items={accounts || []}
            columns={columns}
            isLoading={loading}
        />
    );
};

export default IntegrationEnvironments;

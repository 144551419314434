import translations from '../../../../ts/translations';
import SearchInput from '../../../../ts/components/generic/SearchInput';
import { ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS } from '../../../../ts/components/admin/environments/constants';

const ReleasesListFilters = ({
    environments,
    onDateTimeFilterChange,
    dateTimeOption,
    onEnvironmentFilterChange,
    environmentFilter,
    onSearchTermFilterChange,
    searchTermFilter,
}) => {
    return (
        <div className="flex margin-bottom margin-top filterHeading padding">
            <select
                id="datetime-filter"
                className="form-control form-control-dynamic inline-block margin-right"
                onChange={(e) => onDateTimeFilterChange(e.target.value)}
                value={dateTimeOption}
                data-testid="date-time-filter"
            >
                <option
                    key={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.all}
                    value={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.all}
                >
                    {ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.all}
                </option>
                <option
                    key={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastYear}
                    value={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastYear}
                >
                    {ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastYear}
                </option>
                <option
                    key={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastThirtyDays}
                    value={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastThirtyDays}
                >
                    {ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastThirtyDays}
                </option>
                <option
                    key={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastSevenDays}
                    value={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastSevenDays}
                >
                    {ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastSevenDays}
                </option>
                <option
                    key={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastTwentyFourHours}
                    value={ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastTwentyFourHours}
                >
                    {ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastTwentyFourHours}
                </option>
            </select>
            {environments && (
                <select
                    id="environment-filter"
                    className="form-control form-control-dynamic inline-block margin-right"
                    onChange={(e) => onEnvironmentFilterChange(e.target.value)}
                    value={environmentFilter}
                    data-testid="environment-filter"
                >
                    <option key="" value="">
                        {translations.ENVIRONMENT_filters_select_environment}
                    </option>
                    {environments.map((environment) => (
                        <option key={environment.id} value={environment.id}>
                            {environment.name}
                        </option>
                    ))}
                </select>
            )}
            <SearchInput
                className="tab-header-search inline-block"
                onChange={onSearchTermFilterChange}
                testId="search-term-filter"
                value={searchTermFilter}
            />
        </div>
    );
};

export default ReleasesListFilters;

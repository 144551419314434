import { type CSSProperties, useState } from 'react';
import Select from 'react-select/creatable';
import { notifyError as notifyErrorAction } from '../../../js/actions/reduxActions/notification';
import { connect } from 'react-redux';
import translations from '../../translations';
import type { StylesConfig } from 'react-select';
import { getSharedStyles } from '../../utils/select';
import { stringReplace } from '../../utils/string';

interface Props {
    currentLabels: string[];
    allFlowLabels: string[];
    saveFlowLabels: (labels: string[]) => void;
    notifyError: (error: string) => void;
}

type SelectedLabel = { label: JSX.Element; value: string };

type IsMulti = true;

const controlStyle: CSSProperties = {
    boxShadow: 'none',
    border: 'none',
    margin: 0,
};

const optionStyle: CSSProperties = {
    margin: 0,
};

const multiValueStyle: CSSProperties = {
    border: '1px solid',
    borderColor: '#033d58',
    borderRadius: '5px',
    color: '#033d58',
    backgroundColor: 'white',
    marginTop: 2,
    marginBottom: 2,
    marginRight: 5,
    marginLeft: 0,
};

const multiValueLabelStyle: CSSProperties = {
    color: '#033d58',
    margin: 1,
};

const valueContainerStyle: CSSProperties = {
    padding: '2px 0px',
};

const selectStyle: StylesConfig<SelectedLabel, IsMulti> = {
    ...getSharedStyles(),
    // Custom styles
    control: (baseStyles, state) => ({
        ...baseStyles,
        ...controlStyle,
        borderBottom: state.isFocused ? '2px #127b87 solid' : '2px #127b87 dotted',
        borderRadius: '0px',
        ':hover': {
            ...baseStyles[':hover'],
            cursor: 'pointer',
            borderBottom: state.isFocused ? '2px #127b87 solid' : '2px #127b87 dotted',
        },
        height: '2.3rem',
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        ...optionStyle,
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        ...multiValueStyle,
        borderRadius: '2rem',
    }),
    multiValueLabel: (baseStyles) => ({
        ...baseStyles,
        ...multiValueLabelStyle,
    }),
    multiValueRemove: (baseStyles) => ({
        ...baseStyles,
        '&:hover': {
            color: 'white',
            backgroundColor: '#033d58',
            borderTopRightRadius: '1rem',
            borderBottomRightRadius: '1rem',
        },
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        ...valueContainerStyle,
        display: 'flex',
        flexWrap: 'nowrap',
    }),
};

const FlowLabels = (props: Props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const getLabels = () => {
        return props.currentLabels
            .map((lbl) => ({
                label: <span title={lbl}>{lbl}</span>,
                value: lbl,
            }))
            .sort();
    };

    const getAllLabels = () => {
        if (props.allFlowLabels !== undefined) {
            return props.allFlowLabels.map((lbl) => ({
                label: <span>{lbl}</span>,
                value: lbl,
            }));
        }
        return [];
    };

    const setLabels = (selectedLabels: readonly SelectedLabel[]) => {
        let canSave = true;
        for (const lbl of selectedLabels) {
            if (!props.currentLabels.includes(lbl.value)) {
                if (lbl.value.length > 50) {
                    canSave = false;
                    const errorString = stringReplace(
                        translations.HOME_label_maximum_length_error,
                        lbl.value,
                    );
                    props.notifyError(errorString);
                }
            }
        }
        if (canSave) {
            const labelValues: string[] = selectedLabels.map((lbl) => lbl.value).sort() || [];
            props.saveFlowLabels(labelValues);
        }
    };

    return (
        <div className="flow-tags-container">
            <Select
                value={getLabels()}
                options={getAllLabels()}
                isMulti={true}
                isClearable={false}
                onChange={setLabels}
                isSearchable
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
                styles={selectStyle}
                placeholder={translations.HOME_label_placeholder}
                noOptionsMessage={() => translations.HOME_flow_filter_none}
                menuPosition="fixed"
                menuPortalTarget={document.body}
                // This is an optimisation as calling getElementsByClassName when the table has 50+ elements causes the browser to chug.
                // Store if the menu is open in state and only attempt the closeMenuOnScroll if it is. This should really be default but react-select sucks.
                onMenuOpen={() => setMenuOpen(true)}
                onMenuClose={() => setMenuOpen(false)}
                closeMenuOnScroll={(e) => {
                    if (menuOpen) {
                        return e.target === document.getElementsByClassName('admin-page')[0];
                    }

                    return false;
                }}
            />
        </div>
    );
};
const mapDispactToProps = {
    notifyError: notifyErrorAction,
};

export default connect(null, mapDispactToProps)(FlowLabels);

import { PAGE_ELEMENT_TYPES } from '../../../../constants';
import type { ContextMenuItem } from '../../../../../../types';

interface Props {
    menuItem: ContextMenuItem;
    contextMenuId: string;
    closeContextMenu: () => void;
}

const PageElementMenuItem = ({ menuItem, contextMenuId, closeContextMenu }: Props) => {
    const { icon, caption, shortcut = null, rowType = null, clickAction } = menuItem;

    const onClickHandler = () => {
        clickAction({
            id: null,
            targetId: contextMenuId,
            order: 0,
            containerType: rowType,
            pageElementType: PAGE_ELEMENT_TYPES['container'],
        });

        closeContextMenu();
    };

    return (
        <button type="button" className="composer-context-menu-item" onClick={onClickHandler}>
            <div className="menu-item-icon">{icon}</div>
            <div className="menu-item-label">{caption}</div>
            <div className="menu-item-shortcut">{shortcut}</div>
        </button>
    );
};

export default PageElementMenuItem;

import { sortPropertyByTypeElementDeveloperName } from '../../sources/type';
import './automapping-map.css';
import type { TypeElementResponseAPI, TypeMappingAutoAPI } from '../../types';
import PropertyDetails from './PropertyDetails';

type Types = Record<string, TypeElementResponseAPI>;

interface Props {
    targetTypeElementId: string;
    types: Types;
    mapping: TypeMappingAutoAPI | null | undefined;
}

const hasUnmappedNestedProperty = (
    targetType: TypeElementResponseAPI,
    mapping: TypeMappingAutoAPI | null | undefined,
    types: Types,
): boolean => {
    for (const property of targetType.properties) {
        const mappedProperty = mapping?.properties.find(
            (p) => p.targetTypeElementEntryId === property.id,
        );

        if (!(property.typeElementId || mappedProperty)) {
            return true;
        }
        if (property.typeElementId && mappedProperty) {
            return hasUnmappedNestedProperty(
                types[property.typeElementId],
                mappedProperty.children,
                types,
            );
        }
    }

    return false;
};

const AutoMappingTypeUnMatched = ({ targetTypeElementId, types, mapping }: Props) => {
    const targetType = types[targetTypeElementId];

    return (
        <ol key={targetType.id}>
            {targetType.properties.sort(sortPropertyByTypeElementDeveloperName).map((property) => {
                const mappedProperty = mapping?.properties.find(
                    (p) => p.targetTypeElementEntryId === property.id,
                );

                if (mappedProperty && !hasUnmappedNestedProperty(targetType, mapping, types)) {
                    return null;
                }

                return (
                    <li key={property.id}>
                        {!mappedProperty || property.typeElementId ? (
                            <PropertyDetails
                                property={property}
                                types={types}
                                isMatched={false}
                                showMatch={false}
                            />
                        ) : null}
                        {property.typeElementId ? (
                            <AutoMappingTypeUnMatched
                                targetTypeElementId={property.typeElementId}
                                types={types}
                                mapping={mappedProperty?.children}
                            />
                        ) : null}
                    </li>
                );
            })}
        </ol>
    );
};

export default AutoMappingTypeUnMatched;

import {
    ExAccordion,
    ExAccordionItem,
    ExStructuredList,
    ExStructuredListBody,
    ExStructuredListCol,
    ExStructuredListRow,
} from '@boomi/exosphere';
import type { ResponseHeaders, RootFaultsResponseApi, SingleFault } from '../../../../../types';

import RootFaultResponseHeaders from './RootFaultResponseHeaders';
import ErrorBoundary from '../../../../generic/ErrorBoundary';

interface Props {
    rootFaults: RootFaultsResponseApi | null;
}

interface RootFaultsToDisplay {
    fault: string;
    details: SingleFault;
}

const RootFaultsAccordion = ({ rootFaults }: Props) => {
    const rootFaultsToDisplay: RootFaultsToDisplay[] | null = rootFaults
        ? Object.keys(rootFaults).map((key) => ({
              fault: key,
              details: JSON.parse(rootFaults[key]) as SingleFault,
          }))
        : null;

    return (
        <>
            {rootFaultsToDisplay ? (
                <ExAccordion>
                    {rootFaultsToDisplay.map((rootFaultToDisplay) => (
                        <ExAccordionItem
                            key={rootFaultToDisplay.fault}
                            label={rootFaultToDisplay.fault}
                        >
                            <ExStructuredList>
                                <ExStructuredListBody>
                                    {Object.keys(rootFaultToDisplay.details).map((key) => {
                                        const detail = rootFaultToDisplay.details[key];
                                        return (
                                            <ExStructuredListRow key={key}>
                                                {/* Flow does not strictly type the root faults so using an error boundary to be safe */}
                                                <ErrorBoundary>
                                                    {key === 'responseHeaders' && detail ? (
                                                        <RootFaultResponseHeaders
                                                            headers={detail as ResponseHeaders}
                                                        />
                                                    ) : (
                                                        <ExStructuredListCol>
                                                            <div>
                                                                <b>{key}</b>
                                                            </div>
                                                            <div className="ex-fs-micro">
                                                                {detail as string}
                                                            </div>
                                                        </ExStructuredListCol>
                                                    )}
                                                </ErrorBoundary>
                                            </ExStructuredListRow>
                                        );
                                    })}
                                </ExStructuredListBody>
                            </ExStructuredList>
                        </ExAccordionItem>
                    ))}
                </ExAccordion>
            ) : (
                <div className="center">No root faults to display</div>
            )}
        </>
    );
};

export default RootFaultsAccordion;

import '../../../css/footer.less';
import translations from '../translations';
import { stringReplace } from '../utils';

const Footer = () => (
    <div className="footer">
        <span className="footer-item">
            <a
                className="footer-link"
                href="https://status.boomi.com/"
                rel="noopener noreferrer"
                target="_blank"
            >
                {translations.FOOTER_platform_status_and_announcements_label}
            </a>
        </span>
        <span className="footer-item">
            {stringReplace(translations.FOOTER_copyright_label, {
                date: new Date().getFullYear(),
            })}
        </span>
        <span className="footer-item">
            <a
                className="footer-link"
                href="https://boomi.com/privacy/"
                rel="noopener noreferrer"
                target="_blank"
            >
                {translations.FOOTER_privacy_label}
            </a>
        </span>
    </div>
);

export default Footer;

import { GraphElementType } from '../types';
import { getFlag } from '../utils/flags';

export interface ElementDescription {
    id: GraphElementType;
    name: string;
    tooltip: {
        title: string;
        content: string;
    };
    flow: string | null;
    display: boolean;
    group?: string;
}

export const getElements: () => ElementDescription[] = () => [
    {
        id: GraphElementType.outcome,
        name: 'Outcome',
        tooltip: {
            title: 'Outcome',
            content: '',
        },
        flow: 'Outcome',
        display: false,
    },
    {
        id: GraphElementType.start,
        name: 'Start',
        tooltip: {
            title: 'Start',
            content: '',
        },
        flow: 'Map Element Start',
        display: false,
    },
    {
        id: GraphElementType.step,
        name: 'Step',
        tooltip: {
            title: 'Step',
            content: 'Display rich text content to the user',
        },
        flow: 'Map Element Step',
        display: true,
        group: 'blue',
    },
    {
        id: GraphElementType.input,
        name: 'Page',
        tooltip: {
            title: 'Page',
            content: 'Display complex forms to the user',
        },
        flow: 'Map Element Page',
        display: true,
        group: 'blue',
    },
    {
        id: GraphElementType.modal,
        name: 'Modal',
        tooltip: {
            title: 'Modal',
            content: 'Display forms to the user in a pop up window',
        },
        flow: null,
        display: true,
        group: 'blue',
    },
    {
        id: GraphElementType.decision,
        name: 'Decision',
        tooltip: {
            title: 'Decision',
            content: 'Change the path through the flow based on criteria',
        },
        flow: 'Map Element Decision',
        display: true,
        group: 'green',
    },
    {
        id: GraphElementType.operator,
        name: 'Operator',
        tooltip: {
            title: 'Operator',
            content: 'Create & update values',
        },
        flow: 'Map Element Operator',
        display: true,
        group: 'green',
    },
    {
        id: GraphElementType.message,
        name: 'Message',
        tooltip: {
            title: 'Message',
            content: 'Send requests to 3rd party systems',
        },
        flow: 'Map Element Message',
        display: true,
        group: 'green',
    },
    {
        id: GraphElementType.process,
        name: 'Process',
        tooltip: {
            title: 'Process',
            content: 'Execute an Integration Process',
        },
        flow: null,
        display: true,
        group: 'green',
    },
    {
        id: GraphElementType.wait,
        name: 'Wait',
        tooltip: {
            title: 'Wait',
            content: 'Add a wait into your flow',
        },
        flow: null,
        display: true,
        group: 'green',
    },
    {
        id: GraphElementType.database_load,
        name: 'Load',
        tooltip: {
            title: 'Load',
            content: 'Load data from an external source',
        },
        flow: 'Map Element Database Load',
        display: true,
        group: 'red',
    },
    {
        id: GraphElementType.database_save,
        name: 'Save',
        tooltip: {
            title: 'Save',
            content: 'Save data to an external source',
        },
        flow: 'Map Element Database Save',
        display: true,
        group: 'red',
    },
    {
        id: GraphElementType.database_delete,
        name: 'Delete',
        tooltip: {
            title: 'Delete',
            content: 'Delete data from an external source',
        },
        flow: 'Map Element Database Delete',
        display: true,
        group: 'red',
    },
    {
        id: GraphElementType.openapi,
        name: 'OpenAPI',
        tooltip: {
            title: 'OpenAPI',
            content: 'Connect with OpenAPI endpoints',
        },
        flow: null,
        display: getFlag('OAPI2'),
        group: 'red',
    },
    {
        id: GraphElementType.swimlane,
        name: 'Swimlane',
        tooltip: {
            title: 'Swimlane',
            content: 'Restrict access to part of a flow to specific users or groups',
        },
        flow: 'Map Element Swimlane',
        display: true,
    },
    {
        id: GraphElementType.subflow,
        name: 'Subflow',
        tooltip: {
            title: 'Subflow',
            content: 'Move into a child flow',
        },
        flow: null,
        display: true,
        group: 'grey',
    },
    {
        id: GraphElementType.return,
        name: 'Return',
        tooltip: {
            title: 'Return',
            content: 'Return back to the parent flow',
        },
        flow: null,
        display: true,
        group: 'grey',
    },
    {
        id: GraphElementType.group,
        name: 'Group',
        tooltip: {
            title: 'Group',
            content: 'Organize your flow at build time using groups',
        },
        flow: null,
        display: true,
    },
    {
        id: GraphElementType.note,
        name: 'Note',
        tooltip: {
            title: 'Note',
            content: 'Add annotations to your flow',
        },
        flow: null,
        display: true,
    },
];

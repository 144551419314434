import { createRef, useState } from 'react';
import type { AddNotification, ThemeEdit } from '../../types';
import ThemeDetail from './ThemeDetail';
import ThemeList from './ThemeList';

interface Props {
    addNotification: AddNotification;
}

const Themes = ({ addNotification }: Props) => {
    const [itemToEdit, setItemToEdit] = useState<ThemeEdit | null>(null);
    const wrapperRef = createRef<HTMLDivElement>();

    return (
        <div className="page-wrapper" ref={wrapperRef}>
            {itemToEdit === null ? (
                <ThemeList setItemToEdit={setItemToEdit} addNotification={addNotification} />
            ) : (
                <ThemeDetail
                    container={wrapperRef}
                    theme={itemToEdit}
                    returnToList={() => setItemToEdit(null)}
                    addNotification={addNotification}
                />
            )}
        </div>
    );
};

export default Themes;

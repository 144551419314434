import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const Select = ({
    label,
    isRequired,
    isEditable,
}: {
    label: string;
    isRequired: boolean;
    isEditable: boolean;
}) => {
    return (
        <div data-testid="component-preview-select" className="component-preview-select">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            <select disabled={!isEditable}>
                <option>Option 1</option>
                <option>Option 2</option>
                <option>Option 3</option>
            </select>
        </div>
    );
};

export default Select;

import type { MacroElementRequestAPI, MacroElementResponseAPI } from '../types';
import { CheckStatus, parse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export const macroSource = {
    get: async (id: string) => {
        const response = await fetch(`/api/draw/1/element/macro/${id}`, {
            headers: {
                ManyWhoTenant: getTenantId(),
            },
        });

        return JSON.parse(await (await CheckStatus(response)).text()) as MacroElementResponseAPI;
    },

    getAll: async () => {
        const response = await fetch('/api/draw/1/element/macro/', {
            headers: {
                ManyWhoTenant: getTenantId(),
            },
        });

        return JSON.parse(await (await CheckStatus(response)).text()) as MacroElementResponseAPI[];
    },

    save: async (request: MacroElementRequestAPI) => {
        const response = await fetch('/api/draw/1/element/macro', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ManyWhoTenant: getTenantId(),
            },
            body: JSON.stringify(request),
        });

        return (await parse(await CheckStatus(response))) as MacroElementResponseAPI;
    },

    delete: async (id: string) => {
        const response = await fetch(`/api/draw/1/element/macro/${id}`, {
            method: 'delete',
            headers: {
                ManyWhoTenant: getTenantId(),
            },
        });

        parse(await CheckStatus(response));
    },
};

import { useCollaboration } from '../../../../../ts/collaboration/CollaborationProvider';
import { FLOW_EDITING_TOKEN } from '../../../../../ts/constants';
import {
    getGroupElement as getGroupElementAPI,
    saveGroupElement as saveGroupElementAPI,
} from '../../../../../ts/sources/graph';
import { guid } from '../../../../../ts/utils/guid';

const useGroup = ({ flowId, notifyError, setIsLoading, refreshFlow, focusAndSelectElement }) => {
    const { invoke } = useCollaboration();

    const getGroupElement = async (id) => {
        try {
            return await getGroupElementAPI(id, flowId, FLOW_EDITING_TOKEN);
        } catch (error) {
            notifyError(error);
        }
    };

    const saveGroupElement = async (groupElement) => {
        try {
            // Validate that all the selected users/groups have an attribute selected
            if (
                (groupElement.authorization?.groups &&
                    (groupElement.authorization.groups.some(
                        (item) => item.authenticationId === -1,
                    ) ||
                        groupElement.authorization.groups.some((item) => item.attribute === -1))) ||
                (groupElement.authorization?.users &&
                    (groupElement.authorization.users.some(
                        (item) => item.authenticationId === -1,
                    ) ||
                        groupElement.authorization.users.some((item) => item.attribute === -1)))
            ) {
                throw Error('Please select items from all of the dropdowns');
            }

            if (!groupElement.id) {
                groupElement.id = guid();
            }

            setIsLoading(true);

            const updatedGroupElement = await saveGroupElementAPI(
                groupElement,
                flowId,
                FLOW_EDITING_TOKEN,
            );

            invoke('GraphChanged', flowId, [updatedGroupElement.id], null);

            await refreshFlow();
            focusAndSelectElement(updatedGroupElement.id);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        getGroupElement,
        saveGroupElement,
    };
};

export default useGroup;

import type { PageComponentPreviewProps } from '../../../../../types';
import PreviewComponentLabel from '../previewComponentLabel/PreviewComponentLabel';
import { QrCode } from '@phosphor-icons/react';

const CodeGenerator = ({ label, isRequired }: PageComponentPreviewProps) => {
    return (
        <div
            data-testid="component-preview-code-generator"
            className="component-preview-code-generator"
        >
            <PreviewComponentLabel
                className="component-preview-code-generator-label"
                labelText={label}
                isRequired={isRequired}
            />
            <div
                data-testid="component-preview-code-generator-mock"
                className="component-preview-code-generator-mock"
            >
                <QrCode
                    data-testid="component-preview-code-generator-mock-icon"
                    className="component-preview-code-generator-mock-icon"
                    weight="thin"
                    size={192}
                />
            </div>
        </div>
    );
};

export default CodeGenerator;

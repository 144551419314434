import {
    Clipboard,
    Columns,
    Copy,
    DotsThreeOutline,
    Rows,
    Tabs,
    Trash,
} from '@phosphor-icons/react';
import { memo, useEffect, useRef, type ElementRef } from 'react';
import { useDocumentClick } from '../../../../../../hooks';
import type { ComposerElement, ContextMenuItem } from '../../../../../../types';
import { CONTAINER_LABELS, CONTAINER_TYPE } from '../../../../constants';
import type { ElementSelection } from '../ComposerProvider';
import PageElementMenuItem from './PageElementMenuItem';

interface Props {
    contextMenuId: string | null;
    isOpen: boolean;
    top: number;
    left: number;
    elementTargeted: ComposerElement | undefined;
    onPageElementDrop: (selection: ElementSelection) => void;
    setElementToDelete: (selection: ElementSelection) => void;
    closeContextMenu: (targetElementId?: string | null) => void;
    onPageElementCopy: (selection: ElementSelection) => void;
    onPageElementPaste: (selection: ElementSelection) => void;
}

const PageElementMenu = ({
    contextMenuId,
    isOpen,
    top,
    left,
    elementTargeted,
    onPageElementDrop,
    setElementToDelete,
    closeContextMenu,
    onPageElementCopy,
    onPageElementPaste,
}: Props) => {
    const menuRef = useRef<ElementRef<'nav'>>(null);

    let menuItems: ContextMenuItem[] = [
        {
            icon: <Copy />,
            caption: 'Copy',
            shortcut: 'CTRL-C',
            clickAction: onPageElementCopy,
        },
        {
            icon: <Trash />,
            caption: 'Delete',
            shortcut: 'DELETE',
            separator: true,
            clickAction: setElementToDelete,
        },
    ];

    const pasteMenuItem: ContextMenuItem = {
        icon: <Clipboard />,
        caption: 'Paste',
        shortcut: 'CTRL-V',
        clickAction: onPageElementPaste,
    };

    const containerMenuItems: ContextMenuItem[] = [
        {
            icon: <Rows />,
            caption: `Add ${CONTAINER_LABELS[CONTAINER_TYPE['row']]} Container`,
            shortcut: '---',
            rowType: 'VERTICAL_FLOW',
            clickAction: onPageElementDrop,
        },
        {
            icon: <Columns />,
            caption: `Add ${CONTAINER_LABELS[CONTAINER_TYPE['column']]} Container`,
            shortcut: '---',
            rowType: 'HORIZONTAL_FLOW',
            clickAction: onPageElementDrop,
        },
        {
            icon: <DotsThreeOutline />,
            caption: `Add ${CONTAINER_LABELS[CONTAINER_TYPE['inline']]} Container`,
            shortcut: '---',
            rowType: 'INLINE_FLOW',
            clickAction: onPageElementDrop,
        },
        {
            icon: <Tabs />,
            caption: `Add ${CONTAINER_LABELS[CONTAINER_TYPE['group']]} Container`,
            shortcut: '---',
            rowType: 'GROUP',
            clickAction: onPageElementDrop,
        },
    ];

    const isContainer = elementTargeted
        ? Object.prototype.hasOwnProperty.call(elementTargeted, 'containerType')
        : false;

    if (isContainer) {
        menuItems.splice(1, 0, pasteMenuItem);
        menuItems = [...menuItems, ...containerMenuItems];
    }

    const menuClass = isOpen ? 'composer-context-menu is-open' : 'composer-context-menu';

    useDocumentClick(menuRef, closeContextMenu);

    const renderMenuItems = () =>
        contextMenuId
            ? menuItems.map((menuItem, index) => (
                  // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
                  <div key={index}>
                      <PageElementMenuItem
                          closeContextMenu={closeContextMenu}
                          contextMenuId={contextMenuId}
                          menuItem={menuItem}
                      />
                      {menuItem.separator && <hr className="composer-context-menu-separator" />}
                  </div>
              ))
            : null;

    // Focus the context menu when it opens
    useEffect(() => {
        if (!isOpen) {
            return;
        }

        menuRef.current?.focus();
    }, [isOpen]);

    const handleKeyDown: React.KeyboardEventHandler<HTMLElement> = ({ key }) => {
        if (key !== 'Escape') {
            return;
        }

        // Restore focus to the menu's target (i.e., what was right-clicked on)
        if (elementTargeted?.id) {
            document.getElementById(elementTargeted.id)?.focus();
        }

        closeContextMenu();
    };

    return (
        <nav
            ref={menuRef}
            className={menuClass}
            style={{
                top: `${top}px`,
                left: `${left}px`,
            }}
            tabIndex={-1}
            id="contextMenu"
            data-testid="classic-context-menu"
            onKeyDown={handleKeyDown}
        >
            <section>{renderMenuItems()}</section>
        </nav>
    );
};

export default memo(PageElementMenu);

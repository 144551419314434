import { memo } from 'react';
import '../../../../css/collaboration/cursors.less';
import type { User, Cursor } from '../../collaboration/CollaborationProvider';

interface Props {
    users: Record<string, User>;
    cursors: Record<string, Cursor>;
    currentUserId: string;
    zoom: number;
}

const colors = ['#67abe1', '#0ea076', '#fa4b6d', '#efa037', '#808080'];

const Cursors = ({ users, cursors, currentUserId, zoom }: Props) => {
    if (!cursors || Object.keys(cursors).length === 0) {
        return null;
    }

    const scaleTransform = `scale(${zoom / 0.6}, ${zoom / 0.6})`;

    return (
        <>
            {cursors &&
                Object.values(cursors)
                    .filter((cursor) => cursor.id !== currentUserId)
                    .map((cursor: Cursor, index) => {
                        const user = users[cursor.id] || { firstName: 'User', lastName: 'User' };
                        const initials = `${user.firstName.toUpperCase().slice(0, 1)}${user.lastName
                            .toUpperCase()
                            .slice(0, 1)}`;

                        return (
                            <g
                                key={cursor.id}
                                transform={`translate(${cursor.x}, ${cursor.y}) ${scaleTransform}`}
                                className="cursor"
                            >
                                <path
                                    d="m24.574 18.104c0.8542-3.2021 0.4033-6.6123-1.2538-9.4823-1.657-2.8701-4.3848-4.9657-7.585-5.827l-10.182-2.7284c-0.5016-0.1338-1.0359-0.06315-1.4855 0.19646-0.4497 0.25961-0.778 0.68698-0.9129 1.1883l-2.7284 10.182c-0.85804 3.2022-0.40885 6.6142 1.2487 9.4852 1.6576 2.8711 4.3878 4.9661 7.59 5.8241 3.2023 0.858 6.6142 0.4089 9.4853-1.2487 2.871-1.6577 4.966-4.3879 5.824-7.5901z"
                                    fill={colors[index % colors.length]}
                                />
                                <text
                                    x="12.5"
                                    y="15.5"
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                    strokeWidth="0"
                                    fill="#fff"
                                >
                                    {initials}
                                </text>
                            </g>
                        );
                    })}
        </>
    );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    return prevProps.cursors === nextProps.cursors && prevProps.zoom === nextProps.zoom;
};

export default memo(Cursors, areEqual);

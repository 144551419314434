import { ExIcon, IconVariant } from '@boomi/exosphere';
import translations from '../../translations';

export const timeIcon = (
    <span>
        <ExIcon icon="Timer" variant={IconVariant.INVERSE} className="time-colour" />{' '}
        {translations.ANOMALY_type_time} {translations.ANOMALY_anomaly}
    </span>
);
export const usageIcon = (
    <span>
        <ExIcon icon="Dashboard" variant={IconVariant.INVERSE} className="usage-colour" />{' '}
        {translations.ANOMALY_type_usage} {translations.ANOMALY_anomaly}
    </span>
);
export const errorIcon = (
    <span>
        <ExIcon icon="Error" variant={IconVariant.INVERSE} className="error-colour" />{' '}
        {translations.ANOMALY_type_error} {translations.ANOMALY_anomaly}
    </span>
);

import { type ChangeEventHandler, useId, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { FLOW_ORG_PAGES } from '../../../constants/organization';
import translations from '../../../translations';
import type {
    RuntimeCreateRequest,
    RuntimeListResponse,
    RuntimeResponse,
    RuntimeUpdateRequest,
} from '../../../types/runtime';
import FormGroup from '../../generic/FormGroup';
import Loader from '../../loader/Loader';
import LocalRuntimeOrgTenants from './LocalRuntimeOrgTenants';
import {
    createLocalRuntime,
    updateLocalRuntime,
} from '../../../../js/actions/reduxActions/localRuntimes';

type LocalRuntimeEditorProps = {
    navigateTo: (page: string) => void;
} & ConnectedProps<typeof connector>;

const mapStateToProps = (state: {
    localRuntimes: {
        runtimeList: (
            | (RuntimeResponse & { isEditing: true })
            | (RuntimeListResponse & { isEditing: false })
        )[];
        isLoading: boolean;
    };
}) => ({
    localRuntime: state.localRuntimes.runtimeList.find((runtime) => runtime.isEditing) ?? null,
    isLoading: state.localRuntimes.isLoading,
});

const mapDispatchToProps = {
    createLocalRuntime,
    updateLocalRuntime,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const LocalRuntimeEditor = ({
    localRuntime,
    isLoading,
    navigateTo,
    createLocalRuntime,
    updateLocalRuntime,
}: LocalRuntimeEditorProps) => {
    // The tenant ID's connected to the runtime
    const [connectedTenantIds, setConnectedTenantIds] = useState(
        localRuntime ? localRuntime.tenants.map(({ id }) => id) : [],
    );
    const [nameInput, setNameInput] = useState(localRuntime?.developerName ?? '');
    const inputId = useId();

    const handleSubmit = () => {
        const payload: RuntimeCreateRequest | RuntimeUpdateRequest = {
            developerName: nameInput,
            tenants: connectedTenantIds.map((id) => ({ id })),
        };

        if (localRuntime) {
            updateLocalRuntime(localRuntime.id, payload, navigateTo);
        } else {
            createLocalRuntime(payload, navigateTo);
        }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        setNameInput(value);
    };

    const handleCancel = () => {
        navigateTo(FLOW_ORG_PAGES.flowOrgAdminUI);
    };

    const handleSelectTenant = (isSelected: boolean, tenantId: string) => {
        const checkedTenants = isSelected
            ? [...connectedTenantIds, tenantId]
            : connectedTenantIds.filter((t) => t !== tenantId);
        setConnectedTenantIds(checkedTenants);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <div className="admin-page">
                {localRuntime ? (
                    <h2>{translations.LOCAL_RUNTIME_edit_screen_h2}</h2>
                ) : (
                    <>
                        <h2>{translations.LOCAL_RUNTIME_create_screen_h2}</h2>
                        <p>{translations.LOCAL_RUNTIME_create_screen_h2_summary}</p>
                        <h3>{translations.LOCAL_RUNTIME_create_screen_h3}</h3>
                        <p>{translations.LOCAL_RUNTIME_create_screen_h3_summary}</p>
                    </>
                )}
                <h4>{translations.LOCAL_RUNTIME_create_screen_connection}</h4>
                <FormGroup
                    label={translations.LOCAL_RUNTIME_connection_name_input_label}
                    isRequired
                    htmlFor={inputId}
                >
                    <input
                        id={inputId}
                        className="form-control"
                        value={nameInput}
                        onChange={handleChange}
                        size={50}
                    />
                </FormGroup>
                <h4>{translations.LOCAL_RUNTIME_create_screen_shared_tenants}</h4>
                <p>{translations.LOCAL_RUNTIME_create_screen_shared_tenants_summary}</p>
                <p>{translations.LOCAL_RUNTIME_create_screen_number_tenants_selected_summary}</p>
                <LocalRuntimeOrgTenants
                    onSelect={handleSelectTenant}
                    connectedTenantIds={connectedTenantIds}
                />
            </div>
            <div className="admin-footer">
                <button type="button" className="btn btn-default outcome" onClick={handleCancel}>
                    {translations.LOCAL_RUNTIME_create_screen_cancel}
                </button>
                <button
                    type="button"
                    className="btn btn-primary outcome tenant-save"
                    onClick={handleSubmit}
                >
                    {localRuntime
                        ? translations.LOCAL_RUNTIME_edit_screen_submit
                        : translations.LOCAL_RUNTIME_create_screen_submit}
                </button>
            </div>
        </>
    );
};

export default connector(LocalRuntimeEditor);

import { useEffect, useState } from 'react';
import { getDefaultCulture, getTranslationLanguagesForFlow } from '../../sources/flow';
import { useAuth } from '../AuthProvider';
import type { CultureApi } from '../../types/translation';
import FormGroup from '../generic/FormGroup';

interface Props {
    value: string | undefined;
    onChange: (culture: CultureApi | undefined) => void;
    flowId: string;
    onError: (error: string) => void;
    margin: boolean;
}

const ExistingTranslationsSelect = ({ value, onChange, flowId, onError, margin }: Props) => {
    const [defaultLanguage, setDefaultLanguage] = useState<CultureApi | null>(null);
    const [languages, setLanguages] = useState<CultureApi[]>([]);

    const { tenant } = useAuth();

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        const fetchDefaultCulture = async () => {
            try {
                const defaultCulture: CultureApi = await getDefaultCulture({
                    tenantId: tenant?.id ?? '',
                });

                onChange({ ...defaultCulture, isDefault: true });
                setDefaultLanguage({ ...defaultCulture, isDefault: true });
            } catch (error) {
                onError((error as Error).message);
            }
        };

        fetchDefaultCulture();
    }, [flowId, tenant?.id]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        const fetchExistingTranslationsForFlow = async () => {
            try {
                const existingTranslations: CultureApi[] = await getTranslationLanguagesForFlow({
                    tenantId: tenant?.id ?? '',
                    flowId,
                });

                setLanguages(
                    existingTranslations
                        .filter((language) => language.id !== defaultLanguage?.id)
                        .sort((a, b) =>
                            a.developerName > b.developerName
                                ? 1
                                : b.developerName > a.developerName
                                  ? -1
                                  : 0,
                        ),
                );
            } catch (error) {
                onError((error as Error).message);
            }
        };

        fetchExistingTranslationsForFlow();
    }, [defaultLanguage]);

    const onChangeLanguage: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        onChange(languages.find((language) => language.id === e.target.value));
    };

    return (
        <FormGroup
            label="Language"
            htmlFor="existing-translation-select"
            className={margin ? 'margin-top' : ''}
        >
            <select
                className="form-control form-select"
                id="existing-translation-select"
                value={value ?? ''}
                onChange={onChangeLanguage}
            >
                {defaultLanguage ? (
                    <option value={defaultLanguage.id}>
                        {defaultLanguage.language
                            ? `${defaultLanguage.language} | ${defaultLanguage.developerName}`
                            : defaultLanguage.developerName}
                    </option>
                ) : null}
                {languages.map((language) => (
                    <option key={language.id} value={language.id}>
                        {language.language
                            ? `${language.language} | ${language.developerName}`
                            : language.developerName}
                    </option>
                ))}
            </select>
        </FormGroup>
    );
};

export default ExistingTranslationsSelect;

import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import { type ConnectedProps, connect } from 'react-redux';
import { FLOW_ORG_PAGES } from '../../../constants/organization';
import translations from '../../../translations';
import type { RuntimeCreateResponse } from '../../../types/runtime';
import ClipboardInput from '../../inputs/ClipboardInput';

const mapStateToProps = (state: {
    localRuntimes: {
        // This component is displayed only after a runtime is created, so state will contain a create response with editing set to true. Everything else will have editing false and may not match the create response shape
        runtimeList: ((RuntimeCreateResponse & { isEditing: true }) | { isEditing: false })[];
    };
}) => ({
    installationToken:
        state.localRuntimes.runtimeList.find((runtime) => runtime.isEditing)?.installationToken ??
        '',
});

const connector = connect(mapStateToProps);

type LocalRuntimeDisplayToken = {
    navigateTo: (page: string) => void;
} & ConnectedProps<typeof connector>;

const LocalRuntimeDisplayToken = ({ installationToken, navigateTo }: LocalRuntimeDisplayToken) => {
    const handleSubmit = () => {
        navigateTo(FLOW_ORG_PAGES.flowOrgAdminUI);
    };

    return (
        <>
            <div className="admin-page local-runtime-server-key-display">
                <h2>{translations.LOCAL_RUNTIME_token_display_h2}</h2>
                <ExAlertBanner type={AlertBannerType.WARNING} open className="block margin-bottom">
                    {translations.LOCAL_RUNTIME_token_display_warning}
                </ExAlertBanner>
                <ClipboardInput
                    value={installationToken}
                    buttonTitle={translations.copy_server_key_title}
                />
            </div>
            <div className="admin-footer">
                <button type="button" className="btn btn-primary outcome" onClick={handleSubmit}>
                    {translations.LOCAL_RUNTIME_token_display_submit}
                </button>
            </div>
        </>
    );
};

export default connector(LocalRuntimeDisplayToken);

import { FRIENDLY_DEPENDENCY_NAMES } from '../../ts/constants';
import translations from '../../ts/translations';

export const mapFriendlyNameToDisplay = (friendlyName) => {
    switch (friendlyName) {
        case FRIENDLY_DEPENDENCY_NAMES.FLOW:
            return translations.DISPLAY_NAME_flow;
        case FRIENDLY_DEPENDENCY_NAMES.GROUP_ELEMENT:
            return translations.DISPLAY_NAME_group_element;
        case FRIENDLY_DEPENDENCY_NAMES.MACRO_ELEMENT:
            return translations.DISPLAY_NAME_macro_element;
        case FRIENDLY_DEPENDENCY_NAMES.MAP_ELEMENT:
            return translations.DISPLAY_NAME_map_element;
        case FRIENDLY_DEPENDENCY_NAMES.NAVIGATION_ELEMENT:
            return translations.DISPLAY_NAME_navigation_element;
        case FRIENDLY_DEPENDENCY_NAMES.NAVIGATION_ITEM:
            return translations.DISPLAY_NAME_navigation_item;
        case FRIENDLY_DEPENDENCY_NAMES.PAGE:
            return translations.DISPLAY_NAME_page;
        case FRIENDLY_DEPENDENCY_NAMES.PAGE_ELEMENT:
            return translations.DISPLAY_NAME_page_layout;
        case FRIENDLY_DEPENDENCY_NAMES.SERVICE_ELEMENT:
            return translations.DISPLAY_NAME_connector;
        case FRIENDLY_DEPENDENCY_NAMES.TAG_ELEMENT:
            return translations.DISPLAY_NAME_tag;
        case FRIENDLY_DEPENDENCY_NAMES.TYPE:
            return translations.DISPLAY_NAME_type;
        case FRIENDLY_DEPENDENCY_NAMES.TYPE_ELEMENT:
            return translations.DISPLAY_NAME_type_element;
        case FRIENDLY_DEPENDENCY_NAMES.TYPE_ELEMENT_BINDING:
            return translations.DISPLAY_NAME_type_element_binding;
        case FRIENDLY_DEPENDENCY_NAMES.TYPE_ELEMENT_PROPERTY:
            return translations.DISPLAY_NAME_type_element_property;
        case FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT:
            return translations.DISPLAY_NAME_value_element;
        default:
            return '';
    }
};

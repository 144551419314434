import FormGroup from '../generic/FormGroup';
import translations from '../../translations';
import Select from 'react-select';
import { getSharedStyles } from '../../utils/select';
import { useAnomalyDashboard } from './AnomalyProvider';
import { ANOMALY_TYPE_ERROR, ANOMALY_TYPE_TIME, ANOMALY_TYPE_USAGE } from '../../constants/anomaly';
import InsightsDateFilter from '../developerDashboard/InsightsDateFilter';
import type { TimeFilters } from '../../types/dashboard';

const AnomalyFilters = () => {
    const {
        anomalyFilter,
        setAnomalyFilter,
        flowFilter,
        setFlowFilter,
        stateFilter,
        setStateFilter,
        flows,
        stateIds,
        fromDate,
        dateIndexFilter,
        setDateIndexFilter,
    } = useAnomalyDashboard();

    const mapFilterIndexToTimeFilter: TimeFilters[] = ['day', '3days', 'week'];

    return (
        <span className="filter-bar">
            <span className="data-filters">
                <FormGroup label="Anomaly Type" htmlFor="anomalyFilter">
                    <Select
                        styles={getSharedStyles<{ label: string; value: string } | null>()}
                        inputId="anomalyFilter"
                        isClearable={!!anomalyFilter}
                        placeholder={translations.ANOMALY_select_type}
                        options={[
                            {
                                label: translations.ANOMALY_type_error,
                                value: ANOMALY_TYPE_ERROR,
                            },
                            {
                                label: translations.ANOMALY_type_usage,
                                value: ANOMALY_TYPE_USAGE,
                            },
                            {
                                label: translations.ANOMALY_type_time,
                                value: ANOMALY_TYPE_TIME,
                            },
                        ]}
                        onChange={(e) => {
                            setAnomalyFilter(e?.value);
                        }}
                        value={
                            anomalyFilter
                                ? {
                                      label:
                                          anomalyFilter === ANOMALY_TYPE_ERROR
                                              ? translations.ANOMALY_type_error
                                              : anomalyFilter === ANOMALY_TYPE_USAGE
                                                ? translations.ANOMALY_type_usage
                                                : translations.ANOMALY_type_time,
                                      value: anomalyFilter,
                                  }
                                : null
                        }
                    />
                </FormGroup>
                <FormGroup label="Flow" htmlFor="flowFilter">
                    <Select
                        styles={getSharedStyles<{ label: string; value: string }>()}
                        inputId="flowFilter"
                        isClearable={!!flowFilter}
                        placeholder={translations.ANOMALY_select_flow}
                        options={flows.map((id) => ({ label: id, value: id }))}
                        onChange={(e) => {
                            setFlowFilter(e?.value);
                        }}
                        value={
                            flowFilter
                                ? {
                                      label: flowFilter,
                                      value: flowFilter,
                                  }
                                : null
                        }
                    />
                </FormGroup>
                <FormGroup label="State Id" htmlFor="stateFilter">
                    <Select
                        styles={getSharedStyles<{ label: string; value: string }>()}
                        inputId="stateFilter"
                        isClearable={!!stateFilter}
                        placeholder={translations.ANOMALY_select_state_id}
                        options={stateIds.map((id) => ({ label: id, value: id }))}
                        onChange={(e) => {
                            setStateFilter(e?.value);
                        }}
                        value={
                            stateFilter
                                ? {
                                      label: stateFilter,
                                      value: stateFilter,
                                  }
                                : null
                        }
                    />
                </FormGroup>
            </span>
            <span className="date-filters">
                {fromDate.toLocaleString(undefined, {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                })}{' '}
                to{' '}
                {new Date().toLocaleString(undefined, {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                })}
                <InsightsDateFilter
                    selectedDate={mapFilterIndexToTimeFilter[dateIndexFilter]}
                    setSelectedDate={(date) =>
                        setDateIndexFilter(mapFilterIndexToTimeFilter.indexOf(date))
                    }
                    dateFilterItems={[
                        {
                            label: translations.DASHBOARD_filter_last_day,
                            value: 'day',
                            tooltip: translations.ANOMALY_filter_to_last_day,
                        },
                        {
                            label: translations.ANOMALY_3_days_short,
                            value: '3days',
                            tooltip: translations.ANOMALY_filter_to_last_3_days,
                        },
                        {
                            label: translations.DASHBOARD_filter_last_week,
                            value: 'week',
                            tooltip: translations.ANOMALY_filter_to_last_7_days,
                        },
                    ]}
                />
            </span>
        </span>
    );
};

export default AnomalyFilters;

import { BadgeSize, ExBadge } from '@boomi/exosphere';
import type { ReleasedSnapshot } from '../../../types';
import type { Environment } from '../../../types/environment';
import { environmentTypeToColour } from './utils';

interface Props {
    environments: Environment[];
    snapshot: ReleasedSnapshot;
}

const EnvironmentBadge = ({ environments, snapshot }: Props) => (
    <ExBadge
        color={environmentTypeToColour(
            environments.find((e) => e.id === snapshot.environmentId)?.classificationType,
        )}
        size={BadgeSize.SMALL}
        title={`Last Deployed: ${new Date(snapshot.dateLastDeployed).toLocaleString(undefined, {
            dateStyle: 'medium',
            timeStyle: 'medium',
        })}`}
    >
        {snapshot.environmentName}
    </ExBadge>
);

export default EnvironmentBadge;

import type { DebuggerResponseApi } from '../types';
import { fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

interface StateResponseApi {
    id: string;
    currentFlowId: {
        id: string;
        versionId: string;
    };
}

export const getCallStackItemData = (callStackItemId: string) =>
    fetchAndParse<DebuggerResponseApi>({
        url: `/api/callstack/${callStackItemId}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getState = (stateId: string) =>
    fetchAndParse<StateResponseApi>({
        url: `/api/admin/1/states/${stateId}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

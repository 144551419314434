import type {
    PageCondition,
    ValueElementIdReferenceAPI,
    PageRule,
    PageOperation,
    Confirmation,
} from '../../../../../types';

export interface PageConditionsState {
    conditionIndex: number | null;
    ruleIndex: number | null;
    operationIndex: number | null;
    condition: PageCondition | null;
    confirmation: Confirmation | null;
    values: ValueElementIdReferenceAPI[] | null;
    refreshValues: boolean;
}

export type PageConditionsAction =
    | {
          type: 'update_confirmation';
          payload: Confirmation | null;
      }
    | {
          type: 'update_condition';
          payload: PageCondition;
      }
    | {
          type: 'update_comparison';
          payload: string;
      }
    | {
          type: 'update_rules';
          payload: PageRule[];
      }
    | {
          type: 'update_operations';
          payload: PageOperation[];
      }
    | {
          type: 'update_condition_index';
          payload: number | null;
      }
    | {
          type: 'update_rule_index';
          payload: number | null;
      }
    | {
          type: 'update_operation_index';
          payload: number | null;
      }
    | {
          type: 'refresh_values';
          payload: boolean;
      }
    | {
          type: 'update_values';
          payload: ValueElementIdReferenceAPI[] | null;
      };

export const pageConditionReducer = (
    state: PageConditionsState,
    action: PageConditionsAction,
): PageConditionsState => {
    switch (action.type) {
        case 'update_values':
            return {
                ...state,
                values: action.payload,
            };

        case 'refresh_values':
            return {
                ...state,
                refreshValues: action.payload,
            };

        case 'update_operation_index':
            return {
                ...state,
                operationIndex: action.payload,
            };

        case 'update_rule_index':
            return {
                ...state,
                ruleIndex: action.payload,
            };

        case 'update_condition_index':
            return {
                ...state,
                conditionIndex: action.payload,
            };

        case 'update_confirmation':
            return {
                ...state,
                confirmation: action.payload,
            };

        case 'update_condition':
            return {
                ...state,
                condition: action.payload,
            };

        case 'update_comparison': {
            if (state.condition) {
                return {
                    ...state,
                    condition: {
                        ...state.condition,
                        comparisonType: action.payload,
                    },
                };
            }

            return state;
        }

        case 'update_rules': {
            if (state.condition) {
                return {
                    ...state,
                    condition: {
                        ...state.condition,
                        pageRules: action.payload,
                    },
                };
            }

            return state;
        }

        case 'update_operations': {
            if (state.condition) {
                return {
                    ...state,
                    condition: {
                        ...state.condition,
                        pageOperations: action.payload,
                    },
                };
            }

            return state;
        }

        default:
            return state;
    }
};

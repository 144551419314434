import ButtonDefault from '../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import translations from '../../../../../translations';
import { useMapElement } from '../../../../../../js/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { useWait } from '../../contextProviders/WaitProvider';
import WaitInputs from './WaitInputs';
import type { MapElement } from '../../../../../types/graph';
import ModalBody from '../../../../generic/modal/ModalBody';
import ModalFooter from '../../../../generic/modal/ModalFooter';
import { useEffect } from 'react';

interface MapElementProvider {
    mapElement: MapElement;
    setConfigTitle: (title: string | null) => void;
}

const WaitDetails = () => {
    const { mapElement, setConfigTitle }: MapElementProvider = useMapElement();
    const {
        onReturnToDefaultScreen,
        onApplyWait,
        waitToEdit,
        setHasSubmitted,
        setDefaultWait,
        isValidWait,
        onUpdateName,
        onTimezoneChange,
        onDaysChange,
        onHoursChange,
        onMinutesChange,
        onSecondsChange,
        onRelativeChange,
        onAbsoluteChange,
        onValueChanged,
        onOutcomeChange,
        onHeadlessChange,
    } = useWait();

    const { index } = waitToEdit;

    const onSave = () => {
        setHasSubmitted(true);

        if (isValidWait() && index !== null) {
            onApplyWait(index);
        }
    };

    const onWaitTypeChange = () => {
        setDefaultWait();
    };

    const renderFooter = () => (
        <>
            <ButtonDefault
                key="cancelButton"
                className="flex-child-right"
                onClick={onReturnToDefaultScreen}
            >
                {translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary key="saveButton" className="margin-left" onClick={onSave}>
                {translations.GRAPH_config_panel_add}
            </ButtonPrimary>
        </>
    );

    useEffect(() => {
        setConfigTitle(`Wait for: ${mapElement.developerName ?? ''}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    const renderBody = () => (
        <>
            <WaitInputs
                isMapElement={false}
                onWaitTypeChange={onWaitTypeChange}
                onUpdateName={onUpdateName}
                onSecondsChange={onSecondsChange}
                onMinutesChange={onMinutesChange}
                onHoursChange={onHoursChange}
                onDaysChange={onDaysChange}
                onTimezoneChange={onTimezoneChange}
                onRelativeChange={onRelativeChange}
                onAbsoluteChange={onAbsoluteChange}
                onValueChanged={onValueChanged}
                onOutcomeChange={onOutcomeChange}
                onHeadlessChange={onHeadlessChange}
            />
        </>
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default WaitDetails;

import { type ConnectedProps, connect } from 'react-redux';
import '../../../css/sidebar.less';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import translations from '../translations';

type OwnProps = {
    className?: string;
    isCollapsed?: boolean;
    onCollapse: ({ isCollapsed }: { isCollapsed: boolean }) => void;
    children?: ReactNode;
};

type SideBarProps = OwnProps & ConnectedProps<typeof connector>;

const mapStateToProps = (
    { sidebar: { isCollapsed } }: { sidebar: { isCollapsed: boolean } },
    ownProps: OwnProps,
) => {
    return { isCollapsed: ownProps.isCollapsed ?? isCollapsed };
};

const connector = connect(mapStateToProps, null, null, { forwardRef: true });

const Sidebar = ({ className, isCollapsed, onCollapse, children }: SideBarProps) => {
    const sidebarClassName = classNames('sidebar', className, {
        collapsed: isCollapsed,
    });

    const label = isCollapsed
        ? translations.SIDEBAR_show_sidebar_button_label
        : translations.SIDEBAR_hide_sidebar_button_label;

    return (
        <div className={sidebarClassName}>
            <div className="sidebar-inner added">
                <button
                    className="sidebar-collapse"
                    onClick={() => onCollapse({ isCollapsed })}
                    data-testid="sidebar-collapse-button"
                    type="button"
                    aria-label={label}
                    title={label}
                >
                    {isCollapsed ? <CaretRight /> : <CaretLeft />}
                </button>
                <div className="list-unstyled">{children}</div>
            </div>
        </div>
    );
};

export default connector(Sidebar);

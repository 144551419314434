import actionTypes from '../actions/reduxActions/actionTypes';
import { getItem } from '../../ts/utils/storage';

const initialCollapsed = getItem('sidebar_collapsed');
const initialState = {
    isCollapsed: initialCollapsed !== null ? initialCollapsed : true,
};

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const sidebar = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.SIDEBAR_TOGGLE:
            return {
                isCollapsed: payload,
            };

        default:
            return state;
    }
};

export default sidebar;

import classnames from 'classnames';
import { isEmpty } from 'ramda';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import '../../../../css/flow/graph.less';
import Loader from '../../../ts/components/loader/Loader';
import {
    endMarqueeSelection as endMarqueeSelectionAction,
    setContextMenuData as setContextMenuDataAction,
    setDraggingData as setDraggingDataAction,
    setDraggingOverGroupElementID as setDraggingOverGroupElementIDAction,
    setHoveringMapElementID as setHoveringMapElementIDAction,
} from '../../actions/reduxActions/graphEditor';
import { addNotification as addNotificationAction } from '../../actions/reduxActions/notification';
import {
    NOTIFICATION_TYPES,
    FLOW_EDITING_TOKEN,
    GRAPH_ELEMENT_TYPES,
    MAP_ELEMENT_CONFIGS,
    MAP_ELEMENT_TYPES,
} from '../../../ts/constants';
import { getByID } from '../../../ts/utils/collection';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import { safeToLower } from '../../../ts/utils/string';
import { guid } from '../../../ts/utils/guid';
import ResizeObserver from 'resize-observer-polyfill';
import CanvasPalette from './CanvasPalette';
import { getElementStyles, SNAP_DISTANCE } from './elements/elementStyles';
import Notes from './elements/map/notes/Notes';
import GraphActionMenu from './GraphActionMenu';
import GraphContextMenu from './GraphContextMenu';
import {
    calculateElementBounds,
    calculateIfBoundIsWithinAnotherBounds,
    fitQuery,
    getElementsDimensions,
    getParentOffset,
    getParents,
    moveControlPoints,
    snap,
    snapPosition,
} from './utils';
import throttle from 'lodash.throttle';
import Cursors from '../../../ts/components/collaboration/Cursors';
import MemoizedSVGContentLayered from './MemoizedSVGContentLayered';
import {
    onDragEnd,
    onWheel,
    onKeyDown,
    onMouseDragging,
    onKeyboardDragging,
    onGraphSvgKeydown,
} from './graphEvents';
import translations from '../../../ts/translations';
import { addPositions, subtractPositions } from '../../../ts/components/graph/utils';
import MapElementPicker from '../../../ts/components/flow/MapElementPicker';
import Debug from '../../../ts/components/flow/debug/Debug';
import { getFlag } from '../../../ts/utils/flags';
import FlowActionMenu from '../../../ts/components/graphv2/render/FlowActionMenu';
import { useGraphHOC } from './GraphProvider';
import History from '../../../ts/components/flow/history/History';

export const initialState = {
    // Props to be passed to the drag element
    sidebarDragElementProps: null,
    // Whether the drag element is being dragged and should react to events
    sidebarDragElementDragging: false,
    sidebarDraggingWithKeyboard: false,
    // Whether the drag element should be shown
    // (when the config is open, the element is shown, yet dragging is false)
    sidebarDragElementVisible: false,
    // Svg viewBox for zooming/panning
    viewBox: { x: 0, y: 0, width: 0, height: 0 },
    isMouseOverGraph: false,
    // Saved zoom level
    zoom: 0.6,
    // Graph dragging data (not in Redux because of performance and reloading issues)
    graphDraggingMovedEnough: false,
    graphDraggingInitialPosition: null,
    mousePosition: { x: 0, y: 0 },
    // Should the SVG elements be rendered - We render them a frame after the rest of the Graph
    // so it doesn't block the first render and we can show a spinner while large flows render.
    // Defaults to true because on the first render we will fetch the elements from the engine.
    isContentReady: true,
    showHistory: false,
};

class Graph extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;

        // Svg graph ref
        this.graphElement = createRef(null);
        this.flowEditorWrapperRef = createRef(null);

        // Keep a copy of the cursors that are in the graph here as we need them in order to move to them. We don't want them in state
        // as re-rendering when they change is massively expensive
        this.cursors = {};

        this.setState = this.setState.bind(this);
        this.centerOnPosition = this.centerOnPosition.bind(this);
        this.updateElementGroup = this.updateElementGroup.bind(this);
        this.moveElementsIntoGroups = this.moveElementsIntoGroups.bind(this);
        this.moveInDirection = this.moveInDirection.bind(this);
        this.calculateDraggingOverElementID = this.calculateDraggingOverElementID.bind(this);
        this.resizeViewBox = this.resizeViewBox.bind(this);
        this.zoomByAmount = this.zoomByAmount.bind(this);
        this.setZoom = this.setZoom.bind(this);
        this.zoomViewBox = this.zoomViewBox.bind(this);
        this.setViewBox = this.setViewBox.bind(this);
        this.openContextMenu = this.openContextMenu.bind(this);
        this.getSelectedElementsDimensions = this.getSelectedElementsDimensions.bind(this);
        this.clearAllSideBarDragElements = this.clearAllSideBarDragElements.bind(this);
        this.calculateGraphCentre = this.calculateGraphCentre.bind(this);
        this.calculateSVGPositionFromXY = this.calculateSVGPositionFromXY.bind(this);
        this.calculateSVGPositionOfMapElement = this.calculateSVGPositionOfMapElement.bind(this);
        this.calculateSVGPositionOfMapElementOnSidebar =
            this.calculateSVGPositionOfMapElementOnSidebar.bind(this);
        this.openOutcomeConfig = this.openOutcomeConfig.bind(this);
        this.addOutcomeToStartMapElement = this.addOutcomeToStartMapElement.bind(this);
    }

    componentDidMount() {
        this.listeners = [];
        const addEventListener = (element, eventType, listener, settings) => {
            this.listeners.push({ element, eventType, listener, settings });
            element.addEventListener(eventType, listener, settings);
        };

        const svg = this.graphElement.current;
        this.resizeViewBox(svg.getBoundingClientRect());
        document.querySelectorAll('.sidebar-element').forEach((element) => {
            const { name: mapElementType } = element.dataset;

            const listener = (event) => {
                if (this.props.isActive === false) {
                    return;
                }

                const offset = this.calculateSVGPositionOfMapElement(event, mapElementType);
                this.setState({
                    sidebarDragElementProps: {
                        mapElementType,
                        x: offset.x,
                        y: offset.y,
                    },
                    sidebarDragElementDragging: true,
                    sidebarDragElementVisible: true,
                });
            };

            addEventListener(element, 'mousedown', listener);

            const keyboardListener = (event) => {
                if (this.state.sidebarDragElementDragging && event.key === 'Escape') {
                    this.clearAllSideBarDragElements();
                    return;
                }
                if (
                    this.props.isActive === false ||
                    event.key !== 'Enter' ||
                    this.state.sidebarDragElementDragging ||
                    this.props.isConfigMenuOpen
                ) {
                    return;
                }

                const position = this.calculateSVGPositionOfMapElementOnSidebar();

                this.setState({
                    sidebarDragElementProps: {
                        mapElementType,
                        x: position.x,
                        y: position.y,
                    },
                    sidebarDragElementDragging: true,
                    sidebarDragElementVisible: true,
                    sidebarDraggingWithKeyboard: true,
                });
            };

            // Press enter while a sidebar element is focussed to begin moving a new map element.
            // Escape to cancel this movement.
            addEventListener(element, 'keyup', keyboardListener);
        });

        this.observer = new ResizeObserver((entries) =>
            entries.forEach(
                // Update the viewBox with new information about available space
                (entry) => this.resizeViewBox(entry.contentRect),
            ),
        );
        // Listen to resizes of the flow editor
        if (document.querySelector('.graph-canvas-wrapper.active')) {
            this.observer.observe(document.querySelector('.graph-canvas-wrapper.active'));
        }

        // Cannot set passive parameter when using react event listeners
        // Without this an error is thrown:
        // Unable to preventDefault inside passive event listener
        addEventListener(
            svg,
            'wheel',
            (event) => onWheel({ event, props: this.props, zoomByAmount: this.zoomByAmount }),
            { passive: false },
        );
        addEventListener(window, 'keydown', (event) =>
            onKeyDown({
                event,
                props: this.props,
                state: this.state,
                getSelectedElementsDimensions: this.getSelectedElementsDimensions,
                calculateSVGPositionFromXY: this.calculateSVGPositionFromXY,
                calculateGraphCentre: this.calculateGraphCentre,
                zoomByAmount: this.zoomByAmount,
                calculateDraggingOverElementID: this.calculateDraggingOverElementID,
                setState: this.setState,
                moveInDirection: this.moveInDirection,
                calculateSVGPositionOfMapElement: this.calculateSVGPositionOfMapElement,
                clearAllSideBarDragElements: this.clearAllSideBarDragElements,
                openOutcomeConfig: this.openOutcomeConfig,
                moveElementsIntoGroups: this.moveElementsIntoGroups,
                setInsightsConfigView: this.setInsightsConfigView,
            }),
        );
        addEventListener(window, 'mouseup', (event) =>
            onDragEnd({
                event,
                props: this.props,
                state: this.state,
                calculateSVGPositionOfMapElement: this.calculateSVGPositionOfMapElement,
                clearAllSideBarDragElements: this.clearAllSideBarDragElements,
                setState: this.setState,
                openOutcomeConfig: this.openOutcomeConfig,
                calculateSVGPositionFromXY: this.calculateSVGPositionFromXY,
                moveElementsIntoGroups: this.moveElementsIntoGroups,
                addOutcomeToStartMapElement: this.addOutcomeToStartMapElement,
            }),
        );
        addEventListener(window, 'keyup', (event) =>
            onKeyboardDragging({
                event,
                props: this.props,
                moveInDirection: this.moveInDirection,
                updateElementGroup: this.updateElementGroup,
                viewBox: this.state.viewBox,
                setViewBox: this.setViewBox,
            }),
        );

        const mouseMoveListener = throttle((e) => {
            if (e.clientX !== undefined && e.clientY !== undefined && this.graphElement.current) {
                const { x, y } = this.calculateSVGPositionFromXY({ x: e.clientX, y: e.clientY });
                this.props.collaboration.cursorMoved(this.props.flowId, x, y);
            }
        }, 30);

        addEventListener(svg, 'mousemove', mouseMoveListener);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.showBackdrop && prevProps.showBackdrop) {
            // If the parent tells us the backdrop doesn't exist anymore
            // Then the user must have cancelled it e.g. pressing ESC
            this.clearAllSideBarDragElements();
            this.props.removeAllFakeOutcomes({ type: 'pending' });
        }

        if (
            this.props.collaboration?.items?.[this.props.flowId]?.moveToCursor &&
            this.props.collaboration?.items?.[this.props.flowId]?.moveToCursor !==
                prevProps.collaboration?.items?.[this.props.flowId]?.moveToCursor
        ) {
            const moveToCursorId =
                this.props.collaboration.items?.[this.props.flowId]?.moveToCursor;
            const cursor =
                this.props.collaboration.items?.[this.props.flowId]?.cursors[moveToCursorId];

            this.centerOnPosition({
                x: cursor.x,
                y: cursor.y,
            });
        }

        // We are switching away from this tab, so don't render content
        if (prevProps.isActive && !this.props.isActive) {
            this.setState({ isContentReady: false });
        }
        // We are switching to this tab, so render the content
        // but only after the rest of the Graph has rendered,
        // so it doesn't block the first render of Graph and we can show a spinner
        if (!prevProps.isActive && this.props.isActive) {
            requestAnimationFrame(() => this.setState({ isContentReady: true }));
        }
    }

    componentWillUnmount() {
        this.listeners?.forEach(({ element, eventType, listener, settings }) => {
            element.removeEventListener(eventType, listener, settings);
        });
        this.observer?.disconnect();
    }

    centerOnPosition = ({ x, y }) => {
        this.setViewBox({
            x: x - this.state.viewBox.width / 2,
            y: y - this.state.viewBox.height / 2,
            width: this.state.viewBox.width,
            height: this.state.viewBox.height,
        });
    };

    onCursorsChange = (cursors) => {
        this.cursors = cursors;
    };

    /**
     * returns `false` if the given element has not entered a different group,
     * otherwise returns the element with an updated position, groupElementId and outcomes
     */
    updateElementGroup(element, groupElements, isGroup) {
        const draggingOverGroupId = this.calculateDraggingOverElementID(
            calculateElementBounds(element, groupElements),
        );
        if (element.groupElementId !== draggingOverGroupId) {
            let newElement = { ...element, groupElementId: draggingOverGroupId };
            // update offsets for new parent
            const oldParentOffset = getParentOffset(getParents(element, groupElements));
            const newParentOffset = getParentOffset(getParents(newElement, groupElements));
            newElement = subtractPositions(
                addPositions(newElement, oldParentOffset),
                newParentOffset,
            );
            if (!isGroup) {
                // update outcome control points
                newElement = moveControlPoints(
                    newElement,
                    subtractPositions(oldParentOffset, newParentOffset),
                );
            }
            return newElement;
        }
        return false;
    }

    /**
     * Moves all given elements into any groups that they are inside of.
     * returns the updated list of elements in their new groups
     */
    moveElementsIntoGroups(selectedElementIds, groupElements, mapElements) {
        const movedElements = selectedElementIds.map((elementId) => {
            let oldElement = getByID(elementId, groupElements);
            let isGroup = false;
            if (oldElement) {
                isGroup = true;
            } else {
                oldElement = getByID(elementId, mapElements);
            }

            //Element is in new group or the group is moving with the element
            const newElement =
                !selectedElementIds.includes(oldElement.groupElementId) &&
                this.updateElementGroup(oldElement, groupElements, isGroup);

            if (newElement) {
                // save to context
                if (isGroup) {
                    this.props.setGroupElement(newElement);
                } else {
                    this.props.setMapElement(newElement);
                }
            }
            return newElement || oldElement;
        });
        return movedElements;
    }

    moveInDirection(event, position) {
        const stepAmount = SNAP_DISTANCE; // How much each keypress changes the position
        const newPosition = position;

        if (event.key === 'ArrowLeft') {
            newPosition.x -= stepAmount;
        } else if (event.key === 'ArrowRight') {
            newPosition.x += stepAmount;
        } else if (event.key === 'ArrowUp') {
            newPosition.y -= stepAmount;
        } else if (event.key === 'ArrowDown') {
            newPosition.y += stepAmount;
        }

        return snapPosition(newPosition);
    }

    /**
     * Returns the id of the group that the given bounds are inside of
     */
    calculateDraggingOverElementID = (snappedNewElementBounds) => {
        const { groupElements, dragging } = this.props;
        const elementId = dragging?.elementId;

        let draggingOverGroup = null;
        let groupDepth = -1;

        groupElements
            // the group is not the element being dragged
            .filter((el) => el.id !== elementId)
            .forEach((groupElement) => {
                const parents = getParents(groupElement, groupElements);
                const parentOffset = getParentOffset(parents);

                if (
                    calculateIfBoundIsWithinAnotherBounds({
                        innerBound: snappedNewElementBounds,
                        outerBound: {
                            x: groupElement.x + parentOffset.x,
                            // Add offset because we don't want to include the header in the selection area
                            y:
                                groupElement.y +
                                parentOffset.y +
                                getElementStyles(groupElement.elementType).header.height,
                            width: groupElement.width,
                            // Minus offset to compensate for moving the Y down to avoid the header
                            height:
                                groupElement.height -
                                getElementStyles(groupElement.elementType).header.height,
                        },
                    }) &&
                    // prioritize deeper groups so you can drag into nested groups
                    parents.length > groupDepth
                ) {
                    draggingOverGroup = groupElement.id;
                    groupDepth = parents.length;
                }
            });

        this.props.setDraggingOverGroupElementID(draggingOverGroup);
        return draggingOverGroup;
    };

    resizeViewBox = ({ width, height }) => {
        const x = this.state.viewBox.x;
        const y = this.state.viewBox.y;

        this.setViewBox({
            x,
            y,
            width: width * this.state.zoom,
            height: height * this.state.zoom,
        });
    };

    zoomByAmount = (amount, x, y) => {
        let zoomTarget = { x, y };

        if (isNullOrEmpty(x) || isNullOrEmpty(y)) {
            zoomTarget = this.calculateGraphCentre();
        }

        const startPoint = this.calculateSVGPositionFromXY(zoomTarget);

        this.zoomViewBox(
            {
                x: this.state.viewBox.x - (startPoint.x - this.state.viewBox.x) * (amount - 1),
                y: this.state.viewBox.y - (startPoint.y - this.state.viewBox.y) * (amount - 1),
                width: this.state.viewBox.width * amount,
                height: this.state.viewBox.height * amount,
            },
            this.state.zoom * amount,
        );
    };

    setZoom = (zoom) => {
        this.setState({ zoom });
    };

    zoomViewBox = (viewBox, zoom) => {
        if (viewBox.width > 100000 || viewBox.height > 100000) {
            return;
        }
        if (viewBox.width < 1 || viewBox.height < 1) {
            return;
        }
        this.setViewBox(viewBox);
        this.setZoom(zoom);
    };

    setViewBox = (viewBox) => {
        this.setState({ viewBox });
    };

    openContextMenu = (event) => {
        if (this.props.isActive === false) {
            return;
        }

        const { hoveringMapElementId, hoveringGroupElementId, hoveringOutcomeId } = this.props;

        let activeElement = { id: null, type: GRAPH_ELEMENT_TYPES.graph };

        if (hoveringGroupElementId) {
            activeElement = { id: hoveringGroupElementId, type: GRAPH_ELEMENT_TYPES.group };
        }

        if (hoveringMapElementId) {
            activeElement = { id: hoveringMapElementId, type: GRAPH_ELEMENT_TYPES.map };
        }

        if (hoveringOutcomeId) {
            activeElement = {
                id: hoveringOutcomeId,
                outcomeMapElementId: this.props.hoveringOutcomeMapElementId,
                type: GRAPH_ELEMENT_TYPES.outcome,
            };
        }

        this.props.setContextMenuData({
            show: true,
            x: event.clientX,
            y: event.clientY,
            activeElement,
        });
    };

    /**
     * Returns the x,y,width,height of a rectangle around all selected elements
     */
    getSelectedElementsDimensions() {
        const { mapElements, groupElements, selectedElementIds } = this.props;

        const { lowestX, highestX, lowestY, highestY } = getElementsDimensions(
            selectedElementIds,
            mapElements,
            groupElements,
        );

        return {
            newGroup: {
                x: lowestX - SNAP_DISTANCE,
                y: lowestY - SNAP_DISTANCE,
                width: highestX - lowestX + SNAP_DISTANCE * 2,
                height: highestY - lowestY + SNAP_DISTANCE * 2,
            },
            makeGroupIconRow: {
                x: highestX - 5,
                y: lowestY - 5 - SNAP_DISTANCE,
                width: 40,
                height: 10,
            },
        };
    }

    clearAllSideBarDragElements = () =>
        this.setState({
            sidebarDragElementVisible: false,
            sidebarDragElementDragging: false,
            sidebarDraggingWithKeyboard: false,
        });

    calculateGraphCentre = () => {
        const graphRect = this.graphElement.current.getBoundingClientRect();
        return {
            x: graphRect.x + graphRect.width / 2,
            y: graphRect.y + graphRect.height / 2,
        };
    };

    calculateSVGPositionFromXY({ x, y }) {
        const point = this.graphElement.current.createSVGPoint();
        point.x = x;
        point.y = y;
        return point.matrixTransform(this.graphElement.current.getScreenCTM().inverse());
    }

    calculateSVGPositionOfMapElement(event, mapElementType) {
        const elementStyle = getElementStyles(mapElementType);
        const { width, height } = elementStyle.mapElement
            ? elementStyle.mapElement
            : elementStyle.groupElement;

        const point = this.calculateSVGPositionFromXY({ x: event.clientX, y: event.clientY });
        point.x = snap(point.x - width / 2);
        point.y = snap(point.y - height / 2);
        return point;
    }

    calculateSVGPositionOfMapElementOnSidebar() {
        const graphCentre = this.calculateGraphCentre();
        const graphRect = this.graphElement.current.getBoundingClientRect();

        const point = this.calculateSVGPositionFromXY({
            x: graphRect.left + 200, // Shift left so element doesn't hang off the screen.
            y: graphCentre.y - graphRect.height / 4, // Place a quarter of the way down the screen.
        });

        return snapPosition(point);
    }

    openMapElementPicker = (event, mapElementId) => {
        const {
            mapElements,
            setMapElement,
            setIsMapElementPickerOpen,
            draggingOverGroupElementId,
            setMapElementPickerFromMapElementId,
            setMapElementPickerHoveredGroupElementId,
        } = this.props;

        // Add sidebar drag element element where mouse is
        const offset = this.calculateSVGPositionOfMapElement(event, 'unknown');
        this.setState({
            sidebarDragElementProps: {
                mapElementType: 'unknown',
                x: offset.x,
                y: offset.y,
            },
            sidebarDragElementDragging: false,
            sidebarDragElementVisible: true,
        });

        // Add pending outcome
        const type = 'pending';
        const mapElement = getByID(mapElementId, mapElements);
        const outcomes =
            mapElement?.outcomes?.filter(
                (outcome) => outcome.id !== 'pending' && outcome.id !== 'new',
            ) ?? [];
        outcomes.push({
            id: type,
            nextMapElementId: 'pending',
        });
        setMapElement({ ...mapElement, outcomes });

        // Open modal for choosing map element
        setIsMapElementPickerOpen(true);
        setMapElementPickerFromMapElementId(mapElement.id);
        setMapElementPickerHoveredGroupElementId(draggingOverGroupElementId || '');
    };

    // This is used when the user has let go of an outcome
    openOutcomeConfig = ({ event, mapElementId, configProps }) => {
        const { mapElements, hoveringMapElementId, setMapElement, removeFakeOutcome } = this.props;
        if (hoveringMapElementId) {
            // Open the new Outcome config panel
            const targetElement = mapElements.find((e) => e.id === hoveringMapElementId);
            if (safeToLower(targetElement.elementType) !== MAP_ELEMENT_TYPES.note) {
                configProps.nextMapElementId = hoveringMapElementId;
                this.props.openConfig(MAP_ELEMENT_CONFIGS.outcome, configProps);

                // Add pending outcome
                const type = 'pending';
                const mapElement = getByID(mapElementId, mapElements);
                const outcomes =
                    mapElement?.outcomes?.filter(
                        (outcome) => outcome.id !== 'pending' && outcome.id !== 'new',
                    ) ?? [];
                outcomes.push({
                    id: type,
                    nextMapElementId: hoveringMapElementId,
                });
                setMapElement({ ...mapElement, outcomes });
            } else {
                removeFakeOutcome({
                    mapElementId,
                    type: 'new',
                });
            }
        } else {
            this.openMapElementPicker(event, mapElementId);
        }
    };

    addOutcomeToStartMapElement = (event) => {
        const {
            mapElements,
            hoveringMapElementId,
            addNotification,
            setMapElement,
            saveMapElement,
        } = this.props;

        const startMapElement = mapElements.find(
            (mapElement) => mapElement.elementType === 'START',
        );
        if (!startMapElement.outcomes) {
            startMapElement.outcomes = [];
        }

        if (startMapElement.outcomes.filter((outcome) => outcome.id !== 'new').length > 0) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.GRAPH_start_single_outcome_error,
                isPersistent: true,
            });
            const outcomes =
                startMapElement?.outcomes?.filter(
                    (outcome) => outcome.id !== 'pending' && outcome.id !== 'new',
                ) ?? [];
            setMapElement({ ...startMapElement, outcomes });
            return;
        }

        if (hoveringMapElementId) {
            const targetElement = mapElements.find((e) => e.id === hoveringMapElementId);

            if (safeToLower(targetElement.elementType) !== MAP_ELEMENT_TYPES.note) {
                startMapElement.outcomes = [
                    {
                        id: guid(),
                        developerName: translations.GRAPH_start_outcome_name,
                        nextMapElementId: hoveringMapElementId,
                        order: 0,
                    },
                ];

                saveMapElement(startMapElement);
            }
        } else {
            this.openMapElementPicker(event, startMapElement.id);
        }
    };

    zoomToMapElement = (elementId) => {
        fitQuery(
            null,
            this.props.mapElements,
            this.props.groupElements,
            [],
            this.graphElement.current,
            this.zoomViewBox,
            elementId,
        );
    };

    render() {
        const {
            flowId,
            mapElements,
            openConfig,
            openMetadataEditor,
            setContextMenuData,
            dragging,
            searchQueries,
            canvasSettings,
            isLoading,
            highlightedElementIds,
            isPreviewingAutoArrange,
            hoveringMapElementId,
        } = this.props;

        const { newGroup, makeGroupIconRow } = this.getSelectedElementsDimensions();

        const svgClasses = classnames({
            'graph-canvas': true,
            searching: searchQueries?.[flowId] || highlightedElementIds.length > 0,
            'graph-moving':
                dragging?.dragType === GRAPH_ELEMENT_TYPES.graph && dragging?.usingKeyboard,
            'graph-keyboard-focusable': true,
        });

        // does not work if they delete START
        const isGraphLoading =
            isLoading || !this.state.isContentReady || (isEmpty(mapElements) && !this.props.isNew);

        const zoomOutFillSwitchThreshold = 1;
        // Zooming out the graph beyond the threshold changes the zoom level from 1, to 2
        const zoomLevel = this.state.zoom >= zoomOutFillSwitchThreshold ? 2 : 1;

        const outerClasses = classnames({
            'flow-canvas-wrapper': true,
            'debug-left': this.props.debugConfig.position === 'LEFT',
            'debug-bottom': this.props.debugConfig.position === 'BOTTOM',
            'debug-right': this.props.debugConfig.position === 'RIGHT',
        });

        const graphCanvasWrapperClasses = classnames('graph-canvas-wrapper', {
            active: this.props.isActive,
        });

        return (
            <div className="flow-canvas-history-wrapper">
                <div className={outerClasses} ref={this.flowEditorWrapperRef}>
                    <div
                        className={graphCanvasWrapperClasses}
                        onContextMenu={(event) => event.preventDefault()}
                    >
                        {isGraphLoading && <Loader />}
                        <div className="graph-canvas-overlay">
                            <div className="graph-canvas-overlay-left">
                                <CanvasPalette flowId={flowId} isNew={this.props.isNew} />
                            </div>
                            <div className="graph-canvas-overlay-right">
                                <FlowActionMenu
                                    flowId={flowId}
                                    isActive={this.props.isActive}
                                    modalContainer={this.props.modalContainer}
                                    addNotification={this.props.addNotification}
                                    setTabTitle={this.props.setTabTitle}
                                    notifyError={this.props.notifyError}
                                    notifySuccess={this.props.notifySuccess}
                                    onModalOpen={this.props.setIsActionMenuOpen}
                                    mapElements={this.props.mapElements}
                                    showHistory={this.state.showHistory}
                                    setShowHistory={() => {
                                        this.props.loadReleases();
                                        this.setState({
                                            showHistory: !this.state.showHistory,
                                        });
                                    }}
                                    loadReleases={this.props.loadReleases}
                                />
                                <GraphActionMenu
                                    flowId={flowId}
                                    graphElement={this.graphElement.current}
                                    zoom={this.zoomByAmount}
                                    zoomViewBox={this.zoomViewBox}
                                    isActive={this.props.isActive}
                                />
                            </div>
                        </div>
                        {/* biome-ignore lint/a11y/noSvgWithoutTitle: Unsure what title to use */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id={`graph-svg-${flowId}`}
                            data-testid={`graph-svg-${flowId}`}
                            className={svgClasses}
                            onMouseDown={() => setContextMenuData(null)}
                            onMouseMove={(event) =>
                                onMouseDragging({
                                    event,
                                    props: this.props,
                                    state: this.state,
                                    setState: this.setState,
                                    calculateSVGPositionOfMapElement:
                                        this.calculateSVGPositionOfMapElement,
                                    calculateDraggingOverElementID:
                                        this.calculateDraggingOverElementID,
                                    setViewBox: this.setViewBox,
                                    calculateSVGPositionFromXY: this.calculateSVGPositionFromXY,
                                })
                            }
                            onMouseOver={() => this.setState({ isMouseOverGraph: true })}
                            onFocus={() => this.setState({ isMouseOverGraph: true })}
                            onMouseOut={() => this.setState({ isMouseOverGraph: false })}
                            onBlur={() => this.setState({ isMouseOverGraph: false })}
                            onMouseUp={(event) => {
                                // Open the context menu if the user just right clicking and isn't dragging
                                // This is to avoid opening the context menu when the user just finishes making a selection marquee
                                if (!dragging?.hasMovedEnough && event.button === 2) {
                                    this.openContextMenu(event);
                                }
                            }}
                            onKeyDown={(event) =>
                                onGraphSvgKeydown({
                                    event,
                                    props: this.props,
                                    state: this.state,
                                    setViewBox: this.setViewBox,
                                })
                            }
                            ref={this.graphElement}
                            viewBox={`${this.state.viewBox.x} ${this.state.viewBox.y} ${this.state.viewBox.width} ${this.state.viewBox.height}`}
                        >
                            <g>
                                <defs>
                                    <pattern
                                        id={`dots-${flowId}`}
                                        patternUnits="userSpaceOnUse"
                                        width="10"
                                        height="10"
                                    >
                                        <rect x="0" y="0" width="10" height="10" fill="white" />
                                        <circle cx="0" cy="0" r="1" fill="#ddd" />
                                        <circle cx="0" cy="10" r="1" fill="#ddd" />
                                        <circle cx="10" cy="0" r="1" fill="#ddd" />
                                        <circle cx="10" cy="10" r="1" fill="#ddd" />
                                    </pattern>
                                </defs>
                                <rect
                                    id={`background-dots-${flowId}`}
                                    fill={`url(#dots-${flowId})`}
                                    x={this.state.viewBox.x}
                                    y={this.state.viewBox.y}
                                    width="100%"
                                    height="100%"
                                />
                                {this.props.isActive && this.state.isContentReady ? (
                                    <MemoizedSVGContentLayered
                                        flowId={flowId}
                                        openConfig={openConfig}
                                        sidebarDragElementVisible={
                                            this.state.sidebarDragElementVisible
                                        }
                                        sidebarDragElementProps={this.state.sidebarDragElementProps}
                                        sidebarDraggingWithKeyboard={
                                            this.state.sidebarDraggingWithKeyboard
                                        }
                                        newGroup={newGroup}
                                        makeGroupIconRow={makeGroupIconRow}
                                        dragging={dragging}
                                        calculateSVGPositionFromXY={this.calculateSVGPositionFromXY}
                                        openMetadataEditor={openMetadataEditor}
                                        zoomLevel={zoomLevel}
                                        canvasSettings={canvasSettings}
                                        zoomViewBox={this.zoomViewBox}
                                        graphElement={this.graphElement.current}
                                        hoveringMapElementId={hoveringMapElementId}
                                    />
                                ) : null}
                                {isPreviewingAutoArrange ? (
                                    <rect
                                        id={`background-arrange-preview-${flowId}`}
                                        fill="black"
                                        fillOpacity={0.1}
                                        x={this.state.viewBox.x}
                                        y={this.state.viewBox.y}
                                        width="100%"
                                        height="100%"
                                        className="canvas-blocked"
                                    />
                                ) : null}
                                {this.props.isActive && this.state.isContentReady ? (
                                    <Cursors
                                        users={this.props.collaboration.users}
                                        cursors={this.props.collaboration.items?.[flowId]?.cursors}
                                        currentUserId={this.props.currentUserId}
                                        zoom={this.state.zoom}
                                    />
                                ) : null}
                            </g>
                        </svg>
                        {this.graphElement.current ? (
                            <Notes
                                editingToken={FLOW_EDITING_TOKEN}
                                flowId={flowId}
                                canvasNode={this.graphElement.current.getBoundingClientRect()}
                            />
                        ) : null}
                        {this.props.isActive && (
                            <GraphContextMenu
                                openMetadataEditor={openMetadataEditor}
                                openConfig={openConfig}
                                flowId={flowId}
                                editingToken={FLOW_EDITING_TOKEN}
                                calculateSVGPositionFromXY={this.calculateSVGPositionFromXY}
                                graphElement={this.graphElement.current}
                            />
                        )}

                        {this.props.isMapElementPickerOpen && (
                            <MapElementPicker
                                calculateSVGPositionFromXY={this.calculateSVGPositionFromXY}
                                graphElement={this.graphElement.current}
                                openConfig={openConfig}
                                x={this.state.sidebarDragElementProps.x}
                                y={this.state.sidebarDragElementProps.y}
                                container={this.props.modalContainer}
                            />
                        )}

                        {/* Container used by cytoscape to arrange */}
                        <div id={this.props.isActive ? 'cytoscape-container' : ''} />
                    </div>
                    {getFlag('DEBUG') && (
                        <Debug
                            flowId={this.props.flowId}
                            addNotification={this.props.addNotification}
                            zoomToMapElement={this.zoomToMapElement}
                            ref={this.flowEditorWrapperRef}
                            isActive={this.props.isActive}
                        />
                    )}
                </div>
                {this.state.showHistory && (
                    <History
                        flowId={this.props.flowId}
                        container={this.props.modalContainer}
                        notifyError={this.props.notifyError}
                        refreshFlow={this.props.refreshFlow}
                        isFlowHistoryModalOpen={this.props.isFlowHistoryModalOpen}
                        setIsFlowHistoryModalOpen={this.props.setIsFlowHistoryModalOpen}
                        releases={this.props.releases}
                        environments={this.props.environments}
                        isLoadingReleases={this.props.isLoadingReleases}
                    />
                )}
            </div>
        );
    }
}

export default connect(
    ({ graphEditor }) => ({
        ...graphEditor,
    }),
    {
        addNotification: addNotificationAction,
        setDraggingData: setDraggingDataAction,
        setDraggingOverGroupElementID: setDraggingOverGroupElementIDAction,
        setHoveringMapElementID: setHoveringMapElementIDAction,
        setContextMenuData: setContextMenuDataAction,
        endMarqueeSelection: endMarqueeSelectionAction,
    },
    // biome-ignore lint/correctness/useHookAtTopLevel: Treat warnings as errors, fix later
)(useGraphHOC(Graph));

import { createElement } from 'react';
import Glyphicon from '../../../ts/components/generic/Glyphicon';
import { useDrag, useDrop } from 'react-dnd';
import '../../../../css/flow/navigation-editor.less';

const renderNavigationItem = (props) => createElement(NavigationItem, props);

const NavigationItem = (props) => {
    const [_, drop] = useDrop(
        () => ({
            accept: 'NAVIGATION_ITEM',
            hover: (_item, monitor) => {
                const { id: draggedId, parent } = monitor.getItem();
                const overId = props.item.id;

                if (draggedId !== overId && monitor.isOver({ shallow: true })) {
                    const item = props.findItem(overId, props.items);
                    const overIndex = item.order;
                    props.moveItem(parent, draggedId, overIndex);
                }
            },
            canDrop(props, monitor) {
                const { parent } = monitor.getItem();
                return parent && props.findItem(props.item.id, parent.navigationItems);
            },
        }),
        [],
    );

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'NAVIGATION_ITEM',
            item: { id: props.item.id, order: props.item.order, parent: props.parent },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [props.item.order, props.parent],
    );

    const item = props.item;
    const childMenuClass = props.isTopLevel
        ? ['navigation-item', 'dropdown']
        : ['navigation-item', 'dropdown-submenu'];
    const navigationItemIcon = props.isTopLevel ? (
        <span>
            {item.label} <b className="caret" />
        </span>
    ) : (
        item.label
    );

    const childItems = item.navigationItems || [];
    const items = childItems
        .sort((item1, item2) => {
            return Number.parseInt(item1.order) - Number.parseInt(item2.order);
        })
        .map((child) => {
            return renderNavigationItem({
                items: props.items,
                parent: props.item,
                item: child,
                key: child.id,
                isTopLevel: false,
                moveItem: props.moveItem,
                itemClick: props.itemClick,
                addItem: props.addItem,
                findItem: props.findItem,
            });
        });

    items.push(
        <li className="add-item">
            <div
                onClick={(e) => props.addItem(item.id, e)}
                onKeyUp={(e) => props.addItem(item.id, e)}
                role="button"
                tabIndex={item.order}
            >
                <span className="icon-wrapper">
                    <Glyphicon glyph="plus" />
                </span>
            </div>
        </li>,
    );

    const opacity = isDragging ? 0 : 1;

    const navigationItem = (
        <li
            ref={drop}
            key={item.id}
            className={childMenuClass.join(' ')}
            style={{ opacity: opacity }}
        >
            <div
                ref={drag}
                id={item.id}
                className="click-wrapper"
                onClick={props.itemClick}
                onKeyUp={props.itemClick}
                role="button"
                tabIndex={item.order}
            >
                <span className="item-wrapper">{navigationItemIcon}</span>
                <ul className="dropdown-menu">{items}</ul>
            </div>
        </li>
    );

    return navigationItem;
};

export default NavigationItem;

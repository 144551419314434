import { useState, useEffect, type ReactElement } from 'react';

import { getValueList } from '../../../../../../../../sources/value';
import translations from '../../../../../../../../translations';
import Loader from '../../../../../../../loader/Loader';
import type { PageComponent } from '../../../../../../../../types';

const X_AXIS_COLUMN_INDEX = 0;
const Y_AXIS_COLUMN_INDEX = 1;
const MINIMUM_NO_COLUMNS = 2;

interface Props {
    dataSets: PageComponent[];
    children: ReactElement;
}

const ChartErrors = ({ dataSets, children }: Props) => {
    const [typeElementIds, setTypeElementIds] = useState<(string | null)[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const dataSourceValueIds = dataSets.reduce((acc: string[], curr: PageComponent) => {
        if (curr?.valueElementDataBindingReferenceId?.id) {
            acc.push(curr.valueElementDataBindingReferenceId.id);
        }

        return acc;
    }, []);

    const dataSetChartTypes = dataSets.reduce((acc: string[], curr: PageComponent) => {
        if (curr?.componentType) {
            acc.push(curr.componentType);
        }

        return acc;
    }, []);

    const dataSetsAreValid =
        typeElementIds.every((val, _, arr) => val === arr[0]) &&
        dataSourceValueIds.length === dataSets.length;

    const dataSetsChartTypesAreValid =
        dataSetChartTypes.every((val, _, arr) => val === arr[0]) &&
        dataSetChartTypes.length === dataSets.length;

    const getValueTypes = async (valueIds: string[]) => {
        const values = await getValueList({
            id: valueIds,
        });

        return values.filter((v) => v.contentType === 'ContentList').map((x) => x.typeElementId);
    };

    const getTypeElementIds = async () => {
        if (isLoading) {
            setIsLoading(true);
        }

        const typeIds =
            dataSourceValueIds.length > 0 ? await getValueTypes(dataSourceValueIds) : [];

        setTypeElementIds(typeIds);
        setIsLoading(false);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        getTypeElementIds();
    }, [dataSourceValueIds.length]);

    const validateColumnsInAllDatasets = (dataSets: PageComponent[]) => {
        const columns = dataSets.filter((d) => d.columns).map((d) => d.columns);

        const xAxisColumns = columns.map((c) => (c ? c[X_AXIS_COLUMN_INDEX] : null));
        const yAxisColumns = columns.map((c) => (c ? c[Y_AXIS_COLUMN_INDEX] : null));

        const doAllXaxisColumnsMatch = xAxisColumns.every(
            (x) =>
                x?.typeElementPropertyId === xAxisColumns[0]?.typeElementPropertyId &&
                x?.label === xAxisColumns[0]?.label,
        );

        const doAllYaxisColumnsMatch = yAxisColumns.every(
            (y) =>
                y?.typeElementPropertyId === yAxisColumns[0]?.typeElementPropertyId &&
                y?.label === yAxisColumns[0]?.label,
        );

        if (!(doAllXaxisColumnsMatch && doAllYaxisColumnsMatch)) {
            return false;
        }

        const flattenedColumns = columns.flat();

        if (
            flattenedColumns.length > 0 &&
            flattenedColumns.length !== dataSets.length * MINIMUM_NO_COLUMNS
        ) {
            return false;
        }

        return true;
    };

    const dataSetColumnsAreValid = validateColumnsInAllDatasets(dataSets);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {children}
            {dataSetColumnsAreValid ? null : (
                <div className="help-block error-state">
                    {translations.PAGE_BUILDER_chart_columns_error}
                </div>
            )}
            {dataSetsChartTypesAreValid ? null : (
                <div className="help-block error-state">
                    {translations.PAGE_BUILDER_chart_type_error}
                </div>
            )}
            {dataSetsAreValid ? null : (
                <div className="help-block error-state">
                    {translations.PAGE_BUILDER_chart_data_source_type_error}
                </div>
            )}
        </>
    );
};

export default ChartErrors;

import { ChatCenteredDots } from '@phosphor-icons/react';

const AIChat = ({ label }: { label: string }) => {
    return (
        <div data-testid="component-preview-ai-chat" className="component-preview-ai-chat">
            <ChatCenteredDots size={64} />
            <h2>{label}</h2>
        </div>
    );
};

export default AIChat;

import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';
import { MapElementProvider } from '../contextProviders/MapElementProvider';
import OutcomePanel from './OutcomePanel';

const Outcome = (props) => (
    <MapElementProvider
        {...props}
        elementType={MAP_ELEMENT_TYPES.outcome}
        needsPageComponents={true}
    >
        <MapElementModal>
            <OutcomePanel outcomeId={props.outcomeId} nextMapElementId={props.nextMapElementId} />
        </MapElementModal>
    </MapElementProvider>
);

export default Outcome;

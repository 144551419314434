import classNames from 'classnames';
import type { ReactNode } from 'react';
import { X } from '@phosphor-icons/react';
import translations from '../../../translations';

interface Props {
    children?: ReactNode;
    className?: string | undefined;
    onHide?: (() => void) | null | undefined;
}

const ModalHeader = ({ children, className, onHide }: Props) => (
    <header className={classNames(className, 'modal-header')} data-testid="dialog-header">
        {typeof onHide === 'function' && (
            <button
                type="button"
                className="close"
                onClick={onHide}
                aria-label={translations.COMMON_close}
            >
                <X />
            </button>
        )}
        {children}
    </header>
);

export default ModalHeader;

import classnames from 'classnames';
import {
    useRef,
    useState,
    type ElementRef,
    type KeyboardEvent,
    type MouseEvent,
    type ReactElement,
    type SyntheticEvent,
} from 'react';
import InnerArrows from './InnerArrows';
import OuterArrows from './OuterArrows';
import Chart from './chart/Chart';
import PageComponent from './component/PageComponent';
import PageContainer from './container/PageContainer';

import { useDrag, useDrop } from 'react-dnd';
import withScrolling from 'react-dnd-scrolling';
import type { ComposerElement, ContextSelection, PreviewMode } from '../../../../../../types';
import { CONTAINER_TYPE, ELEMENT_EDGES } from '../../../../constants';
import { calcContextMenuPosition } from '../../../../utils';
import { usePageEditor } from '../../../PageEditorProvider';
import {
    dropPageElement,
    hoverPageElement,
    shouldDisplayBlock,
    shouldDisplayInline,
    type ContainerType,
    type Edge,
    type PageElementType,
} from '../../../dnd-utils';
import type { ElementSelection } from '../ComposerProvider';

const ScrollingComponent = withScrolling('div');

interface DragDropElementProps {
    'data-testid': string;
    role: string;
    tabIndex: number;
    onClick: (e: SyntheticEvent) => void;
    onMouseOver: (e: MouseEvent<HTMLDivElement>) => void;
    onContextMenu: (e: MouseEvent<HTMLDivElement>) => void;
    className: string;
    style: { opacity: number };
    onKeyDown: (e: KeyboardEvent<HTMLDivElement>) => void;
    onMouseOut?: () => void;
}

interface Props {
    setActivePageContainer: (id: string | null) => void;
    id: string;
    containerType: ContainerType;
    children: ReactElement[];
    parentId: string | null | undefined;
    order: number;
    pageElementType: PageElementType;
    elementSelected: string | null;
    setElementSelected: (id: string) => void;
    elementToEdit: string | null;
    openContextMenu: (payload: ContextSelection) => void;
    isContextMenuOpen: boolean;
    onPageElementDrop: (pageElementDrops: ElementSelection) => void;
    preview: PreviewMode | null;
    pageElements: ComposerElement[];
    config: ComposerElement;
}

const ComposerPageElement = ({
    setActivePageContainer,
    id,
    containerType,
    children,
    parentId,
    order,
    pageElementType,
    elementSelected,
    setElementSelected,
    elementToEdit,
    openContextMenu,
    isContextMenuOpen,
    onPageElementDrop,
    preview: previewMode,
    pageElements,
    config,
}: Props) => {
    const ref = useRef<ElementRef<'div'>>(null);

    const [edge, setEdge] = useState<Edge | null>(null);
    const [elementHovered, setElementHovered] = useState<string | null>(null);

    const [{ isOverCurrent }, drop] = useDrop(
        () => ({
            accept: 'COMPONENT',
            drop: (_, monitor) => {
                return dropPageElement(
                    monitor.didDrop(),
                    monitor.getItem(),
                    pageElements,
                    onPageElementDrop,
                    id,
                    parentId || null,
                    order,
                    pageElementType,
                    edge,
                );
            },
            hover: (_, monitor) => {
                const clientOffset = monitor.getClientOffset();
                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                hoverPageElement(
                    monitor.getItem(),
                    parentId as string,
                    order,
                    setEdge,
                    pageElements,
                    containerType,
                    clientOffset?.x as number,
                    clientOffset?.y as number,
                    hoverBoundingRect?.top as number,
                    hoverBoundingRect?.bottom as number,
                    hoverBoundingRect?.right as number,
                    hoverBoundingRect?.left as number,
                );
            },
            collect: (monitor) => ({
                isOverCurrent: monitor.isOver({ shallow: true }),
            }),
        }),
        [edge],
    );

    const [{ opacity, isDragging }, drag, preview] = useDrag(
        () => ({
            type: 'COMPONENT',
            item: {
                id,
                order,
                parentId,
                containerType,
                pageElementType,
                componentType: config.componentType,
            },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0 : 1,
                isDragging: monitor.isDragging(),
            }),
        }),
        [order, parentId, containerType, config.componentType],
    );

    const { state, setShowPageConditions } = usePageEditor();
    const { modifiedPageCondition } = state;

    const isContainerSelectedButComponentConfig = () => {
        const elementCurrentlyEdited = pageElements.find((e) => e.id === elementToEdit);

        return elementCurrentlyEdited && elementCurrentlyEdited.parentId === id && isChartContainer;
    };

    const focusContainer = (e: SyntheticEvent) => {
        if (!(isContextMenuOpen || previewMode)) {
            e.stopPropagation();
            if (isContainerSelectedButComponentConfig()) {
                setActivePageContainer(null);
            } else {
                setActivePageContainer(id);
            }

            setElementSelected(id);

            // If the user opens the page conditions in the sidebar, then clicks on a component
            // or a container and there are no ongoing unsaved page condition changes then
            // we'll close page conditions and open the component/container config in the
            // sidebar again.
            if (!modifiedPageCondition) {
                setShowPageConditions(false);
            }
        }
    };

    const hoverContainer = (e: MouseEvent<HTMLDivElement>) => {
        if (!previewMode) {
            e.stopPropagation();
            setElementHovered(id);
        }
    };

    const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
        if (!previewMode) {
            event.preventDefault();
            event.stopPropagation();
            const { clientX, clientY } = event;

            const { top, left } = calcContextMenuPosition('contextMenu', clientX, clientY);
            const payload: ContextSelection = {
                isOpen: true,
                top,
                left,
                originGuid: id,
                order,
            };

            openContextMenu(payload);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            setActivePageContainer(id);
            setElementSelected(id);
        }
    };

    const handleMouseOut = () => {
        setElementHovered(null);
    };

    const isRootElement = parentId === null;

    const isComponent = pageElementType === 'COMPONENT';

    const containerOuterClasses = classnames({
        'page-container-outer': true,
        'root-container': isRootElement,
        preview: false,
        dragging: isDragging,
    });

    const containerClasses = classnames({
        'page-container': true,
        'page-component': isComponent,
        'root-container-inner': isRootElement,
        'is-dragging-over': isOverCurrent,
        'edge-top': edge === ELEMENT_EDGES['top'] && isOverCurrent,
        'edge-bottom': edge === ELEMENT_EDGES['bottom'] && isOverCurrent,
        'edge-left': edge === ELEMENT_EDGES['left'] && isOverCurrent,
        'edge-right': edge === ELEMENT_EDGES['right'] && isOverCurrent,
        'edge-all': edge === ELEMENT_EDGES['all'] && isOverCurrent,
        active: elementSelected === id,
        hover: elementHovered === id,
        preview: !!previewMode,
    });

    const bodyClasses = classnames({
        'page-container-body': true,
        'page-component-body': !containerType,
        'page-container-row': shouldDisplayBlock(containerType),
        'page-container-column': shouldDisplayInline(containerType),
        preview: !!previewMode,
    });

    const pageElementsComponents = {
        CONTAINER: PageContainer,
        COMPONENT: PageComponent,
        CHART: Chart,
    };

    const isChartContainer = containerType?.toUpperCase() === CONTAINER_TYPE['chart'];
    const pageElementsComponentKey =
        pageElementType && isChartContainer ? 'CHART' : pageElementType;

    const PageElement = pageElementsComponents[pageElementsComponentKey];

    const dragDropElementProps: DragDropElementProps = {
        'data-testid': id,
        role: 'button',
        tabIndex: 0,
        onClick: focusContainer,
        onMouseOver: hoverContainer,
        onContextMenu: handleContextMenu,
        className: containerClasses,
        style: { opacity },
        onKeyDown: handleKeyDown,
        onMouseOut: handleMouseOut,
    };

    const renderPageElementContent = () => {
        return (
            <div className="arrow-wrapper" ref={ref}>
                <OuterArrows edge={edge} isOverCurrent={isOverCurrent} />
                <InnerArrows edge={edge} isOverCurrent={isOverCurrent} />
                <PageElement
                    id={id}
                    type={containerType}
                    parentId={parentId}
                    classes={bodyClasses}
                    drag={drag}
                    config={config}
                    preview={previewMode}
                >
                    {children}
                </PageElement>
            </div>
        );
    };

    if (isRootElement) {
        return (
            <div ref={drop} className={containerOuterClasses}>
                <ScrollingComponent {...dragDropElementProps}>
                    <div ref={preview}>{renderPageElementContent()}</div>
                </ScrollingComponent>
            </div>
        );
    }

    return (
        <div ref={drop} className={containerOuterClasses}>
            <div ref={preview} {...dragDropElementProps}>
                {renderPageElementContent()}
            </div>
        </div>
    );
};

export default ComposerPageElement;

import FormGroup from '../generic/FormGroup';
import { getSharedStyles } from '../../utils/select';
import CreatableSelect from 'react-select/creatable';
import type { ChangeEvent } from 'react';
import type { FlowIdentityProviderAPI, IdentityProviderAPI } from '../../types';

interface Props {
    providers: IdentityProviderAPI[];
    identityProvider: FlowIdentityProviderAPI | null;
    onChangeIdentityProvider: (provider: FlowIdentityProviderAPI | null) => void;
}

interface Option {
    value: string;
    label: string;
}

const IdentityProvider = ({ providers, identityProvider, onChangeIdentityProvider }: Props) => {
    const allowedGroups: Option[] =
        identityProvider?.allowedGroups?.map((group) => ({
            value: group,
            label: group,
        })) ?? [];
    const allowedUsers: Option[] =
        identityProvider?.allowedUsers?.map((user) => ({
            value: user,
            label: user,
        })) ?? [];

    const onChangeSelectedIdentityProvider = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.currentTarget.value === '') {
            onChangeIdentityProvider(null);
        } else {
            const idp = identityProvider ?? { allowedGroups: [], allowedUsers: [] };
            onChangeIdentityProvider({
                ...idp,
                id: e.currentTarget.value,
            });
        }
    };

    const onChangeGroups = (groups: unknown) => {
        if (identityProvider !== null) {
            onChangeIdentityProvider({
                ...identityProvider,
                allowedGroups: (groups as { value: string }[]).map((group) => group.value),
            });
        }
    };

    const onChangeUsers = (users: unknown) => {
        if (identityProvider !== null) {
            onChangeIdentityProvider({
                ...identityProvider,
                allowedUsers: (users as { value: string }[]).map((user) => user.value),
            });
        }
    };

    return (
        <>
            <FormGroup
                label="Identity Provider"
                htmlFor="flow-properties-identity-provider"
                className="margin-top"
            >
                <select
                    id="flow-properties-identity-provider"
                    className="form-control form-control-dynamic"
                    value={identityProvider?.id ?? ''}
                    onChange={onChangeSelectedIdentityProvider}
                >
                    <option key="" value="">
                        No identity provider
                    </option>
                    {providers
                        ? providers.map((provider) => (
                              <option key={provider.id} value={provider.id}>
                                  {provider.developerName}
                              </option>
                          ))
                        : null}
                </select>
            </FormGroup>

            <FormGroup
                label="Allowed Groups"
                htmlFor="flow-properties-identity-provider-groups-input"
            >
                <CreatableSelect
                    styles={getSharedStyles<Option, true>()}
                    name="flow-properties-identity-provider-groups"
                    inputId="flow-properties-identity-provider-groups-input"
                    placeholder="Add group restriction"
                    value={allowedGroups}
                    isMulti={true}
                    options={allowedGroups}
                    onChange={onChangeGroups}
                    noOptionsMessage={() => null}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                />
            </FormGroup>

            <FormGroup
                label="Allowed Users"
                htmlFor="flow-properties-identity-provider-users-input"
            >
                <CreatableSelect
                    styles={getSharedStyles<Option, true>()}
                    name="flow-properties-identity-provider-users"
                    inputId="flow-properties-identity-provider-users-input"
                    placeholder="Add user restriction by email address"
                    value={allowedUsers}
                    isMulti={true}
                    options={allowedUsers}
                    onChange={onChangeUsers}
                    noOptionsMessage={() => null}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                />
            </FormGroup>
        </>
    );
};

export default IdentityProvider;

import translations from '../../translations';
import type { SSOMetadata } from '../../types/auth';
import { isNullOrWhitespace } from '../../utils/guard';
import { stringReplace } from '../../utils/string';

export interface SSOExpiryNotificationProps {
    ssoMetadata: SSOMetadata;
    ssoExpiryInMinutes: number;
}

const SSOExpiryNotification = ({ ssoMetadata, ssoExpiryInMinutes }: SSOExpiryNotificationProps) => {
    const message = stringReplace(translations.SSO_EXPIRY_NOTIFICATION_expiration_time_message, {
        remainingMinutes: Math.floor(ssoExpiryInMinutes),
        expiryTime: new Date(ssoMetadata.sessionExpiry).toLocaleString(),
    });

    const refreshSessionLink = !isNullOrWhitespace(ssoMetadata.loginUri) && (
        <a href={ssoMetadata.loginUri} target="_blank" rel="noreferrer">
            {' '}
            {translations.SSO_EXPIRY_NOTIFICATION_refresh_session_link_text}
        </a>
    );

    return (
        <>
            {message}
            {refreshSessionLink}
        </>
    );
};

export default SSOExpiryNotification;

import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const user = (users = [], { type, payload }) => {
    switch (type) {
        case actionTypes.ADMIN_USERS_SET:
            return payload;
        default:
            return users;
    }
};

export default user;

import { createContext, useContext, type ReactNode, useState } from 'react';

interface Props {
    children: ReactNode;
}

interface Context {
    copy: (metaData: unknown[]) => void;
    paste: () => unknown[];
}

const Context = createContext<Context | undefined>(undefined);

const ClipBoardProvider = ({ children }: Props) => {
    const [clipBoard, setClipBoard] = useState<unknown[]>([]);
    const copy = (metaData: unknown[]) => {
        setClipBoard(metaData);
    };

    const paste = () => {
        return clipBoard;
    };

    const contextValue = {
        copy,
        paste,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useClipBoard = (): Context => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useClipBoard must be used within a ClipBoardProvider');
    }
    return context;
};

export { ClipBoardProvider, useClipBoard };

import ButtonIcon from './ButtonIcon';
import translations from '../../../ts/translations';

const ButtonsReorder = ({
    totalRows,
    rowIndex,
    onReorder,
}: {
    totalRows: number;
    rowIndex: number;
    onReorder: (currentIndex: number, newIndex: number) => void;
}) => {
    const onMoveUp = (index: number) => onReorder(index, index - 1);
    const onMoveDown = (index: number) => onReorder(index, index + 1);

    return (
        <span className="nowrap flex">
            <ButtonIcon
                disabled={rowIndex === 0}
                className="margin-left-small move-up"
                glyph="Up caret"
                iconClass="icon-medium"
                title={translations.BUTTON_reorder_move_up_title}
                onClick={(event) => {
                    event.stopPropagation();
                    onMoveUp(rowIndex);
                }}
            />
            <ButtonIcon
                disabled={rowIndex === totalRows - 1}
                className="margin-left-small move-down"
                glyph="Down caret"
                iconClass="icon-medium"
                title={translations.BUTTON_reorder_move_down_title}
                onClick={(event) => {
                    event.stopPropagation();
                    onMoveDown(rowIndex);
                }}
            />
        </span>
    );
};

export default ButtonsReorder;

import type { ReactNode } from 'react';
import logo from '../../../../img/boomi-logo.svg';
import translations from '../../translations';

interface Props {
    children: ReactNode;
}
export const LoginContainer = ({ children }: Props) => {
    return (
        <div className="login">
            <div>
                <section>
                    <img className="login-header" src={logo} width={190} height={56} alt="Boomi" />
                    {children}
                    <div className="login-details">
                        <p>
                            <a
                                href="https://status.boomi.com/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {translations.AUTH_platform_status}
                            </a>
                        </p>
                        <p>
                            Boomi and your{' '}
                            <a href="http://www.boomi.com/privacy">right to privacy</a>
                        </p>
                        <p>
                            &copy; {new Date().getFullYear()} Copyright Boomi, Inc. All Rights
                            Reserved.
                        </p>
                    </div>
                </section>
            </div>
            <div className="login-image" />
        </div>
    );
};

import { type ChangeEventHandler, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/add-users-modal.less';
import { notifyError, notifySuccess } from '../../../js/actions/reduxActions/notification';
import { SYSTEM_ROLES } from '../../constants';
import { addUserToCurrentTenant } from '../../sources/user';
import translations from '../../translations';
import type { NotifyError, NotifySuccess, RecentlyAddedUser, Role } from '../../types';
import { getEmailValidityMessage } from '../../utils';
import FormGroup from '../generic/FormGroup';
import Modal from '../generic/modal/GenericModal';
import Loader from '../loader/Loader';
import RecentlyAddedUsers from './RecentlyAddedUsers';

interface AddUsersModalProps {
    onUpdateUsers: () => void;
    onClose: () => void;
    show: boolean;
    notifyError: NotifyError;
    notifySuccess: NotifySuccess;
}

interface UserInfo {
    role: Role;
    firstName: string;
    lastName: string;
    email: string;
    comments: string;
}

const initialUserInfo = {
    role: SYSTEM_ROLES.standard_user,
    firstName: '',
    lastName: '',
    email: '',
    comments: '',
} satisfies UserInfo;

type ValidityState = Omit<UserInfo, 'comments' | 'role'>;

const initialValidityState = {
    firstName: '',
    lastName: '',
    email: '',
} satisfies ValidityState;

const roleOptions = (Object.keys(SYSTEM_ROLES) as (keyof typeof SYSTEM_ROLES)[])
    .filter((key) => key !== 'service_user')
    .map((key) => {
        const { developerName, friendlyName } = SYSTEM_ROLES[key];
        return {
            value: developerName,
            label: friendlyName,
        };
    })
    .reverse();

const AddUsersModal = ({
    onUpdateUsers,
    onClose,
    show,
    notifyError,
    notifySuccess,
}: AddUsersModalProps) => {
    const [recentlyAddedUsers, setRecentlyAddedUsers] = useState<RecentlyAddedUser[]>([]);
    const [formData, setFormData] = useState<UserInfo>(initialUserInfo);
    const [validityState, setValidityState] = useState<ValidityState>(initialUserInfo);
    const [isLoading, setIsLoading] = useState(false);

    const resetForm = () => {
        setFormData(initialUserInfo);
        setValidityState(initialValidityState);
    };

    const addUser = async () => {
        try {
            setIsLoading(true);

            await addUserToCurrentTenant(formData);

            onUpdateUsers();

            setRecentlyAddedUsers([...recentlyAddedUsers, formData]);

            resetForm();

            notifySuccess(translations.TENANT_add_users_modal_add_user_success_message);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const validateInput = <T extends keyof ValidityState>(name: T, value: ValidityState[T]) => {
        switch (name) {
            case 'firstName':
            case 'lastName': {
                const isValid = value.length > 0;
                setValidityState((lastState) => ({
                    ...lastState,
                    [name]: isValid
                        ? ''
                        : translations.TENANT_add_users_modal_field_required_message,
                }));

                return isValid;
            }
            case 'email': {
                const message = getEmailValidityMessage(value);
                setValidityState((lastState) => ({
                    ...lastState,
                    [name]: message,
                }));
                return message.length === 0;
            }
            default:
                return true;
        }
    };

    const handleChange: ChangeEventHandler<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    > = ({ target: { name, value } }) => {
        if (Object.keys(validityState).includes(name)) {
            validateInput(name as keyof ValidityState, value);
        }

        const newValue =
            name === 'role'
                ? Object.values(SYSTEM_ROLES).find(({ developerName: name }) => name === value)
                : value;

        setFormData((previousData) => ({
            ...previousData,
            [name]: newValue,
        }));
    };

    const handleCancel = () => {
        resetForm();
        onClose();
    };

    const handleAddUser = () => {
        const isFormValid = (Object.keys(validityState) as (keyof ValidityState)[]).reduce(
            (last, name) => {
                const isValid = validateInput(name, formData[name]);
                // if one field is invalid, the form is
                if (!last) {
                    return false;
                }
                return isValid;
            },
            true,
        );

        if (!isFormValid) {
            return;
        }

        addUser();
    };

    return (
        <Modal
            title={translations.TENANT_add_users_modal_title}
            show={show}
            onHide={handleCancel}
            dialogClassName="add-users-modal-overrides"
            renderBody={() => (
                <>
                    <div>{translations.TENANT_add_users_modal_body_text}</div>
                    <div className="add-users-modal-body">
                        <form>
                            <FormGroup
                                label={translations.TENANT_add_users_modal_role_select_label}
                                htmlFor="add-users-modal-role-select"
                            >
                                <select
                                    id="add-users-modal-role-select"
                                    className="form-control admin-input"
                                    name="role"
                                    value={formData.role.developerName}
                                    onChange={handleChange}
                                >
                                    {roleOptions.map(({ label, value }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                            <FormGroup
                                label={translations.TENANT_add_users_modal_first_name_input_label}
                                htmlFor="add-users-modal-user-first-name-input"
                                isRequired
                                showValidation
                                isValid={validityState.firstName.length === 0}
                                validationMessage={validityState.firstName}
                            >
                                <input
                                    id="add-users-modal-user-first-name-input"
                                    className="form-control admin-input"
                                    value={formData.firstName}
                                    required
                                    name="firstName"
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup
                                label={translations.TENANT_add_users_modal_last_name_input_label}
                                htmlFor="add-users-modal-user-last-name-input"
                                isRequired
                                showValidation
                                isValid={validityState.lastName.length === 0}
                                validationMessage={validityState.lastName}
                            >
                                <input
                                    id="add-users-modal-user-last-name-input"
                                    className="form-control admin-input"
                                    name="lastName"
                                    required
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup
                                label={translations.TENANT_add_users_modal_email_input_label}
                                htmlFor="add-users-modal-user-email-input"
                                isRequired
                                showValidation
                                isValid={validityState.email.length === 0}
                                validationMessage={validityState.email}
                            >
                                <input
                                    id="add-users-modal-user-email-input"
                                    className="form-control admin-input"
                                    name="email"
                                    required
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup
                                label={translations.TENANT_add_users_modal_comments_input_label}
                                htmlFor="add-users-modal-user-comments-input"
                                isRequired={false}
                            >
                                <textarea
                                    id="add-users-modal-user-comments-input"
                                    className="form-control admin-input"
                                    name="comments"
                                    rows={2}
                                    value={formData.comments}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={handleAddUser}
                            >
                                {translations.TENANT_add_users_modal_add_user_button_label}
                            </button>
                        </form>
                        <RecentlyAddedUsers users={recentlyAddedUsers} />
                    </div>
                    {isLoading && <Loader />}
                </>
            )}
            renderFooter={() => (
                <button onClick={handleCancel} className="btn btn-default" type="button">
                    {translations.TENANT_add_users_modal_close_button_label}
                </button>
            )}
        />
    );
};

export default connect(null, { notifyError, notifySuccess })(AddUsersModal);

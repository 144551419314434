import { type ReactNode, createContext, useContext, useEffect, useState } from 'react';
import {
    type AnomalyResponse,
    type LimitReachedError,
    getAnomalyEvents,
} from '../../sources/anomalyEvents';
import translations from '../../translations';
import { uniq } from 'ramda';
import { ANOMALY_TYPE_ERROR, ANOMALY_TYPE_TIME, ANOMALY_TYPE_USAGE } from '../../constants/anomaly';
import {
    convertDateFromUnixTime,
    convertDateToUnixTime,
    formatChartDate,
} from '../../utils/dashboard';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import type { ChartBar, ChartDataPoint } from '../../types/dashboard';

interface ExDetail {
    selectedPage?: number;
    pageSize?: number;
}

interface AnomalyDashboardProviderContext {
    anomalyFilter: string | undefined;
    setAnomalyFilter: (anomalyFilter: string | undefined) => void;
    flowFilter: string | undefined;
    setFlowFilter: (flowFilter: string | undefined) => void;
    stateFilter: string | undefined;
    setStateFilter: (stateFilter: string | undefined) => void;
    flows: string[];
    stateIds: string[];
    fromDate: Date;
    dateIndexFilter: number;
    setDateIndexFilter: (dateIndexFilter: number) => void;
    changePagination: (detail: ExDetail) => void;
    isLoadingData: boolean;
    data: AnomalyResponse[];
    filteredData: AnomalyResponse[];
    paginatedData: AnomalyResponse[];
    chartData: ChartBar[];
    page: number;
    pageSize: number;
}

interface AnomalyDashboardProviderProps {
    children: ReactNode;
    notifyError: (error: string | Error) => void;
    initialDateIndex?: number;
}

const Context = createContext<AnomalyDashboardProviderContext | undefined>(undefined);

const AnomalyDashboardProvider = ({
    children,
    notifyError,
    initialDateIndex = 2,
}: AnomalyDashboardProviderProps) => {
    const [data, setData] = useState<AnomalyResponse[]>([]);
    const [filteredData, setFilteredData] = useState<AnomalyResponse[]>([]);
    const [paginatedData, setPaginatedData] = useState<AnomalyResponse[]>([]);
    const [chartData, setChartData] = useState<ChartBar[]>([]);
    const [flows, setFlows] = useState<string[]>([]);
    const [stateIds, setStateIds] = useState<string[]>([]);
    const [anomalyFilter, setAnomalyFilter] = useState<string | undefined>();
    const [flowFilter, setFlowFilter] = useState<string | undefined>();
    const [stateFilter, setStateFilter] = useState<string | undefined>();
    // | 0 -> 1D | 1 -> 3D | 2 -> 7D |
    const [dateIndexFilter, setDateIndexFilter] = useState(initialDateIndex);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [limitReachedError, setLimitReachedError] = useState<string | undefined>();

    const [fromDate, setFromDate] = useState<Date>(new Date());

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingData(true);
            await getAnomalyEvents()
                .then((data) => {
                    setData(data);
                    setFlows(uniq(data.map((d) => d.flowName)));
                    setStateIds(uniq(data.map((d) => d.stateId)));
                    setIsLoadingData(false);
                    setLimitReachedError(undefined);
                })
                .catch((e: Error | LimitReachedError) => {
                    if ((e as LimitReachedError)?.wasLimitReached) {
                        notifyError((e as LimitReachedError)?.message);
                        setLimitReachedError((e as LimitReachedError)?.message);
                    } else {
                        notifyError(e as Error);
                        setLimitReachedError(undefined);
                    }
                });
        };
        fetchData();
    }, [notifyError]);

    useEffect(() => {
        const formatBarChartData = (
            _data: AnomalyResponse[],
            _fromDate: Date,
            _toDate: Date,
            _dateIndexFilter: number,
        ): ChartBar[] => {
            const timeSlice6Hours = 3600 * 6;
            const timeSlice3Hours = 3600 * 3;
            const timeSlice1Hour = 3600;

            const timeWidth = [timeSlice1Hour, timeSlice3Hours, timeSlice6Hours][_dateIndexFilter];

            const errorChartData: ChartDataPoint[] = [];
            const timeChartData: ChartDataPoint[] = [];
            const usageChartData: ChartDataPoint[] = [];

            const errorData = _data.filter((d) => d.anomalyType === ANOMALY_TYPE_ERROR);
            const timeData = _data.filter((d) => d.anomalyType === ANOMALY_TYPE_TIME);
            const usageData = _data.filter((d) => d.anomalyType === ANOMALY_TYPE_USAGE);

            for (
                let time = convertDateToUnixTime(_fromDate);
                time < convertDateToUnixTime(_toDate);
                time += timeWidth
            ) {
                const countDataInTimeRange = (data: AnomalyResponse[]) =>
                    data.filter((d) => {
                        const unix = convertDateToUnixTime(d.dateTime);
                        return unix > time && unix < time + timeWidth;
                    }).length;

                const timeLabel = formatChartDate(
                    convertDateFromUnixTime(time),
                    _dateIndexFilter === 0 ? 'day' : 'long-day',
                );

                errorChartData.push({ x: timeLabel, y: countDataInTimeRange(errorData) });
                timeChartData.push({ x: timeLabel, y: countDataInTimeRange(timeData) });
                usageChartData.push({ x: timeLabel, y: countDataInTimeRange(usageData) });
            }

            return [
                {
                    data: errorChartData,
                    label: translations.ANOMALY_type_error,
                    backgroundColor: '#C73D58',
                    barThickness: 15,
                },
                {
                    data: timeChartData,
                    label: translations.ANOMALY_type_time,
                    backgroundColor: '#6dc79f',
                    barThickness: 15,
                },
                {
                    data: usageChartData,
                    label: translations.ANOMALY_type_usage,
                    backgroundColor: '#6ebdf5',
                    barThickness: 15,
                },
            ];
        };

        const filterData = (
            _data: AnomalyResponse[],
            _dateDaysAgo: Date,
            _anomalyFilter?: string,
            _flowFilter?: string,
            _stateFilter?: string,
        ) =>
            _data
                .filter((d) => d.isAnomalous)
                .filter(
                    (d) =>
                        (!_anomalyFilter || d.anomalyType === _anomalyFilter) &&
                        (!_flowFilter || d.flowName === _flowFilter) &&
                        (!_stateFilter || d.stateId === _stateFilter) &&
                        new Date(d.dateTime) > _dateDaysAgo,
                );

        const paginateData = (_data: AnomalyResponse[], _page: number, _pageSize: number) => {
            const startIndex = (_page - 1) * _pageSize;
            let endIndex = startIndex + _pageSize;
            if (endIndex > _data.length) {
                endIndex = _data.length;
            }

            return _data.slice(startIndex, endIndex);
        };

        const daysAgoFilter = dateIndexFilter === 0 ? 1 : dateIndexFilter === 1 ? 3 : 7;
        const weekNumberDaysAgo = new Date().getDate() - daysAgoFilter;
        const dateDaysAgo = new Date();
        dateDaysAgo.setDate(weekNumberDaysAgo);
        dateDaysAgo.setMinutes(0, 0, 0);
        setFromDate(dateDaysAgo);

        const filteringData = filterData(data, dateDaysAgo, anomalyFilter, flowFilter, stateFilter);
        setFilteredData(filteringData);

        setChartData(formatBarChartData(filteringData, dateDaysAgo, new Date(), dateIndexFilter));

        const paginatingData = paginateData(filteringData, page, pageSize);
        setPaginatedData(paginatingData);
    }, [data, anomalyFilter, flowFilter, stateFilter, page, pageSize, dateIndexFilter]);

    const changePagination = (detail: ExDetail) => {
        setPage(detail?.selectedPage ?? 1);
        setPageSize(detail?.pageSize ?? 15);
    };

    const contextValue: AnomalyDashboardProviderContext = {
        anomalyFilter,
        setAnomalyFilter,
        flowFilter,
        setFlowFilter,
        stateFilter,
        setStateFilter,
        flows,
        stateIds,
        fromDate,
        dateIndexFilter,
        setDateIndexFilter,
        changePagination,
        isLoadingData,
        data,
        filteredData,
        paginatedData,
        chartData,
        pageSize,
        page,
    };

    if (limitReachedError) {
        return (
            <ExAlertBanner data-testid="anomalyLimitBanner" type={AlertBannerType.ERROR} open>
                <div>
                    {limitReachedError}
                    <p>{translations.ANOMALY_limit_reached_suggestion}</p>
                    <b>
                        {translations.ANOMALY_limit_reached_learn_more_1}
                        <a
                            href="https://help.boomi.com/docs/Atomsphere/Flow/topics/flo-Observability_configuration_0b36d4b4-5f27-49d2-b9e2-487954d6df5b"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {translations.ANOMALY_limit_reached_learn_more_2}
                        </a>
                        {translations.ANOMALY_limit_reached_learn_more_3}
                    </b>
                </div>
            </ExAlertBanner>
        );
    }

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useAnomalyDashboard = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useAnomalyDashboard must be used within a AnomalyDashboardProvider');
    }
    return context;
};

export { AnomalyDashboardProvider, useAnomalyDashboard };

import { exportTenant } from '../../sources/tenant';
import translations from '../../translations';
import type { NotifyError } from '../../types';
import ButtonPrimary from '../buttons/ButtonPrimary';

interface ExportTenantProps {
    tenantId: string;
    tenantName: string;
    notifyError: NotifyError;
}

const ExportTenant = ({ tenantId, tenantName, notifyError }: ExportTenantProps) => {
    const onClick = () => exportTenant(tenantId, tenantName).catch(notifyError);

    return (
        <>
            <p>{translations.IMPORT_EXPORT_export_help_text}</p>
            <ButtonPrimary onClick={onClick}>
                {translations.IMPORT_EXPORT_export_button_label}
            </ButtonPrimary>
        </>
    );
};

export default ExportTenant;

const SubflowIcon = ({ className = '', size = '1em' }) => (
    // biome-ignore lint/a11y/noSvgWithoutTitle: Including a title breaks tests, requires dedicated refactor
    <svg
        className={className}
        width={size}
        height={size}
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.76562 20.8125L9.76484 14.9539L3.90625 14.9531"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.5625 23.1562L8.59375 16.125"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.625 19.25H20.3125C20.5197 19.25 20.7184 19.1677 20.8649 19.0212C21.0114 18.8747 21.0938 18.676 21.0938 18.4688V5.1875C21.0938 4.9803 21.0114 4.78159 20.8649 4.63507C20.7184 4.48856 20.5197 4.40625 20.3125 4.40625H7.03125C6.82405 4.40625 6.62534 4.48856 6.47882 4.63507C6.33231 4.78159 6.25 4.9803 6.25 5.1875V9.875"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9531 13.7319L16.8924 11.7926H17.9791C17.438 12.9273 16.2882 13.7169 14.9531 13.7319ZM11.5559 9.85136H13.3428L15.2841 7.91004H17.288C17.5719 8.18862 17.8058 8.51722 17.9791 8.8807H15.6859L13.7447 10.822H11.5559C11.5331 10.6633 11.5175 10.5022 11.5175 10.3367C11.5175 10.1717 11.5331 10.0105 11.5559 9.85136ZM14.8803 6.94133L12.941 8.88069H11.8495C12.3921 7.74503 13.5432 6.95493 14.8803 6.94133ZM18.312 10.3366C18.312 10.5022 18.296 10.6633 18.2732 10.822H16.4906L14.5493 12.7633H12.5415C12.2567 12.4852 12.0232 12.1561 11.8495 11.7926H14.147L16.0882 9.85136H18.2732C18.296 10.0105 18.312 10.1716 18.312 10.3366ZM14.9148 5.96875C12.5022 5.96875 10.5469 7.92464 10.5469 10.3366C10.5469 12.7492 12.5022 14.7045 14.9148 14.7045C17.3268 14.7045 19.2827 12.7492 19.2827 10.3366C19.2827 7.92464 17.3268 5.96875 14.9148 5.96875Z"
            fill="currentColor"
        />
    </svg>
);

export default SubflowIcon;

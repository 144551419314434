import type { ChangeEventHandler } from 'react';
import { getDateString, getTimeString } from '../../utils/date';

const getFormattedDatetime = (datetimeStr: string, isUTC: boolean) => {
    const dateValue = getDateString(new Date(datetimeStr), isUTC);
    const timeValue = getTimeString(new Date(datetimeStr), isUTC);

    return dateValue && timeValue ? `${dateValue}T${timeValue}` : '';
};

interface DateTimeInputProps {
    className?: string;
    onChange: (value: string) => void;
    value: string;
    isUTC?: boolean;
    maxValue?: string;
    minValue?: string;
    isReadonly?: boolean;
}

const DateTimeInput = ({
    className,
    onChange,
    value,
    isUTC = false,
    maxValue,
    minValue,
    isReadonly = false,
}: DateTimeInputProps) => {
    const updateDateTime: ChangeEventHandler<HTMLInputElement> = ({
        target: { value, valueAsNumber },
    }) => {
        if (value === '') {
            onChange('');
            return;
        }

        if (isUTC) {
            const dateUTC = new Date(valueAsNumber);
            onChange(dateUTC.toISOString());
        } else {
            const date = new Date(value);
            onChange(date.toISOString());
        }
    };

    return (
        <input
            data-testid="input-datetime"
            className={className}
            value={getFormattedDatetime(value, isUTC)}
            onChange={updateDateTime}
            type="datetime-local"
            max={maxValue ? getFormattedDatetime(maxValue, isUTC) : undefined}
            min={minValue ? getFormattedDatetime(minValue, isUTC) : undefined}
            readOnly={isReadonly}
        />
    );
};

export default DateTimeInput;

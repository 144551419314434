export const WAIT_TYPES = {
    relativeTime: 'Relative Time',
    relativePhrase: 'Relative Phrase',
    absoluteTime: 'Absolute Time',
    value: 'Value',
};

export const WAIT_BOUNDARIES = {
    min: 5, // seconds
    max: 120, //days
    altMin: 1, //minute
};

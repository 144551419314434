import './component-suggest.less';
import { useEffect, useState } from 'react';
import { COMPONENT_TYPE } from '../../../constants';
import { getType } from '../../../../../sources/type';
import type { ComponentRegistry, TypeElementResponseAPI } from '../../../../../types';
import ComponentSuggestOptions from './ComponentSuggestOptions';
import ComponentSuggestColumns from './ComponentSuggestColumns';
import ComponentSuggestSingleColumn from './ComponentSuggestSingleColumn';
import ComponentSuggestTiles from './ComponentSuggestTiles';
import ComponentSuggestCharts from './ComponentSuggestCharts';
import { suggestComponent } from '../../../../../sources/componentSuggest';
import type { SuggestPrediction } from '../../../../../types/componentSuggest';
import { SYSTEM_TYPE_NAME_ID_MAPPINGS } from '../../../../../constants';
import ComponentSuggestObjectProperties from './ComponentSuggestObjectProperties';
import { usePageEditor } from '../../PageEditorProvider';
import { useComposer } from '../composer/ComposerProvider';

enum Screen {
    // Suggestion options rendered as tiles here
    options = 0,
    // Column options rendered and checkboxes for each here
    multipleColumns = 1,
    // A single dropdown rendered to pick which column to display
    singleColumn = 2,
    // A few dropdowns rendered to pick which columns to display for Tiles
    tiles = 3,
    // A few dropdowns rendered to pick label and value columns
    charts = 4,
}

const ComponentSuggest = () => {
    const { state, getValidComponents, getComponent } = usePageEditor();
    const { dragDropElements } = useComposer();
    const { componentSuggestValue } = state;

    const [type, setType] = useState<TypeElementResponseAPI | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [neuralNetworkSuggestedType, setNeuralNetworkSuggestedType] = useState<
        string | undefined
    >();

    const updateType = async (typeId: string) => {
        const newType = await getType(typeId);
        setType(newType);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        // On first render, load the type of the selected value
        const typeId =
            componentSuggestValue?.typeElementPropertyTypeElementId ??
            componentSuggestValue?.typeElementId;
        if (typeId) {
            updateType(typeId);
        }
    }, [
        componentSuggestValue?.typeElementId,
        componentSuggestValue?.typeElementPropertyTypeElementId,
    ]);

    const [screen, setScreen] = useState<Screen>(Screen.options);
    const goToMultipleColumnsScreen = () => setScreen(Screen.multipleColumns);
    const goToSingleColumnScreen = () => setScreen(Screen.singleColumn);
    const goToTilesScreen = () => setScreen(Screen.tiles);
    const goToOptionsScreen = () => setScreen(Screen.options);
    const goToChartsScreen = () => setScreen(Screen.charts);

    let availableComponents = getValidComponents(componentSuggestValue?.contentType as string);
    let calculatedSuggestedType = null as string | null;
    let showObjectPropertyPicker = false;

    switch (componentSuggestValue?.contentType) {
        case 'ContentString':
        case 'ContentNumber':
        case 'ContentPassword':
        case 'ContentEncrypted':
        case 'ContentDateTime':
        case 'ContentDate': {
            calculatedSuggestedType = COMPONENT_TYPE['INPUT'];
            break;
        }
        case 'ContentBoolean': {
            calculatedSuggestedType = COMPONENT_TYPE['CHECKBOX'];
            break;
        }
        case 'ContentContent': {
            calculatedSuggestedType = COMPONENT_TYPE['CONTENT'];
            break;
        }
        case 'ContentList': {
            calculatedSuggestedType = COMPONENT_TYPE['TABLE'];
            break;
        }
        case 'ContentObject': {
            const typeId =
                componentSuggestValue.typeElementPropertyTypeElementId ??
                componentSuggestValue.typeElementId;
            if (typeId === SYSTEM_TYPE_NAME_ID_MAPPINGS['$Payment Card']) {
                availableComponents = [getComponent(COMPONENT_TYPE['PAYMENTCARD'])];
                calculatedSuggestedType = COMPONENT_TYPE['PAYMENTCARD'];
            } else {
                showObjectPropertyPicker = true;
            }
            break;
        }
    }

    const otherSuggestedComponents =
        availableComponents.filter((component) => component.type !== calculatedSuggestedType) ?? [];

    const calculatedComponent = availableComponents.find(
        (component) => component.type === calculatedSuggestedType,
    );

    const suggestedComponents = calculatedComponent
        ? [calculatedComponent, ...otherSuggestedComponents]
        : otherSuggestedComponents;

    const [selectedComponent, setSelectedComponent] = useState<ComponentRegistry | undefined>(
        suggestedComponents?.[0],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        // When the reference changes, change the default suggestion
        setSelectedComponent(suggestedComponents?.[0]);
        if (showObjectPropertyPicker) {
            return;
        }
        // Only request AI suggestion if there is more than 1 option
        if (suggestedComponents?.length > 1) {
            setIsLoading(true);
            suggestComponent({
                id: componentSuggestValue?.id,
                propertyId: componentSuggestValue?.typeElementPropertyId,
            })
                .then((prediction: SuggestPrediction) => {
                    if (prediction.confidence > 0.5) {
                        setNeuralNetworkSuggestedType(prediction.predictedOutput.toUpperCase());
                    }
                })
                .catch((error: Error) => {
                    console.error('Error contacting component suggest', error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [componentSuggestValue?.id, componentSuggestValue?.typeElementPropertyId]);

    const mainContainer = dragDropElements.filter((element) => element.parentId === null)[0]
        ?.id as string;

    const order: number = dragDropElements.filter(
        (element) => element.parentId === mainContainer,
    ).length;

    return showObjectPropertyPicker ? (
        <ComponentSuggestObjectProperties isLoading={isLoading} />
    ) : screen === Screen.options || selectedComponent === undefined ? (
        <ComponentSuggestOptions
            selectedComponent={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            calculatedSuggestedType={calculatedSuggestedType}
            neuralNetworkSuggestedType={neuralNetworkSuggestedType}
            goToMultipleColumnsScreen={goToMultipleColumnsScreen}
            goToSingleColumnScreen={goToSingleColumnScreen}
            goToTilesScreen={goToTilesScreen}
            goToChartsScreen={goToChartsScreen}
            suggestedComponents={suggestedComponents}
            mainContainer={mainContainer}
            order={order}
            isLoading={isLoading}
        />
    ) : screen === Screen.multipleColumns ? (
        <ComponentSuggestColumns
            type={type}
            selectedComponent={selectedComponent}
            goToOptionsScreen={goToOptionsScreen}
            mainContainer={mainContainer}
            order={order}
        />
    ) : screen === Screen.singleColumn ? (
        <ComponentSuggestSingleColumn
            type={type}
            selectedComponent={selectedComponent}
            goToOptionsScreen={goToOptionsScreen}
            mainContainer={mainContainer}
            order={order}
        />
    ) : screen === Screen.tiles ? (
        <ComponentSuggestTiles
            type={type}
            selectedComponent={selectedComponent}
            goToOptionsScreen={goToOptionsScreen}
            mainContainer={mainContainer}
            order={order}
        />
    ) : (
        <ComponentSuggestCharts
            type={type}
            selectedComponent={selectedComponent}
            goToOptionsScreen={goToOptionsScreen}
            mainContainer={mainContainer}
            order={order}
        />
    );
};

export default ComponentSuggest;

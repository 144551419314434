import { fetchAndParse } from '../../ts/utils/ajax';
import type { OpenApiAPI, OpenApiServiceAPI } from '../types';
import { getTenantId } from '../utils/tenant';

export const getOpenApiSchemaInfoFromValueId = ({
    valueId,
    operationType,
}: {
    valueId: string | null | undefined;
    operationType: string;
}) => {
    if (!valueId) {
        return;
    }
    return fetchAndParse<OpenApiAPI>({
        url: `/api/draw/1/openapi/info/value/${valueId}/${operationType}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
    });
};

export const GetOpenApiServices = () => {
    return fetchAndParse<OpenApiServiceAPI[]>({
        url: '/api/draw/1/openapi/info/service',
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
    });
};

export const getOpenApiSchemaInfoFromServiceId = ({
    serviceId,
}: {
    serviceId: string | null | undefined;
}) => {
    if (!serviceId) {
        return;
    }
    return fetchAndParse<OpenApiAPI>({
        url: `/api/draw/1/openapi/info/service/${serviceId}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
    });
};

export const getFileOpenApiSchemaInfo = ({
    serviceId,
}: {
    serviceId: string | null | undefined;
}) => {
    if (!serviceId) {
        return;
    }
    return fetchAndParse<OpenApiAPI>({
        url: `/api/draw/1/openapi/info/service/${serviceId}/file`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
    });
};

import ServiceEditor from '../services/editService/ServiceEditor';
import ServiceConfiguration from './editService/ServiceConfiguration';
import ServiceList from './serviceList/ServiceList';
import ServicePreview from './editService/ServicePreview';
import NewService from './newService/NewService';
import { populateServiceValueRequest } from '../../utils/ajax';
import type { BreadcrumbLabels, ServiceViews } from './ServiceConstants';
import {
    useServices,
    type NewServiceData,
    type ServicesProviderState,
} from './contextProviders/ServicesProvider';
import { ElementType } from '../../types';
import {
    getService,
    saveService,
    installService,
    getServiceConfigurationValues,
} from '../../sources/service';
import type {
    ServiceValueRequestAPI,
    ServiceElementResponseAPI,
    ServiceElementRequestAPI,
    ConfigurationAndSelection,
} from '../../types/service';

import '../../../../css/notification.less';
import type { ReactNode } from 'react';
import { isNullOrEmpty } from '../../utils/guard';
import { guid } from '../../utils/guid';
import OpenApiConfiguration from './editService/OpenApiConfiguration';
import { OpenApiConnectorProvider } from './contextProviders/OpenApiConnectorProvider';

export type SaveService = (
    name: string | null,
    url: string,
    username: string | null | undefined,
    password: string | null | undefined,
    httpAuthenticationClientCertificateReference: string | null | undefined,
    httpAuthenticationClientCertificatePasswordReference: string | null | undefined,
    comments: string | null | undefined,
    configurationValues: ServiceValueRequestAPI[] | null,
    identityProviderId: string | null | undefined,
) => Promise<void>;

export type SwitchToRefreshServiceConfig = (
    name: string | null | undefined,
    url: string | undefined,
    username: string | null | undefined,
    password: string | null | undefined,
    comments: string | null | undefined,
    id: string | null,
    httpAuthenticationClientCertificateReference: string | null | undefined,
    httpAuthenticationClientCertificatePasswordReference: string | null | undefined,
    identityProviderId: string | null | undefined,
) => Promise<void>;

const ServiceController = () => {
    const {
        servicesState,
        setServicesState,
        addNotification,
        tenantId,
        initialState,
        pushBreadCrumb,
        setActiveBreadCrumb,
        breadcrumbs,
    } = useServices();

    const {
        currentServiceData,
        serviceConfigurationValues,
        serviceTypesAndActions,
        newServiceData,
    }: ServicesProviderState = servicesState;

    const view = breadcrumbs.activeItemId;

    const pushOrSetActiveBreadCrumb = (id: ServiceViews, content: BreadcrumbLabels) => {
        const breadCrumbExists = breadcrumbs.trail.find((crumb) => crumb?.id === id);
        if (breadCrumbExists) {
            setActiveBreadCrumb(id);
        } else {
            pushBreadCrumb(id, content);
        }
    };

    /**
     * @description Queries a specific service for its configuration details
     * which can then be passed on to the "editing view".
     */
    const onEditService = async (service: ServiceElementResponseAPI) => {
        setServicesState({ ...servicesState, isLoading: true });
        // The information obtained from the list doesn't have Types, so we need to query the service specifically

        try {
            const currentServiceData = await getService(service.id);
            setServicesState({
                ...servicesState,
                currentServiceData,
                isLoading: false,
            });

            pushBreadCrumb('ServiceEditor', 'Edit Connector');
        } catch (error) {
            setServicesState({
                ...servicesState,
                isLoading: false,
            });

            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    };

    /**
     * @description Saves a service and all of its configuration settings
     */
    const onSaveService: SaveService = async (
        name,
        url,
        username,
        password,
        httpAuthenticationClientCertificateReference,
        httpAuthenticationClientCertificatePasswordReference,
        comments,
        configurationValues,
        identityProviderId,
    ) => {
        setServicesState({ ...servicesState, isLoading: true });

        const serviceData = Object.assign({}, currentServiceData, {
            developerName: name,
            uri: url,
            httpAuthenticationUsername: username,
            httpAuthenticationPassword: password,
            httpAuthenticationClientCertificateReference,
            httpAuthenticationClientCertificatePasswordReference,
            developerSummary: comments,
            configurationValues,
            //we set install to null here as we don't want to pass the install actions and
            //types to this request that's handled by the installation of the service
            install: null,
            identityProviderId,
        }) as ServiceElementRequestAPI;

        return saveService(serviceData)
            .then((currentServiceData) => {
                setServicesState({
                    ...servicesState,
                    currentServiceData,
                    isLoading: false,
                });
                switchView('ServiceList');
                return;
            })
            .catch((error) => {
                setServicesState({
                    ...servicesState,
                    isLoading: false,
                });

                addNotification({
                    type: 'error',
                    message: (error as Error).message,
                    isPersistent: true,
                });
            });
    };

    const setNewServiceDetails = (newServiceData: NewServiceData) => {
        setServicesState({ ...servicesState, newServiceData });
    };

    /**
     * @description Retrieves service configuration values that have either been set
     * (if the service has been previously installed/configured) or configuration value
     * placeholders if the service has been initially created.
     */
    const switchToRefreshServiceConfig: SwitchToRefreshServiceConfig = async (
        name,
        url,
        username,
        password,
        comments,
        id,
        httpAuthenticationClientCertificateReference,
        httpAuthenticationClientCertificatePasswordReference,
        identityProviderId,
    ) => {
        const { currentServiceData, newServiceData } = servicesState;

        const doesServiceAlreadyExist = !isNullOrEmpty(id);
        let newCurrentServiceData = null;
        let updatedServiceData = {} as NewServiceData;

        if (doesServiceAlreadyExist) {
            newCurrentServiceData = Object.assign({}, currentServiceData, {
                developerName: name,
                uri: url,
                username,
                password,
                httpAuthenticationUsername: username,
                httpAuthenticationPassword: password,
                developerSummary: comments,
                identityProviderId,
            });
            setServicesState({
                ...servicesState,
                currentServiceData: newCurrentServiceData,
                isLoading: true,
            });
        } else {
            updatedServiceData = Object.assign({}, newServiceData, {
                name,
                url,
                username,
                password,
                comments,
                id,
                identityProviderId,
            });

            setServicesState({
                ...servicesState,
                newServiceData: updatedServiceData,
                isLoading: true,
            });
        }

        try {
            const serviceConfigurationValues = await getServiceConfigurationValues({
                uri: url,
                basicUsername: username,
                basicPassword: password,
                httpAuthenticationClientCertificateReference,
                httpAuthenticationClientCertificatePasswordReference,
                id,
            });

            setServicesState({
                ...servicesState,
                currentServiceData: newCurrentServiceData,
                newServiceData: updatedServiceData,
                serviceConfigurationValues: serviceConfigurationValues ?? [],
                isLoading: false,
            });

            pushOrSetActiveBreadCrumb('ServiceConfiguration', 'Configure Connector');
        } catch (error) {
            setServicesState({ ...servicesState, isLoading: false });
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    };

    const selectServiceType = ({
        description,
        url,
        canHaveIdentity,
    }: {
        description: ReactNode;
        url: string | undefined;
        canHaveIdentity: boolean;
    }) => {
        setServicesState({
            newServiceData: {
                name: newServiceData?.name,
                description,
                url,
                canHaveIdentity,
            },
        });
    };

    const installServiceAndChangeView = async (configValueIds: ConfigurationAndSelection[]) => {
        const previousView = view;

        // If we have just been given new configuration values
        const populatedServiceValueRequests: ServiceValueRequestAPI[] = (
            serviceConfigurationValues && configValueIds
                ? // Then create the request Object and use that
                  populateServiceValueRequest(serviceConfigurationValues, configValueIds)
                : // Otherwise use what's in state (could be empty)
                  // the [] used to be servicesState.populatedServiceValueRequests
                  []
        ).filter((configValue: ServiceValueRequestAPI) => configValue.valueElementToReferenceId);

        // If there is currentServiceData (They are editing an existing service)
        const savedService = isNullOrEmpty(currentServiceData)
            ? // Then use that
              {
                  developerName: newServiceData?.name,
                  developerSummary: newServiceData?.comments,
                  uri: isNullOrEmpty(newServiceData?.url)
                      ? `${newServiceData?.baseUrl as string}${
                            newServiceData?.pathToService as string
                        }`
                      : newServiceData?.url,
                  httpAuthenticationUsername: newServiceData?.httpAuthenticationUsername,
                  httpAuthenticationPassword: newServiceData?.httpAuthenticationPassword,
                  httpAuthenticationClientCertificateReference:
                      newServiceData?.httpAuthenticationClientCertificateReference,
                  httpAuthenticationClientCertificatePasswordReference:
                      newServiceData?.httpAuthenticationClientCertificatePasswordReference,
                  elementType: ElementType.Connector,
                  format: 'json',
                  configurationValues: populatedServiceValueRequests,
                  id: guid(),
                  identityProviderId: newServiceData?.identityProviderId,
              }
            : {
                  ...currentServiceData,
                  configurationValues: populatedServiceValueRequests,
              };

        setServicesState({
            ...servicesState,
            populatedServiceValueRequests,
            isLoading: true,
        });

        const errorHandler = (error: Error) => {
            switchView(previousView);

            setServicesState({
                ...servicesState,
                isLoading: false,
            });

            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
        };

        try {
            const currentServiceData = await installService({
                developerName: savedService.developerName,
                developerSummary: savedService.developerSummary,
                uri: savedService.uri,
                basicUsername: savedService.httpAuthenticationUsername,
                basicPassword: savedService.httpAuthenticationPassword,
                httpAuthenticationClientCertificateReference:
                    savedService.httpAuthenticationClientCertificateReference,
                httpAuthenticationClientCertificatePasswordReference:
                    savedService.httpAuthenticationClientCertificatePasswordReference,
                configurationValues: savedService.configurationValues,
                id: savedService.id,
                identityProviderId: savedService.identityProviderId,
            });

            setServicesState({
                ...servicesState,
                currentServiceData,
                isLoading: false,
            });
            switchView('ServiceList');
        } catch (error) {
            errorHandler(error as Error);
        }
    };

    const switchToNewService = () => {
        setServicesState(initialState);

        pushBreadCrumb('NewServiceSelect', 'New Connector');
    };

    const switchView = (newView: ServiceViews) => {
        setActiveBreadCrumb(newView);
    };

    switch (view) {
        case 'ServiceEditor':
            return (
                <ServiceEditor
                    tenantId={tenantId}
                    switchView={switchView}
                    currentServiceData={currentServiceData as ServiceElementResponseAPI}
                    switchToRefreshServiceConfig={switchToRefreshServiceConfig}
                    saveService={onSaveService}
                />
            );

        case 'ServiceConfiguration':
            return (
                <ServiceConfiguration
                    configurationValues={serviceConfigurationValues}
                    newServiceData={newServiceData}
                    currentServiceData={currentServiceData}
                    switchView={switchView}
                    installService={installServiceAndChangeView}
                />
            );

        case 'OpenApiConfiguration':
            return (
                <OpenApiConnectorProvider>
                    <OpenApiConfiguration
                        currentServiceData={currentServiceData}
                        switchView={switchView}
                    />
                </OpenApiConnectorProvider>
            );

        case 'ServicePreview':
            return (
                <ServicePreview
                    switchView={switchView}
                    newServiceData={newServiceData}
                    currentServiceData={currentServiceData}
                    serviceTypesAndActions={serviceTypesAndActions}
                />
            );

        case 'NewServiceSelect':
            return (
                <NewService
                    selectServiceType={selectServiceType}
                    newServiceData={newServiceData}
                    setNewServiceDetails={setNewServiceDetails}
                    switchToRefreshServiceConfig={switchToRefreshServiceConfig}
                    onCancel={switchView}
                />
            );
        default:
            return (
                <ServiceList
                    onEditService={onEditService}
                    switchToNewService={switchToNewService}
                />
            );
    }
};

export default ServiceController;

import type { FormEvent } from 'react';

interface Props {
    comment: string | null;
    onCommentChanged: (e: FormEvent<HTMLTextAreaElement>) => void;
}

const ActivatePublish = ({ comment, onCommentChanged }: Props) => (
    <>
        <p>
            Publishing this Flow will make this version of the Flow the default version. This will
            be the version of the Flow that users will run if a Flow Version Id is
            <strong> not </strong>
            specified
        </p>
        <textarea
            className="form-control"
            placeholder="Enter a comment for this version"
            rows={3}
            value={comment ?? ''}
            onChange={onCommentChanged}
        />
    </>
);

export default ActivatePublish;

import { useEffect, useState } from 'react';
import {
    BUSINESS_RULES_ELEMENTS,
    COMMON_ICON_ATTRIBUTE_OPTIONS,
    FAULT_OUTCOME_DEVELOPER_NAME,
    FAULT_OUTCOME_ELEMENTS,
    MAP_ELEMENT_TYPES,
    ONE_OUTCOME_ELEMENTS,
    UI_ELEMENTS,
    FAULT_OUTCOME_ID,
} from '../../../../../../ts/constants';
import translations from '../../../../../../ts/translations';
import { getByID } from '../../../../../../ts/utils/collection';
import { isNullOrEmpty, isNullOrWhitespace } from '../../../../../../ts/utils/guard';
import { guid } from '../../../../../../ts/utils/guid';
import { stringReplace, safeToLower } from '../../../../../../ts/utils/string';
import ButtonDefault from '../../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../../../ts/components/generic/FormGroup';
import Glyphicon from '../../../../../../ts/components/generic/Glyphicon';
import Loader from '../../../../../../ts/components/loader/Loader';
import Toggle from '../../../../../../ts/components/inputs/Toggle';
import { useMapElement } from '../../contextProviders/MapElementProvider';
import BusinessRules from '../../outcome/businessRules/BusinessRules';
import NameInput from '../NameInput';
import Table from '../../../../../../ts/components/generic/Table';
import { Trash } from '@phosphor-icons/react';
import ModalBody from '../../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../../ts/components/generic/modal/ModalFooter';
import { getPage } from '../../../../../../ts/sources/page';
import Select from 'react-select';
import { getSharedStyles } from '../../../../../../ts/utils/select';
import CreatableSelect from 'react-select/creatable';

const OutcomeDetails = ({ outcome, onCancel, onSave }) => {
    const { mapElement, mapElements, setMapElement, notifyError, setConfigTitle, container } =
        useMapElement();

    const [pageComponentOptions, setPageComponentOptions] = useState([]);
    const [pageComponentsLoading, setPageComponentsLoading] = useState(true);

    const [menuPortalTarget, setMenuPortalTarget] = useState(null);

    useEffect(() => {
        // The modal appears after the DOM is loaded so we need to wait in order to set a menuPortalTarget
        requestAnimationFrame(() => {
            setMenuPortalTarget(document.querySelector('.outcome-modal'));
        });
    }, []);

    // OutcomeDetails needs a list of page components
    // that are used to populate a dropdown for specifying
    // if an outcome should placed with a page component
    const fetchPageComponents = async (pageId) => {
        try {
            const pageElementResponse = await getPage(pageId);

            const parsedResponse = pageElementResponse;
            setPageComponentOptions(
                parsedResponse?.pageComponents?.map(({ id, developerName }) => ({
                    value: id,
                    label: developerName,
                })) || [],
            );
            setPageComponentsLoading(false);
        } catch (error) {
            notifyError(error);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (mapElement?.pageElementId) {
            fetchPageComponents(mapElement?.pageElementId);
        } else {
            setPageComponentsLoading(false);
        }

        if (isNullOrEmpty(outcome?.id)) {
            // New outcome
            const nextMapName = getByID(nextMapElementId, mapElements)?.developerName;
            const name = nextMapName
                ? stringReplace(translations.OUTCOME_next_map_element_name_template, {
                      nextMapName,
                  })
                : translations.OUTCOME_next_map_element_name_default;
            setDeveloperName(name);
            setLabel(name);
        }

        // Attributes are stored as a single object on the outcome. This can be difficult to manipulate and was the cause of some UX issues.
        // If attributes exist, then convert the object into an array of key value pairs during editing. It later converts them back for storage.
        if (!(isNullOrEmpty(outcome) || isNullOrEmpty(outcome.attributes))) {
            setSelectedAttributesList(
                Object.entries(outcome.attributes).map(([key, value]) => ({
                    key,
                    value,
                })),
            );
        }
    }, []);

    useEffect(() => {
        setConfigTitle(
            stringReplace(translations.OUTCOME_config_title_template, {
                mapElementName: mapElement.developerName,
            }),
        );

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    const hasBusinessRules = BUSINESS_RULES_ELEMENTS.includes(
        mapElement?.elementType.toLowerCase(),
    );
    const isOneOutcomeElement = ONE_OUTCOME_ELEMENTS.includes(
        mapElement?.elementType.toLowerCase(),
    );
    const isUIElement = UI_ELEMENTS.includes(mapElement?.elementType.toLowerCase());
    const hasFaultOutcome = FAULT_OUTCOME_ELEMENTS.includes(mapElement?.elementType.toLowerCase());

    const [developerName, setDeveloperName] = useState(outcome?.developerName ?? '');
    const [label, setLabel] = useState(outcome?.label ?? '');
    const [nextMapElementId, setNextMapElementId] = useState(outcome?.nextMapElementId ?? null);
    const [pageActionBindingType, setPageActionBindingType] = useState(
        outcome?.pageActionBindingType ?? 'SAVE',
    );
    const actionBindingTypeList = [
        { label: translations.OUTCOME_action_binding_type_save_label, value: 'SAVE' },
        { label: translations.OUTCOME_action_binding_type_no_save_label, value: 'NO_SAVE' },
        {
            label: translations.OUTCOME_action_binding_type_partial_save_label,
            value: 'PARTIAL_SAVE',
        },
    ];
    const [order, setOrder] = useState(outcome?.order ?? mapElement.outcomes?.length ?? 0);
    const [pageActionType, setPageActionType] = useState(outcome?.pageActionType ?? null);
    const actionTypeList = [
        'Add',
        'Apply',
        'Back',
        'Cancel',
        'Close',
        'Delegate',
        'Delete',
        'Done',
        'Edit',
        'Escalate',
        'Import',
        'Insert',
        'New',
        'Next',
        'Open',
        'Query',
        'Reject',
        'Remove',
        'Save',
        'Submit',
        'Update',
        'Upsert',
    ];
    const [selectedAttributesList, setSelectedAttributesList] = useState([]);
    const attributesList = [
        { label: translations.OUTCOME_attribute_button_type_label, value: 'type' },
        { label: translations.OUTCOME_attribute_button_icon_label, value: 'icon' },
        { label: translations.OUTCOME_attribute_button_size_label, value: 'size' },
        { label: translations.OUTCOME_attribute_button_uri_label, value: 'uri' },
        { label: translations.OUTCOME_attribute_button_target_label, value: 'target' },
        { label: translations.OUTCOME_attribute_button_classes_label, value: 'classes' },
        { label: translations.OUTCOME_attribute_button_display_label, value: 'display' },
        {
            label: translations.OUTCOME_attribute_button_type_property_id_label,
            value: 'uriTypeElementPropertyId',
        },
    ];
    const [comparison, setComparison] = useState(outcome?.comparison ?? null);
    const [pageObjectBindingId, setPageObjectBindingId] = useState(
        outcome?.pageObjectBindingId ?? null,
    );
    const [isBulkAction, setIsBulkAction] = useState(outcome?.isBulkAction ?? false);

    const isExistingOutcome = isNullOrEmpty(outcome?.id) === false;

    const [hasSubmitted, setHasSubmitted] = useState(false);

    const isNameValid = isNullOrWhitespace(developerName) === false;
    const isLabelValid = isUIElement
        ? isNullOrWhitespace(label) === false
        : // For these elements label is not shown and so empty is valid
          true;
    const isOrderValid = isOneOutcomeElement
        ? isNullOrEmpty(order) === false
        : // For these elements order is not shown and so empty is valid
          true;
    const areAttributesValue =
        selectedAttributesList.length === 0 ||
        !selectedAttributesList.find((attribute) => !attribute.key);

    const isFormValid = isNameValid && isLabelValid && isOrderValid && areAttributesValue;

    const validateSave = () => {
        setHasSubmitted(true);

        if (isFormValid) {
            // Converts the array of key, value pairs into a single object for storage.
            const attributes = selectedAttributesList.reduce(
                (previousValue, currentValue) =>
                    Object.assign(previousValue, { [currentValue.key]: currentValue.value }),
                {},
            );

            const updatedOutcome = {
                ...outcome,
                developerName,
                label,
                nextMapElementId,
                pageActionBindingType,
                pageActionType,
                attributes,
                order,
                comparison,
                pageObjectBindingId,
                isBulkAction,
            };

            if (!isExistingOutcome) {
                updatedOutcome.id = guid();
            }
            const newMapElement = {
                ...mapElement,
                outcomes: (mapElement.outcomes || [])
                    .filter((o) => o.id !== updatedOutcome.id)
                    .concat([updatedOutcome]),
            };

            setMapElement(newMapElement);
            onSave(newMapElement);
        }
    };

    const setAttributeKey = (key, index) => {
        const selectedAttributes = selectedAttributesList;

        if (selectedAttributes[index].key !== key) {
            if (
                selectedAttributes.some(
                    (attribute) => safeToLower(attribute.key) === safeToLower(key),
                )
            ) {
                notifyError(
                    stringReplace(
                        translations.OUTCOME_duplicate_attribute_key_error_message_template,
                        {
                            key,
                        },
                    ),
                );
                return;
            }

            selectedAttributes[index].key = key;
            setSelectedAttributesList([...selectedAttributes]);
        }
    };

    const setAttributeValue = (value, index) => {
        const selectedAttributes = selectedAttributesList;

        if (selectedAttributes[index].value !== value) {
            selectedAttributes[index].value = value;
            setSelectedAttributesList([...selectedAttributes]);
        }
    };

    const removeAttribute = (index) => {
        const selectedAttributes = selectedAttributesList;
        selectedAttributes.splice(index, 1);

        setSelectedAttributesList([...selectedAttributes]);
    };

    const makeFaultOutcome = () => {
        const updatedOutcome = {
            id: FAULT_OUTCOME_ID,
            developerName: FAULT_OUTCOME_DEVELOPER_NAME,
            order,
            nextMapElementId,
            controlPoints: outcome?.controlPoints,
        };

        // Remove any previous fault outcome
        // Remove the original outcome being edited
        const nonFaultElements =
            mapElement.outcomes !== null
                ? mapElement.outcomes.filter(
                      (o) => o.id !== FAULT_OUTCOME_ID && o.id !== outcome?.id,
                  )
                : [];

        const newMapElement = {
            ...mapElement,
            outcomes: [
                ...nonFaultElements,
                // Add a new fault outcome
                updatedOutcome,
            ],
        };

        setMapElement(newMapElement);
        onSave(newMapElement);
    };

    const onChangePlaceOutcome = (selection) => {
        setPageObjectBindingId(selection?.value);
    };

    const getAttributeValueInput = (item, rowIndex) => {
        switch (item.key) {
            case 'display':
                return (
                    <select
                        className="form-control form-select"
                        onChange={({ target: { value } }) => setAttributeValue(value, rowIndex)}
                        value={item.value}
                    >
                        <option value="">
                            {translations.OUTCOME_attribute_value_placeholder_label}
                        </option>
                        <option value="icon">
                            {translations.OUTCOME_display_attribute_value_icon_label}
                        </option>
                        <option value="iconandtext">
                            {translations.OUTCOME_display_attribute_value_icon_and_text_label}
                        </option>
                        <option value="iconnobackground">
                            {translations.OUTCOME_display_attribute_value_icon_no_background_label}
                        </option>
                    </select>
                );
            case 'type':
                return (
                    <select
                        className="form-control form-select"
                        onChange={({ target: { value } }) => setAttributeValue(value, rowIndex)}
                        value={item.value}
                    >
                        <option value="">
                            {translations.OUTCOME_attribute_value_placeholder_label}
                        </option>
                        <option value="default">
                            {translations.OUTCOME_type_attribute_value_default_label}
                        </option>
                        <option value="primary">
                            {translations.OUTCOME_type_attribute_value_primary_label}
                        </option>
                        <option value="success">
                            {translations.OUTCOME_type_attribute_value_success_label}
                        </option>
                        <option value="info">
                            {translations.OUTCOME_type_attribute_value_info_label}
                        </option>
                        <option value="warning">
                            {translations.OUTCOME_type_attribute_value_warning_label}
                        </option>
                        <option value="danger">
                            {translations.OUTCOME_type_attribute_value_danger_label}
                        </option>
                    </select>
                );
            case 'target':
                return (
                    <select
                        className="form-control form-select"
                        onChange={({ target: { value } }) => setAttributeValue(value, rowIndex)}
                        value={item.value}
                    >
                        <option value="">
                            {translations.OUTCOME_attribute_value_placeholder_label}
                        </option>
                        <option value="_blank">
                            {translations.OUTCOME_target_attribute_value_blank_label}
                        </option>
                        <option value="_self">
                            {translations.OUTCOME_target_attribute_value_self_label}
                        </option>
                        <option value="_parent">
                            {translations.OUTCOME_target_attribute_value_parent_label}
                        </option>
                        <option value="_top">
                            {translations.OUTCOME_target_attribute_value_top_label}
                        </option>
                        <option value="framename">
                            {translations.OUTCOME_target_attribute_value_framename_label}
                        </option>
                    </select>
                );
            case 'icon':
                return (
                    <CreatableSelect
                        styles={getSharedStyles()}
                        inputId={`icon-select-${rowIndex}`}
                        value={{
                            label:
                                COMMON_ICON_ATTRIBUTE_OPTIONS.find(
                                    (att) => att.value === item.value,
                                )?.label ?? item?.value,
                            value: item?.value ?? '',
                        }}
                        onChange={(selection) => setAttributeValue(selection?.value, rowIndex)}
                        options={COMMON_ICON_ATTRIBUTE_OPTIONS.map(({ label, value }) => ({
                            label,
                            value,
                        }))}
                        menuPosition="fixed"
                        menuPortalTarget={menuPortalTarget}
                        closeMenuOnScroll={(e) => {
                            return e.target === document.getElementsByClassName('modal-body')[0];
                        }}
                        isClearable
                    />
                );
            case 'size':
                return (
                    <select
                        className="form-control form-select"
                        onChange={({ target: { value } }) => setAttributeValue(value, rowIndex)}
                        value={item.value}
                    >
                        <option value="">
                            {translations.OUTCOME_size_attribute_value_default_label}
                        </option>
                        <option value="lg">
                            {translations.OUTCOME_size_attribute_value_large_label}
                        </option>
                        <option value="sm">
                            {translations.OUTCOME_size_attribute_value_small_label}
                        </option>
                        <option value="xs">
                            {translations.OUTCOME_size_attribute_value_extra_small_label}
                        </option>
                    </select>
                );
            default:
                return (
                    <input
                        value={item.value}
                        onChange={({ target: { value } }) => setAttributeValue(value, rowIndex)}
                        className="form-control"
                        type="text"
                    />
                );
        }
    };

    const renderBody = () => (
        <>
            <NameInput
                isValid={isNameValid}
                showValidation={hasSubmitted}
                id="name-input"
                name={developerName}
                onUpdateName={setDeveloperName}
            />

            {isUIElement ? (
                <FormGroup
                    label={translations.OUTCOME_label_input_label}
                    isRequired
                    isValid={isLabelValid}
                    validationMessage={translations.OUTCOME_label_input_validation_message}
                    showValidation={hasSubmitted}
                    htmlFor="label"
                >
                    <input
                        id="label"
                        value={label}
                        onChange={({ target: { value } }) => setLabel(value)}
                        required
                        className="form-control form-control-width"
                        type="text"
                    />
                </FormGroup>
            ) : null}

            <FormGroup
                label={translations.OUTCOME_next_map_element_select_label}
                htmlFor="next-map-element"
            >
                {pageComponentsLoading ? (
                    <Loader />
                ) : (
                    <Select
                        inputId="next-map-element"
                        className="form-control-width"
                        styles={getSharedStyles()}
                        value={{
                            label: mapElements.find((m) => m.id === nextMapElementId)
                                ?.developerName,
                            value: nextMapElementId,
                        }}
                        onChange={(selection) => setNextMapElementId(selection?.value)}
                        options={mapElements.map(({ developerName, id }) => ({
                            label: developerName,
                            value: id,
                        }))}
                        isClearable
                    />
                )}
            </FormGroup>

            {isUIElement ? (
                <FormGroup
                    label={translations.OUTCOME_page_action_binding_select_label}
                    htmlFor="page-action-binding-type"
                    isRequired
                >
                    <Select
                        inputId="page-action-binding-type"
                        className="form-control-width"
                        styles={getSharedStyles()}
                        value={actionBindingTypeList.find((m) => m.value === pageActionBindingType)}
                        onChange={(selection) => setPageActionBindingType(selection?.value)}
                        options={actionBindingTypeList}
                    />
                </FormGroup>
            ) : null}

            {isOneOutcomeElement ? null : (
                <FormGroup
                    label={translations.OUTCOME_order_input_label}
                    htmlFor="order"
                    isRequired
                    isValid={isOrderValid}
                    validationMessage={translations.OUTCOME_order_input_validation_message}
                    showValidation={hasSubmitted}
                >
                    <input
                        value={order}
                        onChange={({ target: { value } }) => setOrder(value)}
                        required
                        className="form-control form-control-short"
                        type="number"
                    />
                </FormGroup>
            )}

            {[MAP_ELEMENT_TYPES.input, MAP_ELEMENT_TYPES.modal].includes(
                mapElement?.elementType.toLowerCase(),
            ) ? (
                <>
                    <FormGroup
                        label={translations.OUTCOME_page_object_binding_select_label}
                        htmlFor="pageObjectBindingId"
                    >
                        <Select
                            inputId="pageObjectBindingId"
                            className="form-control-width"
                            styles={getSharedStyles()}
                            value={pageComponentOptions.find(
                                (pageComp) => pageComp.value === pageObjectBindingId,
                            )}
                            onChange={onChangePlaceOutcome}
                            options={pageComponentOptions}
                            isClearable
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>
                            <Toggle
                                testId="isBulkAction"
                                isOn={isBulkAction}
                                onChange={({ isOn }) => setIsBulkAction(isOn)}
                            />
                            {translations.OUTCOME_is_bulk_action_toggle_label}
                        </label>
                    </FormGroup>
                </>
            ) : null}

            {isUIElement ? (
                <FormGroup
                    label={translations.OUTCOME_page_action_type_select_label}
                    htmlFor="page-action-type"
                >
                    <Select
                        inputId="page-action-type"
                        className="form-control-width"
                        styles={getSharedStyles()}
                        value={actionTypeList
                            .map((value) => ({
                                label: value,
                                value,
                            }))
                            .find((m) => m.value === pageActionType)}
                        onChange={(selection) => setPageActionType(selection?.value)}
                        options={actionTypeList.map((value) => ({
                            label: value,
                            value,
                        }))}
                        isClearable
                    />
                </FormGroup>
            ) : null}

            {hasBusinessRules ? (
                <BusinessRules
                    container={container}
                    comparison={comparison}
                    setComparison={setComparison}
                />
            ) : null}

            <div className="margin-bottom flex">
                <ButtonPrimary
                    title={translations.OUTCOME_add_attribute_button_label}
                    onClick={() =>
                        setSelectedAttributesList([
                            ...selectedAttributesList,
                            { key: '', value: '' },
                        ])
                    }
                >
                    <Glyphicon glyph="plus" />
                    {translations.OUTCOME_add_attribute_button_label}
                </ButtonPrimary>
            </div>
            <Table
                columns={[
                    {
                        renderHeader: () => translations.COMMON_TABLE_key,
                        renderCell: ({ item, rowIndex }) => (
                            <FormGroup
                                isValid={!isNullOrEmpty(item.key)}
                                showValidation={hasSubmitted}
                                validationMessage={
                                    translations.MAP_ELEMENT_attribute_key_required_validation_message
                                }
                            >
                                <label
                                    className="hidden"
                                    htmlFor={`attribute-key-${rowIndex}`}
                                >{`attribute-key-${rowIndex}`}</label>
                                <CreatableSelect
                                    styles={getSharedStyles()}
                                    inputId={`attribute-key-${rowIndex}`}
                                    placeholder={
                                        translations.MAP_ELEMENT_attribute_key_select_placeholder
                                    }
                                    value={
                                        attributesList.find((m) => m.value === item.key) || {
                                            label: item.key,
                                            value: item.key,
                                        }
                                    }
                                    onChange={(selection) =>
                                        setAttributeKey(selection ? selection.value : '', rowIndex)
                                    }
                                    options={attributesList.filter((attribute) => {
                                        return selectedAttributesList.every((filter) => {
                                            return filter.key !== attribute.value;
                                        });
                                    })}
                                    isClearable
                                    formatCreateLabel={(input) =>
                                        stringReplace(
                                            translations.MAP_ELEMENT_attribute_key_create_label_template,
                                            { input },
                                        )
                                    }
                                    menuPlacement="top"
                                />
                            </FormGroup>
                        ),
                        cellClassName: 'generic-cell-overflow',
                        size: '50%',
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_value,
                        renderCell: ({ item, rowIndex }) => getAttributeValueInput(item, rowIndex),
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_actions,
                        renderCell: ({ rowIndex }) => (
                            <div className="action-btn-wrapper">
                                <button
                                    title={translations.COMMON_delete}
                                    className="table-icon table-icon-delete"
                                    aria-label={translations.COMMON_delete}
                                    onClick={() => removeAttribute(rowIndex)}
                                    type="button"
                                >
                                    <Trash />
                                </button>
                            </div>
                        ),
                        size: '5rem',
                    },
                ]}
                items={selectedAttributesList}
                className="table"
            />

            {hasFaultOutcome ? (
                <FormGroup>
                    <ButtonPrimary onClick={makeFaultOutcome} testId="fault">
                        {translations.OUTCOME_use_outcome_for_faults}
                    </ButtonPrimary>
                </FormGroup>
            ) : null}
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onCancel} data-testid="cancel">
                {translations.COMMON_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={validateSave} data-testid="save">
                {isExistingOutcome ? translations.COMMON_update : translations.COMMON_add}
            </ButtonPrimary>
        </>
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default OutcomeDetails;

import type { Filter, ItemCollectionResponse } from '../types';
import { DIRECTION } from '../constants';
import { pathOr, not, inc, map, pipe, range } from 'ramda';
import type { PackageConflictResponse } from '../types/flow';
import type { ServiceValueRequestAPI, ConfigurationAndSelection } from '../types/service';
import { isNullOrEmpty } from './guard';

interface DrawApiRequestParams {
    url: string;
    method?: string;
    headers?: Record<string, string>;
    body?: unknown;
}

export const handleConflict = async (response: Response) => {
    if (response.status !== 409) {
        return response;
    }

    const conflictingDependents = (await response.json()) as PackageConflictResponse;

    throw { wasConflict: true, conflictingDependents };
};

export const parse = (response: Response) => {
    if (response.status === 204) {
        return Promise.resolve(response);
    }

    if (!response.headers.has('Content-Type')) {
        console.warn('No Content-Type header was found on the response');
        return response.json();
    }

    const contentType = response.headers.get('Content-Type');

    if (contentType?.includes('application/json')) {
        return response.json();
    }

    if (contentType?.includes('text/html')) {
        return response.text();
    }

    console.warn('Unexpected content type encountered', contentType);
    return response.text();
};

const parseRequestBody = (body?: unknown) => {
    if (body === null || body === undefined) {
        return null;
    }

    if (typeof body === 'string') {
        return body;
    }

    return JSON.stringify(body);
};

/**
 * @function fetchAllPaged
 * @description Fetch all items from a paged endpoint in defined page sized chunks
 * @param source Source function that performs the ajax requests
 * @param pageSize Page size of each individual request
 * @export
 * @async
 */
export const fetchAllPaged = async <TItem>(
    source: (page: number, pageSize: number) => Promise<ItemCollectionResponse<TItem>>,
    pageSize: number,
): Promise<TItem[]> => {
    const {
        items,
        _meta: { total },
    } = await source(1, pageSize);

    const totalNumberOfPages = Math.ceil(total / pageSize);

    if (totalNumberOfPages <= 1) {
        return items;
    }

    const allOtherPageResponses = await pipe(
        inc, // add one page to total to get correct range
        range(2), // get range of page numbers starting from second page
        map((pageNumber) => source(pageNumber, pageSize)),
        (requests) => Promise.all(requests),
    )(totalNumberOfPages);

    // Concatenate and return all items from all responses
    return allOtherPageResponses.reduce((accumulation, { items: pageOfItems }) => {
        return accumulation.concat(pageOfItems);
    }, items);
};

/**
 * @function fetchAndParse
 * @description Wraps the native fetch function with body stringifying and response checking and parsing
 */
export const fetchAndParse = <T>({
    url,
    method = 'get',
    headers = {},
    body,
}: DrawApiRequestParams): Promise<T> => {
    const requestBody = method.toLowerCase() !== 'get' ? parseRequestBody(body) : null;

    // Ensure we don't send duplicated 'Content-type' header
    delete headers['Content-type'];

    const request: RequestInit = { method, headers };

    if (requestBody !== null) {
        request.body = requestBody;
        headers['content-type'] = 'application/json';
    }

    return fetch(url, request).then(CheckStatus).then(parse) as Promise<T>;
};

export const filterToQuery = (filter: Filter) => {
    const params = new URLSearchParams();

    const { page, limit, search, exactSearch, orderBy, orderDirection } = filter;

    Object.entries(filter).forEach(([key, value]) => {
        if (key === 'page') {
            params.append(key, (page ?? 1).toString());
            return;
        }

        if (key === 'limit') {
            params.append(key, (limit ?? 1000).toString());
            return;
        }

        if (key === 'orderBy') {
            params.append(key, orderBy ?? 'dateModified');
            return;
        }

        if (key === 'orderDirection') {
            params.append(key, orderDirection ?? DIRECTION.desc);
            return;
        }

        if (key === 'search' && typeof search === 'string') {
            const trimmedSearch = search.trim();

            if (trimmedSearch.length === 0) {
                return;
            }

            params.append('filter', `substringof(developerName, '${trimmedSearch}')`);

            return;
        }

        if (key === 'exactSearch' && typeof exactSearch === 'string') {
            const trimmedExactSearch = exactSearch.trim();

            if (trimmedExactSearch.length === 0) {
                return;
            }

            params.append('filter', `developerName eq '${trimmedExactSearch}'`);

            return;
        }

        if (Array.isArray(value)) {
            value.forEach((item) => params.append(key, item as string));
            return;
        }

        if (typeof value === 'object') {
            Object.entries(value as Record<string, string | number>).forEach(
                ([nestedKey, nestedValue]) => {
                    params.append(`${key}.${nestedKey}`, nestedValue.toString().trim());
                },
            );
            return;
        }

        if (value === null) {
            params.append(key, 'null');
        }

        if (value !== undefined) {
            params.append(key, (value as string).toString());
        }
    });

    const query = params ? `?${params.toString()}` : '';
    return query;
};

export const uploadFile = ({
    file,
    tenantId,
    url,
    progressHandler,
    completeHandler,
    errorHandler,
    sendAsJSON = false,
}: {
    file: File;
    tenantId: string;
    url: string;
    progressHandler?:
        | ((this: XMLHttpRequestUpload, ev: ProgressEvent<XMLHttpRequestEventTarget>) => unknown)
        | undefined;
    completeHandler?:
        | ((this: XMLHttpRequest, ev: ProgressEvent<XMLHttpRequestEventTarget>) => unknown)
        | undefined;
    errorHandler?:
        | ((this: XMLHttpRequest, ev: ProgressEvent<XMLHttpRequestEventTarget> | Event) => unknown)
        | undefined;
    sendAsJSON: boolean;
}) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = (ev) => {
        /**
         * If the server returns an error after the file has been uploaded
         * it is not caught in the error progress event so we need to check
         * the status when done.
         * */

        if (xhr.readyState === XMLHttpRequest.DONE) {
            const { status } = xhr;
            if (status < 200 || status >= 400) {
                errorHandler?.call(xhr, ev);
            }
        }
    };

    if (progressHandler) {
        xhr.upload.addEventListener('progress', progressHandler, false);
    }
    if (completeHandler) {
        xhr.addEventListener('load', completeHandler, false);
    }
    if (errorHandler) {
        xhr.addEventListener('error', errorHandler, false);
    }

    xhr.open('POST', url);
    xhr.setRequestHeader('ManyWhoTenant', tenantId);

    if (sendAsJSON) {
        const reader = new FileReader();
        reader.onload = (event) => {
            xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
            xhr.send(event.target?.result);
        };
        reader.readAsText(file);
    } else {
        const formData = new FormData();
        formData.append('file', file);
        xhr.send(formData);
    }

    return xhr;
};

export const downloadFile = async (
    url: string,
    fileName: string,
    tenantId: string,
    contentType = 'application/json',
) => {
    const response: Response = await fetch(url, {
        headers: {
            'Content-Type': contentType,
            ManyWhoTenant: tenantId,
        },
    });

    const resp = await CheckStatus(response);
    const blob = window.URL.createObjectURL(await resp.blob());
    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = blob;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    window.URL.revokeObjectURL(blob);
    anchor.parentElement?.removeChild(anchor);
};

export const CheckStatus = (response: Response) => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    if (response.status === 401) {
        throw new Error('Not authenticated.');
    }

    if (response.status === 403) {
        throw new Error('Not authorized.');
    }

    return response.text().then((message) => {
        let parsedMessage: unknown;
        let error: Error | undefined;

        try {
            parsedMessage = JSON.parse(message);
        } catch {
            // Ignore parsing errors, parsedMessage will be null
        } finally {
            error = new Error(
                // Use a parsed message
                // Note: Response from a service call will contain an object
                // with a message property... so we should check for this
                (not(isNullOrEmpty(parsedMessage))
                    ? pathOr(parsedMessage, ['message'], parsedMessage)
                    : // Fallback to a raw message
                      not(isNullOrEmpty(message))
                      ? message
                      : // If nothing else, print the status code and url
                        `${response.status} - ${decodeURI(response.url)}`) as string,
            );
        }

        throw error;
    });
};

/**
 * @function populateServiceValueRequest
 * @description Returns a ServiceValueRequest with each ValueElementIdReference populated with the given ids.
 * Converts draw/2/refreshService output data (which lists service configuration values available)
 * with a set of value ids for those configuration Values
 * into a form that draw/2/gettypesandactions accepts
 * @param serviceOutput draw/2/refreshService output data (which lists service configuration values available)
 * @param valueIdObject ids for Values that exist in the Tenant that are to be used for each configuration value in the service
 * @returns service configuration information, with valueElementIdReferences populated for use with draw/2/refreshService
 * @export
 */
export const populateServiceValueRequest = (
    serviceOutput: ServiceValueRequestAPI[],
    valueIdObject: ConfigurationAndSelection[],
) =>
    serviceOutput.map((serviceValueRequest) => {
        const name = serviceValueRequest.developerName;

        const meta = valueIdObject.find((value) => value.name === name)?.value;

        if (!meta) {
            return serviceValueRequest;
        }

        // There's an ID, so create a ValueElementIdReference for the Value
        serviceValueRequest.valueElementToReferenceId = {
            id: meta.id,
            typeElementPropertyId: meta.typeElementPropertyId,
            command: null,
        };

        return serviceValueRequest;
    });

import '../../../../../css/flow/authorization-config.less';
import '../../../../../css/flow/editor.less';
import '../../../../../css/flow/graph.less';
import '../../../../../css/graph/map-element.less';
import '../../../../../css/modal.less';

import {
    type KeyboardEvent,
    type MouseEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import type {
    AddNotification,
    ChartObjectType,
    Dimensions,
    NotifyError,
    NotifySuccess,
} from '../../../types';
import classNames from 'classnames';
import {
    fetchGraph,
    onSVGResizeAction,
    onWheelAction,
    onMouseMoveAction,
    onMouseUpAction,
    onMouseDownAction,
    onAddElementAction,
    onCloseConfigAction,
    onDeleteAction,
    onConfirmDeleteAction,
    onMouseUpOutsideGraphAction,
    onDoubleClickAction,
} from '../state/reducer';
import type { AddMapElementPayload, FlowEditorState, ChartAction } from '../state/types';

import { useSelector } from 'react-redux';
import type { AnyAction, Dispatch } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';
import { calculateSVGPositionFromXY } from '../utils/position';
import { saveFlowGraph2 } from '../../../sources/graph';
import Flowchart from '../../flowchart/Flowchart';
import CanvasPalette from './CanvasPalette';
import GraphConfigEditor from './GraphConfigEditor';
import FlowActionMenu from './FlowActionMenu';
import NewFlowModal from './NewFlowModal';
import type { NavigateFunction } from 'react-router-dom';
import History from '../../flow/history/History';
import type { FlowEditorSharedData } from './FlowEditorSharedWrapper';

interface Props extends FlowEditorSharedData {
    flowId: string;
    isNew: boolean;
    tabKey: string;
    setTabTitle: (payload: { title: string; key: string }) => void;
    isActive: boolean;
    notifyError: NotifyError;
    notifySuccess: NotifySuccess;
    addNotification: AddNotification;
    dispatch: ThunkDispatch<FlowEditorState, undefined, AnyAction> & Dispatch<AnyAction>;
    updateUrlAndTab: (
        {
            key,
            type,
            title,
            elementId,
            tenantId,
        }: {
            key: string;
            type: string;
            title: string | null;
            elementId: string;
            tenantId: string;
        },
        navigate: NavigateFunction,
    ) => void;
    closeTab: (key: string, tenantId: string, navigate: NavigateFunction) => void;
}

const FlowEditor = ({
    flowId,
    tabKey,
    isActive,
    setTabTitle: setTabTitleAction,
    isNew,
    notifyError,
    notifySuccess,
    addNotification,
    dispatch,
    updateUrlAndTab,
    closeTab,
    releases,
    environments,
    loadReleases,
    isLoadingReleases,
}: Props) => {
    const state = useSelector<FlowEditorState>((state) => state) as FlowEditorState;
    const [showHistory, setShowHistory] = useState(false);
    const [isFlowHistoryModalOpen, setIsFlowHistoryModalOpen] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const SVGRef = useRef<SVGSVGElement>(null);

    const containerClasses = classNames({
        'flow-editor': true,
        admin: true,
        // for GRAPH3 feature flag
        'graph-editor': true,
        active: isActive,
    });

    const setTabTitle = (title: string) =>
        setTabTitleAction({
            title,
            key: tabKey,
        });

    const getAction = (event: MouseEvent) => {
        let action: ChartAction | null = null;

        const closestAction: SVGElement | null = (event.target as SVGElement).closest(
            '[data-action]',
        );

        if (closestAction) {
            action = closestAction.dataset['action'] as ChartAction;
        }

        return action;
    };

    const getTargetObject = (event: MouseEvent) => {
        let id: string | null = null;
        let objectType: ChartObjectType | null = null;

        const closestNode: SVGElement | null = (event.target as SVGElement).closest(
            '[data-object=leafNode]',
        );
        if (closestNode) {
            id = closestNode.dataset['id'] as string;
            objectType = 'leafNode';
        }

        const closestGroupNode: SVGElement | null = (event.target as SVGElement).closest(
            '[data-object=groupNode]',
        );
        if (closestGroupNode) {
            id = closestGroupNode.dataset['id'] as string;
            objectType = 'groupNode';
        }

        const closestEdge: SVGElement | null = (event.target as SVGElement).closest(
            '[data-object=edge]',
        );
        if (closestEdge) {
            id = closestEdge.dataset['id'] as string;
            objectType = 'edge';
        }

        const isSelectionBox: SVGElement | null = (event.target as SVGElement).closest(
            '[data-object=selectionBox]',
        );
        if (isSelectionBox) {
            id = 'selectionBox';
            objectType = 'selectionBox';
        }

        const isPort: SVGElement | null = (event.target as SVGElement).closest(
            '[data-object=port]',
        );
        if (isPort) {
            // id comes from closestNode
            objectType = 'port';
        }

        if (objectType === null) {
            objectType = 'viewBox';
        }

        return { id, objectType };
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const onMouseDown = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (SVGRef.current === null) {
                return;
            }

            const SVGPoint = calculateSVGPositionFromXY(SVGRef.current, {
                x: event.clientX,
                y: event.clientY,
            });

            const targetObject = getTargetObject(event);

            dispatch(
                onMouseDownAction({
                    id: targetObject.id,
                    objectType: targetObject.objectType,
                    clientPosition: {
                        x: event.clientX,
                        y: event.clientY,
                    },
                    svgPosition: SVGPoint,
                    ctrlKey: event.ctrlKey,
                }),
            );
        },
        [dispatch],
    );

    const hasRenderedSinceLastMouseMove = useRef(true);
    hasRenderedSinceLastMouseMove.current = true;

    const onMouseMove = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (SVGRef.current === null) {
                return;
            }

            if (!hasRenderedSinceLastMouseMove.current) {
                // Stop any further events until another render has occurred.
                // This prevents new viewbox coord calculations being based on
                // the coords of the SVG element that hasn't yet been updated
                // from a previous viewbox coord calculation.
                return;
            }

            const SVGPoint = calculateSVGPositionFromXY(SVGRef.current, {
                x: event.clientX,
                y: event.clientY,
            });

            dispatch(
                onMouseMoveAction({
                    id: null,
                    objectType: null,
                    clientPosition: {
                        x: event.clientX,
                        y: event.clientY,
                    },
                    svgPosition: SVGPoint,
                    ctrlKey: false,
                }),
            );

            hasRenderedSinceLastMouseMove.current = false;
        },
        [dispatch],
    );

    const onConfirmDelete = async (objectId: string) => {
        await dispatch(onConfirmDeleteAction(objectId));
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const onClick = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (SVGRef.current === null) {
                return;
            }

            const targetObject = getTargetObject(event);
            const action = getAction(event);

            if (action === 'delete' && targetObject.objectType !== null) {
                dispatch(onDeleteAction(false));
            }
        },
        [dispatch],
    );

    const onDoubleClick = useCallback(() => {
        dispatch(onDoubleClickAction());
    }, [dispatch]);

    const onMouseUp = useCallback(
        async (event: MouseEvent<HTMLDivElement>) => {
            if (SVGRef.current === null) {
                return;
            }

            const SVGPoint = calculateSVGPositionFromXY(SVGRef.current, {
                x: event.clientX,
                y: event.clientY,
            });

            await dispatch(
                onMouseUpAction({
                    id: null,
                    objectType: null,
                    clientPosition: {
                        x: event.clientX,
                        y: event.clientY,
                    },
                    svgPosition: SVGPoint,
                    ctrlKey: false,
                }),
            );
        },
        [dispatch],
    );

    const onSVGResize = useCallback(
        (dimensions: Dimensions) => {
            dispatch(onSVGResizeAction(dimensions));
        },
        [dispatch],
    );

    const onKeyUp = useCallback(
        (event: KeyboardEvent<HTMLDivElement>) => {
            switch (event.key) {
                case 'Delete': {
                    dispatch(onDeleteAction(true));
                    break;
                }
            }
        },
        [dispatch],
    );

    const onSidebarElementMouseDown = ({ x, y, elementType }: AddMapElementPayload) => {
        if (SVGRef.current === null) {
            return;
        }

        const SVGPoint = calculateSVGPositionFromXY(SVGRef.current, {
            x,
            y,
        });

        dispatch(onAddElementAction({ x: SVGPoint.x, y: SVGPoint.y, elementType }));
    };

    const setIsLoading = () => {
        // TODO: implement
        console.warn('setIsLoading has not been implemented');
    };

    const focusAndSelectElement = () => {
        // TODO: implement
        console.warn('focusAndSelectElement has not been implemented');
    };

    const refreshFlow = () => {
        dispatch(fetchGraph(flowId));
    };

    const dismissMapElementConfig = () => {
        dispatch(onCloseConfigAction());
        if (SVGRef.current !== null) {
            SVGRef.current.focus();
        }
    };

    // Fetch graph data
    useEffect(() => {
        dispatch(fetchGraph(flowId));
    }, [dispatch, flowId]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (state.flowId !== null) {
            // New elements do not yet have an Id
            const updatedMapElements = state.mapElements.filter(
                ({ id }) => !id || state.selectedObjectIds.includes(id),
            );
            const updatedGroupElements = state.groupElements.filter(
                ({ id }) => !id || state.selectedObjectIds.includes(id),
            );
            if (updatedGroupElements.length > 0 || updatedMapElements.length > 0) {
                saveFlowGraph2({
                    id: state.flowId,
                    mapElements: updatedMapElements,
                    groupElements: updatedGroupElements,
                }).catch(notifyError);
            }
        }
    }, [state.mapElements, state.groupElements, dispatch, state.flowId, notifyError]);

    // Mouse wheel event listener
    useEffect(() => {
        // We have to register this event manually with addEventListener
        // because React doesn't have a way of setting listener options (passive).
        SVGRef.current?.addEventListener(
            'wheel',
            (event) => {
                if (SVGRef.current) {
                    event.preventDefault();
                    const SVGPoint = calculateSVGPositionFromXY(SVGRef.current, {
                        x: event.clientX,
                        y: event.clientY,
                    });
                    dispatch(onWheelAction({ amount: -event.deltaY, SVGPoint }));
                }
            },
            { passive: false },
        );
    }, [dispatch]);

    // Handle cancel node dragging outside of graph
    useEffect(() => {
        const onWindowMouseUp = (event: Event) => {
            if (!(event.target as HTMLElement).closest('.graph-canvas')) {
                dispatch(onMouseUpOutsideGraphAction());
            }
        };
        window.addEventListener('mouseup', onWindowMouseUp);

        return () => window.removeEventListener('mouseup', onWindowMouseUp);
    }, [dispatch]);

    // Resize observer
    useEffect(() => {
        let observer: ResizeObserver | null = null;

        if (containerRef.current) {
            observer = new ResizeObserver((entries) =>
                entries.forEach(
                    // Update the viewBox with new information about available space
                    (entry) => {
                        onSVGResize({
                            height: entry.contentRect.height,
                            width: entry.contentRect.width,
                        });
                    },
                ),
            );

            observer.observe(containerRef.current);
        }

        return () => {
            if (observer !== null) {
                observer.disconnect();
            }
        };
    }, [onSVGResize]);

    return (
        <div id={`flow-${flowId}`} className={containerClasses}>
            {state.openEditorName !== null &&
                containerRef.current !== null &&
                state.focusedObjectId && (
                    <GraphConfigEditor
                        flowId={flowId}
                        focusedObjectId={state.focusedObjectId}
                        selectedObjectIds={state.selectedObjectIds}
                        editorType={state.openEditorName}
                        container={containerRef.current}
                        mapElements={state.mapElements}
                        groupElements={state.groupElements}
                        leafNodes={state.flowchart.leafNodes}
                        groupNodes={state.flowchart.groupNodes}
                        edges={state.flowchart.edges}
                        setIsLoading={setIsLoading}
                        refreshFlow={refreshFlow}
                        dismissMapElementConfig={dismissMapElementConfig}
                        focusAndSelectElement={focusAndSelectElement}
                        notifyError={notifyError}
                        onConfirmDelete={onConfirmDelete}
                    />
                )}
            {isNew && containerRef.current !== null && (
                <NewFlowModal
                    container={containerRef.current}
                    tabKey={tabKey}
                    updateUrlAndTab={updateUrlAndTab}
                    closeTab={closeTab}
                    notifyError={notifyError}
                />
            )}
            <div ref={containerRef} className="graph-canvas-wrapper">
                <div className="graph-canvas-overlay">
                    <div className="graph-canvas-overlay-left">
                        <CanvasPalette onElementMouseDown={onSidebarElementMouseDown} />
                    </div>
                    <div className="graph-canvas-overlay-right">
                        {containerRef.current !== null && (
                            <FlowActionMenu
                                flowId={flowId}
                                isActive={isActive}
                                setTabTitle={setTabTitle}
                                modalContainer={containerRef.current}
                                notifyError={notifyError}
                                notifySuccess={notifySuccess}
                                addNotification={addNotification}
                                mapElements={state.mapElements}
                                showHistory={showHistory}
                                setShowHistory={(state: boolean) => {
                                    loadReleases();
                                    setShowHistory(state);
                                }}
                                loadReleases={loadReleases}
                            />
                        )}
                        {/* <GraphActionMenu
                        flowId={flowId}
                        graphElement={this.graphElement.current}
                        zoom={this.zoomByAmount}
                        zoomViewBox={this.zoomViewBox}
                        isActive={this.props.isActive}
                        /> */}
                    </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions -- Captures bubbled events from the graph, cannot itself be interacted with */}
                <div
                    onContextMenu={(event) => event.preventDefault()}
                    onMouseDown={onMouseDown}
                    onMouseMove={onMouseMove}
                    onMouseUp={onMouseUp}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                    onKeyUp={onKeyUp}
                    className={state.dragSnapshot !== null ? 'is-dragging' : undefined}
                >
                    <Flowchart {...state.flowchart} ref={SVGRef} />
                </div>
            </div>
            {showHistory && (
                <History
                    flowId={flowId}
                    container={containerRef?.current}
                    notifyError={notifyError}
                    refreshFlow={refreshFlow}
                    isFlowHistoryModalOpen={isFlowHistoryModalOpen}
                    setIsFlowHistoryModalOpen={setIsFlowHistoryModalOpen}
                    releases={releases}
                    environments={environments}
                    isLoadingReleases={isLoadingReleases}
                />
            )}
        </div>
    );
};

export default FlowEditor;

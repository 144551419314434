import OutcomeDetails from '../common/outcomes/OutcomeDetails';
import { isNullOrEmpty } from '../../../../../ts/utils/guard';
import { getByID } from '../../../../../ts/utils/collection';
import { useMapElement } from '../contextProviders/MapElementProvider';

/**
 * Handles switching between the outcome configuration screens
 */
const OutcomePanel = ({ outcomeId, nextMapElementId }) => {
    const { mapElement, onClose, onSaveMapElement } = useMapElement();

    if (isNullOrEmpty(mapElement)) {
        return null;
    }

    return (
        <OutcomeDetails
            outcome={getByID(outcomeId, mapElement.outcomes || []) || { nextMapElementId }}
            onSave={onSaveMapElement}
            onCancel={onClose}
        />
    );
};

export default OutcomePanel;

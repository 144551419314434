import { connect, type ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleSidebarCollapsed } from '../../js/actions/reduxActions/sidebar';
import Sidebar from './Sidebar';
import { groups } from '../config/admin';
import { HOSTS, TAB_TYPES } from '../constants';
import type { Tab, TabConfiguration, TabType } from '../types';
import { getFlag } from '../utils/flags';
import { isNullOrEmpty } from '../utils/guard';
import { generateRouteUrl, generateTabKey } from '../utils/routing';
import { useAuth } from './AuthProvider';
import ComponentWithTooltip from './generic/tooltip/ComponentWithTooltip';
import TooltipWithHeader from './generic/tooltip/TooltipWithHeader';
import classNames from 'classnames';

const mapStateToProps = ({ tabs }: { tabs: Tab[] }) => ({ tabs });

const mapDispatchToProps = {
    toggleCollapsed: toggleSidebarCollapsed,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AdminSidebarProps = {
    toggleCollapsed: () => void;
} & ConnectedProps<typeof connector>;

const AdminSidebar = ({ toggleCollapsed, tabs }: AdminSidebarProps) => {
    const { tenant } = useAuth();

    const navigate = useNavigate();

    const tenantId = tenant?.id;

    if (!tenantId) {
        return null;
    }

    const isFederal = window.location.hostname === HOSTS.federal;

    const openAdminTab = (tabType: TabType) => {
        const route = generateRouteUrl({
            tabType,
            tenantId,
            options: {
                tabKey: generateTabKey(),
            },
        });
        navigate(route);
    };

    const filterNavigationItems = (item: TabConfiguration) => {
        if (item.type === TAB_TYPES.themes && !tenant?.tenantSettings?.themes) {
            return false;
        }

        if (item.type === TAB_TYPES.players && (tenant?.tenantSettings?.themes ?? false)) {
            return false;
        }

        if (item.type === TAB_TYPES.anomalyDetection) {
            // Unified dashboards contains the Anomaly detection dashboard
            return getFlag('ANOMLY') && !getFlag('UNIDSH');
        }

        if (item.type === TAB_TYPES.assets && isFederal) {
            return false;
        }
        return true;
    };

    const isItemActive = (item: TabConfiguration) => {
        const activeTab = tabs.find((tab) => tab.isActive);

        if (isNullOrEmpty(activeTab)) {
            return false;
        }

        if (activeTab.type === TAB_TYPES.flow && item.type === 'flows') {
            return true;
        }

        if (
            (activeTab.type === TAB_TYPES.page || activeTab.type === TAB_TYPES.newPage) &&
            item.type === 'pages'
        ) {
            return true;
        }

        return activeTab.type === item.type;
    };

    return (
        <Sidebar className="sidebar-admin" onCollapse={toggleCollapsed}>
            {groups.map((group) =>
                [
                    <div className="heading" key={group.name}>
                        {group.label}
                    </div>,
                ].concat(
                    group.items.filter(filterNavigationItems).map((item) => {
                        const classes = classNames('sidebar-btn', {
                            active: isItemActive(item),
                        });

                        const element = (
                            <button
                                key={item.type}
                                className={classes}
                                onClick={() => openAdminTab(item.type)}
                                type="button"
                            >
                                <div className="glyphicon">
                                    <item.icon width="20" height="20" />
                                </div>
                                <div className="sidebar-item-text">{item.title}</div>
                            </button>
                        );

                        return item.description ? (
                            <ComponentWithTooltip
                                key={`tooltip-${item.type}`}
                                trigger={['hover', 'focus']}
                                tooltipPlacement="right"
                                fadeTime={0.1}
                                tooltipContent={
                                    <TooltipWithHeader
                                        header={item.title}
                                        content={item.description}
                                    />
                                }
                                wrapperClass="full-width"
                                tooltipClass="graph-tooltip"
                                tooltipArrowClass="graph-tooltip-arrow"
                                clearance={10}
                                arrowClearance={7}
                            >
                                {element}
                            </ComponentWithTooltip>
                        ) : (
                            element
                        );
                    }),
                ),
            )}
        </Sidebar>
    );
};

export default connector(AdminSidebar);

import { useEffect, useState } from 'react';
import { getNavigationElements, createOrUpdateNavigationElements } from '../../../ts/sources/flow';
import NavigationItem from './navigation-item';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import { guid } from '../../../ts/utils/guid';
import FormGroup from '../../../ts/components/generic/FormGroup';
import Loader from '../../../ts/components/loader/Loader';
import Modal from '../../../ts/components/generic/modal/GenericModal';
import Glyphicon from '../../../ts/components/generic/Glyphicon';
import ButtonDanger from '../../../ts/components/buttons/ButtonDanger';
import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../ts/components/buttons/ButtonPrimary';
import translations from '../../../ts/translations';
import Footer from './elementConfigurations/common/Footer';
import { RUNTIME_LABELS } from '../../../ts/components/page-editor/constants';
import NavigationUrlInputs from '../../../ts/components/flow/NavigationUrlInputs';
import { filterOutNotes } from '../graph/utils';
import { NAVIGATION_POSITION, URL_TARGET } from '../../../ts/components/flow/constants';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Select from 'react-select';
import { getSharedStyles } from '../../../ts/utils/select';
import { safeToLower } from '../../../ts/utils';

const NavigationEditor = ({
    navigationElementId,
    tenantId,
    editingToken,
    flowId,
    addNotification,
    close,
    closeModal,
    done,
    container,
    mapElements,
}) => {
    const [navigationElement, setNavigationElement] = useState({
        id: null,
        locationMapElementId: null,
        developerName: '',
        label: '',
        navigationItems: [],
        order: 0,
        persistState: true,
        persistValues: true,
        elementType: 'NAVIGATION',
    });

    const [editing, setEditing] = useState(null);
    const [editingParent, setEditingParent] = useState(null);
    const [name, setName] = useState(null);
    const [label, setLabel] = useState(null);
    const [mapElement, setMapElement] = useState(null);
    const [isNewItem, setIsNewItem] = useState(false);
    const [isUrl, setIsUrl] = useState(false);
    const [url, setUrl] = useState(null);
    const [urlTarget, setUrlTarget] = useState(null);

    const mapElementsSorted = filterOutNotes(mapElements ?? []).sort((a, b) => {
        a.developerName.localeCompare(b.developerName);
    });

    const [errors, setErrors] = useState({
        name: false,
        label: false,
        mapElement: false,
    });

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        (async () => {
            if (isNullOrEmpty(navigationElementId)) {
                setIsLoading(false);
            } else {
                try {
                    const response = await getNavigationElements({
                        tenantId: tenantId,
                        editingToken: editingToken,
                        flowId: flowId,
                        navigationElementId: navigationElementId,
                    });

                    setNavigationElement(response);
                    setIsLoading(false);
                } catch ({ message }) {
                    addNotification({
                        type: 'error',
                        message,
                        isPersistent: true,
                    });

                    close();
                }
            }
        })();
    }, []);

    const stopEditingItem = () => {
        setEditing(null);
        setName(null);
        setLabel(null);
        setMapElement(null);
        setIsNewItem(false);
        setErrors({});
        setIsUrl(false);
        setUrl(null);
    };

    const findItem = (itemId, items) => {
        if (items) {
            for (const item of items) {
                if (item.id === itemId) {
                    return item;
                }
                const foundItem = findItem(itemId, item.navigationItems);
                if (foundItem) {
                    return foundItem;
                }
            }
        }
    };

    const addItem = (id, e) => {
        e.preventDefault();
        e.stopPropagation();

        setEditing(guid());
        setEditingParent(id);
        setName('');
        setLabel('');
        setMapElement('');
        setIsUrl(false);
        setUrl(null);
        setIsNewItem(true);
        setUrlTarget(URL_TARGET.BLANK);
    };

    const updateItem = (items, itemId, name, label, mapElement, url, urlTarget) => {
        if (items) {
            return items.map((item) => {
                if (item.id === itemId) {
                    item.developerName = name ?? label;
                    item.label = label;
                    item.locationMapElementId = mapElement;
                    item.url = url;
                    item.urlTarget = urlTarget;

                    setNavigationElement({ ...navigationElement, navigationItems: items });
                }

                const navItems = item.navigationItems;

                updateItem(navItems, itemId, name, label, mapElement, url, urlTarget);
                return item;
            });
        }
    };

    const deleteItem = (items, itemId) => {
        if (items) {
            let foundItem = false;
            const newItems = [];

            for (const item of items) {
                item.navigationItems = deleteItem(item.navigationItems, itemId);

                if (foundItem) {
                    item.order--;
                }

                if (item.id === itemId) {
                    foundItem = true;
                } else {
                    newItems.push(item);
                }
            }

            return newItems;
        }
    };

    const moveItem = (parent, id, order) => {
        let items = parent ? parent.navigationItems : navigationElement.navigationItems;
        const item = findItem(id, items);
        const oldOrder = item.order;

        items = orderItems(items, Number.parseInt(oldOrder), Number.parseInt(order));
        item.order = order;

        if (parent) {
            items = navigationElement.navigationItems;
        }

        setNavigationElement({ ...navigationElement, navigationItems: items });
    };

    const orderItems = (items, oldIndex, newIndex) => {
        const delta = Math.abs(newIndex - oldIndex);
        return items.map((item) => {
            const order = Number.parseInt(item.order);

            if (newIndex > oldIndex) {
                if (order <= newIndex && order > newIndex - delta) {
                    item.order--;
                }
            } else if (order < oldIndex && order >= oldIndex - delta) {
                item.order++;
            }
            return item;
        });
    };

    const onItemClick = (e) => {
        e.nativeEvent.preventDefault();
        e.stopPropagation();

        const itemId = e.currentTarget.id || null;
        const item = findItem(itemId, navigationElement.navigationItems);

        setEditing(itemId);
        setName(item.developerName);
        setLabel(item.label);
        setMapElement(item.locationMapElementId);
        setIsNewItem(false);
        setErrors({});
        if (item.url) {
            setIsUrl(true);
            setUrl(item.url);
            setUrlTarget(item.urlTarget);
        } else {
            setIsUrl(false);
        }
    };

    const onDeleteClick = () => {
        const items = deleteItem(navigationElement.navigationItems, editing);

        stopEditingItem();
        setNavigationElement({ ...navigationElement, navigationItems: items });
    };

    const updateMapElement = (option) => {
        const errorsCopy = errors;
        delete errorsCopy.mapElement;

        // labels that are empty or untouched by the user will be changed
        const shouldChangeLabel =
            isNullOrEmpty(label) || label === name || (isUrl && label === 'URL');

        if (option.value === 'URL') {
            setIsUrl(true);
            setErrors(errorsCopy);
            setLabel(shouldChangeLabel ? 'URL' : label);
            return;
        }
        setIsUrl(false);
        setUrl(null);

        const selectedMapElement = mapElements.find((item) => item.id === option.value);
        setLabel(shouldChangeLabel ? selectedMapElement.developerName : label);
        setName(selectedMapElement.developerName);
        setMapElement(selectedMapElement.id);
        setErrors(errorsCopy);
    };

    const updateLabel = (e) => {
        e.preventDefault();

        const errorsCopy = errors;
        delete errorsCopy.label;

        setLabel(e.currentTarget.value);
        setErrors(errorsCopy);
    };

    const onUrlChanged = (value) => {
        const errorsCopy = errors;
        delete errorsCopy.url;

        setUrl(value);
        setErrors(errorsCopy);
    };

    const updateUrlTarget = (e) => {
        e.preventDefault();

        setUrlTarget(e.currentTarget.value);
    };

    const cancelEdit = () => {
        stopEditingItem();
    };

    const saveNavigationItem = (e) => {
        e.preventDefault();

        const errors = {};

        if (isNullOrEmpty(label)) {
            errors.label = true;
        }

        if (isNullOrEmpty(mapElement) && !isUrl) {
            errors.mapElement = true;
        }

        if (isUrl && isNullOrEmpty(url)) {
            errors.url = true;
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }

        const navigationItems = navigationElement?.navigationItems ?? [];
        const mapElementCopy = mapElements.find((item) => item.id === mapElement);

        if (isNewItem) {
            const newNavItem = {
                id: editing,
                locationMapElementId: mapElement,
                developerName: isUrl ? label : mapElementCopy?.developerName,
                label: label,
                navigationItems: null,
                url: isUrl ? url : null,
                urlTarget: isUrl ? urlTarget ?? URL_TARGET.BLANK : null,
            };

            if (editingParent !== '') {
                const item = findItem(editingParent, navigationItems);

                if (isNullOrEmpty(item.navigationItems)) {
                    item.navigationItems = [];
                }

                newNavItem.order = item.navigationItems.length;
                item.navigationItems.push(newNavItem);
            } else {
                newNavItem.order = navigationItems.length;
                navigationItems.push(newNavItem);
            }

            setNavigationElement({ ...navigationElement, navigationItems });
        } else {
            const updatedItems = updateItem(
                navigationItems,
                editing,
                name,
                label,
                mapElement,
                url,
                urlTarget,
            );

            setNavigationElement({ ...navigationElement, navigationItems: updatedItems });
        }

        stopEditingItem();
    };

    const saveNavigationElement = async () => {
        setHasSubmitted(true);

        if (isPageValid()) {
            try {
                await createOrUpdateNavigationElements(
                    {
                        tenantId: tenantId,
                        editingToken: editingToken,
                        flowId: flowId,
                    },
                    navigationElement,
                );

                done();
            } catch ({ message }) {
                addNotification({
                    type: 'error',
                    message,
                    isPersistent: true,
                });
            }
        }
    };

    const isPageValid = () => {
        let valid = true;

        if (isNullOrEmpty(navigationElement.developerName)) {
            valid = false;
        }

        if (!isNullOrEmpty(errors)) {
            if (errors.name || errors.label || errors.mapElement || errors.url) {
                valid = false;
            }
        }

        return valid;
    };

    const renderLoadingSection = () => <Loader message="Loading navigation details" />;

    const renderDetailsSection = () => {
        return (
            <div className="nav-details-bar">
                <FormGroup
                    label="Name"
                    isRequired
                    isValid={isPageValid()}
                    showValidation={hasSubmitted}
                    validationMessage={'This field is required.'}
                    htmlFor="name"
                >
                    <input
                        type="text"
                        className="form-control"
                        maxLength={150}
                        size={25}
                        value={navigationElement.developerName}
                        onChange={({ target }) => {
                            setNavigationElement({
                                ...navigationElement,
                                developerName: target.value,
                            });
                        }}
                        required
                        data-testid="name-input"
                        id="name"
                    />
                </FormGroup>
                <FormGroup label="Label" htmlFor="label">
                    <input
                        type="text"
                        className="form-control"
                        maxLength={150}
                        size={25}
                        value={navigationElement.label}
                        onChange={({ target }) => {
                            setNavigationElement({ ...navigationElement, label: target.value });
                        }}
                        id="label"
                        data-testid="label-input"
                    />
                </FormGroup>
            </div>
        );
    };

    const renderPreviewSection = () => {
        const navigationItems = navigationElement?.navigationItems ?? [];

        const items = navigationItems
            .sort((item1, item2) => {
                return item1.order - item2.order;
            })
            .map((item) => {
                return (
                    <NavigationItem
                        key={item.id}
                        parent={null}
                        items={navigationItems}
                        item={item}
                        isTopLevel={true}
                        moveItem={moveItem}
                        itemClick={onItemClick}
                        addItem={addItem}
                        findItem={findItem}
                    />
                );
            });

        items.push(
            <li key={'add-item'} className="add-item">
                <div
                    onClick={(e) => addItem('', e)}
                    onKeyUp={(e) => addItem('', e)}
                    role="button"
                    tabIndex="0"
                >
                    <span className="icon-wrapper">
                        <Glyphicon glyph="plus" title="Add Navigation Item" />
                    </span>
                </div>
            </li>,
        );

        return (
            <>
                <h3>Preview</h3>
                <p>Drag & drop to move navigation items. Click the plus icon to add new items</p>
                <nav className="navbar navbar-inverse navbar-editor">
                    <div className="navbar-header">
                        <span
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#"
                        >
                            <span className="sr-only">Toggle Navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </span>
                    </div>
                    <div className="collapse navbar-collapse">
                        <ul className="nav navbar-nav list-wrapper">{items}</ul>
                    </div>
                </nav>
            </>
        );
    };

    const renderFormSection = () => {
        if (mapElementsSorted) {
            const options = mapElementsSorted.map((option) => ({
                label: option.developerName,
                value: option.id,
            }));

            options.unshift({
                label: 'URL',
                value: 'URL',
            });

            const deleteButton = !isNewItem && (
                <ButtonDanger onClick={onDeleteClick}>Delete</ButtonDanger>
            );

            if (editing) {
                return (
                    <div className="navigation-input" key="form">
                        <div className="input-group-wrapper">
                            <FormGroup
                                label="Navigate To "
                                className={`input-group-item${
                                    errors.mapElement ? ' has-error' : ''
                                }`}
                                htmlFor="navigate-to"
                            >
                                <Select
                                    styles={getSharedStyles()}
                                    inputId="navigate-to"
                                    options={options}
                                    onChange={updateMapElement}
                                    placeholder="Select a map element or go to a URL"
                                    noOptionsMessage={() => 'No results found'}
                                    required={true}
                                    value={
                                        isUrl
                                            ? { label: 'URL', value: 'URL' }
                                            : mapElement
                                              ? { label: name, value: mapElement }
                                              : null
                                    }
                                />
                                {errors.mapElement ? (
                                    <div className="help-block error-state">
                                        A map element or URL is required
                                    </div>
                                ) : null}
                            </FormGroup>
                            <FormGroup
                                label="Label "
                                className={`input-group-item${errors.label ? ' has-error' : ''}`}
                                htmlFor="navigate-to-label"
                            >
                                <input
                                    id="navigate-to-label"
                                    data-testid="navigate-to-label"
                                    className="form-control"
                                    type="text"
                                    value={label}
                                    onChange={updateLabel}
                                    required={true}
                                    maxLength={255}
                                />
                                {errors.label ? (
                                    <div className="help-block error-state">
                                        A label is required
                                    </div>
                                ) : null}
                            </FormGroup>
                        </div>
                        {isUrl && (
                            <NavigationUrlInputs
                                hasUrlError={!isNullOrEmpty(errors.url)}
                                url={url}
                                urlTarget={urlTarget}
                                onUrlChanged={onUrlChanged}
                                updateUrlTarget={updateUrlTarget}
                            />
                        )}
                        <div className="add-navigation-actions">
                            <ButtonDefault onClick={cancelEdit}>Cancel</ButtonDefault>
                            <ButtonPrimary onClick={saveNavigationItem}>Save</ButtonPrimary>
                            {deleteButton}
                        </div>
                    </div>
                );
            }
        }
    };

    const nextgenRuntimeSection = () => (
        <div className="state-wrapper">
            <h3>Position</h3>
            <FormGroup label="Position of the navigation relative to the page" htmlFor="position">
                <select
                    id="position"
                    className="form-control auto-width"
                    value={safeToLower(navigationElement.position)}
                    onChange={({ target }) => {
                        setNavigationElement({ ...navigationElement, position: target.value });
                    }}
                    required={true}
                >
                    <option key="top" value={NAVIGATION_POSITION.top}>
                        Top
                    </option>
                    <option key="left" value={NAVIGATION_POSITION.left}>
                        Left
                    </option>
                </select>
                <span className="help-block">
                    This property only works with the {RUNTIME_LABELS.NEXT.toLowerCase()} runtime
                </span>
            </FormGroup>
        </div>
    );

    const stateSection = () => {
        return (
            <div className="state-wrapper">
                <h3>State</h3>
                <label>
                    Persist input/selection data on navigation
                    <input
                        id="input-state-checkbox"
                        className="state-checkbox"
                        type="checkbox"
                        checked={navigationElement.persistState}
                        onChange={({ target }) => {
                            setNavigationElement({
                                ...navigationElement,
                                persistState: target.checked,
                            });
                        }}
                    />
                </label>
                <span className="help-block">
                    If unchecked, navigating away from this page will clear any current user input
                    or selections
                </span>
                <label>
                    Persist values on navigation
                    <input
                        id="value-state-checkbox"
                        className="state-checkbox"
                        type="checkbox"
                        checked={navigationElement.persistValues}
                        onChange={({ target }) => {
                            setNavigationElement({
                                ...navigationElement,
                                persistValues: target.checked,
                            });
                        }}
                    />
                </label>
                <span className="help-block">
                    If unchecked, navigating away from this page will clear any values
                </span>
            </div>
        );
    };

    const commentsSection = () => {
        return (
            <>
                <label htmlFor="comments">Comments</label>
                <textarea
                    maxLength={255}
                    className="form-control comments"
                    rows="5"
                    value={navigationElement.developerSummary}
                    onChange={({ target }) => {
                        setNavigationElement({
                            ...navigationElement,
                            developerSummary: target.value,
                        });
                    }}
                    id="comments"
                    data-testid="comment-input"
                />
            </>
        );
    };

    const pageHeader = () => <h4 className="modal-title">Navigation Editor</h4>;

    const pageBody = () =>
        isLoading ? (
            renderLoadingSection()
        ) : (
            <>
                {renderDetailsSection()}
                {renderPreviewSection()}
                {renderFormSection()}
                <div className="section-wrapper">
                    {nextgenRuntimeSection()}
                    {stateSection()}
                    {commentsSection()}
                </div>
            </>
        );

    const PageFooter = () => (
        <Footer
            save={saveNavigationElement}
            saveButtonText={translations.GRAPH_config_panel_save}
            cancelButtonText="Back"
            cancel={close}
        />
    );

    return (
        <DndProvider backend={HTML5Backend}>
            <Modal
                className="config-modal"
                renderHeader={pageHeader}
                renderBody={pageBody}
                renderFooter={PageFooter}
                container={container}
                onHide={closeModal}
            />
        </DndProvider>
    );
};

export default NavigationEditor;

import { type ReactElement, createContext, useContext, useState } from 'react';
import { getAllPlayerNames } from '../../sources/player';
import { getAllThemeNames } from '../../sources/theme';

interface RunContext {
    player: string;
    theme: string;
    players: string[];
    themes: string[];
    setTheme: (theme: string) => void;
    setPlayer: (player: string) => void;
    getThemes: () => Promise<void>;
    getPlayers: () => Promise<void>;
}

interface Props {
    children?: ReactElement;
}

export const defaultPlayerName = 'default';
export const defaultThemeName = 'default';

const Context = createContext<RunContext | undefined>(undefined);

const RunProvider = (props: Props) => {
    const [player, setPlayer] = useState<string>(defaultPlayerName);
    const [theme, setTheme] = useState<string>(defaultThemeName);
    const [players, setPlayers] = useState<string[]>([]);
    const [themes, setThemes] = useState<string[]>([]);

    const getPlayers = async () => {
        const fetchedPlayers = await getAllPlayerNames();
        setPlayers(fetchedPlayers);

        if (Array.isArray(fetchedPlayers) && fetchedPlayers.indexOf(player) === -1) {
            setPlayer(defaultPlayerName);
        }
    };

    const getThemes = async () => {
        const fetchedThemes = await getAllThemeNames();
        setThemes(fetchedThemes);

        if (Array.isArray(fetchedThemes) && fetchedThemes.indexOf(theme) === -1) {
            setTheme(defaultThemeName);
        }
    };

    const contextValue: RunContext = {
        player,
        theme,
        setPlayer,
        setTheme,
        players,
        themes,
        getPlayers,
        getThemes,
    };

    return <Context.Provider value={contextValue}>{props.children}</Context.Provider>;
};

const useRun = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useRun must be used within a RunProvider');
    }
    return context;
};

export { RunProvider, useRun };

import actionTypes from '../actions/reduxActions/actionTypes';

// invitation example:
// {
//     "completedAt": "",
//     "completedBy": {
//       "email": "",
//       "firstName": "",
//       "id": "",
//       "lastName": ""
//     },
//     "invitedAt": "",
//     "invitedBy": {
//       "email": "",
//       "firstName": "",
//       "id": "",
//       "lastName": ""
//     },
//     "organization": {
//       "id": "",
//       "name": ""
//     },
//     "status": "accepted" | "rejected",
//     "tenant": {
//       "developerName": "",
//       "id": ""
//     },
//     "type": "tenant" | "user",
//     "user": null
// }
// Only tenant or user property will be populated

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const organizationSentUserInvitations = (state = [], { type, payload }) => {
    switch (type) {
        case actionTypes.ORGANIZATION_SET_SENT_USER_INVITATIONS: {
            const typedInvitations = payload.sentInvitations.map((invite) => ({
                ...invite,
                type: invite.type || 'user',
            }));

            return typedInvitations;
        }

        default:
            return state;
    }
};

export default organizationSentUserInvitations;

import type { ReleaseAPI, ReleaseFilter } from '../types/releases';
import { fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export const getReleases = (filter: ReleaseFilter) =>
    fetchAndParse<ReleaseAPI[]>({
        url: '/api/release/1/getreleases',
        method: 'post',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: filter,
    });

export const getRelease = (id: string) =>
    fetchAndParse<ReleaseAPI>({
        url: `/api/release/1/${id}`,
        method: 'get',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const deleteRelease = (releaseId: string) =>
    fetchAndParse<void>({
        url: `/api/release/1/${releaseId}`,
        method: 'delete',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const removeFlowFromRelease = (releaseId: string, flowId: string) =>
    fetchAndParse<void>({
        url: `/api/release/1/${releaseId}/flow/${flowId}`,
        method: 'delete',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const removeThemeFromRelease = (releaseId: string, themeId: string, themeName: string) =>
    fetchAndParse<void>({
        url: `/api/release/1/${releaseId}/theme/${themeId}?themeName=${themeName}`,
        method: 'delete',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const deploy = (releaseId: string, environmentId: string) =>
    fetchAndParse<void>({
        url: `/api/release/1/deploy/${releaseId}/environment/${environmentId}`,
        method: 'put',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const rollback = (releaseId: string, environmentId: string) =>
    fetchAndParse<void>({
        url: `/api/release/1/rollback/${releaseId}/environment/${environmentId}`,
        method: 'delete',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

import { Trash } from '@phosphor-icons/react';
import { useEffect } from 'react';
import translations from '../../translations';
import type { TypeElementPropertyAPI } from '../../types';
import { stringContains } from '../../utils/string';
import CopyableText from '../generic/CopyableText';
import SearchInput from '../generic/SearchInput';
import Table, { type TableColumnList } from '../generic/Table';
import AddPropertyButton from './AddPropertyButton';
import { useTypes } from './TypesProvider';

type TypePropertiesProps = {
    hasSubmitted: boolean;
    onPropertyDelete: (property: TypeElementPropertyAPI) => void;
};

const TypeProperties = ({ hasSubmitted, onPropertyDelete }: TypePropertiesProps) => {
    const {
        typeToEdit,
        createNewTypeProperty,
        editProperty,
        propertyPaging,
        updatePropertyPaging,
    } = useTypes();

    const properties = typeToEdit.properties || [];

    useEffect(() => {
        updatePropertyPaging((previousPaging) => ({
            ...previousPaging,
            total: properties.length,
        }));
    }, [updatePropertyPaging, properties]);

    const propertyColumns: TableColumnList<TypeElementPropertyAPI> = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => (
                <button className="link-emulate" onClick={() => editProperty(item)} type="button">
                    {item.developerName}
                </button>
            ),
            size: '400px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type,
            renderCell: ({ item }) => <div>{item.contentType}</div>,
            size: '400px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type_name,
            renderCell: ({ item }) => <div>{item.typeElementDeveloperName}</div>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_id,
            renderCell: ({ item }) => <CopyableText copyableText={item.id} hasCopyButton={true} />,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => {
                const label = `${translations.COMMON_delete} ${item.developerName}`;

                return (
                    <div className="action-btn-wrapper">
                        <button
                            title={label}
                            className="table-icon table-icon-delete"
                            aria-label={label}
                            onClick={() => onPropertyDelete(item)}
                            type="button"
                        >
                            <Trash />
                        </button>
                    </div>
                );
            },
            size: '5rem',
        },
    ];

    const handlePage = (page: number) => {
        updatePropertyPaging({ ...propertyPaging, page });
    };

    const handleSearch = (search: string) => {
        updatePropertyPaging({ ...propertyPaging, search });
    };

    const filterResults = () => {
        const queryResult = properties.filter((property) =>
            stringContains(property.developerName, propertyPaging.search, false),
        );

        const startIndex = (page - 1) * pageSize;
        let endIndex = startIndex + pageSize;

        if (endIndex > queryResult.length) {
            endIndex = queryResult.length;
        }

        return queryResult.slice(startIndex, endIndex);
    };

    const { page, search, total, pageSize } = propertyPaging;
    const hasProperties = properties.length > 0;

    const propertiesList = hasProperties ? (
        <>
            <h2>{translations.TYPES_properties_heading}</h2>
            <div className="flex">
                <SearchInput value={search} onChange={handleSearch} />
                <AddPropertyButton
                    createProperty={createNewTypeProperty}
                    isValid={hasProperties}
                    showValidation={hasSubmitted}
                />
            </div>
            <Table
                wrapperClassName="margin-top"
                columns={propertyColumns}
                items={filterResults()}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: handlePage,
                }}
                testId="type-properties"
            />
        </>
    ) : (
        <AddPropertyButton
            createProperty={createNewTypeProperty}
            isValid={hasProperties}
            showValidation={hasSubmitted}
        />
    );

    return propertiesList;
};

export default TypeProperties;

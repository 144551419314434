import '../../../../css/buttons.less';
import type { ButtonProps } from '../../types';

// New ButtonAction component (based on ButtonIcon) that uses latest Exosphere styling.
// Mainly used in new page builder as replacement for ButtonIcon so we don't have to
// mess with the original ButtonIcon (for now) which is used in other parts of Tooling.
// Action buttons are meant to be used with Phosphor icons only.
const ButtonAction = ({ onClick, testId, className, children, ...otherProps }: ButtonProps) => (
    <button
        type="button"
        onClick={onClick}
        data-testid={testId}
        className={`btn-action ${className ?? ''}`}
        {...otherProps}
    >
        {children}
    </button>
);

export default ButtonAction;

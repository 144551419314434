import { useEffect, useState } from 'react';
import { getType } from '../../../../../../../../sources/type';
import { NOTIFICATION_TYPES } from '../../../../../../../../constants';
import { usePageEditor } from '../../../../../PageEditorProvider';
import FilterWhereConditionConfig from './filter-where-config/FilterWhereConditionConfig';
import FilterWhereGeneralConfig from './filter-where-config/FilterWhereGeneralConfig';
import translations from '../../../../../../../../translations';
import type { ListFilter, ObjectDataRequest, ColumnOption } from '../../../../../../../../types';

interface Props {
    dataSourceConnector: ObjectDataRequest | null;
    dataSourceFilter: ListFilter | null;
    updateDataSourceFilter: (filter: ListFilter) => void;
}

const FilterWhereConfig = ({
    dataSourceConnector,
    dataSourceFilter,
    updateDataSourceFilter,
}: Props) => {
    const { addNotification } = usePageEditor();

    const [columnOptions, setColumnOptions] = useState<ColumnOption[]>([]);
    const [loadingColumnOptions, setLoadingColumnOptions] = useState(false);

    // Fetch the potential data columns that we will use to define some filter conditions and/or
    // ordering.
    const fetchColumns = async (typeId: string) => {
        try {
            const typeElement = typeId ? await getType(typeId) : null;

            // Get the props from type.bindings[0].propertyBindings (has no content types) or
            // from type.properties (has content type)? Going with type.properties for now.
            if (typeElement?.bindings?.length) {
                const bindingProps = typeElement.properties;
                const options = bindingProps.map((binding) => ({
                    value: binding.id,
                    label: binding.developerName,
                    type: binding.contentType,
                }));

                setColumnOptions(options);
                setLoadingColumnOptions(false);
            }
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: `${translations.PAGE_BUILDER_unable_to_load_columns}: ${
                    (error as Error).message
                }`,
                isPersistent: true,
            });
            setLoadingColumnOptions(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (dataSourceConnector?.typeElementId) {
            setLoadingColumnOptions(true);
            fetchColumns(dataSourceConnector.typeElementId);
        }
    }, [dataSourceConnector?.typeElementId]);

    const renderContent = () => (
        <>
            <FilterWhereConditionConfig
                columns={columnOptions}
                dataSourceFilter={dataSourceFilter}
                updateDataSourceFilter={updateDataSourceFilter}
            />
            <FilterWhereGeneralConfig
                columns={columnOptions}
                dataSourceFilter={dataSourceFilter}
                updateDataSourceFilter={updateDataSourceFilter}
            />
        </>
    );

    // Render the config only when we have the data columns, since without them, we can't really
    // properly do any filter configuration.
    return loadingColumnOptions ? (
        <>Fetching data columns...</>
    ) : columnOptions?.length ? (
        renderContent()
    ) : (
        <>No data columns available!</>
    );
};

export default FilterWhereConfig;

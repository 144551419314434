import actionTypes from './actionTypes';
import { setItem } from '../../../ts/utils/storage';

export const toggleSidebarCollapsed =
    ({ isCollapsed }) =>
    (dispatch) => {
        setItem('sidebar_collapsed', !isCollapsed);

        dispatch({
            type: actionTypes.SIDEBAR_TOGGLE,
            payload: !isCollapsed,
        });
    };

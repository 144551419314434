import { useCollaboration } from '../../../collaboration/CollaborationProvider';
import translations from '../../../translations';
import Presence from '../../collaboration/Presence';
import { useAuth } from '../../AuthProvider';

interface Props {
    flowId: string;
}

const FlowUsers = ({ flowId }: Props) => {
    const { users, items } = useCollaboration();
    const { user } = useAuth();

    const usersInFlow = items[flowId]?.users ?? [];

    const renderContent = () => {
        if (usersInFlow.length === 0) {
            return null;
        }

        if (usersInFlow.length === 1 && usersInFlow.includes(user?.id ?? '')) {
            return <div className="users-message">{translations.HOME_flow_users_just_you}</div>;
        }

        return (
            <div className="flow-users-wrapper">
                <Presence
                    users={users}
                    presentUsers={items[flowId]?.users ?? []}
                    currentUserId={user?.id ?? ''}
                />
            </div>
        );
    };

    return renderContent();
};

export default FlowUsers;

import type { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    className?: string;
}

const Footer = ({ children, className }: Props) => (
    <div className={`admin-footer ${className ?? ''}`}>{children}</div>
);

export default Footer;

import '@boomi/exosphere/dist/styles.css';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from '../js/store/store';
import { AuthProvider } from '../ts/components/AuthProvider';
import Root from './components/Root';

window.React = React;

const root = createRoot(document.getElementById('root') as Element);
root.render(
    <StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <AuthProvider>
                    <Root />
                </AuthProvider>
            </Provider>
        </BrowserRouter>
    </StrictMode>,
);

export default root;

import { OKTA_BASE_URL, OKTA_CLIENT_ID, ENGINE_API_URL } from '../constants';
import { OktaAuth, type TokenParams } from '@okta/okta-auth-js';

export const authClient = new OktaAuth({
    issuer: `${OKTA_BASE_URL}/oauth2/default`,
    clientId: OKTA_CLIENT_ID,
    redirectUri: `${
        ENGINE_API_URL || window.location.origin
    }/api/draw/1/authorization-code/callback`,
    responseType: 'code',
    pkce: false,
});

export const getWithRedirect = async (params?: TokenParams | undefined) => {
    await authClient.token.getWithRedirect(params);
};

export const signOut = async () => {
    await authClient.signOut();
};

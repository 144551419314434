import { stringContains } from './string';

export const formatTenantName = (tenantName: string) => {
    return tenantName.replace('@', '');
};

export const getTenantId = () => {
    const tenantPathSection = window.location.pathname.split('/')[1];
    // A tenant ID will be 36 characters
    if (tenantPathSection?.length !== 36) {
        throw 'Invalid Tenant ID';
    }
    return tenantPathSection;
};

export const isSubtenant = (tenantName: string) => stringContains(tenantName, '+');

import ButtonDefault from '../../../../../../ts/components/buttons/ButtonDefault';
import Glyphicon from '../../../../../../ts/components/generic/Glyphicon';
import translations from '../../../../../../ts/translations';

import { useDataAction } from '../../contextProviders/DataActionProvider';
import { CRUD_OPERATION_TYPES } from '../constants';

const FilterButton = ({ typeElementId }) => {
    const { onCreateDataActionFilter, dataActionToEdit, onEditDataActionFilter } = useDataAction();
    const { dataAction } = dataActionToEdit;
    const shouldDisplay =
        typeElementId && dataAction.crudOperationType === CRUD_OPERATION_TYPES.load;

    if (shouldDisplay) {
        const hasFilter = dataAction.objectDataRequest;
        const onEditFilter = hasFilter ? onEditDataActionFilter : onCreateDataActionFilter;
        return (
            <div className="margin-bottom flex">
                <ButtonDefault title="Edit Filter" onClick={onEditFilter}>
                    <Glyphicon glyph="edit" />
                    {translations.DATA_ACTION_edit_filter_button_text}
                </ButtonDefault>
            </div>
        );
    }

    return null;
};

export default FilterButton;

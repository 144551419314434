import ButtonPrimary from '../../../../../../../buttons/ButtonPrimary';
import Glyphicon from '../../../../../../../generic/Glyphicon';
import ConfigSection from '../../ConfigSection';
import { COMPONENT_CONFIGURATION_PATH as cfgPath } from '../../../../../../constants';
import { useComposer } from '../../../ComposerProvider';
import Table from '../../../../../../../generic/Table';
import { Trash } from '@phosphor-icons/react';
import translations from '../../../../../../../../translations';
import CreatableSelect from 'react-select/creatable';
import { getSharedStyles } from '../../../../../../../../utils/select';

type Attribute = { value: string; key: string };
type AvailableAttribute = { value: string; label: string };

interface Props {
    id: string;
    attributes: Attribute[];
    attributesList: AvailableAttribute[];
}

const PageElementAttributes = ({ id, attributes, attributesList }: Props) => {
    const { onPageElementUpdate } = useComposer();

    const onChange = (updatedAttributes: Attribute[]) => {
        onPageElementUpdate(
            id,
            cfgPath['ATTRIBUTES'],
            updatedAttributes.reduce(
                (previousValue, currentValue) =>
                    Object.assign(previousValue, { [currentValue.key]: currentValue.value }),
                {},
            ),
        );
    };

    const setAttributeKey = (key: string, index: number) => {
        const selectedAttributes = attributes;

        selectedAttributes[index].key = key;
        onChange([...selectedAttributes]);
    };

    const setAttributeValue = (value: string, index: number) => {
        const selectedAttributes = attributes;
        if (selectedAttributes[index].value !== value) {
            selectedAttributes[index].value = value;
            onChange([...selectedAttributes]);
        }
    };

    const removeAttribute = (index: number) => {
        const selectedAttributes = attributes;
        selectedAttributes.splice(index, 1);
        onChange([...selectedAttributes]);
    };

    return (
        <ConfigSection title="Attributes" dataTestId="attributes-section">
            <div className="flex flex-child-right">
                <ButtonPrimary
                    title="Add attribute"
                    onClick={() => onChange([...attributes, { key: '', value: '' }])}
                >
                    <Glyphicon glyph="plus" />
                    Add Attribute
                </ButtonPrimary>
            </div>
            <Table
                columns={[
                    {
                        renderHeader: () => translations.COMMON_TABLE_key,
                        renderCell: ({ item, rowIndex }) => (
                            <div
                                title={
                                    attributesList.find((m) => m.value === item.key)?.label ??
                                    item.key
                                }
                            >
                                <label
                                    className="hidden"
                                    htmlFor={`attribute-key-${rowIndex}`}
                                >{`attribute-key-${rowIndex}`}</label>
                                <CreatableSelect
                                    styles={getSharedStyles<AvailableAttribute>()}
                                    inputId={`attribute-key-${rowIndex}`}
                                    placeholder="Select or create a key"
                                    value={
                                        attributesList.find((m) => m.value === item.key) || {
                                            label: item.key,
                                            value: item.key,
                                        }
                                    }
                                    onChange={(selection) =>
                                        setAttributeKey(selection ? selection.value : '', rowIndex)
                                    }
                                    options={attributesList.filter((attribute) => {
                                        return attributes.every((filter) => {
                                            return filter.key !== attribute.value;
                                        });
                                    })}
                                    isClearable
                                    formatCreateLabel={(input) => `Create "${input}" key`}
                                    menuPlacement="top"
                                />
                            </div>
                        ),
                        cellClassName: 'generic-cell-overflow',
                        size: '50%',
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_value,
                        renderCell: ({ item, rowIndex }) => (
                            <input
                                value={item.value}
                                onChange={({ target: { value } }) =>
                                    setAttributeValue(value, rowIndex)
                                }
                                className="form-control"
                                type="text"
                            />
                        ),
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_actions,
                        renderCell: ({ rowIndex }) => (
                            <div className="action-btn-wrapper">
                                <button
                                    title="Delete"
                                    className="table-icon table-icon-delete"
                                    aria-label="Delete"
                                    onClick={() => removeAttribute(rowIndex)}
                                    type="button"
                                >
                                    <Trash />
                                </button>
                            </div>
                        ),
                        size: '5rem',
                    },
                ]}
                items={attributes}
            />
        </ConfigSection>
    );
};

export default PageElementAttributes;

import { useEffect, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { notifyError } from '../../../../js/actions/reduxActions/notification';
import { getTenants, type OrganizationTenant } from '../../../sources/organization';
import translations from '../../../translations';
import type { TenantMinimalAPI } from '../../../types/Tenant';
import { stringContains, stringReplace } from '../../../utils/string';
import SearchInput from '../../generic/SearchInput';
import Table, { type TableColumnList } from '../../generic/Table';
import TenantName from '../../generic/TenantName';

type LocalRuntimeOrgTenants = {
    connectedTenantIds: string[];
    onSelect: (isSelected: boolean, tenantId: string) => void;
} & ConnectedProps<typeof connector>;

const mapDispatchToProps = {
    notifyError,
};

const connector = connect(null, mapDispatchToProps);

const LocalRuntimeOrgTenants = ({
    connectedTenantIds,
    onSelect,
    notifyError,
}: LocalRuntimeOrgTenants) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tenants, setTenants] = useState<OrganizationTenant[]>([]);
    const [searchFilter, setSearchFilter] = useState('');

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);

                const tenants = await getTenants();

                setTenants(tenants);
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [notifyError]);

    const tenantsToDisplay = tenants
        .flatMap((tenant) => [tenant, ...tenant.subtenants])
        .filter((tenant) => stringContains(tenant.developerName, searchFilter, false));

    const handleChange = (searchTerm: string) => {
        setSearchFilter(searchTerm);
    };

    const tenantTableColumns: TableColumnList<TenantMinimalAPI> = [
        {
            renderHeader: () => {
                return stringReplace(
                    translations.LOCAL_RUNTIME_create_screen_number_tenants_selected,
                    {
                        tenantsSelected: connectedTenantIds.length,
                    },
                );
            },
            renderCell: ({ item: tenant }) => {
                return (
                    <input
                        defaultChecked={!!connectedTenantIds.find((t) => t === tenant.id)}
                        onChange={({ target: { checked } }) => onSelect(checked, tenant.id)}
                        type="checkbox"
                    />
                );
            },
            size: '6rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_tenant_name,
            renderCell: ({ item: tenant }) => {
                return <TenantName name={tenant.developerName} />;
            },
        },
    ];

    return (
        <>
            <SearchInput value={searchFilter} onChange={handleChange} />
            <Table
                wrapperClassName="margin-top"
                items={tenantsToDisplay}
                columns={tenantTableColumns}
                isLoading={isLoading}
                pagination={true}
                rowKeyGenerator={(tenant) => tenant.id}
            />
        </>
    );
};

export default connector(LocalRuntimeOrgTenants);

const { pendo } = window;

export const initializePendo = (user, tenant) => {
    if (pendo && user && tenant) {
        const options = {
            visitor: {
                id: user.id,
                email: user.email,
                full_name: `${user.firstName} ${user.lastName}`,
            },
            account: {
                id: tenant.id,
                name: tenant.developerName,
                expires: new Date(tenant.expiresAt).toISOString(),
            },
            parentAccount: {
                id: tenant.organization ? tenant.organization.id : 'no-organization',
                name: tenant.organization?.name,
            },
        };

        if (pendo.visitorId) {
            pendo.identify(options);
        } else {
            pendo.initialize(options);
        }
    }
};

import ButtonPrimary from '../../../../../../../buttons/ButtonPrimary';
import Glyphicon from '../../../../../../../generic/Glyphicon';
import Table from '../../../../../../../generic/Table';
import translations from '../../../../../../../../translations';

import { usePageEditor } from '../../../../../PageEditorProvider';
import { Trash } from '@phosphor-icons/react';
import type { PageComponent } from '../../../../../../../../types';
import type { ElementSelection } from '../../../ComposerProvider';

interface Props {
    screen?: string;
    dataSets: PageComponent[];
    editDataSet: (id: string | null) => void;
    addNewDataSet: () => void;
    deleteDataSet: (payload: ElementSelection) => void;
}

const DataSets = ({ dataSets, editDataSet, addNewDataSet, deleteDataSet }: Props) => {
    const { getComponent } = usePageEditor();
    const getLabel = (componentType: string | null) => getComponent(componentType).ui.caption;

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }: { item: PageComponent }) => (
                <button
                    className="link-emulate"
                    title="Edit"
                    aria-label="Edit"
                    onClick={() => editDataSet(item.id)}
                    type="button"
                >
                    {item.developerName ?? 'Untitled...'}
                </button>
            ),
        },
        {
            renderHeader: () => 'Chart Type',
            renderCell: ({ item }: { item: PageComponent }) => getLabel(item.componentType),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }: { item: PageComponent }) => (
                <div className="action-btn-wrapper">
                    <button
                        title="Delete"
                        className="table-icon table-icon-delete"
                        aria-label="Delete"
                        onClick={() =>
                            deleteDataSet({
                                targetId: item.id as string,
                                title: translations.PAGE_BUILDER_delete_dataset_title,
                                message: translations.PAGE_BUILDER_delete_dataset_message,
                            })
                        }
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <div className="flex flex-child-right">
                <ButtonPrimary onClick={addNewDataSet} title="Add new chart dataset">
                    <Glyphicon glyph="plus" />
                    Add Dataset
                </ButtonPrimary>
            </div>
            <Table columns={columns} items={dataSets} />
        </>
    );
};

export default DataSets;

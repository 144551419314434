export const AUTHENTICATION_TYPES = {
    public: {
        value: 'PUBLIC',
        label: 'Anyone can run this flow',
    },
    allUsers: {
        value: 'ALL_USERS',
        label: 'Any user that can login with the selected identity connector can run this flow',
    },
    specified: {
        value: 'SPECIFIED',
        label: 'Create restrictions for specific users and groups of users',
    },
};

export const AUTHENTICATION_TYPES_LIST = [
    AUTHENTICATION_TYPES.public,
    AUTHENTICATION_TYPES.allUsers,
    AUTHENTICATION_TYPES.specified,
];

import { tabDetailsObjects } from '../config/admin';
import { ALWAYS_OPEN_NEW_TAB, TAB_TYPES } from '../constants';
import { generateRandomString } from './string';

export const generateTabKey = () => generateRandomString(5);

export const generateRouteUrl = ({
    tabType,
    tenantId,
    options: { elementId, tabKey, pageName = 'New Page', isNewFlowRedirect = false } = {},
}: {
    tabType: string;
    tenantId: string;
    options?: {
        elementId?: string;
        tabKey?: string;
        pageName?: string;
        isNewFlowRedirect?: boolean;
    };
}) => {
    const tabDetails = Object.values(tabDetailsObjects).find((td) => td.type === tabType);

    if (tabDetails === undefined) {
        return '';
    }

    let path = `/${tenantId}/${tabDetails.slug}`;

    if (elementId && elementId.length > 0) {
        path += `/${elementId}`;
    }

    const url = new URL(path, window.location.origin);

    if (ALWAYS_OPEN_NEW_TAB.includes(tabType) || isNewFlowRedirect) {
        url.searchParams.set('tab', tabKey ?? '');
    }

    if (tabType === TAB_TYPES.newPage) {
        // The name of a new page can be defined prior to page creation (from the flow canvas).
        // We need to include as a query parameter so that the name can be extracted from the url
        // once the page editor component has mounted.
        url.searchParams.set('page_name', pageName);
    }

    const searchParams = `?${url.searchParams.toString()}`;
    return `${url.pathname}${searchParams.length > 1 ? searchParams : ''}`;
};

import { type ChangeEvent, useState } from 'react';
import { filterOptions, COMPONENT_CONFIGURATION_LABELS } from '../../../../../../constants';
import { componentDataSourceFilter } from '../../../../../../utils';
import FilterUniqueConfig from './filter-unique-config/FilterUniqueConfig';
import FilterWhereConfig from './FilterWhereConfig';
import ConfirmModal from '../../../../../../../generic/modal/ConfirmModal';
import translations from '../../../../../../../../translations';
import ConfigSection from '../../ConfigSection';
import { usePageEditor } from '../../../../../PageEditorProvider';
import type {
    Confirmation,
    FilterType,
    ListFilter,
    ObjectDataRequest,
    ValueElementIdAPI,
} from '../../../../../../../../types';

const defaultListFilterConfig: ListFilter = {
    comparisonType: 'AND',
    filterByProvidedObjects: false,
    filterId: null,
    limit: 0,
    listFilters: null,
    orderBy: null,
    orderByDirectionType: '',
    orderByTypeElementPropertyId: null,
    properties: null,
    searchCriteria: null,
    where: null,
};

interface Props {
    dataSourceConnector: ObjectDataRequest | null;
    dataSourceFilter: ListFilter | null;
    updateDataSourceFilter: (listFilter: ListFilter | null) => void;
}

const FilterConfig = ({ dataSourceConnector, dataSourceFilter, updateDataSourceFilter }: Props) => {
    const { container } = usePageEditor();
    const [confirmProps, setConfirmProps] = useState<Confirmation | null>(null);
    const { filter } = componentDataSourceFilter(dataSourceFilter);
    const selectedFilter = filter;

    // Reset the current filter configuration based on the selected filter type.
    const resetFilterConfiguration = (clickedOption: FilterType) => {
        const resetListFilterConfig =
            clickedOption === filterOptions['NONE']
                ? null
                : {
                      ...defaultListFilterConfig,
                      // Setting to empty {} or [] so they are picked up later in
                      // componentDataSourceFilter, so we can figure out what filter type
                      // was picked after reset.
                      filterId:
                          clickedOption === filterOptions['UNIQUE']
                              ? ({} as ValueElementIdAPI)
                              : null,
                      where: clickedOption === filterOptions['WHERE'] ? [] : null,
                  };

        updateDataSourceFilter(resetListFilterConfig);
    };

    const changeFilterOption = (event: ChangeEvent<HTMLInputElement>) => {
        const clickedOption = event.target.value as FilterType;

        // Show a warning confirmation modal that any existing filter config will be lost when
        // changing the used filter type since we can't have different types of filters
        // defined/active at the same time.
        if (clickedOption !== selectedFilter) {
            setConfirmProps({
                show: true,
                title: translations.PAGE_BUILDER_data_source_filter_modal_title,
                messages: [
                    'Changing Data Source Filter type will reset any existing filtering configuration.',
                ],
                buttonStyle: 'danger',
                buttonCaption: 'OK',
                onCancel: () => setConfirmProps(null),
                onConfirm: () => {
                    // Reset the current filter configuration based on the selected filter type.
                    resetFilterConfiguration(clickedOption);
                    setConfirmProps(null); // close modal
                },
                container,
            });
        }
    };

    const renderConfig = () =>
        selectedFilter !== filterOptions['NONE'] ? (
            selectedFilter === filterOptions['UNIQUE'] ? (
                <FilterUniqueConfig
                    dataSourceFilter={dataSourceFilter}
                    updateDataSourceFilter={updateDataSourceFilter}
                />
            ) : (
                <FilterWhereConfig
                    dataSourceConnector={dataSourceConnector}
                    dataSourceFilter={dataSourceFilter}
                    updateDataSourceFilter={updateDataSourceFilter}
                />
            )
        ) : (
            <></>
        );

    return (
        <>
            <ConfigSection
                dataTestId="data-source-filter"
                title={COMPONENT_CONFIGURATION_LABELS['DATA_SOURCE_FILTER']}
            >
                <fieldset className="radio-button-group">
                    <label className="radio-button-label" htmlFor={filterOptions['NONE']}>
                        <input
                            className="radio-button-input"
                            type="radio"
                            id={filterOptions['NONE']}
                            name="filtering"
                            value={filterOptions['NONE']}
                            onChange={changeFilterOption}
                            checked={selectedFilter === filterOptions['NONE']}
                        />
                        Do not filter
                    </label>
                    <label className="radio-button-label" htmlFor={filterOptions['UNIQUE']}>
                        <input
                            className="radio-button-input"
                            type="radio"
                            id={filterOptions['UNIQUE']}
                            name="filtering"
                            value={filterOptions['UNIQUE']}
                            onChange={changeFilterOption}
                            checked={selectedFilter === filterOptions['UNIQUE']}
                        />
                        Filter using a unique identifier
                    </label>
                    <label className="radio-button-label" htmlFor={filterOptions['WHERE']}>
                        <input
                            className="radio-button-input"
                            type="radio"
                            id={filterOptions['WHERE']}
                            name="filtering"
                            value={filterOptions['WHERE']}
                            onChange={changeFilterOption}
                            checked={selectedFilter === filterOptions['WHERE']}
                        />
                        Filter using a &apos;WHERE&apos; clause
                    </label>
                </fieldset>

                {renderConfig()}
            </ConfigSection>
            {confirmProps !== null ? <ConfirmModal {...confirmProps} /> : null}
        </>
    );
};

export default FilterConfig;

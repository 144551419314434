import { memo, useEffect, useReducer } from 'react';
import { getServiceAuthenticationAttributes } from '../../sources/service';
import { Trash } from '@phosphor-icons/react';
import translations from '../../translations';
import type {
    AuthorizationEntity,
    AuthorizationEntityType,
    NotifyError,
    ObjectAPI,
} from '../../types';
import Table from '../generic/Table';
import UserGroupSearch from './UserGroupSearch';
import { type GroupsUsersState, reducer } from './groups-users-reducer';

interface Props {
    authorizationEntityType: AuthorizationEntityType;
    connectorId: string;
    groupsOrUsers: AuthorizationEntity[] | null;
    onChangeGroupsOrUsers: (groupsOrUsers: AuthorizationEntity[] | null) => void;
    notifyError: NotifyError;
}

const formatErrorMessage = (authorizationEntityType: AuthorizationEntityType, error: Error) => {
    const introMessage =
        authorizationEntityType === 'group'
            ? translations.GROUPS_USERS_GROUPS_FETCH_ERROR_MESSAGE
            : translations.GROUPS_USERS_USERS_FETCH_ERROR_MESSAGE;

    try {
        const err = JSON.parse(error.message) as {
            kind: string;
            statusCode: number | string;
            message: string;
            uri: string;
        };

        const strStatusCode = err.statusCode?.toString() ?? '';

        const parsedConnectorMessage = `${err.kind.toUpperCase()} ERROR:
        ${err.statusCode} ${err.message}\n
        ${err.statusCode === strStatusCode ? err.uri : ''}`;
        return `${introMessage}\n ${parsedConnectorMessage}`;
    } catch (_e) {
        return `${introMessage}\n ${error.toString()}`;
    }
};

const GroupsUsersManager = ({
    authorizationEntityType,
    connectorId,
    groupsOrUsers: initialGroupsOrUsers,
    onChangeGroupsOrUsers,
    notifyError,
}: Props) => {
    const initialState: GroupsUsersState = {
        isLoading: false,
        groupsOrUsers: initialGroupsOrUsers,
        attributes: [],
        showGroupsUsers: true,
    };

    const [state, dispatchGroupsUsers] = useReducer(reducer, initialState);

    const addUsersOrGroups = (groupOrUserData: ObjectAPI[]): void => {
        const defaultAttribute = state.attributes[0].externalId;

        if (!defaultAttribute) {
            return;
        }

        dispatchGroupsUsers({
            type: 'addGroupsOrUsers',
            payload: { connectorId, groupOrUserData, attribute: defaultAttribute },
        });
    };

    const deleteGroupOrUser = (authenticationId: string) => {
        dispatchGroupsUsers({ type: 'deleteGroupOrUser', payload: { authenticationId } });
    };

    const changeGroupOrUserAttribute = (authenticationId: string, attributeValue: string) => {
        dispatchGroupsUsers({
            type: 'updateAttribute',
            payload: { authenticationId, attributeValue },
        });
    };

    useEffect(() => {
        const loadServiceGroupsOrUsersAttributes = async () => {
            dispatchGroupsUsers({ type: 'requestAttributes' });

            try {
                const attributes = await getServiceAuthenticationAttributes({
                    serviceId: connectorId,
                    type: authorizationEntityType,
                });

                dispatchGroupsUsers({
                    type: 'responseAttributes',
                    payload: { attributes, hasError: false },
                });
            } catch (error) {
                dispatchGroupsUsers({
                    type: 'responseAttributes',
                    payload: { attributes: [], hasError: true },
                });
                const errorMessage = formatErrorMessage(authorizationEntityType, error as Error);
                notifyError(errorMessage);
            }
        };

        loadServiceGroupsOrUsersAttributes();
    }, [authorizationEntityType, connectorId, notifyError]);

    useEffect(() => {
        onChangeGroupsOrUsers(state.groupsOrUsers);
    }, [onChangeGroupsOrUsers, state.groupsOrUsers]);

    if (!state.showGroupsUsers) {
        return null;
    }

    return (
        <>
            <h2>
                {authorizationEntityType === 'group'
                    ? translations.GROUPS_USERS_GROUPS_HEADING
                    : translations.GROUPS_USERS_USERS_HEADING}
            </h2>
            <UserGroupSearch
                serviceId={connectorId}
                authorizedUsersOrGroups={state.groupsOrUsers}
                authorizationEntityType={authorizationEntityType}
                addUsersOrGroups={addUsersOrGroups}
                notifyError={notifyError}
            />
            <div className="search-wrapper">
                <Table<AuthorizationEntity>
                    wrapperClassName="scroll-table-entry margin-top"
                    columns={[
                        {
                            renderHeader: () => translations.COMMON_TABLE_name,
                            renderCell: ({ item }) => (
                                <>{item.friendlyName || item.authenticationId}</>
                            ),
                        },
                        {
                            renderHeader: () => translations.COMMON_TABLE_attribute,
                            renderCell: ({ item }) => (
                                <select
                                    onChange={({ target }) =>
                                        changeGroupOrUserAttribute(
                                            item.authenticationId,
                                            target.value,
                                        )
                                    }
                                    className="form-control form-control-dynamic"
                                    defaultValue={item.attribute ?? ''}
                                    data-testid={`attributes-${item.authenticationId}`}
                                >
                                    {item.attribute === '-1' ? (
                                        <option key="-1" value="">
                                            --Please Select--
                                        </option>
                                    ) : null}
                                    {state.attributes.map((attributeOption) => (
                                        <option
                                            key={attributeOption.externalId}
                                            value={attributeOption.externalId ?? ''}
                                        >
                                            {attributeOption.properties.find(
                                                (property) => property.developerName === 'Label',
                                            )?.contentValue ?? ''}
                                        </option>
                                    ))}
                                </select>
                            ),
                        },
                        {
                            renderHeader: () => translations.COMMON_TABLE_actions,
                            renderCell: ({ item }) => (
                                <div className="action-btn-wrapper">
                                    <button
                                        title={`Remove ${item.friendlyName}`}
                                        className="table-icon table-icon-delete"
                                        aria-label={`Remove ${item.friendlyName}`}
                                        onClick={() => deleteGroupOrUser(item.authenticationId)}
                                        type="button"
                                    >
                                        <Trash />
                                    </button>
                                </div>
                            ),
                            size: '5rem',
                        },
                    ]}
                    items={state.groupsOrUsers ?? []}
                    rowKeyGenerator={(item: AuthorizationEntity) =>
                        item.authenticationId + item.attribute
                    }
                />
            </div>
        </>
    );
};

export default memo(GroupsUsersManager);

const unsafeCellRegex = /([",\n])/g;

type Row = undefined | null | string | number | boolean | Date;

const processRow = (row: Row[]) => {
    const stringifiedCells = row.map((cellData) => {
        const stringifiedCell =
            cellData === undefined || cellData === null
                ? ''
                : cellData instanceof Date
                  ? cellData.toLocaleString()
                  : cellData.toString();

        const safeStringifiedCell = unsafeCellRegex.test(stringifiedCell)
            ? `"${stringifiedCell}"`
            : stringifiedCell;

        return safeStringifiedCell;
    });

    const stringifiedRow = stringifiedCells.join(',');

    return stringifiedRow;
};

/**
 * Converts a nested list of data to CSV format and initiates download
 *
 * @example
 * exportToCsv('export.csv', [
 *     ['name','description'],
 *     ['david','123'],
 *     ['jona','""'],
 *     ['a','b'],
 * ]);
 *
 * @export
 * @param filename
 * @param rows
 */
export const exportToCsv = (filename: string, rows: Row[][]) => {
    const csvFile = rows.map(processRow).join('\n');

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

    exportBlobToCsv(filename, blob);
};

export const exportBlobToCsv = (filename: string, blob: Blob) => {
    const link = document.createElement('a');

    // should be empty string
    if (link.download === undefined) {
        return;
    }

    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

import { useEffect, useReducer } from 'react';
import ConfigSection from '../../ConfigSection';
import { getValueList as getValueReferenceList } from '../../../../../../../../sources/value';
import type {
    AddNotification,
    ContentType,
    PageComponentValidationAPI,
    PageComponentValidationType,
    ValueElementIdAPI,
} from '../../../../../../../../types';
import ValidationForm from './ValidationForm';
import ValidationList from './ValidationList';
import FormGroup from '../../../../../../../generic/FormGroup';
import Toggle from '../../../../../../../inputs/Toggle';
import translations from '../../../../../../../../translations';

interface Props {
    isRequired: boolean;
    updateRequired: (isRequired: boolean) => void;
    valueElementId: ValueElementIdAPI | null;
    validations: PageComponentValidationAPI[];
    updateValidations: (validations: PageComponentValidationAPI[]) => void;
    addNotification: AddNotification;
}

interface State {
    viewState: 'list' | 'form' | 'hidden';
    selectedValidationIndex: number | null;
    contentType: ContentType;
}

export type ValidationAction =
    | {
          type: 'savedValidation';
          payload: { valueElementId: ValueElementIdAPI | null };
      }
    | {
          type: 'cancelValidation';
          payload: { valueElementId: ValueElementIdAPI | null };
      }
    | {
          type: 'setContentType';
          payload: { contentType: ContentType };
      }
    | {
          type: 'addValidationClick';
      }
    | {
          type: 'editValidationClick';
          payload: { selectedValidationIndex: number };
      };

const reducer = (state: State, action: ValidationAction): State => {
    switch (action.type) {
        case 'savedValidation':
        case 'cancelValidation':
            return {
                ...state,
                viewState: action.payload.valueElementId === null ? 'hidden' : 'list',
                selectedValidationIndex: null,
            };

        case 'setContentType': {
            const { contentType } = action.payload;
            const viewState =
                contentType === null
                    ? 'hidden' //  validations are not applicable
                    : 'list';

            return {
                ...state,
                viewState,
                contentType,
            };
        }

        case 'addValidationClick':
            return {
                ...state,
                viewState: 'form',
                selectedValidationIndex: null,
            };

        case 'editValidationClick':
            return {
                ...state,
                viewState: 'form',
                selectedValidationIndex: action.payload.selectedValidationIndex,
            };
    }
};

const filterInvalidValidations = (
    contentType: ContentType,
    validations: PageComponentValidationAPI[],
) => {
    return validations.filter((validation) => {
        const minMax: PageComponentValidationType[] = ['Minimum', 'Maximum'];
        switch (contentType) {
            case null:
            case 'ContentBoolean':
            case 'ContentObject':
                return false;

            case 'ContentString':
            case 'ContentPassword':
            case 'ContentEncrypted':
            case 'ContentContent':
            case 'ContentCode': {
                if (minMax.includes(validation.type)) {
                    return typeof validation.value === 'number';
                }
                return true;
            }

            case 'ContentDate':
            case 'ContentDateTime':
                return (
                    minMax.includes(validation.type) &&
                    typeof validation.value === 'string' &&
                    Number.isNaN(Number(validation.value))
                );

            case 'ContentNumber':
            case 'ContentList':
                return minMax.includes(validation.type) && typeof validation.value === 'number';
        }
    });
};

const ValidationConfig = ({
    isRequired,
    updateRequired,
    valueElementId,
    validations,
    updateValidations,
    addNotification,
}: Props) => {
    const initialState: State = {
        viewState: 'hidden',
        selectedValidationIndex: null,
        contentType: null,
    };

    const [state, dispatchValidations] = useReducer(reducer, initialState);

    const supportsRequiredValidationOnly =
        state.contentType === 'ContentBoolean' || state.contentType === 'ContentObject';

    const renderRequiredToggle = () => (
        <FormGroup>
            <label>
                <Toggle
                    isOn={isRequired}
                    onChange={() => {
                        updateRequired(!isRequired);
                    }}
                />
                {translations.COMMON_required}
            </label>
        </FormGroup>
    );

    const onSave = (updatedValidation: PageComponentValidationAPI) => {
        if (state.selectedValidationIndex === null) {
            updateValidations([...validations, updatedValidation]);
        } else {
            updateValidations(
                validations.map((validation, index) =>
                    index === state.selectedValidationIndex ? updatedValidation : validation,
                ),
            );
        }

        dispatchValidations({ type: 'savedValidation', payload: { valueElementId } });
    };

    const onCancel = () => {
        dispatchValidations({ type: 'cancelValidation', payload: { valueElementId } });
    };

    const selectedValidation =
        state.selectedValidationIndex === null
            ? null
            : validations[state.selectedValidationIndex] ?? null;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // Get the content type of the value bound to the component state
        const getContentType = async (valueElementId: ValueElementIdAPI): Promise<ContentType> => {
            const valueReferences = await getValueReferenceList({ id: valueElementId.id });
            const selectedValueReference = valueReferences.find((ref) => {
                if (
                    valueElementId.typeElementPropertyId === null ||
                    valueElementId.typeElementPropertyId === undefined
                ) {
                    // return the reference with null property
                    return ref.typeElementPropertyId === null;
                }
                // return the reference with matching property
                return ref.typeElementPropertyId === valueElementId.typeElementPropertyId;
            });

            if (selectedValueReference === undefined) {
                throw `Could not find value reference id: ${valueElementId.id ?? ''}, propertyId: ${
                    valueElementId.typeElementPropertyId ?? 'null'
                }`;
            }

            return selectedValueReference.contentType;
        };

        if (valueElementId === null) {
            dispatchValidations({ type: 'setContentType', payload: { contentType: null } });
            updateRequired(false);
            updateValidations([]);
        } else {
            getContentType(valueElementId)
                .then((contentType) => {
                    const filteredValidations = filterInvalidValidations(contentType, validations);
                    if (filteredValidations.length !== validations.length) {
                        updateValidations(filteredValidations);
                    }

                    dispatchValidations({ type: 'setContentType', payload: { contentType } });
                })
                .catch((error) => {
                    addNotification?.({
                        type: 'error',
                        message: (error as Error).toString(),
                    });
                });
        }

        // Remove any validations as they may not be valid for the new content type
    }, [valueElementId]);

    if (supportsRequiredValidationOnly) {
        return renderRequiredToggle();
    }

    if (state.viewState === 'hidden') {
        return null;
    }

    return (
        <ConfigSection dataTestId="validation-section" title="Validation">
            {renderRequiredToggle()}
            {state.viewState === 'form' ? (
                <ValidationForm
                    validation={selectedValidation}
                    contentType={state.contentType}
                    onSave={onSave}
                    onCancel={onCancel}
                />
            ) : (
                <></>
            )}
            {state.viewState === 'list' ? (
                <ValidationList
                    validations={validations}
                    updateValidations={updateValidations}
                    dispatchValidations={dispatchValidations}
                />
            ) : (
                <></>
            )}
        </ConfigSection>
    );
};

export default ValidationConfig;

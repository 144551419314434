import type { ReactNode } from 'react';
import './css/tooltip.less';

interface Props {
    header: ReactNode;
    content: ReactNode;
}

const TooltipWithHeader = ({ header, content }: Props) => {
    return (
        <div className="tooltip-content-wrapper">
            <h5 className="tooltip-content-header">{header}</h5>
            <div className="tooltip-content-body">{content}</div>
        </div>
    );
};

export default TooltipWithHeader;

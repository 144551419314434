import type { ItemCollectionResponse, AuditEvent } from '../types';
import { CheckStatus, fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export const getAuditLogs = (parameters: string) =>
    fetchAndParse<ItemCollectionResponse<AuditEvent>>({
        url: `/api/audit/1/search?${parameters}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const downloadAuditLogsCsv = async (parameters: string) => {
    const response = await fetch(`/api/audit/1/csv?${parameters}`, {
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

    return (await CheckStatus(response)).blob();
};

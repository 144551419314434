import Select, { type SingleValue } from 'react-select';
import { useState, useEffect } from 'react';
import type { OpenApiPathInfo } from '../../types/service';
import { getSharedStyles } from '../../utils/select';
import { getFileOpenApiSchemaInfo } from '../../sources/openapi';
import translations from '../../translations';

interface OpenApiPathDropdownProps {
    selectedConnectorId: string | null;
    selectedPath: string | null;
    onChange: (selection: SingleValue<{ value: string; label: string | null }>) => void;
    onError: (message: unknown) => void;
}

const OpenApiPathDropdown = ({
    selectedConnectorId,
    selectedPath,
    onChange,
    onError,
}: OpenApiPathDropdownProps) => {
    const [paths, setPaths] = useState<OpenApiPathInfo[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchInfo = async () => {
        try {
            if (selectedConnectorId) {
                const openApiInfo = await getFileOpenApiSchemaInfo({
                    serviceId: selectedConnectorId,
                });
                if (openApiInfo?.schemaInfo?.paths) {
                    setPaths(openApiInfo?.schemaInfo?.paths);
                }
            }
        } catch (error) {
            onError((error as Error).message);
        } finally {
            setIsLoading(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        fetchInfo();
    }, []);

    const pathOptions = paths.map((pathInfo) => ({
        value: pathInfo.path,
        label: pathInfo.path,
    }));

    return (
        <>
            {isLoading ? (
                <p>{translations.PAGE_BUILDER_loading_paths}</p>
            ) : (
                <Select
                    styles={getSharedStyles<(typeof pathOptions)[number]>()}
                    inputId="path-dropdown"
                    name="path-dropdown"
                    isMulti={false}
                    options={pathOptions}
                    onChange={onChange}
                    placeholder={translations.PAGE_BUILDER_select_path}
                    value={
                        selectedPath
                            ? {
                                  value: selectedPath,
                                  label: selectedPath,
                              }
                            : null
                    }
                    noOptionsMessage={() => translations.COMMON_SELECT_no_results_found}
                    isClearable
                />
            )}
        </>
    );
};

export default OpenApiPathDropdown;

import { useComposer } from '../../ComposerProvider';
import { CONTAINER_TYPE, CONTAINER_LABELS } from '../../../../../constants';
import type { ContainerType } from '../../../../dnd-utils';

interface Props {
    containerId: string;
    selectedContainerType: ContainerType;
}

const ContainerTypeSelect = ({ containerId, selectedContainerType }: Props) => {
    const { onPageContainerTypeUpdate } = useComposer();
    return (
        <select
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
                onPageContainerTypeUpdate(containerId, e.target.value as ContainerType)
            }
            value={selectedContainerType.toUpperCase()}
            title="Select Container Type"
        >
            {Object.keys(CONTAINER_TYPE)
                .filter(
                    (x) =>
                        x.toUpperCase() !== CONTAINER_LABELS[CONTAINER_TYPE['chart']].toUpperCase(),
                )
                .map((containerTypeKey) => (
                    <option key={containerTypeKey} value={CONTAINER_TYPE[containerTypeKey]}>
                        {CONTAINER_LABELS[CONTAINER_TYPE[containerTypeKey]]}
                    </option>
                ))}
        </select>
    );
};

export default ContainerTypeSelect;

import { createRoot } from 'react-dom/client';
import store from '../../../../../js/store/store';
import { Provider } from 'react-redux';
import type { Editor } from 'tinymce';
import TinyMCEValueSelectorModal from '../../../values/selector/TinyMCEValueSelectorModal';
import type { ValueElementIdReferenceAPI } from '../../../../types';
import { formatAsValue } from '../../../values/selector/value-selector-utils';
import { getActiveAdminContainer } from '../../../../utils/display';

/**
 * @description A TinyMCE plugin that is bound to a custom buttons
 * click handler. The button is for inserting value references into
 * the editor via a valuepicker displayed in a modal.
 */
const plugin = function plugin(editor: Editor) {
    editor.ui.registry.addButton('flow_elements', {
        text: 'Insert Value',
        onAction: () => {
            const element = document.createElement('div');
            document.body.append(element);
            const root = createRoot(element);

            const onClose = () => {
                root.unmount();
                document.body.removeChild(element);
            };

            const onChange = (value: ValueElementIdReferenceAPI) => {
                const content = formatAsValue(
                    value.developerName,
                    value.typeElementPropertyDeveloperName,
                );
                editor.insertContent(content);
                editor.focus();
                onClose();
            };

            root.render(
                <Provider store={store}>
                    <TinyMCEValueSelectorModal
                        onClose={onClose}
                        onChange={onChange}
                        container={
                            (getActiveAdminContainer() ??
                                document.getElementById('generic-modal')) as HTMLElement
                        }
                    />
                </Provider>,
            );
        },
    });
};

export default plugin;

import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../../../../css/graph/note-element.less';
import { safeToLower } from '../../../../../../ts/utils/string';
import { useGraph } from '../../../GraphProvider';
import NoteDisplay from './NoteDisplay';
import NoteForm from './NoteForm';

/**
 *
 * @param {Array} canvasNotes Notes stored in Redux
 * @param {Object} canvasNode The DOMRect object representing the canvas DOM node
 * @param {String} flowId
 * @param {String} editingToken
 *
 * @description Displays notes as an overlay on the Flow canvas. Note
 * position is relative to their corresponding draggable note map element.
 */
const Notes = ({ canvasNotes, canvasNode, flowId, editingToken, searchQueries }) => {
    const { x: canvasX, y: canvasY } = canvasNode;

    const [notes, updateNotes] = useState([]);
    const { setNoteEditing, highlightedElementIds } = useGraph();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // This is for calculating where the note should be positioned on
        // the canvas in relation to the note element as the canvas is zooming and panning
        const notePositions = canvasNotes
            .filter((cn) => cn.flowId === flowId && cn.isOpen)
            .map((note) => {
                const noteDomNode = document.querySelector(
                    `[id="${note.meta.id}"] .map-element-border`,
                );
                if (noteDomNode) {
                    const { x, y, width } = noteDomNode.getBoundingClientRect();
                    return {
                        ...note,
                        top: Math.round(y - canvasY),
                        left: Math.round(x - canvasX),
                        width,
                    };
                }
                return note;
            });

        updateNotes(notePositions);
        setNoteEditing(notePositions?.some((note) => !note.readOnly));
    }, [canvasNotes, canvasNode]);

    return (
        <>
            {notes.map((note) => {
                const { left, top, width, isNew } = note;
                const { id, developerName, userContent, whoCreated, userContentDateModified } =
                    note.meta;

                const isOutOfSearch =
                    highlightedElementIds.length > 0
                        ? !highlightedElementIds.includes(id)
                        : searchQueries[flowId] &&
                          !safeToLower(developerName).includes(searchQueries[flowId]) &&
                          !safeToLower(userContent).includes(searchQueries[flowId]);
                const classes = classnames({
                    'note-wrapper': true,
                    'out-of-search': isOutOfSearch,
                });
                return (
                    <div data-testid={note.id} key={note.id}>
                        <div className={classes} style={{ left: left + width + 10, top: top - 35 }}>
                            {note.readOnly ? (
                                <NoteDisplay
                                    id={id}
                                    flowId={flowId}
                                    editingToken={editingToken}
                                    subject={developerName}
                                    content={userContent}
                                    email={whoCreated ? whoCreated.email : ''}
                                    lastUpdated={userContentDateModified}
                                />
                            ) : (
                                <NoteForm
                                    isNew={isNew}
                                    note={note.meta}
                                    flowId={flowId}
                                    editingToken={editingToken}
                                />
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default connect(({ canvasNotes, graphEditor }) => ({
    canvasNotes,
    searchQueries: graphEditor.searchQueries,
}))(Notes);

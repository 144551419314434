import type { ChartObjectType, Flowchart, INode, Point } from '../../../types';
import { subtractPositions } from '../../graph/utils';
import type { Edge } from '../../page-editor/components/dnd-utils';
import type { FlowEditorState, Selectable } from '../state/types';

const SNAP_DISTANCE = 30;

/** Round the given `value` to the `nearest` multiple of a number (default 30) */
export const snap = (value: number, nearest = SNAP_DISTANCE) =>
    Math.round(value / nearest) * nearest;

export const getSelectedObjectIds = (
    objectId: string | null,
    objectType: ChartObjectType | null,
    ctrlKey: boolean,
    flowchart: Flowchart,
    selectedObjectIds: string[],
): string[] => {
    // If pressing on an already selected object we need to keep the selection
    // to enable making multiple item selections and then dragging
    const objectIsSelected = typeof objectId === 'string' && selectedObjectIds.includes(objectId);
    let newSelection = ctrlKey || objectIsSelected ? selectedObjectIds : [];

    if (objectType === null || objectId === null) {
        return newSelection;
    }

    if (objectType === 'groupNode' || objectType === 'leafNode') {
        const node = flowchart.groupNodes[objectId] || flowchart.leafNodes[objectId];

        if (node === undefined) {
            return newSelection;
        }

        // Remove everything except nodes
        newSelection = newSelection.filter((id) => {
            const isNode =
                flowchart.leafNodes[id] !== undefined || flowchart.groupNodes[id] !== undefined;
            return isNode;
        });

        // Add new node
        newSelection.push(objectId);
    }

    if (objectType === 'edge') {
        const edge = flowchart.edges[objectId];

        if (edge === undefined) {
            return newSelection;
        }

        // Remove everything except edges
        newSelection = newSelection.filter((id) => {
            const isEdge = flowchart.edges[id] !== undefined;
            return isEdge;
        });

        // Add new edge
        newSelection.push(objectId);
    }

    // Remove potential duplicates
    return [...new Set(newSelection)];
};

const setIsSelected = <T extends Selectable>(
    collection: Record<string, T>,
    selectedObjectIds: string[],
) => {
    const newCollection: Record<string, T> = {};

    Object.entries(collection).forEach(([id, selectable]) => {
        if (selectedObjectIds.includes(id) && selectable.isSelected === false) {
            newCollection[id] = { ...collection[id], isSelected: true };
            return;
        }
        if (!selectedObjectIds.includes(id) && selectable.isSelected === true) {
            newCollection[id] = { ...collection[id], isSelected: false };
            return;
        }
        newCollection[id] = collection[id];
    });

    return newCollection;
};

export const setSelectedObjects = (flowchart: Flowchart, selectedObjectIds: string[]) => {
    flowchart.groupNodes = setIsSelected(flowchart.groupNodes, selectedObjectIds);
    flowchart.leafNodes = setIsSelected(flowchart.leafNodes, selectedObjectIds);
    flowchart.edges = setIsSelected(flowchart.edges, selectedObjectIds);

    // When multiple objects are selected
    // the selection box should be the only object
    // that shows the delete icon
    // so set canDelete to false on nodes
    Object.values(flowchart.leafNodes).forEach((leafNode) => {
        leafNode.canDelete =
            !leafNode.isStartNode && leafNode.isSelected && selectedObjectIds.length === 1;
        return leafNode;
    });

    Object.values(flowchart.groupNodes).forEach((groupNode) => {
        groupNode.canDelete = groupNode.isSelected && selectedObjectIds.length === 1;
        return groupNode;
    });

    return flowchart;
};

export const getObjectById = (flowchart: Flowchart, id: string): INode | Edge | null => {
    return flowchart.groupNodes[id] ?? flowchart.leafNodes[id] ?? flowchart.edges[id] ?? null;
};

export const dragViewBox = (dragDifference: Point, state: FlowEditorState) => {
    if (state.dragSnapshot === null) {
        return;
    }

    state.flowchart.viewBox = subtractPositions(state.flowchart.viewBox, dragDifference);
};

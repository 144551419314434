import type { ReleasedSnapshot } from '../../../types';
import { getVersionName } from './utils';
import { formatDistanceStrict } from 'date-fns';

interface Props {
    snapshot: ReleasedSnapshot;
}

const ReleaseDate = ({ snapshot }: Props) => (
    <span
        className="quiet"
        title={`Snapshot Created: ${new Date(snapshot.dateCreated).toLocaleString(undefined, {
            dateStyle: 'medium',
            timeStyle: 'medium',
        })}`}
    >
        {getVersionName(snapshot)}{' '}
        {formatDistanceStrict(new Date(snapshot.dateCreated), Date.now(), {
            addSuffix: true,
        })}
    </span>
);

export default ReleaseDate;

import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const Radio = ({ label, isRequired }: { label: string; isRequired: boolean }) => {
    return (
        <div data-testid="component-preview-radio" className="component-preview-input">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            <div className="radio">
                <label htmlFor="example1">
                    <input type="radio" id="example1" name="example radio" />
                    Radio 1
                </label>
            </div>
            <div className="radio">
                <label htmlFor="example2">
                    <input type="radio" id="example2" name="example radio" />
                    Radio 2
                </label>
            </div>
            <div className="radio">
                <label htmlFor="example3">
                    <input type="radio" id="example3" name="example radio" />
                    Radio 3
                </label>
            </div>
        </div>
    );
};

export default Radio;

import { Trash } from '@phosphor-icons/react';
import { mergeRight } from 'ramda';
import {
    type ChangeEventHandler,
    type ElementRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { connect } from 'react-redux';
import { notifyError, notifySuccess } from '../../../js/actions/reduxActions/notification';
import SearchInput from '../generic/SearchInput';
import { SYSTEM_ROLES } from '../../constants';
import {
    getAllUsersForCurrentTenant,
    getTenantUser,
    removeUserFromTenant,
    updateTenantUser,
} from '../../sources/user';
import translations from '../../translations';
import type { NotifyError, UserAPI } from '../../types';
import { stringReplace } from '../../utils/string';
import FormGroup from '../generic/FormGroup';
import Table, { type TableColumnList } from '../generic/Table';
import Modal from '../generic/modal/GenericModal';
import AddUsersModal from './AddUsersModal';

interface UserListProps {
    notifyError: NotifyError;
    userId: string;
}

const UserList = ({ notifyError, userId }: UserListProps) => {
    const [query, setQuery] = useState('');
    const [users, setUsers] = useState<UserAPI[]>([]);
    const [paging, setPaging] = useState({ page: 1, pageSize: 20, total: 0 });
    const [updatedUser, setUpdatedUser] = useState<{
        currentRole: string;
        newRole: string;
        user: UserAPI;
    } | null>(null);
    const [selectedUser, setSelectedUser] = useState<UserAPI | null>(null);
    const [showRemoveUserConfirm, setShowRemoveUserConfirm] = useState(false);
    const [showUpdateRoleConfirm, setShowUpdateRoleConfirm] = useState(false);
    const [showAddUsersModal, setShowAddUsersModal] = useState(false);
    const [commentsInput, setComments] = useState('');
    const commentsInputRef = useRef<ElementRef<'textarea'>>(null);

    const fetchAndUpdateUsers = useCallback(async () => {
        const fetchedUsers = await getAllUsersForCurrentTenant();

        setUsers(fetchedUsers);

        const newPaging = { ...paging, total: fetchedUsers.length };

        if (JSON.stringify(newPaging) === JSON.stringify(paging)) {
            return;
        }

        setPaging(newPaging);
    }, [paging]);

    useEffect(() => {
        fetchAndUpdateUsers();
    }, [fetchAndUpdateUsers]);

    const onPage = (pageNumber: number) => {
        setPaging(mergeRight(paging, { page: pageNumber }));
    };

    const onSearch = (query: string) => {
        onPage(1);
        setQuery(query);
    };

    const onRemoveUser = (user: UserAPI) => {
        setSelectedUser(user);
        setShowRemoveUserConfirm(true);
    };

    // Update the user's role in component state.
    // This is so that the role select box has the correct selection
    const updateUsersToDisplay = (id: string, updatedUser: UserAPI) => {
        return users.map((user) => (user.id === id ? updatedUser : user));
    };

    const onUserRoleChange = async (userId: string, selectedRole: string) => {
        const newRole = Object.values(SYSTEM_ROLES).find(
            ({ developerName: name }) => name === selectedRole,
        );

        if (!newRole) {
            throw new Error(`Unknown role selected "${selectedRole}"`);
        }

        try {
            const userToUpdate = await getTenantUser(userId);

            const updatedUser = {
                user: { ...userToUpdate, role: newRole }, // Set the new role for the user
                currentRole: userToUpdate.role.friendlyName,
                newRole: newRole.friendlyName,
            };

            setUsers(updateUsersToDisplay(userId, updatedUser.user));
            setUpdatedUser(updatedUser);
            setShowUpdateRoleConfirm(true);
        } catch (error) {
            notifyError(error);
        }
    };

    const onRemoveUserConfirmed = async () => {
        if (!selectedUser) {
            throw new Error('No user selected to remove');
        }

        try {
            await removeUserFromTenant({
                id: selectedUser.id,
                comments: commentsInput,
            });

            setSelectedUser(null);
            setShowRemoveUserConfirm(false);
            setComments('');
            if (commentsInputRef.current) {
                commentsInputRef.current.value = '';
            }

            if (selectedUser.id === userId) {
                // If the logged in user was removed from this tenant, refresh the page so they can select another tenant
                window.document.location.reload();
            } else {
                // Otherwise update the users list to reflect the changes
                await fetchAndUpdateUsers();
            }
        } catch (error) {
            notifyError(error);
        }
    };

    const updateRole = async () => {
        if (!updatedUser) {
            throw new Error('No user selected to update');
        }

        try {
            const {
                user,
                user: { id: userId },
            } = updatedUser;

            await updateTenantUser({
                userId,
                userPayload: user,
                comments: commentsInput,
            });

            notifySuccess(translations.TENANT_update_role_success_message);

            setUsers(updateUsersToDisplay(userId, user));
            setUpdatedUser(null);
            setShowUpdateRoleConfirm(false);
            setComments('');
            if (commentsInputRef.current) {
                commentsInputRef.current.value = '';
            }
        } catch (error) {
            notifyError(error);
        }
    };

    const handleCommentsChange: ChangeEventHandler<HTMLTextAreaElement> = ({
        target: { value },
    }) => {
        setComments(value);
    };

    const hideRemoveUserModal = () => setShowRemoveUserConfirm(false);

    const hideUpdateRoleModal = () => {
        setShowUpdateRoleConfirm(false);
        setUpdatedUser(null);

        // This is so the role select box get repopulated with the
        // correct role selection if the role update is cancelled
        fetchAndUpdateUsers();
    };

    const userColumns: TableColumnList<UserAPI> = [
        {
            renderHeader: () => translations.COMMON_TABLE_email,
            renderCell: ({ item }) => item.email,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_first_name,
            renderCell: ({ item }) => item.firstName,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_last_name,
            renderCell: ({ item }) => item.lastName,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_role,
            renderCell: ({ item: { role: currentRole, id: userId }, rowIndex }) => [
                <select
                    key={rowIndex}
                    onChange={({ target: { value: selectedRole } }) =>
                        onUserRoleChange(userId, selectedRole)
                    }
                    value={currentRole.developerName ?? ''}
                >
                    {(
                        Object.keys(SYSTEM_ROLES).filter(
                            (key) => key !== 'service_user',
                        ) as (keyof Omit<typeof SYSTEM_ROLES, 'service_user'>)[]
                    ).map((key) => {
                        const { roleId, developerName, friendlyName } = SYSTEM_ROLES[key];
                        return (
                            <option key={roleId} value={developerName}>
                                {friendlyName}
                            </option>
                        );
                    })}
                </select>,
            ],
        },
        {
            renderHeader: () => translations.TENANT_users_table_sso_header_text,
            renderCell: ({ item: { isSso }, rowIndex }) => [
                <span key={rowIndex}>
                    {isSso ? translations.TENANT_users_table_is_sso_text : ''}
                </span>,
            ],
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item: user }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={stringReplace(translations.COMMON_delete_param, {
                            param: user.firstName,
                        })}
                        className="table-icon table-icon-delete"
                        aria-label={stringReplace(translations.COMMON_delete_param, {
                            param: user.firstName,
                        })}
                        onClick={() => onRemoveUser(user)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    const containsQuery = (value: string) => value.toLowerCase().includes(query.toLowerCase());
    const queriedUsers = users.filter(
        ({ firstName, lastName, email }) =>
            !query ||
            (firstName && containsQuery(firstName)) ||
            (lastName && containsQuery(lastName)) ||
            containsQuery(email),
    );

    const { firstName, lastName, email } = selectedUser ?? {};
    const { currentRole, user, newRole } = updatedUser ?? {};

    return (
        <>
            <Modal
                show={showRemoveUserConfirm}
                onHide={hideRemoveUserModal}
                title={translations.TENANT_remove_confirmation_title}
                renderBody={() => (
                    <>
                        <p>
                            {stringReplace(translations.TENANT_remove_confirmation_message, {
                                firstName,
                                lastName,
                                email,
                            })}
                        </p>
                        <FormGroup
                            label={translations.TENANT_add_users_modal_comments_input_label}
                            htmlFor="add-users-modal-user-comments-input"
                            isRequired={false}
                        >
                            <textarea
                                id="add-users-modal-user-comments-input"
                                className="form-control"
                                ref={commentsInputRef}
                                onChange={handleCommentsChange}
                                rows={3}
                            />
                        </FormGroup>
                    </>
                )}
                className="config-modal"
                renderFooter={() => (
                    <>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={hideRemoveUserModal}
                        >
                            {translations.COMMON_cancel}
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={onRemoveUserConfirmed}
                        >
                            {translations.COMMON_remove}
                        </button>
                    </>
                )}
            />

            <Modal
                show={showUpdateRoleConfirm}
                onHide={hideUpdateRoleModal}
                title={translations.TENANT_update_role_title}
                renderBody={() => (
                    <>
                        <p>
                            {stringReplace(translations.TENANT_update_role_message, {
                                currentRole,
                                email: user?.email ?? null,
                                newRole,
                            })}
                        </p>
                        <FormGroup
                            label={translations.TENANT_add_users_modal_comments_input_label}
                            htmlFor="assign-new_role-modal-user-comments-input"
                            isRequired={false}
                        >
                            <textarea
                                id="assign-new_role-modal-user-comments-input"
                                className="form-control"
                                ref={commentsInputRef}
                                onChange={handleCommentsChange}
                                rows={3}
                            />
                        </FormGroup>
                    </>
                )}
                className="config-modal"
                renderFooter={() => (
                    <>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={hideUpdateRoleModal}
                        >
                            {translations.COMMON_cancel}
                        </button>
                        <button type="button" className="btn btn-primary" onClick={updateRole}>
                            {translations.TENANT_assign_new_role_confirm_button_label}
                        </button>
                    </>
                )}
            />

            <div className="flex">
                <button
                    onClick={() => setShowAddUsersModal(true)}
                    className="btn btn-sm btn-success"
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    {translations.TENANT_add_users_button_label}
                </button>
                <SearchInput value={query} onChange={onSearch} className="margin-left" />
            </div>
            <Table
                wrapperClassName="margin-top"
                columns={userColumns}
                items={queriedUsers}
                pagination={true}
            />
            <a
                className="link"
                rel="noopener noreferrer"
                target="_blank"
                href="https://help.boomi.com/csh?context=GUID-ad679279-a8fd-4b6e-bccc-e99bbd2c3c05"
            >
                {translations.TENANT_role_access_info_link_text}
            </a>
            <AddUsersModal
                show={showAddUsersModal}
                onClose={() => setShowAddUsersModal(false)}
                onUpdateUsers={fetchAndUpdateUsers}
            />
        </>
    );
};

export default connect(null, { notifyError })(UserList);

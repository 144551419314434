import { useState } from 'react';
import { View } from '../InsightsDashboard';
import classNames from 'classnames';
import AnomalyBarChart from '../../anomalyDetection/AnomalyBarChart';

interface Props {
    setCurrentView: (view: View) => void;
}

const AnomalySummary = ({ setCurrentView }: Props) => {
    const [isHovering, setIsHovering] = useState(false);

    const tileClasses = classNames(
        'insights-chart-wrapper',
        'insights-tile',
        'anomaly-detection',
        isHovering ? 'insights-tile-hover' : '',
    );

    return (
        <div
            className={tileClasses}
            onClick={() => setCurrentView(View.anomaly)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    setCurrentView(View.anomaly);
                }
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            role="button"
            tabIndex={0}
            data-testid="anomaly-dashboard-tile"
        >
            <AnomalyBarChart isSummary setParentHovering={setIsHovering} />
        </div>
    );
};

export default AnomalySummary;

export default {
    NOTIFICATION_ADD: 'NOTIFICATION_ADD',
    NOTIFICATION_REMOVE: 'NOTIFICATION_REMOVE',
    REMOVE_ALL_NOTIFICATIONS: 'REMOVE_ALL_NOTIFICATIONS',

    CURRENT_USER_SET: 'CURRENT_USER_SET',
    CURRENT_USER_REMOVE: 'CURRENT_USER_REMOVE',
    CURRENT_USER_SIGN_OUT: 'CURRENT_USER_SIGN_OUT',

    CURRENT_TENANT_SET: 'CURRENT_TENANT_SET',
    CURRENT_TENANT_SECURITY_SETTING_UPDATE: 'CURRENT_TENANT_SECURITY_SETTING_UPDATE',
    CURRENT_TENANT_SAML_SETTING_UPDATE: 'CURRENT_TENANT_SAML_SETTING_UPDATE',
    CURRENT_TENANT_OTLP_SETTING_UPDATE: 'CURRENT_TENANT_OTLP_SETTING_UPDATE',
    CURRENT_TENANT_DEVELOPER_NAME_UPDATE: 'CURRENT_TENANT_DEVELOPER_NAME_UPDATE',
    CURRENT_TENANT_SUMMARY_UPDATE: 'CURRENT_TENANT_SUMMARY_UPDATE',
    CURRENT_TENANT_IP_ENTRY_CONFIRM: 'CURRENT_TENANT_IP_ENTRY_CONFIRM',
    CURRENT_TENANT_IP_RANGE_ADD: 'CURRENT_TENANT_IP_RANGE_ADD',
    CURRENT_TENANT_IP_RANGE_REMOVE: 'CURRENT_TENANT_IP_RANGE_REMOVE',
    CURRENT_TENANT_SERVICE_INVOKER_LOGGING_UPDATE: 'CURRENT_TENANT_SERVICE_INVOKER_LOGGING_UPDATE',
    CURRENT_TENANT_REPORTING_ENDPOINT_UPDATE: 'CURRENT_TENANT_REPORTING_ENDPOINT_UPDATE',
    CURRENT_TENANT_ENVIRONMENT_SETTING_UPDATE: 'CURRENT_TENANT_ENVIRONMENT_SETTING_UPDATE',
    CURRENT_TENANT_INTEGRATION_ACCOUNT_ADD: 'CURRENT_TENANT_INTEGRATION_ACCOUNT_ADD',
    CURRENT_TENANT_INTEGRATION_ACCOUNT_REMOVE: 'CURRENT_TENANT_INTEGRATION_ACCOUNT_REMOVE',
    CURRENT_TENANT_THEME_SETTING_UPDATE: 'CURRENT_TENANT_THEME_SETTING_UPDATE',

    FLOW_CONFIGURATIONS_REGISTER: 'FLOW_CONFIGURATIONS_REGISTER',
    FLOW_CONFIGURATIONS_UNREGISTER: 'FLOW_CONFIGURATIONS_UNREGISTER',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_NAME: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_NAME',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_ACCESS: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_ACCESS',
    FLOW_CONFIGURATIONS_CHANGE_SOCIAL_FEED: 'FLOW_CONFIGURATIONS_CHANGE_SOCIAL_FEED',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_HISTORICAL_NAVIGATION:
        'FLOW_CONFIGURATIONS_CHANGE_FLOW_HISTORICAL_NAVIGATION',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_JUMPING: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_JUMPING',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_STATE_LIFETIME:
        'FLOW_CONFIGURATIONS_CHANGE_FLOW_STATE_LIFETIME',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_IDLE_STATE_LIFETIME:
        'FLOW_CONFIGURATIONS_CHANGE_FLOW_IDLE_STATE_LIFETIME',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_COMMENT: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_COMMENT',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_SERVICE: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_SERVICE',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP_ATTRIBUTE:
        'FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP_ATTRIBUTE',
    FLOW_CONFIGURATIONS_DELETE_FLOW_GROUP: 'FLOW_CONFIGURATIONS_DELETE_FLOW_GROUP',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_USER: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_USER',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_USER_ATTRIBUTE:
        'FLOW_CONFIGURATIONS_CHANGE_FLOW_USER_ATTRIBUTE',
    FLOW_CONFIGURATIONS_DELETE_FLOW_USER: 'FLOW_CONFIGURATIONS_DELETE_FLOW_USER',
    FLOW_CONFIGURATIONS_RESTRICTION_ACTIVE_UPDATE: 'FLOW_CONFIGURATIONS_RESTRICTION_ACTIVE_UPDATE',
    FLOW_CONFIGURATIONS_RESTRICTION_ADD: 'FLOW_CONFIGURATIONS_RESTRICTION_ADD',
    FLOW_CONFIGURATIONS_RESTRICTION_REMOVE: 'FLOW_CONFIGURATIONS_RESTRICTION_REMOVE',
    FLOW_SET_FLOW_USERS_GROUPS_SELECTION: 'FLOW_SET_FLOW_USERS_GROUPS_SELECTION',
    FLOW_ADD_FLOW_USERS_GROUPS_SELECTION: 'FLOW_ADD_FLOW_USERS_GROUPS_SELECTION',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_IDP: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_IDP',
    FLOW_CONFIGURATIONS_CHANGE_IDP_GROUPS: 'FLOW_CONFIGURATIONS_CHANGE_IDP_GROUPS',
    FLOW_CONFIGURATIONS_CHANGE_IDP_USERS: 'FLOW_CONFIGURATIONS_CHANGE_IDP_USERS',

    DATASTORE_INFORMATION_MIGRATION_STATUS_SET: 'DATASTORE_INFORMATION_MIGRATION_STATUS_SET',

    FETCH_API_ENDPOINTS: 'FETCH_API_ENDPOINTS',
    SET_API_ENDPOINTS: 'SET_API_ENDPOINTS',
    SET_SELECTED_FLOW: 'SET_SELECTED_FLOW',

    SERVICES_SET_ALL: 'SERVICES_SET_ALL',

    ADMIN_USERS_SET: 'ADMIN_USERS_SET',

    FLOW_LIBRARY_LOADING: 'FLOW_LIBRARY_LOADING',
    FLOW_LIBRARY_SET: 'FLOW_LIBRARY_SET',

    GRAPH_SET_HOVER_MAP_ID: 'GRAPH_SET_HOVER_MAP_ID',
    GRAPH_SET_HOVER_GROUP_ID: 'GRAPH_SET_HOVER_GROUP_ID',
    GRAPH_SET_DRAG_OVER_GROUP_ID: 'GRAPH_SET_DRAG_OVER_GROUP_ID',
    GRAPH_SET_DRAGGING_DATA: 'GRAPH_SET_DRAGGING_DATA',
    GRAPH_SET_CONTEXT_MENU_DATA: 'GRAPH_SET_CONTEXT_MENU_DATA',
    GRAPH_SET_SEARCH: 'GRAPH_SET_SEARCH',
    GRAPH_SET_CLIPBOARD: 'GRAPH_SET_CLIPBOARD',

    FLOW_LIST_LOADING: 'FLOW_LIST_LOADING',
    FLOW_LIST_RESPONSE: 'FLOW_LIST_RESPONSE',
    FLOWS_ACTIVATED_RESPONSE: 'FLOWS_ACTIVATED_RESPONSE',
    FLOW_CLOSE: 'FLOW_CLOSE',
    FLOW_UPDATE_LABELS: 'FLOW_UPDATE_LABELS',
    FLOW_DELETING: 'FLOW_DELETING',
    FLOW_SELECT_EXPORT: 'FLOW_SELECT_EXPORT',
    FLOW_INCLUDE_PASSWORDS: 'FLOW_INCLUDE_PASSWORDS',
    FLOW_SET_EXPORTED_ESCAPED_JSON: 'FLOW_SET_EXPORTED_ESCAPED_JSON',
    FLOW_SET_GENERATED_SHARED_TOKEN: 'FLOW_SET_GENERATED_SHARED_TOKEN',
    FLOW_SET_IMPORT_TOKEN: 'FLOW_SET_IMPORT_TOKEN',
    FLOW_SET_IMPORTED_PACKAGE: 'FLOW_SET_IMPORTED_PACKAGE',
    FLOW_SHOW_IMPORT_CONFIRMATION: 'FLOW_SHOW_IMPORT_CONFIRMATION',
    FLOW_IMPORT_SUCCESS: 'FLOW_IMPORT_SUCCESS',
    FLOW_IMPORT_CONFLICT: 'FLOW_IMPORT_CONFLICT', // Import will be overwriting existing shared elements

    USER_SET: 'USER_SET',

    SET_TYPES: 'SET_TYPES',
    LOADED_TYPES: 'LOADED_TYPES',
    LOADING_TYPES: 'LOADING_TYPES',
    SET_VALUE_REFERENCES: 'SET_VALUE_REFERENCES',

    VALUE_PICKER_FILTER_SET: 'VALUE_PICKER_FILTER_SET',
    VALUE_PICKER_FILTER_REMOVE: 'VALUE_PICKER_FILTER_REMOVE',
    VALUE_PICKER_CLEAR_FILTERS: 'CLEAR_FILTERS',

    VALUE_EDITOR_LOADING: 'VALUE_EDITOR_LOADING',
    VALUE_EDITOR_LOADED: 'VALUE_EDITOR_LOADED',
    VALUE_EDITOR_SAVING: 'VALUE_EDITOR_SAVING',
    VALUE_EDITOR_SAVED: 'VALUE_EDITOR_SAVED',
    VALUE_EDITOR_SET_DUPLICATE_WARNING: 'VALUE_EDITOR_SET_DUPLICATE_WARNING',

    // Persist value object
    VALUE_EDITOR_SAVE_VALUE: 'VALUE_EDITOR_SAVE_VALUE',
    // Individual value field setters
    VALUE_EDITOR_SET_VALUE: 'VALUE_EDITOR_SET_VALUE',
    VALUE_EDITOR_SET_VALUE_DEVELOPER_NAME: 'VALUE_EDITOR_SET_VALUE_DEVELOPER_NAME',
    VALUE_EDITOR_SET_VALUE_CONTENT_TYPE: 'VALUE_EDITOR_SET_VALUE_CONTENT_TYPE',
    VALUE_EDITOR_SET_VALUE_TYPE_ELEMENT_ID: 'VALUE_EDITOR_SET_VALUE_TYPE_ELEMENT_ID',
    VALUE_EDITOR_SET_VALUE_DEFAULT_CONTENT_VALUE: 'VALUE_EDITOR_SET_VALUE_DEFAULT_CONTENT_VALUE',
    VALUE_EDITOR_SET_VALUE_CONTENT_FORMAT: 'VALUE_EDITOR_SET_VALUE_CONTENT_FORMAT',
    VALUE_EDITOR_SET_VALUE_IS_FIXED: 'VALUE_EDITOR_SET_VALUE_IS_FIXED',
    VALUE_EDITOR_SET_VALUE_ACCESS: 'VALUE_EDITOR_SET_VALUE_ACCESS',
    VALUE_EDITOR_SET_VALUE_IS_VERSIONLESS: 'VALUE_EDITOR_SET_VALUE_IS_VERSIONLESS',
    VALUE_EDITOR_SET_VALUE_IS_SECRET: 'VALUE_EDITOR_SET_VALUE_IS_SECRET',
    VALUE_EDITOR_SET_VALUE_DEVELOPER_SUMMARY: 'VALUE_EDITOR_SET_VALUE_DEVELOPER_SUMMARY',
    // Update default values for Types and Lists
    VALUE_EDITOR_SET_VALUE_OBJECT_DATA_CONTENT_VALUE:
        'VALUE_EDITOR_SET_VALUE_OBJECT_DATA_CONTENT_VALUE',
    // Add new objectData
    VALUE_EDITOR_SET_VALUE_OBJECT_DATA: 'VALUE_EDITOR_SET_VALUE_OBJECT_DATA',
    VALUE_EDITOR_REPLACE_OBJECT_DATA: 'VALUE_EDITOR_REPLACE_OBJECT_DATA',
    // Remove all default values for the current Type
    VALUE_EDITOR_DELETE_OBJECT_DATA: 'VALUE_EDITOR_DELETE_OBJECT_DATA',
    // Limit display of properties for a Type furing data entry
    VALUE_EDITOR_SET_PROPERTY_FILTER: 'VALUE_EDITOR_SET_PROPERTY_FILTER',
    // Breadcrumb management
    VALUE_EDITOR_PUSH_BREADCRUMB: 'VALUE_EDITOR_PUSH_BREADCRUMB',
    VALUE_EDITOR_POP_BREADCRUMB: 'VALUE_EDITOR_POP_BREADCRUMB',
    VALUE_EDITOR_SET_ACTIVE_BREADCRUMB: 'VALUE_EDITOR_SET_ACTIVE_BREADCRUMB',

    SIDEBAR_TOGGLE: 'SIDEBAR_TOGGLE',

    ORGANIZATION_SET_RECEIVED_TENANT_INVITATIONS: 'ORGANIZATION_SET_RECEIVED_TENANT_INVITATIONS',
    ORGANIZATION_SET_RECEIVED_USER_INVITATIONS: 'ORGANIZATION_SET_RECEIVED_USER_INVITATIONS',
    ORGANIZATION_SET_SENT_TENANT_INVITATIONS: 'ORGANIZATION_SET_SENT_TENANT_INVITATIONS',
    ORGANIZATION_SET_SENT_USER_INVITATIONS: 'ORGANIZATION_SET_SENT_USER_INVITATIONS',
    ORGANIZATION_TENANTS_SET_ALL: 'ORGANIZATION_TENANTS_SET_ALL',
    ORGANIZATION_USERS_SET_ALL: 'ORGANIZATION_USERS_SET_ALL',

    LOCAL_RUNTIME_SET: 'LOCAL_RUNTIME_SET',
    LOCAL_RUNTIME_CREATE: 'LOCAL_RUNTIME_CREATE',
    LOCAL_RUNTIME_SHOW_LOADER: 'LOCAL_RUNTIME_SHOW_LOADER',
    LOCAL_RUNTIME_UPDATE: 'LOCAL_RUNTIME_UPDATE',
    LOCAL_RUNTIME_DELETING: 'LOCAL_RUNTIME_DELETING',
    LOCAL_RUNTIME_DELETE: 'LOCAL_RUNTIME_DELETE',
    LOCAL_RUNTIME_FOR_TENANT_SET: 'LOCAL_RUNTIME_FOR_TENANT_SET',

    SET_RECENT_VALUES: 'SET_RECENT_VALUES',

    NOTE_ADD: 'NOTE_ADD',
    NOTE_SET_ALL: 'NOTE_SET_ALL',
    NOTE_UPDATE: 'NOTE_UPDATE',
    NOTE_EDIT: 'NOTE_EDIT',
    NOTE_TOGGLE_VISIBILITY: 'NOTE_TOGGLE_VISIBILITY',
    NOTE_DELETE: 'NOTE_DELETE',
    NOTE_DELETE_MULTIPLE: 'NOTE_DELETE_MULTIPLE',

    TAB_OPEN: 'TAB_OPEN',
    TAB_CLOSE: 'TAB_CLOSE',
    TAB_CLOSE_ALL: 'TAB_CLOSE_ALL',
    TAB_SET_TITLE: 'TAB_SET_TITLE',
    TAB_UPDATE: 'TAB_UPDATE',

    FEATURE_FLAGS_SET_ALL: 'FEATURE_FLAGS_SET_ALL',
    FEATURE_FLAGS_SET_LOADER: 'FEATURE_FLAGS_SET_LOADER',
};

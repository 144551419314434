import { Component } from 'react';
import FlowList from './FlowList';
import StateList from './StateList';
import Breadcrumb from '../../../ts/components/Breadcrumb';
import '../../../../css/dashboard.less';
import ServiceInvokerRequestList from './ServiceInvokerRequestList';

const views = {
    FLOWLIST: 'FLOWLIST',
    STATELIST: 'STATELIST',
    SERVICEINVOKERREQUESTSLIST: 'SERVICEINVOKERREQUESTSLIST',
};

/**
 * @description The root dashboard component
 */
class Dashboard extends Component {
    constructor() {
        super();

        this.state = {
            view: views.FLOWLIST,
            selectedFlow: null,
            selectedState: null,
        };

        this.showStateList = this.showStateList.bind(this);
    }

    showServiceInvokerRequestsList = (selectedState) => {
        this.setState({
            view: views.SERVICEINVOKERREQUESTSLIST,
            selectedState,
        });
    };

    showStateList(selectedFlow) {
        this.setState({
            view: views.STATELIST,
            selectedFlow,
        });
    }

    render() {
        const activeView = this.state.view;

        const trail = [
            {
                id: views.FLOWLIST,
                content: 'Dashboard',
                onClick:
                    activeView === views.FLOWLIST
                        ? () => undefined
                        : () => this.setState({ view: views.FLOWLIST }),
            },
            {
                id: views.STATELIST,
                content: 'Insights',
                onClick:
                    activeView === views.STATELIST
                        ? () => undefined
                        : () => this.setState({ view: views.STATELIST }),
            },
            {
                id: views.SERVICEINVOKERREQUESTSLIST,
                content: 'Connector Log',
                onClick:
                    activeView === views.SERVICEINVOKERREQUESTSLIST
                        ? () => undefined
                        : () => this.setState({ view: views.SERVICEINVOKERREQUESTSLIST }),
            },
        ];

        return (
            <div className="dashboard admin-page">
                <Breadcrumb trail={trail} activeItemId={activeView} />

                {activeView === views.FLOWLIST ? (
                    <FlowList tenantId={this.props.tenantId} onFlowClick={this.showStateList} />
                ) : null}

                {activeView === views.STATELIST ? (
                    <StateList
                        tenantId={this.props.tenantId}
                        onServiceInvokerClick={this.showServiceInvokerRequestsList}
                        selectedFlow={this.state.selectedFlow}
                    />
                ) : null}

                {activeView === views.SERVICEINVOKERREQUESTSLIST ? (
                    <ServiceInvokerRequestList
                        tenantId={this.props.tenantId}
                        selectedState={this.state.selectedState}
                    />
                ) : null}
            </div>
        );
    }
}

export default Dashboard;

import { useCallback, useEffect, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { notifyError } from '../../../js/actions/reduxActions/notification';
import { getOrgTenantUsers } from '../../sources/organization';
import translations from '../../translations';
import type { UserAPI } from '../../types';
import Table from '../generic/Table';

type TenantUsersDetailsProps = {
    tenantId: string;
} & ConnectedProps<typeof connector>;

const connector = connect(null, {
    notifyError,
});

const TenantUsersDetails = ({ tenantId, notifyError }: TenantUsersDetailsProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tenantUsers, setTenantUsers] = useState<UserAPI[]>([]);
    const [page, setPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);

    const fetchOrgTenantUsers = useCallback(
        async ({ page = 1, pageSize = 20 }: { page?: number; pageSize?: number } = {}) => {
            try {
                setIsLoading(true);

                const {
                    items: users,
                    _meta: { total },
                } = await getOrgTenantUsers({
                    tenantId,
                    page,
                    pageSize,
                });

                setTenantUsers(users);
                setTotalUsers(total);
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [notifyError, tenantId],
    );

    useEffect(() => {
        fetchOrgTenantUsers();
    }, [fetchOrgTenantUsers]);

    const handleChangePage = (page: number) => {
        setPage(page);
        fetchOrgTenantUsers({ page });
    };

    return (
        <Table
            testId="tenant-users-details"
            isLoading={isLoading}
            columns={[
                {
                    renderHeader: () => translations.COMMON_TABLE_email,
                    renderCell: ({ item: user }) => (
                        <span title={`ID: ${user.id}`}>{user.email}</span>
                    ),
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_first_name,
                    renderCell: ({ item: user }) => user.firstName,
                    size: '175px',
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_last_name,
                    renderCell: ({ item: user }) => user.lastName,
                    size: '175px',
                },
                {
                    renderHeader: () => translations.COMMON_TABLE_role,
                    renderCell: ({ item: user }) => user.role.friendlyName,
                },
            ]}
            items={tenantUsers}
            pagination={{
                page,
                total: totalUsers,
                pageSize: 20,
                changePage: handleChangePage,
            }}
        />
    );
};

export default connector(TenantUsersDetails);

import { memo } from 'react';
import '../../../../css/collaboration/collaboration.css';
import type { User } from '../../collaboration/CollaborationProvider';
import { name } from './utils';
import UserCircle from './UserCircle';

interface Props {
    users: Record<string, User>;
    presentUsers?: string[];
    currentUserId: string;
    onUserDoubleClick?: (userId: string) => void;
}

const Presence = ({ users, presentUsers, currentUserId, onUserDoubleClick }: Props) => {
    if (!(users && presentUsers)) {
        return null;
    }

    const usersExcludingMe = presentUsers.filter((id) => id !== currentUserId);

    const icons = usersExcludingMe
        .slice(0, 5)
        .map((id: string, index: number) => (
            <UserCircle
                key={id}
                user={users[id]}
                index={index}
                onUserDoubleClick={onUserDoubleClick}
            />
        ));

    if (usersExcludingMe.length > 5) {
        icons.push(
            <div
                key="more"
                className="collaboration-user collaboration-user-more"
                title={usersExcludingMe
                    .slice(-5)
                    .map((id) => name(users[id]))
                    .join('\n')}
            >
                {`+${usersExcludingMe.length - 5}`}
            </div>,
        );
    }

    icons.reverse();

    return <div className="collaboration-users">{icons}</div>;
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    return prevProps.presentUsers?.length === nextProps.presentUsers?.length;
};

export default memo(Presence, areEqual);

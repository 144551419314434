import { URL_TARGET } from '../../components/flow/constants';
import translations from '../../translations';
import type { ValueElementIdAPI } from '../../types';
import FormGroup from '../generic/FormGroup';
import ValueSelectorModal from '../values/selector/ValueSelectorModal';

interface Props {
    hasUrlError: boolean;
    url: ValueElementIdAPI | null;
    urlTarget: string | null;
    onUrlChanged: (value: ValueElementIdAPI | null) => void;
    updateUrlTarget: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    controlWidth: boolean;
}

const urlTargets = [URL_TARGET.BLANK, URL_TARGET.SELF];

const NavigationUrlInputs = (props: Props) => {
    return (
        <div className="input-group-wrapper">
            <FormGroup
                label="URL "
                className={`input-group-item${props.hasUrlError ? ' has-error' : ''}${
                    props.controlWidth ? ' form-control-width' : ''
                }`}
                htmlFor="navigate-url"
            >
                <ValueSelectorModal
                    value={props.url}
                    onChange={props.onUrlChanged}
                    includeSystemValues={true}
                    contentType="ContentString"
                    container={null}
                />
                {props.hasUrlError ? (
                    <div className="help-block error-state">A URL is required</div>
                ) : null}
            </FormGroup>
            <FormGroup label="URL Target " className={'input-group-item'} htmlFor="url-target">
                <select
                    id="url-target"
                    className={`form-control ${props.controlWidth ? 'form-control-width' : ''}`}
                    value={props.urlTarget as string | readonly string[] | number | undefined}
                    onChange={props.updateUrlTarget}
                    required={true}
                >
                    {urlTargets.map((target) => {
                        return (
                            <option key={target} value={target}>
                                {target}
                            </option>
                        );
                    })}
                </select>
                {props.urlTarget === URL_TARGET.BLANK && (
                    <p className="help-block">{translations.URL_TARGET_blank_help_text}</p>
                )}
                {props.urlTarget === URL_TARGET.SELF && (
                    <p className="help-block">{translations.URL_TARGET_self_help_text}</p>
                )}
            </FormGroup>
        </div>
    );
};

export default NavigationUrlInputs;

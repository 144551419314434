import type { CriteriaType, CriteriaTypeLabel } from '../types';

interface Rule {
    criteria: CriteriaType;
    label: CriteriaTypeLabel;
}

const RULES: Readonly<Record<string, Rule>> = {
    equals: { criteria: 'EQUAL', label: 'Equals' },
    notEquals: { criteria: 'NOT_EQUAL', label: 'Not equal' },
    greaterThan: { criteria: 'GREATER_THAN', label: 'Greater than' },
    greaterThanEqualTo: { criteria: 'GREATER_THAN_OR_EQUAL', label: 'Greater than or equal to' },
    lessThan: { criteria: 'LESS_THAN', label: 'Less than' },
    lessThanEqualTo: { criteria: 'LESS_THAN_OR_EQUAL', label: 'Less than or equal to' },
    startsWith: { criteria: 'STARTS_WITH', label: 'Starts' },
    endsWith: { criteria: 'ENDS_WITH', label: 'Ends' },
    contains: { criteria: 'CONTAINS', label: 'Contains' },
    isEmpty: { criteria: 'IS_EMPTY', label: 'Empty' },
    allEqual: { criteria: 'ALL_EQUAL', label: 'All Equal' },
    anyEqual: { criteria: 'ANY_EQUAL', label: 'Any Equal' },
};

export const criteriaOptions: Readonly<Record<string, Rule[]>> = {
    CONTENTSTRING: [
        RULES['equals'],
        RULES['notEquals'],
        RULES['greaterThan'],
        RULES['greaterThanEqualTo'],
        RULES['lessThan'],
        RULES['lessThanEqualTo'],
        RULES['startsWith'],
        RULES['endsWith'],
        RULES['contains'],
        RULES['isEmpty'],
    ],
    CONTENTBOOLEAN: [RULES['equals'], RULES['notEquals'], RULES['isEmpty']],
    CONTENTCONTENT: [
        RULES['equals'],
        RULES['notEquals'],
        RULES['greaterThan'],
        RULES['greaterThanEqualTo'],
        RULES['lessThan'],
        RULES['lessThanEqualTo'],
        RULES['startsWith'],
        RULES['endsWith'],
        RULES['contains'],
        RULES['isEmpty'],
    ],
    CONTENTPASSWORD: [
        RULES['equals'],
        RULES['notEquals'],
        RULES['greaterThan'],
        RULES['greaterThanEqualTo'],
        RULES['lessThan'],
        RULES['lessThanEqualTo'],
        RULES['startsWith'],
        RULES['endsWith'],
        RULES['contains'],
        RULES['isEmpty'],
    ],
    CONTENTENCRYPTED: [
        RULES['equals'],
        RULES['notEquals'],
        RULES['greaterThan'],
        RULES['greaterThanEqualTo'],
        RULES['lessThan'],
        RULES['lessThanEqualTo'],
        RULES['startsWith'],
        RULES['endsWith'],
        RULES['contains'],
        RULES['isEmpty'],
    ],
    CONTENTDATETIME: [
        RULES['equals'],
        RULES['notEquals'],
        RULES['greaterThan'],
        RULES['greaterThanEqualTo'],
        RULES['lessThan'],
        RULES['lessThanEqualTo'],
        RULES['isEmpty'],
    ],
    CONTENTDATE: [
        RULES['equals'],
        RULES['notEquals'],
        RULES['greaterThan'],
        RULES['greaterThanEqualTo'],
        RULES['lessThan'],
        RULES['lessThanEqualTo'],
        RULES['isEmpty'],
    ],
    CONTENTNUMBER: [
        RULES['equals'],
        RULES['notEquals'],
        RULES['greaterThan'],
        RULES['greaterThanEqualTo'],
        RULES['lessThan'],
        RULES['lessThanEqualTo'],
        RULES['isEmpty'],
    ],
    CONTENTLIST: [RULES['isEmpty']],
    CONTENTLISTPROPERTY: [RULES['allEqual'], RULES['anyEqual']],
    CONTENTOBJECT: [RULES['isEmpty']],
    CONTENTCODE: [
        RULES['equals'],
        RULES['notEquals'],
        RULES['greaterThan'],
        RULES['greaterThanEqualTo'],
        RULES['lessThan'],
        RULES['lessThanEqualTo'],
        RULES['startsWith'],
        RULES['endsWith'],
        RULES['contains'],
        RULES['isEmpty'],
    ],
};

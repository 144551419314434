import { uniq } from 'ramda';
import {
    GUID_REQUIRED_LENGTH,
    REGEX_EMAIL,
    REGEX_GUID,
    REGEX_ILLEGAL_GUID,
    REGEX_TENANT_NAME_ILLEGAL_CHARACTERS,
    TENANT_NAME_MAX_LENGTH,
    TENANT_NAME_MIN_LENGTH,
} from '../constants';
import translations from '../translations';
import { stringReplace } from './string';

const extractInvalidChars = (value: string, regex: RegExp) => {
    const invalidChars = value.match(regex)?.map((char) => {
        switch (char) {
            case ' ':
                return 'spaces';
            case "'":
                return 'apostrophes';
            case '"':
                return 'quotation marks';
            default:
                return `"${char}"`;
        }
    });

    if (!invalidChars) {
        return null;
    }

    const formattedList = uniq(invalidChars).join(', ');

    return formattedList;
};

export const getTenantNameValidityMessage = (value: string) => {
    const invalidChars = extractInvalidChars(value, REGEX_TENANT_NAME_ILLEGAL_CHARACTERS);

    if (invalidChars) {
        return stringReplace(translations.TENANT_name_validity_message_invalid_characters, {
            characters: invalidChars,
        });
    }

    if (value.length < TENANT_NAME_MIN_LENGTH || value.length > TENANT_NAME_MAX_LENGTH) {
        return translations.TENANT_name_validity_message_wrong_length;
    }

    return '';
};

export const getIdValidityMessage = (value: string) => {
    const invalidChars = extractInvalidChars(value, REGEX_ILLEGAL_GUID);

    if (invalidChars) {
        return `${translations.ID_validity_message_invalid_characters} ${invalidChars}`;
    }

    if (value.length !== GUID_REQUIRED_LENGTH) {
        return translations.ID_validity_message_wrong_length;
    }

    if (!REGEX_GUID.test(value)) {
        return translations.ID_validity_message_wrong_format;
    }

    return '';
};

export const getEmailValidityMessage = (value: string) => {
    return REGEX_EMAIL.test(value) ? '' : translations.USER_email_validity_message_wrong_format;
};

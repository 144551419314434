import classNames from 'classnames';
import '../../../../css/generic/progress-bar.less';

interface ProgressBarProps {
    progress?: number;
    className?: string;
    hasError?: boolean;
}

const ProgressBar = ({ progress = 0, className = '', hasError = false }: ProgressBarProps) => {
    const classes = classNames('progress-bar-outer', className, {
        'progress-bar-complete': progress === 100,
        'progress-bar-error': hasError,
    });

    return (
        <div className={classes}>
            <span className="progress-bar-progress" style={{ width: `${progress}%` }} />
        </div>
    );
};

export default ProgressBar;

import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const Input = ({
    label,
    isRequired,
    isEditable,
}: {
    label: string;
    isRequired: boolean;
    isEditable: boolean;
}) => {
    return (
        <div data-testid="component-preview-input" className="component-preview-input">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            <input tabIndex={-1} type="text" disabled={!isEditable} />
        </div>
    );
};

export default Input;

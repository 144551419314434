import { useCallback, useEffect, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { notifyError } from '../../js/actions/reduxActions/notification';
import { FRIENDLY_DEPENDENCY_NAMES } from '../constants';
import dependentSource from '../sources/dependents';
import translations from '../translations';
import type { DependencyAPI } from '../types';
import CopyableText from './generic/CopyableText';
import Table, { type TableColumnList } from './generic/Table';

type DependentsProps = {
    id: string;
} & ConnectedProps<typeof connector>;

type Dependent = {
    type: (typeof FRIENDLY_DEPENDENCY_NAMES)[keyof typeof FRIENDLY_DEPENDENCY_NAMES];
} & Omit<DependencyAPI, 'type'>;

const connector = connect(null, {
    notifyError,
});

const Dependents = ({ id, notifyError }: DependentsProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [dependents, setDependents] = useState<Dependent[]>([]);
    const [paging, setPaging] = useState({
        total: 0,
        pageSize: 10,
        page: 1,
    });

    const fetchDependents = useCallback(
        async (page: number) => {
            try {
                setIsLoading(true);

                const filters = {
                    pageSize: paging.pageSize,
                    page,
                };

                const results = await dependentSource.getAll({
                    id,
                    filters,
                });

                const newDependents = results.items.map((dependent) => ({
                    ...dependent,
                    type: FRIENDLY_DEPENDENCY_NAMES[dependent.type],
                }));

                setDependents(newDependents);

                setPaging(results._meta);
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [id, paging.pageSize, notifyError],
    );

    const handlePageChange = (page: number) => {
        setPaging({ ...paging, page });
        fetchDependents(page);
    };

    useEffect(() => {
        fetchDependents(1);
    }, [fetchDependents]);

    const dependantColumns: TableColumnList<Dependent> = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item.developerName,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type,
            renderCell: ({ item }) => item.type,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_id,
            renderCell: ({ item }) => <CopyableText copyableText={item.id} hasCopyButton={true} />,
        },
    ];

    return (
        <>
            <h2>{translations.DEPENDENTS_table_title}</h2>
            <Table
                wrapperClassName="margin-top"
                columns={dependantColumns}
                items={dependents}
                pagination={{
                    ...paging,
                    changePage: handlePageChange,
                }}
                isLoading={isLoading}
            />
        </>
    );
};

export default connector(Dependents);

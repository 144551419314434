import { concat, pathOr, assocPath, pipe, isEmpty } from 'ramda';
import actionTypes from '../actions/reduxActions/actionTypes';

const getFlowById = (id, allFlows) => allFlows.find((flow) => flow.id.id === id);

const updateFlow = (state, flowId, manipulationFunction) => {
    const flow = getFlowById(flowId, state);
    const newFlowData = manipulationFunction(flow);
    return state.map(
        // Replace the changed flowData
        (flowData) => (flowData.id.id === flowId ? newFlowData : flowData),
    );
};

/**
 *
 * @param {*} state
 * @param {String} flowId
 * @param {Array} selections
 */
export const addSelectedUsersOrGroups = ({ state, flowId, selections, type }) =>
    updateFlow(state, flowId, (flow) => {
        const usersOrGroupsToAdd = selections.map((selection) => {
            const friendlyNameProperty = selection.properties.find(
                (property) => property.developerName === 'FriendlyName',
            );
            const contentOrEmptyString = pathOr('', ['contentValue']);
            const friendlyName = contentOrEmptyString(friendlyNameProperty);

            return { authenticationId: selection.externalId, attribute: -1, friendlyName };
        });

        return assocPath(
            ['authorization', `${type}s`],
            concat(flow.authorization[`${type}s`] || [], usersOrGroupsToAdd),
            flow,
        );
    });

export const setSelectedUsersOrGroups = ({ state, flowId, selections, type }) =>
    updateFlow(state, flowId, (flow) => assocPath(['authorization', `${type}s`], selections, flow));

export const changeFlowName = ({ state, flowId, flowName }) =>
    updateFlow(state, flowId, (flow) => assocPath(['developerName'], flowName, flow));

export const changeFlowAccess = ({ state, flowId, flowAccess }) =>
    updateFlow(state, flowId, (flow) =>
        assocPath(['authorization', 'globalAuthenticationType'], flowAccess, flow),
    );

export const changeFlowHistoricalNavigation = ({ state, flowId, flowHistoricalNavigation }) =>
    updateFlow(state, flowId, (flow) =>
        assocPath(['enableHistoricalNavigation'], flowHistoricalNavigation, flow),
    );

export const changeFlowJumping = ({ state, flowId, flowJumping }) =>
    updateFlow(state, flowId, (flow) => assocPath(['allowJumping'], flowJumping, flow));

export const changeFlowStateLifetime = ({ state, flowId, flowStateLifetime }) =>
    updateFlow(state, flowId, (flow) =>
        assocPath(
            ['stateExpirationLength'],
            isEmpty(flowStateLifetime) ? 0 : flowStateLifetime,
            flow,
        ),
    );

export const changeFlowIdleStateLifetime = ({ state, flowId, flowIdleStateLifetime }) =>
    updateFlow(state, flowId, (flow) =>
        assocPath(
            ['idleStateExpirationLength'],
            isEmpty(flowIdleStateLifetime) ? 0 : flowIdleStateLifetime,
            flow,
        ),
    );

export const changeFlowComment = ({ state, flowId, flowComment }) =>
    updateFlow(state, flowId, (flow) => assocPath(['developerSummary'], flowComment, flow));

export const changeFlowService = ({ state, flowId, flowService }) =>
    updateFlow(state, flowId, (flow) => {
        const changedFlow = pipe(
            assocPath(['authorization', 'serviceElementId'], flowService),
            assocPath(['authorization', 'groups'], []),
            assocPath(['authorization', 'users'], []),
        )(flow);
        return flowService
            ? changedFlow
            : assocPath(['authorization', 'globalAuthenticationType'], 'PUBLIC', changedFlow);
    });

export const changeFlowGroup = ({ state, flowId, groupId, index }) =>
    updateFlow(state, flowId, (flow) =>
        assocPath(['authorization', 'groups', index, 'authenticationId'], groupId, flow),
    );

export const changeFlowGroupAttribute = ({ state, flowId, groupAttributeId, index }) =>
    updateFlow(state, flowId, (flow) =>
        assocPath(['authorization', 'groups', index, 'attribute'], groupAttributeId, flow),
    );

export const deleteFlowGroup = ({ state, flowId, index }) =>
    updateFlow(state, flowId, (flow) => {
        const newFlowGroups = flow.authorization.groups.filter(
            (_, groupIndex) => groupIndex !== index,
        );
        return assocPath(
            ['authorization', 'groups'],
            newFlowGroups.length > 0 ? newFlowGroups : null,
            flow,
        );
    });

export const changeFlowUser = ({ state, flowId, userId, index }) =>
    updateFlow(state, flowId, (flow) =>
        assocPath(['authorization', 'users', index, 'authenticationId'], userId, flow),
    );

export const changeFlowUserAttribute = ({ state, flowId, userAttributeId, index }) =>
    updateFlow(state, flowId, (flow) =>
        assocPath(['authorization', 'users', index, 'attribute'], userAttributeId, flow),
    );

export const deleteFlowUser = ({ state, flowId, index }) =>
    updateFlow(state, flowId, (flow) => {
        const newFlowUsers = flow.authorization.users.filter((_, userIndex) => userIndex !== index);
        return assocPath(
            ['authorization', 'users'],
            newFlowUsers.length > 0 ? newFlowUsers : null,
            flow,
        );
    });

export const changeFlowIdp = ({ state, flowId, idp }) =>
    updateFlow(state, flowId, (flow) => {
        return assocPath(['identityProvider'], idp, flow);
    });

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const flowConfigurations = (state = [], { type, payload }) => {
    switch (type) {
        case actionTypes.FLOW_CONFIGURATIONS_REGISTER: {
            // If the flow already exists, do nothing to keep local changes
            if (state.find((flow) => flow.id.id === payload.id.id)) {
                return state;
            }
            // Otherwise add it as a new flow configuration
            return [...state, payload];
        }

        case actionTypes.FLOW_CONFIGURATIONS_UNREGISTER:
            return state.filter((flow) => flow.id.id !== payload);

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_NAME:
            return changeFlowName({ state, flowId: payload.flowId, flowName: payload.flowName });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_ACCESS:
            return changeFlowAccess({
                state,
                flowId: payload.flowId,
                flowAccess: payload.flowAccess,
            });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_HISTORICAL_NAVIGATION:
            return changeFlowHistoricalNavigation({
                state,
                flowId: payload.flowId,
                flowHistoricalNavigation: payload.flowHistoricalNavigation,
            });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_JUMPING:
            return changeFlowJumping({
                state,
                flowId: payload.flowId,
                flowJumping: payload.flowJumping,
            });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_STATE_LIFETIME:
            return changeFlowStateLifetime({
                state,
                flowId: payload.flowId,
                flowStateLifetime: payload.flowStateLifetime,
            });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_IDLE_STATE_LIFETIME:
            return changeFlowIdleStateLifetime({
                state,
                flowId: payload.flowId,
                flowIdleStateLifetime: payload.flowIdleStateLifetime,
            });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_COMMENT:
            return changeFlowComment({
                state,
                flowId: payload.flowId,
                flowComment: payload.flowComment,
            });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_SERVICE:
            return changeFlowService({
                state,
                flowId: payload.flowId,
                flowService: payload.flowService,
            });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP:
            return changeFlowGroup({
                state,
                flowId: payload.flowId,
                groupId: payload.groupId,
                index: payload.index,
            });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP_ATTRIBUTE:
            return changeFlowGroupAttribute({
                state,
                flowId: payload.flowId,
                groupAttributeId: payload.groupAttributeId,
                index: payload.index,
            });

        case actionTypes.FLOW_CONFIGURATIONS_DELETE_FLOW_GROUP:
            return deleteFlowGroup({ state, flowId: payload.flowId, index: payload.index });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_USER:
            return changeFlowUser({
                state,
                flowId: payload.flowId,
                userId: payload.userId,
                index: payload.index,
            });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_USER_ATTRIBUTE:
            return changeFlowUserAttribute({
                state,
                flowId: payload.flowId,
                userAttributeId: payload.userAttributeId,
                index: payload.index,
            });

        case actionTypes.FLOW_CONFIGURATIONS_DELETE_FLOW_USER:
            return deleteFlowUser({ state, flowId: payload.flowId, index: payload.index });

        case actionTypes.FLOW_ADD_FLOW_USERS_GROUPS_SELECTION:
            return addSelectedUsersOrGroups({ state, ...payload });

        case actionTypes.FLOW_SET_FLOW_USERS_GROUPS_SELECTION:
            return setSelectedUsersOrGroups({ state, ...payload });

        case actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_IDP:
            return changeFlowIdp({
                state,
                flowId: payload.flowId,
                idp: payload.idp,
            });

        default:
            return state;
    }
};

export default flowConfigurations;

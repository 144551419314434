import classNames from 'classnames';
import { Copy } from '@phosphor-icons/react';
import ButtonIcon from '../buttons/ButtonIcon';
import ComponentWithTooltip from './tooltip/ComponentWithTooltip';
import { isNullOrEmpty } from '../../utils/guard';

interface Props {
    wrapperClassName?: string;
    textClassName?: string;
    iconClassName?: string;
    labelText?: string;
    copyableText: string;
    hasCopyButton?: boolean;
    showCopyText?: boolean;
}

const CopyableText = ({
    wrapperClassName,
    textClassName,
    iconClassName = 'copyable-icon',
    labelText,
    copyableText,
    hasCopyButton,
    showCopyText = true,
}: Props) => {
    const buttonClasses = classNames(textClassName, {
        'btn-no-appearance-left': true,
        'cursor-text': true,
        'select-all': true,
    });

    return (
        <span className={classNames(wrapperClassName, { 'flex align-center': showCopyText })}>
            {isNullOrEmpty(labelText) ? null : (
                <span className="margin-right-sml">{labelText}</span>
            )}
            {showCopyText ? (
                <button className={buttonClasses} type="button">
                    {copyableText}
                </button>
            ) : null}
            {hasCopyButton ? (
                <ComponentWithTooltip
                    trigger={'click'}
                    hideDelay={1000}
                    fadeTime={0.2}
                    tooltipContent="Copied"
                >
                    <ButtonIcon
                        className={iconClassName}
                        onClick={() => navigator.clipboard.writeText(copyableText)}
                        title="Click to Copy"
                    >
                        <Copy />
                    </ButtonIcon>
                </ComponentWithTooltip>
            ) : null}
        </span>
    );
};

export default CopyableText;

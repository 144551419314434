import { fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export type TimePeriod = 'day' | 'week' | 'month';

export interface Props {
    payload: {
        timePeriod: TimePeriod;
        outcome: string;
        mapElement: string;
        flow: string;
    };
}

export interface OutcomeEvent {
    count: number;
    timestamp: number;
}

export interface OutcomeEventsResponseApi {
    currentPeriodEvents: OutcomeEvent[];
    previousPeriodEvents: OutcomeEvent[];
}

export const getOutcomeEvents = ({ payload }: Props) =>
    fetchAndParse<OutcomeEventsResponseApi>({
        url: '/api/insights/1/outcomeevent',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: payload,
    });

import { Info, Warning, WarningOctagon, CheckCircle, type IconProps } from '@phosphor-icons/react';

type IconTypes = 'info' | 'warning' | 'error' | 'success';

const icons: {
    info: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
    warning: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
    error: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
    success: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
} = {
    info: Info,
    warning: Warning,
    error: WarningOctagon,
    success: CheckCircle,
};

interface Props {
    type: IconTypes;
    message: string;
}

const Banner = ({ type = 'info', message }: Props) => {
    const BannerIcon = icons[type];

    return (
        <div className={`banner ${type}`}>
            <BannerIcon size={24} weight="fill" className="banner-icon" />
            <p className="banner-message">{message}</p>
        </div>
    );
};

export default Banner;

import classnames from 'classnames';
import { type ElementRef, type ReactNode, useRef, useState } from 'react';
import '../../../../css/dropdown.less';
import { useDocumentClick } from '../../hooks';

interface DropdownListProps {
    buttonContents: ReactNode;
    alignRight: boolean;
    children: ReactNode;
}

const DropdownList = ({ buttonContents, alignRight, children }: DropdownListProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropDownRef = useRef<ElementRef<'button'>>(null);

    useDocumentClick(dropDownRef, () => setIsOpen(false));

    const handleItemClick = () => {
        setIsOpen(!isOpen);
    };

    const classes = classnames('dropdown-box', {
        'is-open': isOpen,
    });

    const wrapperClasses = classnames('dropdown-list-wrapper', {
        'dropdown-right': alignRight,
    });

    return (
        <div className={classes}>
            <button
                ref={dropDownRef}
                className="dropdown-btn"
                onClick={handleItemClick}
                type="button"
            >
                {buttonContents}
            </button>
            <div className={wrapperClasses}>{children}</div>
        </div>
    );
};

export default DropdownList;

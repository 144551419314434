import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const organizationTenants = (state = [], { type, payload }) => {
    switch (type) {
        case actionTypes.ORGANIZATION_TENANTS_SET_ALL:
            return payload;

        default:
            return state;
    }
};

export default organizationTenants;

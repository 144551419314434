import { useMapElement } from '../../../../../js/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import type { OpenApiScreens, UseMapElement } from '../../../../types';
import OpenApiConfiguration from './OpenApiConfiguration';
import OpenApiDetails from './OpenApiDetails';

const OpenApiRouter = () => {
    const { currentScreen }: UseMapElement<OpenApiScreens> = useMapElement();

    switch (currentScreen) {
        case 'openApi':
            return <OpenApiConfiguration />;

        case 'openApiDetails':
            return <OpenApiDetails />;

        default:
            return null;
    }
};

export default OpenApiRouter;

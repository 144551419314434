import { fetchAndParse, filterToQuery } from '../utils/ajax';
import type { Filter, IdentityProviderAPI, ItemCollectionResponse } from '../types';
import { getTenantId } from '../utils/tenant';

export const saveIdentityProvider = (identityProvider: IdentityProviderAPI) => {
    // $type discriminator required for polymorphic JSON parsing by the Engine
    const request = { $type: identityProvider.type, ...identityProvider };

    return fetchAndParse<IdentityProviderAPI>({
        url: '/api/draw/1/element/identityprovider',
        method: 'post',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });
};

export const getIdentityProvider = (id: string) =>
    fetchAndParse({
        url: `/api/draw/1/element/identityprovider/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const deleteIdentityProvider = (id: string) =>
    fetchAndParse({
        url: `/api/draw/1/element/identityprovider/${id}`,
        method: 'delete',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getIdentityProviderList = (filters: Filter) => {
    const query = filterToQuery(filters);

    return fetchAndParse<ItemCollectionResponse<IdentityProviderAPI>>({
        url: `/api/draw/1/element/identityprovider${query}`,
        method: 'get',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

import { FloppyDisk, X } from '@phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/notification.less';
import { addNotification } from '../../../js/actions/reduxActions/notification';
import ConfirmModal from '../generic/modal/ConfirmModal';
import FormGroup from '../generic/FormGroup';
import Modal from '../generic/modal/GenericModal';
import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../ts/components/buttons/ButtonPrimary';
import { macroSource } from '../../sources/macro';
import translations from '../../translations';
import { type AddNotification, ElementType, type MacroElementResponseAPI } from '../../types';
import PersistentNotification from '../notifications/PersistentNotification';
import Loader from '../loader/Loader';
import Editor from '../shared/editor/Editor';
import './macro-editor.less';
import MacroEditorHeader from './MacroEditorHeader';
import { isNullOrWhitespace } from '../../utils/guard';
import { guid } from '../../utils/guid';
import { stringReplace } from '../../utils/string';

const MacroEditor = ({ addNotification }: { addNotification: AddNotification }) => {
    const [macros, setMacros] = useState<MacroElementResponseAPI[]>([]);
    const [newMacroName, setNewMacroName] = useState('');
    const [selected, setSelected] = useState<MacroElementResponseAPI | null>(null);
    const [isNewMacro, setIsNewMacro] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isNameValid, setIsNameValid] = useState(true);
    const modalContainerRef = useRef<HTMLDivElement | null>(null);

    const alertNoMacroSelected = () =>
        addNotification({
            type: 'error',
            message: translations.MACRO_no_selected_macro_message,
        });

    const validateMacroName = (name: string) => {
        const isValid = !isNullOrWhitespace(name);

        setIsNameValid(isValid);

        return isValid;
    };

    useEffect(() => {
        // Flag to ignore results from cleaned up effects
        let ignore = false;

        const fetchSavedMacros = async () => {
            try {
                setLoading(true);

                const loadedMacros = await macroSource.getAll();

                if (ignore) {
                    return;
                }

                setMacros(loadedMacros);
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).toString(),
                });
            } finally {
                setLoading(false);
            }
        };

        fetchSavedMacros();

        return () => {
            ignore = true;
        };
    }, [addNotification]);

    const createMacro = async () => {
        const newMacro: MacroElementResponseAPI = {
            code: '',
            elementType: ElementType.Macro,
            id: guid(),
            developerName: newMacroName,
            developerSummary: null,
            updatedByName: false,
            dateCreated: '',
            dateModified: '',
            whoCreated: null,
            whoModified: null,
            whoOwner: null,
        };

        await saveMacro(newMacro, { showSuccessNotification: false });

        addNotification({
            type: 'success',
            message: translations.MACRO_create_success_message,
        });

        resetEditor();
    };

    const resetEditor = () => {
        setIsNewMacro(false);
        setNewMacroName('');
        setIsNameValid(true);
    };

    const saveMacro = async (
        macroToSave: MacroElementResponseAPI,
        { showSuccessNotification } = { showSuccessNotification: true },
    ) => {
        try {
            setLoading(true);

            await macroSource.save(macroToSave);

            const saved = await macroSource.get(macroToSave.id);

            if (isNewMacro) {
                setMacros([...macros, saved]);
            } else {
                setMacros(macros.map((stored) => (stored.id === saved.id ? saved : stored)));
            }

            setSelected(saved);
            setUnsavedChanges(false);

            if (showSuccessNotification) {
                addNotification({
                    type: 'success',
                    message: translations.MACRO_save_success_message,
                });
            }
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).toString(),
            });
        } finally {
            setLoading(false);
        }
    };

    const deleteMacro = async (macroId: string) => {
        try {
            setLoading(true);

            await macroSource.delete(macroId);

            const updatedMacros = macros.filter(({ id }) => id !== macroId);

            setMacros(updatedMacros);
            setSelected(null);
            setUnsavedChanges(false);
            setShowDeleteConfirmation(false);

            addNotification({
                type: 'success',
                message: translations.MACRO_delete_success_message,
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).toString(),
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCodeChange = (value: string) => {
        if (!selected) {
            return;
        }

        setSelected({
            ...selected,
            code: value,
        });

        setUnsavedChanges(true);
    };

    const handleMacroNameInput: React.ChangeEventHandler<HTMLInputElement> = ({
        target: { value },
    }) => {
        validateMacroName(value);
        setNewMacroName(value);
    };

    const handleMacroNameInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = ({ key }) => {
        if (key === 'Enter') {
            if (!validateMacroName(newMacroName)) {
                return;
            }
            createMacro();
        }

        if (key === 'Escape') {
            resetEditor();
        }
    };

    const handleModalSaveMacro = () => {
        if (!validateMacroName(newMacroName)) {
            return;
        }

        createMacro();
    };

    const handleCancelDelete = () => setShowDeleteConfirmation(false);

    const handleConfirmDelete = () => {
        if (!selected) {
            alertNoMacroSelected();
            return;
        }
        deleteMacro(selected.id);
    };

    return (
        <Editor
            className="macro-editor"
            readOnly={!selected}
            mode="javascript"
            onChange={handleCodeChange}
            value={selected?.code ?? ''}
            ref={modalContainerRef}
        >
            {loading ? <Loader /> : null}
            <MacroEditorHeader
                macros={macros}
                setMacros={setMacros}
                selected={selected}
                setSelected={setSelected}
                setLoading={setLoading}
                setUnsavedChanges={setUnsavedChanges}
                setIsNewMacro={setIsNewMacro}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
                saveMacro={saveMacro}
                addNotification={addNotification}
                alertNoMacroSelected={alertNoMacroSelected}
            />
            <PersistentNotification
                show={unsavedChanges}
                message={translations.MACRO_unsaved_changes_message}
            />
            <Modal
                title={translations.MACRO_new_macro_modal_title}
                show={isNewMacro}
                onHide={resetEditor}
                renderBody={() => (
                    <FormGroup
                        label={translations.MACRO_new_macro_modal_name_input_label}
                        htmlFor="macro-name-input"
                        isRequired
                        isValid={isNameValid}
                        showValidation={!isNameValid}
                        validationMessage={translations.MACRO_invalid_macro_name_message}
                    >
                        <input
                            id="macro-name-input"
                            className="form-control"
                            onChange={handleMacroNameInput}
                            onKeyUp={handleMacroNameInputKeyDown}
                        />
                    </FormGroup>
                )}
                renderFooter={() => (
                    <>
                        <ButtonDefault
                            onClick={resetEditor}
                            className={undefined}
                            testId="macro-create-modal-cancel-button"
                        >
                            <X size={16} weight="bold" className="macro-button-icon" />
                            {translations.MACRO_new_macro_modal_cancel_button_label}
                        </ButtonDefault>
                        <ButtonPrimary
                            onClick={handleModalSaveMacro}
                            className={undefined}
                            testId="macro-create-modal-save-button"
                        >
                            <FloppyDisk size={16} weight="bold" className="macro-button-icon" />
                            {translations.MACRO_new_macro_modal_save_button_label}
                        </ButtonPrimary>
                    </>
                )}
            />
            <ConfirmModal
                show={showDeleteConfirmation}
                title={translations.MACRO_delete_confirmation_title}
                messages={[
                    stringReplace(translations.MACRO_delete_confirmation_message, {
                        macroName: selected?.developerName,
                    }),
                    translations.GENERAL_cannot_be_undone,
                ]}
                buttonStyle="danger"
                buttonCaption={translations.MACRO_delete_macro_modal_delete_button_label}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                isInProgress={loading}
                container={modalContainerRef.current}
            />
        </Editor>
    );
};

const mapDispatchToProps = { addNotification };

export default connect(null, mapDispatchToProps)(MacroEditor);

import { useRef, useState } from 'react';
import { useAuth } from '../../../ts/components/AuthProvider';
import CopyableText from '../../../ts/components/generic/CopyableText';
import JoinedTenants from '../../../ts/components/organization/JoinedTenants';
import JoinedUsers from '../../../ts/components/organization/JoinedUsers';
import LeaveOrg from '../../../ts/components/organization/LeaveOrg';
import PendingTenantInviteList from '../../../ts/components/organization/PendingTenantInviteList';
import PendingUserInviteList from '../../../ts/components/organization/PendingUserInviteList';
import ProvisionOrganizationTenant from '../../../ts/components/organization/ProvisionOrganizationTenant';
import CurrentTenantInviteList from '../../../ts/components/organization/ReceivedTenantInvites';
import CurrentUserInviteList from '../../../ts/components/organization/ReceivedUserInvites';
import TenantInviteModal from '../../../ts/components/organization/TenantInviteModal';
import UserInviteModal from '../../../ts/components/organization/UserInviteModal';
import LocalRuntimesList from '../../../ts/components/organization/localRuntimes/LocalRuntimesList';
import { useScrollSpy } from '../../../ts/hooks/scroll';

/**
 * @description The UI for organization admins
 *
 * Display the UI when the user is an organization admin.
 */
const AdminUI = ({ navigateTo, setActiveSectionId, hasMenu }) => {
    const { tenant } = useAuth();

    const contentRef = useRef(null);

    const [showUserInviteModal, setShowUserInviteModal] = useState(false);
    const [showTenantInviteModal, setShowTenantInviteModal] = useState(false);
    const [showProvisionTenantModal, setShowProvisionTenantModal] = useState(false);

    // using a scrollspy based on the intersection observer to report back to
    // the side-menu the currently active section so the side-menu can update
    // itself (every content page with a side-menu will have to implement this
    // hook)
    useScrollSpy(contentRef, hasMenu, setActiveSectionId);

    const organization = tenant.organization || {};

    return (
        <div data-testid="org-members" className="admin-page" ref={contentRef}>
            {/* Organization Details */}

            <h2 id="organizationDetails">Current Organization</h2>
            <div className="margin-bottom">
                <CopyableText
                    labelText="Name:"
                    copyableText={organization?.name ?? ''}
                    hasCopyButton={true}
                />
                <CopyableText
                    labelText="ID:"
                    copyableText={organization?.id ?? ''}
                    hasCopyButton={true}
                />
            </div>
            <LeaveOrg container={contentRef?.current} />
            <hr />

            {/* Invitations */}

            <h2 id="organizationInvitations">Organization Invitations</h2>
            <CurrentTenantInviteList />
            <CurrentUserInviteList />

            {/* Tenants */}

            <h2 id="organizationTenants">Tenants</h2>

            <div className="margin-bottom">
                <button
                    className="btn btn-primary"
                    onClick={() => setShowTenantInviteModal(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    Invite Tenant
                </button>
                <button
                    className="btn btn-default"
                    onClick={() => setShowProvisionTenantModal(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    Create Tenant
                </button>
            </div>

            <TenantInviteModal
                container={contentRef.current}
                show={showTenantInviteModal}
                closeModal={() => setShowTenantInviteModal(false)}
            />
            <ProvisionOrganizationTenant
                show={showProvisionTenantModal}
                container={contentRef.current}
                closeModal={() => setShowProvisionTenantModal(false)}
            />

            <JoinedTenants container={contentRef.current} />
            <PendingTenantInviteList organizationId={organization.id} />

            {/* Users */}

            <h2 id="organizationAdministrators">Administrators</h2>

            <div className="margin-bottom">
                <button
                    className="btn btn-primary"
                    onClick={() => setShowUserInviteModal(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    Invite User
                </button>
            </div>
            <UserInviteModal
                container={contentRef.current}
                show={showUserInviteModal}
                closeModal={() => setShowUserInviteModal(false)}
            />
            <JoinedUsers container={contentRef.current} />
            <PendingUserInviteList organizationId={organization.id} />

            {/* Local Runtimes */}
            <LocalRuntimesList navigateTo={navigateTo} container={contentRef.current} />
        </div>
    );
};

export default AdminUI;

import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const List = ({ label, isRequired }: { label: string; isRequired: boolean }) => {
    return (
        <div data-testid="component-preview-list">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            <ul>
                <li>List Item 1</li>
                <li>List Item 2</li>
                <li>List Item 3</li>
            </ul>
        </div>
    );
};

export default List;

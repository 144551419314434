import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const organizationUsers = (state = [], { type, payload }) => {
    switch (type) {
        case actionTypes.ORGANIZATION_USERS_SET_ALL:
            return payload;

        default:
            return state;
    }
};

export default organizationUsers;

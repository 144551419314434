import { ExIcon, IconVariant } from '@boomi/exosphere';

interface DropdownButtonProps {
    buttonText: string;
}

const DropdownButton = ({ buttonText }: DropdownButtonProps) => (
    <div className="header-dropdown help-dropdown has-submenu">
        {buttonText}
        <ExIcon
            className="icon-small dropdown-btn-icon"
            icon="Down caret"
            label="Expand"
            variant={IconVariant.INVERSE}
        />
    </div>
);

export default DropdownButton;

import { RUNTIME_URI } from '../../../constants';
import type { FlowResponseAPI, ReleasedSnapshot, UserTenantResponseAPI } from '../../../types';
import type { Environment } from '../../../types/environment';
import { defaultPlayerName, defaultThemeName } from '../../graph/RunProvider';
import { getVersionId } from './utils';

interface Props {
    tenant: UserTenantResponseAPI | null;
    environments: Environment[];
    flowId: string;
    snapshot: ReleasedSnapshot | FlowResponseAPI;
}

const HistoryRunButton = ({ tenant, environments, flowId, snapshot }: Props) => {
    if (!tenant) {
        return null;
    }

    let url = '';

    const versionId = getVersionId(snapshot);
    const versionIdParam = `&flow-version-id=${versionId}`;

    if (environments?.length > 0) {
        const releasedSnapshot = snapshot as ReleasedSnapshot;
        const snapshotEnvironment = environments.find(
            (e) => e.id === releasedSnapshot.environmentId,
        );
        if (!snapshotEnvironment) {
            throw new Error('No environment found');
        }

        if (!tenant?.tenantSettings?.themes) {
            url = `${RUNTIME_URI}/${tenant.id}/play/${snapshotEnvironment.defaultPlayerName}?flow-id=${flowId}&environment-id=${snapshotEnvironment.id}${versionIdParam}`;
        } else if (tenant?.tenantSettings?.themes) {
            url = `${RUNTIME_URI}/${tenant.id}/play/theme/${snapshotEnvironment.defaultThemeName}?flow-id=${flowId}&environment-id=${snapshotEnvironment.id}${versionIdParam}`;
        }
    } else if (tenant?.tenantSettings?.themes) {
        url = `${RUNTIME_URI}/${tenant.id}/play/theme/${defaultThemeName}?flow-id=${flowId}${versionIdParam}`;
    } else {
        url = `${RUNTIME_URI}/${tenant.id}/play/${defaultPlayerName}?flow-id=${flowId}${versionIdParam}`;
    }

    return (
        <a className="btn btn-primary" href={url} target="_blank" rel="noopener noreferrer">
            Run Flow
        </a>
    );
};

export default HistoryRunButton;

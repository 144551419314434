import { PageConditionProvider } from '../../pageConditions/PageConditionsProvider';
import { useRef, forwardRef, useEffect, type ForwardedRef } from 'react';
import { resizeConfigPanel } from '../../../../utils';
import { DotsSixVertical } from '@phosphor-icons/react';
import { useComposer } from '../ComposerProvider';
import { usePageEditor } from '../../../PageEditorProvider';

import ElementConfigInner from './ElementConfigInner';
import classNames from 'classnames';
import PageConditions from '../../pageConditions/PageConditions';
import ElementConfig from './ElementConfig';

const ConfigPanel = (_: unknown, ref: ForwardedRef<HTMLDivElement | null>) => {
    const { state } = usePageEditor();
    const { preview, showPageConditions, tab } = state;
    const { dragDropElements, elementToEdit } = useComposer();

    const elementToConfigure = dragDropElements
        ? dragDropElements.find((element) => element.id === elementToEdit)
        : null;

    const classes = classNames({
        'sidebar-right': true,
        preview,
    });

    const resizeRef = useRef(null);
    const sideBarRef = useRef(null);

    const footerPortalId = tab?.key ? `sidebar-right-footer-portal-${tab.key}` : '';

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            typeof ref !== 'function' &&
            sideBarRef.current !== null &&
            resizeRef.current !== null &&
            ref !== null &&
            ref.current !== null
        ) {
            resizeConfigPanel(sideBarRef.current, resizeRef.current, ref.current);
        }
    }, []);

    return (
        <aside ref={sideBarRef} className={classes} data-testid="sidebar-right">
            <button tabIndex={-1} className="sidebar-resize" ref={resizeRef} type="button">
                <DotsSixVertical size={20} />
            </button>
            <div className="sidebar-right-content">
                {showPageConditions ? (
                    <PageConditionProvider>
                        <PageConditions />
                    </PageConditionProvider>
                ) : (
                    <ElementConfigInner showConfig={elementToConfigure}>
                        <ElementConfig />
                    </ElementConfigInner>
                )}
            </div>
            <div className="sidebar-right-footer" id={footerPortalId} />
        </aside>
    );
};

export default forwardRef(ConfigPanel);

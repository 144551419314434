import translations from '../../translations';
import ClipboardInput from '../inputs/ClipboardInput';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import type { TenantRuntime } from '../../types/Tenant';
import { stringReplace } from '../../utils/string';

interface Props {
    flowId: string;
    tenantId: string;
    selectedRuntime: TenantRuntime;
}

const ActivateSuccessRuntime = ({ flowId, tenantId, selectedRuntime }: Props) => (
    <>
        <h3>{`Your flow has been published successfully to ${selectedRuntime.developerName}!`}</h3>
        <p>
            <b>Player configuration values</b>
        </p>
        <div className="published-wrapper">
            <div className="published-item">
                <label htmlFor="published-tenant-id" className="published-tenant-id config-label">
                    Tenant ID:
                </label>
                <ClipboardInput
                    inputId="published-tenant-id"
                    inputClassName="published-tenant-id"
                    value={tenantId}
                />
            </div>
            <div className="published-item">
                <label htmlFor="published-flow-id" className="published-flow-id config-label">
                    Flow ID:
                </label>
                <ClipboardInput
                    inputId="published-flow-id"
                    inputClassName="published-flow-id"
                    value={flowId}
                />
            </div>
        </div>
        <ExAlertBanner
            type={AlertBannerType.WARNING}
            open
            className="published-local-version-warning"
        >
            {stringReplace(translations.LOCAL_RUNTIME_publish_to_local_runtime_version_warning, {
                version: selectedRuntime.latestVersion || 'unknown',
            })}
        </ExAlertBanner>
    </>
);

export default ActivateSuccessRuntime;

import { COMPONENT_CONFIGURATION_LABELS } from '../../../../../../constants';
import ConfigSection from '../../ConfigSection';
import type { ComposerElement } from '../../../../../../../../types';

interface Props {
    modifierOptions: { [key: string]: boolean };
    modifierValues: Partial<ComposerElement>;
    updateEditable: (isEditable: boolean) => void;
    updateMultiSelect: (isMultiSelect: boolean) => void;
    updateSearchable: (isSearchable: boolean) => void;
    updateSearchableDisplayColumns: (hasSearchableDisplayColumns: boolean) => void;
}

const ModifiersSection = ({
    modifierOptions,
    modifierValues,
    updateEditable,
    updateMultiSelect,
    updateSearchable,
    updateSearchableDisplayColumns,
}: Props) => {
    if (!(modifierOptions || modifierValues)) {
        return null;
    }

    const { hasEditable, hasMultiselect, hasSearchable } = modifierOptions ?? {};

    const { isEditable, isMultiSelect, isSearchable, isSearchableDisplayColumns } = modifierValues;

    const hideModifersSection =
        hasEditable === false && hasMultiselect === false && hasSearchable === false;

    if (hideModifersSection) {
        return null;
    }

    return (
        <ConfigSection contentLayout="flex-row" dataTestId="modifiers-section">
            <>
                {hasEditable && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateEditable(!isEditable);
                                }}
                                checked={isEditable}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['EDITABLE']}
                        </label>
                    </div>
                )}
            </>

            <>
                {hasMultiselect && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateMultiSelect(!isMultiSelect);
                                }}
                                checked={isMultiSelect}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['MULTISELECT']}
                        </label>
                    </div>
                )}
            </>

            <>
                {hasSearchable && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateSearchable(!isSearchable);
                                }}
                                checked={isSearchable}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['SEARCHABLE']}
                        </label>
                    </div>
                )}
            </>

            <>
                {hasSearchable && isSearchable && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateSearchableDisplayColumns(!isSearchableDisplayColumns);
                                }}
                                checked={isSearchableDisplayColumns}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['SEARCHABLEDISPLAYCOLUMNS']}
                        </label>
                    </div>
                )}
            </>
        </ConfigSection>
    );
};

export default ModifiersSection;

import { memo, forwardRef, type ForwardedRef, type ElementRef, useRef } from 'react';
import Background from './Background';
import LeafNode from './LeafNode';
import Edge from './Edge';
import GroupNode from './GroupNode';
import SelectionBox from './SelectionBox';
import type { Flowchart as FlowChartType } from '../../types';
import { guid } from '../../utils/guid';

const Flowchart = (
    { leafNodes, groupNodes, edges, selectionBox, viewBox }: FlowChartType,
    ref: ForwardedRef<ElementRef<'svg'>>,
) => {
    const patternIdRef = useRef(guid());

    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            className={'graph-canvas'}
            viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
        >
            <title>Flow Canvas</title>
            <Background x={viewBox.x} y={viewBox.y} id={patternIdRef.current} />
            {Object.entries(groupNodes).map(([id, groupNode]) => (
                <GroupNode key={id} node={groupNode} patternId={patternIdRef.current} />
            ))}
            {Object.entries(leafNodes).map(([id, leafNode]) => (
                <LeafNode key={id} node={leafNode} />
            ))}
            {Object.entries(edges).map(([id, edge]) => (
                <Edge key={id} edge={edge} />
            ))}
            {selectionBox && (
                <SelectionBox
                    x={selectionBox.x}
                    y={selectionBox.y}
                    width={selectionBox.width}
                    height={selectionBox.height}
                />
            )}
        </svg>
    );
};

export default memo(forwardRef(Flowchart));

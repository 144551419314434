import type { ReactElement } from 'react';
import type { ComposerElement } from '../../../../../../types';

import rocket from '../../../../../../../../img/ootw-miniature-rocket.svg';

const ElementConfigInner = ({
    showConfig,
    children,
}: {
    showConfig: ComposerElement | null | undefined;
    children: ReactElement;
}) => {
    if (showConfig) {
        return children;
    }

    return (
        <div className="sidebar-placeholder">
            <img className="placeholder-rocket" src={rocket} alt="Rocket" />
            <div className="placeholder-message" data-testid="sidebar-placeholder-message">
                Select a container or component on the center page canvas and configure it here.
                <ul>
                    <li>Drag a container onto the canvas to build the page structure.</li>
                    <li>
                        Drag a component into a container to add content and functionality to the
                        page.
                    </li>
                </ul>
                Learn more about&nbsp;
                <a
                    className="link placeholder-link"
                    href="https://help.boomi.com/csh?context=GUID-eafc591c-11b8-4924-835f-beff9aecd8c5"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    using the page builder
                </a>
                &nbsp;in our documentation.
            </div>
        </div>
    );
};

export default ElementConfigInner;

import translations from '../../translations';
import ButtonDefault from '../buttons/ButtonDefault';
import EditorDocumentSelect, {
    type EditorDocumentSelectProps,
} from '../shared/editor/EditorDocumentSelect';

interface Props
    extends Pick<EditorDocumentSelectProps, 'handleChange' | 'options' | 'value' | 'className'> {
    onTogglePreview: () => void;
    showPreview: boolean;
}

const PlayerPreviewPanel = ({
    className,
    handleChange,
    options,
    value,
    onTogglePreview: handleTogglePreview,
}: Props) => {
    return (
        <div className="player-preview-panel-container">
            <EditorDocumentSelect
                className={className}
                id="flow-select"
                label={translations.PLAYER_flow_select_label}
                handleChange={handleChange}
                options={options}
                value={value}
            />
            <ButtonDefault onClick={handleTogglePreview}>
                {translations.PLAYER_preview_open_button_label}
            </ButtonDefault>
        </div>
    );
};

export default PlayerPreviewPanel;

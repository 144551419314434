import FormGroup from '../../generic/FormGroup';

import type { ServiceAuthenticationType, ServiceElementResponseAPI } from '../../../types/service';

interface BasicAuthConfigurationProps {
    userName: string | undefined | null;
    password: string | undefined | null;
    authenticationType: ServiceAuthenticationType;
    setNewServiceDetails: (data: ServiceElementResponseAPI) => void;
    serviceData: ServiceElementResponseAPI;
}

const BasicAuthConfiguration = ({
    userName,
    password,
    authenticationType,
    setNewServiceDetails,
    serviceData,
}: BasicAuthConfigurationProps) =>
    authenticationType === 'BASIC' ? (
        <>
            <p>
                Provide a username and password if requests to the connector URL require
                authentication. This is optional.
            </p>
            <FormGroup htmlFor="basic-auth-username" label="Username">
                <input
                    id="basic-auth-username"
                    className="form-control form-control-long"
                    onChange={({ target }) =>
                        setNewServiceDetails({
                            ...serviceData,
                            httpAuthenticationUsername: target.value,
                        })
                    }
                    value={userName ?? ''}
                    required
                />
            </FormGroup>
            <FormGroup htmlFor="basic-auth-password" label="Password">
                <input
                    id="basic-auth-password"
                    className="form-control form-control-long"
                    onChange={({ target }) =>
                        setNewServiceDetails({
                            ...serviceData,
                            httpAuthenticationPassword: target.value,
                        })
                    }
                    value={password ?? ''}
                    type="password"
                    required
                />
                <span className="help-block">
                    Previously added connector passwords are not shown in this field
                </span>
            </FormGroup>
        </>
    ) : null;

export default BasicAuthConfiguration;

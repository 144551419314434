import { fetchAndParse, fetchAllPaged } from '../utils/ajax';
import type { Role, UserAPI, UserResponseAPI, UserTenantSettingsAPI } from '../types/user';
import type { ItemCollectionResponse } from '../types';
import { SYSTEM_ROLES } from '../constants';
import { getTenantId } from '../utils/tenant';

export interface UserApiKey {
    apiKey: string;
    createdAt: string;
    name: string;
    tenantId: string;
}

interface UserSettings {
    canvasSettings: {
        lineThickness: number;
        mapElementColorStyle: string;
    };
}

export const getUser = async () =>
    fetchAndParse<UserResponseAPI>({
        url: '/api/admin/1/users/me',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getKeys = () =>
    fetchAndParse<UserApiKey[]>({
        url: '/api/admin/1/users/me/keys',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const postKey = (keyName: string, tenantId: string) =>
    fetchAndParse<UserApiKey>({
        url: '/api/admin/1/users/me/keys',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: {
            name: keyName,
            tenantId,
        },
    });

export const deleteKey = (keyName: string) =>
    fetchAndParse({
        url: `/api/admin/1/users/me/keys/${keyName}`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const getUserSettings = () =>
    fetchAndParse<UserSettings>({
        url: '/api/admin/1/users/me/user-settings',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const updateUserSettings = (userSettingPayload: UserSettings) =>
    fetchAndParse<UserSettings>({
        url: '/api/admin/1/users/me/user-settings',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: userSettingPayload,
    });

export const getUserTenantSettings = () =>
    fetchAndParse<UserTenantSettingsAPI>({
        url: '/api/admin/1/users/me/settings',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
    });

export const updateUserTenantSettings = (userTenantSettingPayload: UserTenantSettingsAPI) =>
    fetchAndParse<UserTenantSettingsAPI>({
        url: '/api/admin/1/users/me/settings',
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: userTenantSettingPayload,
    });

export const getTenantUser = (userId: string) => {
    return fetchAndParse<UserAPI>({
        url: `/api/admin/1/users/${userId}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const removeUserFromTenant = ({ id, comments }: { id: string; comments: string }) => {
    return fetchAndParse<void>({
        url: `/api/admin/2/users/${id}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: JSON.stringify(comments),
    });
};

const getUsersForCurrentTenant = (page: number, pageSize: number) => {
    // API has a maximum page size of 1000
    return fetchAndParse<ItemCollectionResponse<UserAPI>>({
        url: `/api/admin/1/users?pageSize=${pageSize}&page=${page}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getAllUsersForCurrentTenant = () => {
    return fetchAllPaged(getUsersForCurrentTenant, 1000);
};

interface UserPayload {
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
}

export const updateTenantUser = ({
    userId,
    userPayload,
    comments,
}: {
    userId: string;
    userPayload: UserPayload;
    comments: string;
}) => {
    const { firstName, lastName, email, role } = userPayload;
    return fetchAndParse<UserAPI>({
        url: `/api/admin/2/users/${userId}`,
        method: 'PUT',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: {
            comments,
            user: { firstName, lastName, email, role },
        },
    });
};

export const addUserToCurrentTenant = ({
    firstName,
    lastName,
    email,
    role = SYSTEM_ROLES.standard_user,
    comments = '',
}: UserPayload & { comments: string }) => {
    return fetchAndParse<UserAPI>({
        url: '/api/admin/2/users',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: {
            comments,
            user: { firstName, lastName, email, role },
        },
    });
};

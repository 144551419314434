const GraphContextMenuItem = ({ id, label, icon, shortcut, onClick, isDisabled, onKeyDown }) => {
    return (
        <button
            id={id}
            type="button"
            className="graph-menu-item"
            onClick={onClick}
            disabled={isDisabled}
            tabIndex={-1}
            onKeyDown={onKeyDown}
        >
            <div className="menu-item-icon">{icon}</div>
            <div className="menu-item-label">{label}</div>
            <div className="menu-item-shortcut">{shortcut}</div>
        </button>
    );
};

export default GraphContextMenuItem;

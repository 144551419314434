import '../../../css/breadcrumb.less';

interface Item {
    id: string;
    content: string;
    onClick: () => void;
}

interface Trail {
    filteredItems: Item[];
    foundActive: boolean;
}

interface BreadcrumbProps {
    trail: Item[];
    activeItemId: string;
}

/**
 * @param trail Array of objects containing id, content and onClick
 * @param activeItemId Matching id from a trail object
 * @description Creates a breadcrumb navigation
 */
const Breadcrumb = ({ trail, activeItemId }: BreadcrumbProps) => {
    const initialTrail: Trail = {
        filteredItems: [],
        foundActive: false,
    };

    const { filteredItems: visibleTrail } = trail.reduce(({ filteredItems, foundActive }, item) => {
        const isActiveItem = item.id === activeItemId;
        return {
            foundActive: foundActive || isActiveItem,
            filteredItems: foundActive ? filteredItems : filteredItems.concat(item),
        };
    }, initialTrail);

    const breadcrumbItems = visibleTrail.map(({ id, content, onClick }, index, allItems) => {
        const isFirst = index === 0;
        const isLast = index === allItems.length - 1;
        return (
            <span key={id} className="breadcrumb-item">
                {isFirst ? '' : ' > '}
                {isLast ? (
                    <span className="breadcrumb-item-active">{content}</span>
                ) : (
                    <button
                        type="button"
                        className="breadcrumb-item-link link-emulate"
                        onClick={onClick}
                    >
                        {content}
                    </button>
                )}
            </span>
        );
    });

    return <div className="breadcrumb-trail">{breadcrumbItems}</div>;
};

export default Breadcrumb;

import { clamp, clone } from 'ramda';
import {
    GRAPH_ELEMENT_TYPES,
    MAP_ELEMENT_CONFIGS,
    MAP_ELEMENT_TYPES,
    NOTIFICATION_TYPES,
} from '../../../ts/constants';
import { getByID } from '../../../ts/utils/collection';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import {
    getElementStyles,
    GROUP_ELEMENT_HEIGHT,
    GROUP_ELEMENT_WIDTH,
    MAP_ELEMENT_HEIGHT,
    MAP_ELEMENT_WIDTH,
} from './elements/elementStyles';
import {
    filterOutNotes,
    getParentOffset,
    getParents,
    hasSelectedAncestors,
    moveControlPoints,
    snap,
    snapPosition,
} from './utils';
import { getFlag } from '../../../ts/utils/flags';
import { addPositions } from '../../../ts/components/graph/utils';

export const onDragEnd = async ({
    event,
    props,
    state,
    calculateSVGPositionOfMapElement,
    clearAllSideBarDragElements,
    setState,
    openOutcomeConfig,
    calculateSVGPositionFromXY,
    moveElementsIntoGroups,
    addOutcomeToStartMapElement,
}) => {
    if (props.isActive === false) {
        return;
    }

    if (state.sidebarDragElementDragging) {
        if (state.isMouseOverGraph || state.sidebarDraggingWithKeyboard) {
            const { groupElements } = props;
            const { mapElementType } = state.sidebarDragElementProps;

            let graphPositionSnapped;

            if (state.sidebarDraggingWithKeyboard) {
                graphPositionSnapped = {
                    x: state.sidebarDragElementProps.x,
                    y: state.sidebarDragElementProps.y,
                };
            } else {
                graphPositionSnapped = calculateSVGPositionOfMapElement(event, mapElementType);
            }

            const elementParentOffset = getParentOffset(
                getParents({ groupElementId: props.draggingOverGroupElementId }, groupElements),
            );

            const configProps = {
                id: '',
                elementType: mapElementType,
                x: graphPositionSnapped.x - elementParentOffset.x,
                y: graphPositionSnapped.y - elementParentOffset.y,
                groupElementId: props.draggingOverGroupElementId || '',
            };

            if (mapElementType === MAP_ELEMENT_TYPES.swimlane) {
                configProps.width = GROUP_ELEMENT_WIDTH;
                configProps.height = GROUP_ELEMENT_HEIGHT;
            }

            props.openConfig(mapElementType, configProps, clearAllSideBarDragElements);

            setState({
                sidebarDragElementDragging: false,
                sidebarDraggingWithKeyboard: false,
            });
        } else {
            clearAllSideBarDragElements();
        }
        props.setDraggingData(null);
        props.setDraggingOverGroupElementID(null);
        return;
    }

    if (!props.dragging && state.graphDraggingMovedEnough === false) {
        // If the user clicks on the graph, reset selections. except right click for contextMenu
        if (state.isMouseOverGraph && event.button !== 2) {
            props.resetSelections();
            setState({
                graphDraggingMovedEnough: false,
                graphDraggingInitialPosition: null,
            });
        }
        return;
    }

    if (state.graphDraggingMovedEnough) {
        // The user moved enough while dragging the graph that we don't reset selections,
        // But we do reset our graph dragging data
        setState({ graphDraggingMovedEnough: false, graphDraggingInitialPosition: null });
        return;
    }

    const {
        flowId,
        mapElements,
        groupElements,
        hoveringMapElementId,
        selectedElementIds,
        setSelectedElementIds,
        saveElements,
    } = props;
    const { dragType, elementId, hasMovedEnough, illegalControlPoint, outcomeId } = props.dragging;

    if (hasMovedEnough) {
        switch (dragType) {
            case GRAPH_ELEMENT_TYPES.outcome: {
                const configProps = {
                    id: elementId,
                };

                if (getByID(elementId, mapElements)?.elementType === 'START') {
                    addOutcomeToStartMapElement(event);
                } else {
                    // Open the outcome config panel
                    openOutcomeConfig({
                        event,
                        mapElementId: elementId,
                        configProps,
                    });
                }

                props.setDraggingData(null);
                props.setDraggingOverGroupElementID(null);
                return;
            }
            case GRAPH_ELEMENT_TYPES.controlPoint: {
                const outcomeMapElement = clone(getByID(elementId, mapElements));
                if (illegalControlPoint) {
                    getByID(outcomeId, outcomeMapElement.outcomes).controlPoints = null;
                }
                saveElements({ elements: [outcomeMapElement] });
                break;
            }
            case GRAPH_ELEMENT_TYPES.marquee: {
                props.endMarqueeSelection(
                    calculateSVGPositionFromXY,
                    flowId,
                    setSelectedElementIds,
                    mapElements,
                    groupElements,
                );
                break;
            }
            case GRAPH_ELEMENT_TYPES.outcomeEndPoint:
            case GRAPH_ELEMENT_TYPES.outcomeStartPoint:
                break;
            default: {
                const elementIds =
                    dragType === GRAPH_ELEMENT_TYPES.resize ? [elementId] : selectedElementIds;
                const movedElements = moveElementsIntoGroups(
                    elementIds,
                    groupElements,
                    mapElements,
                );
                saveElements({ elements: movedElements });
                break;
            }
        }
    }
    // not required to have moved enough
    switch (dragType) {
        case GRAPH_ELEMENT_TYPES.outcomeEndPoint: {
            const outcomeMapElement = clone(getByID(elementId, mapElements));
            const outcome = getByID(outcomeId, outcomeMapElement.outcomes);
            outcome.next = undefined;
            if (
                hasMovedEnough &&
                hoveringMapElementId &&
                getByID(hoveringMapElementId, filterOutNotes(mapElements)) &&
                hoveringMapElementId !== outcome.nextMapElementId
            ) {
                outcome.nextMapElementId = hoveringMapElementId;

                props.openConfig(MAP_ELEMENT_CONFIGS.outcomeRedirect, {
                    id: elementId,
                    outcomeId,
                    targetId: hoveringMapElementId,
                    nextMapElement: mapElements.find((me) => me.id === hoveringMapElementId),
                });
            }
            props.setMapElement(outcomeMapElement);
            break;
        }
        case GRAPH_ELEMENT_TYPES.outcomeStartPoint: {
            const outcomeMapElement = clone(getByID(elementId, mapElements));
            const outcome = getByID(outcomeId, outcomeMapElement.outcomes);
            outcome.from = undefined;
            if (hasMovedEnough && hoveringMapElementId && hoveringMapElementId !== elementId) {
                outcome.fromMapElementId = hoveringMapElementId;

                props.openConfig(MAP_ELEMENT_CONFIGS.outcomeSourceRedriect, {
                    sourceMapElementId: elementId,
                    targetMapElementId: hoveringMapElementId,
                    outcomeId,
                    previousId: elementId,
                });
            }
            props.setMapElement(outcomeMapElement);
            break;
        }
        case GRAPH_ELEMENT_TYPES.outcome: {
            // Remove the fake new outcome we drew every frame
            props.removeFakeOutcome({
                mapElementId: elementId,
                type: 'new',
            });
            break;
        }
        default:
            break;
    }

    props.setDraggingData(null);
    props.setDraggingOverGroupElementID(null);
};

export const onKeyDown = ({
    event,
    props,
    state,
    getSelectedElementsDimensions,
    calculateSVGPositionFromXY,
    calculateGraphCentre,
    zoomByAmount,
    calculateDraggingOverElementID,
    setState,
    moveInDirection,
    calculateSVGPositionOfMapElement,
    clearAllSideBarDragElements,
    openOutcomeConfig,
    moveElementsIntoGroups,
}) => {
    if (
        props.blockHotkeys ||
        // Chrome fires the keydown event every frame the key is held down,
        // so we read the "repeat" property and skip if we've already considered this keydown event
        event.repeat
    ) {
        return;
    }

    const {
        hoveringMapElementId,
        selectedElementIds,
        mapElements,
        hoveringGroupElementId,
        groupElements,
        selectedOutcomeId,
        selectedOutcomeMapElementId,
        hoveringOutcomeId,
        hoveringOutcomeMapElementId,
    } = props;

    const mapElementId =
        hoveringMapElementId || (selectedElementIds.length === 1 && selectedElementIds[0]);
    const mapElement = mapElements.find((m) => m.id === mapElementId);
    const groupElementId =
        hoveringGroupElementId || (selectedElementIds.length === 1 && selectedElementIds[0]);
    const groupElement = groupElements.find((g) => g.id === groupElementId);

    if (event.ctrlKey && event.key === 'g' && selectedElementIds.length > 1) {
        // prevent browser search for grouping/ungrouping
        event.preventDefault();
        props.groupSelectedElements({
            ...getSelectedElementsDimensions().newGroup,
        });
    } else if (
        event.ctrlKey &&
        event.key === 'G' &&
        isNullOrEmpty(groupElement) === false &&
        groupElement.elementType === MAP_ELEMENT_TYPES.group
    ) {
        // prevent browser search for grouping/ungrouping
        event.preventDefault();
        props.ungroup({ selectedGroupElementId: groupElement.id });
    }

    // Prioritise hovering over selection
    const activeElement = hoveringMapElementId
        ? GRAPH_ELEMENT_TYPES.map
        : hoveringGroupElementId
          ? GRAPH_ELEMENT_TYPES.group
          : mapElementId && isNullOrEmpty(mapElement) === false
            ? GRAPH_ELEMENT_TYPES.map
            : groupElementId && isNullOrEmpty(groupElement) === false
              ? GRAPH_ELEMENT_TYPES.group
              : null;

    const activeOutcomeId = hoveringOutcomeId || selectedOutcomeId;

    // The user has pressed the Delete key
    if (event.key === 'Delete') {
        // If the active element is a map element
        if (activeElement === GRAPH_ELEMENT_TYPES.map) {
            const { elementType, id } = mapElement;

            // If it's a start element
            if (elementType.toLowerCase() === MAP_ELEMENT_TYPES.start) {
                // Then throw a helpful error and do nothing
                props.addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: 'You cannot delete the Start element',
                });
                return;
            }

            props.openConfig(MAP_ELEMENT_CONFIGS.multiDelete, { elementId: id });
            return;
        }

        // If the active element is a group element
        if (activeElement === GRAPH_ELEMENT_TYPES.group) {
            const { id } = groupElement;

            props.openConfig(MAP_ELEMENT_CONFIGS.multiDelete, { elementId: id });
            return;
        }

        if (selectedElementIds.length > 0) {
            props.openConfig(MAP_ELEMENT_CONFIGS.multiDelete);
        }

        // If an outcome is active
        if (activeOutcomeId) {
            props.openConfig(MAP_ELEMENT_CONFIGS.multiDelete, {
                elementId: hoveringOutcomeMapElementId || selectedOutcomeMapElementId,
                outcomeId: activeOutcomeId,
            });
            return;
        }
    }

    // If the user presses ESCAPE, reset selections and highlights
    if (event.key === 'Escape') {
        props.resetSelections();
        props.setHighlightedElementIds([]);
    }

    if (event.ctrlKey && event.key.toLowerCase() === 'c') {
        props.copyElement(selectedElementIds, selectedOutcomeId);
    }

    if (event.ctrlKey && event.key.toLowerCase() === 'v') {
        props.pasteElement({
            graphCentre: calculateSVGPositionFromXY(calculateGraphCentre()),
        });
    }

    if (event.key === '_' || event.key === '-') {
        zoomByAmount(1.1);
    } else if (event.key === '+' || event.key === '=') {
        zoomByAmount(0.9);
    }

    if (state.sidebarDragElementDragging && state.sidebarDraggingWithKeyboard) {
        const dragProps = moveInDirection(event, state.sidebarDragElementProps);
        const { mapElementType } = state.sidebarDragElementProps;
        const styles = getElementStyles(mapElementType);

        calculateDraggingOverElementID({
            x: dragProps.x,
            y: dragProps.y,
            ...(styles.mapElement || styles.groupElement),
        });

        setState({
            sidebarDragElementProps: {
                mapElementType,
                x: dragProps.x,
                y: dragProps.y,
            },
        });
    }

    // Hotkey for displaying outcome traffic ratios
    if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'i' && getFlag('IOE')) {
        const { elementType, id } = mapElement;
        if (
            activeElement === GRAPH_ELEMENT_TYPES.map &&
            elementType.toLowerCase() !== MAP_ELEMENT_TYPES.start
        ) {
            props.calculateTrafficRatios(id);
            return;
        }
    }

    // Hotkey for opening the insights modal
    if (event.ctrlKey && event.key.toLowerCase() === 'i' && getFlag('IOE')) {
        const { elementType, id } = mapElement;
        if (
            activeElement === GRAPH_ELEMENT_TYPES.map &&
            elementType.toLowerCase() !== MAP_ELEMENT_TYPES.start
        ) {
            props.setInsightsConfigView({ mapElementId: id });
            return;
        }
    }

    if (
        state.sidebarDragElementDragging &&
        state.sidebarDraggingWithKeyboard &&
        event.key === 'Enter'
    ) {
        onDragEnd({
            event,
            props,
            state,
            calculateSVGPositionOfMapElement,
            clearAllSideBarDragElements,
            setState,
            openOutcomeConfig,
            calculateSVGPositionFromXY,
            moveElementsIntoGroups,
        });
    }
};

export const onWheel = ({ event, props, zoomByAmount }) => {
    event.preventDefault();

    if (props.contextMenu) {
        props.setContextMenuData(null);
    }

    const clampedWheelDelta = clamp(-500, 500, -event.deltaY);

    const scaleDelta = clampedWheelDelta * -0.001 + 1;

    zoomByAmount(scaleDelta, event.clientX, event.clientY);
};

export const onMouseDragging = ({
    event,
    props,
    state,
    setState,
    calculateSVGPositionOfMapElement,
    calculateDraggingOverElementID,
    setViewBox,
    calculateSVGPositionFromXY,
}) => {
    event.persist();

    setState({ isMouseOverGraph: true });

    event.preventDefault();
    const {
        groupElements,
        mapElements,
        dragging,
        contextMenu,
        dragElement,
        setContextMenuData,
        addFakeOutcomeToXY,
        resizeGroupElement,
        setMapElement,
        setDraggingData,
        highlightedElementIds,
        setHighlightedElementIds,
        hoveringMapElementId,
        setDraggingOverGroupElementID,
    } = props;
    const {
        dragType,
        elementId,
        previousElementPosition,
        previousMousePosition,
        handleSide,
        hasMovedEnough,
        initialMousePosition,
        outcomeId,
    } = dragging || {};

    const currentMousePosition = {
        x: event.clientX,
        y: event.clientY,
    };

    const svgCurrentPosition = calculateSVGPositionFromXY(currentMousePosition);

    const calculateMovement = () => ({
        x: (currentMousePosition.x - previousMousePosition.x) * state.zoom,
        y: (currentMousePosition.y - previousMousePosition.y) * state.zoom,
    });

    // Move the element by the same amount that the mouse moved
    const calculateElementPosition = () => {
        // first calculate the mouse movement on the canvas
        const delta = calculateMovement();

        // then calculate newElementPosition
        const newElementPosition = addPositions(previousElementPosition, delta);

        return newElementPosition;
    };

    // If the user is dragging and holding right click, then we create a selection marquee
    if (event.buttons === 2) {
        setContextMenuData(null);
        let newHasMovedEnough = false;
        if (dragType === GRAPH_ELEMENT_TYPES.marquee) {
            newHasMovedEnough =
                hasMovedEnough ||
                Math.abs(currentMousePosition.x - initialMousePosition.x) > 5 ||
                Math.abs(currentMousePosition.y - initialMousePosition.y) > 5;
        }
        if (!dragType || dragType === GRAPH_ELEMENT_TYPES.marquee) {
            setDraggingData({
                ...dragging,
                dragType: GRAPH_ELEMENT_TYPES.marquee,
                previousMousePosition: currentMousePosition,
                hasMovedEnough: newHasMovedEnough,
            });
            return;
        }
    }

    // We only move an element if the user is holding down the left mouse button
    if (event.buttons !== 1) {
        return;
    }

    // If the user moves, clear the highlighted elements
    if (!isNullOrEmpty(highlightedElementIds)) {
        setHighlightedElementIds([]);
    }

    if (contextMenu) {
        setContextMenuData(null);
    }

    if (state.sidebarDragElementDragging && !state.sidebarDraggingWithKeyboard) {
        const { mapElementType } = state.sidebarDragElementProps;
        const offset = calculateSVGPositionOfMapElement(event, mapElementType);

        const styles = getElementStyles(mapElementType);

        calculateDraggingOverElementID({
            x: offset.x,
            y: offset.y,
            ...(styles.mapElement || styles.groupElement),
        });

        setState({
            sidebarDragElementProps: {
                ...state.sidebarDragElementProps,
                x: offset.x,
                y: offset.y,
            },
        });
        return;
    }

    const moveGraph = () => {
        const movement = {
            x: event.movementX * state.zoom,
            y: event.movementY * state.zoom,
        };

        setViewBox({
            x: state.viewBox.x - movement.x,
            y: state.viewBox.y - movement.y,
            width: state.viewBox.width,
            height: state.viewBox.height,
        });
    };

    if (dragging && !dragging.usingKeyboard) {
        // Switching from keyboard to mouse controls causes initialMousePosition to be undefined on the first frame.
        // If this is the case just use false as not to cause a crash.
        const newHasMovedEnough = initialMousePosition
            ? hasMovedEnough ||
              Math.abs(currentMousePosition.x - initialMousePosition.x) > 5 ||
              Math.abs(currentMousePosition.y - initialMousePosition.y) > 5
            : false;

        switch (dragType) {
            case GRAPH_ELEMENT_TYPES.group: {
                dragElement({
                    calculateElementPosition,
                    elementId,
                    currentMousePosition,
                    newHasMovedEnough,
                    calculateDraggingOverElementID: calculateDraggingOverElementID,
                    elementList: groupElements,
                });
                break;
            }
            case GRAPH_ELEMENT_TYPES.outcome: {
                addFakeOutcomeToXY({
                    fromMapElementId: elementId,
                    toXY: svgCurrentPosition,
                });
                if (hoveringMapElementId) {
                    // They are connecting an outcome to a map element, do not show hovered group element
                    setDraggingOverGroupElementID(null);
                } else {
                    // They could be hovering in a group and want to make a new map element within it
                    calculateDraggingOverElementID({
                        x: snap(svgCurrentPosition.x - MAP_ELEMENT_WIDTH / 2),
                        y: snap(svgCurrentPosition.y - MAP_ELEMENT_HEIGHT / 2),
                        width: MAP_ELEMENT_WIDTH,
                        height: MAP_ELEMENT_HEIGHT,
                    });
                }

                setDraggingData({
                    ...dragging,
                    hasMovedEnough: newHasMovedEnough,
                });
                break;
            }
            case GRAPH_ELEMENT_TYPES.resize: {
                const delta = {
                    x: (currentMousePosition.x - previousMousePosition.x) * state.zoom,
                    y: (currentMousePosition.y - previousMousePosition.y) * state.zoom,
                };

                resizeGroupElement({
                    elementId,
                    side: handleSide,
                    delta,
                    currentMousePosition,
                    calculateDraggingOverElementID: calculateDraggingOverElementID,
                    hasMovedEnough: newHasMovedEnough,
                });
                break;
            }
            case GRAPH_ELEMENT_TYPES.controlPoint: {
                const controlPointMapElement = clone(getByID(elementId, mapElements));
                const controlPointOutcome = getByID(outcomeId, controlPointMapElement.outcomes);

                const newElementPosition = calculateElementPosition();
                controlPointOutcome.controlPoints = [snapPosition(newElementPosition, 5)];

                setMapElement(controlPointMapElement);

                setDraggingData({
                    ...dragging,
                    previousElementPosition: newElementPosition,
                    previousMousePosition: currentMousePosition,
                    hasMovedEnough: newHasMovedEnough,
                });
                break;
            }

            case GRAPH_ELEMENT_TYPES.outcomeEndPoint: {
                const outcomeMapElement = clone(getByID(elementId, mapElements));
                const outcome = getByID(outcomeId, outcomeMapElement.outcomes);

                outcome.next = svgCurrentPosition;

                setMapElement(outcomeMapElement);

                setDraggingData({
                    ...dragging,
                    hasMovedEnough: newHasMovedEnough,
                });
                break;
            }
            case GRAPH_ELEMENT_TYPES.outcomeStartPoint: {
                const outcomeMapElement = clone(getByID(elementId, mapElements));
                const outcome = getByID(outcomeId, outcomeMapElement.outcomes);

                outcome.from = svgCurrentPosition;

                setMapElement(outcomeMapElement);

                setDraggingData({
                    ...dragging,
                    hasMovedEnough: newHasMovedEnough,
                });
                break;
            }
            default: {
                dragElement({
                    calculateElementPosition,
                    elementId,
                    currentMousePosition,
                    newHasMovedEnough,
                    calculateDraggingOverElementID: calculateDraggingOverElementID,
                    elementList: mapElements,
                });
                break;
            }
        }
        return;
    }
    // Dragging does not go through Redux, as this then re-triggers any component listening to Redux
    // So for dragging the graph, we know the user is doing this if they're holding down the left mouse button, moving their mouse, and not doing any other drag event
    moveGraph();
    // If we don't have an initialMousePosition, then this is the first drag,
    // and we can't calculate if they've moved enough,
    // so we set the position and return
    if (state.graphDraggingInitialPosition === null) {
        setState({ graphDraggingInitialPosition: currentMousePosition });
        return;
    }
    // Calculate if they've moved enough, and set it
    // This is used to know if they've just clicked on the graph, or are dragging
    // because if they just click, we reset selections on DragEnd
    const newHasMovedEnough =
        state.graphDraggingMovedEnough ||
        Math.abs(currentMousePosition.x - state.graphDraggingInitialPosition.x) > 5 ||
        Math.abs(currentMousePosition.y - state.graphDraggingInitialPosition.y) > 5;

    setState({ graphDraggingMovedEnough: newHasMovedEnough });
};

// Handles logic for dragging an element using the keyboard controls.
// Unlike mouse controls dragging data is toggled until it is switched off, rather than just while a mouse button is held down.
// A usingKeyboard bool has been added to separate it from the mouse controls entirely.
export const onKeyboardDragging = ({
    event,
    props,
    moveInDirection,
    updateElementGroup,
    viewBox,
    setViewBox,
}) => {
    const {
        dragging,
        selectedElementIds,
        mapElements,
        groupElements,
        setMapElement,
        setGroupElement,
        saveElements,
    } = props;

    if (dragging?.usingKeyboard) {
        const localDragging = { ...dragging }; // Make a local editable version of the dragging data.
        // If the dragging element is deselected then work out which element is going to replace it.
        if (localDragging.elementId === null) {
            let highestLevelElement;
            // Loop through all the selected element ids and find the first suitable one to replace the deselected dragging data element
            // Suitable means the first one that is the top of it's lineage.
            for (const selectedId of selectedElementIds) {
                const mapEle = mapElements.find((ele) => ele.id === selectedId);
                if (mapEle && !hasSelectedAncestors(mapEle, groupElements, selectedElementIds)) {
                    localDragging.dragType = GRAPH_ELEMENT_TYPES.map;
                    highestLevelElement = mapEle;
                    break;
                }

                const groupEle = groupElements.find((ele) => ele.id === selectedId);
                if (
                    groupEle &&
                    !hasSelectedAncestors(groupEle, groupElements, selectedElementIds)
                ) {
                    localDragging.dragType = GRAPH_ELEMENT_TYPES.group;
                    highestLevelElement = groupEle;
                    break;
                }
            }

            localDragging.elementId = highestLevelElement.id;
            localDragging.previousElementPosition = {
                x: highestLevelElement.x,
                y: highestLevelElement.y,
            };
        }
        const { previousElementPosition, elementId } = localDragging;

        switch (localDragging.dragType) {
            // Moving the graph
            case GRAPH_ELEMENT_TYPES.graph: {
                const newPos = moveInDirection(event, {
                    x: viewBox.x,
                    y: viewBox.y,
                });

                setViewBox({
                    x: newPos.x,
                    y: newPos.y,
                    width: viewBox.width,
                    height: viewBox.height,
                });
                break;
            }
            // Both map and group types are handled the same apart from saving logic so share all their code and do element type checks later.
            case GRAPH_ELEMENT_TYPES.map:
            case GRAPH_ELEMENT_TYPES.group: {
                const elementsToSave = [];
                let mapElement = mapElements?.find((el) => el.id === elementId);
                const groupElement = groupElements?.find((el) => el.id === elementId);
                const movingElement = mapElement ?? groupElement;

                // Move in a direction by 1 square (10) on the grid using the arrow keys.
                const newPos = moveInDirection(event, {
                    x: previousElementPosition.x,
                    y: previousElementPosition.y,
                });

                // Hasn't moved so do nothing
                if (newPos.x === movingElement.x && newPos.y === movingElement.y) {
                    return;
                }

                const move = (position, groupElementId) => {
                    const delta = {
                        x: position.x - movingElement.x,
                        y: position.y - movingElement.y,
                    };

                    const isDraggingElementInSelection = selectedElementIds.includes(elementId);

                    if (isDraggingElementInSelection && selectedElementIds.length > 1) {
                        selectedElementIds.forEach((elementId) => {
                            let mapElementToSet = mapElements.find(
                                (element) => element.id === elementId,
                            );

                            if (mapElementToSet) {
                                // An element up the chain of parents is also selected so no need to change the position
                                if (
                                    hasSelectedAncestors(
                                        mapElementToSet,
                                        groupElements,
                                        selectedElementIds,
                                    )
                                ) {
                                    return;
                                }

                                mapElementToSet = moveControlPoints(mapElementToSet, delta);

                                mapElementToSet.x += delta.x;
                                mapElementToSet.y += delta.y;
                                mapElementToSet.groupElementId = groupElementId;

                                setMapElement(mapElementToSet);
                                elementsToSave.push(mapElementToSet);
                            } else {
                                const groupElementToSet = groupElements.find(
                                    (element) => element.id === elementId,
                                );

                                if (groupElementToSet) {
                                    // An element up the chain of parents is also selected so no need to change the position
                                    if (
                                        hasSelectedAncestors(
                                            groupElementToSet,
                                            groupElements,
                                            selectedElementIds,
                                        )
                                    ) {
                                        return;
                                    }

                                    groupElementToSet.x += delta.x;
                                    groupElementToSet.y += delta.y;
                                    groupElementToSet.groupElementId = groupElementId;

                                    setGroupElement(groupElementToSet);
                                    elementsToSave.push(groupElementToSet);
                                }
                            }
                        });
                    } else if (mapElement) {
                        mapElement = moveControlPoints(mapElement, delta);

                        mapElement.x = position.x;
                        mapElement.y = position.y;
                        mapElement.groupElementId = groupElementId;

                        setMapElement(mapElement);
                        elementsToSave.push(mapElement);
                    } else if (groupElement) {
                        groupElement.x = position.x;
                        groupElement.y = position.y;
                        groupElement.groupElementId = groupElementId;

                        setGroupElement(groupElement);
                        elementsToSave.push(groupElement);
                    }

                    props.setDraggingData({
                        ...localDragging,
                        previousElementPosition: position,
                    });
                };

                const updatedElement = updateElementGroup(
                    { ...movingElement, ...newPos },
                    groupElements,
                    groupElement,
                );

                if (updatedElement) {
                    move(
                        { x: updatedElement.x, y: updatedElement.y },
                        updatedElement.groupElementId,
                    );
                } else {
                    move(newPos, movingElement.groupElementId);
                }

                saveElements({ elements: elementsToSave });
                break;
            }
        }
    } else {
        props.setDraggingOverGroupElementID(null);
    }
};

export const onGraphSvgKeydown = ({ event, props, state, setViewBox }) => {
    const { flowId } = props;

    if (event.key === 'Tab') {
        props.onTab(event, state.viewBox, setViewBox);
    } else if (
        event.key === 'Enter' &&
        props.dragging === null &&
        event.target.id === `graph-svg-${flowId}`
    ) {
        props.setDraggingData({
            dragType: GRAPH_ELEMENT_TYPES.graph,
            usingKeyboard: true,
        });
    } else if (
        event.key === 'Escape' &&
        props.dragging !== null &&
        event.target.id === `graph-svg-${flowId}`
    ) {
        props.setDraggingData(null);
        props.resetOutcomeDragging();
    }
};

import { useState } from 'react';
import ClientCertificateConfiguration from './ClientCertificateConfiguration';
import BasicAuthConfiguration from './BasicAuthConfiguration';
import FormGroup from '../../generic/FormGroup';

import { SERVICE_URLS } from '../ServiceConstants';

import type { ServiceAuthenticationType, ServiceElementResponseAPI } from '../../../types/service';
import { isNullOrEmpty } from '../../../utils/guard';

interface ServiceAuthenticationProps {
    serviceData: ServiceElementResponseAPI;
    onSetServiceData: (data: ServiceElementResponseAPI) => void;
}

const ServiceAuthentication = ({ serviceData, onSetServiceData }: ServiceAuthenticationProps) => {
    const {
        httpAuthenticationClientCertificateReference,
        httpAuthenticationClientCertificatePasswordReference,
        httpAuthenticationUsername,
        httpAuthenticationPassword,
        uri,
    } = serviceData;

    const isCertificateAuth = !(
        isNullOrEmpty(httpAuthenticationClientCertificateReference) &&
        isNullOrEmpty(httpAuthenticationClientCertificatePasswordReference)
    );

    const isBasicAuth = !(
        isNullOrEmpty(httpAuthenticationUsername) && isNullOrEmpty(httpAuthenticationPassword)
    );

    const [authenticationType, setAuthenticationType] = useState(
        isCertificateAuth ? 'CLIENT_CERTIFICATE' : isBasicAuth ? 'BASIC' : '',
    );

    // If the connector is a supported Flow connector,
    // then display nothing
    if (Object.values(SERVICE_URLS).includes(uri)) {
        return null;
    }

    const clearBasicAuthConfig = () => {
        onSetServiceData({
            ...serviceData,
            httpAuthenticationUsername: null,
            httpAuthenticationPassword: null,
        });
    };

    const clearCertificateAuthConfig = () => {
        onSetServiceData({
            ...serviceData,
            httpAuthenticationClientCertificateReference: null,
            httpAuthenticationClientCertificatePasswordReference: null,
        });
    };

    const onAuthenticationChange = (authType: ServiceAuthenticationType) => {
        switch (authType) {
            case 'CLIENT_CERTIFICATE': {
                clearBasicAuthConfig();
                setAuthenticationType(authType);
                break;
            }
            case 'BASIC': {
                clearCertificateAuthConfig();
                setAuthenticationType(authType);
                break;
            }
            default: {
                clearBasicAuthConfig();
                clearCertificateAuthConfig();
                setAuthenticationType(authType);
            }
        }
    };

    return (
        <>
            <FormGroup htmlFor="authentication-type" label="Authentication Type">
                <select
                    id="authentication-type"
                    className="form-control form-control-long"
                    value={authenticationType}
                    onChange={({ target }) =>
                        onAuthenticationChange(target.value as ServiceAuthenticationType)
                    }
                    required
                >
                    <option value="">Please select an authentication type</option>
                    <option value={'BASIC'}>Basic</option>
                    <option value={'CLIENT_CERTIFICATE'}>Client Certificate</option>
                </select>
            </FormGroup>

            <ClientCertificateConfiguration
                certificateReference={httpAuthenticationClientCertificateReference}
                certificatePasswordReference={httpAuthenticationClientCertificatePasswordReference}
                authenticationType={authenticationType as ServiceAuthenticationType}
                setNewServiceDetails={onSetServiceData}
                serviceData={serviceData}
            />

            <BasicAuthConfiguration
                userName={httpAuthenticationUsername}
                password={httpAuthenticationPassword}
                authenticationType={authenticationType as ServiceAuthenticationType}
                setNewServiceDetails={onSetServiceData}
                serviceData={serviceData}
            />
        </>
    );
};

export default ServiceAuthentication;

import type { FormEvent } from 'react';
import ClipboardInput from '../inputs/ClipboardInput';
import FormGroup from '../generic/FormGroup';
import ExistingTranslationsSelect from '../translations/ExistingTranslationsSelect';
import type { CultureApi } from '../../types/translation';

interface Props {
    flowId: string;
    url: string;
    players: string[];
    selectedPlayer: string;
    setSelectedPlayer: (e: FormEvent<HTMLSelectElement>) => void;
    themes: string[];
    selectedTheme: string;
    setSelectedTheme: (e: FormEvent<HTMLSelectElement>) => void;
    themesEnabled: boolean;
    environmentsEnabled: boolean;
    onError: (error: string) => void;
    culture: CultureApi | undefined;
    onCultureChange: (culture: CultureApi | undefined) => void;
}

const ActivateSuccess = ({
    flowId,
    url,
    players = [],
    selectedPlayer,
    setSelectedPlayer,
    environmentsEnabled,
    themes = [],
    selectedTheme,
    setSelectedTheme,
    themesEnabled,
    onError,
    culture,
    onCultureChange,
}: Props) => {
    return (
        <>
            {environmentsEnabled ? (
                <h3>Your flow has been built and deployed to development successfully!</h3>
            ) : (
                <h3>Your flow has been published successfully!</h3>
            )}

            <>
                <p>You can access this version here:</p>
                <ClipboardInput value={url} buttonTitle="Copy URL" />
                {!(environmentsEnabled || themesEnabled) && (
                    <FormGroup label="Player" htmlFor="select-player" className="margin-top">
                        <select
                            id="select-player"
                            className="form-control form-select"
                            value={selectedPlayer}
                            onChange={setSelectedPlayer}
                        >
                            {players.map((player) => (
                                <option value={player} key={player}>
                                    {player}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                )}
                {!environmentsEnabled && themesEnabled && (
                    <FormGroup label="Theme" htmlFor="select-theme" className="margin-top">
                        <select
                            id="select-theme"
                            className="form-control form-select"
                            value={selectedTheme}
                            onChange={setSelectedTheme}
                        >
                            {themes.map((theme) => (
                                <option value={theme} key={theme}>
                                    {theme}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                )}
                {!environmentsEnabled && (
                    <ExistingTranslationsSelect
                        value={culture?.id}
                        onChange={onCultureChange}
                        flowId={flowId}
                        onError={onError}
                        margin={false}
                    />
                )}
            </>
        </>
    );
};

export default ActivateSuccess;

import actionTypes from './actionTypes';

export const registerFlowConfiguration = (flowData) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_REGISTER,
    payload: flowData,
});

export const unregisterFlowConfiguration = (flowId) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_UNREGISTER,
    payload: flowId,
});

export const changeFlowName = ({ flowId, flowName }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_NAME,
    payload: { flowId, flowName },
});

export const changeFlowAccess = ({ flowId, flowAccess }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_ACCESS,
    payload: { flowId, flowAccess },
});

export const changeFlowHistoricalNavigation = ({ flowId, flowHistoricalNavigation }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_HISTORICAL_NAVIGATION,
    payload: { flowId, flowHistoricalNavigation },
});

export const changeFlowJumping = ({ flowId, flowJumping }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_JUMPING,
    payload: { flowId, flowJumping },
});

export const changeFlowStateLifetime = ({ flowId, flowStateLifetime }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_STATE_LIFETIME,
    payload: { flowId, flowStateLifetime },
});

export const changeFlowIdleStateLifetime = ({ flowId, flowIdleStateLifetime }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_IDLE_STATE_LIFETIME,
    payload: { flowId, flowIdleStateLifetime },
});

export const changeFlowComment = ({ flowId, flowComment }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_COMMENT,
    payload: { flowId, flowComment },
});

export const changeFlowService = ({ flowId, flowService }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_SERVICE,
    payload: { flowId, flowService },
});

export const changeFlowGroup = ({ flowId, groupOrUserId, index }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP,
    payload: { flowId, groupId: groupOrUserId, index },
});

export const changeFlowGroupAttribute = ({ elementId, groupOrUserAttributeId, index }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP_ATTRIBUTE,
    payload: { flowId: elementId, groupAttributeId: groupOrUserAttributeId, index },
});

export const deleteFlowGroup = ({ elementId, index }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_DELETE_FLOW_GROUP,
    payload: { flowId: elementId, index },
});

export const addFlowUsersOrGroups = (flowId, selections, type) => ({
    type: actionTypes.FLOW_ADD_FLOW_USERS_GROUPS_SELECTION,
    payload: { flowId, selections, type },
});

export const setFlowUsersOrGroups = (flowId, selections, type) => ({
    type: actionTypes.FLOW_SET_FLOW_USERS_GROUPS_SELECTION,
    payload: { flowId, selections, type },
});

export const changeFlowUser = ({ flowId, groupOrUserId, index }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_USER,
    payload: { flowId, userId: groupOrUserId, index },
});

export const changeFlowUserAttribute = ({ elementId, groupOrUserAttributeId, index }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_USER_ATTRIBUTE,
    payload: { flowId: elementId, userAttributeId: groupOrUserAttributeId, index },
});

export const deleteFlowUser = ({ elementId, index }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_DELETE_FLOW_USER,
    payload: { flowId: elementId, index },
});

export const changeFlowIdp = ({ flowId, idp }) => ({
    type: actionTypes.FLOW_CONFIGURATIONS_CHANGE_FLOW_IDP,
    payload: { flowId, idp },
});

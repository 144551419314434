import type { Assignment, PageCondition, PageOperation, PageRule, column } from '../../types';

export const defaultDataPresentationColumnConfig: column = {
    boundTypeElementPropertyId: null,
    componentType: null,
    isBound: false,
    isDisplayValue: true,
    isEditable: false,
    label: null,
    order: 0,
    typeElementPropertyDeveloperName: null,
    typeElementPropertyId: null,
    typeElementPropertyToDisplayId: null,
};

// Page conditions
// Single condition, when configured add to pageConditions array.
export const defaultPageConditionConfig: PageCondition = {
    comparisonType: null,
    generatedSummary: null,
    pageRules: [], // array of defaultPageRuleConfig
    pageOperations: [], // array of defaultPageOperationConfig
};

export const defaultConditionOperand: Assignment = {
    metadataType: null, // used for component and value
    pageObjectReferenceDeveloperName: '', // used for component, "" if value
    pageObjectReferenceId: null, // used for component
    typeElementPropertyId: null, // usually stays null for both component and value
    valueElementToReferenceId: null,
    // ^^^ usually stays null if component, or this object if value
    // {
    //     command: null,
    //     id: null, // "fb4ee3fe-e68b-4b97-af7c-3036dcaa151e",
    //     typeElementPropertyId: null, "71d7aac8-b7e5-4256-9798-103a1e52a12e"
    // },
};

// Single page rule, when configured add to pageRules array.
export const defaultPageRuleConfig: PageRule = {
    criteriaType: null,
    generatedSummary: null,
    left: defaultConditionOperand,
    right: defaultConditionOperand,
};

// Single page operation, when configured add to pageOperations array.
export const defaultPageOperationConfig: PageOperation = {
    filter: null, // this seems to be null always?
    generatedSummary: null,
    assignment: {
        assignee: defaultConditionOperand,
        assignor: defaultConditionOperand,
    },
};

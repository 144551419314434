import { CONTENT_TYPES_LIST } from '../../../../../constants';
import type { ContentType, TypeElementResponseAPI } from '../../../../../types';

const validContentTypes: ContentType[] = [
    'ContentString'.toUpperCase() as ContentType,
    'ContentNumber'.toUpperCase() as ContentType,
    'ContentBoolean'.toUpperCase() as ContentType,
    'ContentDateTime'.toUpperCase() as ContentType,
    'ContentDate'.toUpperCase() as ContentType,
    'ContentContent'.toUpperCase() as ContentType,
];

export const suggestSupportedContentTypes: ContentType[] = [
    'ContentString'.toUpperCase() as ContentType,
    'ContentNumber'.toUpperCase() as ContentType,
    'ContentBoolean'.toUpperCase() as ContentType,
    'ContentDateTime'.toUpperCase() as ContentType,
    'ContentDate'.toUpperCase() as ContentType,
    'ContentContent'.toUpperCase() as ContentType,
    'ContentList'.toUpperCase() as ContentType,
];

export const getValidProperties = (type: TypeElementResponseAPI | null) =>
    type?.properties?.filter((prop) =>
        validContentTypes.includes(prop?.contentType?.toUpperCase() as ContentType),
    ) ?? [];

export const getNumberProperties = (type: TypeElementResponseAPI | null) =>
    type?.properties?.filter(
        (prop) => 'ContentNumber'.toUpperCase() === prop?.contentType?.toUpperCase(),
    ) ?? [];

export const invalidContentTypesString = CONTENT_TYPES_LIST.filter(
    (item) => validContentTypes.includes(item.key as ContentType) === false,
)
    .map(({ label }) => label)
    .join('/');

export const suggestSupportedContentTypesString = CONTENT_TYPES_LIST.filter((item) =>
    suggestSupportedContentTypes.includes(item.key as ContentType),
)
    .map(({ label }) => label)
    .join('/');

export const columnTypes = {
    none: 'none',
    single: 'single',
    multiple: 'multiple',
};

const GraphHelpMenu = () => (
    <ul className="help-menu" data-testid="help-menu">
        <li>
            <span className="shortcut">Ctrl</span>+<span className="shortcut">H</span>: Open & close
            help
        </li>
        <li>
            <span className="shortcut">Ctrl</span>+<span className="shortcut">C</span>: Copy an
            element
        </li>
        <li>
            <span className="shortcut">Ctrl</span>+<span className="shortcut">V</span>: Paste an
            element
        </li>
        <li>
            <span className="shortcut">Ctrl</span>+<span className="shortcut">F</span>: Open search
            menu
        </li>
        <li>
            <span className="shortcut">Dot (.)</span>: Open canvas settings
        </li>
        <li>
            <span className="shortcut">Ctrl</span>+<span className="shortcut">G</span>: Group
            selected elements
        </li>
        <li>
            <span className="shortcut">Ctrl</span>+<span className="shortcut">Shift</span>+
            <span className="shortcut">G</span>: Ungroup selected group element
        </li>
        <li>
            <span className="shortcut">Shift</span>+<span className="shortcut">A</span>: Auto
            arrange graph
        </li>
        <li>
            <span className="shortcut">Shift</span>+<span className="shortcut">F</span>: Zoom to fit
            graph
        </li>
        <li>
            <span className="shortcut">Mouse Scroll</span> or{' '}
            <span className="shortcut">Plus (+)</span> and{' '}
            <span className="shortcut">Minus (-)</span>: Zoom graph in/out
        </li>
        <li>
            <span className="shortcut">Left Mouse</span> or <span className="shortcut">Enter</span>:
            Select an element/outcome
        </li>
        <li>
            <span className="shortcut">Left Mouse</span>+<span className="shortcut">Drag</span> or{' '}
            <span className="shortcut">Arrow keys</span>: Move selected element or pan graph
        </li>
        <li>
            <span className="shortcut">Alt</span>+<span className="shortcut">Left Mouse</span> or{' '}
            <span className="shortcut">L</span>: Highlight directly connected elements
        </li>
        <li>
            <span className="shortcut">Ctrl</span>+<span className="shortcut">Left Mouse</span> or{' '}
            <span className="shortcut">Enter</span>: Add a selected element to multi-selection
        </li>
        <li>
            <span className="shortcut">Double Click</span> or{' '}
            <span className="shortcut">Space</span>: Edit an element
        </li>
        <li>
            <span className="shortcut">Right Mouse</span> or{' '}
            <span className="shortcut">≣ Menu</span>: Open context menu
        </li>
        <li>
            <span className="shortcut">Right Mouse</span>+<span className="shortcut">Drag</span>:
            Select all elements within dragged region
        </li>
        <li>
            <span className="shortcut">Escape</span>: Close all menus/Deselect all elements
        </li>
        <li>
            <span className="shortcut">Delete</span>: Delete selected element/outcome
        </li>
    </ul>
);

export default GraphHelpMenu;

import { fetchAndParse, CheckStatus } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export type PlayerName = string;
export type PlayerContent = string;

/**
 * @param {string} name The name of the player.
 * @param {string} [tenantId="ID of current tenant"] ID of tenant containing the player to load content from
 * @description Fetches a single player within the specified tenant. Defaults to the current tenant.
 * Returns text/html of the specified player.
 */
export const getPlayerContent = async (name: string, tenantId: string = getTenantId()) => {
    const response = await fetchAndParse<PlayerContent>({ url: `/${tenantId}/play/${name}` });

    return response;
};

/**
 * @description Fetches all players within the current tenant.
 * Returns an array of text/html where each entry is a player within the specified tenant.
 */
export const getAllPlayerNames = async () => {
    const tenantId = getTenantId();

    const response = await fetchAndParse<PlayerName[]>({
        url: `/${tenantId}/play`,
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

/**
 * @param {string} name The name of the player.
 * @param {string} content The player text/html to use as a base if creating a new player; otherwise text/html of the player to save
 * @description Creates or updates a player with the specified name in the current tenant
 * Returns text/html of the create or updated player.
 */
export const savePlayerContent = async (name: string, content: string) => {
    const tenantId = getTenantId();

    const response = await fetch(`/${tenantId}/play/${name}`, {
        method: 'POST',
        headers: {
            ManyWhoTenant: tenantId,
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: `=${encodeURIComponent(content)}`,
    });

    return (await CheckStatus(response)).text();
};

/**
 * @param {string} name The name of the player.
 * @description Deletes the specified player within the current tenant
 * Returns a 204 response ("No Content") if successful
 */
export const deletePlayer = async (name: string) => {
    const tenantId = getTenantId();

    const response = await fetchAndParse<Response>({
        url: `/${tenantId}/play/${name}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

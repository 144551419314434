import { type ChangeEvent, useState } from 'react';
import { getServiceGroupsOrUsers } from '../../sources/service';
import { append, pathOr, splitEvery } from 'ramda';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import Loader from '../loader/Loader';
import type {
    NotifyError,
    AuthorizationEntity,
    AuthorizationEntityType,
    ObjectAPI,
} from '../../types';
import translations from '../../translations';
import { isNullOrEmpty } from '../../utils';

interface State {
    isLoading: boolean;
    data: ObjectAPI[];
    hasMoreResults: boolean;
}

interface Props {
    serviceId: string;
    authorizationEntityType: AuthorizationEntityType;
    authorizedUsersOrGroups: AuthorizationEntity[] | null;
    addUsersOrGroups: (items: ObjectAPI[]) => void;
    notifyError: NotifyError;
}

const UserGroupSearch = ({
    serviceId,
    authorizationEntityType,
    authorizedUsersOrGroups,
    addUsersOrGroups,
    notifyError,
}: Props) => {
    const MAX_NO_RESULTS = 10;

    const [searchResults, updateResults] = useState<State>({
        isLoading: false,
        data: [],
        hasMoreResults: false,
    });
    const [selections, updateSelections] = useState<ObjectAPI[]>([]);

    const closeSearch = () => {
        updateResults({ ...searchResults, isLoading: false, data: [] });
        updateSelections([]);
    };

    const onSearch = async (value: string) => {
        if (isNullOrEmpty(value)) {
            updateResults({ ...searchResults, data: [] });
        } else {
            updateResults({ ...searchResults, isLoading: true });

            try {
                const searchServiceResults = await getServiceGroupsOrUsers({
                    serviceId,
                    type: authorizationEntityType,
                    search: value,
                    objectData: null,
                });

                const data: ObjectAPI[] = (
                    searchServiceResults?.objectData ? searchServiceResults.objectData : []
                ).map((result) => {
                    const isSelected =
                        authorizedUsersOrGroups?.some(
                            (groupOrUser) => result.externalId === groupOrUser.authenticationId,
                        ) ?? false;
                    return {
                        ...result,
                        isSelected,
                    };
                });

                if (data.length === 0) {
                    throw new Error('No results were found.');
                }

                const hasMoreResults = pathOr(false, ['hasMoreResults'], searchServiceResults);

                updateResults({ isLoading: false, data, hasMoreResults });
            } catch (error) {
                notifyError((error as Error).toString());
                updateResults({ ...searchResults, isLoading: false, data: [] });
            }
        }

        updateSelections([]);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        const result = searchResults.data.find((r) => value === r.externalId);

        if (result && checked) {
            const selectionAdded = append(result, selections);
            updateSelections(selectionAdded);
        }

        if (result && value === result.externalId && !checked) {
            const selectionRemoved = selections.filter((s) => s.externalId !== value);
            updateSelections(selectionRemoved);
        }
    };

    const saveSelection = () => {
        addUsersOrGroups(selections);
        closeSearch();
    };

    const debouncedSearch = debounce(onSearch, 750);

    const containerClasses = classNames({
        'search-form-wrapper': true,
        active: searchResults.isLoading || searchResults.data.length > 0,
    });

    return (
        <div className="search">
            <div className={containerClasses}>
                <div className="form-group">
                    <input
                        placeholder={
                            authorizationEntityType === 'group'
                                ? translations.GROUPS_USERS_GROUPS_SEARCH_PLACEHOLDER
                                : translations.GROUPS_USERS_USERS_SEARCH_PLACEHOLDER
                        }
                        className="form-control"
                        type="text"
                        onChange={(e) => {
                            // See https://reactjs.org/docs/legacy-event-pooling.html
                            const { value } = e.target;
                            debouncedSearch(value);
                        }}
                        name="search"
                    />
                </div>
                {searchResults.isLoading || searchResults.data.length > 0 ? (
                    <div className="search-inner">
                        <div className="search-results">
                            {searchResults.isLoading ? (
                                <div className="wait-container-wrapper">
                                    <Loader message="Searching..." />
                                </div>
                            ) : (
                                <>
                                    <div className="search-results-inner">
                                        {
                                            // We want checkboxes to be displayed in two columns of 5
                                            splitEvery(
                                                5,
                                                searchResults.data.slice(0, MAX_NO_RESULTS),
                                            ).map((column, i) => (
                                                <div
                                                    // biome-ignore lint/suspicious/noArrayIndexKey: Treat warnings as errors, fix later
                                                    key={`column-${i}`}
                                                    className="search-results-column"
                                                >
                                                    {column.map((groupOrUserOption) => {
                                                        const friendlyNameProperty =
                                                            groupOrUserOption.properties.find(
                                                                (property) =>
                                                                    property.developerName ===
                                                                    'FriendlyName',
                                                            );

                                                        const developerSummaryProperty =
                                                            groupOrUserOption.properties.find(
                                                                (property) =>
                                                                    property.developerName ===
                                                                    'DeveloperSummary',
                                                            );

                                                        const contentOrEmptyString = pathOr('', [
                                                            'contentValue',
                                                        ]);

                                                        const friendlyName =
                                                            contentOrEmptyString(
                                                                friendlyNameProperty,
                                                            );
                                                        const developerSummary =
                                                            contentOrEmptyString(
                                                                developerSummaryProperty,
                                                            );

                                                        const optionText =
                                                            developerSummary === ''
                                                                ? friendlyName
                                                                : `${friendlyName} (${developerSummary})`;

                                                        return (
                                                            <div
                                                                key={groupOrUserOption.externalId}
                                                                className="checkbox"
                                                            >
                                                                <label>
                                                                    <input
                                                                        onChange={onChange}
                                                                        type="checkbox"
                                                                        value={
                                                                            groupOrUserOption.externalId ??
                                                                            ''
                                                                        }
                                                                        defaultChecked={
                                                                            groupOrUserOption.isSelected
                                                                        }
                                                                        disabled={
                                                                            groupOrUserOption.isSelected
                                                                        }
                                                                    />
                                                                    {optionText}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {searchResults.hasMoreResults ||
                                    searchResults.data.length > MAX_NO_RESULTS ? (
                                        <p>There are more results, please refine your search</p>
                                    ) : null}
                                    <div className="search-results-footer">
                                        <div>
                                            <button
                                                className="btn btn-default"
                                                onClick={closeSearch}
                                                type="button"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                disabled={selections.length === 0}
                                                className="btn btn-primary"
                                                onClick={saveSelection}
                                                type="button"
                                            >
                                                Add Selected
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default UserGroupSearch;

import { isEmpty, isNil } from 'ramda';

export const isString = (value: unknown): value is string => {
    return typeof value === 'string';
};

export const isArray = (value: unknown): value is [] => {
    return Array.isArray(value);
};

export const isObject = (value: unknown): value is Record<string, unknown> => {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
};

export const isNullOrWhitespace = (value: unknown): value is null | undefined | string => {
    return (
        value === null ||
        typeof value === 'undefined' ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const isNullOrUndefined = (value: unknown): value is null | undefined => {
    return value === null || typeof value === 'undefined';
};

export const isNullOrEmpty = (
    item: unknown,
): item is null | undefined | [] | Record<string, never> | '' => isNil(item) || isEmpty(item);

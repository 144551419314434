import { memo } from 'react';
import classnames from 'classnames';
// import { XCircle } from '@phosphor-icons/react';
import Icon from '../graphv2/render/Icon';
import type { LeafNode as LeafNodeType } from '../../types';
import {
    ArrowFatDown,
    ArrowFatLeft,
    ArrowFatRight,
    ArrowFatUp,
    XCircle,
} from '@phosphor-icons/react';
import '../../../../css/graphv2/leaf-node.less';

interface Props {
    node: LeafNodeType;
}

const LeafNode = ({ node }: Props) => {
    const {
        x,
        y,
        description,
        label,
        id,
        // isHovered,
        isSelected,
        isFilled,
        canDelete,
        height,
        width,
        iconName,
        borderWidth,
        color,
        borderRadius,
        actionText,
    } = node;

    const portSize = 10;
    const midHeight = (height - portSize) / 2;
    const midWidth = (width - portSize) / 2;
    const ports = [
        {
            key: 'port-left',
            foreignobject: {
                x: -portSize,
                y: midHeight,
                width: portSize,
                height: portSize,
            },
            icon: ArrowFatLeft,
        },
        {
            key: 'port-right',
            foreignobject: {
                x: width,
                y: midHeight,
                width: portSize,
                height: portSize,
            },
            icon: ArrowFatRight,
        },
        {
            key: 'port-top',
            foreignobject: {
                x: midWidth,
                y: -portSize,
                width: portSize,
                height: portSize,
            },
            icon: ArrowFatUp,
        },
        {
            key: 'port-bottom',
            foreignobject: {
                x: midWidth,
                y: height,
                width: portSize,
                height: portSize,
            },
            icon: ArrowFatDown,
        },
    ];

    const selectionMarqueeDistance = 5;
    const selectionMarqueeHeight = height + selectionMarqueeDistance * 2;
    const selectionMarqueeWidth = width + selectionMarqueeDistance * 2;
    const selectionMarqueeRadius = borderRadius + selectionMarqueeDistance;

    return (
        <g
            height={height}
            width={width}
            id={id}
            className={'map-element'}
            transform={`matrix(1,0,0,1,${x},${y})`}
            tabIndex={-1}
            aria-label={description}
        >
            <g data-object="leafNode" data-id={node.id}>
                {/*
            Invisible out border around the element so hovering off the element is a bit harder.
            Without this, as soon as your mouse goes off the element (possibly towards the outcome port),
            the outcome is no longer hovered, and so the port disappears.
            */}
                <rect
                    x={-selectionMarqueeDistance}
                    y={-selectionMarqueeDistance}
                    width={selectionMarqueeWidth}
                    height={selectionMarqueeHeight}
                    rx={selectionMarqueeRadius}
                    strokeWidth={0.7}
                    strokeDasharray={2}
                    fillOpacity={0}
                    data-testid="map-element-selected"
                    className={`no-focus-border hover-out-border${
                        isSelected ? ' map-element-selected' : ''
                    }`}
                />
                {ports.map((port) => (
                    <foreignObject
                        key={port.key}
                        {...port.foreignobject}
                        className="map-element-ports"
                        data-object="port"
                        data-testid={`${node.id}-${port.key}`}
                    >
                        <port.icon
                            className="map-element-hover-icon"
                            weight="fill"
                            alt="Drag to create an outcome"
                        />
                    </foreignObject>
                ))}
                {/* Visible dotted grey out border around the element */}
                {/* {isSelected && (!isDragging || keyboardDragging) && ( */}

                {/* Solid coloured border around the element */}
                <rect
                    height={height}
                    width={width}
                    fill={color}
                    rx={borderRadius}
                    data-testid="map-element-border"
                    className="map-element-border"
                />
                {/* White/Optionally coloured fill inside the element */}
                <rect
                    x={borderWidth / 2}
                    y={borderWidth / 2}
                    fill={isFilled ? color : '#fff'}
                    data-testid="standardElement"
                    className="map-element-fill"
                    height={height - borderWidth}
                    width={width - borderWidth}
                    rx={borderRadius - 2}
                />
                {/* Phosphor icon must stay within foreignObject due to Safari issues that do not support an svg within an svg */}
                {canDelete && (
                    <foreignObject x={width} y={-10} width={10} height={10}>
                        <XCircle
                            size={10}
                            weight="fill"
                            className="map-element-delete"
                            data-action="delete"
                            data-testid="elementDeleteButton"
                            alt="Delete"
                        />
                    </foreignObject>
                )}
                {/* Icon and label foreign objects */}
                {/* {!isDragging && ports} */}
                <foreignObject
                    x={borderWidth}
                    y={borderWidth}
                    height={height - borderWidth * 2}
                    width={width - borderWidth * 2}
                >
                    <span className="map-element-content">
                        <span
                            className={classnames({
                                'map-element-icon': true,
                                'safari-fix': true,
                                glyphicon: true,
                            })}
                        >
                            <Icon name={iconName} size={15} />
                        </span>
                        <span className="map-element-text">
                            <span
                                className={classnames({
                                    'map-element-developer-name': true,
                                })}
                                title={label}
                            >
                                {label}
                            </span>
                            {actionText ? (
                                <span className="map-element-description">{actionText}</span>
                            ) : null}
                        </span>
                    </span>
                </foreignObject>
            </g>
        </g>
    );
};

export default memo(LeafNode);

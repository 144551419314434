import { MAP_ELEMENT_DISPLAY_NAMES, MAP_ELEMENT_TYPES } from '../../../../ts/constants';
import { safeToLower } from '../../../../ts/utils/string';
import { getVariableStyles, isColorFillMode } from '../utils';
import { getElementStyles } from './elementStyles';
import GroupElementBase from '../../../../ts/components/graph/elements/group/GroupElementBase.tsx';
import MapElementBase from '../../../../ts/components/graph/elements/map/MapElementBase.tsx';
import NoteElement from './map/notes/NoteElement';
import StandardElement from '../../../../ts/components/graph/elements/map/StandardElement.tsx';

const DragElement = ({
    mapElementType,
    x,
    y,
    canvasSettings,
    zoomLevel,
    sidebarDraggingWithKeyboard,
}) =>
    mapElementType.toLowerCase() === MAP_ELEMENT_TYPES.swimlane ||
    mapElementType.toLowerCase() === MAP_ELEMENT_TYPES.group ? (
        <GroupElementBase
            id="dragElement"
            height={getElementStyles(mapElementType).groupElement.height}
            width={getElementStyles(mapElementType).groupElement.width}
            developerName={`New ${MAP_ELEMENT_DISPLAY_NAMES[mapElementType]}`}
            elementType={mapElementType}
            x={x}
            y={y}
            borderFill={getElementStyles(mapElementType).border.fill}
            isOpen
            movingNewElementWithKeyboard={sidebarDraggingWithKeyboard}
        />
    ) : (
        <MapElementBase
            id="dragElement"
            component={
                safeToLower(mapElementType) === MAP_ELEMENT_TYPES.note
                    ? NoteElement
                    : StandardElement
            }
            styles={getVariableStyles(getElementStyles(mapElementType), canvasSettings, zoomLevel)}
            developerName={`New ${MAP_ELEMENT_DISPLAY_NAMES[mapElementType]}`}
            elementType={mapElementType}
            x={x}
            y={y}
            isColorFillMode={isColorFillMode(canvasSettings.mapElementColorStyle, zoomLevel)}
            movingNewElementWithKeyboard={sidebarDraggingWithKeyboard}
        />
    );

export default DragElement;

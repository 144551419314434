import translations from '../../translations';
import { useAnomalyDashboard } from './AnomalyProvider';
import { type ElementRef, useEffect, useRef } from 'react';
import { Chart, type ChartConfiguration } from 'chart.js/auto';
import InsightsDateFilter from '../developerDashboard/InsightsDateFilter';
import type { TimeFilters } from '../../types/dashboard';

interface Props {
    isSummary?: boolean;
    setParentHovering?: (isHovering: boolean) => void;
}

const AnomalyBarChart = ({ isSummary, setParentHovering }: Props) => {
    const { chartData, dateIndexFilter, setDateIndexFilter } = useAnomalyDashboard();

    const canvasRef = useRef<ElementRef<'canvas'>>(null);

    useEffect(() => {
        const context = canvasRef.current?.getContext('2d');

        if (!context) {
            return;
        }

        const chartConfig: ChartConfiguration<'bar', { x: string; y: number }[]> = {
            type: 'bar',
            data: { datasets: chartData },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: { position: 'top' } },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value: number | string) => {
                                if ((value as number) % 1 === 0) {
                                    return value;
                                }
                                return null;
                            },
                            color: '#9f9f9f',
                        },
                        stacked: true,
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: '#9f9f9f',
                        },
                        stacked: true,
                    },
                },
            },
        };

        const chart = new Chart(context, chartConfig);

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            chart.destroy();
        };
    }, [chartData]);

    const getSelectedDate = (dateIndex: number) => {
        if (dateIndex === 0) {
            return 'day';
        }

        if (dateIndex === 1) {
            return '3days';
        }

        return 'week';
    };

    const getDateIndex = (dateValue: TimeFilters) => {
        if (dateValue === 'day') {
            return 0;
        }

        if (dateValue === '3days') {
            return 1;
        }

        return 2;
    };

    return (
        <div className="bar-chart-section">
            <span className="title-bar insight-tile-header">
                <h2>
                    {isSummary
                        ? translations.DASHBOARD_anomalies_header
                        : translations.ANOMALY_bar_chart_title}
                </h2>
                {isSummary ? (
                    <InsightsDateFilter
                        selectedDate={getSelectedDate(dateIndexFilter)}
                        setSelectedDate={(date) => setDateIndexFilter(getDateIndex(date))}
                        dateFilterItems={[
                            {
                                label: translations.DASHBOARD_filter_last_day,
                                value: 'day',
                                tooltip: translations.ANOMALY_filter_to_last_day,
                            },
                            {
                                label: translations.ANOMALY_3_days_short,
                                value: '3days',
                                tooltip: translations.ANOMALY_filter_to_last_3_days,
                            },
                            {
                                label: translations.DASHBOARD_filter_last_week,
                                value: 'week',
                                tooltip: translations.ANOMALY_filter_to_last_7_days,
                            },
                        ]}
                        setParentHovering={setParentHovering ?? undefined}
                    />
                ) : null}
            </span>
            <div className="insights-chart">
                <canvas ref={canvasRef} data-testid="anomaly-bar-chart-canvas" />
            </div>
        </div>
    );
};

export default AnomalyBarChart;

const Glyphicon = ({
    glyph,
    className,
    title,
}: {
    glyph: string;
    className?: string;
    title?: string;
}) => <span className={`glyphicon glyphicon-${glyph} ${className ?? ''}`} title={title} />;

export default Glyphicon;

import { ExIcon, IconVariant } from '@boomi/exosphere';
import '../../../../css/buttons.less';
import '../../../../css/icons.less';
import type { ButtonProps } from '../../types';

interface ButtonIconProps extends ButtonProps {
    glyph?: string;
    iconClass?: string;
    variant?: IconVariant;
}

const ButtonIcon = ({
    onClick,
    testId,
    className,
    children,
    glyph,
    iconClass = '',
    title,
    variant = IconVariant.DEFAULT,
    ...otherProps
}: ButtonIconProps) => (
    <button
        type="button"
        onClick={onClick}
        data-testid={testId}
        className={`btn-icon ${className ?? ''}`}
        title={title}
        {...otherProps}
    >
        {/* Show ExIcon only if an icon is provided, otherwise skip it so that it doesn't interfere with Phosphor icons. */}
        {glyph ? (
            <ExIcon className={iconClass} icon={glyph} label={title ?? glyph} variant={variant} />
        ) : null}
        {children}
    </button>
);

export default ButtonIcon;

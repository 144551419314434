import ConfigModalHeader from './ConfigModalHeader';
import { MAP_ELEMENT_DISPLAY_NAMES } from '../../../../constants';
import GenericModal from '../../../generic/modal/GenericModal';
import type { ReactElement } from 'react';
import '../../../../../../css/graph/config-modal.less';
import { isNullOrWhitespace } from '../../../../utils/guard';

interface Props {
    id: string;
    renderBody: () => ReactElement;
    renderFooter: () => ReactElement;
    title: string;
    elementType: string;
    onHide: () => void;
    container?: HTMLElement | undefined;
    show?: boolean;
    dialogClassName?: string;
    backdrop?: boolean;
    currentUserId?: string;
}

const ConfigLayoutWrapper = ({
    id,
    renderBody,
    renderFooter,
    title,
    elementType,
    onHide,
    container,
    show = true,
    dialogClassName,
    backdrop = true,
    currentUserId = '',
}: Props) => (
    // Modal view (new canvas)
    <GenericModal
        renderHeader={() => (
            <ConfigModalHeader
                id={id}
                title={
                    isNullOrWhitespace(title)
                        ? `New ${MAP_ELEMENT_DISPLAY_NAMES[elementType] || ''}`
                        : title
                }
                elementType={elementType}
                currentUserId={currentUserId}
            />
        )}
        renderBody={renderBody}
        renderFooter={renderFooter}
        onHide={onHide}
        dialogClassName={dialogClassName || 'modal-large config-modal map-element-config-modal'}
        className={`${elementType}-modal`}
        backdropClassName={`${elementType}-backdrop`}
        container={container}
        animation={false}
        show={show}
        backdrop={backdrop}
    />
);

export default ConfigLayoutWrapper;

import classNames from 'classnames';
import type { ReactNode } from 'react';
import './editor.less';

export interface EditorHeaderProps {
    className?: string | undefined;
    children: ReactNode;
}

const EditorHeader = ({ className, children }: EditorHeaderProps) => {
    const headerClasses = classNames('editor-header', className);

    return <div className={headerClasses}>{children}</div>;
};

export default EditorHeader;

import { type ElementRef, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { addNotification } from '../../../js/actions/reduxActions/notification';
import { NOTIFICATION_TYPES } from '../../constants';
import translations from '../../../ts/translations';
import ConfirmModal from '../generic/modal/ConfirmModal';
import ShowHide from '../generic/ShowHide';
import TenantName from '../generic/TenantName';
import { deleteKey, getKeys, postKey, type UserApiKey } from '../../sources/user';
import CopyableText from '../generic/CopyableText';
import { useAuth } from '../AuthProvider';
import { Trash } from '@phosphor-icons/react';
import Table, { type TableColumnList } from '../generic/Table';
import type { AddNotification, UserTenantResponseAPI } from '../../types';
import FormGroup from '../generic/FormGroup';
import './userSettings.less';
import { getByID } from '../../utils/collection';
import { tryGetErrorMessage } from '../../utils/error';
import { isNullOrWhitespace } from '../../utils/guard';
import { formatTenantName } from '../../utils/tenant';

interface UserSettingsProps {
    addNotification: AddNotification;
}

const sortApiKeys = (apiKeys: UserApiKey[]) => {
    return apiKeys.slice().sort((first, second) => {
        // Subtract second date from first to get ascending sort order
        return new Date(second.createdAt).getTime() - new Date(first.createdAt).getTime();
    });
};

const sortTenants = (tenants: UserTenantResponseAPI[]) => {
    return tenants.slice().sort((first, second) => {
        const firstLowerCase = first.developerName.toLowerCase();
        const secondLowerCase = second.developerName.toLowerCase();

        if (firstLowerCase < secondLowerCase) {
            return -1;
        }

        if (firstLowerCase > secondLowerCase) {
            return 1;
        }

        return 0;
    });
};

const UserSettings = ({ addNotification }: UserSettingsProps) => {
    const [APIKeys, setAPIKeys] = useState<UserApiKey[]>([]);
    const [APIKeysLoading, setAPIKeysLoading] = useState(false);
    const [selectedTenantId, setSelectedTenantId] = useState('');
    const [keyName, setKeyName] = useState('');
    const [selectedKey, setSelectedKey] = useState<UserApiKey | null>(null);
    const [showConfirmRemoveKey, setShowConfirmRemoveKey] = useState(false);
    const modalContainerRef = useRef<ElementRef<'div'>>(null);

    const { fetchUser, user } = useAuth();

    const fetchAPIKeys = useCallback(async () => {
        try {
            const keys = await getKeys();

            const sortedKeys = sortApiKeys(keys);

            setAPIKeys(sortedKeys);
            setAPIKeysLoading(false);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: tryGetErrorMessage(error),
                isPersistent: true,
            });
        }
    }, [addNotification]);

    useEffect(() => {
        if (!user) {
            fetchUser();
        }
        fetchAPIKeys();
    }, [fetchAPIKeys, fetchUser, user]);

    if (!(user?.tenants && user.id && user.email)) {
        return null;
    }

    const { tenants, id, email } = user;

    const onGenerateKey = async () => {
        if (isNullOrWhitespace(keyName) || isNullOrWhitespace(selectedTenantId)) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.USER_SETTINGS_api_key_validation_message,
            });
            return;
        }

        try {
            await postKey(keyName, selectedTenantId);
            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: translations.USER_SETTINGS_api_generate_success_message,
            });

            await fetchAPIKeys();

            setKeyName('');
            setSelectedTenantId('');
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: tryGetErrorMessage(error),
                isPersistent: true,
            });
        }
    };

    const onRemoveKey = async () => {
        if (!selectedKey) {
            addNotification({
                type: NOTIFICATION_TYPES.info,
                message: translations.USER_SETTINGS_no_api_key_selected_message,
            });
            return;
        }

        try {
            await deleteKey(selectedKey.name);

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: translations.USER_SETTINGS_api_delete_success_message,
            });

            await fetchAPIKeys();

            setShowConfirmRemoveKey(false);
            setSelectedKey(null);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: tryGetErrorMessage(error),
                isPersistent: true,
            });
        }
    };

    const APIKeysColumns: TableColumnList<UserApiKey> = [
        {
            renderHeader: () => translations.COMMON_TABLE_key_name,
            renderCell: ({ item }) => item.name,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_tenant_name,
            renderCell: ({ item }) => (
                <TenantName name={getByID(item.tenantId, tenants)?.developerName ?? ''} />
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_tenant_id,
            renderCell: ({ item }) => item.tenantId,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_created_at_utc,
            renderCell: ({ item }) =>
                new Date(item.createdAt).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                }),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_key,
            renderCell: ({ item }) => (
                <ShowHide
                    renderTrigger={(isShowing) =>
                        !isShowing && (
                            <span className="link-emulate">
                                {translations.USER_SETTINGS_show_api_key_button_label}
                            </span>
                        )
                    }
                >
                    <CopyableText copyableText={item.apiKey} hasCopyButton={true} />
                </ShowHide>
            ),
            size: '400px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={translations.USER_SETTINGS_delete_api_key_button_label}
                        className="table-icon table-icon-delete"
                        aria-label={translations.USER_SETTINGS_delete_api_key_button_label}
                        onClick={() => {
                            setShowConfirmRemoveKey(true);
                            setSelectedKey(item);
                        }}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <span className="admin-transition">
            <div className="flex-column full-height">
                <div className="admin-page" ref={modalContainerRef}>
                    <h1>{translations.USER_SETTINGS_heading}</h1>
                    <CopyableText copyableText={id} labelText="User ID: " hasCopyButton={true} />
                    <CopyableText copyableText={email} labelText="Email: " hasCopyButton={true} />
                    <h2>{translations.USER_SETTINGS_api_keys_heading}</h2>
                    <p>{translations.USER_SETTINGS_api_key_description}</p>

                    <h3>{translations.USER_SETTINGS_generate_api_key_heading}</h3>
                    <div className="flex gap-small" onSubmit={onGenerateKey}>
                        <FormGroup
                            className="user-settings-form-group"
                            isRequired
                            htmlFor="key-name-input"
                            label={translations.USER_SETTINGS_api_key_name_input_label}
                        >
                            <input
                                id="key-name-input"
                                value={keyName}
                                onChange={({ target: { value } }) => setKeyName(value)}
                                className="form-control"
                            />
                        </FormGroup>
                        <FormGroup
                            className="user-settings-form-group"
                            isRequired
                            htmlFor="tenant-select"
                            label={translations.USER_SETTINGS_tenant_select_label}
                        >
                            <select
                                id="tenant-select"
                                className="form-control form-select"
                                value={selectedTenantId || ''}
                                onChange={({ target: { value } }) => setSelectedTenantId(value)}
                            >
                                {
                                    <option value="" key="please-select">
                                        {
                                            translations.USER_SETTINGS_tenant_select_default_value_label
                                        }
                                    </option>
                                }
                                {sortTenants(tenants).map((tenant) => {
                                    return (
                                        <option value={tenant.id} key={tenant.id}>
                                            {formatTenantName(tenant.developerName)}
                                        </option>
                                    );
                                })}
                            </select>
                        </FormGroup>
                        <button
                            type="button"
                            className="btn btn-primary flex-end generate-key-button"
                            onClick={onGenerateKey}
                        >
                            {translations.USER_SETTINGS_generate_api_key_button_label}
                        </button>
                    </div>
                    <Table
                        wrapperClassName="margin-top"
                        columns={APIKeysColumns}
                        items={APIKeys}
                        pagination={true}
                        isLoading={APIKeysLoading}
                        rowKeyGenerator={(item) => `${item.name}-${item.tenantId}`}
                    />
                    <ConfirmModal
                        title={translations.USER_SETTINGS_api_key_remove_confirm_title}
                        messages={[translations.USER_SETTINGS_key_remove_confirm_message]}
                        show={showConfirmRemoveKey}
                        onConfirm={onRemoveKey}
                        onCancel={() => {
                            setShowConfirmRemoveKey(false);
                            setSelectedKey(null);
                        }}
                        buttonStyle="danger"
                        buttonCaption={
                            translations.USER_SETTINGS_confirm_delete_api_key_button_label
                        }
                        container={modalContainerRef.current}
                    />
                </div>
            </div>
        </span>
    );
};

const mapDispatchToProps = {
    addNotification,
};

export default connect(null, mapDispatchToProps)(UserSettings);

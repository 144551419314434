import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const recentValues = (state = [], { type, payload }) => {
    switch (type) {
        case actionTypes.SET_RECENT_VALUES:
            return payload;

        default:
            return state;
    }
};

export default recentValues;

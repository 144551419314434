export const MAP_ELEMENT_TYPES = {
    start: 'start',
    input: 'input',
    modal: 'modal',
    step: 'step',
    decision: 'decision',
    operator: 'operator',
    message: 'message',
    process: 'process',
    databaseLoad: 'database_load',
    databaseSave: 'database_save',
    databaseDelete: 'database_delete',
    swimlane: 'swimlane',
    subflow: 'subflow',
    return: 'return',
    group: 'group',
    note: 'note',
    wait: 'wait',
    outcome: 'outcome',
    openapi: 'openapi',
};

export const MAP_ELEMENT_CONFIGS = {
    ...MAP_ELEMENT_TYPES,
    multiDelete: 'multi_delete',
    outcomeRedirect: 'outcome_redirect',
    outcomeSourceRedriect: 'outcome_source_redirect',
    addOutcome: 'add_outcome', // context menu button
};

export const MAP_ELEMENT_DISPLAY_NAMES = {
    [MAP_ELEMENT_TYPES.start]: 'start',
    [MAP_ELEMENT_TYPES.input]: 'page',
    [MAP_ELEMENT_TYPES.modal]: 'modal',
    [MAP_ELEMENT_TYPES.step]: 'step',
    [MAP_ELEMENT_TYPES.decision]: 'decision',
    [MAP_ELEMENT_TYPES.operator]: 'operator',
    [MAP_ELEMENT_TYPES.process]: 'process',
    [MAP_ELEMENT_TYPES.message]: 'message',
    [MAP_ELEMENT_TYPES.databaseLoad]: 'database load',
    [MAP_ELEMENT_TYPES.databaseSave]: 'database save',
    [MAP_ELEMENT_TYPES.databaseDelete]: 'database delete',
    [MAP_ELEMENT_TYPES.swimlane]: 'swimlane',
    [MAP_ELEMENT_TYPES.subflow]: 'subflow',
    [MAP_ELEMENT_TYPES.return]: 'return',
    [MAP_ELEMENT_TYPES.group]: 'group',
    [MAP_ELEMENT_TYPES.note]: 'note',
    [MAP_ELEMENT_TYPES.outcome]: 'outcome',
    [MAP_ELEMENT_TYPES.wait]: 'wait',
    [MAP_ELEMENT_TYPES.openapi]: 'openapi',
    unknown: 'map element',
};

// Rules surrounding the outcomes and the elements that they come from and go to.

/**
 * Here is a defined list of elements that support business rules.
 * This also means they are capable of supporting multiple outcomes.
 */
export const BUSINESS_RULES_ELEMENTS = [
    MAP_ELEMENT_TYPES.process,
    MAP_ELEMENT_TYPES.message,
    MAP_ELEMENT_TYPES.decision,
    MAP_ELEMENT_TYPES.input,
    MAP_ELEMENT_TYPES.modal,
    MAP_ELEMENT_TYPES.databaseLoad,
    MAP_ELEMENT_TYPES.databaseSave,
];

/**
 * Here is a defined list of elements that can only have exactly one outcome.
 */
export const ONE_OUTCOME_ELEMENTS = [
    MAP_ELEMENT_TYPES.start,
    MAP_ELEMENT_TYPES.operator,
    MAP_ELEMENT_TYPES.databaseDelete,
    MAP_ELEMENT_TYPES.subflow,
];

/**
 * Here is a defined list of elements that are UI elements.
 *
 * These elements will render the following configs:
 * - label "Label"
 * - pageActionBindingType "When this outcome is selected"
 * - pageActionType "What kind of action is this?"
 *
 * These configs only make sense when the element we're travelling from has a visual interface
 */
export const UI_ELEMENTS = [
    MAP_ELEMENT_TYPES.step,
    MAP_ELEMENT_TYPES.input,
    MAP_ELEMENT_TYPES.modal,
];

/**
 * Here is a defined list of elements that should render the fault outcome button.
 *
 * The fault outcome button turns the outcome into a fault outcome,
 * meaning that if the element fails for some reason,
 * this outcome is travelled down instead of the first available outcome.
 *
 * Elements in this list should only be elements that can have service issues.
 */
export const FAULT_OUTCOME_ELEMENTS = [
    MAP_ELEMENT_TYPES.message,
    MAP_ELEMENT_TYPES.databaseLoad,
    MAP_ELEMENT_TYPES.databaseSave,
    MAP_ELEMENT_TYPES.databaseDelete,
];

// Element types that have a clickable hyperlink on the graph render.
export const HYPERLINK_ELEMENTS = [
    MAP_ELEMENT_TYPES.input,
    MAP_ELEMENT_TYPES.modal,
    MAP_ELEMENT_TYPES.subflow,
];

export const GROUP_ELEMENTS = [MAP_ELEMENT_TYPES.group, MAP_ELEMENT_TYPES.swimlane];

export const CONTEXT_MENU_NO_CREATE_OPTION = [
    MAP_ELEMENT_TYPES.swimlane,
    MAP_ELEMENT_TYPES.group,
    MAP_ELEMENT_TYPES.return,
    MAP_ELEMENT_TYPES.note,
];

export const GRAPH_ELEMENT_TYPES = {
    group: 'group',
    outcome: 'outcome',
    outcomeStartPoint: 'outcomeStartPoint',
    outcomeEndPoint: 'outcomeEndPoint',
    resize: 'resize',
    graph: 'graph',
    marquee: 'marquee',
    map: 'map',
    controlPoint: 'controlPoint',
};

export const COLLABORATION_ITEM_TYPES = {
    flow: 0,
    mapElement: 1,
    groupElement: 2,
};

export const FAULT_OUTCOME_ID = '318B0F3A-A570-4C5D-835C-21C1EEB17787';
export const FAULT_OUTCOME_DEVELOPER_NAME = 'FAULT';

export const FLOW_EDITING_TOKEN = 'b2cdc610-537e-4ca2-802c-e5277070b2e7';

export const COMMON_ICON_ATTRIBUTE_OPTIONS = [
    { label: 'Add', value: 'plus' },
    { label: 'Apply', value: 'ok' },
    { label: 'Back/Cancel', value: 'arrow-left' },
    { label: 'Delete', value: 'trash' },
    { label: 'Edit', value: 'pencil' },
    { label: 'Import', value: 'import' },
    { label: 'Insert', value: 'log-in' },
    { label: 'New', value: 'new-window' },
    { label: 'Next', value: 'arrow-right' },
    { label: 'Query', value: 'console' },
    { label: 'Reject', value: 'thumbs-down' },
    { label: 'Remove', value: 'remove' },
    { label: 'Save', value: 'floppy-disk' },
    { label: 'Submit', value: 'circle-arrow-down' },
    { label: 'Update', value: 'edit' },
    { label: 'Upsert', value: 'chevron-up' },
];

export const HEADER_ICON_MAPPING: { [key: string]: string } = {
    Organization: 'Suitcase',
    Tenant: 'Tenant',
    Environments: 'Settings',
};

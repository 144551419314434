import DataActionConfiguration from '../common/dataActions/DataActionConfiguration';
import DataActionFilter from '../common/dataActions/filters/DataActionFilter';
import FilterCriteria from '../common/dataActions/filters/FilterCriteria';
import OutcomeDetails from '../common/outcomes/OutcomeDetails';
import ScreenRouter from '../../../../../ts/components/generic/ScreenRouter';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import LoadConfiguration from './LoadConfiguration';
import ListenerDetails from '../common/listeners/ListenerDetails';

const Load = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        onReturnToDefaultScreen,
        setSelectedOutcomeIndex,
    } = useMapElement();

    return (
        <ScreenRouter currentScreen={currentScreen}>
            <LoadConfiguration screen={screens.load} />
            <DataActionConfiguration screen={screens.dataAction} />
            <DataActionFilter screen={screens.filter} />
            <FilterCriteria screen={screens.criteria} />
            <OutcomeDetails
                screen={screens.outcome}
                outcome={mapElement?.outcomes?.[selectedOutcomeIndex]}
                onSave={onReturnToDefaultScreen}
                onCancel={() => {
                    onReturnToDefaultScreen();
                    setSelectedOutcomeIndex(null);
                }}
            />
            <ListenerDetails screen={screens.listener} />
        </ScreenRouter>
    );
};

export default Load;

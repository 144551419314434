export const STATE_STATUS = {
    inProgress: 'in_progress',
    done: 'done',
    expired: 'expired',
    errored: 'errored',
};

export const TIME_FILTER = {
    lastYear: 0,
    lastThirtyDays: 1,
    lastSevenDays: 2,
    lastTwentyFourHours: 3,
};

export const TIME_INTERVAL = {
    lastYear: 'year',
    lastThirtyDays: 'month',
    lastSevenDays: 'week',
    lastTwentyFourHours: 'day',
};

export const SERIES_COLOURS = ['#0C457A', '#B4315C', '#EC672B', '#47ACAD', '#147CB4'];

import FormGroup from '../../../../../../../generic/FormGroup';
import translations from '../../../../../../../../translations';
import { isNullOrEmpty } from '../../../../../../../../utils/guard';
import type {
    ValueElementIdAPI,
    ComponentConfigurationOptions,
} from '../../../../../../../../types';
import { isListSourceConfigured } from '../../../../../../utils';
import ValueSelectorModal from '../../../../../../../values/selector/ValueSelectorModal';

interface Props {
    dataSourceList: ValueElementIdAPI | null;
    updateDataSourceList: (value: ValueElementIdAPI | null) => void;
    requiredConfiguration: ComponentConfigurationOptions;
}

const DataSourceListConfig = ({
    dataSourceList,
    updateDataSourceList,
    requiredConfiguration,
}: Props) => {
    const dataSourceListConfig = isListSourceConfigured(dataSourceList) ? dataSourceList : null;

    const { isDataSourceRequired } = requiredConfiguration;

    // Pre-selecting the data type is kinda pointless as a separate control here since we can
    // filter by type in the new value picker already! Also, the pre-selected type is not saved in
    // the DB anyway, so we would have to manually figure it out which seems a bit overkill?
    return (
        <FormGroup
            label="The List Value that holds the data"
            htmlFor="list-value"
            isValid={!isNullOrEmpty(dataSourceListConfig)}
            validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
            showValidation={true}
            isRequired={isDataSourceRequired}
        >
            <ValueSelectorModal
                value={dataSourceListConfig}
                onChange={updateDataSourceList}
                contentType="ContentList"
                includeSystemValues={false}
                container={null}
            />
        </FormGroup>
    );
};

export default DataSourceListConfig;

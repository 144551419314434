import ConfigSection from '../composer/config-panel/ConfigSection';
import PageConditionConfig from './pageConditionConfig/PageConditionConfig';
import { useEffect } from 'react';
import PageConditionItemList from './common/PageConditionItemList';
import { usePageCondition } from './PageConditionsProvider';
import ConfirmModal from '../../../../generic/modal/ConfirmModal';
import { usePageEditor } from '../../PageEditorProvider';
import translations from '../../../../../translations';
import ButtonDefault from '../../../../buttons/ButtonDefault';
import Portal from './common/Portal';
import { SUMMARY_CONTEXT } from '../../../constants';
import { defaultPageConditionConfig } from '../../../templates';

const PageConditions = () => {
    const {
        toggleUnsavedChanges,
        setModifiedPageConditions,
        state: pageBuilderState,
    } = usePageEditor();
    const { tab, page } = pageBuilderState;
    const { pageConditions = [] } = page;
    const { state: pageConditionState, updateCondition, updateConditionIndex } = usePageCondition();
    const { conditionIndex, confirmation: confirmProps } = pageConditionState;

    const footerPortalId = `sidebar-right-footer-portal-${tab?.key}`;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // Load the selected condition into the context.
        if (conditionIndex !== null && conditionIndex >= 0 && pageConditions) {
            updateCondition(pageConditions[conditionIndex]);
        }

        // A condition was canceled so any unsaved changes (rules/operations) become irrelevant.
        if (conditionIndex === null) {
            setModifiedPageConditions(false);
            toggleUnsavedChanges(false);
        }
    }, [conditionIndex]);

    const onAddNewCondition = () => {
        updateConditionIndex(-1);
        updateCondition(defaultPageConditionConfig);
        setModifiedPageConditions(true);
    };

    return (
        <>
            <h2 className="sidebar-main-heading">
                {translations.PAGE_BUILDER_page_conditions_heading}
            </h2>
            <ConfigSection dataTestId="page-conditions">
                {conditionIndex === null ? (
                    <>
                        <PageConditionItemList listContext={SUMMARY_CONTEXT['CONDITIONS']} />
                        <Portal wrapperId={footerPortalId}>
                            <footer className="sidebar-section-footer">
                                <ButtonDefault
                                    className="align-left"
                                    onClick={onAddNewCondition}
                                    title="Add new page condition"
                                >
                                    Add condition
                                </ButtonDefault>
                            </footer>
                        </Portal>
                    </>
                ) : (
                    <PageConditionConfig />
                )}
            </ConfigSection>
            {confirmProps && <ConfirmModal {...confirmProps} />}
        </>
    );
};

export default PageConditions;

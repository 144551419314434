import actionTypes from './actionTypes';
import {
    createRuntime,
    getRuntimes,
    deleteRuntime,
    getRuntime,
    updateRuntime,
    getRuntimesForTenant,
} from '../../../ts/sources/runtime';
import { addNotification } from './notification';
import { NOTIFICATION_TYPES } from '../../../ts/constants';
import { FLOW_ORG_PAGES } from '../../../ts/constants/organization';

export const setLocalRuntimes = (runtimes) => ({
    type: actionTypes.LOCAL_RUNTIME_SET,
    payload: runtimes,
});

export const setLocalRuntimesForTenant = (runtimes) => ({
    type: actionTypes.LOCAL_RUNTIME_FOR_TENANT_SET,
    payload: runtimes,
});

export const updateLocalRuntimes = (runtime) => ({
    type: actionTypes.LOCAL_RUNTIME_UPDATE,
    payload: { runtime },
});

export const setRuntimeDeleting = (payload) => ({
    type: actionTypes.LOCAL_RUNTIME_DELETING,
    payload,
});

export const removeLocalRuntime = (payload) => ({
    type: actionTypes.LOCAL_RUNTIME_DELETE,
    payload,
});

export const showLocalRuntimeLoader = () => ({
    type: actionTypes.LOCAL_RUNTIME_SHOW_LOADER,
});

export const getLocalRuntimes = () => async (dispatch) => {
    try {
        const runtimes = await getRuntimes();
        dispatch(setLocalRuntimes(runtimes));
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const getLocalRuntimesForTenant = () => async (dispatch) => {
    try {
        const runtimes = await getRuntimesForTenant();
        dispatch(setLocalRuntimesForTenant(runtimes));
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const editLocalRuntime = (id, navigateTo) => async (dispatch) => {
    dispatch(showLocalRuntimeLoader());
    try {
        const payload = await getRuntime({ id });
        dispatch(updateLocalRuntimes(payload));
        navigateTo(FLOW_ORG_PAGES.localRuntimeEdit);
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const updateLocalRuntime = (runtimeId, updateRequest, navigateTo) => async (dispatch) => {
    dispatch(showLocalRuntimeLoader());
    try {
        const payload = await updateRuntime(updateRequest, runtimeId);
        dispatch(updateLocalRuntimes(payload));

        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: `${payload.developerName} has successfully been updated`,
                isPersistent: false,
            }),
        );

        navigateTo(FLOW_ORG_PAGES.flowOrgAdminUI);
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const createLocalRuntime = (request, navigateTo) => async (dispatch) => {
    dispatch(showLocalRuntimeLoader());
    try {
        const newRuntime = await createRuntime(request);
        dispatch({
            type: actionTypes.LOCAL_RUNTIME_CREATE,
            payload: newRuntime,
        });

        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: `${newRuntime.developerName} has successfully been created`,
                isPersistent: false,
            }),
        );

        navigateTo(FLOW_ORG_PAGES.localRuntimeDisplayToken);
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const deleteLocalRuntime = (runtime) => async (dispatch) => {
    dispatch(showLocalRuntimeLoader());
    dispatch(setRuntimeDeleting(true));
    try {
        await deleteRuntime({ runtimeId: runtime.id });

        dispatch(removeLocalRuntime(runtime.id));

        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: `${runtime.developerName} has successfully been deleted`,
                isPersistent: false,
            }),
        );
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

import { useEffect, useState } from 'react';
import translations from '../../translations';

interface PageSideMenuProps {
    items: {
        link: string;
        label: string;
        visible?: boolean;
    }[];
    activeSectionId?: string | null;
}

/**
 * Side menu UI component that used for navigation within a page
 *
 * @param items Set of links for the side menu
 * @param activeSectionId Currently active section id
 */
const PageSideMenu = ({ items, activeSectionId }: PageSideMenuProps) => {
    const [activeSection, setActiveSection] = useState('');

    useEffect(() => {
        if (!activeSectionId) {
            return;
        }

        setActiveSection(`#${activeSectionId}`);
    }, [activeSectionId]);

    return (
        <nav>
            <h5 className="menu-heading">{translations.SIDE_MENU_TITLE}</h5>
            <ul className="menu-list">
                {items.flatMap(({ link, label, visible }) =>
                    visible !== false
                        ? [
                              <li key={label} className="menu-item">
                                  <a
                                      className={
                                          link === activeSection ? 'menu-link active' : 'menu-link'
                                      }
                                      href={link}
                                      onClick={() => setActiveSection(link)}
                                  >
                                      {label}
                                  </a>
                              </li>,
                          ]
                        : [],
                )}
            </ul>
        </nav>
    );
};

export default PageSideMenu;

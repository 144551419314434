import { memo } from 'react';
import type { Box } from '../../types';
import { XCircle } from '@phosphor-icons/react';

const padding = 30;

const SelectionBox = ({ x, y, width, height }: Box) => (
    <g
        data-object="selectionBox"
        data-id="selectionBox"
        transform={`matrix(1,0,0,1,${x},${y})`}
        height={height + padding * 2}
        width={width + padding * 2}
    >
        <rect
            stroke="gray"
            strokeDasharray={2}
            fill="none"
            x={-padding}
            y={-padding}
            width={width + padding * 2}
            height={height + padding * 2}
            data-testid="selection-marquee"
        />
        <foreignObject x={width + padding - 5} y={-padding - 5} width={10} height={10}>
            <XCircle
                size={10}
                weight="fill"
                className="map-element-delete"
                data-action="delete"
                data-testid="elementDeleteButton"
                alt="Delete"
            />
        </foreignObject>
    </g>
);

export default memo(SelectionBox);

import { tabDetailsObjects } from '../../../ts/config/admin';
import { TAB_TYPES } from '../../../ts/constants';
import { findMatchingTab } from '../../reducers/tabs';
import { getLatest } from '../../../ts/sources/flow';
import actionTypes from './actionTypes';
import { closeFlow } from './flows';
import { getPage } from '../../../ts/sources/page';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import { guid } from '../../../ts/utils/guid';
import { generateRouteUrl } from '../../../ts/utils/routing';

const openFlow = async (dispatch, { tenantId, icon, elementId }) => {
    const { developerName } = await getLatest(elementId, tenantId).catch(() => {
        throw Error('Could not open the requested Flow.');
    });

    return dispatch({
        type: actionTypes.TAB_OPEN,
        payload: { type: TAB_TYPES.flow, title: developerName, icon, elementId },
    });
};

const openPage = async (dispatch, { icon, elementId }) => {
    const response = await getPage(elementId).catch(() => {
        throw Error('Could not open the requested Page.');
    });

    dispatch({
        type: actionTypes.TAB_OPEN,
        payload: {
            type: TAB_TYPES.page,
            title: response.developerName,
            icon,
            elementId,
        },
    });
};

export const setTabTitle = ({ key, title }) => ({
    type: actionTypes.TAB_SET_TITLE,
    payload: { key, title },
});

// openTab should only be called as a result of the url changing
// to enable the client-side routing and browser history to work correctly
// Existing tabs are always matched by key
// type, title and elementId may be supplied to update those values on a matching tab
export const openTab =
    ({ key, type, title, elementId, tenantId }) =>
    (dispatch, getState) => {
        const tabDetails = Object.values(tabDetailsObjects).find((td) => td.type === type);
        const { icon } = tabDetails;
        const titleWithDefault = title ?? tabDetails.title;

        const { tabs } = getState();

        const existingTab = findMatchingTab({ key, type, elementId }, tabs);

        if (existingTab) {
            return dispatch({
                type: actionTypes.TAB_OPEN,
                payload: { key, type, elementId },
            });
        }

        switch (type) {
            case TAB_TYPES.flow:
                return openFlow(dispatch, { tenantId, icon, elementId });

            case TAB_TYPES.page:
                return openPage(dispatch, { tenantId, icon, elementId });

            case TAB_TYPES.newPage:
            // biome-ignore lint/suspicious/noFallthroughSwitchClause: Falling through is expected
            case TAB_TYPES.newFlow:
                if (isNullOrEmpty(elementId)) {
                    // generate a new ID for the new page/flow to use
                    elementId = guid();
                }
            default:
                return dispatch({
                    type: actionTypes.TAB_OPEN,
                    payload: {
                        key,
                        type,
                        title: titleWithDefault,
                        icon,
                        elementId,
                    },
                });
        }
    };

export const closeAllTabs = (isHomeActive) => ({
    type: actionTypes.TAB_CLOSE_ALL,
    payload: { isHomeActive },
});

export const closeTab = (key, tenantId, navigate) => (dispatch, getState) => {
    const { tabs } = getState();

    const closingTab = tabs.find((tab) => tab.key === key);
    const closingTabIndex = tabs.findIndex((tab) => tab.key === key);
    const activeTabIndex = tabs.findIndex((tab) => tab.isActive);
    const isClosingActiveTab = closingTabIndex === activeTabIndex;

    // When the tab being closed is the current active tab
    // we want the tab to the right of it to become active.
    // If the tab to the right of it doesn't exist fallback
    // to tab on left.
    if (isClosingActiveTab) {
        const nextActiveTab = tabs[activeTabIndex + 1] ?? tabs[activeTabIndex - 1];

        const { type, elementId } = nextActiveTab;

        const route = generateRouteUrl({
            tabType: type,
            tenantId,
            options: {
                tabKey: nextActiveTab.key,
                elementId,
            },
        });

        navigate(route);
    }

    dispatch({
        type: actionTypes.TAB_CLOSE,
        payload: { key },
    });

    if (closingTab.type === TAB_TYPES.flow) {
        dispatch(closeFlow(closingTab.elementId));
    }
};

export const updateTab = (payload) => ({
    type: actionTypes.TAB_UPDATE,
    payload,
});

export const updateUrlAndTab =
    ({ key, type, title, elementId, tenantId }, navigate) =>
    (dispatch) => {
        // Re-navigate and update the URL
        const route = generateRouteUrl({
            tabType: type,
            tenantId,
            options: {
                tabKey: key,
                ...(elementId && { elementId }),
            },
        });

        navigate(route);

        dispatch(
            updateTab({
                key,
                title,
                elementId,
            }),
        );
    };

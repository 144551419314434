import BarChartImg from '../../../../../../img/components/Bar-chart.svg';
import DoughnutChartImg from '../../../../../../img/components/Doughnut-chart.svg';
import LineChartImg from '../../../../../../img/components/Line-chart.svg';
import PieChartImg from '../../../../../../img/components/Pie-chart.svg';
import PolarChartImg from '../../../../../../img/components/Polar-chart.svg';
import PreviewComponentLabel from '../../../../../ts/components/page-editor/components/preview/previewComponentLabel/PreviewComponentLabel';

import type { PageComponentName } from '../../../../types';
import { COMPONENT_TYPE } from '../../constants';

interface BaseProps {
    isRequired: boolean;
    label: string;
}

interface Props extends BaseProps {
    componentType: PageComponentName;
}

export const BarChartComponent = ({ isRequired, label }: BaseProps) =>
    ChartComponent({ componentType: COMPONENT_TYPE['CHARTBAR'], isRequired, label });
export const LineChartComponent = ({ isRequired, label }: BaseProps) =>
    ChartComponent({ componentType: COMPONENT_TYPE['CHARTLINE'], isRequired, label });
export const PieChartComponent = ({ isRequired, label }: BaseProps) =>
    ChartComponent({ componentType: COMPONENT_TYPE['CHARTPIE'], isRequired, label });
export const DoughnutChartComponent = ({ isRequired, label }: BaseProps) =>
    ChartComponent({ componentType: COMPONENT_TYPE['CHARTDOUGHNUT'], isRequired, label });
export const PolarChartComponent = ({ isRequired, label }: BaseProps) =>
    ChartComponent({ componentType: COMPONENT_TYPE['CHARTPOLAR'], isRequired, label });

const ChartComponent = ({ componentType, isRequired, label }: Props) => {
    const type = {
        [COMPONENT_TYPE['chartBar']]: BarChartImg,
        [COMPONENT_TYPE['chartLine']]: LineChartImg,
        [COMPONENT_TYPE['chartPie']]: PieChartImg,
        [COMPONENT_TYPE['chartDoughnut']]: DoughnutChartImg,
        [COMPONENT_TYPE['chartPolar']]: PolarChartImg,
    };

    const src = type[componentType.toLowerCase()];

    return (
        <div data-testid="component-preview-chart">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            <div className="component-preview-image-container">
                <img
                    data-testid="component-preview-chart"
                    className="component-preview-image"
                    alt=""
                    src={src}
                />
            </div>
        </div>
    );
};

export default ChartComponent;

import { useState, createContext, useContext, useEffect, type ReactNode, useCallback } from 'react';
import {
    deleteCustomPageComponent,
    getCustomPageComponentDependents,
    getCustomPageComponents,
    saveCustomPageComponent,
    type CustomPageComponentAPI,
    type CustomPageComponentDependent,
} from '../../../sources/customPageComponents';
import { uploadFile, downloadFile } from '../../../utils/ajax';
import { componentRegistry } from '../../page-editor/registry';
import { isNullOrEmpty } from '../../../utils/guard';
import { notifyError, notifySuccess } from '../../../../js/actions/reduxActions/notification';
import { connect, type ConnectedProps } from 'react-redux';
import { ElementType, type Filter, type PackageElement } from '../../../types';

type CustomPageComponentsProviderProps = {
    tenantId: string;
    children?: ReactNode;
} & ConnectedProps<typeof connector>;

type ComponentScreens = (typeof COMPONENT_SCREENS)[keyof typeof COMPONENT_SCREENS];

type Paging = Filter & { total: number };

type CustomPageComponentsContext = {
    COMPONENT_SCREENS: typeof COMPONENT_SCREENS;
    currentScreen: ComponentScreens;
    setCurrentScreen: React.Dispatch<React.SetStateAction<ComponentScreens>>;
    components: CustomPageComponentAPI[];
    editingComponent: CustomPageComponentAPI | null;
    setEditingComponent: React.Dispatch<React.SetStateAction<CustomPageComponentAPI | null>>;
    exportComponent: (item: CustomPageComponentAPI) => Promise<void>;
    tryImportComponent: (files: File[]) => XMLHttpRequest;
    fetchPageComponents: ({
        page,
        pageSize,
        search,
        orderBy,
        orderDirection,
    }: Filter) => Promise<void>;
    deleteComponent: (id: string) => Promise<void>;
    saveEditingComponent: (override?: boolean) => Promise<void>;
    editNewComponent: () => void;
    paging: Paging;
    updatePaging: React.Dispatch<React.SetStateAction<Paging>>;
    conflict: PackageElement | null;
    pendingImport: File[] | null;
    closeConflictModal: () => void;
    confirmImportOverwrite: () => void;
    tryDeleteComponent: (component: CustomPageComponentAPI) => Promise<void>;
    closeDependentModal: () => void;
    pendingDeleteComponent: CustomPageComponentAPI | null;
    disconnectedDependentsList: CustomPageComponentDependent[] | null;
    connectedDependentsList: CustomPageComponentDependent[] | null;
    standardComponentOverridden: string | null;
    componentsLoading: boolean;
};

const connector = connect(null, { notifyError, notifySuccess });

const Context = createContext<CustomPageComponentsContext | undefined>(undefined);

const COMPONENT_SCREENS = {
    componentList: 'componentList',
    componentDetail: 'componentDetail',
} as const;

const initialComponent: CustomPageComponentAPI = {
    attributes: {},
    configurationEditors: [],
    designTimeImageURL: '',
    designTimeRenderType: '',
    developerName: null,
    developerSummary: null,
    elementType: ElementType.Custom,
    icon: '',
    id: '',
    key: '',
    scriptURL: '',
    styleSheetURL: '',
    legacyScriptURL: '',
    legacyStyleSheetURL: '',
};

const createFilter = ({
    page,
    pageSize,
    search,
    orderBy,
    orderDirection,
}: { [Key in keyof Paging]?: Paging[Key] | undefined }) => {
    const filter: Filter = {
        page: page ?? 0,
        pageSize: pageSize ?? 20,
        search,
        orderBy,
        orderDirection,
    };

    return filter;
};

const CustomPageComponentsProvider = ({
    notifyError,
    notifySuccess,
    tenantId,
    children,
}: CustomPageComponentsProviderProps) => {
    const [currentScreen, setCurrentScreen] = useState<ComponentScreens>(
        COMPONENT_SCREENS.componentList,
    );
    const [components, setComponents] = useState<CustomPageComponentAPI[]>([]);
    const [componentsLoading, setComponentsLoading] = useState(true);
    const [editingComponent, setEditingComponent] = useState<CustomPageComponentAPI | null>(null);
    const [paging, updatePaging] = useState<Paging>({
        page: 1,
        pageSize: 20,
        total: 0,
        search: '',
        orderBy: 'dateModified',
        orderDirection: 'DESC',
    });
    const [conflict, setConflict] = useState<PackageElement | null>(null);
    const [pendingImport, setPendingImport] = useState<File[] | null>(null);
    const [pendingDeleteComponent, setPendingDeleteComponent] =
        useState<CustomPageComponentAPI | null>(null);
    const [disconnectedDependentsList, setDisconnectedDependentsList] = useState<
        CustomPageComponentDependent[] | null
    >(null);
    const [connectedDependentsList, setConnectedDependentsList] = useState<
        CustomPageComponentDependent[] | null
    >(null);
    const [standardComponentOverridden, setStandardComponentOverridden] = useState<string | null>(
        null,
    );

    const editNewComponent = useCallback(() => {
        setEditingComponent(initialComponent);
    }, []);

    const closeConflictModal = useCallback(() => {
        setConflict(null);
    }, []);

    const clearDependentModalData = useCallback(() => {
        setStandardComponentOverridden(null);
        setDisconnectedDependentsList(null);
        setConnectedDependentsList(null);
    }, []);

    const fetchPageComponents = useCallback(
        async ({ page = 0, pageSize = 20, search, orderBy, orderDirection }: Filter) => {
            try {
                setComponentsLoading(true);

                const filter: Filter = {
                    page,
                    pageSize,
                    search,
                    orderBy,
                    orderDirection,
                };

                const componentsResults = await getCustomPageComponents(filter);

                setComponents(componentsResults.items);

                const newPaging: Paging = {
                    ...filter,
                    ...componentsResults._meta,
                };

                updatePaging(newPaging);
            } catch (error) {
                notifyError(error);
            } finally {
                setComponentsLoading(false);
            }
        },
        [notifyError],
    );

    const exportComponent = useCallback(
        async (item: CustomPageComponentAPI) => {
            try {
                await downloadFile(
                    `/api/draw/1/element/customPageComponent/${item.id}`,
                    `${item.developerName}.component`,
                    tenantId,
                );
            } catch (error) {
                notifyError(error);
            }
        },
        [tenantId, notifyError],
    );

    const importComponent = useCallback(
        (files: File[], overwrite: boolean) => {
            return uploadFile({
                file: files[0],
                tenantId,
                url: `/api/draw/1/element/customPageComponent?overwriteExisting=${overwrite}`,
                completeHandler: function () {
                    if (this.status >= 200 && this.status < 400) {
                        notifySuccess('Component successfully imported');
                        fetchPageComponents(
                            createFilter({
                                page: paging.page,
                                pageSize: paging.pageSize,
                                search: paging.search,
                                orderBy: paging.orderBy,
                                orderDirection: paging.orderDirection,
                            }),
                        );
                        setPendingImport(null);
                        return;
                    }
                    if (this.status === 409) {
                        setConflict(JSON.parse(this.response));
                        setPendingImport(files);
                        return;
                    }
                    notifyError(JSON.parse(this.responseText));
                },
                sendAsJSON: true,
                // If the status is 409, do not throw an error
                errorHandler: function () {
                    return this.status === 409 && notifyError;
                },
            });
        },
        [
            fetchPageComponents,
            notifyError,
            notifySuccess,
            tenantId,
            paging.page,
            paging.pageSize,
            paging.search,
            paging.orderBy,
            paging.orderDirection,
        ],
    );

    const tryImportComponent = useCallback(
        (files: File[]) => {
            return importComponent(files, false);
        },
        [importComponent],
    );

    const confirmImportOverwrite = useCallback(() => {
        if (!pendingImport) {
            return;
        }
        setConflict(null);
        importComponent(pendingImport, true);
    }, [importComponent, pendingImport]);

    const tryDeleteComponent = useCallback(
        async (component: CustomPageComponentAPI) => {
            try {
                const dependents = await getCustomPageComponentDependents({ key: component.key });
                setDisconnectedDependentsList(dependents);
                setPendingDeleteComponent(component);
            } catch (error) {
                notifyError(error);
            }
        },
        [notifyError],
    );

    const deleteComponent = useCallback(
        async (id: string) => {
            try {
                await deleteCustomPageComponent({ id });

                notifySuccess('Component successfully deleted');

                setDisconnectedDependentsList(null);

                await fetchPageComponents(
                    createFilter({
                        page: paging.page,
                        pageSize: paging.pageSize,
                        search: paging.search,
                        orderBy: paging.orderBy,
                        orderDirection: paging.orderDirection,
                    }),
                );
            } catch (error) {
                notifyError(error);
            }
        },
        [
            fetchPageComponents,
            notifyError,
            notifySuccess,
            paging.page,
            paging.pageSize,
            paging.search,
            paging.orderBy,
            paging.orderDirection,
        ],
    );

    const saveEditingComponent = useCallback(
        async (override = false) => {
            if (!editingComponent) {
                return;
            }

            const previousComponent = components.find(({ id }) => id === editingComponent.id);
            // Only run these checks if it's a new component (no previousComponent)
            // or if they've changed the key
            if (
                !override &&
                (isNullOrEmpty(previousComponent) || editingComponent.key !== previousComponent.key)
            ) {
                let impact = false;
                // Disconnection impact

                // A key change could disconnect the component from the pages it's used in
                // and cause them to show 'unknown component' errors at runtime
                // Or could return an overridden component to our default one
                // e.g. 'input' could have been overridden, and with this key rename from 'input' to 'other' it no longer will
                if (previousComponent) {
                    try {
                        const disconnectedDependents = await getCustomPageComponentDependents({
                            key: previousComponent.key,
                        });

                        if (disconnectedDependents.length > 0) {
                            setDisconnectedDependentsList(disconnectedDependents);
                            impact = true;
                        }
                    } catch (error) {
                        notifyError(error);
                    }
                }

                // Connection impact

                // A key change could connect the component from the pages it's not currently used in
                // and cause them to use this custom component now at runtime
                try {
                    const connectedDependents = await getCustomPageComponentDependents({
                        key: editingComponent.key,
                    });

                    if (connectedDependents.length > 0) {
                        setConnectedDependentsList(connectedDependents);
                        impact = true;
                    }
                } catch (error) {
                    notifyError(error);
                }

                // Standard component impact

                // A key change could override a standard component,
                // which is a niche use case,
                // so we warn the user to make sure they understand they will no longer be able to use the standard component
                const standardComponentOverridden =
                    componentRegistry[editingComponent.key.toUpperCase()];

                if (standardComponentOverridden) {
                    setStandardComponentOverridden(standardComponentOverridden.ui.caption);
                    impact = true;
                }

                if (impact) {
                    return;
                }
            }

            clearDependentModalData();

            // Otherwise save the component
            try {
                await saveCustomPageComponent({
                    request: { ...editingComponent, key: editingComponent.key?.toLowerCase() },
                });

                await fetchPageComponents(
                    createFilter({
                        page: paging.page,
                        pageSize: paging.pageSize,
                        search: paging.search,
                        orderBy: paging.orderBy,
                        orderDirection: paging.orderDirection,
                    }),
                );
                setCurrentScreen(COMPONENT_SCREENS.componentList);
            } catch (error) {
                notifyError(error);
            }
        },
        [
            clearDependentModalData,
            fetchPageComponents,
            notifyError,
            components,
            editingComponent,
            paging.page,
            paging.pageSize,
            paging.search,
            paging.orderBy,
            paging.orderDirection,
        ],
    );

    useEffect(() => {
        const filter: Filter = {
            page: paging.page ?? 0,
            pageSize: paging.pageSize ?? 20,
            search: paging.search,
            orderBy: paging.orderBy,
            orderDirection: paging.orderDirection,
        };

        fetchPageComponents(filter);
    }, [
        fetchPageComponents,
        // List individual properties so the Object.is comparison checks values rather than object reference
        paging.page,
        paging.pageSize,
        paging.search,
        paging.orderBy,
        paging.orderDirection,
    ]);

    const contextValue: CustomPageComponentsContext = {
        COMPONENT_SCREENS,
        currentScreen,
        components,
        editingComponent,
        paging,
        conflict,
        pendingImport,
        pendingDeleteComponent,
        disconnectedDependentsList,
        connectedDependentsList,
        standardComponentOverridden,
        componentsLoading,
        setCurrentScreen,
        setEditingComponent,
        exportComponent,
        tryImportComponent,
        fetchPageComponents,
        deleteComponent,
        saveEditingComponent,
        editNewComponent,
        updatePaging,
        closeConflictModal,
        confirmImportOverwrite,
        tryDeleteComponent,
        closeDependentModal: clearDependentModalData,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const ConnectedCustomPageComponentsProvider = connector(CustomPageComponentsProvider);

const useComponents = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useComponents must be used within a CustomPageComponentsProvider');
    }
    return context;
};

export { ConnectedCustomPageComponentsProvider as CustomPageComponentsProvider, useComponents };

import { useEffect, useState } from 'react';
import { isNullOrEmpty } from '../../../../../../../../../utils/guard';
import type { ListFilter, ValueElementIdAPI } from '../../../../../../../../../types';

import FormGroup from '../../../../../../../../generic/FormGroup';
import translations from '../../../../../../../../../translations';
import ValueSelectorModal from '../../../../../../../../values/selector/ValueSelectorModal';

interface Props {
    dataSourceFilter: ListFilter | null;
    updateDataSourceFilter: (listFilter: ListFilter | null) => void;
}

const FilterUniqueConfig = ({ dataSourceFilter, updateDataSourceFilter }: Props) => {
    const uniqueFilter = isNullOrEmpty(dataSourceFilter?.filterId)
        ? null
        : dataSourceFilter?.filterId;

    const [selectedUniqueFilter, setSelectedUniqueFilter] = useState<
        ValueElementIdAPI | null | undefined
    >(uniqueFilter);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (uniqueFilter && uniqueFilter.id !== selectedUniqueFilter?.id) {
            setSelectedUniqueFilter(uniqueFilter);
        }

        // Previously selected filter was removed.
        if (!uniqueFilter && uniqueFilter !== selectedUniqueFilter) {
            setSelectedUniqueFilter(uniqueFilter);
        }
    }, []);

    // Update the data source config context with the selected unique ID filter, but only if the
    // filter has really changed.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const hasChanged = uniqueFilter?.id !== selectedUniqueFilter?.id;

        if (hasChanged && dataSourceFilter) {
            const updated = {
                ...dataSourceFilter,
                // Setting to empty {} so when filter is just removed this still gets picked up
                // later in componentDataSourceFilter, so we can continue with same filter type
                // as before.
                filterId: selectedUniqueFilter ? selectedUniqueFilter : ({} as ValueElementIdAPI),
            };
            updateDataSourceFilter(updated);
        }
    }, [selectedUniqueFilter]);

    return (
        <FormGroup
            label="Load the data with the following identifier"
            htmlFor="unique-value"
            isValid={!isNullOrEmpty(selectedUniqueFilter)}
            validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
            showValidation={true}
            isRequired
        >
            <ValueSelectorModal
                value={selectedUniqueFilter ?? null}
                onChange={setSelectedUniqueFilter}
                typeElementId={selectedUniqueFilter?.typeElementPropertyId ?? null}
                container={null}
                includeSystemValues={false}
            />
        </FormGroup>
    );
};

export default FilterUniqueConfig;

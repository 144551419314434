import screens from '../common/screens';
import DecisionRouter from './DecisionRouter';

import { MapElementProvider } from '../contextProviders/MapElementProvider';
import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';

const Decision = (props) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.decision}
            elementType={MAP_ELEMENT_TYPES.decision}
        >
            <MapElementModal>
                <DecisionRouter />
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Decision;

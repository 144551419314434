// Checks if a piece of text fits within a supplied width so a tooltip can be provided.
// Most useful for table cells with long piece of text that we don't want to wrap.
export const isTooltipRequired = (text: string, remWidth: number, fontSize = 16) => {
    const textSpan = document.createElement('span');
    document.body.appendChild(textSpan);
    textSpan.style.fontFamily = 'Open Sans sans-serif'; // Not loads of difference between fonts but change this if it becomes inaccurate.
    textSpan.style.fontSize = `${fontSize}px`;
    textSpan.style.width = 'auto';
    textSpan.style.opacity = '0';
    textSpan.style.position = 'absolute';
    textSpan.style.whiteSpace = 'no-wrap';
    textSpan.innerHTML = text;
    const width = textSpan.clientWidth;
    document.body.removeChild(textSpan);
    return width > remWidth * fontSize; // rem width * font size is the pixel width.
};

export const getActiveAdminContainer = () => {
    const possibleContainers = document.getElementsByClassName('admin active');

    return possibleContainers.length > 0 ? possibleContainers[0] : null;
};

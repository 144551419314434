/** default is the string 'top' */
export const NAVIGATION_POSITION = {
    top: 'top',
    left: 'left',
};

export const URL_TARGET = {
    BLANK: '_blank',
    SELF: '_self',
};

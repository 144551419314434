import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/notification.less';
import { addNotification } from '../../../js/actions/reduxActions/notification';
import ConfirmModal from '../generic/modal/ConfirmModal';
import { NOTIFICATION_TYPES } from '../../constants';
import EditorDocumentSelect from '../../components/shared/editor/EditorDocumentSelect';
import { getActivated } from '../../sources/flow';
import type { PlayerContent, PlayerName } from '../../sources/player';
import {
    deletePlayer,
    getAllPlayerNames,
    getPlayerContent,
    savePlayerContent,
} from '../../sources/player';
import translations from '../../translations';
import PersistentNotification from '../notifications/PersistentNotification';
import Loader from '../loader/Loader';
import Editor from '../shared/editor/Editor';
import EditorActionsPanel from '../shared/editor/EditorActionsPanel';
import EditorHeader from '../shared/editor/EditorHeader';
import { useAuth } from '../AuthProvider';
import NewPlayerModal from './NewPlayerModal';
import './player-editor.less';
import PlayerPreviewModal from './PlayerPreviewModal';
import PlayerPreviewPanel from './PlayerPreviewPanel';
import type { SingleValue } from 'react-select';
import { stringReplace } from '../../utils/string';

interface SelectOption {
    value: string;
    label: string;
}

export interface PlayerEditorProps {
    addNotification: (notification?: unknown) => void;
    // Used to trigger fetches so that editor stays up to date with player and flow imports
    isActive: boolean;
}

export interface Flow {
    id: string;
    versionId: string;
    name: string;
}

const PlayerEditor = ({ addNotification, isActive }: PlayerEditorProps) => {
    const [showDeletePlayerModal, setShowDeletePlayerModal] = useState(false);
    const [showCreatePlayerModal, setShowCreatePlayerModal] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [selectedPlayerContent, setSelectedPlayerContent] = useState<PlayerContent>('');
    const [playerNames, setPlayerNames] = useState<PlayerName[]>([]);
    const [selectedPlayerName, setSelectedPlayerName] = useState('');
    const [flows, setFlows] = useState<Flow[]>([]);
    const [selectedFlow, setSelectedFlow] = useState<Flow>();
    const [loading, setLoading] = useState(false);
    const { tenant } = useAuth();
    const tenantId = (() => {
        if (!tenant?.id) {
            throw new Error(translations.PLAYER_no_tenant_id_message);
        }

        return tenant.id;
    })();
    const modalContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        resetEditor();
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        const loadAllPlayerNames = async () => {
            try {
                setLoading(true);

                const loadedPlayerNames = await getAllPlayerNames();

                if (ignore) {
                    return;
                }

                setPlayerNames(loadedPlayerNames.sort());
            } catch (error) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: (error as Error).message,
                    isPersistent: true,
                });
            } finally {
                setLoading(false);
            }
        };

        let ignore = false;

        loadAllPlayerNames();

        return () => {
            ignore = true;
        };
    }, [addNotification, tenantId, isActive]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        const loadActiveFlows = async () => {
            try {
                setLoading(true);

                const loadedFlows = await getActivated(tenantId);

                if (ignore) {
                    return;
                }

                const mappedFlows = loadedFlows
                    .map((flow) => ({
                        id: flow.id.id,
                        versionId: flow.id.versionId,
                        name: flow.developerName,
                    }))
                    .sort(({ name: nameFirst }, { name: nameSecond }) => {
                        const nameFirstUpper = nameFirst.toUpperCase();
                        const nameSecondUpper = nameSecond.toUpperCase();

                        if (nameFirstUpper < nameSecondUpper) {
                            return -1;
                        }
                        if (nameFirstUpper === nameSecondUpper) {
                            return 0;
                        }
                        return 1;
                    });

                setFlows(mappedFlows);
            } catch (error) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: (error as Error).message,
                    isPersistent: true,
                });
            } finally {
                setLoading(false);
            }
        };

        let ignore = false;

        loadActiveFlows();

        return () => {
            ignore = true;
        };
    }, [addNotification, tenantId, isActive]);

    const resetEditor = () => {
        setSelectedPlayerName('');
        setSelectedPlayerContent('');
        setUnsavedChanges(false);
    };

    const loadPlayerContent = async (name: string) => {
        try {
            setLoading(true);

            const playerContent = await getPlayerContent(name);

            setSelectedPlayerContent(playerContent);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        if (!selectedPlayerName) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.PLAYER_no_player_selected_message,
                isPersistent: false,
            });
            return;
        }

        try {
            setLoading(true);

            await savePlayerContent(selectedPlayerName, selectedPlayerContent);

            setUnsavedChanges(false);

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: translations.PLAYER_save_successful_message,
                isPersistent: false,
            });
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = async () => {
        if (!selectedPlayerName) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.PLAYER_no_player_selected_message,
                isPersistent: false,
            });
            return;
        }

        await loadPlayerContent(selectedPlayerName);
        setUnsavedChanges(false);
    };

    const handleDelete = () => {
        if (!selectedPlayerName) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.PLAYER_no_player_selected_message,
                isPersistent: false,
            });
            return;
        }

        setShowDeletePlayerModal(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            setLoading(true);

            await deletePlayer(selectedPlayerName);

            setPlayerNames((names) => names.filter((name) => name !== selectedPlayerName).sort());

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: translations.PLAYER_delete_successful_message,
                isPersistent: false,
            });

            setShowDeletePlayerModal(false);
            resetEditor();
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCancel = () => {
        setShowDeletePlayerModal(false);
    };

    const handleTogglePreview = () => {
        if (!(selectedPlayerName || selectedFlow)) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.PLAYER_no_player_or_flow_selected_message,
                isPersistent: false,
            });
            return;
        }
        if (!selectedPlayerName) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.PLAYER_no_player_selected_message,
                isPersistent: false,
            });
            return;
        }
        if (!selectedFlow) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.PLAYER_no_flow_selected_message,
                isPersistent: false,
            });
            return;
        }

        setShowPreview(!showPreview);
    };

    const handlePlayerSelectionChange = async (
        option: SingleValue<SelectOption | undefined> | null,
    ) => {
        if (!(option?.value && option?.label)) {
            return;
        }

        await loadPlayerContent(option.value);

        setSelectedPlayerName(option.label);
        setUnsavedChanges(false);
    };

    const handleFlowSelectionChange = (option: SingleValue<SelectOption | undefined> | null) => {
        if (!option?.value) {
            return;
        }

        const flow = flows?.find((flow) => flow.id === option.value);

        setSelectedFlow(flow);
    };

    const handleCodeChange = (value: string) => {
        setSelectedPlayerContent(value);
        setUnsavedChanges(true);
    };

    const handleOpenNewPlayerModal = () => {
        setShowCreatePlayerModal(true);
    };

    const handleCloseNewPlayerModal = () => {
        setShowCreatePlayerModal(false);
    };

    const handleCreatePlayerConfirm = async (name: string, base: string) => {
        try {
            setLoading(true);

            const basePlayerContent = await getPlayerContent(base);
            const newPlayerContent = await savePlayerContent(name, basePlayerContent);

            setSelectedPlayerContent(newPlayerContent);
            setPlayerNames([...playerNames, name].sort());

            setSelectedPlayerName(name);
            setUnsavedChanges(false);

            addNotification({
                type: NOTIFICATION_TYPES.success,
                message: translations.PLAYER_create_successful_message,
                isPersistent: false,
            });
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const playerOptions = playerNames?.map((player) => ({ value: player, label: player }));

    const flowOptions = flows?.map((flow) => ({ value: flow.id, label: flow.name, id: flow.id }));

    const selectedPlayerOption = playerOptions?.find(
        (player) => player.label === selectedPlayerName,
    ) ?? {
        label: translations.PLAYER_player_select_default_option_label,
        value: '',
    };

    const selectedFlowOption = flowOptions?.find((flow) => flow.id === selectedFlow?.id) ?? {
        label: translations.PLAYER_flow_select_default_option_label,
        value: '',
    };

    return (
        <Editor
            className="player-editor"
            readOnly={!selectedPlayerName}
            mode="html"
            value={selectedPlayerContent}
            onChange={handleCodeChange}
            ref={modalContainerRef}
        >
            {loading ? <Loader /> : null}
            <EditorHeader className="player-editor-header">
                <EditorDocumentSelect
                    className="player-select"
                    handleChange={handlePlayerSelectionChange}
                    id="player-select"
                    label={translations.PLAYER_player_select_label}
                    options={playerOptions}
                    value={selectedPlayerOption}
                />
                <EditorActionsPanel
                    className="player-actions"
                    onNew={handleOpenNewPlayerModal}
                    onSave={handleSave}
                    onDelete={handleDelete}
                    onRefresh={handleRefresh}
                />
                <PlayerPreviewPanel
                    className="flow-select"
                    handleChange={handleFlowSelectionChange}
                    onTogglePreview={handleTogglePreview}
                    value={selectedFlowOption}
                    showPreview={showPreview}
                    options={flowOptions}
                />
            </EditorHeader>
            <PlayerPreviewModal
                tenantId={tenantId}
                selectedFlow={selectedFlow}
                selectedPlayerName={selectedPlayerName}
                showPreview={showPreview}
                onClose={handleTogglePreview}
            />
            <PersistentNotification
                show={unsavedChanges}
                message={translations.PLAYER_unsaved_changes_messages}
            />
            <NewPlayerModal
                show={showCreatePlayerModal}
                playerOptions={playerOptions}
                onClose={handleCloseNewPlayerModal}
                onSave={handleCreatePlayerConfirm}
                container={modalContainerRef.current}
            />
            <ConfirmModal
                show={showDeletePlayerModal}
                title={translations.PLAYER_delete_confirmation_title}
                messages={[
                    stringReplace(translations.PLAYER_delete_confirmation_message, {
                        playerName: selectedPlayerName,
                    }),
                    translations.GENERAL_cannot_be_undone,
                ]}
                buttonStyle="danger"
                buttonCaption={translations.PLAYER_delete_player_modal_delete_button_label}
                onCancel={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                isInProgress={loading}
                container={modalContainerRef.current}
            />
        </Editor>
    );
};

const mapDispatchToProps = {
    addNotification,
};

export default connect(null, mapDispatchToProps)(PlayerEditor);

import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import { FRIENDLY_DEPENDENCY_NAMES } from '../../../ts/constants';
import translations from '../../../ts/translations';
import { Trash } from '@phosphor-icons/react';
import Table from '../../../ts/components/generic/Table';

const SharedElementsTable = ({
    tableData,
    elementType,
    isImportPage,
    importElement,
    removeElement,
}) => {
    const excludeSummaryRow = elementType === FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT;

    const tableColumns = () => {
        const columns = [];

        columns.push({
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item.developerName,
        });

        if (!excludeSummaryRow) {
            columns.push({
                renderHeader: () => translations.COMMON_TABLE_summary,
                renderCell: ({ item }) => item.developerSummary,
            });
        }

        columns.push({
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) =>
                isImportPage ? (
                    <div className="action-btn-wrapper">
                        <ButtonDefault
                            title={`Import ${item.developerName}`}
                            className="btn-sm"
                            aria-label={`Import ${item.developerName}`}
                            onClick={() => importElement(item)}
                        >
                            Import
                        </ButtonDefault>
                    </div>
                ) : (
                    <div className="action-btn-wrapper">
                        <button
                            title={`Delete ${item.developerName}`}
                            className="table-icon table-icon-delete"
                            aria-label={`Delete ${item.developerName}`}
                            onClick={() => removeElement(item)}
                            type="button"
                        >
                            <Trash />
                        </button>
                    </div>
                ),
            size: '6rem',
        });

        return columns;
    };

    return (
        <Table
            items={tableData}
            columns={tableColumns()}
            pagination={true}
            testId={isImportPage ? 'shared-elements-import' : 'shared-elements-main'}
        />
    );
};

export default SharedElementsTable;

import classNames from 'classnames';
import { ArrowClockwise, FloppyDisk, Plus, Trash } from '@phosphor-icons/react';
import translations from '../../../translations';
import ButtonDanger from '../../buttons/ButtonDanger';
import ButtonDefault from '../../buttons/ButtonDefault';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import './editor.less';

export interface EditorActionsPanelProps {
    className?: string | undefined;
    onNew: () => void;
    onSave: () => void;
    onRefresh: () => void;
    onDelete: () => void;
}

const EditorActionsPanel = ({
    className,
    onNew: handleCreate,
    onSave: handleSave,
    onRefresh: handleRefresh,
    onDelete: handleDelete,
}: EditorActionsPanelProps) => {
    const containerClasses = classNames('editor-actions-panel-container', className);

    return (
        <div className={containerClasses}>
            <span className="editor-header-label">{translations.EDITOR_actions_label}</span>
            <div className="editor-action-buttons">
                <ButtonPrimary onClick={handleCreate}>
                    <Plus size={16} weight="bold" className="editor-action-button-icon" />
                    {translations.EDITOR_new_button_label}
                </ButtonPrimary>
                <ButtonPrimary onClick={handleSave}>
                    <FloppyDisk size={16} weight="bold" className="editor-action-button-icon" />
                    {translations.EDITOR_save_button_label}
                </ButtonPrimary>
                <ButtonDefault onClick={handleRefresh}>
                    <ArrowClockwise size={16} weight="bold" className="editor-action-button-icon" />
                    {translations.EDITOR_refresh_button_label}
                </ButtonDefault>
                <ButtonDanger onClick={handleDelete}>
                    <Trash size={16} weight="bold" className="editor-action-button-icon" />
                    {translations.EDITOR_delete_button_label}
                </ButtonDanger>
            </div>
        </div>
    );
};

export default EditorActionsPanel;

import TenantName from '../generic/TenantName';
import translations from '../../translations';
import Loader from '../loader/Loader';
import CopyableText from '../generic/CopyableText';
import Modal from '../generic/modal/GenericModal';
import { connect } from 'react-redux';
import { addNotification } from '../../../js/actions/reduxActions/notification';
import { getUser } from '../../sources/user';
import { useAuth } from '../AuthProvider';
import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import type { UserTenantResponseAPI } from '../../types/user';
import '../../../../css/tenant-select.less';
import type { AddNotification } from '../../types';
import classNames from 'classnames';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import { getDateDisplay } from '../../utils/date';
import { NOTIFICATION_TYPES } from '../../constants';
import { isNullOrEmpty } from '../../utils/guard';
import { stringContains } from '../../utils/string';

export const matchesSearchQuery = (tenant: UserTenantResponseAPI, query: string) => {
    const organizationName = pathOr(null, ['organization', 'name'], tenant);
    const searchValues = [
        tenant.developerName,
        tenant.developerSummary,
        tenant.id,
        organizationName,
    ];

    return searchValues.some((val) => stringContains(val, query, false));
};

interface TenantSwitcherProps {
    addNotification: AddNotification;
    onClose: () => void;
}

const emails = {
    support: 'support@boomi.com',
};

const TenantSwitcher = ({ addNotification, onClose }: TenantSwitcherProps) => {
    const { tenant, switchTenant, user, fetchUser } = useAuth();

    const [search, setSearch] = useState('');
    const [tenantsToDisplay, setTenantsToDisplay] = useState<UserTenantResponseAPI[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchTenants = async () => {
        try {
            const user = await getUser();
            setTenantsToDisplay(user.tenants);
            fetchUser();
            setIsLoading(false);
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).toString(),
                isPersistent: true,
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        fetchTenants();
    }, []);

    const onClick = (tenantId: string) => {
        switchTenant(tenantId);
    };

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const tenants = tenantsToDisplay
        .filter((tenant) => matchesSearchQuery(tenant, search))
        .map((listedTenant) => {
            const lastLoggedInAt =
                listedTenant.lastLoggedInAt === null
                    ? 'Never'
                    : getDateDisplay(listedTenant.lastLoggedInAt);

            const expiresAt =
                listedTenant.expiresAt === null ? '' : getDateDisplay(listedTenant.expiresAt);

            const isActiveTenant = listedTenant.id === tenant?.id;

            const className = classNames('list-group-item', {
                active: isActiveTenant,
            });

            return (
                <button
                    type="button"
                    className={className}
                    onClick={() => onClick(listedTenant.id)}
                    key={listedTenant.id}
                    disabled={listedTenant.isExpired}
                >
                    <h4 className="list-group-item-heading">
                        <TenantName name={listedTenant.developerName} />
                    </h4>
                    <div className="tenant-id">{listedTenant.id}</div>
                    {listedTenant.organization ? (
                        <p className="list-group-item-text">{listedTenant.organization.name}</p>
                    ) : null}
                    <p className="list-group-item-text">{listedTenant.developerSummary}</p>
                    {isNullOrEmpty(expiresAt) ? null : (
                        <p className="list-group-item-text margin-top-small tenant-last-login">
                            {listedTenant.isExpired
                                ? `Your trial expired on ${expiresAt} and has been deactivated.`
                                : `Tenant Expires: ${expiresAt}`}
                        </p>
                    )}
                    <p className="list-group-item-text tenant-last-login">{`Last Login: ${lastLoggedInAt}`}</p>
                </button>
            );
        });

    let tenantList: JSX.Element | undefined;
    if (tenants.length) {
        tenantList = (
            <div className="list-group" data-testid="tenant-switcher-list">
                {tenants}
            </div>
        );
    } else if (!tenants.length && search) {
        tenantList = (
            <ExAlertBanner className="search-empty" type={AlertBannerType.WARNING} open>
                Sorry, it doesn’t look like your search matches any tenants. Please adjust your
                search. If you are having trouble please contact your administrator.
            </ExAlertBanner>
        );
    } else if (user?.id) {
        tenantList = (
            <ExAlertBanner className="tenants-empty" type={AlertBannerType.WARNING} open>
                <div>
                    {translations.TENANT_switcher_no_tenants_message_1}
                    <br />
                    <br />
                    {translations.TENANT_switcher_no_tenants_message_2}
                    <b>
                        <CopyableText copyableText={user.id} hasCopyButton={true} />
                    </b>
                    <br />
                    <br />
                    {translations.TENANT_switcher_no_tenants_message_3}
                    <b>
                        <a href={`mailto:${emails.support}`}>{emails.support}</a>
                    </b>
                </div>
            </ExAlertBanner>
        );
    }

    // Only display this SSO information box if we're logged in using SSO credentials
    let ssoAlert: JSX.Element | undefined;
    if (tenant?.isSso) {
        ssoAlert = (
            <ExAlertBanner type={AlertBannerType.INFO} open>
                <div>
                    <strong>Note:</strong>
                    {'\n'}
                    You&apos;re currently logged in via SSO, so you&apos;re only able to log into
                    the tenant your SSO is linked to
                </div>
            </ExAlertBanner>
        );
    }

    const content = isLoading ? (
        <Loader />
    ) : (
        <div>
            <input
                className="form-control"
                value={search}
                onChange={onSearchChange}
                placeholder="Search"
            />
            {ssoAlert}
            <div className="overflow-y-auto">{tenantList}</div>
        </div>
    );

    return (
        <Modal
            show
            onHide={() => {
                if (isNullOrEmpty(tenant)) {
                    addNotification({
                        type: NOTIFICATION_TYPES.info,
                        message: 'Please select a tenant',
                    });
                    return;
                }

                onClose();
            }} // Only show close button if you're currently logged into a tenant
            dialogClassName="tenant-select"
            title="Select a Tenant"
            renderBody={() => content}
        />
    );
};

const mapDispatchToProps = {
    addNotification,
};

export default connect(null, mapDispatchToProps)(TenantSwitcher);

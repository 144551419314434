import { Component, type ErrorInfo, type ReactNode } from 'react';

interface ErrorBoundaryProps {
    children?: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    override state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    override componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
    }

    override render() {
        if (this.state.hasError) {
            return (
                <div className="margin-top margin-bottom margin-left margin-right">
                    <h2>Something went wrong.</h2>
                    <p>If this keeps happening please contact support.</p>
                    <p>
                        <a
                            href="https://community.boomi.com/s/support"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            community.boomi.com/s/support
                        </a>
                    </p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

import { type FormEvent, useState } from 'react';
import type { TenantRuntime } from '../../types/Tenant';
import { formatTenantName } from '../../utils/tenant';
import { AlertBannerType, AlertBannerVariant, ExAlertBanner } from '@boomi/exosphere';
import translations from '../../translations';

interface Props {
    tenantId: string;
    tenantDeveloperName: string;
    runtimes: TenantRuntime[];
    onChange: (runtime: TenantRuntime | undefined) => void;
    comment: string | null;
    onCommentChanged: (e: FormEvent<HTMLTextAreaElement>) => void;
}

const ActivateRuntime = ({
    tenantId,
    tenantDeveloperName,
    runtimes,
    onChange,
    comment,
    onCommentChanged,
}: Props) => {
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const onRuntimeChanged = (e: FormEvent<HTMLSelectElement>) => {
        const runtime = runtimes.find((rt) => rt.id === e.currentTarget.value);
        if (runtime !== undefined) {
            if (
                runtime.status.toLowerCase().includes('offline') ||
                runtime.status.toLowerCase().includes('unknown')
            ) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        } else {
            setShowWarning(false);
        }

        onChange(runtime);
    };

    return (
        <>
            <p className="activate-runtime-header">
                {'Please select the runtime environment you want to publish to.'}
            </p>
            <div className={'form-group'}>
                <select
                    defaultValue={tenantId}
                    onChange={onRuntimeChanged}
                    className="form-control form-select"
                    data-testid="select-runtime"
                >
                    <option key={tenantId} value={tenantId}>
                        {formatTenantName(tenantDeveloperName)}
                    </option>
                    {runtimes.map((runtime) => (
                        <option key={runtime.id} value={runtime.id}>
                            {`${runtime.developerName} - ${runtime.status}`}
                        </option>
                    ))}
                </select>
                {showWarning ? (
                    <ExAlertBanner
                        type={AlertBannerType.WARNING}
                        open
                        variant={AlertBannerVariant.INLINE}
                        tooltipText="Action"
                    >
                        {translations.LOCAL_RUNTIME_possible_offline_node_warning}
                    </ExAlertBanner>
                ) : (
                    ''
                )}
                <textarea
                    className="form-control margin-top"
                    placeholder="Enter a comment for this version"
                    rows={3}
                    value={comment ?? ''}
                    onChange={onCommentChanged}
                />
            </div>
        </>
    );
};

export default ActivateRuntime;

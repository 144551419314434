import {
    COMPONENT_CONFIGURATION_OPTIONS as componentConfigurationOption,
    COMPONENT_CONFIGURATION_LABELS as componentConfigurationLabels,
} from '../../../../ts/components/page-editor/constants';

export const componentConfigurationSections = {
    General: [
        componentConfigurationOption.NAME,
        componentConfigurationOption.LABEL,
        componentConfigurationOption.CONTENT,
        componentConfigurationOption.IMAGE_URI,
        componentConfigurationOption.SOURCE,
        componentConfigurationOption.WIDTH,
        componentConfigurationOption.HEIGHT,
        componentConfigurationOption.HELP_INFO,
        componentConfigurationOption.HINT_VALUE,
    ],
    Modifiers: [
        componentConfigurationOption.EDITABLE,
        componentConfigurationOption.MULTISELECT,
        componentConfigurationOption.SEARCHABLE,
        componentConfigurationOption.SEARCHABLEDISPLAYCOLUMNS,
    ],
    State: [componentConfigurationOption.STATE_VALUE],
    'Data source': [
        componentConfigurationOption.DATA_SOURCE_LIST,
        componentConfigurationOption.DATA_SOURCE_CONNECTOR,
        componentConfigurationOption.DATA_SOURCE_2,
        componentConfigurationOption.FILE_DATA_SOURCE,
    ],
    'Data source filter': [componentConfigurationOption.DATA_SOURCE_FILTER],
    'Data presentation': [componentConfigurationOption.DATA_PRESENTATION],
};

export const componentConfigurationEditorSectionsWithLabels = Object.entries(
    componentConfigurationSections,
).map(([key, value]) => ({
    label: key,
    options: value
        .filter((conf) => conf !== componentConfigurationOption.NAME)
        .map((conf) => ({
            label: componentConfigurationLabels[conf],
            value: conf,
        })),
}));

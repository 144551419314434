import actionTypes from '../actions/reduxActions/actionTypes';
import { append } from 'ramda';

const localRuntimes = (
    // biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
    runtimes = { runtimeList: [], tenantRuntimeList: [], isLoading: true, isDeleting: false },
    { type, payload },
) => {
    switch (type) {
        case actionTypes.LOCAL_RUNTIME_LOADING:
            return {
                ...runtimes,
                isLoading: true,
            };

        case actionTypes.LOCAL_RUNTIME_DELETING:
            return {
                ...runtimes,
                isDeleting: payload,
            };

        case actionTypes.LOCAL_RUNTIME_DELETE:
            return {
                ...runtimes,
                runtimeList: runtimes.runtimeList.filter((runtime) => runtime.id !== payload),
                isDeleting: false,
            };

        case actionTypes.LOCAL_RUNTIME_SET:
            return {
                ...runtimes,
                runtimeList: payload.map((runtime) => ({ ...runtime, isEditing: false })),
                isLoading: false,
            };

        case actionTypes.LOCAL_RUNTIME_FOR_TENANT_SET:
            return {
                ...runtimes,
                tenantRuntimeList: payload.map((runtime) => ({ ...runtime, isEditing: false })),
                isLoading: false,
            };

        case actionTypes.LOCAL_RUNTIME_UPDATE:
            return {
                ...runtimes,
                isLoading: false,
                runtimeList: runtimes.runtimeList.map((runtime) =>
                    runtime.id === payload.runtime.id
                        ? { ...payload.runtime, isEditing: true }
                        : runtime,
                ),
            };

        case actionTypes.LOCAL_RUNTIME_CREATE:
            return {
                ...runtimes,
                runtimeList: append({ ...payload, isEditing: true }, runtimes.runtimeList),
                isLoading: false,
            };

        case actionTypes.LOCAL_RUNTIME_SHOW_LOADER:
            return {
                ...runtimes,
                isLoading: true,
            };

        case actionTypes.NOTIFICATION_ADD:
            return {
                ...runtimes,
                isLoading: false,
            };

        default:
            return runtimes;
    }
};

export default localRuntimes;

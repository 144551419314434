import { useEffect, useState } from 'react';
import Select from 'react-select';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import { getFlows } from '../../../../../ts/sources/flow';
import translations from '../../../../../ts/translations';
import { getSharedStyles } from '../../../../../ts/utils/select';

const FlowSelectBox = ({
    onChange,
    selectedItem,
    flowId,
    notifyError,
    tenantId,
    isRequired,
    isValid,
    showValidation,
    validationMessage,
    menuPosition,
    menuPortalTarget,
    closeMenuOnScroll,
}) => {
    const [flows, setFlows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchFlows = async () => {
            try {
                setIsLoading(true);

                const flows = await getFlows(tenantId);

                setFlows(flows);
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFlows();
    }, [tenantId, notifyError]);

    const selectedFlow =
        selectedItem && flows.length > 0
            ? flows.find((flow) => flow.developerName === selectedItem)
            : null;

    const currentSelection =
        selectedFlow !== null
            ? { label: selectedFlow.developerName, value: selectedFlow.id.id }
            : null;

    const options = flows
        // Don't show current (parent) flow in select list
        .filter((item) => item.id.id !== flowId)
        .map((flow) => ({ label: flow.developerName, value: flow.id.id }));

    return (
        <FormGroup
            label={translations.SUBFLOW_select_label}
            htmlFor="flow-select-box"
            isRequired={isRequired}
            isValid={isValid}
            showValidation={showValidation}
            validationMessage={validationMessage}
        >
            <Select
                inputId="flow-select-box"
                styles={{
                    ...getSharedStyles(),
                    container: (baseStyles) => ({ ...baseStyles, width: '300px' }),
                }}
                placeholder={translations.SUBFLOW_select_placeholder}
                onChange={onChange}
                value={currentSelection}
                isLoading={isLoading}
                options={options}
                noOptionsMessage={() => 'No results found'}
                menuPosition={menuPosition}
                menuPortalTarget={menuPortalTarget}
                closeMenuOnScroll={closeMenuOnScroll}
                isClearable
            />
        </FormGroup>
    );
};

export default FlowSelectBox;

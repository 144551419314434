import type { DebugTabId } from '../../../types';
import { BadgeShape, BadgeSize, ExBadge, PillColor } from '@boomi/exosphere';
import { useDebug } from './DebugProvider';
import classnames from 'classnames';
import ScreenRouter from '../../generic/ScreenRouter';
import StateValues from './tabs/StateValues';
import Breakpoints from './tabs/breakpoints/Breakpoints';
import RootFaults from './tabs/rootfaults/RootFaults';
import DebugActionButtons from './DebugActionButtons';

interface DebugTab {
    id: DebugTabId;
    name: string;
    isActive: boolean;
    count: number;
    color: PillColor;
}

const DebugTabs = ({ onClose }: { onClose: () => void }) => {
    const { activeTabId, setActiveTabId, callStack } = useDebug();

    const selectedCallStackItem = callStack.find((callStackItem) => callStackItem.isSelected);

    const tabs: DebugTab[] = [
        {
            id: 'STATE_VALUES',
            name: 'State Values',
            isActive: activeTabId === 'STATE_VALUES',
            count: selectedCallStackItem?.stateValues?.length || 0,
            color: PillColor.GRAY,
        },
        {
            id: 'BREAK_POINTS',
            name: 'Assertions',
            isActive: activeTabId === 'BREAK_POINTS',
            count: selectedCallStackItem?.breakpointsHit?.length || 0,
            color: selectedCallStackItem?.breakpointsHit?.length ? PillColor.GREEN : PillColor.GRAY,
        },
        {
            id: 'ROOT_FAULTS',
            name: 'Root Faults',
            isActive: activeTabId === 'ROOT_FAULTS',
            count: Object.keys(selectedCallStackItem?.rootFaults || []).length || 0,
            color: Object.keys(selectedCallStackItem?.rootFaults || []).length
                ? PillColor.RED
                : PillColor.GRAY,
        },
    ];

    const activatedTab = tabs.find((tab) => tab.isActive) as DebugTab;

    return (
        <div className="debug-tabs">
            <div data-testid="tab-header" className="flex gap-small debug-header">
                {tabs.map((tab) => {
                    const classes = classnames({
                        'debug-tab': true,
                        active: tab.isActive,
                    });
                    return (
                        <button
                            key={tab.id}
                            className={classes}
                            onClick={() => setActiveTabId(tab.id)}
                            type="button"
                        >
                            {tab.name}
                            <span className="inline-block margin-left-sml">
                                <ExBadge
                                    size={BadgeSize.SMALL}
                                    color={tab.color}
                                    shape={BadgeShape.ROUND}
                                >{`${
                                    tab.count
                                }${tab.id === 'BREAK_POINTS' ? ' hits' : ''}`}</ExBadge>
                            </span>
                        </button>
                    );
                })}
                <DebugActionButtons onClose={onClose} />
            </div>
            <div className="debug-tab-content full-height">
                <ScreenRouter currentScreen={activatedTab.id}>
                    <StateValues screen="STATE_VALUES" />
                    <Breakpoints screen="BREAK_POINTS" />
                    <RootFaults screen="ROOT_FAULTS" />
                </ScreenRouter>
            </div>
        </div>
    );
};

export default DebugTabs;

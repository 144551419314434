import type {
    Filter,
    ItemCollectionResponse,
    ValueElementIdReferenceAPI,
    ValueElementRequestAPI,
    ValueElementResponseAPI,
    ValueReferenceFilter,
} from '../types';
import { fetchAndParse, filterToQuery } from '../utils/ajax';
import { isNullOrEmpty } from '../utils/guard';
import { getTenantId } from '../utils/tenant';

export const saveValue = (request: ValueElementRequestAPI) =>
    fetchAndParse<ValueElementResponseAPI>({
        url: '/api/draw/1/element/value',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

export const getValue = (id: string) =>
    fetchAndParse<ValueElementResponseAPI>({
        url: `/api/draw/1/element/value/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const deleteValue = (id: string) =>
    fetchAndParse<void>({
        url: `/api/draw/1/element/value/${id}`,
        method: 'delete',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getValueList = (filter?: ValueReferenceFilter | null) => {
    const query = isNullOrEmpty(filter) ? '' : filterToQuery(filter as Filter);

    return fetchAndParse<ValueElementIdReferenceAPI[]>({
        url: `/api/draw/1/element/value/reference${query}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getAllValues = () => {
    return fetchAndParse<ValueElementResponseAPI[]>({
        url: '/api/draw/1/element/value',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getAllValuesV2 = (filter: Filter) => {
    const query = filterToQuery(filter);

    return fetchAndParse<ItemCollectionResponse<ValueElementResponseAPI>>({
        url: `/api/draw/2/element/value${query}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

import { useEffect, useState } from 'react';
import Modal from '../../../ts/components/generic/modal/GenericModal';
import SyntaxHighlighter from '../../../ts/components/generic/SyntaxHighlighter';
import Truncate from '../../../ts/components/Truncate';
import Table from '../../../ts/components/generic/Table';
import translations from '../../../ts/translations';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import { formatJSONContent } from '../../../ts/utils/json';

/**
 * @param {String} text Text content that will be displayed in the modal
 * @description Tries (very roughly) to figure out what syntax highlighting to use for given text.
 */
export const syntax = (text) => {
    const isHtmlLike = (str) => /<[a-z][\s\S]*>/i.test(str);
    const isJsonLike = (str) => /\{(.*?)\}|\[(.*?)\]/g.test(str);

    if (!isNullOrEmpty(text)) {
        return isHtmlLike(text) ? 'html' : isJsonLike(text) ? 'json' : 'text';
    }
    return 'text';
};

/**
 * @param {String} text Text that should be displayed in the table cell and modal
 * @param {Number} limit Limit the number of characters shown in the table cell
 * @param {String} title Title of the modal
 * @param {Function} setContent Set the content into the component state
 * @description Shows the data in the table cell. If data was truncated then it also gives the option to view the complete data in a modal.
 */
export const truncateText = (text, limit, title, setContent) => {
    const viewButton =
        text.length >= limit ? (
            <button
                className="link-emulate"
                onClick={() => setContent({ text, title })}
                type="button"
            >
                View
            </button>
        ) : null;

    return (
        <div className="flex">
            <Truncate text={text} maxLength={limit} />
            &nbsp;
            {viewButton}
        </div>
    );
};

/**
 * @param {Object} selectedState Holds the data related to the selected state
 * @description This component renders a table with Root Faults related to a state (if any are found in the state data)
 */
const RootFaultsList = ({ selectedState }) => {
    const [faults, setFaults] = useState([]);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (selectedState.hasRootFaults) {
            const remappedFaults = Object.entries(selectedState.rootFaults).map((fault) => ({
                name: fault[0],
                data: JSON.parse(selectedState.rootFaults[fault[0]]),
            }));

            setFaults(remappedFaults);
        }
    }, [selectedState]);

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item?.name ?? '',
            size: '13rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_uri,
            renderCell: ({ item }) => item?.data?.uri ?? '',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_message,
            renderCell: ({ item }) =>
                truncateText(
                    item?.data?.message ?? '',
                    65,
                    translations.COMMON_TABLE_message,
                    setContent,
                ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status_code,
            renderCell: ({ item }) => item?.data?.statusCode ?? '',
            size: '7rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_response_body,
            renderCell: ({ item }) =>
                !!item?.data?.responseBody && (
                    <button
                        className="link-emulate"
                        onClick={() =>
                            setContent({
                                text: item.data.responseBody,
                                title: translations.COMMON_TABLE_response_body,
                            })
                        }
                        type="button"
                    >
                        View
                    </button>
                ),
            size: '6rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_response_headers,
            renderCell: ({ item }) =>
                !!item?.data?.responseHeaders && (
                    <button
                        className="link-emulate"
                        onClick={() =>
                            setContent({
                                text: JSON.stringify(item.data.responseHeaders),
                                title: translations.COMMON_TABLE_response_headers,
                            })
                        }
                        type="button"
                    >
                        View
                    </button>
                ),
            size: '6rem',
        },
    ];

    return (
        <div>
            <Table
                columns={columns}
                items={faults}
                rowClassName={() => 'generic-row generic-row-tall'}
            />
            <Modal
                show={!isNullOrEmpty(content)}
                onHide={() => setContent(null)}
                title={content?.title ?? ''}
                bodyClassName="root-fault-content-modal-body"
                renderBody={() => (
                    <SyntaxHighlighter
                        content={formatJSONContent(content?.text ?? '')}
                        language={syntax(content?.text ?? '')}
                    />
                )}
                renderFooter={() => (
                    <div className="flex">
                        <div className="flex-child-right">
                            <button
                                className="btn btn-sm btn-default"
                                onClick={() => setContent(null)}
                                type="button"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

export default RootFaultsList;

import { MapElementProvider } from '../../../../../js/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import type { MapElementProps } from '../../../../types/graph';
import MapElementModal from '../../../graph/MapElementModal';
import { ProcessProvider } from '../contextProviders/ProcessProvider';
import ProcessRouter from './ProcessRouter';

const Process = (props: MapElementProps) => {
    return (
        <MapElementProvider {...props} defaultScreen={'process'} elementType={'process'}>
            <MapElementModal>
                <ProcessProvider defaultScreen={'process'}>
                    <ProcessRouter />
                </ProcessProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Process;

import type { HttpMethod } from '../types';
import { fetchAndParse, getTenantId } from '../utils';

export const sendRequest = async (url: string, method: HttpMethod, requestBody: unknown) => {
    return await fetchAndParse({
        url: `${url.startsWith('/') ? '' : '/'}${url}`,
        method,
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body: method === 'GET' ? null : requestBody,
    });
};

import { type ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { notifyError, notifySuccess } from '../../../../js/actions/reduxActions/notification';
import {
    deleteEnvironment as deleteEnv,
    getEnvironmentFlows as loadEnvironmentFlows,
    getEnvironmentVariables as loadEnvironmentVariables,
    getAllEnvironments as loadEnvironments,
    deleteEnvironmentVariable as removeEnvironmentVariable,
    saveEnvironment as save,
    saveEnvironmentVariable as saveEnvironmentVar,
} from '../../../sources/environments';
import {
    deleteRelease as deleteRel,
    deploy as deployApiCall,
    getRelease as loadRelease,
    getReleases as loadReleases,
    removeFlowFromRelease as removeFlow,
    removeThemeFromRelease as removeTheme,
    rollback as rollbackApiCall,
} from '../../../sources/releases';
import translations from '../../../translations';
import type {
    ItemCollectionResponse,
    NotifyError,
    NotifySuccess,
    ReleaseAPI,
    ReleaseFilter,
} from '../../../types';
import type {
    Environment,
    EnvironmentFlow,
    EnvironmentVariable,
    GetEnvironmentFlowsRequest,
    GetEnvironmentVariablesRequest,
} from '../../../types/environment';

type EnvironmentsContext = {
    getAllEnvironments: () => Promise<Environment[]>;
    getEnvironmentFlows: (
        params: GetEnvironmentFlowsRequest,
    ) => Promise<ItemCollectionResponse<EnvironmentFlow>> | null;
    environmentsLoading: boolean;
    environments: Environment[];
    saveEnvironment: (environment: Environment) => Promise<void>;
    deleteEnvironment: (environmentId: string) => Promise<void>;
    getEnvironmentVariables: (
        environmentId: string,
        filter: GetEnvironmentVariablesRequest,
    ) => Promise<ItemCollectionResponse<EnvironmentVariable> | null>;
    saveEnvironmentVariable: (
        environmentId: string,
        environmentVariable: EnvironmentVariable,
    ) => Promise<void>;
    environmentVariables: EnvironmentVariable[];
    environmentVariablesLoading: boolean;
    deleteEnvironmentVariable: (
        environmentId: string,
        environmentVariableId: string,
    ) => Promise<void>;
    releases: ReleaseAPI[];
    getReleases: (filter: ReleaseFilter) => Promise<ReleaseAPI[]>;
    releasesLoading: boolean;
    getRelease: (id: string) => Promise<ReleaseAPI | null>;
    releaseLoading: boolean;
    deleteRelease: (releaseId: string) => Promise<void>;
    removeFlowFromRelease: (releaseId: string, flowId: string) => Promise<void>;
    removeThemeFromRelease: (
        releaseId: string,
        themeId: string,
        themeName: string,
    ) => Promise<void>;
    deploy: (releaseId: string, environmentId: string) => Promise<boolean>;
    rollback: (releaseId: string, environmentId: string) => Promise<boolean>;
    tenantId: string;
    notifySuccess: NotifySuccess;
    notifyError: NotifyError;
    setIsManagingEnvironments: React.Dispatch<React.SetStateAction<boolean>>;
    managingEnvironments: boolean;
    environmentFlowsLoading: boolean;
};

type EnvironmentsProviderProps = {
    tenantId: string;
    children?: ReactNode;
} & ConnectedProps<typeof connector>;

const connector = connect(null, {
    notifyError,
    notifySuccess,
});

const Context = createContext<EnvironmentsContext | undefined>(undefined);

const EnvironmentsProvider = ({
    notifyError,
    notifySuccess,
    tenantId,
    children,
}: EnvironmentsProviderProps) => {
    const [environmentsLoading, setEnvironmentsLoading] = useState(false);
    const [environmentVariablesLoading, setEnvironmentVariablesLoading] = useState(false);
    const [environmentFlowsLoading, setEnvironmentFlowsLoading] = useState(false);
    const [releasesLoading, setReleasesLoading] = useState(false);
    const [releaseLoading, setReleaseLoading] = useState(false);
    const [managingEnvironments, setIsManagingEnvironments] = useState(false);
    const [environments, setEnvironments] = useState<Environment[]>([]);
    const [releases, setReleases] = useState<ReleaseAPI[]>([]);
    const [environmentVariables, setEnvironmentVariables] = useState<EnvironmentVariable[]>([]);

    const getAllEnvironments = useCallback(async () => {
        try {
            setEnvironmentsLoading(true);
            const results = await loadEnvironments();
            setEnvironments(results);
            return results;
        } catch (error) {
            notifyError(error);
            return [];
        } finally {
            setEnvironmentsLoading(false);
        }
    }, [notifyError]);

    const saveEnvironment = useCallback(
        async (environment: Environment) => {
            try {
                await save(environment);

                notifySuccess(translations.ENVIRONMENT_save_success_message);
            } catch (error) {
                notifyError(error);
            }
        },
        [notifyError, notifySuccess],
    );

    const deleteEnvironment = useCallback(
        async (environmentId: string) => {
            try {
                await deleteEnv(environmentId);
                notifySuccess(translations.ENVIRONMENT_delete_success_message);
            } catch (error) {
                notifyError(error);
            }
        },
        [notifyError, notifySuccess],
    );

    const getEnvironmentFlows = useCallback(
        (params: GetEnvironmentFlowsRequest) => {
            try {
                setEnvironmentFlowsLoading(true);
                return loadEnvironmentFlows(params);
            } catch (error) {
                notifyError(error);
                return null;
            } finally {
                setEnvironmentFlowsLoading(false);
            }
        },
        [notifyError],
    );

    const getEnvironmentVariables = useCallback(
        async (environmentId: string, filter: GetEnvironmentVariablesRequest) => {
            try {
                setEnvironmentVariablesLoading(true);
                const response = await loadEnvironmentVariables(environmentId, filter);
                setEnvironmentVariables(response.items);
                return response;
            } catch (error) {
                notifyError(error);
                return null;
            } finally {
                setEnvironmentVariablesLoading(false);
            }
        },
        [notifyError],
    );

    const saveEnvironmentVariable = useCallback(
        async (environmentId: string, environmentVariable: EnvironmentVariable) => {
            try {
                await saveEnvironmentVar(environmentId, environmentVariable);
            } catch (error) {
                notifyError(error);
            }
        },
        [notifyError],
    );

    const deleteEnvironmentVariable = useCallback(
        async (environmentId: string, environmentVariableId: string) => {
            try {
                await removeEnvironmentVariable(environmentId, environmentVariableId);
            } catch (error) {
                notifyError(error);
            }
        },
        [notifyError],
    );

    const getReleases = useCallback(
        async (filter: ReleaseFilter) => {
            try {
                setReleasesLoading(true);
                const results = await loadReleases(filter);
                setReleases(results);
                return results;
            } catch (error) {
                notifyError(error);
                return [];
            } finally {
                setReleasesLoading(false);
            }
        },
        [notifyError],
    );

    const getRelease = useCallback(
        async (id: string) => {
            setReleaseLoading(true);
            try {
                return await loadRelease(id);
            } catch (error) {
                notifyError(error);
                return null;
            } finally {
                setReleaseLoading(false);
            }
        },
        [notifyError],
    );

    const deleteRelease = useCallback(
        async (releaseId: string) => {
            try {
                await deleteRel(releaseId);
            } catch (error) {
                notifyError(error);
            }
        },
        [notifyError],
    );

    const removeFlowFromRelease = useCallback(
        async (releaseId: string, flowId: string) => {
            try {
                await removeFlow(releaseId, flowId);

                notifySuccess(translations.ENVIRONMENT_remove_item_success);
            } catch (error) {
                notifyError(error);
            }
        },
        [notifyError, notifySuccess],
    );

    const removeThemeFromRelease = useCallback(
        async (releaseId: string, themeId: string, themeName: string) => {
            try {
                await removeTheme(releaseId, themeId, themeName);

                notifySuccess(translations.ENVIRONMENT_remove_item_success);
            } catch (error) {
                notifyError(error);
            }
        },
        [notifyError, notifySuccess],
    );

    const deploy = useCallback(
        async (releaseId: string, environmentId: string) => {
            try {
                await deployApiCall(releaseId, environmentId);
                return true;
            } catch (error) {
                notifyError(error);
                return false;
            }
        },
        [notifyError],
    );

    const rollback = useCallback(
        async (releaseId: string, environmentId: string) => {
            try {
                await rollbackApiCall(releaseId, environmentId);
                return true;
            } catch (error) {
                notifyError(error);
                return false;
            }
        },
        [notifyError],
    );

    const contextValue: EnvironmentsContext = {
        environmentsLoading,
        environments,
        environmentVariables,
        environmentVariablesLoading,
        releases,
        releasesLoading,
        releaseLoading,
        tenantId,
        managingEnvironments,
        environmentFlowsLoading,
        getEnvironmentFlows,
        getAllEnvironments,
        saveEnvironment,
        deleteEnvironment,
        getEnvironmentVariables,
        saveEnvironmentVariable,
        deleteEnvironmentVariable,
        getReleases,
        getRelease,
        deleteRelease,
        removeFlowFromRelease,
        removeThemeFromRelease,
        deploy,
        rollback,
        notifySuccess,
        notifyError,
        setIsManagingEnvironments,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const ConnectedEnvironmentsProvider = connector(EnvironmentsProvider);

const useEnvironments = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error(translations.ENVIRONMENT_missing_context_provider_message);
    }
    return context;
};

export { ConnectedEnvironmentsProvider as EnvironmentsProvider, useEnvironments };

import { useState, useRef, type ElementRef } from 'react';
import TypeProperties from './TypeProperties';
import { SYSTEM_TYPES } from '../../constants';
import translations from '../../translations';
import type { TypeElementPropertyAPI } from '../../types';
import { isNullOrEmpty } from '../../utils/guard';
import { stringReplace } from '../../utils/string';
import Breadcrumb from '../Breadcrumb';
import ButtonDefault from '../buttons/ButtonDefault';
import ButtonPrimary from '../buttons/ButtonPrimary';
import CopyableText from '../generic/CopyableText';
import Footer from '../generic/Footer';
import FormGroup from '../generic/FormGroup';
import ConfirmModal from '../generic/modal/ConfirmModal';
import { useTypes } from './TypesProvider';
import Dependents from '../Dependents';

const TypeDetail = () => {
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

    const {
        removeProperty,
        setPropertyToDelete,
        propertyToDelete,
        saveType,
        typeToEdit,
        updateTypeName,
        updateTypeComments,
        returnToTypeList,
        breadcrumbs,
        notifyError,
    } = useTypes();

    const modalContainerRef = useRef<ElementRef<'div'>>(null);

    const checkRequiredFields = () => ({
        defaultDeveloperName: typeToEdit?.developerName ?? '',
        defaultProperties: typeToEdit?.properties ?? [],
    });

    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onPropertyDelete = (type: TypeElementPropertyAPI) => {
        setPropertyToDelete(type);
        setDeleteModalIsVisible(true);
    };

    const onSave = () => {
        if (SYSTEM_TYPES.includes(typeToEdit.developerName ?? '')) {
            notifyError(translations.TYPES_delete_system_type_error_message);
        } else {
            setHasSubmitted(true);

            const isFormValid = Object.values(checkRequiredFields()).every(
                (field) => !isNullOrEmpty(field),
            );

            if (isFormValid) {
                saveType();
            }
        }
    };

    const { defaultDeveloperName } = checkRequiredFields();

    return (
        <>
            <div className="admin-page" ref={modalContainerRef}>
                <ConfirmModal
                    show={deleteModalIsVisible}
                    title={translations.TYPE_delete_property_confirmation_title}
                    messages={[
                        stringReplace(translations.TYPE_delete_property_confirmation_message, {
                            propertyName: propertyToDelete?.developerName ?? '',
                        }),
                        translations.GENERAL_cannot_be_undone,
                    ]}
                    buttonStyle="danger"
                    buttonCaption={translations.COMMON_delete}
                    onCancel={() => setDeleteModalIsVisible(false)}
                    onConfirm={() => {
                        removeProperty();
                        setDeleteModalIsVisible(false);
                    }}
                    container={modalContainerRef.current}
                />
                <h1>
                    {translations.TYPES_heading_text}
                    {typeToEdit.developerName || ''}
                </h1>
                {typeToEdit.id ? (
                    <CopyableText
                        wrapperClassName="type-id"
                        copyableText={typeToEdit.id}
                        labelText={translations.TYPES_id_label}
                        hasCopyButton={true}
                    />
                ) : null}
                {typeToEdit.serviceElementDeveloperName ? (
                    <CopyableText
                        wrapperClassName="connector-name"
                        copyableText={typeToEdit.serviceElementDeveloperName}
                        labelText={translations.TYPES_connector_name_label}
                        hasCopyButton={true}
                    />
                ) : null}
                {typeToEdit.serviceElementId ? (
                    <CopyableText
                        wrapperClassName="connector-id"
                        copyableText={typeToEdit.serviceElementId}
                        labelText={translations.TYPES_connector_id_label}
                        hasCopyButton={true}
                    />
                ) : null}
                <div className="margin-bottom margin-top-large" data-testid="type-breadcrumbs">
                    <Breadcrumb trail={breadcrumbs.trail} activeItemId={breadcrumbs.activeItemId} />
                </div>
                <FormGroup
                    label={translations.COMMON_name}
                    htmlFor="type-name"
                    isRequired
                    validationMessage={translations.TYPES_name_validation_message}
                    isValid={!isNullOrEmpty(typeToEdit.developerName)}
                    showValidation={hasSubmitted}
                >
                    <input
                        id="type-name"
                        className="form-control form-control-long"
                        value={defaultDeveloperName}
                        onChange={(e) => updateTypeName(e.target.value)}
                        type="text"
                        placeholder={translations.TYPES_name_input_placeholder}
                        autoFocus
                    />
                </FormGroup>
                <FormGroup label={translations.COMMON_comments} htmlFor="type-comments">
                    <textarea
                        id="type-comments"
                        className="form-control form-control-textarea"
                        value={typeToEdit.developerSummary ?? ''}
                        onChange={(e) => updateTypeComments(e.target.value)}
                        placeholder={translations.TYPES_comments_input_placeholder}
                    />
                </FormGroup>
                <TypeProperties onPropertyDelete={onPropertyDelete} hasSubmitted={hasSubmitted} />
                {typeToEdit.id ? <Dependents id={typeToEdit.id} /> : null}
            </div>
            <Footer>
                <ButtonDefault title="Back" className="flex-child-right" onClick={returnToTypeList}>
                    {translations.COMMON_back}
                </ButtonDefault>
                <ButtonPrimary title="Save Type" onClick={onSave}>
                    {translations.TYPES_save_type_button_label}
                </ButtonPrimary>
            </Footer>
        </>
    );
};

export default TypeDetail;

import { useState } from 'react';
import { NOTIFICATION_TYPES } from '../../constants';
import translations from '../../translations';
import type { NotifyError } from '../../types';
import { stringReplace } from '../../utils';
import { useUpload } from '../../utils/file';
import { setItem } from '../../utils/storage';
import { formatTenantName } from '../../utils/tenant';
import ButtonDanger from '../buttons/ButtonDanger';
import ButtonDefault from '../buttons/ButtonDefault';
import CopyableText from '../generic/CopyableText';
import FormGroup from '../generic/FormGroup';
import UploadProgressBar from '../generic/UploadProgressBar';
import Modal from '../generic/modal/GenericModal';
import FileDrop from '../generic/upload/FileDrop';

interface ImportTenantProps {
    tenantId: string;
    tenantName: string;
    notifyError: NotifyError;
}

const ImportTenant = ({ tenantId, tenantName, notifyError }: ImportTenantProps) => {
    const [showConfirmImport, setShowConfirmImport] = useState(false);
    const [typedTenantName, setTypedTenantName] = useState('');

    const formattedTenantName = formatTenantName(tenantName);

    const onComplete = () => {
        setItem('startup-notification', {
            type: NOTIFICATION_TYPES.success,
            message: translations.TENANT_import_success_message,
            isPersistent: false,
        });

        window.location.reload();
    };

    const onError = () => {
        notifyError(translations.TENANT_import_abort_message);

        setShowConfirmImport(false);
    };

    const { setFile, beginUpload, abort, file, isUploading, progressBytes, progressPercentage } =
        useUpload({
            tenantId,
            onComplete,
            onError,
            url: '/api/package/1/tenant',
            sendAsJSON: true,
        });

    const isImportEnabled = file && !isUploading && typedTenantName === formattedTenantName;

    const onDrop = (files: File[]) => {
        const reader = new FileReader();

        reader.addEventListener('load', ({ target }) => {
            let parsed: unknown = null;

            try {
                const result = target?.result;
                parsed = typeof result === 'string' ? JSON.parse(result) : null;
            } catch {
                parsed = null;
            }

            if (typeof parsed === 'string') {
                // This shape is expected
                setFile(files[0]);
            } else {
                // Having to read the file and stringify Component files if they are incorrectly given
                // Without this stringifying, the Component will be given as JSON, and the engine will parse it as null,
                // because it doesn't match the string input accepted,
                // and the user ends up with an error message that seems like they gave no input at all
                setFile(new File([new Blob([JSON.stringify(target?.result)])], files[0].name));
            }
        });

        reader.readAsText(files[0]);

        setShowConfirmImport(true);
    };

    const onConfirmClick = () => {
        beginUpload();
    };

    const onCancelClick = () => {
        abort();
        setFile(null);
        setTypedTenantName('');
        setShowConfirmImport(false);
    };

    return (
        <div className="form-group">
            <Modal
                show={showConfirmImport}
                title={translations.TENANT_import_modal_title}
                onHide={onCancelClick}
                renderBody={() => (
                    <>
                        <p>{translations.TENANT_import_confirm_message_1}</p>
                        <p>{translations.TENANT_import_confirm_message_2}</p>
                        <p>
                            <CopyableText
                                textClassName="bold"
                                copyableText={formattedTenantName}
                                labelText={stringReplace(
                                    translations.TENANT_import_confirm_message_3,
                                    {
                                        fileName: file?.name,
                                    },
                                )}
                            />
                        </p>
                        <FormGroup
                            label={translations.TENANT_import_confirm_label}
                            isRequired
                            htmlFor="typedTenantName"
                        >
                            <input
                                className="form-control form-control-width"
                                id="typedTenantName"
                                value={typedTenantName}
                                onChange={({ target: { value } }) => setTypedTenantName(value)}
                            />
                        </FormGroup>
                        {progressPercentage > 0 && (
                            <div>
                                <UploadProgressBar
                                    progressPercentage={progressPercentage}
                                    progressBytes={progressBytes}
                                    totalBytes={file?.size ?? 0}
                                />
                            </div>
                        )}
                    </>
                )}
                renderFooter={() => (
                    <>
                        <ButtonDefault onClick={onCancelClick}>
                            {translations.TENANT_import_cancel_button_label}
                        </ButtonDefault>
                        <ButtonDanger onClick={onConfirmClick} disabled={!isImportEnabled}>
                            {translations.TENANT_import_confirm_button_label}
                        </ButtonDanger>
                    </>
                )}
            />
            <p>{translations.TENANT_import_description}</p>
            <FileDrop
                onChange={onDrop}
                placeholder={translations.TENANT_import_drop_zone_message}
                fileTypes=".tenant"
            />
        </div>
    );
};

export default ImportTenant;

import translations from '../../../../../../../ts/translations';
import FormGroup from '../../../../../../../ts/components/generic/FormGroup';
import { useDataAction } from '../../../contextProviders/DataActionProvider';
import { useMapElement } from '../../../contextProviders/MapElementProvider';
import { isNullOrEmpty } from '../../../../../../../ts/utils/guard';
import ValueSelectorModal from '../../../../../../../ts/components/values/selector/ValueSelectorModal';

const FilterById = ({ idToFilterBy, hasSubmitted }) => {
    const { filterToEdit, onSetFilterId } = useDataAction();
    const { container } = useMapElement();

    const isFilteredById = filterToEdit?.filterId;

    if (!isFilteredById) {
        return null;
    }

    return (
        <FormGroup
            required
            label={translations.DATA_ACTION_filter_value_identifier_label}
            showValidation={hasSubmitted}
            validationMessage={translations.MAP_ELEMENT_value_field_validation_message}
            isValid={!isNullOrEmpty(idToFilterBy)}
        >
            <ValueSelectorModal
                value={filterToEdit.filterId}
                onChange={onSetFilterId}
                container={container}
            />
        </FormGroup>
    );
};

export default FilterById;

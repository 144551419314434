import { memo } from 'react';
import ComponentWithTooltip from '../../generic/tooltip/ComponentWithTooltip';
import TooltipWithHeader from '../../generic/tooltip/TooltipWithHeader';
import { type ElementDescription, getElements } from '../../../config/graphElements';
import { NODE_STYLES } from './nodeStyles';
import type { MapElementType } from '../../../types';
import Icon from './Icon';
import type { AddMapElementPayload } from '../state/types';

interface Props {
    onElementMouseDown: (props: AddMapElementPayload) => void;
}

const getTooltips = (elements: ElementDescription[]) => {
    return new Map(
        elements
            .filter((element) => element.tooltip)
            .map((element) => [
                element.name,
                <TooltipWithHeader
                    key={element.id}
                    header={element.tooltip.title}
                    content={element.tooltip.content}
                />,
            ]),
    );
};

const CanvasPalette = ({ onElementMouseDown }: Props) => {
    const elements = getElements().filter((element) => element.display);
    const tooltips = getTooltips(elements);
    const items = elements.map((element) => {
        const styles = NODE_STYLES[element.id as MapElementType];
        const itemClasses = 'sidebar-element no-select new-canvas sidebar-btn';
        return (
            <ComponentWithTooltip
                key={element.name}
                trigger={['hover', 'focus']}
                fadeTime={0.1}
                tooltipContent={tooltips.get(element.name)}
                tooltipPlacement="right"
                wrapperClass="sidebar-button-wrapper"
                tooltipClass="graph-tooltip"
                tooltipArrowClass="graph-tooltip-arrow"
                clearance={10}
                arrowClearance={7}
            >
                <button
                    id={element.id}
                    data-flow={element.flow}
                    data-name={element.id}
                    data-testid="sidebar-element"
                    onMouseDown={(event) =>
                        onElementMouseDown({
                            x: event.clientX,
                            y: event.clientY,
                            elementType: element.id as MapElementType,
                        })
                    }
                    className={itemClasses}
                    type="button"
                >
                    <span className="sidebar-btn-icon" style={{ background: styles.color }}>
                        <span className="glyphicon">
                            <Icon name={styles.iconName} size={17} />
                        </span>
                    </span>
                    <div className="sidebar-item-text">{element.name}</div>
                </button>
            </ComponentWithTooltip>
        );
    });

    return <div className="canvas-palette floating-panel">{items}</div>;
};

export default memo(CanvasPalette);

import { type ReactNode, createContext, useContext, useState } from 'react';
import type { DebugConfig } from '../../../types';

interface DebugConfigProviderContext {
    debugConfig: DebugConfig;
    setDebugConfig: (config: DebugConfig) => void;
}

interface DebugConfigProviderProps {
    children: ReactNode;
}

const initialDebugConfig: DebugConfig = {
    position: 'BOTTOM',
    dimensions: {
        BOTTOM: {
            minHeight: '500px',
            height: 'auto',
            width: '100%',
        },
        LEFT: {
            height: '100%',
            minHeight: 'auto',
            width: '600px',
        },
        RIGHT: {
            height: '100%',
            minHeight: 'auto',
            width: '600px',
        },
    },
    paneDimensions: {
        BOTTOM: {
            LEFT: { width: '25%' },
            RIGHT: { width: '75%' },
        },
        LEFT: {
            LEFT: { height: '50%' },
            RIGHT: { height: '50%' },
        },
        RIGHT: {
            LEFT: { height: '50%' },
            RIGHT: { height: '50%' },
        },
    },
};

const Context = createContext<DebugConfigProviderContext | undefined>(undefined);

const DebugConfigProvider = ({ children }: DebugConfigProviderProps) => {
    const [debugConfig, setDebugConfig] = useState(initialDebugConfig);

    const contextValue: DebugConfigProviderContext = {
        debugConfig,
        setDebugConfig,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useDebugConfig = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useDebugConfig must be used within a DebugConfigProvider');
    }
    return context;
};

export { DebugConfigProvider, useDebugConfig };

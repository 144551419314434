import { useEffect } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import '../../../../css/notification.less';
import { addNotification, removeNotification } from '../../../js/actions/reduxActions/notification';
import { getItem, removeItem } from '../../utils/storage';
import type { Notification as StoredNotification } from '../../types';
import Notification from './Notification';

const NotificationList = ({
    notifications,
    addNotification,
    removeNotification,
}: NotificationListProps) => {
    // Only ever show a maximum of 5
    const mostRecentNotifications = notifications.slice(-5);

    useEffect(() => {
        // This notification is added to storage without an ID
        const startupNotification = getItem<Omit<Notification, 'id'>>('startup-notification');

        if (startupNotification) {
            removeItem('startup-notification');
            addNotification(startupNotification);
        }
    }, [addNotification]);

    return (
        <div className="toast-notifications">
            {mostRecentNotifications.map((notification) => (
                <Notification
                    key={notification.id}
                    {...notification}
                    onRemove={removeNotification}
                />
            ))}
        </div>
    );
};

const mapStateToProps = ({ notifications }: { notifications: StoredNotification[] }) => ({
    notifications,
});

const mapDispatchToProps = {
    removeNotification,
    addNotification,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type NotificationListProps = ConnectedProps<typeof connector>;

export default connector(NotificationList);

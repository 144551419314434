import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const featureFlags = (state = { flags: {}, isLoading: true }, { type, payload }) => {
    switch (type) {
        case actionTypes.FEATURE_FLAGS_SET_ALL:
            return { flags: payload, isLoading: false };
        case actionTypes.FEATURE_FLAGS_SET_LOADER:
            return { flags: state.flags, isLoading: payload };
        default:
            return state;
    }
};

export default featureFlags;

import { useLayoutEffect, useRef, useState } from 'react';
import { useGraph } from '../../../js/components/graph/GraphProvider';
import { COLOUR_MAPPINGS } from '../../../js/components/graph/elements/elementStyles';
import MapElementIcon from '../../../js/components/icons/MapElementIcon';
import { getElements } from '../../config/graphElements';
import translations from '../../translations';
import ButtonDefault from '../buttons/ButtonDefault';
import { ExIcon, ExInput } from '@boomi/exosphere';
import Modal from '../generic/modal/GenericModal';
import '../../../../css/flow/map-element-picker.less';
import type { GraphElementType, GroupElement } from '../../types';
import { getParentOffset, getParents } from '../../../js/components/graph/utils';
import type { Input } from '@boomi/exosphere/types/src/components';

interface ConfigProps {
    id: string;
    elementType: GraphElementType;
    x: number;
    y: number;
    groupElementId: string;
    fromMapElementId: string | null;
}

interface Props {
    container: HTMLElement;
    openConfig: (
        elementType: string,
        configProps: ConfigProps,
        clearAllSideBarDragElements: () => void,
    ) => void;
    clearAllSideBarDragElements: () => void;
    x: number;
    y: number;
    mapElementPickerHoveredGroupElementId: string;
}

const MapElementPicker = ({ container, openConfig, clearAllSideBarDragElements, x, y }: Props) => {
    const {
        setIsMapElementPickerOpen,
        mapElementPickerFromMapElementId,
        setMapElementPickerFromMapElementId,
        mapElementPickerHoveredGroupElementId,
        groupElements,
    }: {
        setIsMapElementPickerOpen: (open: boolean) => void;
        setMapElementPickerFromMapElementId: (id?: string) => void;
        mapElementPickerFromMapElementId: string | null;
        mapElementPickerHoveredGroupElementId: string;
        groupElements: GroupElement[];
    } = useGraph();
    const [search, setSearch] = useState('');
    const searchRef = useRef<Input>(null);
    const resultsRef = useRef<HTMLDivElement>(null);
    const graphElements = getElements();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useLayoutEffect(() => {
        // ExInput renders empty initially, and focus attempts fail
        // so we need to wait for it to render i.e. height > 0
        if (searchRef?.current && searchRef.current.clientHeight > 0) {
            searchRef.current.focus(undefined);
        }
        // Dependency is not "unnecessary", focus doesn't work when it's removed
    }, [searchRef?.current]);

    const closePicker = () => {
        setIsMapElementPickerOpen(false);
        setMapElementPickerFromMapElementId();
    };

    const createMapElement = (elementType: GraphElementType) => {
        const elementParentOffset = getParentOffset(
            getParents({ groupElementId: mapElementPickerHoveredGroupElementId }, groupElements),
        ) as { x: number; y: number };

        const configProps = {
            id: '',
            elementType,
            x: x - elementParentOffset.x,
            y: y - elementParentOffset.y,
            groupElementId: mapElementPickerHoveredGroupElementId,
            fromMapElementId: mapElementPickerFromMapElementId,
        };
        openConfig(elementType, configProps, clearAllSideBarDragElements);
        closePicker();
    };

    const getSearchedColourElements = (colour: string) => {
        const colouredElements = graphElements
            .filter(
                (element) =>
                    (search.length === 0 ||
                        element.name.toLowerCase().includes(search.toLowerCase())) &&
                    element.group === colour,
            )
            .map(
                (element) =>
                    element.display && (
                        <button
                            key={element.id}
                            className="modal-element no-select new-canvas modal-btn"
                            title={element.name}
                            onClick={() => createMapElement(element.id)}
                            type="button"
                        >
                            <span
                                className="modal-btn-icon"
                                style={{
                                    background: COLOUR_MAPPINGS[element.id],
                                }}
                            >
                                <span className="glyphicon">
                                    <MapElementIcon elementType={element.id} size={17} />
                                </span>
                            </span>
                            <div className="modal-item-text">{element.name}</div>
                        </button>
                    ),
            );
        if (colouredElements?.length > 0) {
            return <span className="colour-group">{colouredElements}</span>;
        }
        return null;
    };

    const blueItems = getSearchedColourElements('blue');
    const greenItems = getSearchedColourElements('green');
    const redItems = getSearchedColourElements('red');
    const greyItems = getSearchedColourElements('grey');

    const renderModalContent = () => (
        <div
            className="canvas-palette full-width"
            onKeyDown={(e) => {
                if (e.key === 'Enter' && resultsRef?.current) {
                    (resultsRef.current.children?.[0]?.children?.[0] as HTMLButtonElement)?.focus();
                }
            }}
        >
            <ExInput
                placeholder="Search for a map element..."
                type="text"
                clearable={true}
                value={search}
                onInput={(e: Event) => {
                    setSearch((e.currentTarget as HTMLInputElement).value);
                }}
                autofocus={true}
                ref={searchRef}
            >
                <ExIcon icon="magnifying-glass" label="Search" slot="prefix" />
            </ExInput>
            <div className="modal-button-wrapper" ref={resultsRef}>
                {blueItems}
                {greenItems}
                {redItems}
                {greyItems}
            </div>
        </div>
    );

    const renderFooter = () => {
        return (
            <>
                <ButtonDefault onClick={closePicker}>
                    {translations.GRAPH_config_panel_cancel}
                </ButtonDefault>
            </>
        );
    };

    return (
        <Modal
            show
            onHide={closePicker}
            container={container}
            className="map-element-picker-modal"
            title={'Select a map element'}
            renderBody={renderModalContent}
            renderFooter={renderFooter}
        />
    );
};

export default MapElementPicker;

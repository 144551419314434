import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addNotification as addNotificationAction } from '../../../../actions/reduxActions/notification';
import { getValueList } from '../../../../../ts/sources/value';
import InputOutputValues from './InputOutputValues';
import Loader from '../../../../../ts/components/loader/Loader';
import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import { matchAllSubFlowArguments } from './subflowUtils';
import { ACCESS_LEVELS } from '../../../../../ts/constants';
import translations from '../../../../../ts/translations';

/**
 * @param {String} flowId
 * @param {String} subFlowId
 * @param {Array} subFlowArguments The raw arguments metadata for the subflow map element
 * @param {Array} subFlowArgumentsToBeRendered The subflow arguments formatted as such to tell
 * the UI how to render the argument e.g how to display the value selector etc
 *
 * @param {Function} setSubFlowArguments Callback for setting arguments in the root components state
 * @param {Function} onSelect Callback for updating a single argument
 * @param {Function} togglePicker Callback to trigger opening/closing the value picker modal
 * @param {Boolean} isLoading Determines whether to show a loading indicator
 * @param {Boolean} isMatching Determines whether to set the matching checkbox to checked/unchecked
 * @param {Function} onMatch Callback for setting subflow arguments in root component state
 * after arguments have been matched
 *
 * @description Renders a UI for displaying subflow arguments
 * across all value access type (input, output, input_output).
 * Displays a checkbox to match/unmatch vales to be passed
 * in/out of the subflow.
 */

const SubFlowArguments = ({
    tenantId,
    subFlowId,
    subFlowArguments,
    subFlowArgumentsToBeRendered,
    setSubFlowArguments,
    onSelect,
    togglePicker,
    onMatch,
    addNotification,
    isLoading,
    isMatching,
}) => {
    /**
     * We need to set all value references available inside the tenant
     * into state as they are used for mapping the "value to be passed"
     * part of the subflow argument when we need to match arguments
     */
    const [valueReferences, setValueReferences] = useState([]);

    const [isFetchingValues, setIsFetchingValues] = useState(true);

    useEffect(() => {
        fetchValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Treat warnings as errors, fix later
    }, []);

    const fetchValues = async () => {
        try {
            const valueRefs = await getValueList(null);

            setValueReferences(valueRefs);
            setIsFetchingValues(false);
        } catch ({ message }) {
            addNotification({
                type: 'error',
                message,
                isPersistent: true,
            });
        }
    };

    const childProps = {
        subFlowId,
        subFlowArguments,
        setSubFlowArguments,
        onSelect,
        togglePicker,
        isLoading,
        isMatching,
        subFlowArgumentsToBeRendered,
        tenantId,
    };

    return (
        <>
            {isFetchingValues ? (
                <Loader />
            ) : (
                <>
                    <div>
                        <ButtonDefault
                            className="match-args"
                            onClick={() =>
                                matchAllSubFlowArguments(
                                    valueReferences,
                                    onMatch,
                                    subFlowArgumentsToBeRendered,
                                )
                            }
                        >
                            {translations.SUBFLOW_auto_match_arguments_button_label}
                        </ButtonDefault>
                    </div>
                    <InputOutputValues
                        valueReferences={valueReferences}
                        access={ACCESS_LEVELS.INPUT.value}
                        {...childProps}
                    />

                    <InputOutputValues
                        valueReferences={valueReferences}
                        access={ACCESS_LEVELS.OUTPUT.value}
                        {...childProps}
                    />

                    <InputOutputValues
                        valueReferences={valueReferences}
                        access={ACCESS_LEVELS.INPUT_OUTPUT.value}
                        {...childProps}
                    />

                    <InputOutputValues
                        valueReferences={valueReferences}
                        access={ACCESS_LEVELS.PUBLIC.value}
                        {...childProps}
                    />
                </>
            )}
        </>
    );
};

const mapDispatchToProps = {
    addNotification: addNotificationAction,
};

export default connect(null, mapDispatchToProps)(SubFlowArguments);

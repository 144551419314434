import { type ComponentProps, useState } from 'react';
import { NOTIFICATION_TYPES } from '../../constants';
import translations from '../../translations';
import type { AssetAPI } from '../../types/asset';
import GenericModal from '../generic/modal/GenericModal';
import AssetManager from './AssetManager';
import store from '../../../js/store/store';
import { addNotification as unconnectedAddNotification } from '../../../js/actions/reduxActions/notification';
import type { AddNotification } from '../../types';

interface AssetManagerModalProps extends Omit<ComponentProps<typeof GenericModal>, 'onHide'> {
    onInsertAsset: (asset: string) => void;
    showAssetModal: boolean;
    setShowAssetModal: (show: boolean) => void;
    allowedExtensions: string | string[];
    filterListToAllowedExtensions: boolean;
}

const addNotification: AddNotification = (notification) => {
    unconnectedAddNotification(notification)(store.dispatch);
};

const AssetManagerModal = ({
    onInsertAsset,
    showAssetModal,
    setShowAssetModal,
    allowedExtensions,
    filterListToAllowedExtensions,
    ...otherProps
}: AssetManagerModalProps) => {
    // Name of asset that the user has clicked on
    const [selectedAssetURL, setSelectedAssetURL] = useState('');
    // Name of asset that has just been deleted
    const [deletedAssetURL, setDeletedAssetURL] = useState('');

    const closeModal = () => {
        setSelectedAssetURL('');
        setDeletedAssetURL('');
        setShowAssetModal(false);
    };

    const insertAsset = () => {
        if (!selectedAssetURL) {
            addNotification({
                type: NOTIFICATION_TYPES.info,
                message: translations.ASSET_manager_modal_no_asset_selected_message,
            });
        } else if (deletedAssetURL === selectedAssetURL) {
            addNotification({
                type: NOTIFICATION_TYPES.info,
                message: translations.ASSET_manager_modal_asset_deleted_message,
            });
        } else {
            try {
                onInsertAsset(selectedAssetURL);
            } catch (error) {
                if (error instanceof Error) {
                    addNotification({
                        type: NOTIFICATION_TYPES.error,
                        message: error.message,
                        isPersistent: true,
                    });
                }
            } finally {
                closeModal();
            }
        }
    };

    const markCurrentAssetAsDeleted = () => {
        setDeletedAssetURL(selectedAssetURL);
    };

    const extractAsset = (asset: AssetAPI) => {
        // So we can keep track of asset currently clicked on
        setSelectedAssetURL(asset.publicUrl);
    };

    return (
        <GenericModal
            backdropClassName="modal-above-TinyMCE"
            title={translations.ASSET_manager_modal_title}
            onHide={closeModal}
            show={showAssetModal}
            renderBody={() => (
                <AssetManager
                    addNotification={addNotification}
                    onAssetSelect={extractAsset}
                    onAssetDelete={markCurrentAssetAsDeleted}
                    allowedExtensions={allowedExtensions}
                    filterListToAllowedExtensions={filterListToAllowedExtensions}
                />
            )}
            renderFooter={() => (
                <>
                    <button type="button" onClick={closeModal} className="btn btn-default">
                        {translations.ASSET_manager_modal_close_button_label}
                    </button>
                    <button type="button" onClick={insertAsset} className="btn btn-primary">
                        {translations.ASSET_manager_modal_insert_asset_button_label}
                    </button>
                </>
            )}
            {...otherProps}
        />
    );
};

export default AssetManagerModal;

import { fetchAndParse } from '../../ts/utils/ajax';
import type { SuggestInput, SuggestPrediction } from '../types/componentSuggest';
import { getTenantId } from '../utils/tenant';

export const suggestComponent = (request: SuggestInput) =>
    fetchAndParse<SuggestPrediction>({
        url: '/api/draw/1/componentSuggest',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: JSON.stringify(request),
    });

import SearchInput from './SearchInput';
import ButtonPrimary from '../buttons/ButtonPrimary';
import ButtonDefault from '../buttons/ButtonDefault';

import { Plus, ArrowsClockwise } from '@phosphor-icons/react';
import type { ReactNode } from 'react';

interface CustomButton {
    name: string;
    onClick: () => void;
}

interface PageHeaderProps {
    title: string;
    searchQuery: string | undefined;
    onSearch: (query: string) => void;
    searchPlaceholderText: string;
    onAdd: () => void;
    onRefresh: () => void;
    refreshTitle: string;
    addText: string;
    children?: ReactNode[];
    customButtons?: CustomButton[];
}

const PageHeader = ({
    title,
    searchQuery,
    onSearch,
    searchPlaceholderText,
    onAdd,
    onRefresh,
    refreshTitle,
    addText,
    children,
    customButtons,
}: PageHeaderProps) => {
    return (
        <>
            <h1>{title}</h1>
            {children}
            <div className="flow-controls-bar">
                <div className="controls-left">
                    <SearchInput
                        value={searchQuery ?? ''}
                        onChange={(value) => onSearch(value)}
                        placeholder={searchPlaceholderText}
                    />
                </div>
                <div className="controls-right">
                    <ButtonPrimary onClick={onAdd}>
                        <Plus />
                        {addText}
                    </ButtonPrimary>
                    {customButtons?.map((customButton) => (
                        <button
                            type="button"
                            key={customButton.name}
                            className="btn btn-primary"
                            onClick={customButton.onClick}
                            title={customButton.name}
                        >
                            {customButton.name}
                        </button>
                    ))}
                    <ButtonDefault
                        aria-label={refreshTitle}
                        title={refreshTitle}
                        onClick={onRefresh}
                    >
                        <ArrowsClockwise />
                    </ButtonDefault>
                </div>
            </div>
        </>
    );
};

export default PageHeader;

import { sortPropertyByTypeElementDeveloperName } from '../../sources/type';
import type { TypeElementResponseAPI, TypeMappingAutoAPI } from '../../types';
import PropertyDetails from './PropertyDetails';

type Types = Record<string, TypeElementResponseAPI>;

interface Props {
    sourceTypeElementId: string;
    targetTypeElementId: string | null | undefined;
    types: Types;
    mapping: TypeMappingAutoAPI | null | undefined;
}

const AutoMappingTypeMatched = ({
    sourceTypeElementId,
    targetTypeElementId,
    types,
    mapping,
}: Props) => {
    const sourceType = types[sourceTypeElementId];
    const targetType = targetTypeElementId ? types[targetTypeElementId] : null;

    return (
        <ol key={sourceType.id}>
            {sourceType.properties.sort(sortPropertyByTypeElementDeveloperName).map((property) => {
                const mappedProperty = mapping?.properties.find(
                    (p) => p.sourceTypeElementEntryId === property.id,
                );
                const targetProperty =
                    mappedProperty && targetType
                        ? targetType.properties.find(
                              (p) => p.id === mappedProperty.targetTypeElementEntryId,
                          )
                        : null;

                return (
                    <li key={property.id}>
                        {targetProperty ? (
                            <PropertyDetails
                                property={targetProperty}
                                types={types}
                                isMatched={false}
                                showMatch={false}
                            />
                        ) : (
                            <p className="property-details" />
                        )}
                        {property.typeElementId ? (
                            <AutoMappingTypeMatched
                                sourceTypeElementId={property.typeElementId}
                                targetTypeElementId={mappedProperty?.children?.targetTypeElementId}
                                types={types}
                                mapping={mappedProperty?.children}
                            />
                        ) : null}
                    </li>
                );
            })}
        </ol>
    );
};

export default AutoMappingTypeMatched;

import HiddenComponentImg from '../../../../../../img/components/Hidden-component.svg';

const HiddenInput = () => {
    return (
        <div
            data-testid="component-preview-hidden-input"
            className="component-preview-image-container"
        >
            <img
                className="component-preview-image"
                alt="Hidden Component Preview"
                src={HiddenComponentImg}
            />
        </div>
    );
};

export default HiddenInput;

import { useState } from 'react';
import translations from '../../../../../ts/translations';
import { isNullOrWhitespace } from '../../../../../ts/utils/guard';
import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import HTMLInput from '../../../../../ts/components/generic/HTMLEditor/HTMLInput';
import NameInput from '../common/NameInput';
import NavigationOverrideList from '../common/navigationOverrides/NavigationOverrideList';
import OutcomeList from '../common/outcomes/OutcomeList';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import ModalBody from '../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../ts/components/generic/modal/ModalFooter';

/**
 * The main step config screen
 */
const StepConfiguration = () => {
    const {
        mapElement,
        onSaveMapElement,
        onClose,
        setMapElement,
        onUpdateName,
        setSelectedNavigationIndex,
        setSelectedOutcomeIndex,
        onSwitchScreen,
    } = useMapElement();

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const isFormValid = isNullOrWhitespace(mapElement?.developerName) === false;

    const onSave = () => {
        setHasSubmitted(true);

        if (isFormValid) {
            onSaveMapElement(mapElement);
        }
    };

    const renderBody = () => (
        <>
            <NameInput
                isValid={isFormValid}
                showValidation={hasSubmitted}
                id="step-name"
                name={mapElement.developerName}
                onUpdateName={onUpdateName}
            />
            <FormGroup label="The content to be displayed to users">
                <HTMLInput
                    contentValue={mapElement.userContent}
                    onChange={(userContent) =>
                        setMapElement({
                            ...mapElement,
                            userContent,
                        })
                    }
                    hasLinkPlugin={true}
                    hasImagePlugin={true}
                    hasValuePickerPlugin={true}
                    height={240}
                />
            </FormGroup>
            <div className="alert alert-warning">
                {'Script tags are not enabled by default. Learn more in our '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://help.boomi.com/csh?context=GUID-6c363b3c-9556-4075-8763-7e98308a80b9"
                >
                    Documentation
                </a>
                .
            </div>
            <OutcomeList
                onAddOutcome={() => {
                    setSelectedOutcomeIndex(null);
                    onSwitchScreen(screens.outcomeDetails);
                }}
            />
            <NavigationOverrideList
                onAddNavigationOverride={() => {
                    setSelectedNavigationIndex(null);
                    onSwitchScreen(screens.navigationDetails);
                }}
            />
            <FormGroup
                label="Message to show users that are not authorized to take action"
                labelId="notAuthorizedMessage"
            >
                <HTMLInput
                    contentValue={mapElement.notAuthorizedMessage}
                    onChange={(notAuthorizedMessage) =>
                        setMapElement({
                            ...mapElement,
                            notAuthorizedMessage,
                        })
                    }
                    hasLinkPlugin={true}
                    hasImagePlugin={true}
                    hasValuePickerPlugin={true}
                    height={240}
                />
            </FormGroup>
            <FormGroup label="Comments about this Step" htmlFor="comments">
                <textarea
                    id="comments"
                    data-testid="comments"
                    className="form-control form-textarea"
                    value={mapElement.developerSummary ?? ''}
                    onChange={({ target: { value: developerSummary } }) =>
                        setMapElement({
                            ...mapElement,
                            developerSummary,
                        })
                    }
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onClose}>
                {translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {translations.GRAPH_config_panel_save}
            </ButtonPrimary>
        </>
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default StepConfiguration;

import { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { COLOUR_MAPPINGS } from '../../../js/components/graph/elements/elementStyles';
import type { Edge as EdgeType } from '../../types';

interface Props {
    edge: EdgeType;
}

const Edge = ({ edge }: Props) => {
    const { id, label, points, headRotation, isSelected } = edge;

    const lastPoint = points[points.length - 1];

    const classes = classNames({
        outcome: true,
        // 'out-of-search': isOutOfSearch,
        // dragging: isDragging,
    });

    let path = `M ${points[0].x} ${points[0].y}`;

    for (let i = 1; i < points.length - 1; i++) {
        path += `L ${points[i].x} ${points[i].y}`;
    }

    // Stop a little short of the map element so the border doesn't cover the arrow head point
    switch (headRotation) {
        case 180: {
            path += `L ${lastPoint.x} ${lastPoint.y - 1}`;
            break;
        }
        case 0: {
            path += `L ${lastPoint.x} ${lastPoint.y + 1}`;
            break;
        }
        case 90: {
            path += `L ${lastPoint.x - 1} ${lastPoint.y}`;
            break;
        }
        case 270: {
            path += `L ${lastPoint.x + 1} ${lastPoint.y}`;
            break;
        }
    }

    const pathRef = useRef<SVGPathElement>(null);
    const [pathMiddle, setPathMiddle] = useState<DOMPoint | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (pathRef?.current?.getTotalLength) {
            setPathMiddle(
                pathRef.current
                    ? pathRef.current.getPointAtLength(pathRef.current.getTotalLength() / 2)
                    : null,
            );
        }
    }, [points]);

    return (
        <g
            data-object="edge"
            data-id={id}
            className={classes}
            // onFocus={() => setHoveringOutcome(outcome.id, originalMapElementId)}
        >
            {isSelected ? (
                <>
                    {/* rounded grey background line */}
                    <path
                        data-testid="outcome-selection-outline"
                        d={path}
                        strokeWidth="10"
                        stroke="#9c9c9c"
                        fill="none"
                        strokeLinejoin="round"
                    />
                    {/* striped light foreground line */}
                    <path
                        d={path}
                        strokeWidth="10.5"
                        stroke="white"
                        fill="none"
                        strokeLinejoin="bevel"
                        strokeDasharray="2"
                    />
                    {/* thinner light line to fill center */}
                    <path
                        d={path}
                        strokeWidth="8.5"
                        stroke="white"
                        fill="none"
                        strokeLinejoin="round"
                    />
                </>
            ) : (
                /* transparent line to make outcome bigger so it is easier to click */
                <path
                    d={path}
                    strokeWidth="10"
                    stroke="transparent"
                    fill="none"
                    strokeLinejoin="round"
                />
            )}
            <title>{label}</title>
            {/* light border to separate overlapping/crossing outcomes */}
            <path d={path} strokeWidth="5" stroke="white" fill="none" />
            {/* main outcome path */}
            <path
                data-testid="outcome-path"
                ref={pathRef}
                d={path}
                strokeWidth="1"
                // stroke={
                //     outcomeTrafficRatio?.hex ? outcomeTrafficRatio.hex : COLOUR_MAPPINGS.outcome
                // }
                stroke={COLOUR_MAPPINGS.outcome}
                fill="none"
            />
            {/* text */}
            {pathMiddle && (
                <text
                    className="outcome-text"
                    x={pathMiddle.x}
                    y={pathMiddle.y}
                    fill={COLOUR_MAPPINGS.outcome}
                >
                    {label}
                </text>
            )}

            {/* ratio of runtime traffic */}
            {/* {pathMiddle && (
                <OutcomeTrafficPercentage
                    x={pathMiddle.x}
                    y={pathMiddle.y}
                    value={outcomeTrafficRatio?.ratioPercentage}
                    isLoading={outcomeTrafficRatio?.isLoading}
                />
            )} */}

            {/* control point */}
            {/* {(isSelected || isHovered || isDragging) &&
                controlPointPosition &&
                outcome.id !== 'new' && (
                    <rect
                        className="outcome-control-point"
                        data-testid="control-point"
                        x={controlPointPosition.x - 3}
                        y={controlPointPosition.y - 3}
                        width="6"
                        height="6"
                        fill={
                            illegalControlPoint && !usePathMiddleForControlPoint
                                ? COLOUR_DANGER
                                : COLOUR_MAPPINGS.outcome
                        }
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            setDraggingData({
                                dragType: GRAPH_ELEMENT_TYPES.controlPoint,
                                elementId: originalMapElementId,
                                outcomeId: outcome.id,
                                previousElementPosition: relativeControlPointPosition,
                                previousMousePosition: { x: e.clientX, y: e.clientY },
                                illegalControlPoint: illegalControlPoint,
                            });
                        }}
                    />
                )} */}
            <polygon
                data-testid="outcome-arrow"
                fill={COLOUR_MAPPINGS.outcome}
                transform={`
                translate(${lastPoint.x} ${lastPoint.y})
                rotate(${headRotation})
            `}
                // Together, (0,0), (3,6), (-3,6), back to (0,0)
                // draw an arrow pointing up
                //        (0,0)
                //        /  \
                //       /    \
                //      /      \
                // (-3,6)------(3,6)
                points="0,0 3,6 -3,6"
            />
            {/* Invisible rect used for outcome redirect dragging */}
            <polygon
                data-testid={`outcome-${label}-redirect`}
                className="outcome-redirect"
                fillOpacity={0}
                transform={`
                translate(${lastPoint.x} ${lastPoint.y})
                rotate(${headRotation})
            `}
                // Together, (-5,0) (-5,10) (5,10) (5,0), back to (0,0)
                // draw a square that overlaps the outcome arrow
                // (-5,0)----(5,0)
                //   |         |
                //   |         |
                //   |         |
                // (-5,10)---(5,10)
                points="-5,0 -5,10 5,10 5,0"
                // onMouseDown={(e) => {
                //     e.stopPropagation();
                //     setDraggingData({
                //         dragType: GRAPH_ELEMENT_TYPES.outcomeEndPoint,
                //         elementId: originalMapElementId,
                //         outcomeId: outcome.id,
                //         previousMousePosition: { x: e.clientX, y: e.clientY },
                //         illegalControlPoint: illegalControlPoint,
                //     });
                // }}
            />
            {/* Circle shown for outcome source */}
            {/* {(isSelected || (isHovered && !outcome.next) || isDragging) && (
                <circle
                    data-testid="outcome-circle"
                    cx={
                        points[0].x +
                        (startOffset.rotation === -90 ? 3 : startOffset.rotation === 90 ? -3 : 0)
                    }
                    cy={
                        points[0].y +
                        (startOffset.rotation === 0 ? 3 : startOffset.rotation === 180 ? -3 : 0)
                    }
                    r="3"
                    fill={COLOUR_MAPPINGS.outcome}
                />
            )} */}
            {/* Invisible rect used for outcome source redirect dragging */}
            {/* <polygon
                className="outcome-redirect"
                data-testid={`outcome-circle-dragbox-${label}`}
                fillOpacity={0}
                transform={`
                translate(${points[0].x} ${points[0].y})
                rotate(${startOffset.rotation})
            `}
                // Together, (-5,0) (-5,10) (5,10) (5,0), back to (0,0)
                // draw a square that overlaps the outcome source circle
                // (-5,0)----(5,0)
                //   |         |
                //   |         |
                //   |         |
                // (-5,10)---(5,10)
                points="-5,0 -5,10 5,10 5,0"
                onMouseDown={(e) => {
                    e.stopPropagation();
                    setDraggingData({
                        dragType: GRAPH_ELEMENT_TYPES.outcomeStartPoint,
                        elementId: originalMapElementId,
                        outcomeId: outcome.id,
                        previousMousePosition: { x: e.clientX, y: e.clientY },
                        illegalControlPoint: illegalControlPoint,
                    });
                }}
            /> */}
        </g>
    );
};

export default memo(Edge);

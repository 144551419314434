import classNames from 'classnames';
import { type ReactNode, forwardRef, type ForwardedRef } from 'react';
import CodeEditor, { type CodeEditorProps } from '../../generic/CodeEditor';
import './editor.less';

export interface EditorProps extends Omit<CodeEditorProps, 'name'> {
    children?: ReactNode;
    ref: ForwardedRef<HTMLDivElement>;
}

const Editor = (
    { className, readOnly, mode, value, onChange, children }: EditorProps,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const containerClasses = classNames('editor-container', className);

    return (
        <div className={containerClasses} ref={ref}>
            {children}
            <CodeEditor
                readOnly={readOnly}
                name="editor"
                mode={mode}
                value={value}
                onChange={onChange}
            />
        </div>
    );
};

export default forwardRef(Editor);

import type {
    Breakpoint,
    BreakpointHits,
    ContentType,
    CriteriaType,
    Property,
    StateValue,
} from '../../../../../types';

import { isEqual } from 'date-fns';

const evaluateBreakpoint = (
    actualContentValue: string | null,
    expectedContentValue: string,
    operator: CriteriaType,
    contentType: ContentType,
) => {
    if (actualContentValue === null) {
        return false;
    }

    switch (operator) {
        case 'EQUAL': {
            if (contentType === 'ContentDateTime' || contentType === 'ContentDate') {
                return isEqual(new Date(actualContentValue), new Date(expectedContentValue));
            }

            return actualContentValue === expectedContentValue;
        }
        case 'NOT_EQUAL': {
            if (contentType === 'ContentDateTime' || contentType === 'ContentDate') {
                return !isEqual(new Date(actualContentValue), new Date(expectedContentValue));
            }

            return actualContentValue !== expectedContentValue;
        }
        case 'GREATER_THAN': {
            if (contentType === 'ContentDateTime' || contentType === 'ContentDate') {
                return Date.parse(actualContentValue) > Date.parse(expectedContentValue);
            }

            if (contentType === 'ContentNumber') {
                return Number.parseInt(actualContentValue) > Number.parseInt(expectedContentValue);
            }

            if (contentType === 'ContentString' || contentType === 'ContentContent') {
                return actualContentValue.length > expectedContentValue.length;
            }

            return false;
        }
        case 'GREATER_THAN_OR_EQUAL': {
            if (contentType === 'ContentDateTime' || contentType === 'ContentDate') {
                return Date.parse(actualContentValue) >= Date.parse(expectedContentValue);
            }

            if (contentType === 'ContentNumber') {
                return Number.parseInt(actualContentValue) >= Number.parseInt(expectedContentValue);
            }

            if (contentType === 'ContentString' || contentType === 'ContentContent') {
                return actualContentValue.length >= expectedContentValue.length;
            }

            return false;
        }
        case 'LESS_THAN': {
            if (contentType === 'ContentDateTime' || contentType === 'ContentDate') {
                return Date.parse(actualContentValue) < Date.parse(expectedContentValue);
            }

            if (contentType === 'ContentNumber') {
                return Number.parseInt(actualContentValue) < Number.parseInt(expectedContentValue);
            }

            if (contentType === 'ContentString' || contentType === 'ContentContent') {
                return actualContentValue.length < expectedContentValue.length;
            }

            return false;
        }
        case 'LESS_THAN_OR_EQUAL': {
            if (contentType === 'ContentDateTime' || contentType === 'ContentDate') {
                return Date.parse(actualContentValue) <= Date.parse(expectedContentValue);
            }

            if (contentType === 'ContentNumber') {
                return Number.parseInt(actualContentValue) <= Number.parseInt(expectedContentValue);
            }

            if (contentType === 'ContentString' || contentType === 'ContentContent') {
                return actualContentValue.length <= expectedContentValue.length;
            }

            return false;
        }
        default:
            return false;
    }
};

export const checkBreaks = (
    values: StateValue[] | Property[],
    breakpoints: Breakpoint[],
): BreakpointHits[] | null => {
    const hits: BreakpointHits[] = [];

    const breakpointHits = (values: Property[] | StateValue[]) =>
        breakpoints.forEach((breakpoint) => {
            const breakpointValueId = breakpoint.value?.id;
            const breakpointOperator = breakpoint.operator;
            const breakpointExpectedContentValue = breakpoint.expectedContentValue;
            const breakpointTypeElementPropertyId = breakpoint.value?.typeElementPropertyId;
            const stateValue = values?.find((sv) =>
                'valueElementId' in sv
                    ? sv.valueElementId === breakpointValueId
                    : sv.typeElementPropertyId === breakpointTypeElementPropertyId,
            );

            const isComplexValue =
                stateValue?.contentType === 'ContentObject' ||
                stateValue?.contentType === 'ContentList';

            // We need to evaluate object data properties
            if (isComplexValue && stateValue.objectData) {
                stateValue.objectData.forEach((od) => {
                    const property = od.properties.find(
                        (prop) => prop.typeElementPropertyId === breakpointTypeElementPropertyId,
                    );

                    if (property) {
                        breakpointHits([property]);
                    }
                });
            }

            if (
                stateValue &&
                !isComplexValue &&
                breakpoint.value &&
                breakpointOperator &&
                breakpointExpectedContentValue
            ) {
                const conditionPasses = evaluateBreakpoint(
                    stateValue.contentValue,
                    breakpointExpectedContentValue,
                    breakpointOperator,
                    stateValue.contentType,
                );
                if (conditionPasses) {
                    hits.push({ breakpointId: breakpoint.id, value: breakpoint.value });
                }
            }
        });

    breakpointHits(values);

    return hits.length ? hits : null;
};

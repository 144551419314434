import { useEffect, useState } from 'react';
import { getValueList } from '../../../sources/value';
import Table from '../../generic/Table';
import translations from '../../../translations';
import type { ContentType, ValueElementIdAPI, ValueElementIdReferenceAPI } from '../../../types';
import ValueSelectorModal from './ValueSelectorModal';
import { ExLoader } from '@boomi/exosphere';

interface Item {
    developerName: string;
    contentType: string;
    typeElementId: string | null;
    order: number;
    valueElementToReferenceId: ValueElementIdAPI | null;
    valueElementToApplyId?: ValueElementIdAPI | null;
}

interface Props {
    items: Item[];
    selectValue: (value: ValueElementIdAPI | null, item: Item) => void;
    getStartingValueName?: (item: Item) => string;
    includeSystemValues?: boolean | undefined;
    modalContainer: HTMLElement | null;
}

const ValueSelectorTable = ({
    items,
    selectValue,
    getStartingValueName,
    includeSystemValues,
    modalContainer,
}: Props) => {
    const [valueReferences, setValueReferences] = useState<ValueElementIdReferenceAPI[]>([]);
    const [loading, setLoading] = useState(true);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            if (!Array.isArray(items) || items.length === 0) {
                setLoading(false);
                return;
            }

            try {
                setValueReferences(
                    await getValueList({
                        id: items
                            .map(
                                (item) =>
                                    item.valueElementToReferenceId?.id ||
                                    item.valueElementToApplyId?.id,
                            )
                            .filter((item) => !!item) as string[],
                    }),
                );
            } finally {
                setLoading(false);
            }
        };

        load();
    }, []);

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }: { item: Item }) => item.developerName,
            size: '25%',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_value,
            renderCell: ({ item }: { item: Item }) => {
                const onChange = (value: ValueElementIdAPI | null) => {
                    selectValue(value, item);
                };

                const value =
                    valueReferences.find(
                        (valueReference) =>
                            (item.valueElementToReferenceId?.id === valueReference.id &&
                                item.valueElementToReferenceId?.typeElementPropertyId ===
                                    valueReference.typeElementPropertyId) ||
                            (item.valueElementToApplyId?.id === valueReference.id &&
                                item.valueElementToApplyId?.typeElementPropertyId ===
                                    valueReference.typeElementPropertyId),
                    ) ||
                    item.valueElementToReferenceId ||
                    item.valueElementToApplyId ||
                    null;

                return (
                    <ValueSelectorModal
                        includeSystemValues={
                            includeSystemValues !== undefined ? includeSystemValues : true
                        }
                        value={value}
                        contentType={item.contentType as ContentType}
                        typeElementId={item.typeElementId}
                        startingValueName={getStartingValueName?.(item)}
                        onChange={onChange}
                        container={modalContainer}
                        key={item.developerName}
                    />
                );
            },
        },
    ];

    return loading ? (
        <ExLoader />
    ) : (
        <Table<Item> items={items ?? []} columns={columns} testId="values-table" />
    );
};

export default ValueSelectorTable;

import _ from 'lodash';
import { clone, equals, partition, pluck, prop, uniqBy } from 'ramda';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useCollaboration } from '../../../ts/collaboration/CollaborationProvider';
import { useAuth } from '../../../ts/components/AuthProvider';
import {
    deleteElements as deleteElementsAPI,
    deleteGroupElement as deleteGroupElementAPI,
    deleteMapElement as deleteMapElementAPI,
    getFlowGraph2,
    getFullGraphElements,
    getMapElement as getMapElementAPI,
    saveFlowGraph2,
    saveFullGraphElements,
    saveGroupElement as saveGroupElementAPI,
    saveMapElement as saveMapElementAPI,
} from '../../../ts/sources/graph';
import translations from '../../../ts/translations';
import {
    addNote as addNoteAction,
    deleteNote as deleteNoteAction,
    setAllNotes as setAllNotesAction,
    updateNote as updateNoteAction,
} from '../../actions/reduxActions/canvasNotes';
import {
    setClipboard as setClipboardAction,
    setDraggingData as setDraggingDataAction,
} from '../../actions/reduxActions/graphEditor';
import { notifyError as notifyErrorAction } from '../../actions/reduxActions/notification';
import {
    NOTIFICATION_TYPES,
    CONTEXT_MENU_NO_CREATE_OPTION,
    FLOW_EDITING_TOKEN,
    GRAPH_ELEMENT_TYPES,
    HYPERLINK_ELEMENTS,
    MAP_ELEMENT_TYPES,
} from '../../../ts/constants';
import { getByID } from '../../../ts/utils/collection';
import { guid } from '../../../ts/utils/guid';
import { isNullOrEmpty, isNullOrWhitespace } from '../../../ts/utils/guard';
import { getElementStyles, MAP_ELEMENT_HEIGHT, MAP_ELEMENT_WIDTH } from './elements/elementStyles';
import {
    autoArrange,
    calculateElementBounds,
    findHighlightDescendants,
    focusElement,
    getElementDimensions,
    getElementsDimensions,
    getParentOffset,
    getParents,
    isGroupElement,
    isMapElement,
    moveControlPoints,
    snap,
    snapPosition,
    zoomToFit,
} from './utils';
import {
    initializeTrafficRatio,
    calculateRatioPercentages,
} from '../../../ts/components/flow/insights/utils';
import { getOutcomeEvents } from '../../../ts/sources/outcomeinsights';
import { addPositions, subtractPositions } from '../../../ts/components/graph/utils';
import { useDebugConfig } from '../../../ts/components/flow/debug/DebugConfigProvider';

const Context = createContext(undefined);

const UnconnectedGraphProvider = ({
    children,
    flowId,
    addNotification,
    notifyError,
    clipboard,
    setClipboard,
    addNote,
    updateNote,
    deleteNote,
    isActive,
    dragging,
    setDraggingData,
    isNew,
    setAllNotes,
    canvasNotes,
    isTenantSelectorOpen,
}) => {
    const { subscribe, unsubscribe, invoke } = useCollaboration();
    const { user, tenant, userSettings } = useAuth();

    const { id: currentUserId, email } = user;
    const { id: tenantId } = tenant;
    const { canvasSettings } = userSettings;

    const [mapElements, setMapElements] = useState([]);
    const [groupElements, setGroupElements] = useState([]);
    const [startElementId, setStartElementId] = useState(null);

    const setSomeMapElements = (updatedElements) =>
        setMapElements(uniqBy(prop('id'), [...updatedElements, ...mapElements]));

    const setSomeGroupElements = (updatedElements) =>
        setGroupElements(uniqBy(prop('id'), [...updatedElements, ...groupElements]));

    const graphChanged = (mapElementIds, groupElementId, deletedElementIds) => {
        invoke(
            'GraphChanged',
            flowId,
            [...(mapElementIds || []), ...(groupElementId || [])],
            deletedElementIds,
        );
    };

    const setMapElement = (updatedMapElement, collaborate) => {
        if (collaborate === true) {
            graphChanged([updatedMapElement.id], null, null);
        }

        setMapElements((prevMapElements) => [
            ...prevMapElements.filter((m) => m.id !== updatedMapElement.id),
            updatedMapElement,
        ]);
    };

    const removeMapElement = (id, collaborate) => {
        if (collaborate === true) {
            graphChanged(null, null, [id]);
        }

        setMapElements([...mapElements.filter((m) => m.id !== id)]);
    };

    const moveMapElement = ({ elementId, delta }) => {
        const mapElement = getByID(elementId, mapElements);
        setMapElement({
            ...moveControlPoints(mapElement, snapPosition(delta)),
            ...snapPosition(addPositions(mapElement, delta)),
        });
    };

    const setGroupElement = (updatedGroupElement, collaborate) => {
        if (collaborate === true) {
            graphChanged(null, [updatedGroupElement.id], null);
        }

        setGroupElements((prevGroupElements) => [
            ...prevGroupElements.filter((g) => g.id !== updatedGroupElement.id),
            updatedGroupElement,
        ]);
    };

    const removeGroupElement = (id, collaborate) => {
        if (collaborate === true) {
            graphChanged(null, null, [id]);
        }

        setGroupElements([...groupElements.filter((g) => g.id !== id)]);
    };

    const moveGroupElement = ({ elementId, x, y }) => {
        const oldGroupElement = getByID(elementId, groupElements);
        setGroupElement({ ...oldGroupElement, x, y });
    };

    // Be wary adding new states to the GraphProvider, especially states that are updated from outside this component.
    // This can end up in a large amount of re-renders which will severely impact performance.
    const [selectedElementIds, setSelectedElementIds] = useState([]);
    const [selectedOutcomeId, setSelectedOutcomeId] = useState(null);
    const [selectedOutcomeMapElementId, setSelectedOutcomeMapElementId] = useState(null);
    const [hoveringOutcomeId, setHoveringOutcomeId] = useState(null);
    const [hoveringOutcomeMapElementId, setHoveringOutcomeMapElementId] = useState(null);
    const [highlightedElementIds, setHighlightedElementIds] = useState([]);
    const [isPreviewingAutoArrange, setIsPreviewingAutoArrange] = useState(false);

    const [groupNameEditing, setGroupNameEditing] = useState(false);
    const [noteEditing, setNoteEditing] = useState(false);
    const [isMetadataMenuOpen, setIsMetadataMenuOpen] = useState(false);
    const [isConfigMenuOpen, setIsConfigMenuOpen] = useState(false);
    const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
    const [isMapElementPickerOpen, setIsMapElementPickerOpen] = useState(false);
    const [isFlowHistoryModalOpen, setIsFlowHistoryModalOpen] = useState(false);
    const [isSearchFocused, setIsSearchFocused] = useState(false);

    const [mapElementPickerFromMapElementId, setMapElementPickerFromMapElementId] = useState(false);
    const [mapElementPickerHoveredGroupElementId, setMapElementPickerHoveredGroupElementId] =
        useState('');

    const [tabbedElementId, setTabbedElementId] = useState(null);
    const [tabbableElements, setTabbableElements] = useState([]);

    const [noteTabIndex, setNoteTabIndex] = useState(null);
    const noteDisplayBase = ['name', 'actions'];
    const noteDisplayContent = 'content';
    const noteDisplayOptions = ['edit', 'delete'];
    const noteFormStates = ['name-input', 'content-input', 'cancel', 'save'];

    const contextMenuElement = useRef(null);
    const [contextMenuTabIndex, setContextMenuTabIndex] = useState(-1);
    const [insightsConfigView, setInsightsConfigView] = useState(null);
    const [outcomeTrafficRatios, setOutcomeTrafficRatios] = useState({});

    const graphSelector = `graph-svg-${flowId}`;

    const [isLoading, setIsLoading] = useState(true);

    const blockHotkeys =
        isSearchFocused ||
        groupNameEditing ||
        noteEditing ||
        isMetadataMenuOpen ||
        isConfigMenuOpen ||
        isActionMenuOpen ||
        !isActive ||
        isLoading ||
        isNew ||
        isTenantSelectorOpen ||
        insightsConfigView ||
        isMapElementPickerOpen ||
        isFlowHistoryModalOpen;

    // Combine all elements into 1 array and then sort them into an order that makes more sense.
    // Possibly a way to optimise this for existing elements being updated.
    // Pass in the most up to date elements each time so debounce/useCallback can be used to optimise.
    const sortElementsByPosition = (
        updatedMapElements,
        updatedGroupElements,
        updatedTabbableElements,
    ) => {
        const allElements = [...updatedMapElements, ...updatedGroupElements];

        // Sort by X first and then sort by Y, accounting for elements in groups. May want to make this smarter in the future.
        allElements.sort((a, b) => {
            // Get the positions of each element as if they weren't in a group.
            const parentOffsetA = getParentOffset(getParents(a, updatedGroupElements));
            const parentOffsetB = getParentOffset(getParents(b, updatedGroupElements));
            const aPos = { y: a.y + parentOffsetA.y, x: a.x + parentOffsetA.x };
            const bPos = { y: b.y + parentOffsetB.y, x: b.x + parentOffsetB.x };

            return aPos.y - bPos.y || aPos.x - bPos.x;
        });

        if (equals(allElements, tabbableElements)) {
            return;
        }

        // Reset the final element tabIndex value to -1 if it exists when a re-sort happens.
        if (updatedTabbableElements.length > 0) {
            const finalElement = document.getElementById(
                updatedTabbableElements[updatedTabbableElements.length - 1].id,
            );

            if (finalElement) {
                finalElement.tabIndex = -1;
            }
        }

        // Set the final element to tabIndex 0 so it can tabbed to when tabbing backwards from outside the graph.
        if (!isNullOrEmpty(allElements[allElements.length - 1])) {
            const finalElement = document.getElementById(allElements[allElements.length - 1].id);
            if (!isNullOrEmpty(finalElement)) {
                finalElement.tabIndex = 0;
            }
        }

        if (equals(allElements, tabbableElements)) {
            return;
        }

        setTabbableElements(allElements);
    };

    // Sorting elements happens when any element saves so debounce this to reduce how many time this happens
    // for cases such as moving multiple elements. May want to adjust rate if needed.
    const sortElementsDebounced = _.debounce(sortElementsByPosition, 400);

    const refreshFlow = useCallback(
        async (graphElement = null, zoomViewBox = null) => {
            try {
                if (!isNew) {
                    const flow = await getFlowGraph2(flowId);

                    // empty map elements first
                    // to avoid errors when deleting a map element and its parent
                    setMapElements([]);
                    setGroupElements(flow.groupElements);

                    /**
                     * Bit of a problem here - the endpoint behind getFlowGraph
                     * only returns a shallow copy of the map element metadata, so no
                     * operations, macros etc. We run a big risk of parts of the canvas
                     * thinking that these fields are empty, or worse, saving these fields back to the engine.
                     */
                    setMapElements(flow.mapElements);

                    /**
                     * setStartElementId triggers functions to run that use the mapElements state.
                     * We need the mapElements state to be updated before these functions use them.
                     * */
                    requestAnimationFrame(() => {
                        const startElement = flow.mapElements.find(
                            (map) => map.developerName?.toLocaleLowerCase() === 'start',
                        );
                        if (startElement) {
                            setStartElementId(startElement.id);
                        }
                    });

                    const notes = flow.mapElements.filter(
                        (element) => element.elementType === 'note',
                    );

                    // Request all notes only to get note content
                    if (notes.length > 0) {
                        const response = await getFullGraphElements(flowId, {
                            mapElementIds: notes.map(({ id }) => id),
                            groupElementIds: [],
                        });

                        setAllNotes(response.mapElements, flowId);
                    } else {
                        setAllNotes([], flowId);
                    }

                    // If we were given these params, then zoomToFit the graph
                    if (graphElement && zoomViewBox) {
                        zoomToFit(flow.mapElements, flow.groupElements, graphElement, zoomViewBox);
                    }
                }
                setIsLoading(false);
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: error.message,
                    isPersistent: true,
                });
            }
        },
        [addNotification, flowId, isNew, setAllNotes],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        refreshFlow();
    }, [isNew, refreshFlow]);

    useEffect(() => {
        if (!isLoading) {
            sortElementsDebounced(mapElements, groupElements, tabbableElements);
        }
    }, [mapElements, groupElements, isLoading, sortElementsDebounced, tabbableElements]);

    useEffect(() => {
        const onGraphChanged = (changedMapElements, changedGroupElements, deletedElementIds) => {
            let newGroupElements = [];
            let newMapElements = [];

            for (const groupElement of groupElements) {
                if (deletedElementIds?.includes(groupElement.id)) {
                    continue;
                }

                if (changedGroupElements?.[groupElement.id]) {
                    newGroupElements.push({
                        ...groupElement,
                        ...changedGroupElements[groupElement.id],
                    });

                    delete changedGroupElements[groupElement.id];
                } else {
                    newGroupElements.push(groupElement);
                }
            }

            if (changedGroupElements && Object.keys(changedGroupElements).length > 0) {
                newGroupElements = newGroupElements.concat(Object.values(changedGroupElements));
            }

            for (const mapElement of mapElements) {
                if (deletedElementIds?.includes(mapElement.id)) {
                    continue;
                }

                if (changedMapElements?.[mapElement.id]) {
                    newMapElements.push({
                        ...mapElement,
                        ...changedMapElements[mapElement.id],
                    });

                    delete changedMapElements[mapElement.id];
                } else {
                    newMapElements.push(mapElement);
                }
            }

            if (changedMapElements && Object.keys(changedMapElements).length > 0) {
                newMapElements = newMapElements.concat(Object.values(changedMapElements));
            }

            if (isPreviewingAutoArrange) {
                // Ignore position changes if previewing an arrangement
                // we will either get these later on reload when we "Revert"
                // or be setting them when we "Save" the arrangement
                newGroupElements = newGroupElements.map((group) => {
                    const { x, y } = getByID(group.id, groupElements);
                    return { ...group, x, y };
                });
                newMapElements = newMapElements.map((map) => {
                    const { x, y } = getByID(map.id, mapElements);
                    return { ...map, x, y };
                });
            }

            setGroupElements(newGroupElements);
            setMapElements(newMapElements);
        };

        subscribe('GraphChanged', onGraphChanged);

        return () => {
            unsubscribe('GraphChanged', onGraphChanged);
        };
    }, [isPreviewingAutoArrange, mapElements, groupElements, subscribe, unsubscribe]);

    const setSelectedOutcome = (id = null, mapElementId = null) => {
        if (isNullOrEmpty(id) !== isNullOrEmpty(mapElementId)) {
            throw Error('Missing id or mapElementId of the selected outcome');
        }
        setSelectedOutcomeId(id);
        setSelectedOutcomeMapElementId(mapElementId);
    };

    const setHoveringOutcome = (id = null, mapElementId = null) => {
        if (isNullOrEmpty(id) !== isNullOrEmpty(mapElementId)) {
            throw Error('Missing id or mapElementId of the hovered outcome');
        }
        setHoveringOutcomeId(id);
        setHoveringOutcomeMapElementId(mapElementId);
    };

    const toggleSelectedElementId = (toggleId, selectedElementIds) => {
        if (selectedElementIds.includes(toggleId)) {
            setSelectedElementIds(selectedElementIds.filter((id) => id !== toggleId));
        } else {
            setSelectedElementIds([...selectedElementIds, toggleId]);
        }
    };

    const resetSelections = () => {
        setSelectedElementIds([]);
        setSelectedOutcome();
    };

    const copyElement = async (selectedElementIds, selectedOutcomeId) => {
        try {
            if (isNullOrEmpty(selectedElementIds)) {
                if (selectedOutcomeId) {
                    throw Error('You cannot copy outcomes');
                }
                // It is intentional to not throw an error if they copy with no selections,
                // as this means they clicked the keyboard shortcut,
                // and they may have just been copying text
                return;
            }

            let [mapElementIds, groupElementIds] = partition(
                (id) => getByID(id, mapElements) !== undefined,
                selectedElementIds,
            );

            // Map elements contained in a group should be copied when the group is copied
            ({ mapElementIds, groupElementIds } = copyInternalGroupElements(
                mapElementIds,
                groupElementIds,
            ));

            const response = await getFullGraphElements(flowId, {
                mapElementIds,
                groupElementIds,
            });

            let containsStartElement = false;

            response.mapElements.forEach((element) => {
                if (element.elementType.toLowerCase() === MAP_ELEMENT_TYPES.start) {
                    containsStartElement = true;
                }
            });

            // If the user tried to copy the start element
            if (containsStartElement) {
                // Show an error and stop
                throw Error('You cannot copy the Start element');
            }

            const offsetFinder = (element) => {
                if (
                    // If the element doesn't have a parent
                    element.groupElementId === null ||
                    // or the user also copied an element's parent,
                    getByID(element.groupElementId, response.groupElements)
                ) {
                    // then we don't need to touch the x,y,
                    // because the parent hasn't changed
                    return element;
                }

                // However, if the user hasn't copied the element's parent,
                // we need to add the x,y offset the parent is visually giving,
                // so it appears in the right place
                const parentOffset = getParentOffset(getParents(element, groupElements));
                if (parentOffset) {
                    element.x += parentOffset.x;
                    element.y += parentOffset.y;
                }
                return element;
            };

            response.mapElements.map(offsetFinder);
            response.groupElements.map(offsetFinder);

            setClipboard({
                elements: response,
                flowId,
                explicitlySelectedElements: selectedElementIds,
            });

            addNotification({
                type: NOTIFICATION_TYPES.info,
                message: `Copied ${selectedElementIds?.length} element${
                    selectedElementIds?.length === 1 ? '' : 's'
                }`,
                isPersistent: false,
            });
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const copyInternalGroupElements = (mapElementIds, groupElementIds) => {
        if (groupElementIds.length > 0) {
            groupElementIds.forEach((elementId) => {
                const matchToThisGroup = (el) => {
                    return el.groupElementId === elementId;
                };

                const groupedMapElements = mapElements.filter(matchToThisGroup);
                const groupedGroups = groupElements.filter(matchToThisGroup);
                if (groupedMapElements.length > 0) {
                    groupedMapElements.forEach((groupedMapEl) => {
                        mapElementIds.push(groupedMapEl.id);
                    });
                }
                if (groupedGroups.length > 0) {
                    groupedGroups.forEach((groupedGroup) => {
                        const nested = copyInternalGroupElements([], [groupedGroup.id]);
                        // biome-ignore lint/style/noParameterAssign: Requires dedicated refactor
                        mapElementIds = mapElementIds.concat(nested.mapElementIds);
                        // biome-ignore lint/style/noParameterAssign: Requires dedicated refactor
                        groupElementIds = groupElementIds.concat(nested.groupElementIds);
                    });
                }
            });
        }
        return { mapElementIds, groupElementIds };
    };

    const pasteElement = async ({ pastePosition = null, graphCentre = null }) => {
        if (isNullOrEmpty(clipboard)) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: 'Please copy a map or group element before pasting',
                isPersistent: true,
            });
            return;
        }
        setIsLoading(true);

        const isDifferentFlow = clipboard.flowId !== flowId;
        const explicitlySelectedNewElements = [];

        // ID mapping changes
        const swapIDMapper = (mapping, element) => {
            const oldID = element.id;
            const newID = guid();
            mapping[oldID] = { ...element, id: newID };
            if (clipboard.explicitlySelectedElements?.includes(oldID)) {
                explicitlySelectedNewElements.push(newID);
            }
            return mapping;
        };

        // Loop over and change ids and store mapping
        const mapIDMapping = clipboard.elements.mapElements.reduce(swapIDMapper, {});
        const groupIDMapping = clipboard.elements.groupElements.reduce(swapIDMapper, {});

        const allNewElementIDs = [
            ...Object.values(mapIDMapping).map((element) => element.id),
            ...Object.values(groupIDMapping).map((element) => element.id),
        ];

        const swapOutcomeAndGroupIDMapper = (isGroup) => (element) => {
            let newGroupElementID = '';

            if (element.groupElementId) {
                if (groupIDMapping[element.groupElementId]) {
                    // If the new group element came with us, then swap the id over
                    newGroupElementID = groupIDMapping[element.groupElementId].id;
                }
            }

            if (isGroup) {
                return { ...element, groupElementId: newGroupElementID };
            }
            // Change outcomes to point to new elements
            const newIDOutcomes = [];

            element.outcomes?.forEach((outcome) => {
                if (outcome.nextMapElementId) {
                    if (mapIDMapping[outcome.nextMapElementId]) {
                        // The destination for this outcome came with us, so we swap out to the new one
                        newIDOutcomes.push({
                            ...outcome,
                            nextMapElementId: mapIDMapping[outcome.nextMapElementId].id,
                        });
                        return;
                    }
                    if (isDifferentFlow) {
                        // We changed flows, and didn't copy the destination map element, so delete this outcome (don't push to the new list)
                        return;
                    }
                    // We have pasted to the same flow, and didn't copy the destination map element, so keep this outcome pointing to the old element
                    newIDOutcomes.push(outcome);
                    return;
                }
                // There was no nextMapElementID, so the outcome is okay to keep
                newIDOutcomes.push(outcome);
            });

            return { ...element, outcomes: newIDOutcomes, groupElementId: newGroupElementID };
        };

        // Loop over and replace old id references with new ids using mapping
        const allNewIDMapElements = Object.values(mapIDMapping).map(
            swapOutcomeAndGroupIDMapper(false),
        );
        const allNewIDGroupElements = Object.values(groupIDMapping).map(
            swapOutcomeAndGroupIDMapper(true),
        );

        // Positional changes
        const centrePosition = pastePosition
            ? // Pasting using the right click context menu,
              // we use the mouse position the context menu gave us
              pastePosition
            : // Otherwise, pasting using the keyboard shortcut,
              // we use the centre of the graph
              graphCentre;

        // We have the centre position for pasting
        // Now we need to know the centre for all our pasting map elements
        // ┌───────────┐
        // │[ ]→[ ]    │
        // │     ↓     │
        // │    [ ]→[ ]│
        // └───────────┘
        const { lowestX, highestX, lowestY, highestY } = getElementsDimensions(
            allNewElementIDs,
            allNewIDMapElements,
            allNewIDGroupElements,
        );
        const oldCentrePosition = {
            x: (lowestX + highestX) / 2,
            y: (lowestY + highestY) / 2,
        };
        const movementOfAllElements = subtractPositions(centrePosition, oldCentrePosition);
        // And move them to the new centre position
        //  [ ]->[ ]
        //        •
        //       [ ]→[ ]
        const moveElement = (element) =>
            isNullOrEmpty(element.groupElementId)
                ? {
                      ...element,
                      ...snapPosition(addPositions(element, movementOfAllElements)),
                  }
                : element;

        const positionedMapElements = allNewIDMapElements.map(moveElement);
        const positionedGroupElements = allNewIDGroupElements.map(moveElement);

        const updateOverridesAndName = (element) => {
            const newElement = {
                ...element,
                whoCreated: null,
                whoModified: null,
                whoOwner: null,
                developerName: element.developerName,
                navigationOverrides: isDifferentFlow ? null : element.navigationOverrides,
            };

            if (newElement.elementType.toLowerCase() === MAP_ELEMENT_TYPES.note) {
                // Update canvasNotes store
                addNote(newElement, flowId, {
                    readOnly: true,
                    // Newly made notes are open for editing
                    isOpen: true,
                    meta: { whoCreated: { email } },
                });
            }

            return newElement;
        };

        // Save them all to the flow
        await saveFullGraphElements(flowId, {
            mapElements: positionedMapElements.map(updateOverridesAndName),
            groupElements: positionedGroupElements.map(updateOverridesAndName),
        });

        // Set selection to new elements
        setSelectedElementIds(explicitlySelectedNewElements);

        // Update canvas
        return await refreshFlow();
    };

    const getMapElement = async (id) => {
        try {
            return await getMapElementAPI(id, flowId, FLOW_EDITING_TOKEN);
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const saveMapElement = async (mapElement) => {
        setIsLoading(true);

        try {
            const response = await saveMapElementAPI(mapElement, flowId, FLOW_EDITING_TOKEN);

            setMapElement(response, true);

            await refreshFlow();
            focusAndSelectElement(response.id);
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const saveMapElements = async (mapElements) => {
        setIsLoading(true);

        try {
            for (const element of mapElements) {
                const mapElementData = await saveMapElementAPI(element, flowId, FLOW_EDITING_TOKEN);
                setMapElement(mapElementData);
            }

            await refreshFlow();
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const saveNote = async (note, isNew = false, newNoteData = {}) => {
        setIsLoading(true);

        note.userContentDateModified = new Date().toISOString();

        try {
            const response = await saveMapElementAPI(note, flowId, FLOW_EDITING_TOKEN);

            if (isNew) {
                // When a new mapelement is created the entire canvas
                // contents need to be fetched again so everything can re-render
                await refreshFlow();
                addNote(response, flowId, newNoteData);

                focusAndSelectElement(response.id);
            } else {
                updateNote(response);
            }
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Uses the `/graph/flow` endpoint for a partial/coordinate only save of elements.
     *
     * Saves only the given elements to the engine.
     * Takes either a list of `elementsIds` from the context or `elements` to save
     *
     * (defaults to save all elements)
     */
    const saveElements = async ({ elementIds = [], elements = [] }) => {
        try {
            const request = {
                id: flowId,
                ...(isNullOrEmpty(elementIds)
                    ? isNullOrEmpty(elements)
                        ? {
                              mapElements,
                              groupElements,
                          }
                        : {
                              mapElements: elements.filter(isMapElement),
                              groupElements: elements.filter(isGroupElement),
                          }
                    : {
                          mapElements: mapElements.filter((map) => elementIds.includes(map.id)),
                          groupElements: groupElements.filter((group) =>
                              elementIds.includes(group.id),
                          ),
                      }),
            };

            await saveFlowGraph2(request);

            graphChanged(
                pluck('id', request.mapElements),
                pluck('id', request.groupElements),
                null,
            );
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
        }
    };

    const resizeGroupElement = ({
        elementId,
        side,
        delta,
        currentMousePosition,
        calculateDraggingOverElementID,
        hasMovedEnough,
    }) => {
        const previousDraggingData = dragging;

        const { x: previousX, y: previousY } = previousDraggingData.previousMousePosition;

        const deltaX = delta.x;
        const deltaY = delta.y;

        let groupElement = groupElements.find((ge) => ge.id === elementId);

        // We want to override the saved snapped position with the saved raw position
        // so that any small changes and round ups or downs do not lose us our position under the mouse
        groupElement = { ...groupElement, ...previousDraggingData.previousElementPosition };

        let { x: newX, y: newY, width: newWidth, height: newHeight } = groupElement;
        if (side.includes('top')) {
            newHeight = groupElement.height - deltaY;
            newY = groupElement.y + deltaY;
        }
        if (side.includes('bottom')) {
            newHeight = groupElement.height + deltaY;
        }
        if (side.includes('left')) {
            newWidth = groupElement.width - deltaX;
            newX = groupElement.x + deltaX;
        }
        if (side.includes('right')) {
            newWidth = groupElement.width + deltaX;
        }

        let undoX = false;
        let undoY = false;

        // do not allow group size to be smaller than a map element
        if (newHeight < MAP_ELEMENT_HEIGHT) {
            undoY = true;
        }
        if (newWidth < MAP_ELEMENT_WIDTH) {
            undoX = true;
        }

        // do not allow group to be resized out of its parent
        const parentGroup = groupElements.find((ge) => ge.id === groupElement.groupElementId);
        if (parentGroup) {
            if (newY < 0 || newY + newHeight > parentGroup.height) {
                undoY = true;
            }
            if (newX < 0 || newX + newWidth > parentGroup.width) {
                undoX = true;
            }
        }

        // do not allow child elements to leave group
        const childrenMapElements = [...mapElements, ...groupElements].filter(
            (m) => m.groupElementId === elementId,
        );
        if (childrenMapElements.length > 0) {
            childrenMapElements.forEach((mapElement) => {
                const { width, height } = getElementDimensions(mapElement);
                // group too small - child element hits the bottom of the group
                if (mapElement.y + height > newHeight) {
                    undoY = true;
                }
                // group too small - child element hits the right of the group
                if (mapElement.x + width > newWidth) {
                    undoX = true;
                }
            });
        }

        // Use these for setDraggingData, so we do not update an axis when resizing is restricted
        // this means that the user must return their pointer to the location of the restriction
        // which avoids an issue where the pointer is out of sync with the dragging handle
        let newDraggingX = currentMousePosition.x;
        let newDraggingY = currentMousePosition.y;
        // Undo the changes to the axis as if the delta was 0
        if (undoX) {
            newWidth = groupElement.width;
            newX = groupElement.x;
            newDraggingX = previousX;
        }
        if (undoY) {
            newHeight = groupElement.height;
            newY = groupElement.y;
            newDraggingY = previousY;
        }

        const resizedGroup = {
            ...groupElement,
            x: snap(newX),
            y: snap(newY),
            width: snap(newWidth),
            height: snap(newHeight),
        };

        calculateDraggingOverElementID(calculateElementBounds(resizedGroup, groupElements));

        setGroupElement(resizedGroup);

        setDraggingData({
            ...previousDraggingData,
            previousElementPosition: {
                x: newX,
                y: newY,
                width: newWidth,
                height: newHeight,
            },
            previousMousePosition: { x: newDraggingX, y: newDraggingY },
            hasMovedEnough,
        });
    };

    const ungroup = async ({ selectedGroupElementId }) => {
        const selectedGroup = groupElements.find((el) => el.id === selectedGroupElementId);
        const childGroupElements = groupElements.filter(
            (el) => el.groupElementId === selectedGroupElementId,
        );
        const childMapElements = mapElements.filter(
            (el) => el.groupElementId === selectedGroupElementId,
        );

        // remove all child group elements from the selected group
        childGroupElements.forEach((group) => {
            group.groupElementId = selectedGroup.groupElementId;
            group.x += selectedGroup.x;
            group.y += selectedGroup.y;
        });

        // remove all child map elements from the selected group
        childMapElements.forEach((map) => {
            map.groupElementId = selectedGroup.groupElementId;
            map.x += selectedGroup.x;
            map.y += selectedGroup.y;
            // Move the outcomes along with the map elements
            map.outcomes = moveControlPoints(map, selectedGroup).outcomes;
        });

        setIsLoading(true);

        // save ui changes first to prevent control point flicker
        setMapElements(uniqBy(prop('id'), [...childMapElements, ...mapElements]));
        removeGroupElement(selectedGroupElementId);

        // save child changes to engine
        await saveElements({
            elements: [...childGroupElements, ...childMapElements],
        });

        // delete the selected group element in the engine
        await deleteGroupElementAPI(selectedGroupElementId, flowId, FLOW_EDITING_TOKEN);

        resetSelections();

        // collaboration update after all engine changes are synced
        graphChanged(null, null, [selectedGroupElementId]);

        setIsLoading(false);
    };

    const groupSelectedElements = async ({ x, y, width, height }) => {
        // check that all selected elements have the same parent
        const selectedElements = selectedElementIds.map(
            (id) =>
                // cloned so that useMemo causes a rerender
                clone(mapElements).find((el) => el.id === id) ||
                clone(groupElements).find((el) => el.id === id),
        );
        const sameParent = selectedElements.every(
            (el) => el.groupElementId === selectedElements[0].groupElementId,
        );
        if (!sameParent) {
            // the selected elements must all share the same groupElementId

            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.create_group_parents_error,
                isPersistent: true,
            });
            return;
        }

        const { header } = getElementStyles(MAP_ELEMENT_TYPES.group);

        setIsLoading(true);
        // save the new group
        const newGroupParentOffset = getParentOffset(
            getParents(selectedElements[0], groupElements),
        );
        const newGroup = await saveGroupElementAPI(
            {
                x: x - newGroupParentOffset.x,
                y: y - newGroupParentOffset.y - header.height,
                width,
                height: height + header.height,
                elementType: MAP_ELEMENT_TYPES.group,
                developerName: 'New group',
                groupElementId: selectedElements[0].groupElementId,
            },
            flowId,
            FLOW_EDITING_TOKEN,
        );

        // update selected elements
        selectedElements.forEach((element) => {
            element.groupElementId = newGroup.id;
            element.x -= newGroup.x;
            element.y -= newGroup.y;

            // Move the outcomes along with the map elements
            element.outcomes = moveControlPoints(element, {
                x: -newGroup.x,
                y: -newGroup.y,
            }).outcomes;
        });

        await saveElements({
            elements: [newGroup, ...selectedElements],
        });

        const selectedGroupElements = selectedElements.filter(isGroupElement);
        const selectedMapElements = selectedElements.filter(isMapElement);

        setGroupElements(
            uniqBy(prop('id'), [newGroup, ...selectedGroupElements, ...groupElements]),
        );
        setMapElements(uniqBy(prop('id'), [...selectedMapElements, ...mapElements]));
        setSelectedElementIds([newGroup.id]);

        setIsLoading(false);
    };

    const deleteMapElement = async (id) => {
        try {
            await deleteMapElementAPI(id, flowId, FLOW_EDITING_TOKEN);
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
            return;
        }

        // update notes
        deleteNote(id);

        // update canvas
        resetSelections();
        removeMapElement(id, true);
    };

    const deleteGroupElement = async (id) => {
        try {
            await deleteGroupElementAPI(id, flowId, FLOW_EDITING_TOKEN);
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
            return;
        }

        // update canvas
        resetSelections();
        graphChanged([], [], selectedElementIds);
        await refreshFlow();
    };

    const deleteOutcome = async (id, mapElementId) => {
        setIsLoading(true);

        try {
            let mapElement = await getMapElementAPI(mapElementId, flowId, FLOW_EDITING_TOKEN);

            mapElement = {
                ...mapElement,
                outcomes: mapElement.outcomes.filter((outcome) => outcome.id !== id),
            };

            await saveMapElementAPI(mapElement, flowId, FLOW_EDITING_TOKEN);

            resetSelections();
            graphChanged([mapElement.id], [], []);
            await refreshFlow();
            setMapElement(mapElement);
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
    };

    const deleteElements = async (
        elementsToDeleteIds,
        allDeletedElementIds,
        mapElementsToUpdate,
    ) => {
        setIsLoading(true);

        try {
            const [mapElementIds, groupElementIds] = partition(
                (id) => getByID(id, mapElements) !== undefined,
                elementsToDeleteIds,
            );
            await deleteElementsAPI(flowId, { mapElementIds, groupElementIds });
        } catch (error) {
            addNotification({
                type: 'error',
                message: error.message,
                isPersistent: true,
            });
            setIsLoading(false);
            return;
        }

        // update canvas
        resetSelections();
        graphChanged(mapElementsToUpdate || [], [], allDeletedElementIds);
        await refreshFlow();
    };

    const removeOutcome = ({ type, mapElement }) => {
        const outcomes = mapElement.outcomes
            ? mapElement.outcomes.filter((outcome) => outcome.id !== type)
            : mapElement.outcomes;
        return { ...mapElement, outcomes };
    };

    const findAndRemoveOutcome = ({ type, mapElementId }) => {
        const mapElement = mapElements.find((el) => el.id === mapElementId);
        return removeOutcome({ type, mapElement });
    };

    const addFakeOutcomeToXY = ({ fromMapElementId, toXY }) => {
        const type = 'new';
        const mapElement = findAndRemoveOutcome({ type, mapElementId: fromMapElementId });
        const outcomes = mapElement?.outcomes ?? [];
        outcomes.push({
            id: type,
            next: {
                x: toXY.x,
                y: toXY.y,
            },
        });
        setMapElement({ ...mapElement, outcomes });
    };

    const addFakeOutcomeToMapElement = ({ fromMapElementId, toMapElementId }) => {
        const type = 'pending';
        const mapElement = findAndRemoveOutcome({ type, mapElementId: fromMapElementId });
        const outcomes = mapElement?.outcomes ?? [];
        outcomes.push({
            id: type,
            nextMapElementId: toMapElementId,
        });
        setMapElement({ ...mapElement, outcomes });
    };

    const removeFakeOutcome = ({ mapElementId, type }) => {
        setMapElement(findAndRemoveOutcome({ mapElementId, type }));
    };

    const removeAllFakeOutcomes = ({ type }) => {
        setMapElements(mapElements.map((mapElement) => removeOutcome({ type, mapElement })));
    };

    const dragElement = ({
        calculateElementPosition,
        elementId,
        currentMousePosition,
        newHasMovedEnough,
        calculateDraggingOverElementID,
        elementList,
    }) => {
        const newElementPosition = calculateElementPosition();
        const snappedNewElementPosition = snapPosition(newElementPosition);

        const isDraggingElementInSelection = selectedElementIds.includes(elementId);

        if (!isDraggingElementInSelection) {
            setSelectedElementIds([elementId]);
        }

        const delta = subtractPositions(
            snappedNewElementPosition,
            dragging.previousElementPosition,
        );
        if (isDraggingElementInSelection && selectedElementIds.length > 1) {
            moveElements({ delta, elementIds: selectedElementIds });
        } else {
            moveElements({ delta, elementIds: [elementId] });
        }

        setDraggingData({
            ...dragging,
            dragType: dragging.dragType,
            elementId,
            previousElementPosition: newElementPosition,
            previousMousePosition: currentMousePosition,
            hasMovedEnough: newHasMovedEnough,
        });

        calculateDraggingOverElementID(
            calculateElementBounds(
                {
                    ...elementList.find((el) => el.id === elementId),
                    ...snappedNewElementPosition,
                },
                groupElements,
            ),
        );
    };

    const moveElements = ({ delta, elementIds }) =>
        elementIds.forEach((elementId) => {
            const groupElement = groupElements.find((el) => el.id === elementId);

            if (groupElement) {
                if (elementIds.includes(groupElement.groupElementId) === false) {
                    moveGroupElement({
                        elementId,
                        ...snapPosition(addPositions(groupElement, delta)),
                    });
                }
            }

            const mapElement = mapElements.find((el) => el.id === elementId);

            if (mapElement) {
                if (elementIds.includes(mapElement.groupElementId) === false) {
                    moveMapElement({
                        elementId,
                        delta,
                    });
                }
            }
        });

    const highlightGroupElement = (groupElement) => {
        // Highlight the descendants of this group element
        setHighlightedElementIds(
            findHighlightDescendants(groupElement.id, mapElements, groupElements),
        );
    };

    const snapToOutOfViewElements = (element, viewBox, setViewBox) => {
        const movingElement = calculateElementBounds(element, groupElements);
        const xPos = movingElement.x + movingElement.width / 2;
        const yPos = movingElement.y + movingElement.height / 2;

        if (
            xPos < viewBox.x ||
            yPos < viewBox.y ||
            xPos > viewBox.x + viewBox.width ||
            yPos > viewBox.y + viewBox.height
        ) {
            setViewBox({
                x: movingElement.x + movingElement.width / 2 - viewBox.width / 2,
                y: movingElement.y + movingElement.height / 2 - viewBox.height / 2,
                width: viewBox.width,
                height: viewBox.height,
            });
        }
    };

    const focusElementAndSnap = (element, viewBox, setViewBox) => {
        if (viewBox !== null && setViewBox !== null) {
            snapToOutOfViewElements(element, viewBox, setViewBox);
        }

        focusElement(element.id);
    };

    const isNoteOpen = (id) => {
        const note = canvasNotes.find((note) => note.id === id);

        if (!isNullOrEmpty(note)) {
            return note.isOpen;
        }

        return false;
    };

    // Checks by Id if a note is being edited (not read only)
    const isNoteReadOnly = (id) => {
        const note = canvasNotes.find((note) => note.id === id);

        if (!isNullOrEmpty(note)) {
            return note.readOnly;
        }
    };

    const isNoteContentPopulated = (id) => {
        const note = canvasNotes.find((note) => note.id === id);

        if (!(isNullOrEmpty(note) || isNullOrWhitespace(note.meta.userContent))) {
            return true;
        }

        return false;
    };

    // Check whether to use the alternative note tabbing logic for when tabbing through the note form/display.
    const isNoteTabbing = (event, currentIndex) => {
        const currentElement = tabbableElements[currentIndex];

        // Any of these cases should return false so normal element tabbing logic can take over.
        if (
            isNullOrEmpty(currentElement) || // Element is null
            !isNoteOpen(currentElement?.id) || // Note is not open
            currentIndex <= -1 || // Current Index is below 0
            (noteTabIndex === null && event.shiftKey) // Focus is on the note element and the user is tabbing backwards
        ) {
            return false;
        }

        // The conditions above aren't true and the current element is a note, carry on with note tabbing logic.
        if (currentElement.elementType === MAP_ELEMENT_TYPES.note) {
            return true;
        }

        // Fallback in case anything slips through, better to assume there's no note.
        return false;
    };

    // For hyperlinks focus them based on ID but don't update the state. Normal tabbing will continue once tab is hit again, in either direction.
    // Only focus the hyperlink if moving forwards, that way users can't focus from the next element.
    const isHyperlinkTabbing = (event, currentIndex) => {
        if (
            !event.shiftKey &&
            currentIndex > -1 &&
            HYPERLINK_ELEMENTS.includes(tabbableElements[currentIndex].elementType) &&
            document.activeElement.getAttribute('id') !==
                `${tabbableElements[currentIndex].id}-hyper-link`
        ) {
            return true;
        }

        return false;
    };

    const generateNoteTabOrder = (id) => {
        let steps = [];
        // Note is not being edited so provide Ids from the NoteDisplay component.
        if (isNoteReadOnly(id)) {
            steps = steps.concat(noteDisplayBase);

            // If the note content is empty it shouldn't be tabbable.
            if (isNoteContentPopulated(id)) {
                steps.splice(1, 0, noteDisplayContent);
            }

            // Additionally append the option menu if it is not hidden.
            const optionsElement = document.getElementById(`${id}-options-menu`);
            if (optionsElement.ariaHidden === 'false') {
                steps = steps.concat(noteDisplayOptions);
            }
        }
        // Note is being edited so provide Ids for the NoteForm component.
        else {
            steps = steps.concat(noteFormStates);
        }

        return steps;
    };

    // Logic for when tabbing on a note, either the form or display.
    const onTabNote = (event, currentIndex) => {
        event.preventDefault();

        const currentElement = tabbableElements[currentIndex];

        // Generate a tabbing order based on whether the note is being edited and the options are open.
        const noteTabSteps = generateNoteTabOrder(currentElement.id);

        if (!event.shiftKey) {
            // Tabbing forward into the note for the first time, set up initial index and focus the first step.
            if (noteTabIndex === null) {
                setNoteTabIndex(0);
                focusElement(`${currentElement.id}-${noteTabSteps[0]}`);
                return;
            }

            // Increment index and if there's steps to go then set focus and index to the next step.
            const nextNoteIndex = noteTabIndex + 1;

            if (nextNoteIndex < noteTabSteps.length) {
                setNoteTabIndex(nextNoteIndex);
                focusElement(`${currentElement.id}-${noteTabSteps[nextNoteIndex]}`);
                return;
            }

            // Reached the end so remove the index and set the focus back to the parent NoteElement component.
            setNoteTabIndex(null);
            focusElement(currentElement.id);
        }
        // Tabbing backwards, make sure noteTabIndex is not null as this means we are back at the start.
        else if (event.shiftKey && noteTabIndex !== null) {
            // Decrement index and as long as it's above -1 set the focus and index to the previous step.
            const prevNoteIndex = noteTabIndex - 1;

            if (prevNoteIndex > -1) {
                setNoteTabIndex(prevNoteIndex);
                focusElement(`${currentElement.id}-${noteTabSteps[prevNoteIndex]}`);
                return;
            }

            // Reached the beginning so remove the index and set the focus back to the parent NoteElement component.
            setNoteTabIndex(null);
            focusElement(currentElement.id);
        }
    };

    // Get the IDs for each of the possible context options based on the type of element that the menu has been opened on.
    const getContextMenuOptions = (activeElement) => {
        const contextElement = tabbableElements.find((ele) => ele.id === activeElement.id);
        const options = [];

        // Some map element can have more or less than the normal set of options.
        if (activeElement.type === GRAPH_ELEMENT_TYPES.map) {
            // Start is a special case that only has 'Create Outcome', return it straight away.
            if (contextElement.elementType.toLowerCase() === MAP_ELEMENT_TYPES.start) {
                return ['context-menu-option-create-outcome'];
            }

            // Most but not all have the 'Create Outcome' option, this filters out those that don't.
            if (!CONTEXT_MENU_NO_CREATE_OPTION.includes(contextElement.elementType)) {
                options.push('context-menu-option-create-outcome');
            }
        }

        // Everything apart from start has at least these options.
        options.push(
            'context-menu-option-edit',
            'context-menu-option-edit-meta',
            'context-menu-option-copy',
            'context-menu-option-delete',
        );

        return options;
    };

    // Intercepts Tab key presses from anything that is a child of the graph, this includes the graph itself, map elements, group elements and notes.
    // Most of the time it will override the default behaviour and manually set the focus, based on element position and store this in state.
    const onTab = (event, viewBox = null, setViewBox = null) => {
        const currentIndex = tabbableElements.findIndex((ele) => ele.id === tabbedElementId);

        // Context menu is open for the currently tabbed element
        if (contextMenuElement.current?.id === tabbedElementId) {
            const contextMenuOptions = getContextMenuOptions(contextMenuElement.current);

            if (!event.shiftKey) {
                event.preventDefault();

                const newIndex = contextMenuTabIndex + 1;

                if (newIndex < contextMenuOptions.length) {
                    focusElement(contextMenuOptions[newIndex]);
                    setContextMenuTabIndex(newIndex);
                    return;
                }

                // Come to the end of the elements so set back to the active element.
                if (currentIndex === tabbableElements.length - 1) {
                    focusElement(tabbableElements[currentIndex].id);
                }

                // Used up all the options, set to -1 so order is reset when tabbing forward again.
                setContextMenuTabIndex(-1);
            } else if (event.shiftKey && contextMenuTabIndex > -1) {
                event.preventDefault();

                const newIndex = contextMenuTabIndex - 1;

                if (newIndex > -1) {
                    focusElement(contextMenuOptions[newIndex]);
                } else {
                    focusElement(tabbableElements[currentIndex].id);
                }

                setContextMenuTabIndex(newIndex);
                return;
            }
        }

        // For notes handle the tabbing logic separately.
        if (isNoteTabbing(event, currentIndex)) {
            onTabNote(event, currentIndex);
            return;
        }

        // For hyperlinks do something a bit different
        if (isHyperlinkTabbing(event, currentIndex)) {
            event.preventDefault();
            focusElement(`${tabbableElements[currentIndex].id}-hyper-link`);
            return;
        }

        // Tabbing forwards. Keep going unless we've reached the end of the elements, then let the browser take over.
        if (!event.shiftKey && currentIndex < tabbableElements.length - 1) {
            event.preventDefault();

            // Tabbing in from the graph, set the focus to the first element.
            if (tabbedElementId === null) {
                setTabbedElementId(tabbableElements[0].id);
                focusElementAndSnap(tabbableElements[0], viewBox, setViewBox);
                return;
            }

            const nextIndex = currentIndex + 1;

            // Bump the tabbedElementId along if it's not the last element, update state and focus.
            if (nextIndex < tabbableElements.length) {
                setTabbedElementId(tabbableElements[nextIndex].id);
                focusElementAndSnap(tabbableElements[nextIndex], viewBox, setViewBox);
            }
        }
        // About to tab past the last element so reset the tabbedElementId to null.
        else if (!event.shiftKey && currentIndex >= tabbableElements.length - 1) {
            setTabbedElementId(null);
        }
        // Tabbing backwards.
        else if (event.shiftKey && tabbedElementId !== null) {
            event.preventDefault();

            const prevIndex = currentIndex - 1;

            if (prevIndex >= 0) {
                setTabbedElementId(tabbableElements[prevIndex].id);
                focusElementAndSnap(tabbableElements[prevIndex], viewBox, setViewBox);
            }
            // At the start of the elements again so focus the graph and null tabbedElementId
            else if (currentIndex !== -1) {
                setTabbedElementId(null);
                focusElement(graphSelector);
            }
        }
        // Handle tabbing backwards from the end of the graph.
        // Exclude times where we are tabbing from the graph itself to prevent looping.
        else if (
            event.shiftKey &&
            tabbedElementId === null &&
            document.activeElement.getAttribute('id') !== graphSelector
        ) {
            event.preventDefault();

            // If there's 2 or more elements then set the tabbedElementId to the second from last one.
            // This is because the tab backwards is handled at first by setting tab index to 0 on the last element.
            if (tabbableElements.length > 1) {
                setTabbedElementId(tabbableElements[tabbableElements.length - 2].id);
                focusElementAndSnap(
                    tabbableElements[tabbableElements.length - 2],
                    viewBox,
                    setViewBox,
                );
            }
            // Only 1 element? Simply set to the graph instead.
            else {
                setTabbedElementId(null);
                focusElement(graphSelector);
            }
        }
    };

    // Handles hitting enter on some of the options on a note, for example, editing the note content should tab to the content field
    // and saving should refocus the note, rather than whatever is after it.
    const setDynamicNoteFocus = (id, step) => {
        switch (step) {
            case 'cancel':
            case 'save': {
                setNoteTabIndex(null);
                focusElement(id);
                break;
            }
            case 'edit':
            case 'name': {
                setNoteTabIndex(0);
                focusElement(`${id}-name-input`);
                break;
            }
            case 'content': {
                setNoteTabIndex(1);
                focusElement(`${id}-content-input`);
                break;
            }
            default: {
                setNoteTabIndex(null);
                focusElement(id);
            }
        }
    };

    // Set the in state focus manually for if a user clicks on an element.
    const setDynamicFocus = (id) => {
        const focusedElement = tabbableElements.find((ele) => ele.id === id);

        if (!isNullOrEmpty(focusedElement)) {
            setTabbedElementId(focusedElement.id);
        }
    };

    // Pass in an ID to focus on an element. Useful for keyboard controls
    // to make it easier to pick up where you left off when a config is closed.
    const focusAndSelectElement = (id) => {
        if (!isNullOrEmpty(id)) {
            document.getElementById(id)?.focus();
            setSelectedElementIds([id]);
            setDynamicFocus(id);
        }
    };

    const arrange = ({ graphElement, zoomViewBox, groupId = null, selectedElementIds = null }) =>
        autoArrange({
            setIsLoading,
            setIsPreviewingAutoArrange,

            mapElements,
            setSomeMapElements,

            topLevelGroupId: groupId,
            groupElements,
            setSomeGroupElements,

            graphElement,
            zoomViewBox,

            notifyError,

            selectedElementIds,
        });

    const autoArrangeSelectedElements = ({ graphElement, zoomViewBox }) =>
        arrange({ graphElement, zoomViewBox, selectedElementIds });

    const autoArrangeGroup = ({ graphElement, zoomViewBox, groupId }) =>
        arrange({ graphElement, zoomViewBox, groupId });

    const autoArrangeGraph = ({ graphElement, zoomViewBox }) =>
        arrange({
            graphElement,
            zoomViewBox,
            selectedElementIds: selectedElementIds.length > 0 ? selectedElementIds : null,
        });

    const resetOutcomeDragging = () =>
        setMapElements(
            mapElements.map((mapElement) => ({
                ...mapElement,
                outcomes: mapElement.outcomes?.map((out) => ({
                    ...out,
                    from: undefined,
                    next: undefined,
                })),
            })),
        );

    const calculateTrafficRatios = async (mapElementId) => {
        setOutcomeTrafficRatios(
            initializeTrafficRatio(mapElementId, mapElements, outcomeTrafficRatios),
        );

        try {
            const mapElement = mapElements.find((mapElement) => mapElement.id === mapElementId);
            const outcomes = mapElement.outcomes || [];
            const outcomeEventsRequests = outcomes.map((outcome) =>
                getOutcomeEvents({
                    payload: {
                        timePeriod: 'month',
                        outcome: outcome.id,
                        mapElement: mapElementId,
                        flow: flowId,
                    },
                }),
            );
            const events = await Promise.all(outcomeEventsRequests);
            const ratios = calculateRatioPercentages(events, outcomes);
            setOutcomeTrafficRatios({ ...outcomeTrafficRatios, ...ratios });
        } catch ({ message }) {
            addNotification({
                type: 'error',
                message,
                isPersistent: true,
            });
        }
    };

    const contextValue = {
        startElementId,
        refreshFlow,
        copyElement,
        pasteElement,
        saveMapElement,
        saveMapElements,
        saveNote,
        saveElements,
        groupSelectedElements,
        ungroup,
        deleteOutcome,
        deleteMapElement,
        deleteGroupElement,
        deleteElements,
        highlightedElementIds,
        setHighlightedElementIds,
        highlightGroupElement,
        isLoading,
        setIsLoading,
        dragElement,
        // Map/Group elements (before saving to engine)
        mapElements,
        getMapElement,
        setMapElement,
        setMapElements,
        groupElements,
        setGroupElement,
        setGroupElements,
        moveGroupElement,
        resizeGroupElement,
        // Fake temporary Outcomes
        addFakeOutcomeToXY,
        addFakeOutcomeToMapElement,
        removeAllFakeOutcomes,
        removeFakeOutcome,
        mapElementPickerFromMapElementId,
        setMapElementPickerFromMapElementId,
        mapElementPickerHoveredGroupElementId,
        setMapElementPickerHoveredGroupElementId,
        // Dragging outcome locations
        resetOutcomeDragging,
        // Map/Group element selection
        selectedElementIds,
        setSelectedElementIds,
        toggleSelectedElementId,
        resetSelections,
        // Outcome selection
        selectedOutcomeId,
        selectedOutcomeMapElementId,
        setSelectedOutcome,
        // Hovering
        hoveringOutcomeId,
        hoveringOutcomeMapElementId,
        setHoveringOutcome,
        // Disabling keyboard shortcuts
        isActive,
        blockHotkeys,
        groupNameEditing,
        setGroupNameEditing,
        noteEditing,
        setNoteEditing,
        isMetadataMenuOpen,
        setIsMetadataMenuOpen,
        isConfigMenuOpen,
        setIsConfigMenuOpen,
        isActionMenuOpen,
        setIsActionMenuOpen,
        isMapElementPickerOpen,
        setIsMapElementPickerOpen,
        isSearchFocused,
        setIsSearchFocused,
        onTab,
        setDynamicFocus,
        setDynamicNoteFocus,
        focusAndSelectElement,
        setContextMenuTabIndex,
        currentUserId,
        contextMenuElement,
        // Auto arrange
        isPreviewingAutoArrange,
        setIsPreviewingAutoArrange,
        tenantId,
        autoArrangeGraph,
        autoArrangeGroup,
        autoArrangeSelectedElements,
        canvasSettings,
        insightsConfigView,
        setInsightsConfigView,
        outcomeTrafficRatios,
        calculateTrafficRatios,
        // History
        isFlowHistoryModalOpen,
        setIsFlowHistoryModalOpen,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useGraph = () => {
    // biome-ignore lint/correctness/useHookAtTopLevel: Treat warnings as errors, fix later
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useGraph must be used within a GraphProvider');
    }
    return context;
};

// A higher order component to pull in the useGraph Provider context
const useGraphHOC = (Component) => {
    return (props) => {
        // biome-ignore lint/correctness/useHookAtTopLevel: Treat warnings as errors, fix later
        const graphProps = useGraph();
        // biome-ignore lint/correctness/useHookAtTopLevel: Treat warnings as errors, fix later
        const debugConfigProps = useDebugConfig();

        const collaborationProps = {
            // biome-ignore lint/correctness/useHookAtTopLevel: Treat warnings as errors, fix later
            collaboration: useCollaboration(),
        };

        return (
            <Component {...props} {...graphProps} {...collaborationProps} {...debugConfigProps} />
        );
    };
};

const GraphProvider = connect(
    ({ graphEditor: { clipboard, dragging }, canvasNotes }) => ({
        clipboard,
        canvasNotes,
        dragging,
    }),
    {
        setClipboard: setClipboardAction,
        addNote: addNoteAction,
        updateNote: updateNoteAction,
        setDraggingData: setDraggingDataAction,
        deleteNote: deleteNoteAction,
        setAllNotes: setAllNotesAction,
        notifyError: notifyErrorAction,
    },
)(UnconnectedGraphProvider);

export { GraphProvider, useGraph, useGraphHOC };

import {
    type ReactNode,
    createContext,
    useCallback,
    useContext,
    useReducer,
    useState,
} from 'react';
import {
    BREADCRUMB_ACTION_TYPES,
    createBreadcrumbReducer,
    type BreadcrumbState,
} from '../../reducers/breadcrumbs';
import {
    deleteType as deleteTypeAPI,
    getTypeListV2,
    saveType as saveTypeAPI,
} from '../../sources/type';
import {
    ElementType,
    type Filter,
    type NotifyError,
    type NotifySuccess,
    type OrderDirection,
    type Paging,
    type TypeElementPropertyAPI,
    type TypeElementRequestAPI,
    type TypeElementResponseAPI,
} from '../../types';
import { guid } from '../../utils/guid';

type TypesProviderContext = {
    TYPE_SCREENS: typeof TYPE_SCREENS;
    fetchTypes: (filters: Filter) => Promise<void>;
    saveType: () => Promise<void>;
    deleteType: () => Promise<void>;
    types: TypeElementResponseAPI[];
    typesLoading: boolean;
    currentScreen: TypeScreen;
    createNewType: () => void;
    typeToEdit: TypeElementRequestAPI;
    updateTypeName: (developerName: string) => void;
    updateTypeComments: (developerSummary: string) => void;
    setTypeToEdit: React.Dispatch<React.SetStateAction<TypeElementRequestAPI>>;
    createNewTypeProperty: () => void;
    editType: (type: TypeElementRequestAPI) => void;
    propertyToEdit: TypeElementPropertyAPI;
    breadcrumbs: BreadcrumbState<TypeScreen>;
    editProperty: (property: TypeElementPropertyAPI) => void;
    pushBreadCrumb: (id: TypeScreen, content: string) => void;
    returnToType: () => void;
    returnToTypeList: () => void;
    applyProperty: (property: TypeElementPropertyAPI) => void;
    paging: Paging & {
        search: string;
        orderBy: string;
        orderDirection: OrderDirection;
    };
    propertyPaging: {
        page: number;
        pageSize: number;
        total: number;
        search: string;
    };
    updatePropertyPaging: React.Dispatch<
        React.SetStateAction<{
            page: number;
            pageSize: number;
            total: number;
            search: string;
        }>
    >;
    setTypeToDelete: React.Dispatch<React.SetStateAction<TypeElementRequestAPI | null>>;
    typeToDelete: TypeElementRequestAPI | null;
    propertyToDelete: TypeElementPropertyAPI | null;
    setPropertyToDelete: React.Dispatch<React.SetStateAction<TypeElementPropertyAPI | null>>;
    removeProperty: () => void;
    tenantId: string;
    notifyError: NotifyError;
    notifySuccess: NotifySuccess;
    importProfile: () => void;
};

const Context = createContext<TypesProviderContext | undefined>(undefined);

type TypesProviderProps = {
    notifyError: NotifyError;
    notifySuccess: NotifySuccess;
    tenantId: string;
    children?: ReactNode;
};

const TYPE_SCREENS = {
    typeList: 'typeList',
    typeDetail: 'typeDetail',
    typePropertyDetail: 'typePropertyDetail',
    importProfile: 'importProfile',
} as const;

const initialTypeToEdit: TypeElementRequestAPI = {
    id: '',
    serviceElementDeveloperName: null,
    properties: [],
    bindings: [],
    updateByName: false,
    serviceElementId: null,
    elementType: ElementType.Type,
    developerName: '',
    developerSummary: null,
};

const initialPropertyToEdit: TypeElementPropertyAPI = {
    id: '',
    developerName: '',
    contentType: null,
    contentFormat: null,
    typeElementId: null,
    typeElementDeveloperName: null,
};

type TypeScreen = (typeof TYPE_SCREENS)[keyof typeof TYPE_SCREENS];

const TypesProvider = ({ notifyError, notifySuccess, tenantId, children }: TypesProviderProps) => {
    const [currentScreen, setCurrentScreen] = useState<TypeScreen>(TYPE_SCREENS.typeList);
    const [types, setTypes] = useState<TypeElementResponseAPI[]>([]);
    const [typesLoading, setTypesLoading] = useState(true);
    const [typeToEdit, setTypeToEdit] = useState<TypeElementRequestAPI>(initialTypeToEdit);
    const [typeToDelete, setTypeToDelete] = useState<TypeElementRequestAPI | null>(null);
    const [propertyToEdit, setPropertyToEdit] =
        useState<TypeElementPropertyAPI>(initialPropertyToEdit);
    const [propertyToDelete, setPropertyToDelete] = useState<TypeElementPropertyAPI | null>(null);
    const [breadcrumbs, setBreadcrumbs] = useReducer(createBreadcrumbReducer<TypeScreen>(), {
        trail: [
            {
                id: TYPE_SCREENS.typeList,
                content: 'Types',
                onClick: () => returnToTypeList(),
            },
        ],
        activeItemId: TYPE_SCREENS.typeList,
    });

    const [paging, updatePaging] = useState<
        Paging & { search: string; orderBy: string; orderDirection: OrderDirection }
    >({
        page: 1,
        pageSize: 20,
        total: 0,
        search: '',
        orderBy: 'dateModified',
        orderDirection: 'DESC',
    });

    const [propertyPaging, updatePropertyPaging] = useState({
        page: 1,
        pageSize: 10,
        total: 0,
        search: '',
    });

    const fetchTypes = useCallback(
        async (filters: Filter) => {
            setTypesLoading(true);

            try {
                const typesResults = await getTypeListV2(filters);
                const { items, _meta } = typesResults;

                setTypes(items);

                const { search, orderBy, orderDirection } = filters;

                updatePaging((previousPaging) => ({
                    ..._meta,
                    search: search ?? previousPaging.search,
                    orderBy: orderBy ?? previousPaging.orderBy,
                    orderDirection: orderDirection ?? previousPaging.orderDirection,
                }));
            } catch (error) {
                notifyError(error);
            } finally {
                setTypesLoading(false);
            }
        },
        [notifyError],
    );

    const saveType = async () => {
        try {
            await saveTypeAPI(typeToEdit);
            notifySuccess(`The type: ${typeToEdit.developerName} has been successfully saved.`);
            returnToTypeList();
        } catch (error) {
            notifyError(error);
        }
    };

    const deleteType = async () => {
        const { id, developerName } = typeToDelete ?? {};

        if (!(id && developerName)) {
            notifyError('No type selected to delete');
            return;
        }

        try {
            await deleteTypeAPI(id);

            const remainingTypes = types.filter((type) => type.id !== id);

            setTypes(remainingTypes);
            returnToTypeList();
            notifySuccess(`Type: ${developerName} has been successfully deleted.`);
        } catch (error) {
            notifyError(error);
        }
    };

    const createNewType = () => {
        setCurrentScreen(TYPE_SCREENS.typeDetail);
        setTypeToEdit(initialTypeToEdit);
        pushBreadCrumb(TYPE_SCREENS.typeDetail, 'New Type');
    };

    const importProfile = () => {
        setCurrentScreen(TYPE_SCREENS.importProfile);
    };

    const pushBreadCrumb = (id: TypeScreen, content: string) => {
        setBreadcrumbs({
            type: BREADCRUMB_ACTION_TYPES.push_breadcrumb,
            payload: {
                id,
                content,
                onClick: () => setActiveBreadCrumb(id),
            },
        });
    };

    const setActiveBreadCrumb = (activeItemId: TypeScreen) => {
        setBreadcrumbs({
            type: BREADCRUMB_ACTION_TYPES.set_active_breadcrumb,
            payload: {
                activeItemId,
            },
        });
        setCurrentScreen(activeItemId);
    };

    const editType = (type: TypeElementRequestAPI) => {
        setCurrentScreen(TYPE_SCREENS.typeDetail);
        setTypeToEdit(type);
        pushBreadCrumb(TYPE_SCREENS.typeDetail, type?.developerName ?? '');
    };

    const editProperty = (property: TypeElementPropertyAPI) => {
        setCurrentScreen(TYPE_SCREENS.typePropertyDetail);
        setPropertyToEdit(property);
        pushBreadCrumb(TYPE_SCREENS.typePropertyDetail, property.developerName);
    };

    const removeProperty = () => {
        setTypeToEdit({
            ...typeToEdit,
            properties: typeToEdit.properties.filter(
                (property) => property.id !== propertyToDelete?.id,
            ),
        });

        returnToType();
    };

    const returnToTypeList = () => {
        setCurrentScreen(TYPE_SCREENS.typeList);
        setActiveBreadCrumb(TYPE_SCREENS.typeList);
        updatePropertyPaging({
            page: 1,
            pageSize: 10,
            total: 0,
            search: '',
        });
    };

    const returnToType = () => {
        setCurrentScreen(TYPE_SCREENS.typeDetail);
        setActiveBreadCrumb(TYPE_SCREENS.typeDetail);
    };

    const createNewTypeProperty = () => {
        setCurrentScreen(TYPE_SCREENS.typePropertyDetail);

        const newProperty = {
            id: guid(),
            developerName: '',
            contentType: null,
            contentFormat: null,
            typeElementId: null,
            typeElementDeveloperName: null,
        };

        setPropertyToEdit(newProperty);
        pushBreadCrumb(TYPE_SCREENS.typePropertyDetail, 'New Property');
    };

    const updateTypeName = (developerName: string) => {
        setTypeToEdit({ ...typeToEdit, developerName });
    };

    const updateTypeComments = (developerSummary: string) => {
        setTypeToEdit({ ...typeToEdit, developerSummary });
    };

    const applyProperty = (property: TypeElementPropertyAPI) => {
        const propertyExists = typeToEdit.properties.find((p) => p.id === property.id);
        const properties = propertyExists
            ? typeToEdit.properties.map((existingProperty) =>
                  existingProperty.id === property.id ? property : existingProperty,
              )
            : [...typeToEdit.properties, property];

        setTypeToEdit({ ...typeToEdit, properties });
        returnToType();
    };

    const contextValue = {
        TYPE_SCREENS,
        fetchTypes,
        saveType,
        deleteType,
        types,
        typesLoading,
        currentScreen,
        createNewType,
        typeToEdit,
        updateTypeName,
        updateTypeComments,
        setTypeToEdit,
        createNewTypeProperty,
        editType,
        propertyToEdit,
        editProperty,
        breadcrumbs,
        pushBreadCrumb,
        returnToType,
        returnToTypeList,
        applyProperty,
        paging,
        propertyPaging,
        updatePropertyPaging,
        setTypeToDelete,
        typeToDelete,
        propertyToDelete,
        setPropertyToDelete,
        removeProperty,
        tenantId,
        notifyError,
        notifySuccess,
        importProfile,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useTypes = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useTypes must be used within a TypesProvider');
    }
    return context;
};

export { TypesProvider, useTypes };

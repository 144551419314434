import { useNavigate } from 'react-router-dom';
import { clone } from 'ramda';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/flow/properties.less';
import {
    changeFlowName,
    registerFlowConfiguration,
    unregisterFlowConfiguration,
} from '../../actions/reduxActions/flowConfigurations';
import { addNotification as addNotificationAction } from '../../actions/reduxActions/notification';
import { closeTab, updateTab } from '../../actions/reduxActions/tabs';
import FormGroup from '../../../ts/components/generic/FormGroup';
import { TAB_TYPES } from '../../../ts/constants';
import { setLatest } from '../../../ts/sources/flow';
import translations from '../../../ts/translations';
import { generateRouteUrl } from '../../../ts/utils/routing';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../ts/components/buttons/ButtonPrimary';
import Modal from '../../../ts/components/generic/modal/GenericModal';
import { fetchAllFlows } from '../../actions/reduxActions/flows';

const NewFlow = ({
    tenantId,
    flowId,
    flowData,
    addNotification,
    registerFlowConfiguration,
    unregisterFlowConfiguration,
    changeFlowName,
    container,
    closeTab,
    tabs,
    updateTab,
    fetchFlows,
}) => {
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const navigate = useNavigate();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        registerFlowConfiguration({
            id: {
                id: flowId,
            },
            developerName: '',
            developerSummary: '',
            startMapElementId: null,
            allowJumping: false,
            enableHistoricalNavigation: false,
            stateExpirationLength: 0,
            idleStateExpirationLength: 0,
            authorization: {
                serviceElementId: null,
                globalAuthenticationType: 'PUBLIC',
                streamBehaviourType: 'NONE',
                showPagesAsReadOnly: false,
                groups: [],
                users: [],
                locations: [],
            },
        });
    }, []);

    const onSave = async () => {
        setHasSubmitted(true);

        if (isFormValid) {
            const newFlowData = clone(flowData);

            // Set flow ID back to null so it is recognised as new by the API
            newFlowData.id = null;

            try {
                const response = await setLatest(newFlowData, tenantId);

                createFlowDone(response);
            } catch (error) {
                // Catch error and notify
                addNotification({
                    type: 'error',
                    message: error.message,
                    isPersistent: true,
                });
            }
        }
    };

    const createFlowDone = async (response) => {
        const tab = tabs.find((tab) => tab.elementId === flowId);
        updateTab({
            ...tab,
            title: response.developerName,
            tabType: TAB_TYPES.flow,
        });
        const route = generateRouteUrl({
            tabType: TAB_TYPES.flow,
            tenantId: tenantId,
            options: {
                elementId: response.id.id,
                tabKey: tab?.key,
                isNewFlowRedirect: true,
            },
        });

        unregisterFlowConfiguration(flowData.id.id);
        fetchFlows(tenantId);
        navigate(route);
    };

    const onCancel = () => {
        const tab = tabs.find((tab) => tab.elementId === flowId);
        unregisterFlowConfiguration(flowData.id.id);
        closeTab(tab.key, tenantId, navigate);
    };

    const isFormValid = isNullOrEmpty(flowData) ? false : flowData.developerName !== '';

    const renderModalContent = () => {
        return (
            <>
                <FormGroup
                    label={'Name'}
                    isRequired
                    isValid={isFormValid}
                    showValidation={hasSubmitted}
                    validationMessage={'This field is required.'}
                    htmlFor="new-flow-name-field"
                >
                    <input
                        id="new-flow-name-field"
                        maxLength={150}
                        className="form-control form-control-dynamic name"
                        value={flowData?.developerName ?? ''}
                        onChange={({ target }) => {
                            changeFlowName({
                                flowId: flowData.id.id,
                                flowName: target.value,
                            });
                        }}
                        required
                        autoFocus
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                onSave();
                            }
                        }}
                    />
                </FormGroup>
            </>
        );
    };

    const renderFooter = () => {
        return (
            <>
                <ButtonDefault className="new-flow-cancel" onClick={onCancel}>
                    {translations.GRAPH_config_panel_cancel}
                </ButtonDefault>
                <ButtonPrimary className="new-flow-save" onClick={onSave}>
                    {translations.GRAPH_config_panel_save}
                </ButtonPrimary>
            </>
        );
    };

    return (
        <Modal
            className="config-modal"
            title={translations.FLOW_CREATE_NEW}
            renderBody={renderModalContent}
            renderFooter={renderFooter}
            container={container}
            bodyClassName="auto-height"
            onHide={onCancel}
        />
    );
};
// Take in Redux state and save as local props
const mapStateToProps = (state, ownProps) => ({
    flowData: state.flowConfigurations.find((flow) => flow.id.id === ownProps.flowId),
    tabs: state.tabs,
});

const mapDispatchToProps = {
    registerFlowConfiguration,
    unregisterFlowConfiguration,
    changeFlowName,
    addNotification: addNotificationAction,
    closeTab,
    updateTab,
    fetchFlows: fetchAllFlows,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewFlow);

import translations from '../../translations';

type AddPropertyButtonProps = {
    createProperty: () => void;
    isValid: boolean;
    showValidation: boolean;
};

const AddPropertyButton = ({ createProperty, isValid, showValidation }: AddPropertyButtonProps) => {
    return (
        <div className="flex-child-right">
            <button
                className="btn btn-success btn-sm"
                onClick={createProperty}
                title={translations.TYPES_new_property_button_label}
                type="button"
            >
                <span className="glyphicon glyphicon-plus" />
                {translations.TYPES_new_property_button_label}
            </button>
            {!isValid && showValidation && (
                <span className="help-block error-state">
                    {translations.TYPES_properties_required_error_message}
                </span>
            )}
        </div>
    );
};

export default AddPropertyButton;

import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';
import { MapElementProvider } from '../contextProviders/MapElementProvider';
import SubFlowRouter from './SubFlowRouter';

/**
 * Handles switching between the subflow configuration screens
 */
const SubFlow = (props) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={'subflow'}
            elementType={MAP_ELEMENT_TYPES.subflow}
        >
            <MapElementModal>
                <SubFlowRouter />
            </MapElementModal>
        </MapElementProvider>
    );
};

export default SubFlow;

import ProgressBar from './ProgressBar';
import { getProgressBytesDisplay, getProgressPercentageDisplay } from '../../utils/file';

interface UploadProgressBarProps {
    progressPercentage: number;
    progressBytes: number;
    totalBytes: number;
}

const UploadProgressBar = ({
    progressPercentage,
    progressBytes,
    totalBytes,
}: UploadProgressBarProps) => (
    <>
        <ProgressBar key="progress" progress={progressPercentage} />
        <div className="flex">
            <span>{getProgressBytesDisplay(totalBytes, progressBytes)}</span>
            <span className="flex-child-right">
                {getProgressPercentageDisplay(progressPercentage)}
            </span>
        </div>
    </>
);

export default UploadProgressBar;

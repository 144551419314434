import ButtonPrimary from '../../../buttons/ButtonPrimary';
import Glyphicon from '../../../generic/Glyphicon';
import Table from '../../../generic/Table';
import translations from '../../../../translations';
import type { ProcessProperties } from '../../../../types/integration';
import { useProcess } from '../contextProviders/ProcessProvider';
import { useEffect, useState } from 'react';
import { getAllProcessPropertiesNames } from '../../../../sources/integration';
import { Trash } from '@phosphor-icons/react';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import { stringReplace } from '../../../../utils/string';

const ProcessPropertiesTable = () => {
    const {
        processToEdit,
        onRemoveProcessProperties,
        onAddProcessProperties,
        onEditProcessProperties,
    } = useProcess();

    const [processPropertiesNames, setProcessPropertiesNames] = useState<Record<string, string>>(
        {},
    );

    const [loadingProcessPropertiesNames, setLoadingProcessPropertiesNames] = useState(true);
    const [error, setError] = useState<string | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            setLoadingProcessPropertiesNames(true);

            try {
                const result = await getAllProcessPropertiesNames(
                    processToEdit.process.accountId,
                    processToEdit.process.processProperties.map((processProperties) => ({
                        accountId: processToEdit.process.accountId,
                        id: processProperties.componentId,
                    })),
                );

                if (result) {
                    setProcessPropertiesNames(
                        result.reduce(
                            (previousValue, currentValue) =>
                                Object.assign(previousValue, {
                                    [currentValue.id]: currentValue.name,
                                }),
                            {},
                        ),
                    );
                }
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setLoadingProcessPropertiesNames(false);
            }
        };

        if (processToEdit.process?.processId) {
            load();
        }
    }, []);

    const columns = [
        {
            renderHeader: () => translations.PROCESS_process_property_column_name,
            renderCell: ({ item }: { item: ProcessProperties }) => {
                let name = processPropertiesNames[item.componentId]
                    ? processPropertiesNames[item.componentId]
                    : stringReplace(
                          translations.PROCESS_process_property_name_not_found,
                          item.componentId,
                      );

                if (loadingProcessPropertiesNames) {
                    name = translations.PROCESS_process_property_name_loading;
                }

                return (
                    <button
                        className="link-emulate overflow-ellipsis"
                        onClick={() => onEditProcessProperties(item.componentId)}
                        type="button"
                    >
                        {name}
                    </button>
                );
            },
        },
        {
            renderHeader: () => 'Actions',
            renderCell: ({ item }: { item: ProcessProperties }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`${translations.COMMON_delete} ${
                            processPropertiesNames[item.componentId] || ''
                        }`}
                        className="table-icon table-icon-delete"
                        onClick={() => onRemoveProcessProperties(item.componentId)}
                        aria-label={`${translations.COMMON_delete} ${
                            processPropertiesNames[item.componentId] || ''
                        }`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <h4>{translations.PROCESS_process_properties_heading}</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary
                    onClick={onAddProcessProperties}
                    disabled={!processToEdit.process.accountId}
                >
                    <Glyphicon glyph="plus" />
                    {translations.PROCESS_process_property_add}
                </ButtonPrimary>
            </div>
            <Table<ProcessProperties>
                columns={columns}
                tableClass="generic-table margin-top margin-bottom"
                items={processToEdit.process.processProperties}
                testId="process-properties"
            />
            {error ? (
                <ExAlertBanner open={true} type={AlertBannerType.ERROR}>
                    {stringReplace(translations.PROCESS_process_properties_error, error)}
                </ExAlertBanner>
            ) : null}
        </>
    );
};

export default ProcessPropertiesTable;

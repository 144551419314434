import type { ReactElement } from 'react';

// Can't enforce the shape of children, so it's possible children may not have this prop
type Screen = ReactElement<{
    screen: string | undefined;
}>;

interface ScreenRouterProps {
    currentScreen: string;
    children: Screen | Screen[];
}

/**
 * @returns A React component with a prop named "screen" that matches currentScreen
 */
const ScreenRouter = ({ currentScreen, children }: ScreenRouterProps) => {
    const screen = Array.isArray(children)
        ? children.find((child) => child.props.screen === currentScreen)
        : children.props.screen === currentScreen
          ? children
          : null;

    if (!screen) {
        throw new Error(
            `A child component routing to the screen named "${currentScreen}" could not be found`,
        );
    }

    return screen;
};

export default ScreenRouter;

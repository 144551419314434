import classNames from 'classnames';
import { clone, last } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/graph/outcome.less';
import { setDraggingData as setDraggingDataAction } from '../../actions/reduxActions/graphEditor';
import { GRAPH_ELEMENT_TYPES, MAP_ELEMENT_CONFIGS, MAP_ELEMENT_TYPES } from '../../../ts/constants';
import { getByID } from '../../../ts/utils/collection';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import { safeToLower } from '../../../ts/utils/string';
import { COLOUR_DANGER, COLOUR_MAPPINGS } from './elements/elementStyles';
import { useGraph } from './GraphProvider';
import {
    drawControlPointLoop,
    drawControlPointStraightLines,
    drawCShapePath,
    drawNoControlPointLoop,
    drawNoControlPointPath,
    drawRemainingElbowBends,
    drawZShapePath,
    getOffset,
    OffsetSide,
} from '../../../ts/components/graph/outcomeUtils';
import {
    calculateIfPointIsWithinBounds,
    executeIfNotDragging,
    filterOutNotes,
    getElementDimensions,
    getParentOffset,
    getParents,
} from './utils';
import OutcomeTrafficPercentage from '../../../ts/components/flow/insights/OutcomeTrafficPercentage';

const Outcome = (props) => {
    const {
        setSelectedElementIds,
        setSelectedOutcome,
        selectedOutcomeId,
        selectedOutcomeMapElementId,
        hoveringOutcomeId,
        hoveringOutcomeMapElementId,
        setHoveringOutcome,
        mapElements,
        groupElements,
        highlightedElementIds,
        setHighlightedElementIds,
        outcomeTrafficRatios,
    } = useGraph();
    const {
        outcome,
        openConfig,
        flowId,
        isDragging,
        searchQueries,
        setDraggingData,
        dragging,
        hoveringMapElementId,
        originalMapElementId, // this id does not get overwritten when hovering over a map element while dragging the source
    } = props;

    let { mapElement } = props;

    // When the user is dragging to make a new outcome we add a 'next' property on the outcome
    // so if this exists then we are one of these dragging outcomes
    // If we don't have this then we are a saved outcome from the api
    const isDraggingEnd = !isNullOrEmpty(outcome.next);

    const draggingElement = document.querySelector(`[id='graph-svg-${flowId}'] [id='dragElement']`);
    let draggingElementAttribute = {};
    let draggingElementXY = [0, 0];
    if (draggingElement) {
        draggingElementAttribute = draggingElement.getAttribute('transform');
        draggingElementXY = draggingElementAttribute
            .substring(7, draggingElementAttribute.length - 1)
            .split(',');
    }

    let nextMapElement = isDraggingEnd
        ? // These fake outcomes will use the element the user is hovering over (if any)
          getByID(hoveringMapElementId, filterOutNotes(mapElements))
        : outcome.nextMapElementId === 'pending'
          ? {
                id: 'test',
                elementType: MAP_ELEMENT_TYPES.step,
                x: Number.parseInt(draggingElementXY[draggingElementXY.length - 2]),
                y: Number.parseInt(draggingElementXY[draggingElementXY.length - 1]),
            }
          : getByID(outcome.nextMapElementId, mapElements);

    // is the user dragging the start? (from)
    // or has dropped the start with the config open? (fromMapElementId)
    const isDraggingStart = !isNullOrEmpty(outcome.fromMapElementId || outcome.from);
    mapElement = isDraggingStart
        ? getByID(
              // outcome start snaps to the hovering map element
              // or a temporary fromMapElementId which is set when the start is dropped while the config opens
              hoveringMapElementId ?? outcome.fromMapElementId,
              filterOutNotes(mapElements),
          ) ?? outcome.from // falls back to pointer location when dragging the start
        : mapElement;

    const { x: fromParentOffsetX, y: fromParentOffsetY } = mapElement
        ? getParentOffset(getParents(mapElement, groupElements))
        : getOffset(0, 0, OffsetSide.None);

    const { x: nextParentOffsetX, y: nextParentOffsetY } = nextMapElement
        ? getParentOffset(getParents(nextMapElement, groupElements))
        : getOffset(0, 0, OffsetSide.None);

    nextMapElement = nextMapElement || outcome.next;

    const offsetFromMapElement = {
        x: mapElement.x + fromParentOffsetX,
        y: mapElement.y + fromParentOffsetY,
    };
    const offsetNextMapElement = {
        x: nextMapElement?.x + nextParentOffsetX,
        y: nextMapElement?.y + nextParentOffsetY,
    };

    let d = null;
    const pathRef = useRef();
    const [pathMiddle, setPathMiddle] = useState(null);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (pathRef?.current?.getTotalLength) {
            setPathMiddle(
                pathRef.current
                    ? pathRef.current.getPointAtLength(pathRef.current.getTotalLength() / 2)
                    : null,
            );
        }
        // Force a reload if the outcome or connected map elements move
    }, [
        pathRef?.current,
        offsetFromMapElement?.x,
        offsetFromMapElement?.y,
        offsetNextMapElement?.x,
        offsetNextMapElement?.y,
        outcome?.controlPoints?.[0]?.x,
        outcome?.controlPoints?.[0]?.y,
    ]);

    if (
        // Do not render if the nextMapElement or mapElement does not exist
        (nextMapElement?.groupElementId &&
            groupElements.find((group) => group.id === nextMapElement.groupElementId) ===
                undefined) ||
        (mapElement.groupElementId &&
            groupElements.find((group) => group.id === mapElement.groupElementId) === undefined) ||
        (outcome.nextMapElementId === 'pending' && !draggingElement) ||
        // Some outcomes are allowed to be saved without destinations
        // for these we can not render anything and so render nothing
        !nextMapElement
    ) {
        // Do not render if the nextMapElement or mapElement does not exist
        return null;
    }

    // take group element size or fall back to styles for map elements
    const { width: fromWidth, height: fromHeight } = getElementDimensions(mapElement);

    const { width: nextWidth, height: nextHeight } = getElementDimensions(nextMapElement);

    const relativeControlPoint = clone(outcome.controlPoints ? outcome.controlPoints[0] : null);
    const controlPoint = clone(relativeControlPoint);
    let illegalControlPoint = false;
    let outsideLeft = null;
    let outsideRight = null;
    let outsideTop = null;
    let outsideBottom = null;

    if (controlPoint) {
        // control points in groups are relative
        // add parent offset to get control point absolute position
        controlPoint.x += fromParentOffsetX;
        controlPoint.y += fromParentOffsetY;
        // check if control point is in illegal position
        outsideLeft =
            controlPoint.x < offsetFromMapElement.x && controlPoint.x < offsetNextMapElement.x;
        outsideRight =
            controlPoint.x > offsetFromMapElement.x + fromWidth &&
            controlPoint.x > offsetNextMapElement.x + nextWidth;
        outsideTop =
            controlPoint.y < offsetFromMapElement.y && controlPoint.y < offsetNextMapElement.y;
        outsideBottom =
            controlPoint.y > offsetFromMapElement.y + fromHeight &&
            controlPoint.y > offsetNextMapElement.y + nextHeight;
        const fromBounds = {
            x: offsetFromMapElement.x,
            y: offsetFromMapElement.y,
            width: fromWidth,
            height: fromHeight,
        };
        const insideFromMapElement = calculateIfPointIsWithinBounds({
            point: controlPoint,
            bounds: fromBounds,
        });
        const nextBounds = {
            x: offsetNextMapElement.x,
            y: offsetNextMapElement.y,
            width: nextWidth,
            height: nextHeight,
        };
        const insideNextMapElement = calculateIfPointIsWithinBounds({
            point: controlPoint,
            bounds: nextBounds,
        });
        // Illegal control point positions:
        // ⌧        ⌧
        //  [⌧]───┐
        //       [⌧]
        // ⌧        ⌧
        //
        // [] - from and next map elements.
        // ⌧ - illegal control point positions.
        if (
            ((outsideLeft || outsideRight) && (outsideTop || outsideBottom)) ||
            insideFromMapElement ||
            insideNextMapElement
        ) {
            illegalControlPoint = true;
        }
    }
    if (isDragging && dragging && dragging.illegalControlPoint !== illegalControlPoint) {
        setDraggingData({ ...dragging, illegalControlPoint });
    }

    const fromMapElementId = mapElement?.id;
    const nextMapElementId = nextMapElement?.id;
    let startSide = null;
    let startOffset = null;
    let endSide = null;
    let endOffset = null;
    let points = null;

    if (controlPoint && !illegalControlPoint) {
        // Example:
        // 0╌╌1
        //    2
        //    3╌╌4
        points = [
            {}, // 0 - start outside point
            {}, // 1 - start inside point
            {
                // 2 - control point
                x: controlPoint.x,
                y: controlPoint.y,
            },
            {}, // 3 - end inside point
            {}, // 4 - end outside point
        ];
        // which side of the map element the control point is
        const cSide = outsideLeft
            ? OffsetSide.Left
            : outsideRight
              ? OffsetSide.Right
              : outsideTop
                ? OffsetSide.Top
                : outsideBottom
                  ? OffsetSide.Bottom
                  : null;

        if (fromMapElementId === nextMapElementId) {
            // outcome is a loop
            startSide = endSide = cSide;
            ({ points } = drawControlPointLoop(
                points[2],
                offsetNextMapElement,
                nextWidth,
                nextHeight,
                cSide,
            ));
        } else {
            ({
                outsidePoint: points[0],
                insidePoint: points[1],
                side: startSide,
            } = drawControlPointStraightLines(
                points[2],
                offsetFromMapElement,
                fromWidth,
                fromHeight,
            ));
            ({
                outsidePoint: points[4],
                insidePoint: points[3],
                side: endSide,
            } = drawControlPointStraightLines(
                points[2],
                offsetNextMapElement,
                nextWidth,
                nextHeight,
            ));
        }

        // no points were defined when calculating straight lines
        // so this must be a double elbow C or Z
        if (isNullOrEmpty(points[0]) && isNullOrEmpty(points[3])) {
            if (isNullOrEmpty(cSide)) {
                // outcome is a Z shape
                ({ points, startSide, endSide } = drawZShapePath(
                    points,
                    offsetFromMapElement,
                    fromWidth,
                    fromHeight,
                    offsetNextMapElement,
                    nextWidth,
                    nextHeight,
                ));
            } else {
                // outcome is a C shape
                ({ points, cSide: startSide } = { cSide: endSide } = drawCShapePath(
                    points,
                    offsetFromMapElement,
                    fromWidth,
                    fromHeight,
                    offsetNextMapElement,
                    nextWidth,
                    nextHeight,
                    cSide,
                ));
            }
        } else {
            // fill in missing points after straight lines to/from the control point
            // these points are filled with elbow bends to/from the control point
            if (isNullOrEmpty(points[0]) && isNullOrEmpty(points[1])) {
                ({
                    outsidePoint: points[0],
                    insidePoint: points[1],
                    side: startSide,
                } = drawRemainingElbowBends(
                    points[2],
                    offsetFromMapElement,
                    offsetNextMapElement,
                    offsetFromMapElement,
                    fromWidth,
                    fromHeight,
                    nextHeight,
                ));
            }
            if (isNullOrEmpty(points[4]) && isNullOrEmpty(points[3])) {
                ({
                    outsidePoint: points[4],
                    insidePoint: points[3],
                    side: endSide,
                } = drawRemainingElbowBends(
                    points[2],
                    offsetFromMapElement,
                    offsetNextMapElement,
                    offsetNextMapElement,
                    nextWidth,
                    nextHeight,
                    nextHeight,
                ));
            }
        }
    } else if (fromMapElementId === nextMapElementId) {
        // outcome is a loop
        ({ points, side: endSide } = { side: startSide } = drawNoControlPointLoop(
            offsetFromMapElement,
            fromWidth,
            fromHeight,
        ));
    } else {
        // default Z shape outcome
        ({ points, startOffset, endOffset } = drawNoControlPointPath(
            offsetFromMapElement,
            fromWidth,
            fromHeight,
            offsetNextMapElement,
            nextWidth,
            nextHeight,
        ));
    }

    points = points.map((point) => (isNullOrEmpty(point) ? { x: 0, y: 0 } : point));
    // draw a connected path between the points
    // except the last point which is shortened to avoid the arrow head
    d = `M ${points[0].x} ${points[0].y}`;
    for (let i = 1; i < points.length - 1; i++) {
        d += `L ${points[i].x} ${points[i].y}`;
    }

    startOffset = startOffset || getOffset(0, 0, startSide);
    endOffset = endOffset || getOffset(0, 0, endSide);

    // Stop a little short of the map element so the border doesn't cover the arrow head point
    const endPoint = last(points);
    switch (endOffset.rotation) {
        case getOffset(0, 0, OffsetSide.Top).rotation: {
            d += `L ${endPoint.x} ${endPoint.y - 1}`;
            break;
        }
        case getOffset(0, 0, OffsetSide.Bottom).rotation: {
            d += `L ${endPoint.x} ${endPoint.y + 1}`;
            break;
        }
        case getOffset(0, 0, OffsetSide.Left).rotation: {
            d += `L ${endPoint.x - 1} ${endPoint.y}`;
            break;
        }
        case getOffset(0, 0, OffsetSide.Right).rotation: {
            d += `L ${endPoint.x + 1} ${endPoint.y}`;
            break;
        }
        default:
            d += `L ${endPoint.x} ${endPoint.y}`;
    }

    const openOutcomeConfig = () =>
        openConfig(MAP_ELEMENT_CONFIGS.outcome, {
            id: originalMapElementId,
            nextMapElementId: outcome.nextMapElementId,
            outcomeId: outcome.id,
        });

    const onSelect = (id = null, mapElementId = null) => {
        if (id !== null && mapElementId !== null) {
            setSelectedElementIds([]);
        }
        setSelectedOutcome(id, mapElementId);
    };

    const isOutOfSearch =
        highlightedElementIds.length > 0
            ? !highlightedElementIds.includes(outcome.id)
            : searchQueries[flowId] &&
              outcome.developerName &&
              !safeToLower(outcome.developerName).includes(searchQueries[flowId]);
    const classes = classNames({
        outcome: true,
        'out-of-search': isOutOfSearch,
        dragging: isDragging,
    });

    // must be relative to the parent group when used to save
    const relativePathMiddle = {
        x: pathMiddle?.x - fromParentOffsetX,
        y: pathMiddle?.y - fromParentOffsetY,
    };

    const usePathMiddleForControlPoint =
        // use pathMiddle if there isn't a control point
        !controlPoint ||
        // or for illegal control points (unless you are dragging)
        (illegalControlPoint && !(isDragging && dragging.hasMovedEnough));
    const controlPointPosition = usePathMiddleForControlPoint ? pathMiddle : controlPoint;
    const relativeControlPointPosition = usePathMiddleForControlPoint
        ? relativePathMiddle
        : relativeControlPoint;

    const highlightThisOutcome = () =>
        setHighlightedElementIds([outcome.id, fromMapElementId, nextMapElementId]);

    const outcomeTrafficRatio = outcomeTrafficRatios[outcome.id];

    const isSelected =
        selectedOutcomeId === outcome.id && selectedOutcomeMapElementId === originalMapElementId;
    const isHovered =
        hoveringOutcomeId === outcome.id && hoveringOutcomeMapElementId === originalMapElementId;

    return (
        <g
            data-testid={`outcome-${mapElement.developerName}-${outcome.id}`}
            className={classes}
            onDoubleClick={openOutcomeConfig}
            onMouseEnter={() => setHoveringOutcome(outcome.id, originalMapElementId)}
            onFocus={() => setHoveringOutcome(outcome.id, originalMapElementId)}
            onMouseLeave={() => setHoveringOutcome()}
            onMouseDown={(e) => (e.button === 0 && e.altKey ? highlightThisOutcome() : null)}
            onMouseUp={(e) =>
                executeIfNotDragging(e, dragging, () =>
                    e.ctrlKey && isSelected
                        ? onSelect()
                        : onSelect(outcome.id, originalMapElementId),
                )
            }
            // While dragging, ignore pointer events for the outcome as they block hovering over other elements
            pointerEvents={isDragging && dragging?.hasMovedEnough ? 'none' : ''}
        >
            {isSelected ? (
                <>
                    {/* rounded grey background line */}
                    <path
                        data-testid="outcome-selection-outline"
                        d={d}
                        strokeWidth="10"
                        stroke="#9c9c9c"
                        fill="none"
                        strokeLinejoin="round"
                    />
                    {/* striped light foreground line */}
                    <path
                        d={d}
                        strokeWidth="10.5"
                        stroke="white"
                        fill="none"
                        strokeLinejoin="bevel"
                        strokeDasharray="2"
                    />
                    {/* thinner light line to fill center */}
                    <path
                        d={d}
                        strokeWidth="8.5"
                        stroke="white"
                        fill="none"
                        strokeLinejoin="round"
                    />
                </>
            ) : (
                /* transparent line to make outcome bigger so it is easier to click */
                <path
                    d={d}
                    strokeWidth="10"
                    stroke="transparent"
                    fill="none"
                    strokeLinejoin="round"
                />
            )}
            <title>{outcome.developerName}</title>
            {/* light border to separate overlapping/crossing outcomes */}
            <path d={d} strokeWidth="5" stroke="white" fill="none" />
            {/* main outcome path */}
            <path
                data-testid="outcome-path"
                ref={pathRef}
                d={d}
                strokeWidth="1"
                stroke={
                    outcomeTrafficRatio?.hex ? outcomeTrafficRatio.hex : COLOUR_MAPPINGS.outcome
                }
                fill="none"
            />
            {/* text */}
            {pathMiddle && (
                <text
                    className="outcome-text"
                    x={pathMiddle.x}
                    y={pathMiddle.y}
                    fill={COLOUR_MAPPINGS.outcome}
                >
                    {outcome.developerName}
                </text>
            )}

            {/* ratio of runtime traffic */}
            {pathMiddle && (
                <OutcomeTrafficPercentage
                    x={pathMiddle.x}
                    y={pathMiddle.y}
                    value={outcomeTrafficRatio?.ratioPercentage}
                    isLoading={outcomeTrafficRatio?.isLoading}
                />
            )}

            {/* control point */}
            {(isSelected || isHovered || isDragging) &&
                controlPointPosition &&
                outcome.id !== 'new' && (
                    <rect
                        className="outcome-control-point"
                        data-testid="control-point"
                        x={controlPointPosition.x - 3}
                        y={controlPointPosition.y - 3}
                        width="6"
                        height="6"
                        fill={
                            illegalControlPoint && !usePathMiddleForControlPoint
                                ? COLOUR_DANGER
                                : COLOUR_MAPPINGS.outcome
                        }
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            setDraggingData({
                                dragType: GRAPH_ELEMENT_TYPES.controlPoint,
                                elementId: originalMapElementId,
                                outcomeId: outcome.id,
                                previousElementPosition: relativeControlPointPosition,
                                previousMousePosition: { x: e.clientX, y: e.clientY },
                                illegalControlPoint: illegalControlPoint,
                            });
                        }}
                    />
                )}
            <polygon
                data-testid="outcome-arrow"
                fill={COLOUR_MAPPINGS.outcome}
                transform={`
                translate(${endPoint.x} ${endPoint.y})
                rotate(${endOffset.rotation})
            `}
                // Together, (0,0), (3,6), (-3,6), back to (0,0)
                // draw an arrow pointing up
                //        (0,0)
                //        /  \
                //       /    \
                //      /      \
                // (-3,6)------(3,6)
                points="0,0 3,6 -3,6"
            />
            {/* Invisible rect used for outcome redirect dragging */}
            <polygon
                data-testid={`outcome-${mapElement.developerName}-redirect`}
                className="outcome-redirect"
                fillOpacity={0}
                transform={`
                translate(${endPoint.x} ${endPoint.y})
                rotate(${endOffset.rotation})
            `}
                // Together, (-5,0) (-5,10) (5,10) (5,0), back to (0,0)
                // draw a square that overlaps the outcome arrow
                // (-5,0)----(5,0)
                //   |         |
                //   |         |
                //   |         |
                // (-5,10)---(5,10)
                points="-5,0 -5,10 5,10 5,0"
                onMouseDown={(e) => {
                    e.stopPropagation();
                    setDraggingData({
                        dragType: GRAPH_ELEMENT_TYPES.outcomeEndPoint,
                        elementId: originalMapElementId,
                        outcomeId: outcome.id,
                        previousMousePosition: { x: e.clientX, y: e.clientY },
                        illegalControlPoint: illegalControlPoint,
                    });
                }}
            />
            {/* Circle shown for outcome source */}
            {(isSelected || (isHovered && !outcome.next) || isDragging) && (
                <circle
                    data-testid="outcome-circle"
                    cx={
                        points[0].x +
                        (startOffset.rotation === -90 ? 3 : startOffset.rotation === 90 ? -3 : 0)
                    }
                    cy={
                        points[0].y +
                        (startOffset.rotation === 0 ? 3 : startOffset.rotation === 180 ? -3 : 0)
                    }
                    r="3"
                    fill={COLOUR_MAPPINGS.outcome}
                />
            )}
            {/* Invisible rect used for outcome source redirect dragging */}
            <polygon
                className="outcome-redirect"
                data-testid={`outcome-circle-dragbox-${outcome.id}`}
                fillOpacity={0}
                transform={`
                translate(${points[0].x} ${points[0].y})
                rotate(${startOffset.rotation})
            `}
                // Together, (-5,0) (-5,10) (5,10) (5,0), back to (0,0)
                // draw a square that overlaps the outcome source circle
                // (-5,0)----(5,0)
                //   |         |
                //   |         |
                //   |         |
                // (-5,10)---(5,10)
                points="-5,0 -5,10 5,10 5,0"
                onMouseDown={(e) => {
                    e.stopPropagation();
                    setDraggingData({
                        dragType: GRAPH_ELEMENT_TYPES.outcomeStartPoint,
                        elementId: originalMapElementId,
                        outcomeId: outcome.id,
                        previousMousePosition: { x: e.clientX, y: e.clientY },
                        illegalControlPoint: illegalControlPoint,
                    });
                }}
            />
        </g>
    );
};

export const mapStateToProps = (
    { graphEditor: { searchQueries, dragging, hoveringMapElementId } },
    ownProps,
) => {
    return {
        searchQueries,
        isDragging:
            dragging &&
            dragging.outcomeId === ownProps.outcome.id &&
            dragging.elementId === ownProps.mapElement?.id,
        dragging,
        hoveringMapElementId,
        originalMapElementId: ownProps.mapElement?.id,
    };
};

export default connect(mapStateToProps, {
    setDraggingData: setDraggingDataAction,
})(Outcome);

import { User as UserIcon } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { TAB_TYPES } from '../../constants';
import translations from '../../translations';
import { generateRouteUrl } from '../../utils/routing';

interface UserSettingsButtonProps {
    tenantId: string;
}

const UserSettingsButton = ({ tenantId }: UserSettingsButtonProps) => {
    const navigate = useNavigate();

    const openAdminTab = () => {
        const route = generateRouteUrl({
            tabType: TAB_TYPES.userSettings,
            tenantId,
        });

        navigate(route);
    };

    const label = translations.HEADER_user_settings;

    return (
        <button
            className="btn-icon user-settings-btn"
            title={label}
            aria-label={label}
            onClick={() => openAdminTab()}
            type="button"
        >
            <UserIcon width="25" height="25" alt="" />
        </button>
    );
};

export default UserSettingsButton;

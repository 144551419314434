import type { AuthorizationEntity, ObjectAPI } from '../../types';

export interface GroupsUsersState {
    isLoading: boolean;
    groupsOrUsers: AuthorizationEntity[] | null;
    attributes: ObjectAPI[];
    showGroupsUsers: boolean;
}

export type GroupsUsersAction =
    | {
          type: 'setGroupsOrUsers';
          payload: { groupsOrUsers: AuthorizationEntity[] | null };
      }
    | { type: 'requestAttributes' }
    | {
          type: 'responseAttributes';
          payload: { attributes: ObjectAPI[]; hasError: boolean };
      }
    | { type: 'updateAttribute'; payload: { authenticationId: string; attributeValue: string } }
    | { type: 'deleteGroupOrUser'; payload: { authenticationId: string } }
    | {
          type: 'addGroupsOrUsers';
          payload: { connectorId: string; groupOrUserData: ObjectAPI[]; attribute: string };
      };

export const reducer = (state: GroupsUsersState, action: GroupsUsersAction): GroupsUsersState => {
    switch (action.type) {
        case 'setGroupsOrUsers':
            return {
                ...state,
                groupsOrUsers: action.payload.groupsOrUsers,
            };

        case 'requestAttributes': {
            return {
                ...state,
                isLoading: true,
            };
        }

        case 'responseAttributes': {
            return {
                ...state,
                isLoading: false,
                attributes: action.payload.attributes,
                showGroupsUsers: !action.payload.hasError,
            };
        }

        case 'deleteGroupOrUser': {
            return {
                ...state,
                groupsOrUsers: (state.groupsOrUsers ?? []).filter(
                    (groupOrUser) =>
                        groupOrUser.authenticationId !== action.payload.authenticationId,
                ),
            };
        }

        case 'updateAttribute': {
            return {
                ...state,
                groupsOrUsers: (state.groupsOrUsers ?? []).map((groupOrUser) => {
                    if (groupOrUser.authenticationId === action.payload.authenticationId) {
                        return {
                            ...groupOrUser,
                            attribute: action.payload.attributeValue,
                        };
                    }

                    return groupOrUser;
                }),
            };
        }

        case 'addGroupsOrUsers': {
            const mappedNewGroupsOrUsers = action.payload.groupOrUserData.reduce(
                (mapped, groupOrUserData) => {
                    const serviceElementId = action.payload.connectorId;
                    const attribute = action.payload.attribute;
                    const authenticationId = groupOrUserData.properties.find(
                        (prop) => prop.developerName === 'AuthenticationId',
                    )?.contentValue;
                    const friendlyName = groupOrUserData.properties.find(
                        (prop) => prop.developerName === 'FriendlyName',
                    )?.contentValue;
                    const developerSummary = groupOrUserData.properties.find(
                        (prop) => prop.developerName === 'DeveloperSummary',
                    )?.contentValue;

                    if (authenticationId && friendlyName && developerSummary) {
                        mapped.push({
                            attributeLabel: null,
                            attribute,
                            serviceElementId,
                            authenticationId,
                            friendlyName,
                            developerSummary,
                        });
                    }

                    return mapped;
                },
                [] as AuthorizationEntity[],
            );
            return {
                ...state,
                groupsOrUsers: [...(state.groupsOrUsers ?? []), ...mappedNewGroupsOrUsers],
            };
        }
    }
};

import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import { useEffect, useMemo, useState } from 'react';
import '../../../css/apiTool.less';
import '../../../css/metadata-editor.less';
import { GRAPH_ELEMENT_TYPES } from '../constants';
import {
    getGroupElement,
    getMapElement,
    saveGroupElement,
    saveMapElement,
    type GroupElementResponseAPI,
    type MapElementResponseAPI,
} from '../sources/graph';
import translations from '../translations';
import type { NotifyError, NotifySuccess } from '../types';
import ButtonDefault from './buttons/ButtonDefault';
import ButtonPrimary from './buttons/ButtonPrimary';
import CodeEditor from './generic/CodeEditor';
import Modal from './generic/modal/GenericModal';
import Loader from './loader/Loader';
import { stringReplace } from '../utils';

type MetadataEditorProps = {
    id: string;
    flowId: string;
    isVisible: boolean;
    elementType: string | null | undefined;
    container: HTMLElement | null | undefined;
    toggleMetadata: () => void;
    notifySuccess: NotifySuccess;
    notifyError: NotifyError;
};

const MetadataEditor = ({
    id,
    flowId,
    isVisible,
    elementType,
    container,
    toggleMetadata,
    notifySuccess,
    notifyError,
}: MetadataEditorProps) => {
    const [metadata, setMetadata] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const elementTypeLower = useMemo(() => elementType?.toLowerCase(), [elementType]);

    useEffect(() => {
        const loadMetadata = async () => {
            try {
                setIsLoading(true);

                let response: MapElementResponseAPI | GroupElementResponseAPI;

                switch (elementTypeLower) {
                    case GRAPH_ELEMENT_TYPES.map: {
                        response = await getMapElement(id, flowId);
                        break;
                    }
                    case GRAPH_ELEMENT_TYPES.group: {
                        response = await getGroupElement(id, flowId);
                        break;
                    }
                    default: {
                        notifyError(
                            stringReplace(
                                translations.GRAPH_metadata_editor_unsupported_element_type_error_message,
                                { type: elementTypeLower },
                            ),
                        );
                        return;
                    }
                }

                setMetadata(JSON.stringify(response, null, 4));
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (!isVisible) {
            return;
        }

        loadMetadata();
    }, [flowId, id, isVisible, notifyError, elementTypeLower]);

    const handleChange = (value: string) => {
        setMetadata(value);
    };

    const handleHide = () => {
        toggleMetadata();
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);

            const json = JSON.parse(metadata);

            switch (elementTypeLower) {
                case GRAPH_ELEMENT_TYPES.map: {
                    await saveMapElement(json, flowId);
                    break;
                }
                case GRAPH_ELEMENT_TYPES.group: {
                    await saveGroupElement(json, flowId);
                    break;
                }
                default: {
                    notifyError(
                        stringReplace(
                            translations.GRAPH_metadata_editor_unsupported_element_type_error_message,
                            { type: elementTypeLower },
                        ),
                    );
                    return;
                }
            }

            notifySuccess(translations.GRAPH_metadata_editor_save_success_message);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            show={isVisible}
            onHide={handleHide}
            container={container}
            className="metadata-editor config-modal"
            title={translations.GRAPH_metadata_editor_title}
            renderBody={() => (
                <>
                    <CodeEditor
                        value={metadata}
                        name={`metadata-editor-${flowId}`}
                        onChange={handleChange}
                    />
                    <ExAlertBanner type={AlertBannerType.WARNING} open>
                        {translations.GRAPH_metadata_editor_saving_metadata_alert_text}
                    </ExAlertBanner>
                    {isLoading && <Loader />}
                </>
            )}
            renderFooter={() => (
                <>
                    <ButtonDefault onClick={handleHide}>{translations.COMMON_close}</ButtonDefault>
                    <ButtonPrimary onClick={handleSave}>{translations.COMMON_save}</ButtonPrimary>
                </>
            )}
        />
    );
};

export default MetadataEditor;

import { useState } from 'react';
import { WAIT_BOUNDARIES, WAIT_TYPES } from './constants';
import { isNullOrEmpty } from '../../../../../utils/guard';
import DateTimeInput from '../../../../generic/DateTimeInput';
import FormGroup from '../../../../generic/FormGroup';
import { useMapElement } from '../../../../../../js/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import {
    type OnAbsoluteChange,
    type OnDaysChange,
    type OnHeadlessChange,
    type OnHoursChange,
    type OnMinutesChange,
    type OnOutcomeChange,
    type OnRelativeChange,
    type OnSecondsChange,
    type OnTimezoneChange,
    type OnUpdateName,
    type OnValueChanged,
    useWait,
} from '../../contextProviders/WaitProvider';
import NameInput from '../../../../../../js/components/flow/elementConfigurations/common/NameInput';
import type { MapElementProvider } from '../../../../../types/graph';
import Toggle from '../../../../inputs/Toggle';
import translations from '../../../../../translations';
import ValueSelectorModal from '../../../../values/selector/ValueSelectorModal';

const timezones = [
    'UTC+0',
    'UTC+1',
    'UTC+2',
    'UTC+3',
    'UTC+4',
    'UTC+5',
    'UTC+6',
    'UTC+7',
    'UTC+8',
    'UTC+9',
    'UTC+10',
    'UTC+11',
    'UTC+12',
    'UTC+13',
    'UTC+14',
    'UTC-1',
    'UTC-2',
    'UTC-3',
    'UTC-4',
    'UTC-5',
    'UTC-6',
    'UTC-7',
    'UTC-8',
    'UTC-9',
    'UTC-10',
    'UTC-11',
    'UTC-12',
];

const waitMinimum = `${WAIT_BOUNDARIES.min} seconds`;
const waitMaximum = `${WAIT_BOUNDARIES.max} days`;
const altWaitMinimum = `${WAIT_BOUNDARIES.altMin} minute`;

interface Props {
    onTimezoneChange: OnTimezoneChange;
    onWaitTypeChange: () => void;
    onDaysChange: OnDaysChange;
    onHoursChange: OnHoursChange;
    onMinutesChange: OnMinutesChange;
    onSecondsChange: OnSecondsChange;
    onRelativeChange: OnRelativeChange;
    onAbsoluteChange: OnAbsoluteChange;
    onValueChanged: OnValueChanged;
    onUpdateName: OnUpdateName;
    onOutcomeChange: OnOutcomeChange;
    onHeadlessChange: OnHeadlessChange;
    isMapElement: boolean;
}

const WaitInputs = ({
    onTimezoneChange,
    onWaitTypeChange,
    onDaysChange,
    onHoursChange,
    onMinutesChange,
    onSecondsChange,
    onRelativeChange,
    onAbsoluteChange,
    onValueChanged,
    onUpdateName,
    onOutcomeChange,
    onHeadlessChange,
    isMapElement,
}: Props) => {
    const { mapElement, container }: MapElementProvider = useMapElement();
    const {
        waitToEdit,
        hasSubmitted,
        setHasSubmitted,
        isNameValid,
        isMaximumValid,
        isMinimumValid,
        isValidHeadlessWait,
        noNegative,
        allPopulated,
        isValidRelativeTime,
        isValidAbsoluteTime,
        isAbsolutePopulated,
        isValidValue,
        isValidValueContentType,
        isValidPageWait,
    } = useWait();
    const wait = isMapElement ? mapElement : waitToEdit;
    const developerName = isMapElement ? mapElement.developerName : waitToEdit?.wait?.developerName;

    const [waitType, setWaitType] = useState<string>(
        wait?.testProps?.waitType
            ? wait?.testProps?.waitType
            : isNullOrEmpty(wait.wait?.relative)
              ? isNullOrEmpty(wait.wait?.absoluteDate)
                  ? isNullOrEmpty(wait.wait?.value)
                      ? WAIT_TYPES.relativeTime
                      : WAIT_TYPES.value
                  : WAIT_TYPES.absoluteTime
              : WAIT_TYPES.relativePhrase,
    );
    const getMinimumMessage = (helptext = false) => {
        let waitMin = waitMinimum;
        if (!(isMapElement && isValidHeadlessWait())) {
            waitMin = altWaitMinimum;
        }

        if (helptext) {
            return `${translations.WAIT_range_help} ${waitMin} ${translations.AND} ${waitMaximum}.`;
        }
        return `${translations.WAIT_min_help} ${waitMin}.`;
    };

    const maxWaitError = (
        <span className="help-block error-state">
            {`${translations.WAIT_max_help} ${waitMaximum}.`}
        </span>
    );

    const renderTimezoneSelect = () => {
        const offset = (new Date().getTimezoneOffset() * -1) / 60;
        let utcString = '';
        if (offset < 0) {
            utcString = `UTC${offset}`;
        } else {
            utcString = `UTC+${offset}`;
        }
        return (
            <FormGroup label="Timezone" htmlFor="timezone-select">
                <>
                    <select
                        id="timezone-select"
                        value={wait.wait?.timezone ?? 'UTC+0'}
                        onChange={onTimezoneChange}
                        placeholder="Select Timezone"
                        required
                        className="form-control form-control-width"
                    >
                        {timezones.map((timezone) => (
                            <option key={timezone} value={timezone}>
                                {timezone}
                            </option>
                        ))}
                    </select>
                    <span className="help-block">
                        {translations.WAIT_default_timezone}
                        <br />
                        {`${translations.WAIT_your_timezone} ${utcString}.`}
                    </span>
                </>
            </FormGroup>
        );
    };

    return (
        <>
            <NameInput
                isValid={isNameValid()}
                showValidation={hasSubmitted}
                id="wait-name"
                name={developerName}
                onUpdateName={onUpdateName}
            />
            <div className="form-group">
                <label>
                    <Toggle
                        isOn={wait.wait?.headless ?? false}
                        onChange={onHeadlessChange}
                        testId="is-headless"
                    />
                    {translations.WAIT_is_headless}
                </label>
            </div>
            <FormGroup
                isRequired
                validationMessage={translations.WAIT_type_required}
                isValid={!isNullOrEmpty(waitType)}
                showValidation={hasSubmitted}
                label="Wait Type"
                htmlFor="wait-type"
            >
                <select
                    id="wait-type"
                    value={waitType}
                    onChange={({ target: { value } }) => {
                        setWaitType(value);
                        setHasSubmitted(false);
                        onWaitTypeChange();
                    }}
                    placeholder={translations.WAIT_type_select}
                    required
                    className="form-control form-control-width"
                >
                    <option value={WAIT_TYPES.relativeTime}>{WAIT_TYPES.relativeTime}</option>
                    <option value={WAIT_TYPES.relativePhrase}>{WAIT_TYPES.relativePhrase}</option>
                    <option value={WAIT_TYPES.absoluteTime}>{WAIT_TYPES.absoluteTime}</option>
                    <option value={WAIT_TYPES.value}>{WAIT_TYPES.value}</option>
                </select>
            </FormGroup>
            {waitType === WAIT_TYPES.relativeTime && (
                <>
                    <FormGroup
                        label={translations.WAIT_days}
                        isRequired
                        isValid={
                            isMaximumValid() && isMinimumValid() && noNegative() && allPopulated()
                        }
                        showValidation={hasSubmitted}
                        htmlFor="wait-days"
                    >
                        <input
                            id="wait-days"
                            value={wait.wait?.days ?? 0}
                            onChange={onDaysChange}
                            required
                            className="form-control form-control-width"
                            type="number"
                        />
                    </FormGroup>
                    <FormGroup
                        label={translations.WAIT_hours}
                        isRequired
                        isValid={
                            isMaximumValid() && isMinimumValid() && noNegative() && allPopulated()
                        }
                        showValidation={hasSubmitted}
                        htmlFor="wait-hours"
                    >
                        <input
                            id="wait-hours"
                            value={wait.wait?.hours ?? 0}
                            onChange={onHoursChange}
                            required
                            className="form-control form-control-width"
                            type="number"
                        />
                    </FormGroup>
                    <FormGroup
                        label={translations.WAIT_minutes}
                        isRequired
                        isValid={
                            isMaximumValid() && isMinimumValid() && noNegative() && allPopulated()
                        }
                        showValidation={hasSubmitted}
                        htmlFor="wait-minutes"
                    >
                        <input
                            id="wait-minutes"
                            value={wait.wait?.minutes ?? 0}
                            onChange={onMinutesChange}
                            required
                            className="form-control form-control-width"
                            type="number"
                        />
                    </FormGroup>
                    <FormGroup
                        label={translations.WAIT_seconds}
                        isRequired
                        isValid={
                            isMaximumValid() && isMinimumValid() && noNegative() && allPopulated()
                        }
                        showValidation={hasSubmitted}
                        htmlFor="wait-seconds"
                    >
                        <input
                            id="wait-seconds"
                            value={wait.wait?.seconds ?? 0}
                            onChange={onSecondsChange}
                            required
                            className="form-control form-control-width"
                            type="number"
                        />
                    </FormGroup>
                    {!noNegative() && hasSubmitted && (
                        <span className="help-block error-state">
                            {translations.WAIT_negative_error}
                        </span>
                    )}
                    {!allPopulated() && hasSubmitted && (
                        <span className="help-block error-state">
                            {translations.WAIT_timefield_error}
                        </span>
                    )}
                    {!isMinimumValid() && hasSubmitted && (
                        <span className="help-block error-state">{getMinimumMessage()}</span>
                    )}
                    {!isMaximumValid() && hasSubmitted && maxWaitError}
                </>
            )}
            {waitType === WAIT_TYPES.relativePhrase && (
                <>
                    <FormGroup
                        label={translations.WAIT_phrase}
                        isRequired
                        isValid={isValidRelativeTime()}
                        showValidation={hasSubmitted}
                        htmlFor="wait-relative-phrase"
                    >
                        <>
                            <input
                                id="wait-relative-phrase"
                                value={wait.wait?.relative ?? ''}
                                onChange={onRelativeChange}
                                required
                                className="form-control form-control-width"
                                type="text"
                            />
                            <span className="help-block">{getMinimumMessage(true)}</span>
                            <span className="help-block">
                                {translations.WAIT_phrase_help_1}
                                <br />
                                {translations.WAIT_phrase_help_2}
                                <br />
                                {translations.WAIT_phrase_help_3}
                                <br />
                                {translations.WAIT_phrase_help_4}
                                <br />
                                {translations.WAIT_phrase_help_5}
                            </span>
                        </>
                    </FormGroup>
                    {!isValidRelativeTime() && hasSubmitted && (
                        <span className="help-block error-state">
                            {translations.WAIT_relative_error}
                        </span>
                    )}
                    {renderTimezoneSelect()}
                </>
            )}
            {waitType === WAIT_TYPES.absoluteTime && (
                <>
                    <FormGroup
                        label={translations.WAIT_point_in_time}
                        isRequired
                        isValid={isValidAbsoluteTime()}
                        showValidation={hasSubmitted}
                    >
                        <DateTimeInput
                            className="form-control form-control-width"
                            value={wait.wait?.absoluteDate ?? ''}
                            onChange={onAbsoluteChange}
                            isUTC={true}
                        />
                    </FormGroup>
                    {!isAbsolutePopulated() && hasSubmitted && (
                        <span className="help-block error-state">
                            {translations.WAIT_enter_absolute}
                        </span>
                    )}
                    {!isMaximumValid() && hasSubmitted && maxWaitError}
                    {renderTimezoneSelect()}
                </>
            )}
            {waitType === WAIT_TYPES.value && (
                <>
                    <FormGroup
                        label={translations.VALUE}
                        isRequired
                        isValid={isValidValue()}
                        showValidation={hasSubmitted}
                    >
                        <ValueSelectorModal
                            value={wait?.wait?.value ?? null}
                            onChangeAsValueReference={onValueChanged}
                            includeSystemValues={false}
                            container={container}
                        />
                    </FormGroup>
                    {!isValidValueContentType() && hasSubmitted && (
                        <span className="help-block error-state">
                            {translations.WAIT_invalid_value}
                        </span>
                    )}
                    {renderTimezoneSelect()}
                </>
            )}
            {!isMapElement && (
                <>
                    <FormGroup
                        validationMessage={translations.WAIT_outcome_error}
                        showValidation={hasSubmitted}
                        isValid={isValidPageWait()}
                        label="Outcome"
                        htmlFor="outcome-select"
                        isRequired={true}
                    >
                        <>
                            <select
                                id="outcome-select"
                                value={wait.wait?.outcomeId ?? ''}
                                onChange={onOutcomeChange}
                                placeholder={translations.WAIT_default_outcome}
                                required
                                className="form-control form-control-width"
                            >
                                <option value="" key="please-select">
                                    {translations.WAIT_default_outcome}
                                </option>
                                {mapElement.outcomes?.map((item) => {
                                    if (item !== null) {
                                        return (
                                            <option key={item.id} value={item.id}>
                                                {item?.developerName}
                                            </option>
                                        );
                                    }
                                    return null;
                                })}
                            </select>
                            <span className="help-block">{translations.WAIT_outcome_help}</span>
                        </>
                    </FormGroup>
                </>
            )}
        </>
    );
};

export default WaitInputs;

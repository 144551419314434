import { Scan } from '@phosphor-icons/react';
import type { PageComponentPreviewProps } from '../../../../../types';
import PreviewComponentLabel from '../previewComponentLabel/PreviewComponentLabel';

const CodeScanner = ({ label, isRequired }: PageComponentPreviewProps) => {
    return (
        <div
            data-testid="component-preview-code-scanner"
            className="component-preview-code-scanner"
        >
            <PreviewComponentLabel
                className="component-preview-code-scanner-label"
                labelText={label}
                isRequired={isRequired}
            />
            <div
                data-testid="component-preview-code-scanner-mock"
                className="component-preview-code-scanner-mock"
            >
                <Scan
                    data-testid="component-preview-code-scanner-mock-icon"
                    className="component-preview-code-scanner-mock-icon"
                    weight="thin"
                    size={192}
                />
            </div>
        </div>
    );
};

export default CodeScanner;

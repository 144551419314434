import { ButtonFlavor, ButtonType, ExButton, ExEmptyState } from '@boomi/exosphere';
import type {
    ElementChange,
    ElementIndividualChange,
    FlowResponseAPI,
    NotifyError,
    PackageConflictResponse,
    ReleasedSnapshot,
} from '../../../types';
import translations from '../../../translations';
import SnapshotCard from './SnapshotCard';
import {
    getSnapshotCreators,
    getVersionName,
    revertVersion,
    calculateHistory,
    inspectHistory,
} from './utils';
import { useAuth } from '../../AuthProvider';
import ConfirmModal from '../../generic/modal/ConfirmModal';
import { useEffect, useState } from 'react';
import DependencyTable from '../../generic/DependencyTable';
import './history.less';
import HistoryRunButton from './HistoryRunButton';
import GenericModal from '../../generic/modal/GenericModal';
import ButtonDefault from '../../buttons/ButtonDefault';
import ChangesSection from './ChangesSection';
import type { FlowEditorSharedData } from '../../graphv2/render/FlowEditorSharedWrapper';
import Loader from '../../loader/Loader';

interface Props extends Omit<FlowEditorSharedData, 'loadReleases'> {
    flowId: string;
    container: HTMLElement | null;
    notifyError: NotifyError;
    isFlowHistoryModalOpen: boolean;
    setIsFlowHistoryModalOpen: (isOpen: boolean) => void;
    refreshFlow: () => void;
}

const History = ({
    flowId,
    container,
    notifyError,
    refreshFlow,
    isFlowHistoryModalOpen,
    setIsFlowHistoryModalOpen,
    releases,
    environments,
    isLoadingReleases,
}: Props) => {
    const { tenant } = useAuth();

    const [snapshotDiffs, setSnapshotDiffs] = useState<ElementChange[]>([]);
    const [historyData, setHistoryData] = useState<ElementIndividualChange[] | null>(null);
    const [historyBeforeVersion, setHistoryBeforeVersion] = useState<ReleasedSnapshot | null>(null);
    const [historyAfterVersion, setHistoryAfterVersion] = useState<ReleasedSnapshot | null>(null);
    const [restoringVersion, setRestoringVersion] = useState<ReleasedSnapshot | FlowResponseAPI>();
    const [conflictingDependents, setConflictingDependents] = useState<PackageConflictResponse>();
    const [selectedSnapshots, setSelectedSnapshots] = useState<ReleasedSnapshot[]>([]);
    const [isCompareMode, setIsCompareMode] = useState<boolean>(false);
    const [selectedTreeNodePath, setSelectedTreeNodePath] = useState<string | null>(null);
    const [isSingleReleaseView, setIsSingleReleaseView] = useState<boolean>(false);
    const [isViewChangesModalOpen, setIsViewChangesModalOpen] = useState<boolean>(false);
    const [isLoadingHistoryData, setIsLoadingHistoryData] = useState<boolean>(false);

    const stopViewingChanges = () => {
        setIsViewChangesModalOpen(false);
        setHistoryData(null);
    };

    const selectForCompare = (snapshot: ReleasedSnapshot) => {
        if (selectedSnapshots.some((s) => s.releaseId === snapshot.releaseId)) {
            return setSelectedSnapshots(
                selectedSnapshots.filter((s) => s.releaseId !== snapshot.releaseId),
            );
        }
        if (selectedSnapshots.length >= 2) {
            return;
        }
        return setSelectedSnapshots([...selectedSnapshots, snapshot]);
    };

    const stopComparing = () => {
        setSelectedSnapshots([]);
        setIsCompareMode(false);
    };

    const compareSelections = () => {
        setIsSingleReleaseView(false);
        if (
            new Date(selectedSnapshots[0].dateCreated) < new Date(selectedSnapshots[1].dateCreated)
        ) {
            return calculateHistoryHelper(selectedSnapshots[0], selectedSnapshots[1]);
        }
        // Flip the order so we cna be certain the first is the earlier snapshot
        setSelectedSnapshots([selectedSnapshots[1], selectedSnapshots[0]]);
        return calculateHistoryHelper(selectedSnapshots[1], selectedSnapshots[0]);
    };

    const isInitialRestoreModalOpen = !!restoringVersion && !conflictingDependents;

    const isConflictsRestoreModalOpen = !!conflictingDependents;

    useEffect(() => {
        const isAFlowHistoryModalOpen =
            isInitialRestoreModalOpen || isConflictsRestoreModalOpen || isViewChangesModalOpen;
        if (isFlowHistoryModalOpen !== isAFlowHistoryModalOpen) {
            setIsFlowHistoryModalOpen(isAFlowHistoryModalOpen);
        }
    }, [
        isInitialRestoreModalOpen,
        isConflictsRestoreModalOpen,
        isViewChangesModalOpen,
        isFlowHistoryModalOpen,
        setIsFlowHistoryModalOpen,
    ]);

    const inspectHistoryHelper = (givenSnapshotDiffs: ElementChange[] | null = null) =>
        inspectHistory(snapshotDiffs, setHistoryData, givenSnapshotDiffs);

    const revertVersionHelper = (
        restoringVersion: ReleasedSnapshot | FlowResponseAPI,
        force: boolean,
    ) =>
        revertVersion(
            restoringVersion,
            flowId,
            refreshFlow,
            setRestoringVersion,
            setConflictingDependents,
            force,
        );

    const calculateHistoryHelper = async (
        beforeVersion: ReleasedSnapshot,
        afterVersion: ReleasedSnapshot,
    ) =>
        calculateHistory(
            beforeVersion,
            afterVersion,
            setHistoryBeforeVersion,
            setHistoryAfterVersion,
            flowId,
            setSnapshotDiffs,
            inspectHistoryHelper,
            notifyError,
            setIsLoadingHistoryData,
            setIsViewChangesModalOpen,
        );

    const userList = getSnapshotCreators(releases);

    return (
        <div className="history-panel">
            <div className="history-title">
                <h4>Flow History</h4>
                {releases.length > 0 ? (
                    isCompareMode ? (
                        <>
                            <ExButton
                                flavor={ButtonFlavor.BRANDED}
                                type={ButtonType.SECONDARY}
                                onClick={stopComparing}
                            >
                                {translations.COMMON_cancel}
                            </ExButton>
                            <ExButton
                                flavor={ButtonFlavor.BRANDED}
                                type={ButtonType.PRIMARY}
                                onClick={compareSelections}
                                disabled={selectedSnapshots?.length !== 2}
                            >
                                {translations.FLOW_HISTORY_compare_button}
                            </ExButton>
                        </>
                    ) : (
                        <ExButton
                            flavor={ButtonFlavor.BRANDED}
                            type={ButtonType.SECONDARY}
                            onClick={() => setIsCompareMode(true)}
                        >
                            {translations.FLOW_HISTORY_start_comparing_button}
                        </ExButton>
                    )
                ) : null}
            </div>
            {isCompareMode && (
                <span className="compare-text">
                    {translations.FLOW_HISTORY_please_select_releases}
                    <b>{selectedSnapshots.length}/2 selected</b>
                </span>
            )}
            {isLoadingReleases ? (
                <Loader message={translations.FLOW_HISTORY_loading_releases} />
            ) : (
                <>
                    {/* Render snapshots */}
                    {releases.length > 0 ? (
                        releases.map((snapshot, index) => (
                            <SnapshotCard
                                snapshot={snapshot}
                                key={snapshot.releaseId}
                                environments={environments}
                                userList={userList}
                                isCompareMode={isCompareMode}
                                selectForCompare={selectForCompare}
                                selected={selectedSnapshots.some(
                                    (s) => s.releaseId === snapshot.releaseId,
                                )}
                            >
                                <div className="button-group">
                                    <HistoryRunButton
                                        tenant={tenant}
                                        environments={environments}
                                        flowId={flowId}
                                        snapshot={snapshot}
                                    />
                                    {releases.length > index + 1 ? (
                                        <ExButton
                                            onClick={() => {
                                                setIsSingleReleaseView(true);
                                                calculateHistoryHelper(
                                                    releases[index + 1],
                                                    snapshot,
                                                );
                                            }}
                                            flavor={ButtonFlavor.BRANDED}
                                            type={ButtonType.SECONDARY}
                                            data-testid="view-changes"
                                        >
                                            View Changes
                                        </ExButton>
                                    ) : null}
                                </div>
                            </SnapshotCard>
                        ))
                    ) : (
                        <ExEmptyState
                            className="full-width"
                            label={translations.FLOW_HISTORY_no_snapshots}
                            text={translations.FLOW_HISTORY_no_snapshots_description}
                        />
                    )}
                </>
            )}
            {/* Viewing changes */}
            <GenericModal
                title="View Changes"
                renderBody={() => (
                    <>
                        <span className="changes-section-text">
                            {isSingleReleaseView
                                ? `Changes made in "${historyAfterVersion?.releaseName}"`
                                : `Changes made between "${historyBeforeVersion?.releaseName}" and "${historyAfterVersion?.releaseName}"`}
                        </span>
                        <span className="scroll-blocker" />
                        {isLoadingHistoryData ? (
                            <Loader message={translations.FLOW_HISTORY_loading_changes} />
                        ) : (
                            <ChangesSection
                                historyBeforeVersion={historyBeforeVersion}
                                historyAfterVersion={historyAfterVersion}
                                userList={userList}
                                environments={environments}
                                historyData={historyData}
                                selectedTreeNodePath={selectedTreeNodePath}
                                setSelectedTreeNodePath={setSelectedTreeNodePath}
                                container={container}
                            />
                        )}
                    </>
                )}
                renderFooter={() => (
                    <ButtonDefault onClick={stopViewingChanges}>
                        {translations.COMMON_cancel}
                    </ButtonDefault>
                )}
                show={isViewChangesModalOpen}
                container={container}
                onHide={stopViewingChanges}
                dialogClassName="modal-dialog-large"
                bodyClassName="no-padding"
            />
            {/* Initial "Are you sure you want to restore" modal */}
            <ConfirmModal
                title="Restore version"
                messages={[
                    translations.FLOW_HISTORY_restore_confirm_1,
                    <span key={0}>
                        {translations.FLOW_HISTORY_restore_confirm_2}{' '}
                        <b>{restoringVersion ? getVersionName(restoringVersion) : 'Unknown'}</b>
                    </span>,
                    translations.IMPORT_confirm_overwrite_body_4,
                ]}
                onCancel={() => setRestoringVersion(undefined)}
                onConfirm={() => restoringVersion && revertVersionHelper(restoringVersion, false)}
                buttonStyle="danger"
                buttonCaption={`Restore ${
                    restoringVersion ? getVersionName(restoringVersion) : 'Unknown'
                } version`}
                show={isInitialRestoreModalOpen}
                container={container}
            />
            {/* Second "This is what will be overridden" modal if things are overridden */}
            <ConfirmModal
                title={translations.IMPORT_confirm_overwrite_title}
                messages={[
                    translations.FLOW_HISTORY_restore_conflict_1,
                    translations.FLOW_HISTORY_restore_conflict_2,
                    (conflictingDependents && (
                        <DependencyTable key={0} dependents={conflictingDependents} />
                    )) ?? <span />,
                    <span key={1}>
                        {translations.FLOW_HISTORY_restore_conflict_3}{' '}
                        <b>{restoringVersion ? getVersionName(restoringVersion) : 'Unknown'}</b>
                    </span>,
                    translations.IMPORT_confirm_overwrite_body_4,
                ]}
                onCancel={() => {
                    setRestoringVersion(undefined);
                    setConflictingDependents(undefined);
                }}
                buttonStyle="danger"
                onConfirm={() => restoringVersion && revertVersionHelper(restoringVersion, true)}
                show={isConflictsRestoreModalOpen}
                container={container}
            />
        </div>
    );
};

export default History;

import { useEffect } from 'react';
import translations from '../../../../../ts/translations';
import { isNullOrEmpty } from '../../../../../ts/utils/guard';
import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import HTMLInput from '../../../../../ts/components/generic/HTMLEditor/HTMLInput';
import { useMapElement } from '../contextProviders/MapElementProvider';
import { useWait } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/WaitProvider';
import WaitInputs from '../../../../../ts/components/flow/elementConfigurations/common/wait/WaitInputs';
import ModalBody from '../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../ts/components/generic/modal/ModalFooter';

/**
 * The wait config screen
 */
const Wait = () => {
    const { mapElement, onSaveMapElement, onClose, setMapElement } = useMapElement();
    const {
        setHasSubmitted,
        isValidWait,
        initialWait,
        onWaitTypeChange,
        onSecondsChange,
        onMinutesChange,
        onHoursChange,
        onDaysChange,
        onTimezoneChange,
        onRelativeChange,
        onAbsoluteChange,
        onValueChanged,
        isMapElement,
        setIsMapElement,
        onUpdateName,
        onHeadlessChange,
    } = useWait();

    useEffect(() => {
        if (isNullOrEmpty(mapElement.wait)) {
            setMapElement({
                ...mapElement,
                developerName: initialWait.developerName,
                wait: {
                    ...initialWait,
                },
            });
        }
        if (!isMapElement) {
            setIsMapElement(true);
        }
    });

    const renderBody = () => (
        <>
            <WaitInputs
                isMapElement={true}
                onWaitTypeChange={onWaitTypeChange}
                onUpdateName={onUpdateName}
                onSecondsChange={onSecondsChange}
                onMinutesChange={onMinutesChange}
                onHoursChange={onHoursChange}
                onDaysChange={onDaysChange}
                onTimezoneChange={onTimezoneChange}
                onRelativeChange={onRelativeChange}
                onAbsoluteChange={onAbsoluteChange}
                onValueChanged={onValueChanged}
                onHeadlessChange={onHeadlessChange}
            />
            <FormGroup label="Message to show users who are waiting">
                <HTMLInput
                    contentValue={mapElement.userContent ?? ''}
                    onChange={(event) => {
                        setMapElement({
                            ...mapElement,
                            userContent: event,
                            userContentDateModified: new Date().toISOString(),
                        });
                    }}
                    hasValuePickerPlugin={true}
                />
            </FormGroup>
            <FormGroup
                key="wait-comments"
                label={translations.WAIT_ELEMENT_comments_label}
                htmlFor="wait-comments"
            >
                <textarea
                    id="wait-comments"
                    className="form-control form-textarea"
                    value={mapElement.developerSummary}
                    onChange={({ target: { value } }) => {
                        setMapElement({
                            ...mapElement,
                            developerSummary: value,
                        });
                    }}
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => [
        <ButtonDefault key="cancelButton" className="flex-child-right" onClick={() => onClose()}>
            {translations.GRAPH_config_panel_cancel}
        </ButtonDefault>,
        <ButtonPrimary key="saveButton" className="margin-left" onClick={saveWaitElement}>
            {translations.GRAPH_config_panel_save}
        </ButtonPrimary>,
    ];

    const saveWaitElement = () => {
        setHasSubmitted(true);
        if (isValidWait()) {
            onSaveMapElement(mapElement);
        }
    };

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default Wait;

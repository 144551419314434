import ButtonPrimary from '../../../../../../../../buttons/ButtonPrimary';
import Glyphicon from '../../../../../../../../generic/Glyphicon';
import DraggableTable from '../../../../../../../../generic/DraggableTable';
import { Trash } from '@phosphor-icons/react';
import translations from '../../../../../../../../../translations';
import type { column } from '../../../../../../../../../types';

interface Props {
    screen?: string;
    listItems: column[] | null;
    editItem: (selectedItem: number) => void;
    deleteItem: (selectedItem: number) => void;
    updateDataPresentation: (columns: column[]) => void;
    createItem: () => void;
}

const DataPresentationList = ({
    listItems,
    editItem,
    deleteItem,
    updateDataPresentation,
    createItem,
}: Props) => {
    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_column,
            renderCell: ({ item, rowIndex }: { item: column; rowIndex: number }) => (
                <button
                    className="link-emulate"
                    title="Edit"
                    aria-label="Edit"
                    onClick={() => editItem(rowIndex)}
                    type="button"
                >
                    <div className="item-info">
                        <span className="item-column">{item.typeElementPropertyDeveloperName}</span>
                        {item.label && (
                            <>
                                labeled as
                                <span className="item-column">{item.label}</span>
                            </>
                        )}
                    </div>
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ rowIndex }: { rowIndex: number }) => (
                <button
                    title={`Delete row ${rowIndex}`}
                    className="table-icon table-icon-delete margin-left"
                    aria-label={`Delete row ${rowIndex}`}
                    onClick={() => deleteItem(rowIndex)}
                    type="button"
                >
                    <Trash />
                </button>
            ),
            size: '8rem',
        },
    ];

    return (
        <>
            <div className="flex flex-child-right">
                <ButtonPrimary onClick={createItem} title="Add new data presentation column">
                    <Glyphicon glyph="plus" />
                    Add column
                </ButtonPrimary>
            </div>
            {listItems?.length ? (
                <DraggableTable
                    onReorder={(items) =>
                        updateDataPresentation(items.map((od, i) => ({ ...od, order: i })))
                    }
                    columns={columns}
                    items={listItems.sort((a, b) => a.order - b.order)}
                    testId="columns"
                    tableId="DATA_PRESENTATION"
                />
            ) : null}
        </>
    );
};

export default DataPresentationList;

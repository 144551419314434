import Glyphicon from '../../../../../../ts/components/generic/Glyphicon';
import { useMapElement } from '../../contextProviders/MapElementProvider';
import ButtonPrimary from '../../../../../../ts/components/buttons/ButtonPrimary';
import screens from '../screens';
import FormGroup from '../../../../../../ts/components/generic/FormGroup';
import Toggle from '../../../../../../ts/components/inputs/Toggle';
import translations from '../../../../../../ts/translations';
import { Check, Trash, X } from '@phosphor-icons/react';
import Table from '../../../../../../ts/components/generic/Table';

const NavigationOverrideList = () => {
    const {
        mapElement,
        onEditNavigation,
        onDeleteNavigation,
        setSelectedNavigationIndex,
        onSwitchScreen,
        setMapElement,
    } = useMapElement();

    const onAddNavigationOverride = () => {
        setSelectedNavigationIndex(null);
        onSwitchScreen(screens.navigationOverrides);
    };

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_navigation,
            renderCell: ({ item, rowIndex }) => (
                <button
                    className="link-emulate"
                    title={`Edit ${item.navigationElementDeveloperName}`}
                    onClick={() => onEditNavigation(rowIndex)}
                    type="button"
                >
                    {item.navigationElementDeveloperName ?? ''}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_item,
            renderCell: ({ item: { navigationItemDeveloperName } }) =>
                navigationItemDeveloperName ?? '',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_location,
            renderCell: ({ item: { locationMapElementDeveloperName, urlValueDeveloperName } }) =>
                urlValueDeveloperName
                    ? urlValueDeveloperName
                    : locationMapElementDeveloperName ?? '',
        },
        {
            renderHeader: () => (
                <span className="block full-width text-center">
                    {translations.COMMON_TABLE_enabled}
                </span>
            ),
            renderCell: ({ item: { isEnabled } }) => (
                <span className="block full-width text-center table-icon-medium">
                    {isEnabled ? <Check /> : <X />}
                </span>
            ),
            size: '5rem',
        },
        {
            renderHeader: () => (
                <span className="block full-width text-center">
                    {translations.COMMON_TABLE_visible}
                </span>
            ),
            renderCell: ({ item: { isVisible } }) => (
                <span className="block full-width text-center table-icon-medium">
                    {isVisible ? <Check /> : <X />}
                </span>
            ),
            size: '5rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item, rowIndex }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.navigationElementDeveloperName}`}
                        className="table-icon table-icon-delete"
                        aria-label={`Delete ${item.navigationElementDeveloperName}`}
                        onClick={() => onDeleteNavigation(rowIndex)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    const onChangeClearOverrides = ({ isOn }) => {
        setMapElement({
            ...mapElement,
            clearNavigationOverrides: isOn,
        });
    };

    return (
        <>
            <h4>Override Navigation</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onAddNavigationOverride}>
                    <Glyphicon glyph="plus" />
                    Add Navigation Override
                </ButtonPrimary>
            </div>
            <Table
                columns={columns}
                wrapperClassName="margin-bottom-large"
                items={mapElement.navigationOverrides}
                testId="navigation-overrides-list"
            />
            <FormGroup>
                <label>
                    <Toggle
                        isOn={!!mapElement.clearNavigationOverrides}
                        onChange={onChangeClearOverrides}
                    />
                    Clear all navigation overrides
                </label>
            </FormGroup>
        </>
    );
};

export default NavigationOverrideList;

import { createElement, useState } from 'react';
import { createRoot } from 'react-dom/client';
import AssetManagerModal from '../../../assets/AssetManagerModal';

interface Props {
    onInsertAsset: (value: string) => void;
}

const AssetModal = ({ onInsertAsset }: Props) => {
    const [showAssetModal, setShowAssetModal] = useState(true);
    return (
        <AssetManagerModal
            showAssetModal={showAssetModal}
            setShowAssetModal={setShowAssetModal}
            onInsertAsset={onInsertAsset}
            allowedExtensions=".jpg,.jpeg,.png,.svg,.gif,.webp,.apng,.avif"
            filterListToAllowedExtensions={true}
        />
    );
};

export const file_picker_callback = (
    callback: (value: string, meta?: Record<string, unknown> | undefined) => void,
) => {
    const element = document.createElement('div');
    document.body.append(element);
    const root = createRoot(element);

    const onInsertAsset = (assetURL: string) => {
        callback(assetURL);
        root.unmount();
        document.body.removeChild(element);
    };

    root.render(
        createElement(AssetModal, {
            onInsertAsset,
        }),
    );
};

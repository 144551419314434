import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notifyError } from '../../../../actions/reduxActions/notification';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';
import { getMapElement } from '../../../../../ts/sources/graph';
import translations from '../../../../../ts/translations';
import { stringReplace } from '../../../../../ts/utils/string';
import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../ts/components/buttons/ButtonPrimary';
import { useGraph } from '../../../graph/GraphProvider';
import Loader from '../../../../../ts/components/loader/Loader';
import ConfigLayoutWrapper from '../../../../../ts/components/flow/elementConfigurations/common/ConfigLayoutWrapper';

const OutcomeRedirect = ({
    id,
    dismissMapElementConfig,
    container,
    editingToken,
    flowId,
    nextMapElement,
    outcomeId,
    targetId,
    onError,
}) => {
    const { saveMapElement, refreshFlow } = useGraph();
    const [mapElement, setMapElement] = useState(null);
    const title = translations.OUTCOME_confirm_move;
    const message = stringReplace(
        translations.OUTCOME_confirm_move_message,
        nextMapElement.developerName,
    );

    const elementType = MAP_ELEMENT_TYPES.outcome;

    const onSave = async () => {
        const updatedOutcomes = mapElement.outcomes.map((outcome) => {
            if (outcome.id === outcomeId) {
                return {
                    ...outcome,
                    nextMapElementId: targetId,
                };
            }
            return outcome;
        });

        const updatedMapElement = {
            ...mapElement,
            outcomes: updatedOutcomes,
        };

        await saveMapElement(updatedMapElement);
        dismissMapElementConfig();
    };

    const onCancel = () => {
        // Reset the flow canvas
        refreshFlow();
        dismissMapElementConfig();
    };

    const fetchMapElement = async () => {
        try {
            const mapElementData = await getMapElement(id, flowId, editingToken);

            setMapElement(mapElementData);
        } catch (error) {
            // If something goes wrong, then show an error
            // and just close the outcome redirect config panel
            onError(error);
            onCancel();
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchMapElement();
    }, []);

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onCancel}>
                {translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {translations.GRAPH_config_panel_save}
            </ButtonPrimary>
        </>
    );

    if (!mapElement) {
        return <Loader />;
    }

    return (
        <ConfigLayoutWrapper
            renderBody={() => <p>{message}</p>}
            renderFooter={renderFooter}
            title={title}
            elementType={elementType}
            onHide={onCancel}
            container={container}
        />
    );
};

export default connect(null, {
    onError: notifyError,
})(OutcomeRedirect);

import { useMapElement } from '../../../../../js/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import ProcessConfiguration from './ProcessConfiguration';
import ProcessDetails from './ProcessDetails';
import OutcomeDetails from '../../../../../js/components/flow/elementConfigurations/common/outcomes/OutcomeDetails';
import type { ProcessScreens, UseMapElement } from '../../../../types';
import ProcessPropertiesDetails from './ProcessPropertiesDetails';

const ProcessRouter = () => {
    const {
        currentScreen,
        mapElement,
        onSwitchScreen,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
    }: UseMapElement<ProcessScreens> = useMapElement();

    switch (currentScreen) {
        case 'process':
            return <ProcessConfiguration />;

        case 'processDetails':
            return <ProcessDetails />;

        case 'processProperties':
            return <ProcessPropertiesDetails />;

        case 'outcome':
            return (
                <OutcomeDetails
                    outcome={mapElement?.outcomes?.[selectedOutcomeIndex]}
                    onSave={() => onSwitchScreen('process')}
                    onCancel={() => {
                        onSwitchScreen('process');
                        setSelectedOutcomeIndex(null);
                    }}
                />
            );

        default:
            return null;
    }
};

export default ProcessRouter;

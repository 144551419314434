import Table from '../../../../../../../generic/Table';
import type { PageComponentValidationAPI } from '../../../../../../../../types';
import ButtonIcon from '../../../../../../../buttons/ButtonIcon';
import ButtonPrimary from '../../../../../../../buttons/ButtonPrimary';
import Glyphicon from '../../../../../../../generic/Glyphicon';
import type { ValidationAction } from './ValidationConfig';
import translations from '../../../../../../../../translations';

interface Props {
    validations: PageComponentValidationAPI[];
    updateValidations: (validations: PageComponentValidationAPI[]) => void;
    dispatchValidations: (value: ValidationAction) => void;
}

const ValidationList = ({ validations, updateValidations, dispatchValidations }: Props) => {
    const removeValidation = (removeIndex: number) => {
        updateValidations(validations.filter((_, index) => index !== removeIndex));
    };

    const onReorder = (orderedRows: PageComponentValidationAPI[]) => {
        updateValidations(orderedRows);
    };

    return (
        <>
            {validations.length > 0 && (
                <p>
                    Validations in the list below will only apply when the component has a value
                    supplied.
                </p>
            )}
            <div className="flex">
                <ButtonPrimary
                    className="flex-child-right"
                    title="Add validation"
                    onClick={() => dispatchValidations({ type: 'addValidationClick' })}
                >
                    <Glyphicon glyph="plus" />
                    {translations.PAGE_BUILDER_validation_add}
                </ButtonPrimary>
            </div>
            {validations.length > 0 && (
                <Table
                    tableId="validation-list-table"
                    onReorder={onReorder}
                    // Function must be passed for rows to selectable, which is needed for re-ordering
                    onRowSelect={() => undefined}
                    columns={[
                        {
                            renderHeader: () => translations.COMMON_TABLE_type,
                            renderCell: ({ item }) => item.type,
                        },
                        {
                            renderHeader: () => translations.COMMON_TABLE_value,
                            renderCell: ({ item }) => {
                                // minimum and maximum validation could be a date in the format yyyy-mm-dd or a number
                                if (
                                    (item.type === 'Minimum' || item.type === 'Maximum') &&
                                    typeof item.value === 'string' &&
                                    Number.isNaN(Number(item.value))
                                ) {
                                    // This looks like a date, so format it in the user's locale format
                                    return new Date(item.value).toLocaleDateString();
                                }
                                return item.value;
                            },
                        },
                        {
                            renderHeader: () => translations.COMMON_TABLE_message,
                            renderCell: ({ item }) => item.message,
                        },
                        {
                            renderHeader: () => translations.COMMON_TABLE_actions,
                            renderCell: ({ rowIndex }) => (
                                <div className="action-btn-wrapper">
                                    <ButtonIcon
                                        glyph="Edit"
                                        aria-label="Edit"
                                        onClick={() => {
                                            dispatchValidations({
                                                type: 'editValidationClick',
                                                payload: {
                                                    selectedValidationIndex: rowIndex,
                                                },
                                            });
                                        }}
                                        iconClass="icon-small"
                                    />
                                    <ButtonIcon
                                        className="danger"
                                        glyph="Delete"
                                        aria-label="Delete"
                                        onClick={() => removeValidation(rowIndex)}
                                        iconClass="icon-small"
                                    />
                                </div>
                            ),
                            size: '8rem',
                        },
                    ]}
                    items={validations}
                />
            )}
        </>
    );
};

export default ValidationList;

import { getType, getAutoMappingToTargetType } from '../../sources/type';
import './automapping-map.css';
import { useEffect, useState } from 'react';
import type { TypeElementResponseAPI, TypeMappingAutoAPI } from '../../types';
import AutoMappingType from './AutoMappingType';
import { ExLoader, LoaderSize, ExAlertBanner, AlertBannerType } from '@boomi/exosphere';
import translations from '../../translations';
import AutoMappingTypeMatched from './AutoMappingTypeMatched';
import AutoMappingTypeUnMatched from './AutoMappingTypeUnMatched';

type Types = Record<string, TypeElementResponseAPI>;

interface Props {
    sourceTypeElementId: string;
    targetTypeElementId: string;
}

const AutoMappingMap = ({ sourceTypeElementId, targetTypeElementId }: Props) => {
    const [types, setTypes] = useState<Types>({});
    const [mappings, setMappings] = useState<TypeMappingAutoAPI | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const loadType = async (id: string, types: Types) => {
            if (!types[id]) {
                const type = await getType(id);
                types[type.id] = type;

                for (const property of type.properties) {
                    if (property.typeElementId && !types[property.typeElementId]) {
                        // biome-ignore lint/style/noParameterAssign: Unsure how to refactor this to not use parameter assignment
                        types = await loadType(property.typeElementId, types);
                    }
                }
            }

            return types;
        };

        const loadTypes = async () => {
            let newTypes = await loadType(sourceTypeElementId, {});
            newTypes = await loadType(targetTypeElementId, newTypes);
            setTypes(newTypes);
        };

        const loadMappings = async () => {
            setMappings(await getAutoMappingToTargetType(sourceTypeElementId, targetTypeElementId));
        };

        const load = async () => {
            setLoading(true);

            try {
                await loadTypes();
                await loadMappings();
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setLoading(false);
            }
        };

        load();
    }, []);

    return (
        <div className="automapping-type-map">
            {error ? (
                <ExAlertBanner type={AlertBannerType.ERROR} open={true} className="ex-mb-large">
                    {error}
                </ExAlertBanner>
            ) : null}
            {loading ? (
                <ExLoader size={LoaderSize.LARGE} label={translations.AUTO_MAPPING_type_loading} />
            ) : (
                <>
                    <div className="source-type card">
                        <h4
                            className="clipped-text"
                            title={types[sourceTypeElementId]?.developerName || ''}
                        >
                            {types[sourceTypeElementId]?.developerName}
                        </h4>
                        <AutoMappingType
                            sourceTypeElementId={sourceTypeElementId}
                            types={types}
                            mapping={mappings}
                        />
                    </div>
                    <div className="target-type-container card">
                        <div className="target-type-matched">
                            <h4
                                className="clipped-text"
                                title={types[sourceTypeElementId]?.developerName || ''}
                            >
                                {types[targetTypeElementId]?.developerName}
                            </h4>
                            <AutoMappingTypeMatched
                                sourceTypeElementId={sourceTypeElementId}
                                targetTypeElementId={targetTypeElementId}
                                mapping={mappings}
                                types={types}
                            />
                        </div>
                        <div className="target-type-unmatched">
                            <h4>{translations.AUTO_MAPPING_type_unmatched_properties}</h4>
                            <AutoMappingTypeUnMatched
                                targetTypeElementId={targetTypeElementId}
                                mapping={mappings}
                                types={types}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default AutoMappingMap;

import type { Point } from '../../../types';

export const calculateSVGPositionFromXY = (svgElement: SVGSVGElement, { x, y }: Point): Point => {
    const point = svgElement.createSVGPoint();
    point.x = x;
    point.y = y;
    const screenCTM = svgElement.getScreenCTM();
    const invertedCTM = screenCTM?.inverse();
    const transformedPoint = point.matrixTransform(invertedCTM);
    return {
        x: transformedPoint.x,
        y: transformedPoint.y,
    };
};

export const positionsAreEqual = (pointA: Point | null, pointB: Point | null) => {
    if (pointA === null || pointB === null) {
        return false;
    }
    return pointA.x === pointB.x && pointA.y === pointB.y;
};

import {
    calculateElementBounds,
    calculateIfBoundIsWithinAnotherBounds,
    getMarqueeDimensions,
} from '../../components/graph/utils';
import actionTypes from './actionTypes';

export const setHoveringMapElementID = (payload) => ({
    type: actionTypes.GRAPH_SET_HOVER_MAP_ID,
    payload,
});

export const setHoveringGroupElementID = (payload) => ({
    type: actionTypes.GRAPH_SET_HOVER_GROUP_ID,
    payload,
});

export const setDraggingOverGroupElementID = (payload) => ({
    type: actionTypes.GRAPH_SET_DRAG_OVER_GROUP_ID,
    payload,
});

export const setDraggingData = (payload) => ({
    type: actionTypes.GRAPH_SET_DRAGGING_DATA,
    payload,
});

export const setContextMenuData = (payload) => ({
    type: actionTypes.GRAPH_SET_CONTEXT_MENU_DATA,
    payload,
});

export const endMarqueeSelection =
    (calculateSVGPositionFromXY, _flowId, setSelectedElementIds, mapElements, groupElements) =>
    async (_dispatch, getState) => {
        const state = getState();
        const initialMousePosition = state.graphEditor.dragging.initialMousePosition;
        const previousMousePosition = state.graphEditor.dragging.previousMousePosition;

        const { x, y, width, height } = getMarqueeDimensions(
            initialMousePosition,
            previousMousePosition,
            calculateSVGPositionFromXY,
        );

        const selectedIds = [...mapElements, ...groupElements]
            .filter((element) =>
                calculateIfBoundIsWithinAnotherBounds({
                    innerBound: calculateElementBounds(element, groupElements),
                    outerBound: {
                        x,
                        y,
                        width,
                        height,
                    },
                }),
            )
            .map((element) => element.id);

        setSelectedElementIds(selectedIds);
    };

export const setSearch = (payload) => ({
    type: actionTypes.GRAPH_SET_SEARCH,
    payload,
});

export const setClipboard = (payload) => ({
    type: actionTypes.GRAPH_SET_CLIPBOARD,
    payload,
});

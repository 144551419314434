import { type FocusEventHandler, type MouseEventHandler, useEffect, useState } from 'react';
import { equals, remove } from 'ramda';
import {
    AlertBannerType,
    ButtonFlavor,
    ButtonType,
    ExAlertBanner,
    ExButton,
    ExLoader,
} from '@boomi/exosphere';
import classnames from 'classnames';
import { VALUE_TYPES } from '../../../constants/value';
import translations from '../../../translations';
import { guid } from '../../../utils/guid';
import { stringReplace } from '../../../utils/string';
import type { ObjectAPI, ObjectPath, PropertyAPI, TypeElementResponseAPI } from '../../../types';
import { getType } from '../../../sources/type';

interface Props {
    value: PropertyAPI;
    path: ObjectPath;
    selectedPath: ObjectPath;
    hoveredPath: ObjectPath;
    filter: string;
    onSelect: (path: ObjectPath, offsetTop: number) => void;
    onObjectDataPropertyChange: (objectData: ObjectAPI[], propertyPath: ObjectPath) => void;
    onHover: (path: ObjectPath) => void;
    typeDictionary: Record<string, TypeElementResponseAPI>;
}

export const isComplexProperty = (value: PropertyAPI) =>
    value.typeElementId && VALUE_TYPES.find((t) => t.value === value.contentType && t.requiresType);

export const newObjectDataFromType = (type: TypeElementResponseAPI, order: number) => ({
    order: order.toString(),
    isSelected: false,
    externalId: null,
    internalId: guid(),
    typeElementId: type.id,
    typeElementBindingDeveloperName: '',
    developerName: type.developerName || '',
    properties: type.properties.map<PropertyAPI>((property) => ({
        developerName: property.developerName,
        contentFormat: '',
        contentType: property.contentType,
        contentValue: '',
        objectData: [],
        typeElementId: property.typeElementId || '',
        typeElementPropertyId: property.id,
    })),
});

export const includeProperty = (filter: string, property: PropertyAPI) => {
    if (property.developerName.toUpperCase().includes(filter.toUpperCase())) {
        return true;
    }

    if (
        property.objectData?.find((item) => item.properties.find((p) => includeProperty(filter, p)))
    ) {
        return true;
    }

    return false;
};

const ObjectDataEditorItem = ({
    value,
    path,
    selectedPath,
    hoveredPath,
    filter,
    onSelect,
    onObjectDataPropertyChange,
    onHover,
    typeDictionary,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [type, setType] = useState<TypeElementResponseAPI | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);
                setType(
                    typeDictionary[value.typeElementId ?? ''] ||
                        (await getType(value.typeElementId ?? '')),
                );
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setLoading(false);
            }
        };

        if (isComplexProperty(value) && value.typeElementId) {
            load();
        }
    }, []);

    const selected = equals(path, selectedPath);
    const hovered = equals(path, hoveredPath);

    const onAddItem = () => {
        if (type) {
            onObjectDataPropertyChange(
                [
                    ...(value.objectData || []),
                    newObjectDataFromType(type, value.objectData ? value.objectData.length : 0),
                ],
                path,
            );
        }
    };

    const onRemoveItem = (index: number) => {
        onObjectDataPropertyChange(remove(index, 1, value.objectData ?? ({} as ObjectAPI[])), path);
    };

    const onMouseOver: MouseEventHandler<HTMLLIElement> = (e) => {
        e.stopPropagation();
        onHover(path);
    };

    const onFocus: FocusEventHandler<HTMLLIElement> = (e) => {
        e.stopPropagation();
        onHover(path);
    };

    const onMouseLeave: MouseEventHandler<HTMLLIElement> = (e) => {
        e.stopPropagation();
        onHover([]);
    };

    const canAddNewItem =
        (value.contentType === 'ContentObject' &&
            (!value.objectData || value.objectData.length === 0)) ||
        value.contentType === 'ContentList';

    const typeName =
        isComplexProperty(value) && type
            ? `${value.contentType === 'ContentList' ? 'List' : 'Object'} of ${
                  type?.developerName || ''
              }`
            : null;

    return (
        <li
            role="treeitem"
            aria-selected={selected}
            className={classnames('object-data-editor-item', { hover: hovered })}
            onMouseLeave={(e) => isComplexProperty(value) && onMouseLeave(e)}
            onMouseOver={(e) => isComplexProperty(value) && onMouseOver(e)}
            onFocus={(e) => isComplexProperty(value) && onFocus(e)}
        >
            {error ? (
                <ExAlertBanner type={AlertBannerType.ERROR} open>
                    {error}
                </ExAlertBanner>
            ) : null}
            {loading ? (
                <ExLoader />
            ) : (
                <>
                    <button
                        onClick={(e) =>
                            !isComplexProperty(value) && onSelect(path, e.currentTarget.offsetTop)
                        }
                        className={classnames({
                            selected,
                            'complex-property': isComplexProperty(value),
                        })}
                        type="button"
                    >
                        <div className="property-name">
                            <span>{value.developerName}</span>
                            {isComplexProperty(value) ? (
                                <span className="complex-property-type-name">{typeName}</span>
                            ) : null}
                        </div>
                        {isComplexProperty(value) ? null : <span>{value.contentValue}</span>}
                    </button>
                    {isComplexProperty(value) && type ? (
                        <ol>
                            {value.objectData?.map((objectData, objectDataIndex) => {
                                const objectDataPath = [...path, 'objectData', objectDataIndex];

                                return (
                                    <li key={objectDataPath.join('_')}>
                                        <div className="object-data-editor-item-header">
                                            <span>{`Item - ${objectDataIndex}`}</span>
                                            <ExButton
                                                flavor={ButtonFlavor.BRANDED}
                                                type={ButtonType.SECONDARY}
                                                onClick={() => onRemoveItem(objectDataIndex)}
                                                data-testId={`property-${value.developerName}-item-${objectDataIndex}-remove`}
                                            >
                                                {translations.OBJECT_DATA_EDITOR_remove}
                                            </ExButton>
                                        </div>
                                        <ol>
                                            {objectData.properties
                                                .filter((property) =>
                                                    includeProperty(filter, property),
                                                )
                                                .map((property, propertyIndex) => (
                                                    <ObjectDataEditorItem
                                                        value={property}
                                                        path={[
                                                            ...objectDataPath,
                                                            'properties',
                                                            propertyIndex,
                                                        ]}
                                                        selectedPath={selectedPath}
                                                        hoveredPath={hoveredPath}
                                                        onHover={onHover}
                                                        filter={filter}
                                                        onSelect={onSelect}
                                                        onObjectDataPropertyChange={
                                                            onObjectDataPropertyChange
                                                        }
                                                        key={`${[
                                                            ...objectDataPath,
                                                            'properties',
                                                            property.typeElementPropertyId,
                                                        ].join('_')}`}
                                                        typeDictionary={typeDictionary}
                                                    />
                                                ))}
                                        </ol>
                                    </li>
                                );
                            })}
                        </ol>
                    ) : null}
                    {canAddNewItem ? (
                        <ExButton
                            flavor={ButtonFlavor.BRANDED}
                            type={ButtonType.SECONDARY}
                            onClick={onAddItem}
                            className="object-data-editor-add-new-item"
                            data-testId={`${value.developerName}-add-new-item`}
                        >
                            {stringReplace(
                                translations.OBJECT_DATA_EDITOR_add_new_item as string,
                                type?.developerName,
                            )}
                        </ExButton>
                    ) : null}
                </>
            )}
        </li>
    );
};

export default ObjectDataEditorItem;

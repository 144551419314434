import { useEffect, useState } from 'react';
import Table, { type TableColumnList } from '../../generic/Table';
import translations from '../../../translations';
import type { StateResponseAPI } from '../../../types/states';
import GenericModal from '../../generic/modal/GenericModal';
import { formatJSONContent, isNullOrEmpty } from '../../../utils';
import SyntaxHighlighter from '../../generic/SyntaxHighlighter';
import Truncate from '../../Truncate';

// Tries (very roughly) to figure out what syntax highlighting to use for given text.
export const syntax = (text: string) => {
    const isHtmlLike = (str: string) => /<[a-z][\s\S]*>/i.test(str);
    const isJsonLike = (str: string) => /\{(.*?)\}|\[(.*?)\]/g.test(str);

    if (!isNullOrEmpty(text)) {
        return isHtmlLike(text) ? 'html' : isJsonLike(text) ? 'json' : 'text';
    }
    return 'text';
};

export const truncateText = (
    text: string,
    limit: number,
    title: string,
    setContent: ({ text, title }: { text: string; title: string }) => void,
) => {
    const viewButton =
        text.length >= limit ? (
            <button
                className="link-emulate"
                onClick={() => setContent({ text, title })}
                type="button"
            >
                {translations.COMMON_view}
            </button>
        ) : null;

    return (
        <div className="flex">
            <Truncate text={text} maxLength={limit} />
            &nbsp;
            {viewButton}
        </div>
    );
};

type RemappedFaults = {
    name: string;
    data: {
        uri: string;
        message: string;
        statusCode: string;
        responseBody: string;
        responseHeaders: string;
    };
};

interface Props {
    selectedState: StateResponseAPI;
}

const RootFaultsInsights = ({ selectedState }: Props) => {
    const [faults, setFaults] = useState<RemappedFaults[]>([]);
    const [content, setContent] = useState<{ text: string; title: string } | null>(null);

    useEffect(() => {
        if (selectedState.hasRootFaults) {
            const remappedFaults = Object.entries(selectedState.rootFaults).map((fault) => ({
                name: fault[0],
                data: JSON.parse(selectedState.rootFaults[fault[0]]),
            }));

            setFaults(remappedFaults);
        }
    }, [selectedState]);

    const columns: TableColumnList<RemappedFaults> = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item?.name ?? '',
            size: '13rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_uri,
            renderCell: ({ item }) => item?.data?.uri ?? '',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_message,
            renderCell: ({ item }) =>
                truncateText(
                    item?.data?.message ?? '',
                    65,
                    translations.COMMON_TABLE_message,
                    setContent,
                ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status_code,
            renderCell: ({ item }) => item?.data?.statusCode ?? '',
            size: '7rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_response_body,
            renderCell: ({ item }) =>
                !!item?.data?.responseBody && (
                    <button
                        className="link-emulate"
                        onClick={() =>
                            setContent({
                                text: item.data.responseBody,
                                title: translations.COMMON_TABLE_response_body,
                            })
                        }
                        type="button"
                    >
                        {translations.COMMON_view}
                    </button>
                ),
            size: '6rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_response_headers,
            renderCell: ({ item }) =>
                !!item?.data?.responseHeaders && (
                    <button
                        className="link-emulate"
                        onClick={() =>
                            setContent({
                                text: JSON.stringify(item.data.responseHeaders),
                                title: translations.COMMON_TABLE_response_headers,
                            })
                        }
                        type="button"
                    >
                        {translations.COMMON_view}
                    </button>
                ),
            size: '6rem',
        },
    ];
    return (
        <>
            <Table
                columns={columns}
                items={faults}
                rowClassName={() => 'generic-row generic-row-tall'}
            />
            <GenericModal
                show={!isNullOrEmpty(content)}
                onHide={() => setContent(null)}
                title={content?.title ?? ''}
                bodyClassName="root-fault-content-modal-body"
                renderBody={() => (
                    <SyntaxHighlighter
                        content={formatJSONContent(content?.text ?? '')}
                        language={syntax(content?.text ?? '')}
                    />
                )}
                renderFooter={() => (
                    <div className="flex">
                        <div className="flex-child-right">
                            <button
                                className="btn btn-sm btn-default"
                                onClick={() => setContent(null)}
                                type="button"
                            >
                                {translations.COMMON_close}
                            </button>
                        </div>
                    </div>
                )}
            />
        </>
    );
};

export default RootFaultsInsights;

import { useState, createContext, useContext } from 'react';
import screens from '../common/screens';
import { isNullOrEmpty } from '../../../../../ts/utils/guard';
import { useMapElement } from './MapElementProvider';

const Context = createContext(undefined);

const ListenerProvider = ({ defaultScreen, children }) => {
    const { mapElement, setMapElement, onSwitchScreen } = useMapElement();

    const initialListener = {
        attributes: null,
        developerName: null,
        listenerType: null,
        serviceElementId: null,
        valueElementToReferenceForListeningId: null,
    };

    const [listenerToEdit, setListenerToEdit] = useState({
        listener: null,
        index: null,
    });

    const onCreateListener = () => {
        const totalListeners = mapElement.listeners ? mapElement.listeners.length : 0;
        setListenerToEdit({
            listener: { ...initialListener },
            index: totalListeners + 1,
        });
        onSwitchScreen(screens.listener);
    };

    const onEditListener = (listener, index) => {
        setListenerToEdit({ listener, index });
        onSwitchScreen(screens.listener);
    };

    const onDeleteListener = (index) => {
        setMapElement({
            ...mapElement,
            listeners: mapElement.listeners.filter((_, i) => i !== index),
        });
    };

    const onSelectDeveloperName = (developerName) =>
        setListenerToEdit({
            ...listenerToEdit,
            listener: {
                ...listenerToEdit.listener,
                developerName,
            },
        });

    const onSelectListenerType = (listenerType) =>
        setListenerToEdit({
            ...listenerToEdit,
            listener: {
                ...listenerToEdit.listener,
                listenerType,
            },
        });

    const onSelectServiceElementId = (serviceElementId) =>
        setListenerToEdit({
            ...listenerToEdit,
            listener: {
                ...listenerToEdit.listener,
                serviceElementId,
            },
        });

    const onSelectValueReference = (valueReference) => {
        let selectedValue = null;

        if (!isNullOrEmpty(valueReference)) {
            selectedValue = {
                id: valueReference.id,
                typeElementPropertyId: valueReference.typeElementPropertyId,
            };
        }

        setListenerToEdit({
            ...listenerToEdit,
            listener: {
                ...listenerToEdit.listener,
                valueElementToReferenceForListeningId: selectedValue,
            },
        });
    };

    const onApplyListener = (index) => {
        const listenerExists = mapElement.listeners
            ? mapElement.listeners.find((_, i) => i === index)
            : null;
        const listeners = listenerExists
            ? mapElement.listeners.map((existingListener, i) =>
                  i === index ? listenerToEdit.listener : existingListener,
              )
            : [...(mapElement.listeners ?? []), listenerToEdit.listener];

        setMapElement({ ...mapElement, listeners });
        onSwitchScreen(defaultScreen);
    };

    const onReturnToDefaultScreen = () => {
        onSwitchScreen(defaultScreen);
    };

    const contextValue = {
        listenerToEdit,
        onCreateListener,
        onEditListener,
        onDeleteListener,
        onSelectDeveloperName,
        onSelectListenerType,
        onSelectServiceElementId,
        onSelectValueReference,
        onApplyListener,
        onReturnToDefaultScreen,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useListener = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useListener must be used within a ListenerProvider');
    }
    return context;
};

export { ListenerProvider, useListener };

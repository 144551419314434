import {
    type ReactNode,
    createContext,
    useCallback,
    useContext,
    useReducer,
    useState,
} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addNotification } from '../../js/actions/reduxActions/notification';
import { initializePendo } from '../../js/actions/reduxActions/pendo';
import { ENGINE_API_URL, NOTIFICATION_TYPES } from '../../ts/constants';
import { signOut, signOutSSO } from '../sources/authentication';
import { allowed } from '../sources/tenant';
import { getUser, getUserSettings } from '../sources/user';
import type { AddNotification } from '../types';
import type { AuthenticatedUser, SSOMetadata, UserSettings } from '../types/auth';
import type { UserResponseAPI, UserTenantResponseAPI } from '../types/user';
import { reloadPage } from '../utils/browser';
import { isNullOrEmpty, isNullOrUndefined } from '../utils/guard';
import { getWithRedirect, signOut as oktaSignOut } from '../utils/okta';

type Auth = {
    user: AuthenticatedUser | null;
    tenant: UserTenantResponseAPI | null;
    ssoMetadata: SSOMetadata | null;
    userSettings: UserSettings;
};

type AuthAction =
    | {
          type: 'set_auth';
          payload: Auth;
      }
    | {
          type: 'set_user_settings_line_thickness';
          payload: number;
      }
    | {
          type: 'set_user_settings_color_style';
          payload: string;
      };

const AuthReducer = (state: Auth, action: AuthAction): Auth => {
    switch (action.type) {
        case 'set_auth':
            return action.payload;
        case 'set_user_settings_line_thickness':
            return {
                ...state,
                userSettings: {
                    ...state.userSettings,
                    canvasSettings: {
                        lineThickness: action.payload,
                        mapElementColorStyle:
                            state.userSettings.canvasSettings.mapElementColorStyle,
                    },
                },
            };
        case 'set_user_settings_color_style':
            return {
                ...state,
                userSettings: {
                    ...state.userSettings,
                    canvasSettings: {
                        lineThickness: state.userSettings.canvasSettings.lineThickness,
                        mapElementColorStyle: action.payload,
                    },
                },
            };
        default:
            return state;
    }
};

interface AuthProviderContext {
    initializeUser: () => Promise<void>;
    signOutUser: () => Promise<void>;
    user: AuthenticatedUser | null;
    tenant: UserTenantResponseAPI | null;
    ssoMetadata: SSOMetadata | null;
    userSettings: UserSettings;
    fetchUser: () => Promise<void>;
    switchTenant: (tenantId: string) => Promise<void>;
    addNotification: AddNotification;
    hasWarnedOfExpiry: boolean;
    setHasWarnedOfExpiry: (hasWarned: boolean) => void;
    getSSOMetadata: () => SSOMetadata | null;
    idleTimeoutKey: string;
    setUserSettingsLineThickness: (lineThickness: number) => void;
    setUserSettingsColorStyle: (colorStyle: string) => void;
}

const Context = createContext<AuthProviderContext | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
    defaultUser?: UserResponseAPI | null;
    defaultTenant?: UserTenantResponseAPI | null;
    defaultSsoMetadata?: SSOMetadata | null;
    addNotification: AddNotification;
}

const userSettingsDefaultState = {
    canvasSettings: {
        lineThickness: 2.1,
        mapElementColorStyle: 'Color Borders',
    },
};

const UnconnectedAuthProvider = ({
    defaultUser = null,
    defaultTenant = null,
    defaultSsoMetadata = null,
    addNotification,
    children,
}: AuthProviderProps) => {
    const [hasWarnedOfExpiry, setHasWarnedOfExpiry] = useState(false);
    const [authState, dispatch] = useReducer(AuthReducer, {
        user: defaultUser,
        tenant: defaultTenant,
        ssoMetadata: defaultSsoMetadata,
        userSettings: userSettingsDefaultState,
    });

    const navigate = useNavigate();

    const setUserAuth = useCallback(
        (userAndTenant: Auth) => dispatch({ type: 'set_auth', payload: userAndTenant }),
        [],
    );

    const setUserSettingsLineThickness = useCallback(
        (lineThickness: number) =>
            dispatch({ type: 'set_user_settings_line_thickness', payload: lineThickness }),
        [],
    );

    const setUserSettingsColorStyle = useCallback(
        (colorStyle: string) =>
            dispatch({ type: 'set_user_settings_color_style', payload: colorStyle }),
        [],
    );

    const initializeTenant = useCallback(
        async (user: AuthenticatedUser | null) => {
            if (user?.tenants) {
                const tenantIdFromUrl = window.location.pathname.split('/').filter((p) => !!p)[0];
                let tenant = tenantIdFromUrl
                    ? user.tenants.find((t) => t.id === tenantIdFromUrl)
                    : user.tenants[0];

                let errorMessage = '';

                // Tenant Id was specified in the url but we couldn't find a matching tenant for the logged in user
                if (!tenant && tenantIdFromUrl) {
                    errorMessage = `You are not a member of the tenant with id ${tenantIdFromUrl}`;
                }

                //Check to see if the user is a member of any active tenants
                const hasActiveTenants = user.tenants.find((t) => !t.isExpired);
                if (!hasActiveTenants) {
                    errorMessage = `Your user account doesn't have access to any active tenants. They have been either expired or deactivated.`;
                    tenant = undefined;
                }

                if (tenant) {
                    // For expired tenants stick an error notification on the screen, this will also pop open the tenant selector
                    if (tenant.isExpired) {
                        errorMessage = `Your trial expired on ${tenant.expiresAt} for tenant ${tenant.developerName} and has been deactivated.`;

                        tenant = undefined;
                    }

                    if (tenant) {
                        try {
                            // Check that we have definitive access to the tenant i.e. not blocked by ip restrictions
                            await allowed(tenant.id);

                            // If there wasn't a tenant id in the url but the user is a member of at least one non-expired tenant then
                            // navigate to the flows page
                            if (!tenantIdFromUrl && hasActiveTenants) {
                                navigate(`/${hasActiveTenants.id}/flows`);
                            }
                        } catch (_ex) {
                            errorMessage = `Your user account doesn't have access to the Tenant ${tenant.developerName}`;
                            tenant = undefined;
                        }
                    }
                }

                if (!tenant) {
                    window.history.replaceState({}, document.title, '');
                }

                if (errorMessage !== '') {
                    addNotification({
                        type: NOTIFICATION_TYPES.error,
                        message: errorMessage,
                        isPersistent: true,
                    });
                }
                return tenant;
            }

            return undefined;
        },
        [addNotification, navigate],
    );

    const initializeUser = useCallback(async () => {
        // Clean up legacy 'flowUser' removed in FLOW-3303
        window.sessionStorage.removeItem('flowUser');

        let user = null;
        const isSsoBookmark = new URLSearchParams(window.location.search).get('sso');
        try {
            // Attempt to get the currently logged in user. If this fails then the cookie they have is no good
            // or they don't have a cookie
            user = await getUser();
        } catch (_ex) {
            setUserAuth({
                ...authState,
                user: { refreshing: false },
                tenant: null,
                ssoMetadata: null,
            });
        }

        if (user) {
            const userSettings = await fetchUserSettings();
            const authorizedTenant = await initializeTenant(user);

            if (authorizedTenant) {
                initializePendo(user, authorizedTenant);
                setUserAuth({
                    user: { ...user, refreshing: false },
                    tenant: authorizedTenant,
                    ssoMetadata: getSSOMetadata(),
                    userSettings,
                });
            } else {
                setUserAuth({
                    user: {
                        ...user,
                        refreshing: false,
                    },
                    tenant: null,
                    ssoMetadata: null,
                    userSettings,
                });
            }
        } else {
            //if an sso user bookmarks flow after logging in, we don't want them to hit the main login screen.
            if (
                isSsoBookmark &&
                window.location.pathname !== '/logged-out' &&
                redirectUserToSsoLogin()
            ) {
                return;
            }
            // Stops the page continually refreshing if no flow user is found with the Okta details provided.
            if (
                window.location.pathname !== '/user-not-found' &&
                window.location.pathname !== '/logged-out'
            ) {
                await getWithRedirect({
                    scopes: ['openid', 'email', 'profile'],
                    state:
                        window.location.pathname.length > 0
                            ? `{ "origin": "${
                                  ENGINE_API_URL || window.location.origin
                              }", "redirect": "${window.location.pathname}" }`
                            : '',
                });
            }
        }
    }, [authState, initializeTenant, setUserAuth]);

    const fetchUser = useCallback(async () => {
        try {
            const fetchedUser = await getUser();
            const authorizedTenant = (await initializeTenant(fetchedUser)) as UserTenantResponseAPI;
            const userSettings = await fetchUserSettings();
            setUserAuth({
                user: fetchedUser,
                tenant: authorizedTenant ?? null,
                ssoMetadata: getSSOMetadata(),
                userSettings,
            });
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    }, [addNotification, initializeTenant, setUserAuth]);

    const fetchUserSettings = useCallback(async () => {
        let userSettings = authState.userSettings;
        try {
            userSettings = await getUserSettings();
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        }

        return {
            canvasSettings: userSettings.canvasSettings ?? authState.userSettings?.canvasSettings,
        };
    }, [addNotification, authState]);

    const signOutUser = useCallback(async () => {
        if (authState !== null && authState.tenant !== null && authState.tenant.isSso === true) {
            let { redirectUri } = await signOutSSO(authState.tenant.id);
            if (isNullOrEmpty(redirectUri)) {
                redirectUri = '/logged-out';
            }
            window.location.assign(redirectUri);
        } else {
            await signOut();
            await oktaSignOut();
        }
    }, [authState]);

    const switchTenant = useCallback(
        async (tenantId: string) => {
            const user = await getUser();
            const tenant = user.tenants.find((t) => t.id === tenantId);

            if (!tenant && tenantId) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: `You are not a member of the tenant with id ${tenantId}`,
                    isPersistent: true,
                });

                return;
            }

            if (tenant) {
                reloadPage(`/${tenant.id}/flows`);
            }
        },
        [addNotification],
    );

    const getSSOMetadata = useCallback(() => {
        const cookies = document.cookie.split(';');
        const ssoInfoCookie = cookies.find((value) =>
            value.includes('boomi-flow-sso-metadata-session'),
        );
        if (ssoInfoCookie) {
            const cookieContent = ssoInfoCookie.split('=').pop();
            if (!isNullOrEmpty(cookieContent)) {
                return JSON.parse(decodeURIComponent(cookieContent)) as SSOMetadata;
            }
        }
        return null;
    }, []);

    const redirectUserToSsoLogin = useCallback(() => {
        const tenantId = window.location.pathname.split('/')[1];
        if (!isNullOrUndefined(tenantId)) {
            window.location.replace(
                `${
                    ENGINE_API_URL || window.location.origin
                }/api/draw/1/authentication/saml/${tenantId}`,
            );
            return true;
        }

        return false;
    }, []);

    const contextValue: AuthProviderContext = {
        user: authState.user,
        tenant: authState.tenant,
        ssoMetadata: authState.ssoMetadata,
        userSettings: authState.userSettings,
        initializeUser,
        signOutUser,
        fetchUser,
        switchTenant,
        addNotification,
        hasWarnedOfExpiry,
        setHasWarnedOfExpiry,
        getSSOMetadata,
        idleTimeoutKey: 'boomi_flow_sso_idle_timeout',
        setUserSettingsLineThickness,
        setUserSettingsColorStyle,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useAuth = (): AuthProviderContext => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};

const AuthProvider = connect(null, { addNotification })(UnconnectedAuthProvider);

export { AuthProvider, useAuth };

import { useState } from 'react';
import type { Breakpoint, DebugTabId } from '../../../../../types';
import ScreenRouter from '../../../../generic/ScreenRouter';
import AddBreakpoint from './AddBreakpoint';
import BreakpointList from './BreakpointList';

interface Props {
    screen?: DebugTabId;
}

const Breakpoints = (_: Props) => {
    const [screen, setScreen] = useState('LIST');
    const [breakpointToEdit, setBreakpointToEdit] = useState<Breakpoint | null>(null);

    const addNew = () => {
        setBreakpointToEdit(null);
        setScreen('DETAIL');
    };

    const backToList = () => {
        setScreen('LIST');
    };

    const edit = (breakpoint: Breakpoint) => {
        setBreakpointToEdit(breakpoint);
        setScreen('DETAIL');
    };

    return (
        <div data-testid="breakpoints" className="padding full-height">
            <ScreenRouter currentScreen={screen}>
                <BreakpointList edit={edit} addNew={addNew} screen="LIST" />
                <AddBreakpoint
                    breakpointToEdit={breakpointToEdit}
                    backToList={backToList}
                    screen="DETAIL"
                />
            </ScreenRouter>
        </div>
    );
};

export default Breakpoints;

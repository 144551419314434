import { useId } from 'react';
import '../../../../../../css/builder/page-builder.less';
import type { AddNotification, Tab } from '../../../../types';
import { PageEditorProvider } from '../PageEditorProvider';
import Main from './Main';
import { ComposerProvider } from './composer/ComposerProvider';

interface Props {
    addNotification: AddNotification;
    updateTab: (tab: Tab) => void;
    updateUrlAndTab: ({
        key,
        type,
        title,
        elementId,
        tenantId,
    }: {
        key: string;
        type: string;
        title: string | null;
        elementId: string;
        tenantId: string;
    }) => void;
    tabConfig: Tab;
}

const PageEditor = ({ addNotification, updateTab, updateUrlAndTab, tabConfig }: Props) => {
    const containerId = useId();

    const pageBuilderClass = tabConfig.isActive
        ? 'nextgen-page-builder admin active'
        : 'nextgen-page-builder';

    return (
        <PageEditorProvider
            addNotification={addNotification}
            updateTab={updateTab}
            containerId={containerId}
        >
            <ComposerProvider updateUrlAndTab={updateUrlAndTab}>
                <div id={containerId} className={pageBuilderClass} data-tab-content={tabConfig.key}>
                    <Main tabConfig={tabConfig} />
                </div>
            </ComposerProvider>
        </PageEditorProvider>
    );
};

export default PageEditor;

import { isNullOrEmpty } from '../../../../../../utils/guard';
import { TOGGLE_CATEGORY_OPTIONS } from '../../../../constants';
import translations from '../../../../../../translations';
import FormGroup from '../../../../../generic/FormGroup';
import ValueSelectorModal from '../../../../../values/selector/ValueSelectorModal';
import { getSharedStyles } from '../../../../../../utils';
import Select, { type SingleValue } from 'react-select';
import type {
    ContentType,
    ValueElementIdAPI,
    ValueElementIdReferenceAPI,
} from '../../../../../../types';

interface Props {
    selectedConfigType?: string | undefined;
    componentOptions?: { label: string; value: string }[];
    propertyOptions?: { label: string; value: string }[];
    selectedComponent?: { label: string; value: string } | null;
    selectedProperty?: { label: string; value: string } | null;
    selectedValue?: ValueElementIdAPI | null;
    updateSelectedComponent?: (
        newComponent: SingleValue<{ label: string; value: string }> | null,
    ) => void;
    updateSelectedProperty?: (newProperty: SingleValue<{ label: string; value: string }>) => void;
    updateSelectedValue?: (value: ValueElementIdReferenceAPI | null) => void;
    hasSubmitted: boolean;
    identifier: string;
    contentTypeFilter?: ContentType | null | undefined;
    container?: HTMLElement | null | undefined;
}

const ComponentOrValueConfig = ({
    selectedConfigType,
    componentOptions,
    propertyOptions,
    selectedComponent,
    selectedProperty,
    selectedValue,
    updateSelectedComponent,
    updateSelectedProperty,
    updateSelectedValue,
    hasSubmitted,
    identifier, // used to generate unique labels, input and test IDs
    contentTypeFilter,
    container,
}: Props) => {
    const showComponent =
        selectedConfigType === undefined ||
        selectedConfigType === TOGGLE_CATEGORY_OPTIONS['COMPONENT'] ||
        selectedConfigType === 'ONLY_COMPONENT';
    const showProperty =
        selectedConfigType === undefined ||
        selectedConfigType === TOGGLE_CATEGORY_OPTIONS['COMPONENT'] ||
        selectedConfigType === 'ONLY_PROPERTY';
    const showValue = !(showComponent || showProperty);

    return (
        <>
            {showComponent && updateSelectedComponent && componentOptions && (
                <FormGroup
                    labelId={`${identifier}-component-label`}
                    label="Component"
                    htmlFor={`${identifier}-component`}
                    isValid={!isNullOrEmpty(selectedComponent)}
                    validationMessage={
                        translations.PAGE_BUILDER_field_is_required_validation_message
                    }
                    showValidation={hasSubmitted}
                    isRequired
                >
                    <Select
                        inputId={`${identifier}-component`}
                        className="select-field"
                        styles={getSharedStyles<{ label: string; value: string }>()}
                        options={componentOptions}
                        onChange={(newComponent) => updateSelectedComponent(newComponent)}
                        placeholder="Select a component"
                        value={selectedComponent || null}
                        noOptionsMessage={() => 'No results found'}
                        isClearable
                    />
                </FormGroup>
            )}
            {showProperty && updateSelectedProperty && propertyOptions && (
                <FormGroup
                    labelId={`${identifier}-property-label`}
                    label="Property"
                    htmlFor={`${identifier}-property`}
                    isValid={!isNullOrEmpty(selectedProperty)}
                    validationMessage={
                        translations.PAGE_BUILDER_field_is_required_validation_message
                    }
                    showValidation={hasSubmitted}
                    isRequired
                >
                    <Select
                        inputId={`${identifier}-property`}
                        className="select-field"
                        styles={getSharedStyles<{ label: string; value: string }>()}
                        value={selectedProperty || null}
                        options={propertyOptions}
                        onChange={(newProperty) => updateSelectedProperty(newProperty)}
                        placeholder="Select a component property"
                        noOptionsMessage={() => 'No results found'}
                        isClearable
                    />
                </FormGroup>
            )}
            {showValue && updateSelectedValue && (
                <FormGroup
                    labelId={`${identifier}-value-label`}
                    label="Value"
                    htmlFor={`${identifier}-value`}
                    isValid={!isNullOrEmpty(selectedValue)}
                    validationMessage={
                        translations.PAGE_BUILDER_field_is_required_validation_message
                    }
                    showValidation={hasSubmitted}
                    isRequired
                >
                    <ValueSelectorModal
                        onChangeAsValueReference={updateSelectedValue}
                        value={selectedValue || null}
                        contentType={contentTypeFilter || null}
                        includeSystemValues={true}
                        container={container}
                    />
                </FormGroup>
            )}
        </>
    );
};

export default ComponentOrValueConfig;

import { pathOr } from 'ramda';
import { type ReactNode, useState } from 'react';
import Breadcrumb from '../../../components/Breadcrumb';
import { isNullOrEmpty } from '../../../utils/guard';
import type { ServiceViews } from '../ServiceConstants';
import type { SwitchToRefreshServiceConfig } from '../ServiceController';
import { type NewServiceData, useServices } from '../contextProviders/ServicesProvider';
import NewCustomService from './NewCustomService';
import NewFlowService from './NewFlowService';
import NewIntegrationService from './NewIntegrationService';
import NewServiceSelect, { type NewServiceType } from './NewServiceSelect';

interface NewServiceProps {
    selectServiceType: ({
        description,
        url,
        canHaveIdentity,
    }: {
        description: ReactNode;
        url: string | undefined;
        canHaveIdentity: boolean;
    }) => void;
    newServiceData: NewServiceData;
    setNewServiceDetails: (newServiceData: NewServiceData) => void;
    switchToRefreshServiceConfig: SwitchToRefreshServiceConfig;
    onCancel: (newView: ServiceViews) => void;
}

const NewService = ({
    selectServiceType,
    newServiceData,
    setNewServiceDetails,
    switchToRefreshServiceConfig,
    onCancel,
}: NewServiceProps) => {
    const [hasSubmitted, updateHasSubmitted] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<NewServiceType>({
        view: undefined,
        name: undefined,
        canHaveIdentity: false,
    });

    const { breadcrumbs } = useServices();

    const checkRequiredFields = () => {
        const requiredFields: {
            atomType?: string;
            baseUrl?: string;
            pathToService?: string;
            url?: string;
            name?: string;
        } = {};

        const isIntegrationService =
            selectedService.view === 'NewIntegrationService' && newServiceData.atomType !== 'Other';

        if (isIntegrationService) {
            requiredFields.atomType = pathOr('', ['atomType'], newServiceData);
            requiredFields.baseUrl = pathOr('', ['baseUrl'], newServiceData);
            requiredFields.pathToService = pathOr('', ['pathToService'], newServiceData);
        } else {
            requiredFields.url = pathOr('', ['url'], newServiceData);
        }

        requiredFields.name = pathOr('', ['name'], newServiceData);

        return requiredFields;
    };

    const onSave = () => {
        updateHasSubmitted(true);
        const isFormValid = Object.values(checkRequiredFields()).every(
            (field) => !isNullOrEmpty(field),
        );

        if (isFormValid) {
            switchToRefreshServiceConfig(
                newServiceData?.name,
                newServiceData?.baseUrl
                    ? newServiceData?.baseUrl + newServiceData?.pathToService
                    : newServiceData?.url,
                newServiceData?.httpAuthenticationUsername,
                newServiceData?.httpAuthenticationPassword,
                newServiceData?.comments,
                null,
                newServiceData?.httpAuthenticationClientCertificateReference,
                newServiceData?.httpAuthenticationClientCertificatePasswordReference,
                newServiceData?.identityProviderId,
            );
        }
    };

    const onServiceSelect = ({ name, description, url, view, canHaveIdentity }: NewServiceType) => {
        updateHasSubmitted(false);
        setSelectedService({ view, name, canHaveIdentity });
        selectServiceType({ description, url, canHaveIdentity });
    };

    const renderNewServiceScreen = () => {
        switch (selectedService.view) {
            case 'NewCustomService':
                return (
                    <NewCustomService
                        newServiceData={newServiceData}
                        setNewServiceDetails={setNewServiceDetails}
                        hasSubmitted={hasSubmitted}
                    />
                );
            case 'NewFlowService':
                return (
                    <NewFlowService
                        newServiceData={newServiceData}
                        setNewServiceDetails={setNewServiceDetails}
                        hasSubmitted={hasSubmitted}
                    />
                );
            case 'NewIntegrationService':
                return (
                    <NewIntegrationService
                        newServiceData={newServiceData}
                        setNewServiceDetails={setNewServiceDetails}
                        hasSubmitted={hasSubmitted}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="full-height flex-column">
            <div className="admin-page">
                <div className="margin-bottom" data-testid="connector-breadcrumbs">
                    <Breadcrumb trail={breadcrumbs.trail} activeItemId={breadcrumbs.activeItemId} />
                </div>
                <NewServiceSelect
                    selectedService={selectedService.name}
                    selectServiceType={onServiceSelect}
                />
                {renderNewServiceScreen()}
            </div>
            <div className="admin-footer">
                <button
                    type="button"
                    className="btn btn-default outcome"
                    onClick={() => onCancel('ServiceList')}
                >
                    {'Back'}
                </button>
                {selectedService.name ? (
                    <button onClick={onSave} className="btn btn-primary outcome" type="button">
                        {'Retrieve Connector Configuration Data'}
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default NewService;

import FormGroup from '../../generic/FormGroup';
import ServiceAuthentication from '../authentication/ServiceAuthentication';
import translations from '../../../translations';
import { OTHER_ATOM_CLOUD, ATOM_CLOUDS } from '../ServiceConstants';
import type { NewServiceData } from '../contextProviders/ServicesProvider';
import type { ServiceElementResponseAPI } from '../../../types/service';
import { isNullOrEmpty } from '../../../utils/guard';

interface NewIntegrationServiceProps {
    newServiceData: NewServiceData;
    setNewServiceDetails: (newServiceData: NewServiceData) => void;
    hasSubmitted: boolean;
}

/**
 * Allow the user to install an Integration Flow Service, deployed on the Production cloud, Test cloud, or anywhere else.
 * This screen guides them to where to find the credentials they need, then auto-fills the baseUrl when possible.
 * @param {Object} newServiceData = {} - Object containing all the data about the new service being created
 * @param {Function} setNewServiceDetails = {} - Function on ServiceController that allows us to update the newServiceData
 * @param {Boolean} hasSubmitted
 */
const NewIntegrationService = ({
    newServiceData,
    setNewServiceDetails,
    hasSubmitted,
}: NewIntegrationServiceProps) => {
    const ATOM_TYPES = ATOM_CLOUDS.concat([
        {
            name: OTHER_ATOM_CLOUD,
            baseUrl: '',
        },
    ]);

    const onAtomTypeChange = (atomType: string) => {
        // Clear the path field if atom type is "Other", as is not applicable
        const pathToService = atomType === OTHER_ATOM_CLOUD ? '' : newServiceData.pathToService;

        // Clear the url field if atom type is not "Other", as is not applicable
        const url = atomType !== OTHER_ATOM_CLOUD ? '' : newServiceData.url;

        setNewServiceDetails({
            ...newServiceData,
            atomType,
            pathToService,
            url,
            baseUrl: ATOM_TYPES.find((type) => type.name === atomType)?.baseUrl,
        });
    };

    const selectedAtomType = ATOM_TYPES.find((type) => type.name === newServiceData.atomType);
    const isCustomAtomType = selectedAtomType && selectedAtomType.name === OTHER_ATOM_CLOUD;
    const inputFields = selectedAtomType ? (
        <>
            {isCustomAtomType ? (
                <FormGroup
                    isRequired
                    htmlFor="integration-service-url"
                    label="Url"
                    validationMessage={translations.SC_url_field_validation_message}
                    isValid={!isNullOrEmpty(newServiceData.url)}
                    showValidation={hasSubmitted}
                >
                    <input
                        id="integration-service-url"
                        key="url"
                        className="form-control form-control-long"
                        onChange={({ target }) =>
                            setNewServiceDetails({
                                ...newServiceData,
                                url: target.value,
                            })
                        }
                        value={newServiceData?.url}
                    />
                </FormGroup>
            ) : (
                <>
                    <FormGroup htmlFor="integration-base-url" label="Base Url">
                        <input
                            id="integration-base-url"
                            key="baseUrl"
                            className="form-control form-control-long"
                            value={selectedAtomType.baseUrl}
                            disabled
                        />
                    </FormGroup>
                    <FormGroup
                        isRequired
                        htmlFor="integration-path"
                        label="Path To Service"
                        validationMessage={translations.SC_path_field_validation_message}
                        isValid={!isNullOrEmpty(newServiceData.pathToService)}
                        showValidation={hasSubmitted}
                    >
                        <input
                            id="integration-path"
                            className="form-control form-control-long"
                            onChange={({ target }) =>
                                setNewServiceDetails({
                                    ...newServiceData,
                                    pathToService: target.value,
                                })
                            }
                            value={newServiceData.pathToService ? newServiceData.pathToService : ''}
                        />
                        <span className="help-block">
                            Found on the Flow Service component in Boomi Integration e.g.
                            /fs/SampleService
                        </span>
                    </FormGroup>
                </>
            )}

            <ServiceAuthentication
                serviceData={newServiceData as ServiceElementResponseAPI}
                onSetServiceData={setNewServiceDetails}
            />
        </>
    ) : null;

    return (
        <>
            <h1>New Boomi Integration Service</h1>
            <h4>Install a Flow Service from Boomi Integration</h4>
            <FormGroup
                isRequired
                htmlFor="integration-service-name"
                label="Name"
                validationMessage={translations.SC_name_field_validation_message}
                isValid={!isNullOrEmpty(newServiceData.name)}
                showValidation={hasSubmitted}
            >
                <input
                    id="integration-service-name"
                    className="form-control form-control-long"
                    onChange={({ target }) =>
                        setNewServiceDetails({
                            ...newServiceData,
                            name: target.value,
                        })
                    }
                    value={newServiceData.name ? newServiceData.name : ''}
                />
            </FormGroup>
            <FormGroup
                isRequired
                htmlFor="atom-type"
                label="Atom Type"
                validationMessage={translations.SC_atom_field_validation_message}
                isValid={!isNullOrEmpty(newServiceData.atomType)}
                showValidation={hasSubmitted}
            >
                <select
                    id="atom-type"
                    className="form-control form-control-long"
                    value={newServiceData.atomType}
                    onChange={({ target }) => onAtomTypeChange(target.value)}
                >
                    <option key="" value="">
                        Please select an Atom type
                    </option>
                    {ATOM_TYPES.map((region) => (
                        <option key={region.name} value={region.name}>
                            {region.name}
                        </option>
                    ))}
                </select>
            </FormGroup>
            {inputFields}
        </>
    );
};

export default NewIntegrationService;

import type { ComposerElement } from '../../../../../../../types';
import ContainerSection from './ContainerSection';
import ChartSection from './chartContainerConfiguration/ChartSection';
import ScreenRouter from '../../../../../../generic/ScreenRouter';

const ContainerRouter = ({ container }: { container: ComposerElement | null | undefined }) => {
    const { containerType } = container as { containerType: string };
    const containerScreen = containerType.toUpperCase() === 'CHARTS' ? 'CHART' : 'OTHER';

    if (!container) {
        return null;
    }

    return (
        <ScreenRouter currentScreen={containerScreen}>
            <ContainerSection screen="OTHER" container={container} />
            <ChartSection screen="CHART" container={container} />
        </ScreenRouter>
    );
};

export default ContainerRouter;

import { ButtonFlavor, ButtonSize, ButtonType, ExButton, ExStructuredList } from '@boomi/exosphere';
import type { Breakpoint as BreakpointType } from '../../../../../types';
import { useDebug } from '../../DebugProvider';

import Breakpoint from './Breakpoint';
import EmptyBreakpointList from './EmptyBreakpointList';

interface Props {
    addNew: () => void;
    edit: (breakpoint: BreakpointType) => void;
    screen?: string;
}

const BreakpointList = ({ addNew, edit }: Props) => {
    const { breakpoints, removeBreakpoint, callStack } = useDebug();

    const checkIsHit = (breakpointId: string): boolean => {
        const breakpointsHit = callStack.find((csi) => csi.isSelected)?.breakpointsHit;
        return !!breakpointsHit?.find((bph) => bph.breakpointId === breakpointId);
    };

    return (
        <>
            {breakpoints.length ? (
                <div data-testid="breakpoint-list">
                    <ExStructuredList className="breakpoint-list">
                        {breakpoints.map((breakpoint) => (
                            <Breakpoint
                                key={breakpoint.id}
                                breakpoint={breakpoint}
                                removeBreakpoint={removeBreakpoint}
                                edit={edit}
                                isHit={checkIsHit(breakpoint.id)}
                            />
                        ))}
                    </ExStructuredList>
                    <div className="flex margin-top">
                        <ExButton
                            flavor={ButtonFlavor.BRANDED}
                            type={ButtonType.SECONDARY}
                            size={ButtonSize.DEFAULT}
                            onClick={addNew}
                        >
                            Add Assertion
                        </ExButton>
                    </div>
                </div>
            ) : (
                <EmptyBreakpointList addBreakpoint={addNew} />
            )}
        </>
    );
};

export default BreakpointList;

import { pathOr } from 'ramda';
import { connect } from 'react-redux';
import {
    updateReportingEndpoint,
    updateServiceInvokerLogging,
} from '../../../js/actions/reduxActions/currentTenant';
import translations from '../../translations';
import Toggle from '../inputs/Toggle';

interface ReportingProps {
    updateServiceInvokerLogging: typeof updateServiceInvokerLogging;
    updateReportingEndpoint: typeof updateReportingEndpoint;
    disableServiceInvokerLogging: boolean;
    endpoint: string;
}

const Reporting = ({
    updateServiceInvokerLogging,
    updateReportingEndpoint,
    disableServiceInvokerLogging,
    endpoint,
}: ReportingProps) => {
    return (
        <>
            <div className="form-group">
                <label htmlFor="reporting-endpoint">
                    {translations.TENANT_reporting_endpoint_url_input_label}
                </label>
                <input
                    id="reporting-endpoint"
                    className="form-control reporting-endpoint"
                    maxLength={500}
                    value={endpoint}
                    onChange={({ target }) => updateReportingEndpoint(target.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="reporting-enable-logging-toggle">
                    <Toggle
                        id="reporting-enable-logging-toggle"
                        className="reporting-enable-logging"
                        isOn={!disableServiceInvokerLogging}
                        onChange={({ isOn }) => updateServiceInvokerLogging(isOn)}
                    />
                    {translations.TENANT_reporting_enable_connector_logging_toggle_label}
                </label>
            </div>
        </>
    );
};

const mapStateToProps = ({ currentTenant }: Record<string, unknown>) => ({
    disableServiceInvokerLogging: pathOr(
        false,
        ['tenantSettings', 'disableServiceInvokerLogging'],
        currentTenant,
    ),
    endpoint: pathOr('', ['stateSettings', 'endpoint'], currentTenant),
});

const mapDispatchToProps = {
    updateServiceInvokerLogging,
    updateReportingEndpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);

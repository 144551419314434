import { memo, useState } from 'react';
import { ButtonFlavor, ButtonType, ExButton, ExInput } from '@boomi/exosphere';
import translations from '../../../translations';
import GenericModal from '../../generic/modal/GenericModal';
import { setLatest } from '../../../sources/flow';
import { getTenantId } from '../../../utils/tenant';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { TAB_TYPES } from '../../../constants';
import type { NotifyError } from '../../../types';

interface Props {
    container: HTMLDivElement | null;
    tabKey: string;
    updateUrlAndTab: (
        {
            key,
            type,
            title,
            elementId,
            tenantId,
        }: {
            key: string;
            type: string;
            title: string | null;
            elementId: string;
            tenantId: string;
        },
        navigate: NavigateFunction,
    ) => void;
    closeTab: (key: string, tenantId: string, navigate: NavigateFunction) => void;
    notifyError: NotifyError;
}

const NewFlowModal = ({ container, tabKey, updateUrlAndTab, closeTab, notifyError }: Props) => {
    const [flowName, setFlowName] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const navigate = useNavigate();

    const onCancel = () => {
        closeTab(tabKey, getTenantId(), navigate);
    };

    const onSave = async () => {
        setHasSubmitted(true);

        if (flowName) {
            try {
                const response = await setLatest({
                    id: null,
                    developerName: flowName,
                    developerSummary: '',
                    startMapElementId: null,
                    allowJumping: false,
                    enableHistoricalNavigation: false,
                    stateExpirationLength: 0,
                    idleStateExpirationLength: 0,
                    authorization: {
                        serviceElementId: null,
                        globalAuthenticationType: 'PUBLIC',
                        streamBehaviourType: 'NONE',
                        showPagesAsReadOnly: false,
                        groups: [],
                        users: [],
                        locations: [],
                    },
                });

                updateUrlAndTab(
                    {
                        key: tabKey,
                        type: TAB_TYPES.flow,
                        title: response.developerName,
                        elementId: response.id.id || '',
                        tenantId: getTenantId(),
                    },
                    navigate,
                );
            } catch (error) {
                // Catch error and notify
                notifyError(error as Error);
            }
        }
    };

    return (
        <GenericModal
            show={true}
            onHide={onCancel}
            title={translations.FLOW_CREATE_NEW}
            container={container}
            renderBody={() => (
                <ExInput
                    data-testid="flow-name-input"
                    label="Name"
                    value={flowName}
                    onChange={({ target }) => {
                        setFlowName((target as HTMLInputElement).value);
                    }}
                    required={true}
                    invalid={hasSubmitted && !flowName}
                    error-msg="This field is required."
                    type="text"
                    autofocus={true}
                />
            )}
            className="config-modal"
            bodyClassName="auto-height"
            footerClassName="buttons-right"
            renderFooter={() => (
                <>
                    <ExButton
                        type={ButtonType.SECONDARY}
                        flavor={ButtonFlavor.BRANDED}
                        onClick={onCancel}
                    >
                        Cancel
                    </ExButton>
                    <ExButton
                        type={ButtonType.PRIMARY}
                        flavor={ButtonFlavor.BRANDED}
                        onClick={onSave}
                    >
                        Save
                    </ExButton>
                </>
            )}
        />
    );
};

export default memo(NewFlowModal);

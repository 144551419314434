import translations from '../../../../../ts/translations';
import FormGroup from '../../../../../ts/components/generic/FormGroup';

const NameInput = ({
    // FormGroup props
    isValid,
    showValidation,
    // input props
    id,
    name,
    onUpdateName,
}) => (
    <FormGroup
        label={translations.MAP_ELEMENT_name_field_label}
        htmlFor={id}
        isRequired
        isValid={isValid}
        validationMessage={translations.MAP_ELEMENT_name_field_validation_message}
        showValidation={showValidation}
    >
        <input
            autoFocus
            id={id}
            data-testid={id}
            value={name}
            onChange={(e) => onUpdateName(e.target.value)}
            maxLength="255"
            required
            className="form-control form-control-width"
            type="text"
        />
    </FormGroup>
);

export default NameInput;

import { getValueList } from '../../../../../ts/sources/value';
import { guid } from '../../../../../ts/utils/guid';

/**
 * @param {String} access Value access type (INPUT, OUTPUT or INPUT_OUTPUT)

 * @param {String} tenantId
 * @param {String} subFlowId
 * @param {Array} valueReferences Value element references returned from api
 * @param {Array} subFlowArguments The raw arguments metadata for the subflow map element
 *
 * @description Returns an array of objects that represents subflow arguments to be rendered.
 */
export const generateSubFlowArgumentSelectors = async (
    access,
    _tenantId,
    subFlowId,
    valueReferences,
    subFlowArguments,
) => {
    const params = { access, flow: subFlowId };

    try {
        // This tells us what values the subflow will accept as arguments
        const formalValueRefs = await getValueList(params);

        return formalValueRefs
            .filter((value) => !value.typeElementPropertyId)
            .map((value) => {
                // Grab the corresponding argument from the subflow metadata
                const arg = subFlowArguments
                    ? subFlowArguments.find((a) => a.valueElementInSubflowId.id === value.id)
                    : null;

                // Now find the value that will be passed by mapping the argument ID to the value ID
                const actualArgument = arg
                    ? valueReferences.find(
                          (avf) =>
                              avf.id === arg.valueElementToApplyId.id &&
                              avf.typeElementPropertyId ===
                                  arg.valueElementToApplyId.typeElementPropertyId,
                      )
                    : null;

                // Return an object that we can give to a value selector
                return {
                    // Should we display the value picker modal?
                    isOpen: false,
                    id: guid(),

                    // The value access type (used for filter what values to display)
                    access,

                    // The name of the value argument from inside the subflow
                    formalValueName: value.developerName,

                    // The ID of the value argument from inside the subflow
                    formalValueId: value.id,

                    // The type element property ID of the value argument from inside the subflow
                    formalValueTypeElementPropertyId: value.typeElementPropertyId,

                    // The formatted value metatdata used for displaying a selected value
                    // in the value selector component. In this case this would be the
                    // value selected for passing to/from the parent flow to the child flow
                    selectedValueMeta: actualArgument,
                };
            });
    } catch (error) {
        throw Error(error.message);
    }
};

/**
 * @param {Array} valueReferences Value element references returned from api
 * @param {Function} onMatch Callback for setting matched/unmatched arguments in root component state
 * @param {Array} subFlowArgumentsToBeRendered The subflow arguments formatted as such to tell
 * the UI how to render the argument e.g how to display the value selector etc
 *
 * @description Either matches or unmatches the value the subflow accepts with value
 * to be passed to/from the subflow.
 */
export const matchAllSubFlowArguments = (
    valueReferences,
    onMatch,
    subFlowArgumentsToBeRendered,
) => {
    const selectedValuesToBeMatched = subFlowArgumentsToBeRendered.map(
        (subFlowArgumentToBeRendered) => {
            const matchingValue = valueReferences.find(
                (value) =>
                    value.id === subFlowArgumentToBeRendered.formalValueId &&
                    value.typeElementPropertyId ===
                        subFlowArgumentToBeRendered.formalValueTypeElementPropertyId,
            );

            return {
                ...subFlowArgumentToBeRendered,
                selectedValueMeta: matchingValue,
            };
        },
    );

    onMatch(selectedValuesToBeMatched, selectedValuesToBeMatched.length);
};

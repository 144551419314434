import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../ts/components/buttons/ButtonPrimary';
import translations from '../../../../../ts/translations';

const Footer = ({ save, saveButtonText, cancel, cancelButtonText }) => {
    return (
        <>
            <ButtonDefault className="flex-child-right" onClick={cancel}>
                {cancelButtonText ?? translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={save}>
                {saveButtonText ?? translations.GRAPH_config_panel_save}
            </ButtonPrimary>
        </>
    );
};

export default Footer;

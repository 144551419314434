import { useCallback, useEffect, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { notifyError as notifyErrorAction } from '../../../js/actions/reduxActions/notification';
import {
    getFeatureFlags,
    enableFeatureFlag as toggleFeatureFlag,
} from '../../sources/featureFlags';
import translations from '../../translations';
import type { FeatureFlagAPI } from '../../types';
import Toggle from '../inputs/Toggle';
import Loader from '../loader/Loader';
import FormGroup from '../generic/FormGroup';

const mapDispatchToProps = { notifyError: notifyErrorAction };

const connector = connect(null, mapDispatchToProps);

type FeaturesProps = ConnectedProps<typeof connector>;

const Features = ({ notifyError }: FeaturesProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [features, setFeatures] = useState<FeatureFlagAPI[]>([]);

    const fetchFeatureFlags = useCallback(async () => {
        try {
            setIsLoading(true);
            const newFeatures = await getFeatureFlags();
            setFeatures(newFeatures);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    }, [notifyError]);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                await fetchFeatureFlags();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [fetchFeatureFlags, notifyError]);

    const sortFeatures = (first: FeatureFlagAPI, second: FeatureFlagAPI) => {
        // Can't compare if either name is null
        if (!(first.name && second.name)) {
            return 0;
        }

        return first.name.localeCompare(second.name);
    };

    const handleChange = async (id: FeatureFlagAPI['id'], state: boolean) => {
        if (!id) {
            return;
        }

        try {
            setIsLoading(true);

            await toggleFeatureFlag({ featureFlagId: id, enabled: state });

            const updatedFeatures = features.map((feature) => {
                if (feature.id === id) {
                    return {
                        ...feature,
                        enabled: state,
                    };
                }

                return feature;
            });

            setFeatures(updatedFeatures);

            await fetchFeatureFlags();
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <span className="admin-transition">
            <div className="flex-column full-height">
                <div className="admin-page">
                    <h1>{translations.FEATURES_heading}</h1>
                    <p>{translations.FEATURES_description}</p>
                    {features.sort(sortFeatures).map((feature) => {
                        const id = `feature-${feature.id}`;
                        return (
                            <div key={feature.id} className="margin-bottom">
                                <FormGroup
                                    htmlFor={id}
                                    label={feature.name ?? ''}
                                    labelPosition="after"
                                >
                                    <Toggle
                                        id={id}
                                        isOn={!!feature.enabled}
                                        onChange={({ isOn }) => handleChange(feature.id, isOn)}
                                    />
                                </FormGroup>
                                <p>{feature.description}</p>
                            </div>
                        );
                    })}
                    {isLoading && <Loader />}
                </div>
            </div>
        </span>
    );
};

export default connector(Features);

import translations from '../../translations';
import type { UserTenantResponseAPI } from '../../types';
import CurrentTenantInviteList from './ReceivedTenantInvites';
import CurrentUserInviteList from './ReceivedUserInvites';

type NonAdminUI = {
    tenant: UserTenantResponseAPI | null;
};

const NonAdminUI = ({ tenant }: NonAdminUI) => {
    return (
        <div data-testid="non-admin-ui" className="flow-organization admin-page">
            {tenant?.organization ? (
                <>
                    <h2>{translations.FORG_non_admin_joined_tenant_heading}</h2>
                    <p>
                        {translations.FORG_non_admin_joined_tenant_help_text}{' '}
                        <strong>{tenant.organization.name ?? ''}</strong>
                    </p>
                </>
            ) : (
                <h2>{translations.FORG_non_admin_not_joined_tenant_heading}</h2>
            )}
            <CurrentTenantInviteList />
            <CurrentUserInviteList />
        </div>
    );
};

export default NonAdminUI;

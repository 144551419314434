import Glyphicon from '../../../generic/Glyphicon';
import Presence from '../../../collaboration/Presence';
import {
    COLOUR_DANGER,
    getElementStyles,
} from '../../../../../js/components/graph/elements/elementStyles';
import { type IconProps, XCircle } from '@phosphor-icons/react';
import { useCollaboration } from '../../../../collaboration/CollaborationProvider';

interface Props {
    id: string;
    title: string;
    elementType: string;
    currentUserId?: string | undefined;
}

interface ElementStylesMinimum {
    border: {
        fill: string;
    };
    icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
}

const ConfigModalHeader = ({ id, title, elementType, currentUserId }: Props) => {
    let element = getElementStyles(elementType) as ElementStylesMinimum;

    if (elementType === 'delete_header') {
        element = {
            border: {
                fill: COLOUR_DANGER,
            },
            icon: XCircle,
        };
    }

    const backgroundFill = { background: element.border.fill };

    const { users, items } = useCollaboration();

    return (
        <>
            <span className="modal-element-header" data-testid="modal-element-header">
                <span className="modal-element-top-bar" style={backgroundFill} />
                <span className="modal-element-icon-background" style={backgroundFill}>
                    {typeof element.icon === 'string' ? (
                        <Glyphicon className="modal-element-icon" glyph={element.icon} />
                    ) : (
                        <span className="modal-element-icon glyphicon">
                            <element.icon size={17} />
                        </span>
                    )}
                </span>
                <h4 className="modal-element-title modal-title">{title}</h4>
                {currentUserId && (
                    <Presence
                        users={users}
                        presentUsers={items[id]?.users ?? []}
                        currentUserId={currentUserId}
                    />
                )}
            </span>
        </>
    );
};

export default ConfigModalHeader;

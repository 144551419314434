import { useCallback, useEffect, useState } from 'react';
import type { AddNotification } from '../../types';
import {
    deleteAsset,
    getAssets,
    createAsset,
    uploadAsset,
    getAssetInfo,
} from '../../sources/assets';
import type { AssetAPI } from '../../types/asset';
import Loader from '../loader/Loader';
import Table, { type TableColumnList } from '../generic/Table';
import { getSizeDisplay } from '../../utils/file';
import { getDateDisplay } from '../../utils/date';
import translations from '../../translations';
import { ArrowSquareOut, Trash } from '@phosphor-icons/react';
import SearchInput from '../generic/SearchInput';
import {
    ButtonFlavor,
    ButtonSize,
    ButtonType,
    ExButton,
    ExDialog,
    ExIcon,
    IconVariant,
} from '@boomi/exosphere';
import FileDrop from '../generic/upload/FileDrop';
import { isNullOrEmpty } from '../../utils/guard';
import { stringContains, stringReplace } from '../../utils/string';

interface AssetManagerProps {
    addNotification: AddNotification;
    allowedExtensions?: string | string[];
    onAssetSelect?: (asset: AssetAPI) => void;
    onAssetDelete?: (asset: AssetAPI) => void;
    filterListToAllowedExtensions?: boolean;
}

// AWS S3 key format is <guid>/<filename>
const getFilename = (asset: AssetAPI) => asset.key.slice(37);

const AssetManager = ({
    addNotification,
    allowedExtensions = [],
    onAssetSelect = () => undefined,
    onAssetDelete = () => undefined,
    filterListToAllowedExtensions = false,
}: AssetManagerProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState<AssetAPI | null>(null);
    const [assets, setAssets] = useState<AssetAPI[]>([]);
    const [search, setSearch] = useState('');
    const [maxAllowedFileSize, setMaxAllowedFileSize] = useState(0);
    const [apiAllowedExtensions, setApiAllowedExtensions] = useState<string[]>([]);

    // Ensure we always have a lower case list of allowedExtensions including the "."
    let fileTypeList = Array.isArray(allowedExtensions)
        ? allowedExtensions
        : allowedExtensions.split(',');
    //if there's nothing in this list then we should use the api passed values
    if (fileTypeList.length === 0) {
        fileTypeList = apiAllowedExtensions;
    }

    const updatedFileTypeList = fileTypeList.map((fileType) =>
        fileType.startsWith('.') ? fileType.toLowerCase() : `.${fileType.toLowerCase()}`,
    );

    const regexString = updatedFileTypeList.map((extension) => `(\\${extension}$)`).join('|');

    const fetchAssets = useCallback(async () => {
        const allowedExtensionsRegExp = new RegExp(regexString, 'i');
        setIsLoading(true);

        try {
            const allAssets = await getAssets();
            const filteredAssets = allAssets.filter((asset) =>
                filterListToAllowedExtensions
                    ? allowedExtensionsRegExp.test(asset.publicUrl)
                    : true,
            );

            setAssets(filteredAssets);
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).toString(),
            });
        } finally {
            setIsLoading(false);
        }
    }, [regexString, filterListToAllowedExtensions, addNotification]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        async function GetAllowedFileInfo(
            setApiAllowedExtensions: React.Dispatch<React.SetStateAction<string[]>>,
            setMaxAllowedFileSize: React.Dispatch<React.SetStateAction<number>>,
        ): Promise<void> {
            try {
                const info = await getAssetInfo();
                setApiAllowedExtensions(info.acceptableExtensions);
                setMaxAllowedFileSize(info.maxAllowedFileSize);
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).toString(),
                });
            }
        }
        GetAllowedFileInfo(setApiAllowedExtensions, setMaxAllowedFileSize);
    }, []);

    useEffect(() => {
        fetchAssets();
    }, [fetchAssets]);

    const onClickDeleteAsset = (asset: AssetAPI) => {
        setShowDeleteModal(true);
        setSelectedAsset(asset);
    };

    const onConfirmDeleteCancel = () => {
        setSelectedAsset(null);
        setShowDeleteModal(false);
    };

    const onConfirmDelete = async () => {
        if (selectedAsset) {
            try {
                await deleteAsset(getFilename(selectedAsset));
                onAssetDelete(selectedAsset);
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).toString(),
                });
            }

            await fetchAssets();
        }

        setShowDeleteModal(false);
    };

    const onDrop = async (files: File[]) => {
        if (isNullOrEmpty(files) || files.length === 0) {
            return;
        }

        setIsUploading(true);

        try {
            const assetUrls = await Promise.all(
                files.map((file) =>
                    createAsset({
                        fileType: file.type,
                        filename: file.name,
                        filesize: file.size,
                    }),
                ),
            );

            await Promise.all(
                assetUrls.map((assetUrl, index) => uploadAsset(assetUrl, files[index])),
            );

            await fetchAssets();
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).toString(),
            });
        }

        setIsUploading(false);
    };

    const columns: TableColumnList<AssetAPI> = [
        {
            renderHeader: () => 'File',
            renderCell: ({ item }) => getFilename(item),
        },
        {
            renderHeader: () => 'Size',
            renderCell: ({ item }) => getSizeDisplay(item.size),
        },
        {
            renderHeader: () => 'Last Modified',
            renderCell: ({ item }) => getDateDisplay(item.modifiedAt),
        },
        {
            renderHeader: () => 'Actions',
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <a
                        className="table-icon table-icon-edit"
                        title={`${translations.COMMON_open} ${getFilename(item)}`}
                        href={item.publicUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <ArrowSquareOut />
                    </a>
                    <button
                        title={`${translations.COMMON_delete} ${getFilename(item)}`}
                        className="table-icon table-icon-delete"
                        onClick={() => onClickDeleteAsset(item)}
                        aria-label={`${translations.COMMON_delete} ${getFilename(item)}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="asset-manger">
            {isUploading && <Loader />}

            <div className="margin-bottom-large">
                <FileDrop
                    onChange={onDrop}
                    fileTypes={fileTypeList.join(',')}
                    maxSize={maxAllowedFileSize}
                />
            </div>

            <SearchInput value={search} onChange={setSearch} />

            <Table<AssetAPI>
                wrapperClassName="margin-top"
                columns={columns}
                items={assets.filter((asset) => stringContains(getFilename(asset), search, false))}
                onRowSelect={(_, item): undefined => {
                    onAssetSelect(item);
                }}
            />

            {filterListToAllowedExtensions && !isNullOrEmpty(fileTypeList) ? (
                <span className="help-block">
                    {'Only showing assets of file type: '}
                    {fileTypeList.join(', ')}
                </span>
            ) : null}

            {showDeleteModal && selectedAsset !== null && (
                <ExDialog open hideClose dialogTitle="Delete this asset?">
                    {stringReplace(translations.ASSET_delete_confirmation_message, {
                        assetName: getFilename(selectedAsset),
                    })}
                    <ExIcon slot="icon" icon="trash" variant={IconVariant.DANGER} />
                    <ExButton
                        slot="footer"
                        flavor={ButtonFlavor.RISKY}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.LARGE}
                        onClick={onConfirmDelete}
                    >
                        Delete
                    </ExButton>
                    <ExButton
                        slot="footer"
                        flavor={ButtonFlavor.BASE}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.LARGE}
                        onClick={onConfirmDeleteCancel}
                    >
                        Cancel
                    </ExButton>
                </ExDialog>
            )}
        </div>
    );
};

export default AssetManager;

import type { ReactNode } from 'react';
import '../../../../css/page-wrapper.less';

interface PageWrapper {
    selector: string;
    title: string;
    breadcrumbs: ReactNode;
    menu: ReactNode;
    content: ReactNode;
}

/**
 * Generic layout component that renders all the building blocks of a page view
 * with breadcrumbs, side menu (optional) and page content
 *
 * @param selector Custom CSS class selector
 * @param title Title of the view (page) being rendered by this component
 * @param breadcrumbs Breadcrumbs UI component (renders breadcrumbs describing current page location)
 * @param menu Side menu UI component (renders the side menu for current page)
 * @param content Current page UI component (renders the current page content)
 */
const PageWrapper = ({ selector, title, breadcrumbs, menu, content }: PageWrapper) => (
    <div className={`page-wrapper ${selector}`} data-testid="page-wrapper">
        <div className="page-wrapper-breadcrumbs">{breadcrumbs}</div>
        <div className="page-wrapper-header">
            <h1 className="admin-heading-1">{title}</h1>
        </div>
        <div className="page-wrapper-main">
            {menu && <div className="page-wrapper-menu">{menu}</div>}
            <div className="page-wrapper-content">{content}</div>
        </div>
    </div>
);

export default PageWrapper;

import { getTenantId } from '../utils/tenant';
import TabContent from './tabs/TabContent';
import { useMatch, Navigate } from 'react-router-dom';

const Tenant = ({ isTenantSelectorOpen }: { isTenantSelectorOpen: boolean }) => {
    const noTabmatch = useMatch('/:tenantId');

    const tenantId = getTenantId();

    if (noTabmatch) {
        return <Navigate to={`${tenantId}/flows`} />;
    }

    return <TabContent adminTenantId={tenantId} isTenantSelectorOpen={isTenantSelectorOpen} />;
};

export default Tenant;

import { fetchAndParse } from '../../ts/utils/ajax';
import type { ItemCollectionResponse } from '../types';
import type { ServiceInvokerRequestAPI } from '../types/service';

// Fetching all the service invoker requests for a given state ID
export const getServiceInvokerRequestsByState = ({
    tenantId,
    stateId,
    page = 1,
    pageSize = 1000,
}: {
    tenantId: string;
    stateId: string;
    page: number;
    pageSize: number;
}) =>
    fetchAndParse<ItemCollectionResponse<ServiceInvokerRequestAPI>>({
        url: `/api/service/1/requests/state/${stateId}?page=${page}&pageSize=${pageSize}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

// Fetch the given service invoker request by ID
export const getServiceInvokerRequest = ({
    tenantId,
    requestId,
}: {
    tenantId: string;
    requestId: string;
}) =>
    fetchAndParse<ServiceInvokerRequestAPI>({
        url: `/api/service/1/requests/${requestId}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

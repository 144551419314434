import { useState, useEffect } from 'react';
import { getAllIntegrationAccounts } from '../../sources/tenant';
import {
    ExPageHeader,
    ExSelect,
    ExIcon,
    ExMenuItem,
    ExLoader,
    ExAlertBanner,
    ExButton,
    ExEmptyState,
    AlertBannerType,
    ButtonFlavor,
    ButtonType,
    ButtonSize,
    LoaderSize,
    IconVariant,
} from '@boomi/exosphere';
import './import-profile.css';
import translations from '../../translations';
import { useTypes } from './TypesProvider';
import Footer from '../generic/Footer';
import type { IntegrationProfileAPI, SelectOption, TypeElementResponseAPI } from '../../types';
import TypePropertyPicker from '../generic/TypePropertyPicker';
import { getAllProfiles, getAllTypesForProfile, saveType } from '../../sources/type';
import debounce from 'lodash.debounce';
import AsyncSelect from 'react-select/async';
import FormGroup from '../generic/FormGroup';
import type { SingleValue } from 'react-select';

type SelectedStringValueEvent = CustomEvent<{ value: string }>;

const byName = (a: IntegrationProfileAPI, b: IntegrationProfileAPI) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }

    return 0;
};

const ImportProfile = () => {
    const [accountsLoading, setAccountsLoading] = useState(true);
    const [accounts, setAccounts] = useState<string[]>([]);
    const [account, setAccount] = useState<string>('');

    const [profilesLoading, setProfilesLoading] = useState(false);
    const [profile, setProfile] = useState<SelectOption | null>(null);

    const [typesLoading, setTypesLoading] = useState(false);
    const [types, setTypes] = useState<TypeElementResponseAPI[]>([]);
    const [typesPropertyIds, setTypesPropertyIds] = useState<Record<string, string[]>>({});
    const [typesSaving, setTypesSaving] = useState(false);

    const { returnToTypeList, notifyError } = useTypes();

    const loadAccounts = async () => {
        setAccountsLoading(true);

        try {
            const result = await getAllIntegrationAccounts();
            setAccounts(result);

            if (result.length > 0 && result[0]) {
                setAccount(result[0] || '');
                setTypes([]);
            }
        } catch (error) {
            notifyError(error);
        } finally {
            setAccountsLoading(false);
        }
    };

    const loadProfileOptions = debounce(
        async (search: string, callback: (options: SelectOption[]) => void) => {
            setProfilesLoading(true);

            try {
                const results = await getAllProfiles(account, search);
                results.sort(byName);

                callback(
                    results.map<SelectOption>((profile) => ({
                        value: profile.id,
                        label: profile.name,
                    })),
                );
            } catch (error) {
                notifyError(error);
            } finally {
                setProfilesLoading(false);
            }
        },
        500,
    ) as (inputValue: string, callback: (options: SelectOption[]) => void) => void;

    const loadTypes = async (account: string, profile: string) => {
        setTypesLoading(true);

        try {
            const result = await getAllTypesForProfile(account, profile);
            setTypes(result.reverse());
        } catch (error) {
            notifyError(error);
        } finally {
            setTypesLoading(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Requires dedicated refactor
    useEffect(() => {
        loadAccounts();
    }, []);

    const onAccountChange = (e: SelectedStringValueEvent) => {
        setAccount(e.detail.value);
        setProfile(null);
        setTypes([]);
    };

    const onProfileChange = (value: SingleValue<SelectOption>) => {
        setProfile(value);
        setTypes([]);

        if (value !== null) {
            loadTypes(account, value.value as string);
        }
    };

    const onSelectedPropertiesChange = (typeElementId: string, propertyIds: string[]) => {
        setTypesPropertyIds({
            ...typesPropertyIds,
            [typeElementId]: propertyIds,
        });
    };

    const onSaveTypes = async () => {
        try {
            setTypesSaving(true);

            for (const type of [...types]) {
                await saveType({
                    ...type,
                    properties: typesPropertyIds[type.id]
                        ? type.properties.filter((property) =>
                              typesPropertyIds[type.id]?.includes(property.id),
                          )
                        : type.properties,
                });
            }

            returnToTypeList();
        } catch (error) {
            notifyError(error);
        } finally {
            setTypesSaving(false);
        }
    };

    let typesCards = null;
    if (types) {
        typesCards = types.map((type) => {
            const isInvalid = typesPropertyIds[type.id] && typesPropertyIds[type.id]?.length === 0;

            return (
                <div key={type.id} className="import-type">
                    <h5>{type.developerName}</h5>
                    {isInvalid ? (
                        <p className="invalid-type">
                            <ExIcon
                                className="error-icon"
                                icon="circle-warning"
                                variant={IconVariant.DANGER}
                            />{' '}
                            {translations.TYPES_import_profile_properties_invalid}
                        </p>
                    ) : null}
                    <TypePropertyPicker
                        value={type}
                        scalarPropertiesOnly={false}
                        onChange={(ids) => onSelectedPropertiesChange(type.id, ids)}
                    />
                </div>
            );
        });
    }

    const isImportDisabled =
        types.length === 0 ||
        typesSaving ||
        !!Object.values(typesPropertyIds).find((ids) => ids.length === 0);

    return (
        <>
            <div className="admin-page import-profiles">
                <ExPageHeader pageTitle={translations.TYPES_import_profile}>
                    <p slot="body">{translations.TYPES_import_profile_description}</p>
                </ExPageHeader>
                {!accountsLoading && accounts.length === 0 ? (
                    <ExAlertBanner
                        type={AlertBannerType.WARNING}
                        open={true}
                        className="ex-mb-large"
                    >
                        {translations.TYPES_import_profile_no_integration_accounts}
                    </ExAlertBanner>
                ) : null}
                {accountsLoading ? (
                    <ExLoader color="dark" />
                ) : (
                    <ExSelect
                        className="input-wide"
                        label={translations.TYPES_import_profile_account}
                        placeholder={translations.TYPES_import_profile_account_placeholder}
                        required={true}
                        selected={account}
                        onChange={onAccountChange}
                        data-testId="select-account"
                    >
                        {accounts.map((account) => (
                            <ExMenuItem key={account} value={account}>
                                {account}
                            </ExMenuItem>
                        ))}
                    </ExSelect>
                )}
                {!accountsLoading && account ? (
                    <FormGroup
                        label={translations.TYPES_import_profile_profile}
                        htmlFor="profile"
                        className="form-control-long"
                    >
                        <AsyncSelect
                            key={account}
                            inputId="profile"
                            isMulti={false}
                            isSearchable={true}
                            className="margin-bottom-small"
                            loadOptions={loadProfileOptions}
                            onChange={onProfileChange}
                            value={profile}
                            isLoading={profilesLoading}
                            placeholder={translations.TYPES_import_profile_profile_placeholder}
                            defaultOptions
                        />
                    </FormGroup>
                ) : null}
                {account && profile && types.length > 0 ? (
                    <h4>{translations.TYPES_import_profile_types}</h4>
                ) : null}
                {account && profile && !typesLoading && types.length === 0 ? (
                    <ExEmptyState
                        icon="integration"
                        label={translations.TYPES_import_profile_no_types_label}
                        text={translations.TYPES_import_profile_no_types_text}
                    />
                ) : null}
                {typesLoading ? (
                    <ExLoader
                        size={LoaderSize.LARGE}
                        label={translations.TYPES_import_profile_types_loading}
                    />
                ) : (
                    <div className="import-types">{typesCards}</div>
                )}
                {typesSaving ? (
                    <ExLoader
                        size={LoaderSize.LARGE}
                        label={translations.TYPES_import_profile_types_saving}
                    />
                ) : null}
            </div>
            <Footer>
                <ExButton
                    onClick={returnToTypeList}
                    slot="footer"
                    flavor={ButtonFlavor.BASE}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.LARGE}
                    disabled={typesSaving}
                >
                    {translations.COMMON_back}
                </ExButton>
                <ExButton
                    slot="footer"
                    flavor={ButtonFlavor.BRANDED}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.LARGE}
                    disabled={isImportDisabled}
                    onClick={onSaveTypes}
                >
                    {translations.TYPES_import_profile_import}
                </ExButton>
            </Footer>
        </>
    );
};

export default ImportProfile;

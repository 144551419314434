import { fetchAndParse, CheckStatus } from '../utils/ajax';

export const signOut = async () => {
    await fetch('/api/draw/1/authentication/signout', {
        method: 'POST',
    }).then(CheckStatus);
};

export const signOutSSO = async (tenantId: string) => {
    const response = await fetchAndParse<{ redirectUri: string | null }>({
        url: '/api/draw/1/authentication/saml/signout',
        method: 'POST',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

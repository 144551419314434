import { type ComponentPropsWithoutRef, useState } from 'react';
import TenantNameInput from './TenantNameInput';
import GenericModal from '../generic/modal/GenericModal';
import FooterButtons from '../generic/modal/FooterButtons';
import translations from '../../translations';
import { provisionTenant } from '../../sources/tenant';
import { addNotification } from '../../../js/actions/reduxActions/notification';
import { connect, type ConnectedProps } from 'react-redux';
import Loader from '../loader/Loader';
import NotificationList from '../../../ts/components/notifications/NotificationList';
import { useNavigate } from 'react-router-dom';

const mapStateToProps = (state: unknown) => {
    return {
        state,
    };
};

const mapDispatchToProps = { addNotification };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    container?: HTMLElement | null;
}

const ProvisionTenant = ({ container, addNotification }: Props) => {
    const [tenantName, setTenantName] = useState('');
    const [tenantNameValid, setTenantNameValid] = useState(false);
    const [hasSubmitted, setHadSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userEmail = urlParams.get('email') ?? '';
    const userFirstName = urlParams.get('first-name') ?? '';
    const userLastName = urlParams.get('last-name') ?? '';

    const onCreateTenant = async () => {
        try {
            setHadSubmitted(true);

            if (tenantNameValid) {
                setIsLoading(true);
                const newTenant = await provisionTenant(
                    userEmail,
                    tenantName,
                    userFirstName,
                    userLastName,
                );
                window.location.replace(`${window.location.origin}/${newTenant.tenantId}`);
            }
        } catch (error) {
            setIsLoading(false);

            addNotification({
                type: 'error',
                message: (error as Error).toString(),
                isPersistent: true,
            });
        }
    };

    const onTenantNameChange: ComponentPropsWithoutRef<typeof TenantNameInput>['onChange'] = ({
        value,
        isValid,
    }) => {
        setTenantName(value);
        setTenantNameValid(isValid);
    };

    const onCancel = () => {
        navigate(window.location.origin);
    };

    const renderHeader = () => <h4 className="modal-title">Create tenant for region?</h4>;

    const renderBody = () => (
        <>
            <div className="margin-bottom">
                To start using this region, first create a tenant for your{' '}
                <strong>{userEmail}</strong> user.
            </div>
            <TenantNameInput
                value={tenantName}
                showValidation={hasSubmitted}
                inputId="tenant-name-input"
                labelText="Tenant Name"
                onChange={onTenantNameChange}
            />
            <div className="margin-bottom-small margin-top-large">
                <strong>Email:</strong> {userEmail}
            </div>
            <div className="margin-bottom-small">
                <strong>{translations.TENANT_add_users_modal_first_name_input_label}:</strong>{' '}
                {userFirstName}
            </div>
            <div className="margin-bottom-small">
                <strong>{translations.TENANT_add_users_modal_last_name_input_label}:</strong>{' '}
                {userLastName}
            </div>
        </>
    );

    const renderFooter = () => (
        <FooterButtons
            confirm={onCreateTenant}
            confirmButtonText={translations.FORG_provision_title}
            cancel={onCancel}
            cancelButtonText={translations.COMMON_back}
        />
    );

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <GenericModal
                onHide={onCancel}
                renderHeader={renderHeader}
                renderBody={renderBody}
                renderFooter={renderFooter}
                container={container}
            />
            <NotificationList />
        </>
    );
};

export default connector(ProvisionTenant);

import type { TimeFilters } from '../../types/dashboard';
import { getFromDate } from '../../utils/dashboard';
import DateTimeInput from '../generic/DateTimeInput';

interface Props {
    toDate: Date;
    dateRange: TimeFilters;
    onChangeToDate: (toDate: Date) => void;
}

const InsightsDateRange = ({ toDate, dateRange, onChangeToDate }: Props) => (
    <div>
        {/* We are using the same date picker but in readonly mode so we can ensure identical formatting */}
        <DateTimeInput
            className="insights-date-picker-small bold"
            value={getFromDate(dateRange, toDate).toISOString()}
            onChange={() => null}
            isReadonly
        />{' '}
        to{' '}
        <DateTimeInput
            className="insights-date-picker bold"
            value={toDate.toISOString()}
            onChange={(newDateString) =>
                // I can't remove the clear button so instead I do nothing otherwise it causes a crash.
                newDateString !== '' ? onChangeToDate(new Date(newDateString)) : null
            }
            maxValue={new Date().toISOString()}
        />
    </div>
);

export default InsightsDateRange;

import {
    BadgeColor,
    BadgeShape,
    BadgeSize,
    ExBadge,
    ExEmptyState,
    ExIcon,
    ExTree,
    IconSize,
} from '@boomi/exosphere';
import type { ElementIndividualChange, ReleasedSnapshot } from '../../../types';
import UserCircle from '../../collaboration/UserCircle';
import { getChangeName, getChangePath, getCreator } from './utils';
import type { Environment } from '../../../types/environment';
import translations from '../../../translations';
import classNames from 'classnames';
import EnvironmentBadge from './EnvironmentBadge';
import ReleaseDate from './ReleaseDate';
import { isNullOrWhitespace } from '../../../utils';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

type TreeNode = {
    label: string;
    path: string;
    expanded: boolean;
    children: TreeNode[];
    checked: boolean;
    selected: boolean;
};

interface ChangesSectionProps {
    historyBeforeVersion: ReleasedSnapshot | null;
    historyAfterVersion: ReleasedSnapshot | null;
    userList: string[];
    environments: Environment[];
    historyData: ElementIndividualChange[] | null;
    selectedTreeNodePath: string | null;
    setSelectedTreeNodePath: React.Dispatch<React.SetStateAction<string | null>>;
    container: HTMLElement | null;
}

const ChangesSection = ({
    historyBeforeVersion,
    historyAfterVersion,
    userList,
    environments,
    historyData,
    selectedTreeNodePath,
    setSelectedTreeNodePath,
    container,
}: ChangesSectionProps) => {
    // before and after can't be null
    if (!(historyBeforeVersion && historyAfterVersion)) {
        return null;
    }

    return (
        <div className="changes-grid">
            <span className="left-section sticky-top-section">
                <ExBadge
                    color={BadgeColor.GRAY}
                    shape={BadgeShape.SQUARED}
                    size={BadgeSize.SMALL}
                    showIcon={false}
                >
                    {historyBeforeVersion.releaseName}
                </ExBadge>
                <ExIcon icon="Right arrow" size={IconSize.S} />
                <ExBadge
                    color={BadgeColor.GRAY}
                    shape={BadgeShape.SQUARED}
                    size={BadgeSize.SMALL}
                    showIcon={false}
                >
                    {historyAfterVersion.releaseName}
                </ExBadge>
            </span>
            <span className="header-section sticky-top-section">
                <UserCircle
                    user={getCreator(historyBeforeVersion)}
                    index={
                        getCreator(historyBeforeVersion)
                            ? userList.indexOf(getCreator(historyBeforeVersion)!.id)
                            : userList.length
                    }
                />
                <span className="text">
                    <span className="release-name">{historyBeforeVersion.releaseName}</span>
                    <ReleaseDate snapshot={historyBeforeVersion} />
                </span>
                <EnvironmentBadge environments={environments} snapshot={historyBeforeVersion} />
            </span>
            <span className="header-section sticky-top-section">
                <UserCircle
                    user={getCreator(historyAfterVersion)}
                    index={
                        getCreator(historyAfterVersion)
                            ? userList.indexOf(getCreator(historyAfterVersion)!.id)
                            : userList.length
                    }
                />
                <span className="text">
                    <span className="release-name">{historyAfterVersion.releaseName}</span>
                    <ReleaseDate snapshot={historyAfterVersion} />
                </span>
                <EnvironmentBadge environments={environments} snapshot={historyAfterVersion} />
            </span>
            <span className="sticky-left-section-wrapper">
                <span className="sticky-left-section">
                    <ExTree
                        showDropdown={false}
                        showTreeLine={true}
                        enableDragAndDrop={false}
                        treeData={
                            historyData?.reduce(
                                (acc: TreeNode[], current: ElementIndividualChange) => {
                                    const path = getChangePath(current);
                                    const getOrCreateData = (
                                        label: string,
                                        collection: TreeNode[],
                                        pathString: string,
                                    ) => {
                                        const foundData = collection.find(
                                            (item) => item.label === label,
                                        );
                                        if (foundData) {
                                            return foundData;
                                        }
                                        const newData: TreeNode = {
                                            checked: false,
                                            path: pathString,
                                            label,
                                            expanded: true,
                                            selected: pathString === selectedTreeNodePath,
                                            children: [],
                                        };
                                        collection.push(newData);
                                        return newData;
                                    };
                                    let searchList = acc;

                                    path.forEach((element, index) => {
                                        searchList = getOrCreateData(
                                            element,
                                            searchList,
                                            path.slice(0, index + 1).join(' / '),
                                        ).children;
                                    });
                                    return acc;
                                },
                                [],
                            ) ?? []
                        }
                        onClick={(event: CustomEvent) => {
                            const foundChange = Array.from(
                                document.querySelectorAll('.change-title'),
                            ).find((el) => el.textContent?.includes(event.detail.node.path));
                            setSelectedTreeNodePath(event.detail.node.path);
                            if (foundChange) {
                                const modalBody = (container ?? document).querySelector(
                                    '.modal-body',
                                );
                                const changeHeader = modalBody?.querySelector(
                                    '.changes-grid .header-section',
                                );
                                if (modalBody && changeHeader) {
                                    modalBody?.scrollTo(
                                        0,
                                        (foundChange as HTMLElement).offsetTop -
                                            (changeHeader as HTMLElement).offsetHeight -
                                            10 -
                                            15, // Change (10px gap) Modal body (15px padding)
                                    );
                                }
                            }
                        }}
                    />
                </span>
            </span>
            <span className="diff-section">
                {!(
                    isNullOrWhitespace(historyBeforeVersion.comment) &&
                    isNullOrWhitespace(historyAfterVersion.comment)
                ) && (
                    <div className="description">
                        <span>
                            <b>Description:</b> {historyBeforeVersion.comment}
                        </span>
                        <span>
                            <b>Description:</b> {historyAfterVersion.comment}
                        </span>
                    </div>
                )}
                {historyData && historyData?.length > 0 ? (
                    historyData?.map((d) => (
                        <div
                            className={classNames('change', {
                                selected: getChangeName(d) === selectedTreeNodePath,
                            })}
                            key={getChangeName(d)}
                        >
                            <div className="change-titles">
                                <span className="change-title">
                                    {d.diff.operationType === 'added' ? (
                                        <ExBadge color={BadgeColor.GREEN} size={BadgeSize.SMALL}>
                                            Added
                                        </ExBadge>
                                    ) : (
                                        getChangeName(d)
                                    )}
                                </span>
                                <span className="change-title">
                                    {d.diff.operationType === 'removed' ? (
                                        <ExBadge color={BadgeColor.RED} size={BadgeSize.SMALL}>
                                            Removed
                                        </ExBadge>
                                    ) : (
                                        getChangeName(d)
                                    )}
                                </span>
                            </div>
                            <div className={classNames('diff change-body', d.diff.operationType)}>
                                <ReactDiffViewer
                                    oldValue={d.diff.before ?? ''}
                                    newValue={d.diff.after ?? ''}
                                    compareMethod={DiffMethod.WORDS}
                                    hideLineNumbers={false}
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <ExEmptyState
                        label={translations.FLOW_HISTORY_no_changes}
                        text={translations.FLOW_HISTORY_no_changes_description}
                    />
                )}
            </span>
        </div>
    );
};

export default ChangesSection;

import { useEffect, useState } from 'react';
import '../../../../css/graph/graphUserSettings.less';
import { useAuth } from '../../../ts/components/AuthProvider';
import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../ts/components/buttons/ButtonPrimary';
import Loader from '../../../ts/components/loader/Loader';
import { getUserSettings, updateUserSettings } from '../../../ts/sources/user';
import translations from '../../../ts/translations';
import { notifyError } from '../../actions/reduxActions/notification';
import FormGroup from '../../../ts/components/generic/FormGroup';

const GraphUserSettings = () => {
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [savedLineThickness, setSavedLineThickness] = useState(null);
    const [savedBorderStyle, setSavedBorderStyle] = useState(null);
    const [loading, setLoading] = useState(false);

    const { userSettings, setUserSettingsLineThickness, setUserSettingsColorStyle } = useAuth();
    const { canvasSettings } = userSettings;
    const { lineThickness, mapElementColorStyle: borderStyle } = canvasSettings;

    useEffect(() => {
        const loadUserSettings = async () => {
            try {
                setLoading(true);

                const settings = await getUserSettings();

                setSavedLineThickness(settings?.canvasSettings?.lineThickness ?? 2.1);
                setSavedBorderStyle(
                    settings?.canvasSettings?.mapElementColorStyle ?? 'Color Border',
                );
            } catch (error) {
                notifyError(error);
            } finally {
                setLoading(false);
            }
        };

        loadUserSettings();
    }, []);

    const revertToInitialValues = () => {
        setUserSettingsLineThickness(savedLineThickness);
        setUserSettingsColorStyle(savedBorderStyle);

        setUnsavedChanges(false);
    };

    const saveUserSettings = async () => {
        try {
            setLoading(true);

            const userSettings = {
                canvasSettings: {
                    lineThickness,
                    mapElementColorStyle: borderStyle,
                },
            };

            await updateUserSettings(userSettings);

            setSavedLineThickness(lineThickness);
            setSavedBorderStyle(borderStyle);

            setUnsavedChanges(false);
        } catch (error) {
            notifyError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="settings-menu">
            <div className="settings-body">
                <h4 className="settings-heading">{translations.CANVAS_SETTINGS_header}</h4>
                <FormGroup
                    label={translations.CANVAS_SETTINGS_element_style_label}
                    htmlFor="element-style-select"
                >
                    <select
                        id="element-style-select"
                        className="form-control full-width"
                        value={borderStyle}
                        onChange={({ target: { value: newBorderStyle } }) => {
                            setUserSettingsColorStyle(newBorderStyle);
                            setUnsavedChanges(newBorderStyle !== savedBorderStyle);
                        }}
                        disabled={loading}
                    >
                        <option value="Color Borders">Color Borders</option>
                        <option value="Color Fill">Color Fill</option>
                        <option value="Color Fill When Zoomed Out">
                            Color Fill When Zoomed Out
                        </option>
                    </select>
                </FormGroup>
                <FormGroup
                    label={translations.CANVAS_SETTINGS_border_thickness_label}
                    htmlFor="line-thickness-slider"
                >
                    <input
                        id="line-thickness-slider"
                        className="thickness-slider"
                        type="range"
                        min="15"
                        max="50"
                        value={lineThickness * 10} // Sliders use whole values so multiply by 10 to increase slider resolution.
                        onChange={({ target: { value } }) => {
                            // Divide by 10 to turn back to real values.
                            const newLineThickness = value / 10;

                            setUserSettingsLineThickness(newLineThickness);
                            setUnsavedChanges(newLineThickness !== savedLineThickness);
                        }}
                        disabled={loading}
                    />
                </FormGroup>
            </div>
            <div className="settings-footer">
                <div className="loading-indicator-container">
                    {loading ? <Loader small /> : null}
                </div>
                <ButtonDefault
                    disabled={!unsavedChanges || loading}
                    onClick={revertToInitialValues}
                >
                    {translations.GRAPH_config_panel_revert}
                </ButtonDefault>
                <ButtonPrimary disabled={!unsavedChanges || loading} onClick={saveUserSettings}>
                    {translations.GRAPH_config_panel_save}
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default GraphUserSettings;

import { ButtonFlavor, ButtonSize, ButtonType, ExButton, ExDialog } from '@boomi/exosphere';
import { useDebug } from './DebugProvider';

import translations from '../../../translations';
import { stringReplace } from '../../../utils/string';

const SwitchFlowConfirmation = () => {
    const { switchFlowConfirmation, confirmSwitchFlow, dispatch } = useDebug();
    return (
        <ExDialog
            open={switchFlowConfirmation !== null}
            dialogTitle={translations.DEBUGGER_confirmation_title}
            hideClose
        >
            {switchFlowConfirmation?.canSwitch
                ? stringReplace(translations.DEBUGGER_confirmation_summary, {
                      flowName: switchFlowConfirmation?.stackItemFlowName,
                  })
                : stringReplace(translations.DEBUGGER_confirmation_summary_tab_conflict, {
                      flowName: switchFlowConfirmation?.stackItemFlowName,
                  })}
            {switchFlowConfirmation?.canSwitch && (
                <ExButton
                    slot="footer"
                    flavor={ButtonFlavor.BRANDED}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.LARGE}
                    onClick={() => {
                        if (switchFlowConfirmation) {
                            confirmSwitchFlow(
                                switchFlowConfirmation.stackItemFlowId,
                                switchFlowConfirmation.stackItemFlowName,
                            );
                        }
                    }}
                >
                    {translations.COMMON_confirm}
                </ExButton>
            )}
            <ExButton
                slot="footer"
                flavor={ButtonFlavor.BASE}
                type={ButtonType.SECONDARY}
                size={ButtonSize.LARGE}
                onClick={() => {
                    if (switchFlowConfirmation?.stackItemId) {
                        dispatch({
                            type: 'dismissSwitchFlowConfirmation',
                        });
                    }
                }}
            >
                {switchFlowConfirmation?.canSwitch
                    ? translations.COMMON_cancel
                    : translations.COMMON_ok}
            </ExButton>
        </ExDialog>
    );
};

export default SwitchFlowConfirmation;

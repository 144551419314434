import { TIME_FILTER, TIME_INTERVAL } from '../../../ts/components/dashboard/constants';
import { addDays, addYears, addHours } from '../../../ts/utils/date';

/**
 * @param {Object} target Object passed from DOM event
 * @description Calculates the start and end time of some default time ranges.
 * The target value has to map to a key in the defaultFlowFilter object.
 */
export const getTimeRange = (target) => {
    const now = new Date();
    const defaultFlowFilter = {
        [TIME_FILTER.lastYear]: {
            from: addYears(now, -1).toISOString(),
            to: now.toISOString(),
            groupBy: 'monthly',
            interval: TIME_INTERVAL.lastYear,
        },
        [TIME_FILTER.lastThirtyDays]: {
            from: addDays(now, -30).toISOString(),
            to: now.toISOString(),
            groupBy: 'daily',
            interval: TIME_INTERVAL.lastThirtyDays,
        },
        [TIME_FILTER.lastSevenDays]: {
            from: addDays(now, -7).toISOString(),
            to: now.toISOString(),
            groupBy: 'daily',
            interval: TIME_INTERVAL.lastSevenDays,
        },
        [TIME_FILTER.lastTwentyFourHours]: {
            from: addHours(now, -24).toISOString(),
            to: now.toISOString(),
            groupBy: 'hourly',
            interval: TIME_INTERVAL.lastTwentyFourHours,
        },
    };

    return defaultFlowFilter[target.value];
};

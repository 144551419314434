import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { type ReactNode, useEffect, useState } from 'react';
import './css/sortable.less';
import type { OrderDirection } from '../../types';

export interface Props {
    direction: OrderDirection | null;
    defaultDirection?: OrderDirection;
    onSort: (direction: OrderDirection) => void;
    children: ReactNode;
}

const Sortable = ({ direction, defaultDirection, onSort, children }: Props) => {
    const [directionState, setDirectionState] = useState<OrderDirection | null>(direction || null);

    useEffect(() => {
        setDirectionState(direction);
    }, [direction]);

    const setSort = (direction: OrderDirection) => {
        setDirectionState(direction);
        onSort(direction);
    };

    const onToggleSort = () => {
        if (directionState === null) {
            setSort(defaultDirection || 'ASC');
        } else if (directionState === 'ASC') {
            setSort('DESC');
        } else if (directionState === 'DESC') {
            setSort('ASC');
        }
    };

    const renderSorter = () => {
        return (
            <span className="sorter-arrows">
                {directionState === 'ASC' ? (
                    <CaretUp />
                ) : directionState === 'DESC' ? (
                    <CaretDown />
                ) : (
                    <div className="unsorted-arrows">
                        <CaretUp className="unsorted-caret-up" />
                        <CaretDown className="unsorted-caret-down" />
                    </div>
                )}
            </span>
        );
    };

    return (
        <button
            className="sortable-btn"
            onClick={onToggleSort}
            data-testid="sort-button"
            type="button"
        >
            {children}
            {renderSorter()}
        </button>
    );
};

export default Sortable;

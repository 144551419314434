import { type ElementRef, useEffect, useRef, useState } from 'react';
import translations from '../../../translations';
import { getUsageForTenant } from '../../../sources/dashboard';
import type { NotifyError } from '../../../types';
import {
    convertDateFromUnixTime,
    convertDateToUnixTime,
    formatChartDate,
    getDashboardLineChartOptions,
    getFromDate,
    getSecondsToIncrement,
    insightDateFilterItems,
} from '../../../utils/dashboard';
import type {
    ChartLine,
    ChartDataPoint,
    TimeFilters,
    FlowUsageDashboardResponse,
    UsageDashboardBucket,
} from '../../../types/dashboard';
import { Chart } from 'chart.js/auto';
import { View } from '../InsightsDashboard';
import InsightsDateFilter from '../InsightsDateFilter';
import classNames from 'classnames';
import { useInsights } from '../InsightsProvider';

const getChartData = (
    flowUsage: FlowUsageDashboardResponse[],
    toDate: Date,
    dateRange: TimeFilters,
) => {
    const chartLine: ChartLine[] = [];
    const usageLineData: ChartDataPoint[] = [];

    const fromDate = getFromDate(dateRange, toDate);

    for (
        let time = convertDateToUnixTime(fromDate);
        time <= convertDateToUnixTime(toDate);
        time += getSecondsToIncrement(dateRange, convertDateFromUnixTime(time))
    ) {
        const bucketsInRange: UsageDashboardBucket[] = [];

        flowUsage.forEach((usage) => {
            const dataInTimeRange = usage.flowLaunchData.filter((usageData) => {
                const dataUnix = convertDateToUnixTime(usageData.bucket);
                return (
                    dataUnix > time &&
                    dataUnix <
                        time + getSecondsToIncrement(dateRange, convertDateFromUnixTime(time))
                );
            });

            bucketsInRange.push(...dataInTimeRange);
        });

        const usageForTimeBucket = bucketsInRange.reduce(
            (accumulator, currentValue) => accumulator + currentValue.counter,
            0,
        );

        usageLineData.push({
            x: formatChartDate(convertDateFromUnixTime(time), dateRange),
            y: usageForTimeBucket,
        });
    }

    chartLine.push({
        data: usageLineData,
        label: 'Usage',
        tension: 0.3,
    });

    return chartLine;
};

interface Props {
    notifyError: NotifyError;
    setCurrentView: (view: View) => void;
}

const UsageSummary = ({ notifyError, setCurrentView }: Props) => {
    const [isHovering, setIsHovering] = useState(false);
    const [usageLine, setUsageLine] = useState<ChartLine[]>([]);

    const canvasRef = useRef<ElementRef<'canvas'>>(null);

    const { usageDateFilter, setUsageDateFilter } = useInsights();

    useEffect(() => {
        const toDate = new Date();

        getUsageForTenant(usageDateFilter, toDate)
            .then((response) => {
                setUsageLine(getChartData(response, toDate, usageDateFilter));
            })
            .catch(notifyError);
    }, [usageDateFilter, notifyError]);

    useEffect(() => {
        const context = canvasRef.current?.getContext('2d');

        if (!context) {
            return;
        }

        const chart = new Chart(context, {
            type: 'line',
            data: { datasets: usageLine },
            options: getDashboardLineChartOptions(),
        });

        // Cleanup the chart to prevent it from wildly animating when switching away and then back to Dashboard tab.
        return () => {
            chart?.destroy();
        };
    }, [usageLine]);

    const tileClasses = classNames(
        'insights-chart-wrapper',
        'insights-tile',
        isHovering ? 'insights-tile-hover' : '',
    );

    return (
        <div
            className={tileClasses}
            onClick={() => setCurrentView(View.usage)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    setCurrentView(View.usage);
                }
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            role="button"
            tabIndex={0}
            data-testid="usage-dashboard-tile"
        >
            <div className="title-bar insight-tile-header">
                <h2>{translations.DASHBOARD_usage_header}</h2>
                <InsightsDateFilter
                    selectedDate={usageDateFilter}
                    setSelectedDate={setUsageDateFilter}
                    dateFilterItems={insightDateFilterItems}
                    setParentHovering={setIsHovering}
                />
            </div>
            <div className="insights-chart">
                <canvas ref={canvasRef} data-testid="usage-chart-canvas" />
            </div>
        </div>
    );
};

export default UsageSummary;

import type { OrganizationMinimal } from '../types';
import { isNullOrEmpty } from './guard';

export const resolveOrgUserRole = (
    userData: { organizations?: OrganizationMinimal[] },
    tenantData: { organization?: OrganizationMinimal | null },
) => {
    const isTenantInOrg = !isNullOrEmpty(tenantData.organization);
    const isUserAdmin =
        !isNullOrEmpty(userData.organizations) && isTenantInOrg
            ? userData.organizations.some((org) => org.id === tenantData.organization?.id)
            : false;

    return { isUserAdmin, isTenantInOrg };
};

import FlowList from './FlowList';
import './css/home.less';

interface Props {
    isEditorActive: boolean;
}
const Home = ({ isEditorActive }: Props) => {
    return (
        <div data-testid="home-screen" className="admin-page flow-wrapper">
            <FlowList isEditorActive={isEditorActive} />
        </div>
    );
};

export default Home;

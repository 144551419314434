import { createContext, useContext, useEffect, useState, type ReactNode } from 'react';
import type { StateErrorsLineChartDataWithFilters, TimeFilters } from '../../types/dashboard';
import { getStateErrorsForLineChart } from '../../sources/dashboard';
import type { NotifyError } from '../../types';

interface InsightsProviderContext {
    usageDateFilter: TimeFilters;
    setUsageDateFilter: (filter: TimeFilters) => void;
    errorsDateFilter: TimeFilters;
    setErrorsDateFilter: (filter: TimeFilters) => void;
    stateErrorsLineChartData: StateErrorsLineChartDataWithFilters;
    customUsageToDate: Date;
    setCustomUsageToDate: (toDate: Date) => void;
    customErrorsToDate: Date;
    setCustomErrorsToDate: (toDate: Date) => void;
    errorsFlowNameFilter: string;
    setErrorsFlowNameFilter: (filter: string) => void;
    errorsSearchFilter: string;
    setErrorsSearchFilter: (filter: string) => void;
}

interface InsightsProviderProps {
    children: ReactNode;
    notifyError: NotifyError;
}

const Context = createContext<InsightsProviderContext | undefined>(undefined);

const InsightsProvider = ({ children, notifyError }: InsightsProviderProps) => {
    const [usageDateFilter, setUsageDateFilter] = useState<TimeFilters>('day');
    const [errorsDateFilter, setErrorsDateFilter] = useState<TimeFilters>('day');
    const [customUsageToDate, setCustomUsageToDate] = useState(new Date());
    const [customErrorsToDate, setCustomErrorsToDate] = useState(new Date());
    const [errorsFlowNameFilter, setErrorsFlowNameFilter] = useState('');
    const [errorsSearchFilter, setErrorsSearchFilter] = useState('');

    const [stateErrorsLineChartData, setStateErrorsLineChartData] =
        useState<StateErrorsLineChartDataWithFilters>({
            stateErrorsData: [],
            stateErrorsTotal: 0,
            dateFilter: 'day',
            customToDate: new Date(),
        });

    useEffect(() => {
        getStateErrorsForLineChart(
            errorsDateFilter,
            customErrorsToDate,
            errorsSearchFilter,
            errorsFlowNameFilter,
        )
            .then((response) =>
                setStateErrorsLineChartData({
                    stateErrorsData: response.stateErrorsData,
                    stateErrorsTotal: response.stateErrorsTotal,
                    dateFilter: errorsDateFilter,
                    customToDate: customErrorsToDate,
                }),
            )
            .catch(notifyError);
    }, [
        errorsDateFilter,
        notifyError,
        customErrorsToDate,
        errorsSearchFilter,
        errorsFlowNameFilter,
    ]);

    const contextValue: InsightsProviderContext = {
        usageDateFilter,
        setUsageDateFilter,
        errorsDateFilter,
        setErrorsDateFilter,
        stateErrorsLineChartData,
        customUsageToDate,
        setCustomUsageToDate,
        customErrorsToDate,
        setCustomErrorsToDate,
        errorsFlowNameFilter,
        setErrorsFlowNameFilter,
        errorsSearchFilter,
        setErrorsSearchFilter,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useInsights = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useInsights must be used within an InsightsProvider');
    }
    return context;
};

export { InsightsProvider, useInsights };

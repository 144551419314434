import actionTypes from '../actions/reduxActions/actionTypes';

const initialState = {
    hoveringMapElementId: null,
    hoveringGroupElementId: null,
    draggingOverGroupElementId: null,
    previousDraggingPosition: { x: 0, y: 0 },
    dragging: null,
    // dragging has shape: {
    //     dragType,
    //     elementId,
    //     previousElementPosition,
    //     previousMousePosition,
    //     initialMousePosition?,
    //     hasMovedEnough?,
    //     handleSide?,
    // },
    contextMenu: null,
    // contextMenu has shape: {
    //     show,
    //     x,
    //     y,
    //     activeElement: {
    //         id,
    //         type,
    //     }
    // },
    // Text that the user has searched the canvas for
    searchQueries: {},
    // searchQueries has shape: {
    //    [flowId]: query,
    //    ...
    // }
    clipboard: null,
    // clipboard has shape: {
    //    flowId,
    //    elements: {
    //        mapElements,
    //        groupElements,
    //    }
    // }
};

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const graph = (graphState = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GRAPH_SET_HOVER_MAP_ID:
            return { ...graphState, hoveringMapElementId: payload };
        case actionTypes.GRAPH_SET_HOVER_GROUP_ID:
            return { ...graphState, hoveringGroupElementId: payload };
        case actionTypes.GRAPH_SET_DRAG_OVER_GROUP_ID:
            return { ...graphState, draggingOverGroupElementId: payload };
        case actionTypes.GRAPH_SET_DRAGGING_DATA: {
            // If we are setting the dragging props for the first time from null
            // to something that isn't null
            // then also save the mouse position as the initial mouse position
            if (graphState.dragging === null && payload) {
                payload.initialMousePosition = payload.previousMousePosition;
            }

            return { ...graphState, dragging: payload };
        }
        case actionTypes.GRAPH_SET_CONTEXT_MENU_DATA:
            return { ...graphState, contextMenu: payload };
        case actionTypes.TAB_OPEN:
            return { ...graphState, contextMenu: null };
        case actionTypes.FLOW_CLOSE:
            return {
                ...graphState,
                contextMenu: null,
                searchQueries: {
                    ...graphState.searchQueries,
                    [payload]: null,
                },
            };
        case actionTypes.GRAPH_SET_SEARCH:
            return {
                ...graphState,
                searchQueries: {
                    ...graphState.searchQueries,
                    [payload.flowId]: payload.query,
                },
            };
        case actionTypes.GRAPH_SET_CLIPBOARD:
            return { ...graphState, clipboard: payload };
        default:
            return graphState;
    }
};

export default graph;

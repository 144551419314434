import { Component } from 'react';
import { merge, pipe, curry } from 'ramda';
import classnames from 'classnames';
import { exportToCsv } from '../../../ts/utils/csv';
import { getStatesByFlow } from '../../../ts/sources/dashboard';
import GenericSelectBox from '../../../ts/components/generic/GenericSelectBox';
import { getTimeRange } from './getTimeRange';
import { STATE_STATUS, TIME_FILTER } from '../../../ts/components/dashboard/constants';
import { fetchAllPaged } from '../../../ts/utils/ajax';
import Loader from '../../../ts/components/loader/Loader';
import CopyableText from '../../../ts/components/generic/CopyableText';
import ButtonDefault from '../../../ts/components/buttons/ButtonDefault';
import { ArrowsClockwise, DownloadSimple } from '@phosphor-icons/react';
import Table from '../../../ts/components/generic/Table';
import translations from '../../../ts/translations';
import { formatDistanceStrict } from 'date-fns';

const initialState = {
    isLoading: false,
    isFetchingAllStates: false,
    items: [],
    paging: {
        page: 1,
        pageSize: 50,
        total: 0,
    },
    filter: {
        status: STATE_STATUS.inProgress,
        dates: {
            from: null,
            to: null,
            groupBy: 'monthly',
        },
    },
};

const { errored: ERRORED, expired: EXPIRED, done: DONE, inProgress: IN_PROGRESS } = STATE_STATUS;

const exportToCsvCurried = curry(exportToCsv);

export const getStatusText = ({ status, item }) => {
    const { isDone, isExpired } = item;

    switch (status) {
        case DONE:
            return 'Done';

        case IN_PROGRESS:
            return 'In Progress';

        case EXPIRED:
            return 'Expired';

        case ERRORED:
            return isDone ? 'Done' : isExpired ? 'Expired' : 'In Progress';

        // All
        default:
            return isDone ? 'Done' : 'In Progress';
    }
};

export const showErrorFlag = ({ status, item }) => {
    if (!item.hasRootFaults || status === ERRORED) {
        return false;
    }

    return true;
};

export const showExpiredFlag = ({ status, item }) => {
    if (!item.isExpired || status === EXPIRED) {
        return false;
    }

    return true;
};

export const statusClassName = ({ isDone, isExpired, hasRootFaults }) =>
    classnames({
        'dashboard-status-column': true,
        'status-errored': hasRootFaults,
        'status-expired': isExpired && !hasRootFaults,
        'status-done': isDone && !hasRootFaults && !isExpired,
    });

export const formatStateDataToCsv = (states) => {
    const headerRow = [
        'Done',
        'Expired',
        'Has Root Faults',
        'Current Step',
        'User',
        'Date Created',
        'Last Action On',
        'Join Uri',
        'State ID',
    ];

    const bodyRows = states.map((state) => [
        state.isDone,
        state.isExpired,
        state.hasRootFaults,
        state.currentMapElementDeveloperName,
        state.isMcr ? '' : state.currentRunningUserDisplayName,
        state.dateCreated,
        state.dateModified,
        state.joinUri,
        state.id,
    ]);

    const allRows = [headerRow, ...bodyRows];

    return allRows;
};

/**
 * @param {Object} selectedFlow Object containing information about the flow which the view should be based on
 * @param {Function} onServiceInvokerClick Called when clicking through to a state's service invoker communication. Passed the selected state item data.
 * @description Controls and shows information about a single flow such as a list of states
 */
class StateList extends Component {
    constructor() {
        super();

        this.state = initialState;
        this.fetchData = this.fetchData.bind(this);
        this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
        this.exportResultsToCSV = this.exportResultsToCSV.bind(this);
        this.onTimeFilterChange = this.onTimeFilterChange.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
    }

    componentDidMount() {
        this.fetchData(1);
    }

    onRefresh() {
        this.fetchData(1);
    }

    onStatusFilterChange({ target }) {
        const updatedFilter = merge(this.state.filter, { status: target.value });

        this.setState(
            {
                filter: updatedFilter,
            },
            () => this.fetchData(),
        );
    }

    onTimeFilterChange({ target }) {
        const updatedFilter = merge(this.state.filter, { dates: getTimeRange(target) });

        this.setState(
            {
                filter: updatedFilter,
            },
            () => this.fetchData(),
        );
    }

    fetchData(page = 1) {
        const { status } = this.state.filter;
        const { from, to } = this.state.filter.dates;

        const urlParams = {
            status,
            page,
            pageSize: 50,
        };

        if (from !== null && to !== null) {
            urlParams.from = from;
            urlParams.to = to;
        }

        this.setState({ isLoading: true });

        getStatesByFlow(this.props.selectedFlow.id, urlParams)
            .then(({ _meta: paging, items }) =>
                this.setState({
                    isLoading: false,
                    items,
                    paging,
                }),
            )
            .catch(() => {
                this.setState({
                    isLoading: false,
                    items: initialState.items,
                    paging: initialState.paging,
                });
            });
    }

    async exportResultsToCSV() {
        this.setState({ isFetchingAllStates: true });

        const states = await fetchAllPaged(
            (_, pageSize) =>
                getStatesByFlow(this.props.selectedFlow.id, {
                    status: this.state.filter.status,
                    from: this.state.filter.dates.from,
                    to: this.state.filter.dates.to,
                    pageSize,
                    page: 1,
                }),
            1000,
        );

        pipe(formatStateDataToCsv, exportToCsvCurried('states.csv'))(states);

        this.setState({ isFetchingAllStates: false });
    }

    render() {
        const columns = [
            {
                renderHeader: () => translations.COMMON_TABLE_status,
                renderCell: ({ item }) => {
                    const { status } = this.state.filter;

                    const text = getStatusText({ status, item });
                    const showError = showErrorFlag({ status, item });
                    const showExpired = showExpiredFlag({ status, item });
                    const className = statusClassName(item);

                    return (
                        <span className={className}>
                            {text}
                            {showExpired && (
                                <span className="glyphicon glyphicon-time" title="Expired" />
                            )}
                            {showError && (
                                <span
                                    className="glyphicon glyphicon-exclamation-sign status-errored"
                                    title="Root Faults"
                                />
                            )}
                        </span>
                    );
                },
                size: '10rem',
            },
            {
                renderHeader: () => translations.COMMON_TABLE_current_step,
                renderCell: ({ item }) => item.currentMapElementDeveloperName,
                size: '11rem',
            },
            {
                renderHeader: () => translations.COMMON_TABLE_user,
                renderCell: ({ item }) => (item.isMcr ? '' : item.currentRunningUserDisplayName),
            },
            {
                renderHeader: () => translations.COMMON_TABLE_elapsed_time,
                renderCell: ({ item }) =>
                    formatDistanceStrict(new Date(item.dateCreated), new Date()),
                size: '6rem',
            },
            {
                renderHeader: () => translations.COMMON_TABLE_created_at_utc,
                renderCell: ({ item }) =>
                    new Date(item.dateCreated).toLocaleString(undefined, {
                        dateStyle: 'medium',
                        timeStyle: 'medium',
                    }),
                size: '11rem',
            },
            {
                renderHeader: () => translations.COMMON_TABLE_last_action_utc,
                renderCell: ({ item }) =>
                    new Date(item.dateModified).toLocaleString(undefined, {
                        dateStyle: 'medium',
                        timeStyle: 'medium',
                    }),
                size: '11rem',
            },
            {
                renderHeader: () => translations.COMMON_TABLE_join_uri,
                renderCell: ({ item }) => <a href={item.joinUri}>{item.joinUri}</a>,
            },
            {
                renderHeader: () => translations.COMMON_TABLE_state_id,
                renderCell: ({ item }) => (
                    <CopyableText copyableText={item.id} hasCopyButton={true} />
                ),
            },
            {
                renderHeader: () => translations.COMMON_TABLE_actions,
                renderCell: ({ item }) => (
                    <button
                        className="link-emulate"
                        onClick={() => this.props.onServiceInvokerClick(item)}
                        hidden={item?.isMcr}
                        type="button"
                    >
                        Log
                    </button>
                ),
                size: '5rem',
            },
        ];

        const items = this.state.isLoading ? [] : this.state.items;

        return (
            <div className="dashboard-states">
                <div className="flex align-baseline">
                    <h1 className="margin-right">{`${this.props.selectedFlow.developerName} Flow`}</h1>
                    <CopyableText
                        textClassName="flow-id"
                        copyableText={this.props.selectedFlow.id}
                        hasCopyButton={true}
                    />
                </div>
                <div className="state-actions">
                    <GenericSelectBox
                        onFilter={this.onTimeFilterChange}
                        selected={TIME_FILTER.lastYear}
                    >
                        <option value={TIME_FILTER.lastYear}>Last Year</option>
                        <option value={TIME_FILTER.lastThirtyDays}>Last 30 Days</option>
                        <option value={TIME_FILTER.lastSevenDays}>Last 7 Days</option>
                        <option value={TIME_FILTER.lastTwentyFourHours}>Last 24 Hours</option>
                    </GenericSelectBox>
                    <GenericSelectBox
                        onFilter={this.onStatusFilterChange}
                        selected={STATE_STATUS.inProgress}
                    >
                        <option value={STATE_STATUS.inProgress}>In Progress</option>
                        <option value={STATE_STATUS.done}>Done</option>
                        <option value={STATE_STATUS.errored}>Errored</option>
                        <option value={STATE_STATUS.expired}>Expired</option>
                        <option>All</option>
                    </GenericSelectBox>
                    <div className="form-group export-csv">
                        <ButtonDefault
                            title="Download results in CSV format"
                            className="export"
                            disabled={items.length === 0}
                            onClick={this.exportResultsToCSV}
                        >
                            <DownloadSimple />
                        </ButtonDefault>
                    </div>

                    <ButtonDefault title="Refresh results" onClick={this.onRefresh}>
                        <ArrowsClockwise />
                    </ButtonDefault>
                </div>
                <Table
                    columns={columns}
                    items={items}
                    isLoading={this.state.isLoading}
                    pagination={{
                        page: this.state.paging.page,
                        total: this.state.paging.total,
                        pageSize: this.state.paging.pageSize,
                        changePage: this.fetchData,
                    }}
                />

                {this.state.isFetchingAllStates && <Loader message="Downloading state data" />}
            </div>
        );
    }
}

export default StateList;

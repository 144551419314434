import { useCallback, useEffect, useReducer, useRef } from 'react';
import ConfirmModal from '../../generic/modal/ConfirmModal';
import translations from '../../../translations';
import {
    type AddNotification,
    ElementType,
    type Filter,
    type IdentityProviderAPI,
    type ItemCollectionResponse,
    type OrderDirection,
    type Paging,
} from '../../../types';
import { deleteIdentityProvider, getIdentityProviderList } from '../../../sources/identityprovider';
import Table, { type TableColumnList } from '../../generic/Table';
import Sortable from '../../generic/Sortable';
import { Trash } from '@phosphor-icons/react';
import PageHeader from '../../generic/PageHeader';
import { ENGINE_API_URL } from '../../../../ts/constants';
import { getTenantId } from '../../../utils/tenant';
import { stringReplace } from '../../../utils/string';

interface Props {
    addNotification: AddNotification;
    setItemToEdit: (item: IdentityProviderAPI) => void;
}

type ViewState = 'list' | 'loading' | 'deleteConfirmation';

interface State {
    items: IdentityProviderAPI[];
    viewState: ViewState;
    itemToDelete: IdentityProviderAPI | null;
    paging: Paging;
    filter: Filter;
}

type Action =
    | { type: 'itemsLoadRequest' }
    | {
          type: 'itemsLoadSuccess';
          payload: { response: ItemCollectionResponse<IdentityProviderAPI> };
      }
    | { type: 'itemsLoadFail' }
    | { type: 'page'; payload: { page: number } }
    | { type: 'itemsPerPage'; payload: { pageSize: number } }
    | {
          type: 'sort';
          payload: { orderBy: keyof IdentityProviderAPI; orderDirection: OrderDirection };
      }
    | { type: 'filter'; payload: { search: string } }
    | { type: 'setItemToDelete'; payload: { item: IdentityProviderAPI | null } };

const idpTypes = {
    saml: 'saml',
    oidc: 'oidc',
    oauth2: 'oauth2',
};

const initialState: State = {
    items: [],
    viewState: 'list',
    itemToDelete: null,
    paging: {
        page: 1,
        pageSize: 20,
        total: 0,
    },
    filter: {
        search: '',
        orderBy: 'dateModified',
        orderDirection: 'DESC',
    },
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'itemsLoadRequest':
            return {
                ...state,
                viewState: 'loading',
            };

        case 'itemsLoadSuccess':
            return {
                ...state,
                items: action.payload.response.items,
                paging: action.payload.response._meta,
                viewState: 'list',
            };

        case 'itemsLoadFail':
            return {
                ...state,
                viewState: 'list',
            };

        case 'page':
            return {
                ...state,
                paging: {
                    ...state.paging,
                    page: action.payload.page,
                },
            };

        case 'itemsPerPage':
            return {
                ...state,
                paging: {
                    ...state.paging,
                    page: 1,
                    pageSize: action.payload.pageSize,
                },
            };

        case 'sort':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    orderBy: action.payload.orderBy,
                    orderDirection: action.payload.orderDirection,
                },
            };

        case 'filter':
            return {
                ...state,
                filter: {
                    ...state.filter,
                    search: action.payload.search,
                },
            };

        case 'setItemToDelete':
            return {
                ...state,
                itemToDelete: action.payload.item,
                viewState: action.payload.item === null ? 'list' : 'deleteConfirmation',
            };
    }
};

const IdentityProvidersList = ({ setItemToEdit, addNotification }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { pageSize, page, total } = state.paging;
    const { search, orderBy, orderDirection } = state.filter;

    const tenantId = getTenantId();

    const modalContainerRef = useRef<HTMLDivElement | null>(null);

    const fetchItems = useCallback(
        async (filters: Filter) => {
            dispatch({ type: 'itemsLoadRequest' });
            try {
                const response = await getIdentityProviderList(filters);
                dispatch({ type: 'itemsLoadSuccess', payload: { response } });
            } catch (error) {
                dispatch({ type: 'itemsLoadFail' });
                addNotification({
                    type: 'error',
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        },
        [addNotification],
    );

    const onRefresh = () => {
        fetchItems({
            limit: pageSize,
            page,
            search,
            orderBy,
            orderDirection: orderDirection,
        });
    };

    const onSort = ({
        orderBy,
        direction,
    }: {
        orderBy: keyof IdentityProviderAPI;
        direction: OrderDirection;
    }) => {
        dispatch({
            type: 'sort',
            payload: {
                orderBy,
                orderDirection: direction,
            },
        });
    };

    const onPage = (page: number) => {
        dispatch({
            type: 'page',
            payload: { page },
        });
    };

    const onFilter = (searchQuery: string) => {
        dispatch({
            type: 'filter',
            payload: { search: searchQuery },
        });
    };

    const onDelete = (item: IdentityProviderAPI) => {
        dispatch({
            type: 'setItemToDelete',
            payload: { item },
        });
    };

    const onConfirmDelete = async () => {
        if (state.itemToDelete === null) {
            dispatch({
                type: 'setItemToDelete',
                payload: { item: null },
            });
            return;
        }

        try {
            await deleteIdentityProvider(state.itemToDelete?.id);
            await fetchItems(state.filter);
            addNotification({
                type: 'success',
                message: `${
                    state.itemToDelete.developerName ?? 'Provider'
                } has been successfully deleted.`,
            });
        } catch (e) {
            addNotification({
                type: 'error',
                message: (e as Error).message,
            });
        } finally {
            dispatch({
                type: 'setItemToDelete',
                payload: { item: null },
            });
        }
    };

    const onCancelDelete = () => {
        dispatch({
            type: 'setItemToDelete',
            payload: { item: null },
        });
    };

    const createItem = () => {
        setItemToEdit({
            attributeMappings: {},
            elementType: ElementType.IdentityProvider,
            developerName: '',
            developerSummary: '',
            type: null,
            id: '',
            whoCreated: null,
            whoModified: null,
            whoOwner: null,
        });
    };

    const columns: TableColumnList<IdentityProviderAPI> = [
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    direction={orderBy === 'developerName' ? orderDirection ?? 'ASC' : null}
                    onSort={(direction: OrderDirection) =>
                        onSort({ orderBy: 'developerName', direction })
                    }
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }) => (
                <button
                    className="link-emulate"
                    onClick={() => setItemToEdit(item)}
                    title={`Edit ${item.developerName ?? 'Provider'}`}
                    aria-label={`Edit ${item.developerName ?? 'Provider'}`}
                    type="button"
                >
                    {item.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type,
            renderCell: ({ item }) =>
                (translations as Record<string, string>)[
                    `IDENTITY_PROVIDER_${item.type ?? ''}_label`
                ],
            size: '11rem',
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    direction={orderBy === 'dateModified' ? orderDirection ?? 'ASC' : null}
                    onSort={(direction: OrderDirection) =>
                        onSort({ orderBy: 'dateModified', direction })
                    }
                >
                    {translations.COMMON_TABLE_last_modified}
                </Sortable>
            ),
            renderCell: ({ item }) =>
                new Date(item.dateModified ?? '').toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item }) => <div>{item.developerSummary}</div>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_saml_metadata,
            renderCell: ({ item }) => {
                if (item.type !== idpTypes.saml) {
                    return null;
                }

                const href = `${ENGINE_API_URL ?? ''}/api/identityprovider/1/${tenantId}/${
                    item.id
                }/saml/metadata`;
                return (
                    <a href={href} target="_blank" rel="noopener noreferrer">
                        {href}
                    </a>
                );
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }: { item: IdentityProviderAPI }) => {
                const providerName = item.developerName ?? 'Provider';
                return (
                    <div className="action-btn-wrapper">
                        <button
                            title={`Delete ${providerName}`}
                            className="table-icon table-icon-delete"
                            aria-label={`Delete ${providerName}`}
                            onClick={() => onDelete(item)}
                            type="button"
                        >
                            <Trash />
                        </button>
                    </div>
                );
            },
            size: '5rem',
        },
    ];

    useEffect(() => {
        fetchItems({
            search,
            limit: pageSize,
            page,
            orderBy,
            orderDirection,
        });
    }, [fetchItems, orderBy, orderDirection, page, search, pageSize]);

    return (
        <div className="admin-page flow-wrapper" ref={modalContainerRef}>
            <ConfirmModal
                show={state.viewState === 'deleteConfirmation'}
                title={translations.IDENTITY_PROVIDER_delete_confirmation_title}
                messages={[
                    stringReplace(translations.IDENTITY_PROVIDER_delete_confirmation_message, {
                        name: state.itemToDelete?.developerName ?? '',
                    }),
                    translations.GENERAL_cannot_be_undone,
                ]}
                buttonStyle="danger"
                buttonCaption="Delete"
                onCancel={onCancelDelete}
                onConfirm={onConfirmDelete}
                container={modalContainerRef.current}
            />

            <PageHeader
                title="Identity Providers"
                onAdd={createItem}
                onRefresh={onRefresh}
                onSearch={onFilter}
                searchQuery={state.filter.search}
                searchPlaceholderText="Search"
                addText="New Identity Provider"
                refreshTitle="Refresh Results"
            />

            <Table<IdentityProviderAPI>
                wrapperClassName="margin-top"
                columns={columns}
                items={state.items}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: onPage,
                }}
                isLoading={state.viewState === 'loading'}
            />
        </div>
    );
};

export default IdentityProvidersList;

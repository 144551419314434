const Table = () => (
    <div className="page-component-table custom-table-multiple page-component">
        <div className="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Forename</th>
                        <th>Surname</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="table-cell-string">Erkin</td>
                        <td className="table-cell-string">Baha</td>
                    </tr>
                    <tr>
                        <td className="table-cell-string">Karina</td>
                        <td className="table-cell-string">Teodor</td>
                    </tr>
                    <tr>
                        <td className="table-cell-string">Marina</td>
                        <td className="table-cell-string">Rígán</td>
                    </tr>
                    <tr className="selected">
                        <td className="table-cell-string">York</td>
                        <td className="table-cell-string">Nika (selected)</td>
                    </tr>
                    <tr>
                        <td className="table-cell-string">Sofie</td>
                        <td className="table-cell-string">Estel</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
);

export default Table;

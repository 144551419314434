import translations from '../../../../../../../../translations';
import type {
    ComponentConfigurationOptions,
    ComposerElement,
    ValueElementIdReferenceAPI,
} from '../../../../../../../../types';
import { isNullOrEmpty } from '../../../../../../../../utils/guard';
import FormGroup from '../../../../../../../generic/FormGroup';
import HTMLInput from '../../../../../../../generic/HTMLEditor/HTMLInput';
import InputOrValuePicker from '../../../../../../../generic/InputOrValuePicker';
import { COMPONENT_CONFIGURATION_LABELS as componentConfigurationLabels } from '../../../../../../constants';
import { usePageEditor } from '../../../../../PageEditorProvider';
import ConfigSection from '../../ConfigSection';

interface Props {
    generalOptions: { [key: string]: boolean };
    generalValues: Partial<ComposerElement>;
    requiredConfiguration: ComponentConfigurationOptions;
    updateName: (name: string) => void;
    updateLabel: (label: string) => void;
    updateContent: (content: string) => void;
    updateImageUri: (imageUri: string) => void;
    updateSource: (source: string | ValueElementIdReferenceAPI | null) => void;
    updateWidth: (width: number) => void;
    updateHeight: (height: number) => void;
}

const GeneralSection = ({
    generalOptions,
    generalValues,
    requiredConfiguration,
    updateName,
    updateLabel,
    updateContent,
    updateImageUri,
    updateSource,
    updateWidth,
    updateHeight,
}: Props) => {
    const { container } = usePageEditor();

    if (!(generalOptions || generalValues)) {
        return null;
    }

    const { developerName, label, content, imageUri, width, height, attributes } = generalValues;

    const { hasName, hasLabel, hasContent, hasImageUri, hasWidth, hasHeight, hasSource } =
        generalOptions ?? {};

    const {
        isNameRequired,
        isLabelRequired,
        isContentRequired,
        isImageUriRequired,
        isSourceRequired,
        isWidthRequired,
        isHeightRequired,
    } = requiredConfiguration;

    const source = attributes?.['$src'];

    return (
        <ConfigSection dataTestId="general-section">
            <>
                {hasName && (
                    <FormGroup
                        label={componentConfigurationLabels['NAME']}
                        htmlFor="component-name"
                        isRequired={isNameRequired}
                        isValid={!isNullOrEmpty(developerName)}
                        validationMessage={translations.PAGE_BUILDER_name_field_validation_message}
                        showValidation={true}
                    >
                        <input
                            className="form-control"
                            id="component-name"
                            type="text"
                            value={developerName || ''}
                            onChange={(event) => updateName(event.target.value)}
                            title="Component name"
                            required={isNameRequired}
                        />
                    </FormGroup>
                )}
            </>

            <>
                {hasLabel && (
                    <FormGroup
                        label={componentConfigurationLabels['LABEL']}
                        htmlFor="component-label"
                        isRequired={isLabelRequired}
                    >
                        <input
                            className="form-control"
                            id="component-label"
                            type="text"
                            value={label || ''}
                            onChange={(event) => {
                                updateLabel(event.target.value);
                            }}
                            title="Component label"
                            required={isLabelRequired}
                        />
                    </FormGroup>
                )}
            </>

            <>
                {hasContent && (
                    <FormGroup
                        label={componentConfigurationLabels['CONTENT']}
                        htmlFor="component-content"
                        isRequired={isContentRequired}
                    >
                        <HTMLInput
                            contentValue={content || ''}
                            onChange={(userContent) => updateContent(userContent)}
                            hasLinkPlugin={true}
                            hasImagePlugin={true}
                            hasValuePickerPlugin={true}
                            height={300}
                        />
                    </FormGroup>
                )}
            </>

            <>
                {hasImageUri && (
                    <FormGroup
                        label={componentConfigurationLabels['IMAGE_URI']}
                        htmlFor="component-imageuri"
                        isRequired={isImageUriRequired}
                    >
                        <input
                            className="form-control"
                            id="component-imageuri"
                            type="text"
                            value={imageUri || ''}
                            onChange={(event) => {
                                updateImageUri(event.target.value);
                            }}
                            title="Component image URI"
                            required={isImageUriRequired}
                        />
                    </FormGroup>
                )}
            </>

            <>
                {hasSource && (
                    <FormGroup
                        label={componentConfigurationLabels['SOURCE']}
                        htmlFor="component-source"
                        isRequired={isSourceRequired}
                    >
                        <InputOrValuePicker
                            value={source ?? null}
                            onChangeAsValueReference={updateSource}
                            contentType={'ContentString'}
                            includeSystemValues={false}
                            container={container}
                        >
                            <input
                                placeholder="https://example.com"
                                className="form-control"
                                id="component-source"
                                type="text"
                                value={(source as string) ?? ''}
                                onChange={(event) => {
                                    updateSource(event.target.value);
                                }}
                                title="Source URL"
                                required={isSourceRequired}
                            />
                        </InputOrValuePicker>
                    </FormGroup>
                )}
            </>

            <>
                {hasWidth && (
                    <FormGroup
                        label={componentConfigurationLabels['WIDTH']}
                        htmlFor="component-width"
                        isRequired={isWidthRequired}
                    >
                        <input
                            className="form-control"
                            id="component-width"
                            type="number"
                            value={width || ''}
                            onChange={(event) => {
                                updateWidth(
                                    Number.isNaN(event.target.valueAsNumber)
                                        ? 0
                                        : event.target.valueAsNumber,
                                );
                            }}
                            title="Component width"
                            required={isWidthRequired}
                        />
                    </FormGroup>
                )}
            </>

            <>
                {hasHeight && (
                    <FormGroup
                        label={componentConfigurationLabels['HEIGHT']}
                        htmlFor="component-height"
                        isRequired={isHeightRequired}
                    >
                        <input
                            className="form-control"
                            id="component-height"
                            type="number"
                            value={height || ''}
                            onChange={(event) => {
                                updateHeight(
                                    Number.isNaN(event.target.valueAsNumber)
                                        ? 0
                                        : event.target.valueAsNumber,
                                );
                            }}
                            title="Component height"
                            required={isHeightRequired}
                        />
                    </FormGroup>
                )}
            </>
        </ConfigSection>
    );
};

export default GeneralSection;

import ButtonDanger from '../../../buttons/ButtonDanger';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const Outcomes = ({ label, isRequired }: { label: string; isRequired: boolean }) => {
    return (
        <div data-testid="component-preview-outcomes" className="component-preview-outcomes">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            <ButtonPrimary>Outcome 1</ButtonPrimary>
            <ButtonDefault>Outcome 2</ButtonDefault>
            <ButtonDanger>Outcome 3</ButtonDanger>
        </div>
    );
};

export default Outcomes;

import Modal from '../generic/modal/GenericModal';
import { useState } from 'react';
import { addIntegrationAccount, validateIntegrationAccount } from '../../sources/tenant';
import type { AddNotification } from '../../types';
import translations from '../../translations';
import { ButtonFlavor, ButtonType, ExButton, ExInput } from '@boomi/exosphere';

interface Props {
    tenantId: string;
    show: boolean;
    onAdded: (username: string, accountId: string, apiKey: string) => void;
    onClose: () => void;
    addNotification: AddNotification;
}

const AddIntegrationAccountModal = ({
    tenantId,
    show,
    onAdded,
    onClose,
    addNotification,
}: Props) => {
    const [username, setUsername] = useState('');
    const [accountId, setAccountId] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const onUsernameChange = (e: Event) => {
        setUsername((e.target as HTMLInputElement).value);
    };

    const onAccountIdChange = (e: Event) => {
        setAccountId((e.target as HTMLInputElement).value);
    };

    const onAPIKeyChange = (e: Event) => {
        setApiKey((e.target as HTMLInputElement).value);
    };

    const isAccountIdVaild = () => {
        return accountId !== '' && /\w{0,25}-\w{6}/i.test(accountId);
    };

    const onAdd = async () => {
        setHasSubmitted(true);

        if (isAccountIdVaild() && apiKey) {
            try {
                const isValid = await validateIntegrationAccount(
                    tenantId,
                    username,
                    accountId,
                    apiKey,
                );

                if (!isValid) {
                    addNotification({
                        type: 'error',
                        message: translations.TENANT_integration_accounts_invalid,
                        isPersistent: true,
                    });
                    return;
                }

                await addIntegrationAccount(tenantId, username, accountId, apiKey);

                addNotification({
                    type: 'success',
                    message: translations.TENANT_integration_accounts_added_success,
                    isPersistent: false,
                });

                onAdded(username, accountId, apiKey);
                setAccountId('');
                setApiKey('');
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        }
    };

    const onCancel = () => {
        setAccountId('');
        setApiKey('');
        onClose();
    };

    return (
        <Modal
            onHide={onCancel}
            title="Add Integration Account"
            show={show}
            footerClassName="add-integration-account-modal-footer"
            renderBody={() => (
                <>
                    <ExInput
                        label={translations.TENANT_integration_accounts_username}
                        value={username}
                        onChange={onUsernameChange}
                        required={true}
                        invalid={hasSubmitted && !username}
                        error-msg={translations.TENANT_integration_accounts_username_required}
                        type="text"
                        id="add-integration-account-username"
                        data-testid="add-integration-account-username"
                    />
                    <ExInput
                        label={translations.TENANT_integration_accounts_account_id}
                        value={accountId}
                        onChange={onAccountIdChange}
                        required={true}
                        invalid={hasSubmitted && !isAccountIdVaild()}
                        error-msg={translations.TENANT_integration_accounts_account_id_required}
                        type="text"
                        id="add-integration-account-id"
                        data-testid="add-integration-account-id"
                    />
                    <ExInput
                        label={translations.TENANT_integration_accounts_api_key}
                        value={apiKey}
                        onChange={onAPIKeyChange}
                        required={true}
                        invalid={
                            hasSubmitted &&
                            !/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/.test(apiKey)
                        }
                        error-msg={translations.TENANT_integration_accounts_api_key_required}
                        type="password"
                        togglePassword={true}
                        id="add-integration-account-api-key"
                        data-testid="add-integration-account-api-key"
                    />
                </>
            )}
            renderFooter={() => (
                <>
                    <ExButton
                        type={ButtonType.SECONDARY}
                        flavor={ButtonFlavor.BRANDED}
                        onClick={onCancel}
                    >
                        {translations.COMMON_cancel}
                    </ExButton>
                    <ExButton
                        type={ButtonType.PRIMARY}
                        flavor={ButtonFlavor.BRANDED}
                        onClick={onAdd}
                    >
                        Add
                    </ExButton>
                </>
            )}
        />
    );
};

export default AddIntegrationAccountModal;

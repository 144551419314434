import { guid } from '../../../../../../utils/guid';

interface Props {
    options: { [key: string]: string };
    selectedOption: string;
    updateSelectedOption: (option: string) => void;
}

const RadioToggle = ({ options, selectedOption, updateSelectedOption }: Props) => {
    // Make sure we got unique ID for options so if we have multiple toggles with same options they don't
    // trigger each other.
    const optionGuid = guid();

    const renderOptions = () => {
        return Object.values(options).map((option, index) => (
            <label
                // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
                key={index}
                className={`radio-button-label ${selectedOption === option ? 'selected' : ''}`}
                htmlFor={`${option}-${optionGuid}`}
            >
                <input
                    className="radio-button-input"
                    type="radio"
                    id={`${option}-${optionGuid}`}
                    value={option}
                    onChange={() => updateSelectedOption(option)}
                    checked={selectedOption === option}
                />
                {option.toLowerCase()}
            </label>
        ));
    };

    return (
        <fieldset className="radio-button-group horizontal" data-testid="radio-toggle">
            {renderOptions()}
        </fieldset>
    );
};

export default RadioToggle;

import type {
    Box,
    Dimensions,
    EdgeCollection,
    FlowGraphGroupElementResponseAPI,
    FlowGraphMapElementResponseAPI,
    FlowGraphOutcomeResponseAPI,
    Edge,
    Point,
    Side,
} from '../../../types';
import { getByID } from '../../../utils/collection';
import { isNullOrEmpty } from '../../../utils/guard';
import { addPositions, subtractPositions } from '../../graph/utils';
import { getElementDimensions, getElementOffset, getNodePortPosition, reverseSide } from './node';

/** Returns a side if the control point is to one side of BOTH bounding boxes, otherwise null */
const getControlPointSide = (controlPoint: Point, boundingBoxes: [Box, Box]): Side | null => {
    const [originBox, nextBox] = boundingBoxes;

    if (controlPoint.x < originBox.x && controlPoint.x < nextBox.x) {
        return 'left';
    }
    if (
        controlPoint.x > originBox.x + originBox.width &&
        controlPoint.x > nextBox.x + nextBox.width
    ) {
        return 'right';
    }
    if (controlPoint.y < originBox.y && controlPoint.y < nextBox.y) {
        return 'top';
    }
    if (
        controlPoint.y > originBox.y + originBox.height &&
        controlPoint.y > nextBox.y + nextBox.height
    ) {
        return 'bottom';
    }

    return null;
};

/** Some points may be empty after `drawControlPointStraightLines`.
 * Fill in those empty points with an elbow bend */
const drawRemainingElbowBends = (
    controlPoint: Point,
    offsetFromMapElement: Point,
    offsetNextMapElement: Point,
    offsetMapElement: Point,
    width: number,
    height: number,
    nextHeight: number,
): { outsidePoint: Point; insidePoint: Point } => {
    // elbow bend to/from the control point
    if (controlPoint.y < offsetFromMapElement.y && controlPoint.y < offsetNextMapElement.y) {
        // In╌╌2,3
        //  ┊   │
        // Out  4
        // the control point is above both map elements
        const outsidePoint = addPositions(
            getNodePortPosition(width, height, 'top'),
            offsetMapElement,
        );
        const insidePoint = {
            x: outsidePoint.x,
            y: controlPoint.y,
        };
        return { outsidePoint, insidePoint };
    }
    if (
        controlPoint.y > offsetFromMapElement.y + height &&
        controlPoint.y > offsetNextMapElement.y + nextHeight
    ) {
        // Out  4
        //  ┊   │
        // In╌╌2,3
        // the control point is below both map elements
        const outsidePoint = addPositions(
            getNodePortPosition(width, height, 'bottom'),
            offsetMapElement,
        );
        const insidePoint = {
            x: outsidePoint.x,
            y: controlPoint.y,
        };
        return { outsidePoint, insidePoint };
    }
    // Out╌╌In
    //      ┊
    //     2,3──4
    // control point is to the left or right
    const side = controlPoint.x < offsetMapElement.x ? 'left' : 'right';
    const outsidePoint = addPositions(getNodePortPosition(width, height, side), offsetMapElement);
    const insidePoint = { x: controlPoint.x, y: outsidePoint.y };
    return { outsidePoint, insidePoint };
};

/** Draw a C shape path towards the side of control point */
const drawCShapePath = (
    controlPoint: Point,
    boundingBoxes: [Box, Box],
    controlPointSide: Side,
): Point[] => {
    const [originBox, nextBox] = boundingBoxes;
    const points: Point[] = [];
    // 1╌╌2╌╌3
    // ┊     ┊
    // x     4
    // outcome starts at the middle side of the C shape
    points[0] = addPositions(
        getNodePortPosition(originBox.width, originBox.height, controlPointSide),
        originBox,
    );

    // 1╌╌2╌╌3
    // ┊     ┊
    // 0     x
    // outcome ends at the middle side of the C shape
    points[4] = addPositions(
        getNodePortPosition(nextBox.width, nextBox.height, controlPointSide),
        nextBox,
    );

    // set axis that the C path follows in the middle and start
    const cMiddleAxis = controlPointSide === 'top' || controlPointSide === 'bottom' ? 'x' : 'y';
    const cStartAxis = cMiddleAxis === 'x' ? 'y' : 'x';

    points[1] = { x: 0, y: 0 };
    points[3] = { x: 0, y: 0 };

    // x──2╌╌3
    // │     ┊
    // 0     4
    // outcome goes from point 0 to in line with the control point
    points[1][cStartAxis] = controlPoint[cStartAxis];
    points[1][cMiddleAxis] = points[0][cMiddleAxis];

    // 1──2──x
    // │     │
    // 0     4
    // outcome goes from control point to in line with last point
    points[3][cStartAxis] = controlPoint[cStartAxis];
    points[3][cMiddleAxis] = points[4][cMiddleAxis];

    // 1──x──3
    // │     │
    // 0     4
    points[2] = controlPoint;

    return points;
};

/** Draw a Z shape path between the map elements and through the control point. */
const drawZShapePath = (controlPoint: Point, boundingBoxes: [Box, Box]): Point[] => {
    // because map elements are shorter than they are wide,
    // a Z shape will always be shorter than a └┐ shape

    const [originBox, nextBox] = boundingBoxes;

    const points: Point[] = [];

    // the side that the Z shape starts from
    const startSide = controlPoint.x < originBox.x ? 'left' : 'right';

    // x╌╌1
    //    2
    //    3╌╌4
    points[0] = addPositions(
        getNodePortPosition(originBox.width, originBox.height, startSide),
        originBox,
    );

    // 0╌╌1
    //    2
    //    3╌╌x
    const endSide = reverseSide(startSide);
    points[4] = addPositions(getNodePortPosition(nextBox.width, nextBox.height, endSide), nextBox);

    points[1] = { x: 0, y: 0 };
    points[3] = { x: 0, y: 0 };

    // 0──x
    //    2
    //    3╌╌4
    points[1].x = controlPoint.x;
    points[1].y = points[0].y;

    // 0──1
    //    2
    //    x──4
    points[3].x = controlPoint.x;
    points[3].y = points[4].y;

    // 0──1
    //    x
    //    3──4
    points[2] = controlPoint;

    return points;
};

/** Draw any straight lines where the control point is
 * directly in line with a side of the map element. */
const drawControlPointStraightLines = (
    controlPoint: Point,
    boundingBox: Box,
): [Point | null, Point | null] => {
    // 0╌╌1,2
    // the inside point (1) is on top of the control point (2) as there is no bend required
    // the outside point (0) is either the start or end of the outcome
    let outsidePoint: Point | null = null;
    let insidePoint: Point | null = null;
    // straight line between offsetMapElement and control point
    if (controlPoint.x >= boundingBox.x && controlPoint.x <= boundingBox.x + boundingBox.width) {
        // outcome goes straight vertically to/from a control point
        insidePoint = controlPoint;
        outsidePoint = { x: controlPoint.x, y: 0 };
        if (controlPoint.y >= boundingBox.y) {
            outsidePoint.y = boundingBox.y + boundingBox.height;
        } else {
            outsidePoint.y = boundingBox.y;
        }
    } else if (
        controlPoint.y >= boundingBox.y &&
        controlPoint.y <= boundingBox.y + boundingBox.height
    ) {
        // outcome goes straight horizontally to/from a control point
        insidePoint = controlPoint;
        outsidePoint = { x: 0, y: controlPoint.y };
        if (controlPoint.x >= boundingBox.x) {
            outsidePoint.x = boundingBox.x + boundingBox.width;
        } else {
            outsidePoint.x = boundingBox.x;
        }
    }
    return [outsidePoint, insidePoint];
};

/** Draws a top or left loop between two elements with no space in between */
const drawAdjacentLoop = (
    boundingBoxes: [Box, Box],
    fromSide: number,
    nextSide: number,
    side: Side, // top or left
): Point[] => {
    const [originBox, nextBox] = boundingBoxes;
    // left side example:
    // 1,2╌╌0
    // ┊
    // 3╌╌4
    // start at the middle of the from element side
    const point0 = addPositions(
        getNodePortPosition(originBox.width, originBox.height, side),
        originBox,
    );
    // the next two points are the same: directly above or left of point 0
    // we allow a 30 unit space from the minimum side for the loop
    // x────0
    // ┊
    // 3╌╌4
    const loopAxisMin = Math.min(fromSide, nextSide) - 30;
    const axis = side === 'left' ? 'x' : 'y';
    const point1 = { ...point0, [axis]: loopAxisMin };
    const point2 = point1;
    // fill the remaining points 3 and 4
    // 1,2──0
    // │
    // x──x
    const { outsidePoint: point4, insidePoint: point3 } = drawRemainingElbowBends(
        point2,
        originBox,
        nextBox,
        nextBox,
        nextBox.width,
        nextBox.height,
        nextBox.height,
    );
    return [point0, point1, point2, point3, point4];
};

interface OffsetsWithDistance {
    distanceToOffset: number | null;
    startOffset: Point;
    endOffset: Point;
}

/**
 * If there is space between the elements: draw the shortest Z path between two opposite sides,
 * otherwise: draw a vertical or horizontal loop between the elements
 */
const drawNoControlPointPath = (boundingBoxes: [Box, Box]): Point[] => {
    const [originBox, nextBox] = boundingBoxes;

    const fromLeft = originBox.x;
    const fromRight = originBox.x + originBox.width;
    const fromTop = originBox.y;
    const fromBottom = originBox.y + originBox.height;

    const nextLeft = nextBox.x;
    const nextRight = nextBox.x + nextBox.width;
    const nextTop = nextBox.y;
    const nextBottom = nextBox.y + nextBox.height;

    if (
        // the left/right sides are touching or the elements are overlapping
        // [next][from][next]
        (nextRight >= fromLeft &&
            nextLeft <= fromRight &&
            nextBottom > fromTop &&
            nextTop < fromBottom) ||
        // the corners are touching
        // [next]      [next]
        //       [from]
        // [next]      [next]
        ((nextRight === fromLeft || nextLeft === fromRight) &&
            (nextBottom === fromTop || nextTop === fromBottom))
    ) {
        // draw a top loop like this:
        // 1,2╌╌╌3
        //  ┊    ┊
        //  0    ┊
        //       4
        return drawAdjacentLoop(boundingBoxes, fromTop, nextTop, 'top');
    }

    if (
        // the top/bottom sides are touching
        // [next]
        // [from]
        // [next]
        nextRight > fromLeft &&
        nextLeft < fromRight &&
        (nextBottom === fromTop || nextTop === fromBottom)
    ) {
        // draw a left loop like this:
        // 1,2╌╌0
        // ┊
        // 3╌╌4
        return drawAdjacentLoop(boundingBoxes, fromLeft, nextLeft, 'left');
    }

    // draw the shortest valid path between two opposite sides
    const bestOffsets = (['top', 'bottom', 'left', 'right'] as Side[]).reduce(
        (currentBestOffsets: OffsetsWithDistance, side: Side): OffsetsWithDistance => {
            const startOffset = addPositions(
                getNodePortPosition(originBox.width, originBox.height, side),
                originBox,
            );
            const endOffset = addPositions(
                getNodePortPosition(nextBox.width, nextBox.height, reverseSide(side)),
                nextBox,
            );

            if (
                // ignore left side if endOffset is to the right of startOffset
                (side === 'left' && endOffset.x >= startOffset.x) ||
                // ignore right side if endOffset is to the left of startOffset
                (side === 'right' && endOffset.x <= startOffset.x)
            ) {
                return currentBestOffsets;
            }

            const difference = subtractPositions(endOffset, startOffset);
            // Pythagoras' theorem
            // |\
            // b c
            // |_a_\
            // a² + b² = c²
            // where a and b form a right angle and c is the hypotenuse of the triangle
            // c = √(a² + b²)
            const distanceToOffset = Math.sqrt(difference.x ** 2 + difference.y ** 2);
            if (
                currentBestOffsets.distanceToOffset === null ||
                currentBestOffsets.distanceToOffset > distanceToOffset
            ) {
                return { distanceToOffset, startOffset, endOffset };
            }
            return currentBestOffsets;
        },
        {
            distanceToOffset: null,
            startOffset: { x: 0, y: 0 },
            endOffset: { x: 0, y: 0 },
        },
    );

    const { startOffset, endOffset } = bestOffsets;

    // 0╌╌1
    //    ┊
    //    2╌╌3
    const midpoint: Point = {
        x: startOffset.x + (endOffset.x - startOffset.x) / 2,
        y: startOffset.y + (endOffset.y - startOffset.y) / 2,
    };

    // draw elbow bend through the midpoint, finishing in line with the last point
    const isHorizontal =
        nextBox.x > originBox.x + originBox.width || // right side
        nextBox.x + nextBox.width < originBox.x; // left side
    const point1 = isHorizontal
        ? { x: midpoint.x, y: startOffset.y }
        : { x: startOffset.x, y: midpoint.y };
    const point2 = isHorizontal
        ? { x: midpoint.x, y: endOffset.y }
        : { x: endOffset.x, y: midpoint.y };

    return [startOffset, point1, point2, endOffset];
};

/** Draw a loop back to the from element with a control point */
const drawControlPointLoop = (controlPoint: Point, boundingBox: Box, controlPointSide: Side) => {
    const sidePoint = addPositions(
        getNodePortPosition(boundingBox.width, boundingBox.height, controlPointSide),
        boundingBox,
    );

    // vertical example:
    // 1───2
    // │   │
    // 0   3
    const points = [{ ...sidePoint }, controlPoint, { ...sidePoint }, { ...sidePoint }];

    // flip x and y for loops coming out the left/right sides
    const isVertical = ['top', 'bottom'].includes(controlPointSide);
    const x = isVertical ? 'x' : 'y';
    const y = isVertical ? 'y' : 'x';

    // 1───x
    // ┊   ┊
    // 0   3
    // same distance from the center as the control point, parallel with element side
    let distanceFromCenter = sidePoint[x] - controlPoint[x];
    if (distanceFromCenter === 0) {
        // minimum absolute distance of 5 to avoid loop going back on itself
        distanceFromCenter = 5;
        points[1][x] = sidePoint[x] - distanceFromCenter;
    }
    points[2][x] = sidePoint[x] + distanceFromCenter;
    points[2][y] = controlPoint[y];

    // 1───2
    // │   │
    // x   x
    // make start and end points line up with points 1 and 2
    points[0][x] = controlPoint[x];
    points[3][x] = points[2][x];

    return points;
};

/** Draw a loop back to the from element */
export const drawNoControlPointLoop = (boundingBox: Box) => {
    const loopWidth = 40;
    const loopHeight = 30;
    const side = 'top';
    const mockControlPoint = addPositions(
        getNodePortPosition(boundingBox.width, boundingBox.height, side),
        boundingBox,
    );

    mockControlPoint.x -= loopWidth;
    mockControlPoint.y -= loopHeight;

    const points = drawControlPointLoop(mockControlPoint, boundingBox, side);

    return points;
};

export const getHeadRotation = (points: Point[]): 0 | 90 | 180 | 270 => {
    const lastPoint = points[points.length - 1];
    const penultimatePoint = points[points.length - 2];

    const headRotation =
        penultimatePoint.x < lastPoint.x
            ? 90
            : penultimatePoint.y < lastPoint.y
              ? 180
              : penultimatePoint.x > lastPoint.x
                ? 270
                : 0;

    return headRotation;
};

const isPointWithinBounds = ({
    point: { x: pointX, y: pointY },
    bounds: { x: boundsX, y: boundsY, width: boundsWidth, height: boundsHeight },
}: {
    point: Point;
    bounds: Box;
}) => {
    // If the point's x coordinate lies with the bound's horizontal area
    // │ ●     │
    // and the point's y coordinate lies with the bound's vertical area,
    //  ───────
    //   ●
    //  ───────
    // then the point is within the bound
    // ┌───────┐
    // │ ●     │
    // └───────┘
    if (
        // Check if the point is inside the bounds horizontally
        pointX >= boundsX &&
        pointX <= boundsX + boundsWidth &&
        // Check if the point is inside the bounds vertically
        pointY >= boundsY &&
        pointY <= boundsY + boundsHeight
    ) {
        return true;
    }
    return false;
};

const isControlPointLegal = (controlPoint: Point, boundingBoxes: [Box, Box]) => {
    // Illegal control point positions:
    // ⌧        ⌧
    //  [⌧]───┐
    //       [⌧]
    // ⌧        ⌧
    //
    // [] - from and next map elements.
    // ⌧ - illegal control point positions.

    if (
        isPointWithinBounds({ point: controlPoint, bounds: boundingBoxes[0] }) ||
        isPointWithinBounds({ point: controlPoint, bounds: boundingBoxes[1] })
    ) {
        return false;
    }

    const outsideLeft = controlPoint.x < boundingBoxes[0].x && controlPoint.x < boundingBoxes[1].x;
    const outsideRight =
        controlPoint.x > boundingBoxes[0].x + boundingBoxes[0].width &&
        controlPoint.x > boundingBoxes[1].x + boundingBoxes[1].width;
    const outsideTop = controlPoint.y < boundingBoxes[0].y && controlPoint.y < boundingBoxes[1].y;
    const outsideBottom =
        controlPoint.y > boundingBoxes[0].y + boundingBoxes[0].height &&
        controlPoint.y > boundingBoxes[1].y + boundingBoxes[1].height;

    if ((outsideLeft || outsideRight) && (outsideTop || outsideBottom)) {
        return false;
    }

    return true;
};

export const generatePoints = (
    boundingBoxes: [Box, Box],
    controlPoint: Point | null,
    returnsToSelf: boolean,
): Point[] => {
    const [originBox, nextBox] = boundingBoxes;

    const controlPointIsLegal =
        controlPoint !== null && isControlPointLegal(controlPoint, boundingBoxes);

    if (controlPoint && controlPointIsLegal) {
        // TODO: when introducing groups
        // control points in groups are relative
        // add parent offset to get control point absolute position
        // controlPoint.x += fromParentOffsetX;
        // controlPoint.y += fromParentOffsetY;

        // Example:
        // 0╌╌1
        //    2
        //    3╌╌4
        // points[2] = {
        //     // 2 - control point
        //     x: controlPoint.x,
        //     y: controlPoint.y,
        // };

        // which side of BOTH boxes the control point is, otherwise null
        const controlPointSide = getControlPointSide(controlPoint, boundingBoxes);

        if (returnsToSelf) {
            // outcome is a loop
            const points = drawControlPointLoop(controlPoint, originBox, controlPointSide as Side);
            return points;
        }

        let [firstPoint, secondPoint] = drawControlPointStraightLines(controlPoint, originBox);
        let [lastPoint, penultimatePoint] = drawControlPointStraightLines(controlPoint, nextBox);

        // no points were defined when calculating straight lines
        // so this must be a double elbow C or Z
        if (isNullOrEmpty(firstPoint) && isNullOrEmpty(penultimatePoint)) {
            if (!isNullOrEmpty(controlPointSide)) {
                // outcome is a C shape
                const points = drawCShapePath(
                    controlPoint,
                    boundingBoxes,
                    controlPointSide as Side,
                );
                return points;
            }
            // outcome is a Z shape
            const points = drawZShapePath(controlPoint, boundingBoxes);
            return points;
        }
        // fill in missing points after straight lines to/from the control point
        // these points are filled with elbow bends to/from the control point
        if (isNullOrEmpty(firstPoint) && isNullOrEmpty(secondPoint)) {
            ({ outsidePoint: firstPoint, insidePoint: secondPoint } = drawRemainingElbowBends(
                controlPoint,
                originBox,
                nextBox,
                originBox,
                originBox.width,
                originBox.height,
                nextBox.height,
            ));
        }

        if (isNullOrEmpty(lastPoint) && isNullOrEmpty(penultimatePoint)) {
            ({ outsidePoint: lastPoint, insidePoint: penultimatePoint } = drawRemainingElbowBends(
                controlPoint,
                originBox,
                nextBox,
                nextBox,
                nextBox.width,
                nextBox.height,
                nextBox.height,
            ));
        }

        return [
            firstPoint as Point,
            secondPoint as Point,
            controlPoint,
            penultimatePoint as Point,
            lastPoint as Point,
        ];
    }

    if (returnsToSelf) {
        // outcome is a loop
        const points = drawNoControlPointLoop(originBox);
        return points;
    }

    // default Z shape outcome
    const points = drawNoControlPointPath(boundingBoxes);
    return points;
};

const outcomeToEdge = (
    outcome: FlowGraphOutcomeResponseAPI,
    /** A 2 element array of origin map element and ending map element */
    mapElements: [FlowGraphMapElementResponseAPI, FlowGraphMapElementResponseAPI],
    groupElements: FlowGraphGroupElementResponseAPI[],
): Edge => {
    const mapElementAbsolutePositions = mapElements.map((mapElement) => {
        const offset = getElementOffset(mapElement, groupElements);
        const absolutePosition = addPositions(offset, { x: mapElement.x, y: mapElement.y });
        return absolutePosition;
    }) as [Point, Point];
    const mapElementDimensions = mapElements.map(getElementDimensions) as [Dimensions, Dimensions];

    const returnsToSelf = mapElements[0].id === mapElements[1].id;

    const boundingBoxes: [Box, Box] = [
        { ...mapElementAbsolutePositions[0], ...mapElementDimensions[0] },
        { ...mapElementAbsolutePositions[1], ...mapElementDimensions[1] },
    ];

    const controlPoint = outcome?.controlPoints?.[0] ?? null;

    const points = generatePoints(boundingBoxes, controlPoint, returnsToSelf);

    const headRotation = getHeadRotation(points);

    return {
        id: outcome.id,
        label: outcome.developerName ?? '',
        controlPoint,
        points,
        headRotation,
        isSelected: false,
        nodeIds: mapElements.map((element) => element.id) as [string, string],
    };
};

export const createEdges = (
    mapElements: FlowGraphMapElementResponseAPI[],
    groupElements: FlowGraphGroupElementResponseAPI[],
) => {
    const edges = mapElements.reduce((edges, mapElement) => {
        (mapElement.outcomes ?? []).forEach((outcome) => {
            const nextMapElement = getByID(
                outcome.nextMapElementId,
                mapElements,
            ) as FlowGraphMapElementResponseAPI;

            const outcomeMapElements: [
                FlowGraphMapElementResponseAPI,
                FlowGraphMapElementResponseAPI,
            ] = [mapElement, nextMapElement];

            const edge = outcomeToEdge(outcome, outcomeMapElements, groupElements);

            edges[edge.id] = edge;
        });

        return edges;
    }, {} as EdgeCollection);

    return edges;
};

import { type ConnectedProps, connect } from 'react-redux';
import {
    acknowledgeRejectedUserInvitation,
    cancelUserInvitation,
    fetchSentUserInvitations,
} from '../../../js/actions/reduxActions/organizations';
import type { OrganizationInvite } from '../../sources/organization';
import translations from '../../translations';
import InviteList from './InviteList';

type PendingUserInviteListProps = {
    organizationId: string;
} & ConnectedProps<typeof connector>;

const mapStateToProps = ({
    organizationSentUserInvitations,
}: { organizationSentUserInvitations: OrganizationInvite[] }) => ({
    invitations: organizationSentUserInvitations,
});

const mapDispatchToProps = {
    cancelInvitation: cancelUserInvitation,
    acknowledgeRejection: acknowledgeRejectedUserInvitation,
    fetchSentUserInvitations,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const PendingUserInviteList = ({
    cancelInvitation,
    acknowledgeRejection,
    fetchSentUserInvitations,
    invitations,
    organizationId,
}: PendingUserInviteListProps) => {
    const handleAcknowledge = (id: string) => {
        acknowledgeRejection({ invitedUserId: id });
    };

    const handleCancel = (id: string) => {
        cancelInvitation({ invitedUserId: id });
    };

    return (
        <InviteList
            caption={translations.FORG_user_invite_list_caption}
            invitations={invitations}
            organizationId={organizationId}
            loadInvitations={fetchSentUserInvitations}
            onAcknowledge={handleAcknowledge}
            onCancel={handleCancel}
        />
    );
};

export default connector(PendingUserInviteList);

import { isNil } from 'ramda';
import type { BuilderWhoAPI, ItemCollectionResponse, OrganizationMinimal, UserAPI } from '../types';
import type { TenantMinimalAPI } from '../types/Tenant';
import { fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export interface OrganizationTenant {
    id: string;
    developerName: string;
    addedAt: string | null;
    addedBy: BuilderWhoAPI;
    userCount: number;
    subtenants: TenantMinimalAPI[];
}

export interface OrganizationUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    addedAt: string | null;
    addedBy: BuilderWhoAPI;
}

export type OrganizationInvite = {
    organization: OrganizationMinimal;
    completedBy: BuilderWhoAPI;
    completedAt: string | null;
    invitedBy: BuilderWhoAPI;
    invitedAt: string;
    status: 'invited' | 'accepted' | 'rejected' | 'canceled' | 'acknowledged';
} & (
    | {
          type: 'tenant';
          tenant: TenantMinimalAPI;
      }
    | {
          type: 'user';
          user: BuilderWhoAPI;
      }
);

export const getTenants = () =>
    fetchAndParse<OrganizationTenant[]>({
        url: '/api/admin/1/organization/tenants',
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getUsers = () =>
    fetchAndParse<OrganizationUser[]>({
        url: '/api/admin/1/organization/users',
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const sendTenantInvite = ({
    invitationTenantName,
    invitationTenantId,
}: {
    invitationTenantName: string;
    invitationTenantId: string;
}) => {
    let requestData = {};

    // Request body can only contain id or developerName
    if (typeof invitationTenantName === 'string') {
        requestData = { developerName: invitationTenantName };
    } else {
        requestData = { id: invitationTenantId };
    }

    return fetchAndParse<void>({
        url: '/api/admin/1/organization/invites/tenants',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body: requestData,
    });
};

export const sendUserInvite = ({
    invitationEmail,
    invitationUserId,
}: {
    invitationEmail: string;
    invitationUserId: string;
}) => {
    let requestData = {};

    // Request body can only contain id or developerName
    if (isNil(invitationEmail)) {
        requestData = { id: invitationUserId };
    } else {
        requestData = { email: invitationEmail };
    }

    return fetchAndParse<void>({
        url: '/api/admin/1/organization/invites/users',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: requestData,
    });
};

export const getSentTenantInvites = () =>
    fetchAndParse<OrganizationInvite[]>({
        url: '/api/admin/1/organization/invites/tenants',
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getSentUserInvites = () =>
    fetchAndParse<OrganizationInvite[]>({
        url: '/api/admin/1/organization/invites/users',
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getReceivedTenantInvites = () =>
    fetchAndParse<OrganizationInvite[]>({
        url: '/api/admin/1/organization/invites/?type=tenant',
        method: 'GET',
    });

export const getReceivedUserInvites = () =>
    fetchAndParse<OrganizationInvite[]>({
        url: '/api/admin/1/organization/invites/?type=user',
        method: 'GET',
    });

export const acceptTenantInvite = ({ organizationId }: { organizationId: string }) =>
    fetchAndParse<OrganizationInvite>({
        url: `/api/admin/1/organization/invites/tenants/${organizationId}`,
        method: 'PUT',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const rejectTenantInvite = ({ organizationId }: { organizationId: string }) =>
    fetchAndParse<OrganizationInvite>({
        url: `/api/admin/1/organization/invites/tenants/${organizationId}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const cancelTenantInvite = ({ invitedTenantId }: { invitedTenantId: string }) =>
    fetchAndParse<OrganizationInvite>({
        url: `/api/admin/1/organization/invites/tenants/cancel/${invitedTenantId}`,
        method: 'PATCH',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const acknowledgeRejectedTenantInvite = ({ invitedTenantId }: { invitedTenantId: string }) =>
    fetchAndParse<OrganizationInvite>({
        url: `/api/admin/1/organization/invites/tenants/acknowledge/${invitedTenantId}`,
        method: 'PATCH',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const acceptUserInvite = ({ organizationId }: { organizationId: string }) =>
    fetchAndParse<OrganizationInvite>({
        url: `/api/admin/1/organization/invites/users/${organizationId}`,
        method: 'PUT',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const rejectUserInvite = ({ organizationId }: { organizationId: string }) =>
    fetchAndParse<OrganizationInvite>({
        url: `/api/admin/1/organization/invites/users/${organizationId}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const cancelUserInvite = ({ invitedUserId }: { invitedUserId: string }) =>
    fetchAndParse<OrganizationInvite>({
        url: `/api/admin/1/organization/invites/users/cancel/${invitedUserId}`,
        method: 'PATCH',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const acknowledgeRejectedUserInvite = ({ invitedUserId }: { invitedUserId: string }) =>
    fetchAndParse<OrganizationInvite>({
        url: `/api/admin/1/organization/invites/users/acknowledge/${invitedUserId}`,
        method: 'PATCH',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

// Removes a user from the current organization
export const removeUser = ({ userId }: { userId: string }) =>
    fetchAndParse<void>({
        url: `/api/admin/1/organization/users/${userId}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

// Removes a tenant from the current organization
export const removeTenant = ({ tenantId }: { tenantId: string }) =>
    fetchAndParse<void>({
        url: `/api/admin/1/organization/tenants/${tenantId}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const provisionTenantAndOrUser = ({ provisionData }: { provisionData: unknown }) =>
    fetchAndParse<OrganizationTenant>({
        url: '/api/admin/1/organization/tenants',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: provisionData,
    });

export const getOrgTenantUsers = ({
    tenantId,
    page = 1,
    pageSize = 30,
}: { tenantId: string; page?: number; pageSize?: number }) => {
    const params = new URLSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
    });

    return fetchAndParse<ItemCollectionResponse<UserAPI>>({
        url: `/api/admin/1/organization/tenant/${tenantId}/users?${params}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

import { useNavigate } from 'react-router-dom';
import { ExIcon, IconVariant } from '@boomi/exosphere';
import { HEADER_ICON_MAPPING } from '../../../constants';
import { generateRouteUrl } from '../../../utils/routing';

interface DropdownListButtonProps {
    navigateTo: string;
    buttonText: string;
    buttonSummary: string;
    icon: string;
    tenantId: string;
    visible: boolean;
}

const DropdownListButton = ({
    navigateTo,
    buttonText,
    buttonSummary,
    icon,
    tenantId,
    visible,
}: DropdownListButtonProps) => {
    const navigate = useNavigate();

    const openAdminTab = (type: string) => {
        const route = generateRouteUrl({
            tabType: type,
            tenantId,
        });

        navigate(route);
    };

    if (!visible) {
        return null;
    }

    return (
        <button className="dropdown-link" onClick={() => openAdminTab(navigateTo)} type="button">
            <ExIcon
                className="icon-large dropdown-link-icon"
                icon={HEADER_ICON_MAPPING[icon]}
                label={buttonText}
                variant={IconVariant.DEFAULT}
            />
            <div className="dropdown-content">
                {buttonText}
                <p>{buttonSummary}</p>
            </div>
        </button>
    );
};

export default DropdownListButton;

import { ACCESS_LEVELS, VALUE_TYPES } from '../../../constants/value';
import PrimitiveContentTypeEditor from './PrimitiveContentTypeEditor';
import '../../../../../css/value-editor.less';
import { ExAlertBanner, ExInput, ExMenuItem, ExSelect, ExTextarea } from '@boomi/exosphere';
import Toggle from '../../inputs/Toggle';
import { getValueList } from '../../../sources/value';
import ObjectDataEditor from './ObjectDataEditor';
import type { ContentType, ValueElementResponseAPI } from '../../../types';
import translations from '../../../translations';
import TypeElementDropdown from '../../types/TypeElementDropdown';
import { isNullOrEmpty } from '../../../utils/guard';

interface Props {
    value: ValueElementResponseAPI;
    onChange: (value: ValueElementResponseAPI) => void;
    showValidation: boolean;
}

export const isValid = (value: ValueElementResponseAPI) => {
    const isComplexValueType =
        value.contentType === 'ContentObject' || value.contentType === 'ContentList';

    return (
        !(isNullOrEmpty(value.developerName) || isNullOrEmpty(value.contentType)) &&
        (isComplexValueType ? !isNullOrEmpty(value.typeElementId) : true)
    );
};

export const isDuplicateValueByName = async (value: ValueElementResponseAPI) => {
    const values = await getValueList({ exactSearch: value.developerName });
    return !!values
        .filter((existingValue) => existingValue.id !== value.id)
        .find((existingValue) => existingValue.developerName === value.developerName);
};

const ValueEditor = ({ value, onChange, showValidation }: Props) => {
    const onValueChange = (newValue: Partial<ValueElementResponseAPI>) => {
        let defaultObjectData = value.defaultObjectData;

        // if the content type or type has changed then we reset the object data
        if (
            (newValue.contentType && newValue.contentType !== value.contentType) ||
            (newValue.typeElementId && newValue.typeElementId !== value.typeElementId)
        ) {
            defaultObjectData = [];
        } else if (newValue.defaultObjectData !== undefined) {
            defaultObjectData = newValue.defaultObjectData;
        }

        if (
            newValue.contentType &&
            newValue.contentType !== 'ContentObject' &&
            newValue.contentType !== 'ContentList'
        ) {
            newValue.typeElementId = null;
            newValue.typeElementDeveloperName = null;
        }

        onChange({
            ...value,
            ...newValue,
            defaultObjectData,
        } as ValueElementResponseAPI);
    };

    const currentValueType = VALUE_TYPES.find((valueType) => valueType.value === value.contentType);
    const canBeFormatted = currentValueType ? currentValueType.canBeFormatted : false;
    const requiresType = currentValueType ? currentValueType.requiresType : false;
    const isPrimitive = currentValueType ? !requiresType : false;

    return (
        <div className="value-editor">
            <ExInput
                required={true}
                label={translations.VALUE_EDITOR_value_name}
                type="text"
                value={value.developerName}
                placeholder={translations.VALUE_EDITOR_value_name_placeholder}
                autofocus={true}
                onInput={(e: Event) =>
                    onValueChange({ developerName: (e.target as HTMLInputElement).value })
                }
                data-testId="value-editor-name"
                invalid={showValidation && isNullOrEmpty(value.developerName)}
            />
            <ExSelect
                required={true}
                label={translations.VALUE_EDITOR_value_content_type}
                selected={value.contentType || ''}
                onChange={(e: CustomEvent<{ value: ContentType }>) =>
                    onValueChange({ contentType: e.detail.value })
                }
                invalid={showValidation && isNullOrEmpty(value.contentType)}
                placeholder={translations.VALUE_EDITOR_value_content_type}
                data-testId="value-editor-content-type"
            >
                {VALUE_TYPES.map((item) => (
                    <ExMenuItem key={item.value} data-value={item.value}>
                        {item.label}
                    </ExMenuItem>
                ))}
            </ExSelect>
            {
                // For a complex type determine if a List or Object
                requiresType && (
                    <TypeElementDropdown
                        isRequired
                        requiredIndicator="(required)"
                        validationMessage="Value type field is required"
                        isValid={!isNullOrEmpty(value.typeElementId)}
                        showValidation={showValidation}
                        className="value-editor-type-selector"
                        typeElementId={value.typeElementId}
                        onChange={(typeElementId: string | null) =>
                            onValueChange({ typeElementId })
                        }
                    />
                )
            }
            <ExSelect
                label={translations.VALUE_EDITOR_value_access}
                selected={value.access}
                onChange={(e: CustomEvent<{ value: string }>) =>
                    onValueChange({ access: e.detail.value })
                }
            >
                {Object.values(ACCESS_LEVELS).map((item) => (
                    <ExMenuItem key={item.value} data-value={item.value}>
                        {item.label}
                    </ExMenuItem>
                ))}
            </ExSelect>
            <div className="form-group">
                <label htmlFor="value-is-versionless">
                    <Toggle
                        id="value-is-versionless"
                        isOn={!value.isVersionless}
                        onChange={({ isOn }) => onValueChange({ isVersionless: !isOn })}
                        testId="is-versionless"
                    />
                    {translations.VALUE_EDITOR_value_is_versionless}
                </label>
            </div>
            <div className="form-group">
                <label htmlFor="value-is-secret">
                    <Toggle
                        id="value-is-secret"
                        isOn={value.isSecret}
                        onChange={({ isOn }) => onValueChange({ isSecret: isOn })}
                        testId="is-secret"
                    />
                    {translations.VALUE_EDITOR_value_is_secret}
                </label>
                {value.isSecret && value.hasValue ? (
                    <ExAlertBanner open={true}>
                        {translations.VALUE_EDITOR_value_is_secret_exists}
                    </ExAlertBanner>
                ) : null}
            </div>
            {isPrimitive ? (
                <PrimitiveContentTypeEditor
                    value={value.defaultContentValue as string}
                    isRequired={false}
                    type={value.contentType}
                    format={value.contentFormat}
                    label={
                        value.contentType === 'ContentBoolean'
                            ? translations.VALUE_default_boolean
                            : translations.VALUE_default
                    }
                    placeholder={translations.VALUE_EDITOR_value_default_content_value}
                    onChange={(defaultContentValue) =>
                        onValueChange({ defaultContentValue: defaultContentValue.toString() })
                    }
                    onContentFormatChange={(format) => onValueChange({ contentFormat: format })}
                />
            ) : null}
            {value.typeElementId && requiresType ? (
                <>
                    <h3>{translations.VALUE_EDITOR_value_default_obectdata}</h3>
                    <ObjectDataEditor
                        value={value.defaultObjectData}
                        onChange={(defaultObjectData) => onValueChange({ defaultObjectData })}
                        typeElementId={value.typeElementId}
                        contentType={value.contentType}
                    />
                </>
            ) : null}
            {canBeFormatted ? (
                <ExInput
                    label={translations.VALUE_EDITOR_value_content_format}
                    id="value-presentation"
                    onInput={(e: Event) =>
                        onValueChange({ contentFormat: (e.target as HTMLInputElement).value })
                    }
                    type="text"
                    value={value.contentFormat}
                    placeholder={translations.VALUE_EDITOR_value_content_format_placeholder}
                />
            ) : null}
            <ExTextarea
                label={translations.VALUE_EDITOR_value_developer_summary}
                id="value-comments"
                onInput={(e: Event) =>
                    onValueChange({ developerSummary: (e.target as HTMLTextAreaElement).value })
                }
                value={value.developerSummary}
                placeholder={translations.VALUE_EDITOR_value_developer_summary_placeholder}
                rows={3}
            />
        </div>
    );
};

export default ValueEditor;

import classNames from 'classnames';
import type { ReactNode } from 'react';

interface Props {
    children?: ReactNode;
    className?: string | undefined;
}

const ModalFooter = ({ children, className }: Props) => (
    <footer className={classNames(className, 'modal-footer')} data-testid="dialog-footer">
        {children}
    </footer>
);

export default ModalFooter;

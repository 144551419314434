import type { ItemCollectionResponse, OrderDirection } from '../types';
import type {
    FlowUsageDashboardResponse,
    StateError,
    StateErrorsLineChartData,
    StateErrorsPieChartData,
    TimeFilters,
} from '../types/dashboard';
import type { StateResponseAPI } from '../types/states';
import { fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export interface StateFlowResponseAPI {
    count: number;
    id: string;
    developerName: string;
}

export interface DashboardLaunchesResponse {
    bucket: string | null;
    counter: number;
}

export interface DashboardFlowLaunchesResponse {
    flowId: string;
    flowName: string;
    flowLaunchTotal: number;
    flowLaunchData: DashboardLaunchesResponse[];
}

export const getFlows = (params: {
    orderBy: string;
    orderDirection: OrderDirection;
    pageSize: number;
    page: number;
    from: string;
    to: string;
}) => {
    const URLparams = new URLSearchParams();

    URLparams.append('orderBy', params.orderBy);
    URLparams.append('orderDirection', params.orderDirection);
    URLparams.append('pageSize', params.pageSize.toString());
    URLparams.append('page', params.page.toString());
    URLparams.append('from', params.from);
    URLparams.append('to', params.to);

    const url = `/api/admin/1/states/flow?${URLparams.toString()}`;

    return fetchAndParse<ItemCollectionResponse<StateFlowResponseAPI>>({
        url,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getStatesByFlow = (
    flowId: string,
    params: {
        status: 'in_progress' | 'done' | 'expired' | 'errored';
        orderBy: string;
        orderDirection: OrderDirection;
        pageSize: number;
        page: number;
        from: string;
        to: string;
    },
) => {
    const URLparams = new URLSearchParams();

    URLparams.append('status', params.status);
    URLparams.append('pageSize', params.pageSize.toString());
    URLparams.append('page', params.page.toString());
    if (params.orderBy) {
        URLparams.append('orderBy', params.orderBy);
    }
    if (params.orderDirection) {
        URLparams.append('orderDirection', params.orderDirection);
    }
    if (params.from) {
        URLparams.append('from', params.from);
    }
    if (params.to) {
        URLparams.append('to', params.to);
    }

    const url = `/api/admin/1/states/flow/${flowId}?${URLparams.toString()}`;

    return fetchAndParse<ItemCollectionResponse<StateResponseAPI>>({
        url,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getLaunchesByFlow = (flowId: string, interval: TimeFilters) => {
    const URLparams = new URLSearchParams();
    URLparams.append('interval', interval);

    const url = `/api/dashboard/1/flow/${flowId}?${URLparams.toString()}`;
    return fetchAndParse<DashboardFlowLaunchesResponse>({
        url,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getUsageForFlows = (flowIds: string[], interval: TimeFilters) => {
    const URLparams = new URLSearchParams();
    URLparams.append('interval', interval);

    const url = `/api/dashboard/1/flows?${URLparams.toString()}`;
    return fetchAndParse<FlowUsageDashboardResponse[]>({
        url,
        method: 'POST',
        headers: { ManyWhoTenant: getTenantId() },
        body: JSON.stringify(flowIds),
    });
};

export const getUsageForTenant = (interval: TimeFilters, toDate: Date) => {
    const URLparams = new URLSearchParams();
    URLparams.append('interval', interval);
    URLparams.append('toDate', toDate.toISOString());

    const url = `/api/dashboard/1/flows?${URLparams.toString()}`;
    return fetchAndParse<FlowUsageDashboardResponse[]>({
        url,
        method: 'GET',
        headers: { ManyWhoTenant: getTenantId() },
    });
};

export const getStateErrorsForTable = (
    interval: TimeFilters,
    toDate: Date,
    page: number,
    searchFilter: string,
    flowNameFilter: string,
) => {
    const URLparams = new URLSearchParams();
    URLparams.append('interval', interval);
    URLparams.append('toDate', toDate.toISOString());
    URLparams.append('page', page.toString());
    URLparams.append('searchFilter', searchFilter);
    URLparams.append('flowNameFilter', flowNameFilter);

    const url = `/api/dashboard/1/stateErrors?${URLparams.toString()}`;
    return fetchAndParse<ItemCollectionResponse<StateError>>({
        url,
        method: 'GET',
        headers: { ManyWhoTenant: getTenantId() },
    });
};

export const getStateErrorsForLineChart = (
    interval: TimeFilters,
    toDate: Date,
    searchFilter: string,
    flowNameFilter: string,
) => {
    const URLparams = new URLSearchParams();
    URLparams.append('interval', interval);
    URLparams.append('toDate', toDate.toISOString());
    URLparams.append('searchFilter', searchFilter);
    URLparams.append('flowNameFilter', flowNameFilter);

    const url = `/api/dashboard/1/stateErrorsLineChart?${URLparams.toString()}`;
    return fetchAndParse<StateErrorsLineChartData>({
        url,
        method: 'GET',
        headers: { ManyWhoTenant: getTenantId() },
    });
};

export const getStateErrorsForPieChart = (
    interval: TimeFilters,
    toDate: Date,
    searchFilter: string,
    flowNameFilter: string,
) => {
    const URLparams = new URLSearchParams();
    URLparams.append('interval', interval);
    URLparams.append('toDate', toDate.toISOString());
    URLparams.append('searchFilter', searchFilter);
    URLparams.append('flowNameFilter', flowNameFilter);

    const url = `/api/dashboard/1/stateErrorsPieChart?${URLparams.toString()}`;
    return fetchAndParse<StateErrorsPieChartData>({
        url,
        method: 'GET',
        headers: { ManyWhoTenant: getTenantId() },
    });
};

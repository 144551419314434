import { type ComponentProps, useEffect, useId, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getType, getTypeList } from '../../sources/type';
import FormGroup from '../generic/FormGroup';
import translations from '../../translations';
import type { AddNotification } from '../../types';
import type { SingleValue } from 'react-select';
import { connect } from 'react-redux';
import { addNotification } from '../../../js/actions/reduxActions/notification';
import { getSharedStyles } from '../../utils/select';

interface Props {
    typeElementId: string | null;
    onChange: (typeElementId: string | null) => void;
    addNotification: AddNotification;
    className?: string;
    label?: string;
    isRequired?: boolean;
    isValid?: boolean;
    showValidation?: boolean;
    validationMessage?: string;
    requiredIndicator?: ComponentProps<typeof FormGroup>['requiredIndicator'];
}

type TypeOption = { value: string; label: string | null };

const TypeElementDropdown = ({
    typeElementId,
    onChange,
    addNotification,
    className = '',
    label = 'What Type of Value is this?',
    isRequired = false,
    isValid = true,
    showValidation = true,
    validationMessage = 'Invalid Type selection',
    requiredIndicator = '*',
}: Props) => {
    const [selectedOption, setSelectedOption] = useState<TypeOption | null>(null);
    const [loading, setLoading] = useState(true);
    const inputId = useId();

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true);

                const type = await getType(typeElementId as string);
                setSelectedOption({ value: type.id, label: type.developerName });
            } catch (error) {
                addNotification({
                    type: 'error',
                    isPersistent: true,
                    message: (error as Error).message,
                });
            } finally {
                setLoading(false);
            }
        };

        if (typeElementId === null) {
            setSelectedOption(null);
        } else if (selectedOption?.value !== typeElementId) {
            load();
        }
    }, [typeElementId]);

    const onSelect = (value: SingleValue<TypeOption>) => {
        setSelectedOption(value);
        onChange(value ? value.value : null);
    };

    const loadOptions = async (search: string) => {
        try {
            setLoading(true);

            const types = await getTypeList({
                search,
                limit: 20,
            });

            return types.map((type) => ({ value: type.id, label: type.developerName }));
        } catch (error) {
            addNotification({
                type: 'error',
                isPersistent: true,
                message: (error as Error).message,
            });
        } finally {
            setLoading(false);
        }

        return [];
    };

    return (
        <FormGroup
            className={className}
            htmlFor={inputId}
            label={label}
            isRequired={isRequired}
            isValid={isValid}
            validationMessage={validationMessage}
            showValidation={showValidation}
            requiredIndicator={requiredIndicator}
        >
            <AsyncSelect
                inputId={inputId}
                name={inputId}
                isMulti={false}
                styles={{
                    ...getSharedStyles<{ value: string; label: string | null }>(),
                    // Custom styles
                    menu: (baseStyles) => ({
                        ...baseStyles,
                        zIndex: 'var(--exo-z-index-select-menu-dropdown)',
                    }),
                }}
                loadOptions={loadOptions}
                onChange={onSelect}
                placeholder={translations.VALUE_type_selector_placeholder_text}
                value={selectedOption}
                noOptionsMessage={() => 'No results found'}
                isLoading={loading}
                defaultOptions
            />
        </FormGroup>
    );
};

export default connect(null, { addNotification })(TypeElementDropdown);

import { Warning } from '@phosphor-icons/react';
import RuntimeMarker from '../misc/RuntimeMarker';
import { useDrag } from 'react-dnd';
import type { ReactNode } from 'react';
import type { ContainerType, PageElementType } from '../../../dnd-utils';
import type { RuntimeVersion } from '../../../../../../types';

interface Props {
    pageElementType: PageElementType;
    componentType?: string;
    containerType?: ContainerType;
    overridden?: boolean;
    label: string;
    icon: ReactNode;
    runtime?: RuntimeVersion;
}

const SidebarItem = (props: Props) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'COMPONENT',
        item: {
            pageElementType: props.pageElementType,
            componentType: props.componentType,
            containerType: props.containerType,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const opacity = isDragging ? 0.4 : 1;

    if (props.overridden) {
        return (
            <div
                title="A custom component is overriding this standard component"
                className="drag-component draggable"
                style={{ opacity }}
            >
                {props.icon}
                <span>{props.label}</span>
                {props.runtime && <RuntimeMarker runtime={props.runtime} miniMarker={true} />}
                <Warning className="margin-left-sml" size={20} />
            </div>
        );
    }

    return (
        <div
            title={`Drag ${props.label} component`}
            className="drag-component draggable"
            style={{ opacity }}
            ref={drag}
        >
            {props.icon}
            <span>{props.label}</span>
            {props.runtime && <RuntimeMarker runtime={props.runtime} miniMarker={true} />}
        </div>
    );
};

export default SidebarItem;

import { ExIconButton, IconButtonType } from '@boomi/exosphere';
import PanelPositionDropdown from './PanelPositionDropdown';

const DebugActionButtons = ({ onClose }: { onClose: () => void }) => {
    return (
        <div className="debug-action-buttons flex align gap-small">
            <PanelPositionDropdown />
            <ExIconButton
                onClick={() => onClose()}
                icon="Close"
                type={IconButtonType.SECONDARY}
                data-testid="close-debugger"
            />
        </div>
    );
};

export default DebugActionButtons;

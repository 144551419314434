import classNames from 'classnames';
import ImageComponentImg from '../../../../../../img/components/Image-component.svg';
import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

interface Props {
    developerName: string;
    imageUri: string;
    width: number;
    height: number;
    label: string;
}

const Image = ({ developerName, imageUri, width, height, label }: Props) => {
    const classes = classNames({
        'component-preview-image': !(imageUri || width || height),
    });

    const imageProps = {
        className: classes,
        src: imageUri || ImageComponentImg,
        width: width ?? 0,
        height: height ?? 0,
    };

    return (
        <div data-testid="component-preview-image">
            <PreviewComponentLabel labelText={label} />
            <div className="component-preview-image-container">
                <img data-testid="component-preview-image" {...imageProps} alt={developerName} />
            </div>
        </div>
    );
};

export default Image;

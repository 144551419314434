// Regex for characters that are hexadecimal (a-f, A-F, 0-9) in the pattern []{8}-[]{4}-[]{4}-[]{4}-[]{12}
export const REGEX_GUID =
    /[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}/;
// Regex for characters that are NOT hexadecimal (a-f, A-F, 0-9) or dashes
export const REGEX_ILLEGAL_GUID = /[^A-Fa-f0-9-]/g;

// Regex for characters NOT in (a-z, A-Z, 0-9) and not hyphens (-) or periods (.)
export const REGEX_TENANT_NAME_ILLEGAL_CHARACTERS = /[^a-zA-Z0-9-.]/g;

/* Our current basic email regex:
 * [^@]+ Starts with a non-zero amount of characters that aren't '@'
 * @     Then has an '@'
 * [^.]+ Then has a non-zero amount of characters that aren't '.'
 * [.]   Then has a literal '.'
 * .+    Then has a non-zero amount of characters
 */
export const REGEX_EMAIL = /[^@]+@[^.]+[.].+/;

export const REGEX_PLAYER_NAME = /^[a-zA-Z0-9-]*$/;

export const TENANT_NAME_MAX_LENGTH = 100;
export const TENANT_NAME_MIN_LENGTH = 3;
export const GUID_REQUIRED_LENGTH = 36;

import ValuesList from './ValuesList';
import ValueDetail from './ValueDetail';
import { useValues } from './ValuesProvider';

const Values = () => {
    const { currentScreen } = useValues();

    const getScreen = () => {
        switch (currentScreen) {
            case 'valueList':
                return <ValuesList />;
            case 'valueDetail':
                return <ValueDetail />;
            default:
                return <ValuesList />;
        }
    };
    return <div className="page-wrapper">{getScreen()}</div>;
};

export default Values;

import IdentityProvidersList from './IdentityProvidersList';
import IdentityProviderDetail from './IdentityProviderDetail';
import type { AddNotification, IdentityProviderAPI } from '../../../types';
import { useState } from 'react';

interface Props {
    addNotification: AddNotification;
}

const IdentityProviders = ({ addNotification }: Props) => {
    const [itemToEdit, setItemToEdit] = useState<IdentityProviderAPI | null>(null);

    return (
        <div className="page-wrapper">
            {itemToEdit === null ? (
                <IdentityProvidersList
                    addNotification={addNotification}
                    setItemToEdit={setItemToEdit}
                />
            ) : (
                <IdentityProviderDetail
                    itemToEdit={itemToEdit}
                    returnToList={() => setItemToEdit(null)}
                />
            )}
        </div>
    );
};

export default IdentityProviders;

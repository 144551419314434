import actionTypes from '../actions/reduxActions/actionTypes';

// invitation example:
// {
//     "completedAt": "",
//     "completedBy": {
//       "email": "",
//       "firstName": "",
//       "id": "",
//       "lastName": ""
//     },
//     "invitedAt": "",
//     "invitedBy": {
//       "email": "",
//       "firstName": "",
//       "id": "",
//       "lastName": ""
//     },
//     "organization": {
//       "id": "",
//       "name": ""
//     },
//     "status": "accepted" | "rejected",
//     "tenant": {
//       "developerName": "",
//       "id": ""
//     },
//     "type": "tenant" | "user",
//     "user": null
// }
// Only tenant or user property will be populated

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const organizationReceivedUserInvitations = (state = [], { type, payload }) => {
    switch (type) {
        case actionTypes.ORGANIZATION_SET_RECEIVED_USER_INVITATIONS: {
            return payload.receivedInvitations;
        }

        default:
            return state;
    }
};

export default organizationReceivedUserInvitations;

import { useEffect, useState, useRef } from 'react';
import ConfirmModal from '../../generic/modal/ConfirmModal';
import translations from '../../../translations';
import { useServices } from '../contextProviders/ServicesProvider';
import { Trash } from '@phosphor-icons/react';
import Table from '../../../components/generic/Table';
import Sortable from '../../../components/generic/Sortable';
import { ATOM_CLOUDS, SERVICE_ICONS } from '../ServiceConstants';
import CopyableText from '../../../components/generic/CopyableText';
import ComponentWithTooltip from '../../../components/generic/tooltip/ComponentWithTooltip';
import type { OrderDirection } from '../../../types';
import type { ServiceElementResponseAPI } from '../../../types/service';
import PageHeader from '../../../../ts/components/generic/PageHeader';
import { stringContains, stringReplace } from '../../../utils/string';

interface ServiceListProps {
    switchToNewService: () => void;
    onEditService: (service: ServiceElementResponseAPI) => Promise<void>;
}

const ServiceList = ({ switchToNewService, onEditService }: ServiceListProps) => {
    const {
        services,
        fetchServices,
        paging,
        servicesState,
        serviceToDelete,
        setServiceToDelete,
        onServiceDeleteConfirm,
    } = useServices();
    const { isLoading } = servicesState;
    const { page, search, orderBy, orderDirection } = paging;

    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
    const [filteredServices, setFilteredServices] = useState(services);
    const [searchTerms, setSearchTerms] = useState('');
    const [sortBy, setSortBy] = useState({
        property: 'dateModified',
        direction: 'ASC',
    });
    const modalContainerRef = useRef<HTMLDivElement | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        fetchServices({
            limit: 1000, // pageSize,
            page,
            search,
            orderBy,
            orderDirection,
        });
    }, []);

    useEffect(() => {
        let servicesToFilter = [...services];

        if (searchTerms) {
            servicesToFilter = servicesToFilter.filter(
                (service) =>
                    stringContains(service.developerName ?? '', searchTerms, false) ||
                    stringContains(service.developerSummary ?? '', searchTerms, false) ||
                    stringContains(service.uri ?? '', searchTerms, false),
            );
        }

        if (sortBy) {
            servicesToFilter.sort((a, b) => {
                const propertyName = sortBy.property as keyof ServiceElementResponseAPI;

                if (propertyName === 'dateModified') {
                    if (sortBy.direction === 'ASC') {
                        return (
                            new Date(b.dateModified).getTime() - new Date(a.dateModified).getTime()
                        );
                    }
                    return new Date(a.dateModified).getTime() - new Date(b.dateModified).getTime();
                }

                if (typeof a[propertyName] === 'string' && typeof b[propertyName] === 'string') {
                    const aProp = a[propertyName] as string;
                    const bProp = b[propertyName] as string;

                    if (sortBy.direction === 'ASC') {
                        return (aProp ?? '').localeCompare(bProp ?? '');
                    }
                    return (bProp ?? '').localeCompare(aProp ?? '');
                }

                return 0;
            });
        }

        setFilteredServices(servicesToFilter);
    }, [services, searchTerms, sortBy]);

    const onDeleteService = (service: ServiceElementResponseAPI) => {
        setServiceToDelete(service);
        setDeleteModalIsVisible(true);
    };

    const onRefresh = () => {
        fetchServices({
            limit: 1000, //pageSize,
            page,
            search,
            orderBy,
            orderDirection,
        });
    };

    const onAddService = () => {
        switchToNewService();
    };

    const getIcon = (serviceUrl: string) => {
        const isIntegrationConnector = ATOM_CLOUDS.find(({ baseUrl }) =>
            serviceUrl.includes(baseUrl),
        );

        if (isIntegrationConnector) {
            return SERVICE_ICONS.integration;
        }

        return SERVICE_ICONS[serviceUrl] || SERVICE_ICONS.default;
    };

    const columns = [
        {
            renderHeader: () => null,
            renderCell: ({ item }: { item: ServiceElementResponseAPI }) => {
                const icon = getIcon(item.uri);
                return icon ? <img src={icon.src} alt={icon.alt} /> : null;
            },
            size: '48px',
        },
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) => setSortBy({ property: 'developerName', direction })}
                    direction={
                        sortBy?.property === 'developerName'
                            ? (sortBy?.direction as OrderDirection)
                            : null
                    }
                >
                    {translations.SC_table_name}
                </Sortable>
            ),
            renderCell: ({ item }: { item: ServiceElementResponseAPI }) => (
                <ComponentWithTooltip
                    trigger={['hover', 'focus']}
                    showDelay={500}
                    fadeTime={0.2}
                    tooltipPlacement="top"
                    tooltipContent={item.developerName}
                    wrapperClass="width-expand-to-full"
                >
                    <button
                        className="link-emulate overflow-ellipsis"
                        onClick={() => onEditService(item)}
                        type="button"
                    >
                        {item.developerName}
                    </button>
                </ComponentWithTooltip>
            ),
        },
        {
            renderHeader: () => translations.SC_table_description,
            renderCell: ({ item }: { item: ServiceElementResponseAPI }) => (
                <ComponentWithTooltip
                    trigger={['hover', 'focus']}
                    showDelay={500}
                    fadeTime={0.2}
                    tooltipPlacement="top"
                    tooltipContent={item.developerSummary}
                    wrapperClass="width-expand-to-full cursor-text"
                >
                    <div className="overflow-ellipsis">{item.developerSummary}</div>
                </ComponentWithTooltip>
            ),
        },
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) => setSortBy({ property: 'uri', direction })}
                    direction={
                        sortBy?.property === 'uri' ? (sortBy?.direction as OrderDirection) : null
                    }
                >
                    {translations.SC_table_url}
                </Sortable>
            ),
            renderCell: ({ item }: { item: ServiceElementResponseAPI }) => (
                <ComponentWithTooltip
                    trigger={['hover', 'focus']}
                    showDelay={500}
                    fadeTime={0.2}
                    tooltipPlacement="top"
                    tooltipContent={item.uri}
                    wrapperClass="width-expand-to-full cursor-text"
                >
                    <CopyableText copyableText={item.uri} hasCopyButton={true} />
                </ComponentWithTooltip>
            ),
        },
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) => setSortBy({ property: 'dateModified', direction })}
                    direction={
                        sortBy?.property === 'dateModified'
                            ? (sortBy?.direction as OrderDirection)
                            : null
                    }
                    defaultDirection={'ASC'}
                >
                    {translations.SC_table_date_modified}
                </Sortable>
            ),
            renderCell: ({ item }: { item: ServiceElementResponseAPI }) =>
                new Date(item.dateModified).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.SC_table_actions,
            renderCell: ({ item }: { item: ServiceElementResponseAPI }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`${translations.SC_table_actions_delete_title}`}
                        className="table-icon table-icon-delete"
                        onClick={() => onDeleteService(item)}
                        aria-label={`${translations.SC_table_actions_delete_title}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <div className="admin-page flow-wrapper" ref={modalContainerRef}>
            <PageHeader
                title={translations.SC_connectors_title}
                onAdd={onAddService}
                onRefresh={onRefresh}
                onSearch={setSearchTerms}
                searchQuery={paging.search}
                searchPlaceholderText="Search"
                addText={translations.SC_connectors_new_button}
                refreshTitle={translations.SC_connectors_refresh_button}
            />
            <Table
                items={filteredServices}
                columns={columns}
                isLoading={isLoading}
                pagination={true}
                testId="connectors-table"
            />
            <ConfirmModal
                show={deleteModalIsVisible}
                title={translations.SC_delete_confirmation_title}
                messages={[
                    stringReplace(translations.SC_delete_service_1, {
                        serviceName: serviceToDelete?.developerName ?? '',
                    }),
                    translations.GENERAL_cannot_be_undone,
                ]}
                buttonStyle="danger"
                buttonCaption="Delete"
                onCancel={() => setDeleteModalIsVisible(false)}
                onConfirm={() => {
                    onServiceDeleteConfirm();
                    setDeleteModalIsVisible(false);
                }}
                container={modalContainerRef.current}
            />
        </div>
    );
};

export default ServiceList;

import { fetchAndParse, filterToQuery } from '../utils/ajax';
import type {
    Filter,
    IntegrationProfileAPI,
    ItemCollectionResponse,
    TypeElementPropertyAPI,
    TypeElementRequestAPI,
    TypeElementResponseAPI,
    TypeMappingAutoAPI,
} from '../types';
import { getTenantId } from '../utils/tenant';
import { isNullOrEmpty } from '../utils/guard';

export const saveType = (request: TypeElementRequestAPI) =>
    fetchAndParse<TypeElementResponseAPI>({
        url: '/api/draw/1/element/type',
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

export const getType = (id: string) =>
    fetchAndParse<TypeElementResponseAPI>({
        url: `/api/draw/1/element/type/${id}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getAllTypes = () =>
    fetchAndParse<TypeElementResponseAPI[]>({
        url: '/api/draw/1/element/type',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getTypeList = (filters?: Filter | null) => {
    const query = isNullOrEmpty(filters) ? '' : filterToQuery(filters);

    return fetchAndParse<TypeElementResponseAPI[]>({
        url: `/api/draw/1/element/type${query}`,
        method: 'get',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getTypeListV2 = (filters: Filter) => {
    const query = filters ? filterToQuery(filters) : '';

    return fetchAndParse<ItemCollectionResponse<TypeElementResponseAPI>>({
        url: `/api/draw/2/element/type${query}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getAllProfiles = (
    accountId: string,
    search: string,
): Promise<IntegrationProfileAPI[]> =>
    fetchAndParse<IntegrationProfileAPI[]>({
        url: `/api/draw/2/element/type/integration/${accountId}/profile?search=${search}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getAllTypesForProfile = (
    accountId: string,
    profileId: string,
): Promise<TypeElementResponseAPI[]> =>
    fetchAndParse<TypeElementResponseAPI[]>({
        url: `/api/draw/2/element/type/integration/${accountId}/profile/${profileId}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const deleteType = (id: string) =>
    fetchAndParse<void>({
        url: `/api/draw/1/element/type/${id}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getAutoMappingToTargetType = (
    id: string,
    targetId: string,
): Promise<TypeMappingAutoAPI> =>
    fetchAndParse<TypeMappingAutoAPI>({
        url: `/api/draw/2/element/type/${id}/mapping/${targetId}/auto`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const sortPropertyByTypeElementDeveloperName = (
    a: TypeElementPropertyAPI,
    b: TypeElementPropertyAPI,
) => {
    if ((a.typeElementDeveloperName || '') < (b.typeElementDeveloperName || '')) {
        return -1;
    }
    if ((a.typeElementDeveloperName || '') > (b.typeElementDeveloperName || '')) {
        return 1;
    }
    return 0;
};

import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const services = (state = [], { type, payload }) => {
    switch (type) {
        case actionTypes.SERVICES_SET_ALL:
            return payload;

        default:
            return state;
    }
};

export default services;

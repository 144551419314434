export const SYSTEM_VALUE_NAME_ID_MAPPINGS = {
    $JoinUri: 'e2063196-3c75-4388-8b00-1005b8cd59ad',
    $User: '03dc41dd-1c6b-4b33-bf61-cbd1d0778fff',
    $State: '1f2a56fd-e14b-460c-aabd-9fbf344b84f3',
    $True: 'be1bc78e-fd57-40ec-9a86-a815de2a9e28',
    $False: '496fd041-d91f-48fb-aa4f-91c6c9a11ca1',
};
export const SYSTEM_VALUE_NAMES = Object.keys(SYSTEM_VALUE_NAME_ID_MAPPINGS);
export const SYSTEM_VALUE_IDS = Object.values(SYSTEM_VALUE_NAME_ID_MAPPINGS);

export const SYSTEM_TYPE_NAME_ID_MAPPINGS = {
    $File: 'af48e652-7dec-4739-9c98-586318e0ad7d',
    $Group: '82de6847-03d6-4ace-91cc-26cd80aea9fc',
    $Location: '7834ce95-dd99-42c6-96fc-ad12e1b48a69',
    $State: '6f5844b9-b79f-49ce-ba65-843dc754d1b6',
    $User: '2674CE95-DD99-42C6-96FC-AD12E1B48A69',
    '$Payment Card': '1ba0ac0c-ed9d-47b9-9c0c-85e3b65f9048',
    '$Chat Message': '9ff705aa-1511-49cc-8391-d8d0d314c9e8',
};

export const SYSTEM_TYPES = Object.keys(SYSTEM_TYPE_NAME_ID_MAPPINGS);
export const SYSTEM_TYPE_IDS = Object.values(SYSTEM_TYPE_NAME_ID_MAPPINGS);

// These will need to be hard-coded until roles are surfaced by the API
export const SYSTEM_ROLES = {
    administrator: {
        roleId: 1,
        developerName: 'ADMINISTRATOR',
        friendlyName: 'Administrator',
    },
    standard_user: {
        roleId: 2,
        developerName: 'STANDARD_USER',
        friendlyName: 'Standard User',
    },
    service_user: {
        roleId: 3,
        developerName: 'SERVICE_USER',
        friendlyName: 'Service User',
    },
} as const;

export const SYSTEM_VALUES = {
    true: {
        developerName: '$True',
        id: 'be1bc78e-fd57-40ec-9a86-a815de2a9e28',
    },
    false: {
        developerName: '$False',
        id: '496fd041-d91f-48fb-aa4f-91c6c9a11ca1',
    },
};

import {
    ArrowsInLineVertical,
    CirclesThreePlus,
    Code,
    FileJs,
    FileSearch,
    Gauge,
    GearSix,
    IdentificationCard,
    ImageSquare,
    Layout,
    Package,
    PaintBrush,
    Pulse,
    ShareNetwork,
    Suitcase,
    TerminalWindow,
    ToggleRight,
    User,
    Users,
} from '@phosphor-icons/react';
import ConnectorIcon from '../../js/components/icons/ConnectorIcon';
import FlowIcon from '../../js/components/icons/FlowIcon';
import type { TabConfiguration, TabConfigurationGroup, TabType } from '../types';

export const tabDetailsObjects: Record<TabType, TabConfiguration> = {
    userSettings: {
        title: 'User Settings',
        description: null,
        type: 'userSettings',
        icon: User,
        slug: 'user-settings',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: true,
        isMultiInstance: false,
    },
    flows: {
        title: 'Flows',
        description: 'Manage existing and create new flows',
        type: 'flows',
        icon: FlowIcon,
        slug: 'flows',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    flow: {
        title: null, // Will be populated with the name of the flow
        description: null,
        type: 'flow',
        icon: FlowIcon,
        slug: 'flows',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    newFlow: {
        title: 'New Flow',
        description: null,
        type: 'newFlow',
        icon: FlowIcon,
        slug: 'new_flow',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: true,
    },
    pages: {
        title: 'Pages',
        description: 'Manage existing and create new pages',
        type: 'pages',
        icon: Layout,
        slug: 'pages',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    page: {
        title: null, // Will be populated with the name of the page
        description: null,
        type: 'page',
        icon: Layout,
        slug: 'pages',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    newPage: {
        title: null,
        description: null,
        type: 'newPage',
        icon: Layout,
        slug: 'new_page',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: true,
    },
    environments: {
        title: 'Environments',
        description: null,
        type: 'environments',
        icon: GearSix,
        slug: 'environments',
        roles: ['ADMINISTRATOR'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    environment: {
        title: 'Environment',
        description: null,
        type: 'environment',
        icon: GearSix,
        slug: 'environment',
        roles: ['ADMINISTRATOR'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: true,
    },
    values: {
        title: 'Values',
        description: 'Manage existing and create new values',
        type: 'values',
        icon: CirclesThreePlus,
        slug: 'values',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    services: {
        title: 'Connectors',
        description: 'Manage existing and create new connectors',
        type: 'services',
        icon: ConnectorIcon,
        slug: 'connectors',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    types: {
        title: 'Types',
        description: 'Manage existing and create new types',
        type: 'types',
        icon: Package,
        slug: 'types',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    identityProviders: {
        title: 'Identity Providers',
        description: 'Manage existing and create new identity providers',
        type: 'identityProviders',
        icon: Users,
        slug: 'identityproviders',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    assets: {
        title: 'Assets',
        description: 'Upload static assets to your Tenant',
        type: 'assets',
        icon: ImageSquare,
        slug: 'assets',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    api: {
        title: 'API',
        description: 'Interact with the REST api directly',
        type: 'api',
        icon: ShareNetwork,
        slug: 'api',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: true,
    },
    importExport: {
        title: 'Import / Export',
        description: 'Import & Export Flows',
        type: 'importExport',
        icon: ArrowsInLineVertical,
        slug: 'import-export',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    themes: {
        title: 'Themes',
        description: 'Create and edit custom themes',
        type: 'themes',
        icon: PaintBrush,
        slug: 'themes',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    players: {
        title: 'Players',
        description: 'Create, edit & preview custom players',
        type: 'players',
        icon: TerminalWindow,
        slug: 'players',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: true,
    },
    macros: {
        title: 'Macros',
        description: 'Create or edit Macros',
        type: 'macros',
        icon: Code,
        slug: 'macros',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: true,
    },
    dashboard: {
        title: 'Dashboard',
        description: 'Overview your flow launches', // , anomalies and errors -> to be added later
        type: 'dashboard',
        icon: Gauge,
        slug: 'dashboard',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    anomalyDetection: {
        title: 'Anomaly Detection',
        description: 'View the Anomaly Detection dashboard',
        type: 'anomalyDetection',
        icon: Pulse,
        slug: 'anomalyDetection',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    auditing: {
        title: 'Auditing',
        description: 'View the audit logs for this tenant',
        type: 'auditing',
        icon: FileSearch,
        slug: 'auditing',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    tenant: {
        title: 'Tenant',
        description: null,
        type: 'tenant',
        slug: 'tenant',
        icon: IdentificationCard,
        roles: ['ADMINISTRATOR'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    organization: {
        title: 'Organization',
        description: null,
        type: 'organization',
        slug: 'organization',
        icon: Suitcase,
        roles: ['ADMINISTRATOR'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    features: {
        title: 'Features',
        description: null,
        type: 'features',
        icon: ToggleRight,
        slug: 'features',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
    components: {
        title: 'Components',
        description: 'Manage existing and create new custom page components',
        type: 'components',
        icon: FileJs,
        slug: 'components',
        roles: ['ADMINISTRATOR', 'STANDARD_USER'],
        isVisible: true,
        isSelected: false,
        isMultiInstance: false,
    },
};

export const groups: TabConfigurationGroup[] = [
    {
        name: 'admin',
        label: null,
        items: [
            tabDetailsObjects.flows,
            tabDetailsObjects.pages,
            tabDetailsObjects.values,
            tabDetailsObjects.services,
            tabDetailsObjects.types,
            tabDetailsObjects.identityProviders,
            tabDetailsObjects.components,
            tabDetailsObjects.assets,
            tabDetailsObjects.themes,
        ],
    },
    {
        name: 'tools',
        label: 'Tools',
        items: [
            tabDetailsObjects.api,
            tabDetailsObjects.importExport,
            tabDetailsObjects.players,
            tabDetailsObjects.macros,
            tabDetailsObjects.dashboard,
            tabDetailsObjects.auditing,
            tabDetailsObjects.anomalyDetection,
        ],
    },
];

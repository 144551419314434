import type {
    FlowIdentityProviderAPI,
    FlowResponseAPI,
    GroupAuthorizationAPI,
} from '../../../../types';

export type FlowConfigurationAction =
    | {
          type: 'setFlowConfiguration';
          payload: FlowResponseAPI;
      }
    | {
          type: 'updateAuthorization';
          payload: { authorization: GroupAuthorizationAPI };
      }
    | {
          type: 'updateName';
          payload: { name: string };
      }
    | {
          type: 'updateSummary';
          payload: { developerSummary: string };
      }
    | {
          type: 'updateIdentityProvider';
          payload: { identityProvider: FlowIdentityProviderAPI | null };
      }
    | {
          type: 'updateHistoricalNavigation';
          payload: { isEnabled: boolean };
      }
    | {
          type: 'updateAllowJumping';
          payload: { isEnabled: boolean };
      }
    | {
          type: 'updateStateExpirationLength';
          payload: { length: number };
      }
    | {
          type: 'updateIdleStateExpirationLength';
          payload: { length: number };
      };

export type DispatchFlowConfiguration = React.Dispatch<FlowConfigurationAction>;

export const reducer = (
    flowConfiguration: FlowResponseAPI | null,
    { type, payload }: FlowConfigurationAction,
): FlowResponseAPI => {
    if (type === 'setFlowConfiguration') {
        return payload;
    }

    if (flowConfiguration === null) {
        throw 'FlowResponseAPI cannot be null.';
    }

    switch (type) {
        case 'updateName':
            return {
                ...flowConfiguration,
                developerName: payload.name,
            };

        case 'updateSummary':
            return {
                ...flowConfiguration,
                developerSummary: payload.developerSummary,
            };

        case 'updateIdentityProvider':
            return {
                ...flowConfiguration,
                identityProvider: payload.identityProvider,
            };

        case 'updateAuthorization':
            return {
                ...flowConfiguration,
                authorization: payload.authorization,
            };

        case 'updateHistoricalNavigation':
            return {
                ...flowConfiguration,
                enableHistoricalNavigation: payload.isEnabled,
            };

        case 'updateAllowJumping':
            return {
                ...flowConfiguration,
                allowJumping: payload.isEnabled,
            };

        case 'updateStateExpirationLength':
            return {
                ...flowConfiguration,
                stateExpirationLength: payload.length,
            };

        case 'updateIdleStateExpirationLength':
            return {
                ...flowConfiguration,
                idleStateExpirationLength: payload.length,
            };
    }
};

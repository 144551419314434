import { Plus } from '@phosphor-icons/react';
import translations from '../../../translations';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import Boominaut from './Boominaut';

interface Props {
    onNewFlowClick: () => void;
}

const GettingStarted = ({ onNewFlowClick }: Props) => (
    <div className="getting-started">
        <Boominaut />
        <h2 className="getting-started-title">{translations.HOME_getting_started_header}</h2>
        <p className="getting-started-content">
            {translations.HOME_getting_started_content}{' '}
            <a
                href="https://help.boomi.com/bundle/flow/page/c-flo-Flows_Creating_a_new_flow.html"
                target="_blank"
                rel="noopener noreferrer"
            >
                {translations.HOME_getting_started_content_end}
            </a>
        </p>
        <ButtonPrimary onClick={onNewFlowClick}>
            <Plus className="new-flow-icon" /> {translations.HOME_new_flow}
        </ButtonPrimary>
    </div>
);

export default GettingStarted;

import { ButtonFlavor, ExButton, ButtonType } from '@boomi/exosphere';
import type { ValueElementIdReferenceAPI } from '../../../types';
import { getValueName, getValueType, isNonEditableValue } from './value-selector-utils';
import translations from '../../../translations';
import type { ReactElement } from 'react';

interface Props {
    value: ValueElementIdReferenceAPI | null;
    onOpen: () => void;
    onEdit: (value: ValueElementIdReferenceAPI) => void;
    onRemove: () => void;
    buttonType?: ButtonType;
    renderSelectorButton?: (() => ReactElement) | undefined;
}

const ValueSelector = ({
    value,
    onOpen,
    onEdit,
    onRemove,
    buttonType = ButtonType.PRIMARY,
    renderSelectorButton,
}: Props) => {
    return (
        <>
            {value ? (
                <div className="value-selector-selected">
                    <button className="value-selector-values-option" onClick={onOpen} type="button">
                        <span className="value-selector-values-option-name">
                            {getValueName(value)}
                        </span>
                        <span className="value-selector-values-option-meta">
                            {getValueType(value)}
                        </span>
                    </button>
                    <button className="btn-default btn-link" onClick={onOpen} type="button">
                        {translations.VALUE_SELECTOR_select}
                    </button>
                    {isNonEditableValue(value) === false && (
                        <button
                            className="btn-default btn-link"
                            onClick={() => onEdit(value)}
                            type="button"
                        >
                            {translations.VALUE_SELECTOR_edit}
                        </button>
                    )}
                    <button className="btn-default btn-link" onClick={onRemove} type="button">
                        {translations.VALUE_SELECTOR_remove}
                    </button>
                </div>
            ) : renderSelectorButton ? (
                <button type="button" className="value-selector-custom" onClick={onOpen}>
                    {renderSelectorButton()}
                </button>
            ) : (
                <ExButton
                    onClick={onOpen}
                    className="value-selector"
                    flavor={ButtonFlavor.BRANDED}
                    type={buttonType}
                >
                    {translations.VALUE_SELECTOR_select_or_create}
                </ExButton>
            )}
        </>
    );
};

export default ValueSelector;

import { type ComponentType, useEffect, useState } from 'react';
import PageBreadcrumbs from './PageBreadcrumbs';
import PageSideMenu from './PageSideMenu';
import PageWrapper from './PageWrapper';

export const rebuildCrumbs = (pages: Page[], breadcrumbs: Page[], selectedPage: Page) => {
    const crumbs = [...breadcrumbs];
    const findParent = (children: Page[], parent: string) =>
        children.find((child) => child.id === parent);

    let parentPage = findParent(pages, selectedPage?.parent ?? '');

    if (parentPage && breadcrumbs.includes(parentPage)) {
        // if the parent of selected page is already in the breadcrumbs then
        // remove all after the parent and add selected at the end
        crumbs.length = crumbs.indexOf(parentPage) + 1;
        crumbs.push(selectedPage);
    } else {
        // rebuild crumbs from scratch by adding selected page and all its
        // parents
        crumbs.length = 0;
        crumbs.push(selectedPage);
        while (parentPage) {
            crumbs.push(parentPage);
            parentPage = findParent(pages, parentPage?.parent ?? '');
        }
        crumbs.reverse();
    }

    return crumbs;
};

const pageHasMenu = (menu: MenuItem[] | null): menu is MenuItem[] => !!menu && menu.length > 0;

interface MenuItem {
    label: string;
    link: string;
    visible: boolean;
}

interface Page {
    id: string;
    label: string;
    parent: string | null;
    menu: MenuItem[] | null;
    component: ComponentType<{
        navigateTo: (page: string) => void;
        setActiveSectionId: (id: string) => void;
        hasMenu: boolean;
    }>;
}

interface PageSwitcherProps {
    pages: Page[];
    wrapperSelector: string;
    initialPageId?: string;
}

/**
 * Generic UI component for switching between pages that belong to a specific main view
 *
 * Set of pages for use in this component should be defined in a configuration
 * file and then this configuration should be passed into the page switcher
 * component.
 *
 * A standard page component for use within the page switcher with the ability
 * to navigate to other pages should be built similarly as shown in the example
 * below.
 *
 * @param pages Array of pages belonging to a specific main view
 * @param wrapperSelector Custom CSS selector for the page wrapper
 * @param initialPageId Define the initial page to start with
 *
 * @example
 *
 *  // sample of a basic page component
 *  // (component should receive the `navigateTo` function as a prop)
 *  const TestPage = ({ navigateTo }) => (
 *      <>
 *          // put your page content here
 *          <p>...</p>
 *
 *          // to add the ability to navigate to other pages add button(s)
 *          // like this to your page content
 *          <button onClick={() => navigateTo('pageTwoId')}>Page Two</button>
 *      </>
 * );
 *
 */
const PageSwitcher = ({ pages, wrapperSelector, initialPageId }: PageSwitcherProps) => {
    const [activeSectionId, setActiveSectionId] = useState<string | null>(null);

    const pagesDefined = pages.length > 0;
    // If a specific page is required and available, use that as the initial page; otherwise use the first page
    const initialPage =
        pagesDefined && initialPageId
            ? pages.find(({ id }) => id === initialPageId) || null
            : pagesDefined
              ? pages[0]
              : null;

    // build breadcrumbs based on the given pages
    const initialCrumbs = pages;
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [breadcrumbs, setBreadcrumbs] = useState([initialCrumbs[0]]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // make sure we re-render when the set of pages is changed,
        // ex. switching between Tenant and Organization views/pages
        setCurrentPage(initialPage);
    }, [pages, initialPage]);

    const navigateToPage = (selectedPageId: string) => {
        const selectedPage = pages.find(({ id }) => id === selectedPageId);
        if (!selectedPage) {
            throw new Error(`Unable to find page with ID ${selectedPageId}`);
        }
        const crumbs = rebuildCrumbs(initialCrumbs, breadcrumbs, selectedPage);

        setBreadcrumbs(crumbs);
        setCurrentPage(crumbs[crumbs.length - 1]);
    };

    const setupBreadcrumbs = (breadcrumbItems: typeof breadcrumbs) => (
        <PageBreadcrumbs breadcrumbs={breadcrumbItems} navigateTo={navigateToPage} />
    );

    const setupMenu = (menuItems: MenuItem[] | null) =>
        pageHasMenu(menuItems) && (
            <PageSideMenu items={menuItems} activeSectionId={activeSectionId} />
        );

    // React component names must start with an uppercase letter
    const PageComponent = currentPage?.component;

    return pagesDefined && initialPage ? (
        <PageWrapper
            selector={wrapperSelector}
            title={currentPage?.label ?? ''}
            breadcrumbs={setupBreadcrumbs(breadcrumbs)}
            menu={setupMenu(currentPage?.menu ?? null)}
            content={
                PageComponent && (
                    <PageComponent
                        navigateTo={navigateToPage}
                        setActiveSectionId={setActiveSectionId}
                        hasMenu={pageHasMenu(currentPage?.menu ?? null)}
                    />
                )
            }
        />
    ) : null;
};

export default PageSwitcher;

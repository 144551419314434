import { fetchAndParse, filterToQuery } from '../utils/ajax';
import type { AuthorizationEntityType, Filter, ItemCollectionResponse, ObjectAPI } from '../types';
import type {
    DescribeServiceTypesAndActions,
    ServiceElementRequestAPI,
    ServiceElementResponseAPI,
    ServiceValueRequestAPI,
    ObjectDataResponse2API,
    OpenApiAPI,
} from '../types/service';
import { getTenantId } from '../utils/tenant';

export const getService = (serviceId: string) =>
    fetchAndParse<ServiceElementResponseAPI>({
        url: `/api/draw/1/element/service/${serviceId}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const saveService = (serviceData: ServiceElementRequestAPI) =>
    fetchAndParse<ServiceElementResponseAPI>({
        url: '/api/draw/1/element/service',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ManyWhoTenant: getTenantId(),
        },
        method: 'POST',
        body: JSON.stringify(serviceData),
    });

export const deleteService = (serviceId: string) =>
    // ServiceElementResponseAPI
    fetchAndParse<void>({
        url: `/api/draw/1/element/service/${serviceId}`,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ManyWhoTenant: getTenantId(),
        },
        method: 'DELETE',
    });

export const getAllServices = () =>
    fetchAndParse<ServiceElementResponseAPI[]>({
        url: '/api/draw/1/element/service',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getAllServicesV2 = (filters: Filter) => {
    const query = filterToQuery(filters);
    return fetchAndParse<ItemCollectionResponse<ServiceElementResponseAPI>>({
        url: `/api/draw/2/element/service${query}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

/**
 * If a search term is provided, then the service will attempt to return results based on that.
 * If objectData is provided then the service will attempt to filter results based on that.
 * So for example, to return specific users the objectData might look like:

 [
 {
            developerName: "GroupAuthorizationUser",
            properties: [
                {
                    developerName: "AuthenticationId",
                    contentType: "ContentString",
                    contentValue: "1"
                }
            ]
        },
        {
            developerName: "GroupAuthorizationUser",
            properties: [
                {
                    developerName: "AuthenticationId",
                    contentType: "ContentString",
                    contentValue: "2"
                }
            ]
        }
 ]
 */
export const getServiceGroupsOrUsers = ({
    serviceId,
    type,
    search = null,
    objectData = null,
}: {
    serviceId: string;
    type: AuthorizationEntityType;
    search: string | null;
    objectData: ServiceElementRequestAPI | null;
}) => {
    const body = {
        objectData,
        listFilter: {
            comparisonType: 'VALUE',
            limit: 1000,
            filterByProvidedObjects: objectData !== null, // Used by some services to tell it to filter by objectdata
            search,
        },
    };
    return fetchAndParse<ObjectDataResponse2API>({
        url: `/api/draw/1/element/service/${serviceId}/${type}`,
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
        body,
    });
};

export const getServiceAuthenticationAttributes = ({
    serviceId,
    type,
}: {
    serviceId: string;
    type: string;
}) =>
    fetchAndParse<ObjectAPI[]>({
        url: `/api/draw/1/element/service/${serviceId}/${type}/authenticationattributes`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getServiceConfigurationValues = ({
    uri,
    basicUsername = null,
    basicPassword = null,
    httpAuthenticationClientCertificateReference = null,
    httpAuthenticationClientCertificatePasswordReference = null,
    configurationValues = null,
    id = null,
}: {
    uri: string | undefined;
    basicUsername: string | null | undefined;
    basicPassword: string | null | undefined;
    httpAuthenticationClientCertificateReference: string | null | undefined;
    httpAuthenticationClientCertificatePasswordReference: string | null | undefined;
    configurationValues?: ServiceValueRequestAPI[] | null;
    id: string | null;
}) => {
    const body = {
        uri,
        httpAuthenticationUsername: basicUsername,
        httpAuthenticationPassword: basicPassword,
        HttpAuthenticationClientCertificate: httpAuthenticationClientCertificateReference,
        HttpAuthenticationClientCertificatePassword:
            httpAuthenticationClientCertificatePasswordReference,
        configurationValues,
        id,
    };
    return fetchAndParse<ServiceValueRequestAPI[]>({
        url: '/api/draw/1/element/service/configurationValues',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
        body,
    });
};

export const getServiceTypesAndActions = ({
    uri,
    basicUsername = null,
    basicPassword = null,
    httpAuthenticationClientCertificateReference = null,
    httpAuthenticationClientCertificatePasswordReference = null,
    configurationValues = null,
    id = null,
    identityProviderId = null,
}: {
    uri: string | undefined;
    basicUsername: string | null | undefined;
    basicPassword: string | null | undefined;
    httpAuthenticationClientCertificateReference: string | null | undefined;
    httpAuthenticationClientCertificatePasswordReference: string | null | undefined;
    configurationValues: ServiceValueRequestAPI[] | null;
    id: string | null | undefined;
    identityProviderId: string | null | undefined;
}) => {
    const body = {
        uri,
        httpAuthenticationUsername: basicUsername,
        httpAuthenticationPassword: basicPassword,
        HttpAuthenticationClientCertificate: httpAuthenticationClientCertificateReference,
        HttpAuthenticationClientCertificatePassword:
            httpAuthenticationClientCertificatePasswordReference,
        configurationValues,
        id,
        identityProviderId,
    };
    return fetchAndParse<DescribeServiceTypesAndActions>({
        url: '/api/draw/1/element/service/typesAndActions',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
        body,
    });
};

export const installService = ({
    developerName,
    developerSummary,
    uri,
    basicUsername = null,
    basicPassword = null,
    httpAuthenticationClientCertificateReference = null,
    httpAuthenticationClientCertificatePasswordReference = null,
    configurationValues = null,
    id = null,
    identityProviderId = null,
}: {
    developerName: string | null | undefined;
    developerSummary: string | null | undefined;
    uri: string | undefined;
    basicUsername: string | null | undefined;
    basicPassword: string | null | undefined;
    httpAuthenticationClientCertificateReference: string | null | undefined;
    httpAuthenticationClientCertificatePasswordReference: string | null | undefined;
    configurationValues: ServiceValueRequestAPI[] | null;
    id: string | null | undefined;
    identityProviderId: string | null | undefined;
}) => {
    const body = {
        uri,
        developerName,
        developerSummary,
        httpAuthenticationUsername: basicUsername,
        httpAuthenticationPassword: basicPassword,
        HttpAuthenticationClientCertificate: httpAuthenticationClientCertificateReference,
        HttpAuthenticationClientCertificatePassword:
            httpAuthenticationClientCertificatePasswordReference,
        configurationValues,
        id,
        identityProviderId,
    };
    return fetchAndParse<ServiceElementResponseAPI>({
        url: '/api/draw/1/element/service/install',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
        body,
    });
};

export const getOpenApiSchemaInfo = ({
    schemaValueId,
}: {
    schemaValueId: string | null | undefined;
}) => {
    if (!schemaValueId) {
        return;
    }
    return fetchAndParse<OpenApiAPI>({
        url: `/api/draw/1/openapi/info/${schemaValueId}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
    });
};

export const SaveOpenApiSchemaConfig = (openApiAPI: OpenApiAPI) => {
    return fetchAndParse<OpenApiAPI>({
        url: '/api/draw/1/element/openapi',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: openApiAPI,
    });
};

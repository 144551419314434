import { useState } from 'react';
import translations from '../../../../translations';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import ConfirmModal from '../../../generic/modal/ConfirmModal';
import { deactivateAllFlowSnapshots } from '../../../../sources/flow';
import type { NotifyError, NotifySuccess } from '../../../../types';

interface Props {
    flowId: string;
    modalContainer: HTMLElement;
    notifySuccess: NotifySuccess;
    notifyError: NotifyError;
}

const Deactivation = ({ flowId, modalContainer, notifySuccess, notifyError }: Props) => {
    const [showDeactivateSnapshotConfirmation, setShowDeactivateSnapshotConfirmation] =
        useState(false);

    const deactivateFlow = async () => {
        try {
            await deactivateAllFlowSnapshots(flowId);
            setShowDeactivateSnapshotConfirmation(false);
            notifySuccess(translations.VERSION_SECTION_deactivate_all_snapshots_success);
        } catch (error) {
            notifyError((error as Error).toString());
        }
    };

    return (
        <>
            <h2>Deactivation</h2>
            <span className="margin-bottom">
                {translations.VERSION_SECTION_deactivate_all_snapshots_environment_text}
            </span>
            <span>
                <ButtonPrimary
                    className="btn-danger"
                    title={translations.VERSION_SECTION_deactivate_flow_environment}
                    onClick={() => setShowDeactivateSnapshotConfirmation(true)}
                >
                    {translations.VERSION_SECTION_deactivate_flow_environment}
                </ButtonPrimary>
            </span>

            <ConfirmModal
                show={showDeactivateSnapshotConfirmation}
                title={translations.VERSION_SECTION_deactivate_all_snapshots}
                messages={[
                    translations.VERSION_SECTION_deactivate_all_snapshots_confirm_environments,
                ]}
                buttonStyle="danger"
                buttonCaption={translations.COMMON_confirm}
                onCancel={() => setShowDeactivateSnapshotConfirmation(false)}
                onConfirm={() => {
                    deactivateFlow();
                }}
                container={modalContainer}
            />
        </>
    );
};

export default Deactivation;

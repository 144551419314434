import type { AssetAPI, AssetInfoAPI } from '../types/asset';
import { fetchAndParse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export const getAssets = () =>
    fetchAndParse<AssetAPI[]>({
        url: '/api/draw/1/assets',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const deleteAsset = (filename: string) =>
    fetchAndParse<null>({
        url: '/api/draw/1/assets',
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body: { key: filename },
    });

export const editAsset = ({
    oldFilename,
    newFilename,
}: {
    oldFilename: string;
    newFilename: string;
}) =>
    fetchAndParse<AssetAPI>({
        url: '/api/draw/1/assets',
        method: 'PUT',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body: {
            oldKey: oldFilename,
            newKey: newFilename,
        },
    });

export const uploadAsset = (endpoint: string, file: File) =>
    fetch(endpoint, { method: 'PUT', body: file });

export const createAsset = (payload: { fileType: string; filename: string; filesize: number }) =>
    fetchAndParse<string>({
        url: '/api/draw/1/assets/upload',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body: {
            contentType: payload.fileType,
            key: payload.filename,
            size: payload.filesize,
        },
    });

export const getAssetInfo = () =>
    fetchAndParse<AssetInfoAPI>({
        url: '/api/draw/1/assets/info',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

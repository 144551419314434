import classNames from 'classnames';
import translations from '../../../../../translations';

interface Props {
    labelText?: string;
    isRequired?: boolean;
    className?: string;
}

const PreviewComponentLabel = ({ labelText: labelTextProp, isRequired, className }: Props) => {
    const showLabel = !!isRequired || !!labelTextProp;
    const labelText =
        labelTextProp || translations.PAGE_BUILDER_preview_component_placeholder_label;

    const classes = classNames(
        'page-component-preview-label',
        {
            'page-component-preview-label-required': isRequired,
        },
        className,
    );

    return showLabel ? <span className={classes}>{labelText}</span> : null;
};

export default PreviewComponentLabel;

import { connect } from 'react-redux';
import ErrorBoundary from '../../ts/components/generic/ErrorBoundary';
import Build from '../../ts/components/ApiTool';
import PlayerEditor from '../../ts/components/player-editor/PlayerEditor';
import ImportExport from '../../ts/components/ImportExport';
import MacroEditor from '../../ts/components/macro-editor/MacroEditor';
import Dashboard from './dashboard/Dashboard';
import PageList from '../../ts/components/admin/pages/PageList';
import { isSubtenant } from '../../ts/utils/tenant';
import PageSwitcher from '../../ts/components/generic/PageSwitcher';
import { ServicesProvider } from '../../ts/components/services/contextProviders/ServicesProvider';
import ServiceController from '../../ts/components/services/ServiceController';
import InitialOrgPage from '../../ts/components/organization/InitialOrgPage';
import AuditLogs from '../../ts/components/auditLogs/AuditLogs';
import UserSettings from '../../ts/components/userSettings/UserSettings';
import Features from '../../ts/components/featureFlags/Features';
import classnames from 'classnames';
import { TAB_TYPES } from '../../ts/constants';
import { ValuesProvider } from '../../ts/components/admin/values/ValuesProvider';
import Values from '../../ts/components/admin/values/Values';
import { TypesProvider } from '../../ts/components/types/TypesProvider';
import Types from '../../ts/components/types/Types';
import IdentityProviders from '../../ts/components/admin/identityproviders/IdentityProviders';
import { addNotification, notifySuccess, notifyError } from '../actions/reduxActions/notification';
import { EnvironmentsProvider } from '../../ts/components/admin/environments/EnvironmentsProvider';
import Environments from './admin/environments/Environments';
import Environment from './admin/environments/Environment';
import { CustomPageComponentsProvider } from '../../ts/components/admin/customPageComponents/CustomPageComponentsProvider';
import CustomPageComponents from './admin/customPageComponents/CustomPageComponents';
import translations from '../../ts/translations';
import { useAuth } from '../../ts/components/AuthProvider';
import Home from '../../ts/components/flow/homePage/Home';
import Themes from '../../ts/components/theme/Themes';
import AssetTab from '../../ts/components/assets/AssetTab';
import AnomalyDashboard from '../../ts/components/anomalyDetection/AnomalyDashboard';
import InsightsDashboard from '../../ts/components/developerDashboard/InsightsDashboard';
import { getFlag } from '../../ts/utils/flags';
import { AnomalyDashboardProvider } from '../../ts/components/anomalyDetection/AnomalyProvider';
import '../../../css/admin.less';
import '../../../css/modal.less';
import { TENANT_VIEWS } from '../../ts/constants';
import Tenant from '../../ts/components/tenant/Tenant';
import CreateSubtenant from '../../ts/components/tenant/CreateSubtenant';

const getTenantPages = (isSubtenant) => {
    /**
     * Configuration object holding all the side menu links for each page
     *
     * If a page does not have a side menu then there is no need to add any config
     * for it here.
     *
     * The side menu component will handle the highlighting as links are clicked by the user.
     *
     * @param {Array} <page_id> Property that holds the set of link definitions for a specific page
     * @param {String} label Text shown in the link
     * @param {String} link Link's hash id used for navigating within the parent page
     * @param {Boolean} [visible = true] Determines if the link is visible
     *
     * @example
     *
     *  pageSideMenuItems = {
     *      unique_page_id: [
     *          {
     *              label: 'Link 1',
     *              link: '#link_id',
     *          },
     *          {
     *              label: 'Link 2',
     *              link: '#link_2_1',
     *              visible: false,
     *          },
     *          ...
     *      ],
     *      ...
     *  };
     */

    const pageSideMenuItems = {
        tenant: [
            {
                label: 'Tenant Name',
                link: '#tenant-name',
            },
            {
                label: 'Users',
                link: '#tenant-users',
            },
            {
                label: 'Environments',
                link: '#tenant-environment',
                visible: true,
            },
            {
                label: 'Themes',
                link: '#tenant-themes',
            },
            {
                label: 'Integration Accounts',
                link: '#tenant-integration-accounts',
            },
            {
                label: 'Subtenants',
                link: '#tenant-subtenants',
                visible: !isSubtenant,
            },
            {
                label: 'Single Sign-on',
                link: '#tenant-single-sign-on',
            },
            {
                label: 'Security',
                link: '#tenant-security',
            },
            {
                label: 'Reporting',
                link: '#tenant-reporting',
            },
            {
                label: 'Observability',
                link: '#tenant-observability',
            },
            {
                label: 'Export',
                link: '#tenant-export',
            },
            {
                label: 'Import',
                link: '#tenant-import',
            },
            {
                label: 'Comments',
                link: '#tenant-comments',
            },
        ],
    };

    /**
     * Configuration array holding all the pages that belong to the same main view
     *
     * @param {String} id A unique page id
     * @param {String} label Name of the page, also used for main page title
     * @param {(null|String)} parent Id of the parent page if current page is a child of it
     * @param {(null|pageSideMenuItems|Array)} menu Set of side menu links for the page (prefer pageSideMenuItems.<unique_page_id>)
     * @param {React.Component} component React component of the current page
     *
     * @example
     *
     *  orgPages = [
     *      {
     *          id: 'uniquePageNameOrId',
     *          label: 'Page Title',
     *          parent: null | 'parentPageNameOrId',
     *          menu: null | pageSideMenuItems.uniquePageNameOrId | [...]
     *          component: React component (imported at the top of the file)
     *      },
     *      ...
     *  ];
     */

    const tenantPages = [
        {
            id: TENANT_VIEWS.tenant,
            label: translations.TENANT_page_title_tenant,
            parent: null,
            menu: pageSideMenuItems.tenant,
            component: Tenant,
        },
        {
            id: TENANT_VIEWS.newSubtenant,
            label: translations.TENANT_page_title_create_subtenant,
            parent: 'tenant',
            menu: null,
            component: CreateSubtenant,
        },
    ];

    return tenantPages;
};

const Admin = ({ isActive, tabType, addNotification, notifySuccess, notifyError }) => {
    const { tenant } = useAuth();
    const { id: tenantId, developerName: tenantName, tenantSettings } = tenant;
    const environmentsIsOn = tenantSettings?.environments;

    if (!tenantId) {
        return null;
    }

    let content = null;

    const currentTenantIsSubtenant = isSubtenant(tenantName);

    switch (tabType) {
        case TAB_TYPES.auditing: {
            content = (
                <ErrorBoundary>
                    <AuditLogs tenantId={tenantId} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.tenant: {
            content = (
                <ErrorBoundary>
                    <PageSwitcher pages={getTenantPages(currentTenantIsSubtenant)} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.flows: {
            content = (
                <ErrorBoundary>
                    <Home isEditorActive={isActive} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.services: {
            content = (
                <ErrorBoundary>
                    <ServicesProvider addNotification={addNotification} tenantId={tenantId}>
                        <ServiceController />
                    </ServicesProvider>
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.types: {
            content = (
                <ErrorBoundary>
                    <TypesProvider
                        notifyError={notifyError}
                        notifySuccess={notifySuccess}
                        tenantId={tenantId}
                    >
                        <Types />
                    </TypesProvider>
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.values: {
            content = (
                <ValuesProvider addNotification={addNotification} tenantId={tenantId}>
                    <Values />
                </ValuesProvider>
            );
            break;
        }

        case TAB_TYPES.identityProviders: {
            content = (
                <ErrorBoundary>
                    <IdentityProviders addNotification={addNotification} tenantId={tenantId} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.environments: {
            content = (
                <ErrorBoundary>
                    <EnvironmentsProvider tenantId={tenantId}>
                        <Environments />
                    </EnvironmentsProvider>
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.environment: {
            if (environmentsIsOn) {
                content = (
                    <ErrorBoundary>
                        <EnvironmentsProvider tenantId={tenantId}>
                            <Environment />
                        </EnvironmentsProvider>
                    </ErrorBoundary>
                );
            } else {
                content = <span>{translations.ENVIRONMENT_not_on}</span>;
            }
            break;
        }

        case TAB_TYPES.pages: {
            content = (
                <ErrorBoundary>
                    <PageList tenantId={tenantId} />
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.assets: {
            content = (
                <ErrorBoundary>
                    <AssetTab addNotification={addNotification} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.themes: {
            content = (
                <ErrorBoundary>
                    <Themes key="themes" isActive={isActive} addNotification={addNotification} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.players: {
            content = (
                <ErrorBoundary>
                    <PlayerEditor key="players" tenantId={tenantId} isActive={isActive} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.api: {
            content = (
                <ErrorBoundary>
                    <Build key="build" />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.macros: {
            content = (
                <ErrorBoundary>
                    <MacroEditor key="macro-editor" />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.importExport: {
            content = (
                <ErrorBoundary>
                    <ImportExport key="import-export" tenant={tenant} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.dashboard: {
            content = (
                <ErrorBoundary>
                    {getFlag('UNIDSH') ? (
                        <InsightsDashboard notifyError={notifyError} />
                    ) : (
                        <Dashboard tenantId={tenantId} />
                    )}
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.organization: {
            content = (
                <ErrorBoundary>
                    <InitialOrgPage />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.userSettings: {
            content = <UserSettings />;
            break;
        }
        case TAB_TYPES.features: {
            content = <Features />;
            break;
        }

        case TAB_TYPES.components: {
            content = (
                <ErrorBoundary>
                    <CustomPageComponentsProvider tenantId={tenantId}>
                        <CustomPageComponents />
                    </CustomPageComponentsProvider>
                </ErrorBoundary>
            );
            break;
        }

        case TAB_TYPES.anomalyDetection: {
            content = (
                <ErrorBoundary>
                    <AnomalyDashboardProvider notifyError={notifyError}>
                        {/* I've moved this admin-page class up to here for now until the unified dashboard is finished. 
                        It was getting two of these and causing double padding */}
                        <div className="admin-page">
                            <AnomalyDashboard />
                        </div>
                    </AnomalyDashboardProvider>
                </ErrorBoundary>
            );
            break;
        }

        default: {
            content = null;
            break;
        }
    }

    const classes = classnames({
        admin: true,
        active: isActive,
    });
    return (
        <div className={classes}>
            <span className="admin-transition">{content}</span>
        </div>
    );
};

export default connect(null, { addNotification, notifySuccess, notifyError })(Admin);

import { useEffect, useState } from 'react';
import {
    getSnapshots,
    activateFlowSnapshot as activateSnapshot,
    deactivateAllFlowSnapshots,
} from '../../../../sources/flow';
import Loader from '../../../loader/Loader';
import ButtonDefault from '../../../buttons/ButtonDefault';
import { ArrowsClockwise, CheckSquare, Square } from '@phosphor-icons/react';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import Table from '../../../generic/Table';
import translations from '../../../../translations';
import ConfirmModal from '../../../generic/modal/ConfirmModal';
import type { FlowResponseAPI, NotifyError, NotifySuccess, Paging } from '../../../../types';

interface Props {
    flowId: string;
    notifyError: NotifyError;
    notifySuccess: NotifySuccess;
    modalContainer: HTMLElement;
}

const FlowVersionManager = ({ flowId, notifyError, notifySuccess, modalContainer }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [snapshots, setSnapshots] = useState<FlowResponseAPI[]>([]);
    const [showDeactivateSnapshotConfirmation, setShowDeactivateSnapshotConfirmation] =
        useState(false);
    const [paging, setPaging] = useState<Paging>({ page: 1, total: 0, pageSize: 10 });

    useEffect(() => {
        loadFlowSnapshots(paging.page);
    }, [paging.page]);

    const loadFlowSnapshots = async (page: number = paging.page) => {
        setIsLoading(true);

        try {
            const allSnapshots = await getSnapshots(flowId, page);
            setSnapshots(allSnapshots.items);
            setPaging(allSnapshots._meta);
        } catch (error) {
            notifyError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const activate = (flowId: string, flowVersionId: string) => {
        setIsLoading(true);
        activateSnapshot(flowId, flowVersionId)
            .then(() => loadFlowSnapshots())
            .finally(() => {
                setIsLoading(false);
            })
            .catch(notifyError);
    };

    const deactivateAll = () => {
        deactivateAllFlowSnapshots(flowId)
            .then(async () => {
                setShowDeactivateSnapshotConfirmation(false);
                await loadFlowSnapshots();
                notifySuccess(translations.VERSION_SECTION_deactivate_all_snapshots_success);
            })
            .catch(notifyError);
    };

    if (isLoading) {
        return <Loader message="Loading snapshots" />;
    }

    return (
        <>
            <h2>{translations.VERSION_SECTION_heading}</h2>
            <span className="body-text">
                <p>{translations.VERSION_SECTION_description_1}</p>
                <p>{translations.VERSION_SECTION_description_2}</p>
                <p>{translations.VERSION_SECTION_description_3}</p>
                <p>{translations.VERSION_SECTION_description_4}</p>
            </span>

            <span>
                <ButtonPrimary
                    className="btn-danger float-right margin-right"
                    title={translations.VERSION_SECTION_deactivate_all_snapshots}
                    onClick={() => setShowDeactivateSnapshotConfirmation(true)}
                >
                    {translations.VERSION_SECTION_deactivate_all_snapshots}
                </ButtonPrimary>
            </span>

            <ConfirmModal
                show={showDeactivateSnapshotConfirmation}
                title={translations.VERSION_SECTION_deactivate_all_snapshots}
                messages={[translations.VERSION_SECTION_deactivate_all_snapshots_confirm]}
                buttonStyle="danger"
                buttonCaption={translations.COMMON_confirm}
                onCancel={() => setShowDeactivateSnapshotConfirmation(false)}
                onConfirm={deactivateAll}
                container={modalContainer}
            />
            <div className="mw-items-header">
                <ButtonDefault
                    className="btn-sm"
                    title="Refresh Snapshots"
                    onClick={() => loadFlowSnapshots()}
                >
                    <ArrowsClockwise />
                </ButtonDefault>
            </div>

            <Table<FlowResponseAPI>
                wrapperClassName="margin-top"
                columns={[
                    {
                        renderHeader: () => translations.COMMON_TABLE_date_activated,
                        renderCell: ({ item }) =>
                            new Date(item.dateCreated).toLocaleString(undefined, {
                                dateStyle: 'medium',
                                timeStyle: 'short',
                            }),
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_comment,
                        renderCell: ({ item }) => <div>{item.comment}</div>,
                        cellClassName: 'generic-cell-overflow',
                    },
                    {
                        renderHeader: () => translations.COMMON_TABLE_actions,
                        renderCell: ({ item }) =>
                            item.isDefault ? (
                                <div className="button-padding-left-container flex">
                                    <CheckSquare className="table-icon-medium" />
                                    <span className="padding-left-sml">Activated</span>
                                </div>
                            ) : (
                                <ButtonPrimary
                                    className="btn-sm"
                                    title="Activate Flow Snapshot"
                                    onClick={() => activate(item.id.id, item.id.versionId)}
                                >
                                    <>
                                        <Square className="table-icon-medium" />
                                        Activate Flow Snapshot
                                    </>
                                </ButtonPrimary>
                            ),
                        size: '14rem',
                    },
                ]}
                items={snapshots}
                rowClassName={() => 'generic-row button-height-container'}
                pagination={{
                    ...paging,
                    changePage: (page) => setPaging((prevPaging) => ({ ...prevPaging, page })),
                }}
            />
        </>
    );
};

export default FlowVersionManager;

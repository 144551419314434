import { useReducer } from 'react';
import type {
    ContentType,
    PageComponentValidationAPI,
    PageComponentValidationType,
} from '../../../../../../../../types';
import ButtonPrimary from '../../../../../../../buttons/ButtonPrimary';
import ButtonDefault from '../../../../../../../buttons/ButtonDefault';
import FormGroup from '../../../../../../../generic/FormGroup';
import { COMPONENT_CONFIGURATION_LABELS } from '../../../../../../constants';

interface Props {
    contentType: Readonly<ContentType>;
    validation: Readonly<PageComponentValidationAPI | null>;
    onSave: (validation: PageComponentValidationAPI) => void;
    onCancel: () => void;
}

interface State {
    validationToEdit: PageComponentValidationAPI;
    hasSubmitted: boolean;
}

type Action =
    | {
          type: 'updateType';
          payload: { type: PageComponentValidationType };
      }
    | {
          type: 'updateValue';
          payload: { value: string | number };
      }
    | {
          type: 'updateMessage';
          payload: { message: string };
      }
    | {
          type: 'submit';
      };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'updateType':
            return {
                ...state,
                validationToEdit: {
                    ...state.validationToEdit,
                    type: action.payload.type,
                },
            };
        case 'updateValue':
            return {
                ...state,
                validationToEdit: {
                    ...state.validationToEdit,
                    value: action.payload.value,
                },
            };
        case 'updateMessage':
            return {
                ...state,
                validationToEdit: {
                    ...state.validationToEdit,
                    message: action.payload.message,
                },
            };
        case 'submit':
            return {
                ...state,
                hasSubmitted: true,
            };
    }
};

const ValidationForm = ({ contentType, validation, onSave, onCancel }: Props) => {
    const getApplicableValidationOptions = (
        contentType: ContentType,
    ): PageComponentValidationType[] => {
        switch (contentType) {
            case null:
            case 'ContentBoolean':
            case 'ContentObject':
                return [];

            case 'ContentString':
            case 'ContentPassword':
            case 'ContentEncrypted':
            case 'ContentContent':
            case 'ContentCode':
                return ['Pattern', 'Minimum', 'Maximum'];

            case 'ContentDate':
            case 'ContentDateTime':
                return ['Minimum', 'Maximum'];

            case 'ContentNumber':
            case 'ContentList':
                return ['Minimum', 'Maximum'];
        }
    };

    const getInputType = (
        contentType: ContentType,
        validationType: PageComponentValidationType,
    ): 'text' | 'date' | 'number' => {
        switch (contentType) {
            case null:
            case 'ContentBoolean':
            case 'ContentObject':
            case 'ContentString':
            case 'ContentPassword':
            case 'ContentEncrypted':
            case 'ContentContent':
            case 'ContentCode': {
                if (validationType === 'Pattern') {
                    return 'text';
                }
                return 'number';
            }

            case 'ContentDate':
            case 'ContentDateTime':
                return 'date';

            case 'ContentNumber':
            case 'ContentList':
                return 'number';
        }
    };

    const getValidationLabel = (
        contentType: ContentType,
        validationType: PageComponentValidationType,
    ) => {
        switch (validationType) {
            case 'Pattern':
                return COMPONENT_CONFIGURATION_LABELS['VALIDATION_PATTERN'];

            case 'Minimum':
                return contentType === 'ContentDate' || contentType === 'ContentDateTime'
                    ? COMPONENT_CONFIGURATION_LABELS['VALIDATION_MIN_DATE']
                    : COMPONENT_CONFIGURATION_LABELS['VALIDATION_MIN_NUMBER'];

            case 'Maximum':
                return contentType === 'ContentDate' || contentType === 'ContentDateTime'
                    ? COMPONENT_CONFIGURATION_LABELS['VALIDATION_MAX_DATE']
                    : COMPONENT_CONFIGURATION_LABELS['VALIDATION_MAX_NUMBER'];
        }
    };

    const createNewValidation = (contentType: ContentType): PageComponentValidationAPI => {
        switch (contentType) {
            case null:
            case 'ContentBoolean':
            case 'ContentObject':
            case 'ContentString':
            case 'ContentPassword':
            case 'ContentEncrypted':
            case 'ContentContent':
            case 'ContentCode':
                return { type: 'Pattern', value: '', message: '' };

            case 'ContentDate':
            case 'ContentDateTime':
            case 'ContentNumber':
            case 'ContentList':
                return { type: 'Minimum', value: '', message: '' };
        }
    };

    const onSubmit = () => {
        if (isValid) {
            onSave(state.validationToEdit);
        } else {
            dispatch({ type: 'submit' });
        }
    };

    const isNew = validation === null;

    const initialValidationToEdit: PageComponentValidationAPI = isNew
        ? createNewValidation(contentType)
        : validation;

    const initialState: State = {
        validationToEdit: initialValidationToEdit,
        hasSubmitted: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const valueIsValid =
        state.validationToEdit.value !== null &&
        state.validationToEdit.value !== undefined &&
        state.validationToEdit.value !== '';

    const messageIsValid = state.validationToEdit.message !== '';

    const isValid = valueIsValid && messageIsValid;

    if (contentType === null) {
        // The form makes no sense without a contentType.
        // This shouldn't happen, but just in case.
        return null;
    }

    return (
        <div className="sidebar-mini-editor">
            <h4 className="sidebar-section-heading">{`${
                isNew ? 'Create new ' : 'Edit '
            } validation`}</h4>
            <FormGroup label="Type" htmlFor="validation-type" isRequired>
                <select
                    id="validation-type"
                    className="form-control validation-type"
                    value={state.validationToEdit.type}
                    title="Type of validation"
                    onChange={({ target: { value } }) =>
                        dispatch({
                            type: 'updateType',
                            payload: { type: value as PageComponentValidationType },
                        })
                    }
                >
                    {getApplicableValidationOptions(contentType).map((validationType) => (
                        <option key={validationType} value={validationType}>
                            {getValidationLabel(contentType, validationType)}
                        </option>
                    ))}
                </select>
            </FormGroup>
            <FormGroup
                isRequired
                label="Value"
                htmlFor="validation-value"
                showValidation={!valueIsValid && state.hasSubmitted}
                validationMessage="Value is required"
            >
                <input
                    id="validation-value"
                    type={getInputType(contentType, state.validationToEdit.type)}
                    title="Validation value"
                    value={state.validationToEdit.value}
                    onChange={({ target }) =>
                        dispatch({
                            type: 'updateValue',
                            payload: {
                                value:
                                    target.type === 'number' ? target.valueAsNumber : target.value,
                            },
                        })
                    }
                    className="form-control"
                />
            </FormGroup>
            <FormGroup
                isRequired
                label="Message"
                htmlFor="validation-message"
                showValidation={!messageIsValid && state.hasSubmitted}
                validationMessage="Message is required"
            >
                <input
                    id="validation-message"
                    title="Validation message"
                    value={state.validationToEdit.message}
                    onChange={({ target: { value } }) =>
                        dispatch({ type: 'updateMessage', payload: { message: value } })
                    }
                    className="form-control"
                    type="text"
                />
            </FormGroup>
            <div className="margin-top">
                <ButtonDefault onClick={onCancel}>Cancel</ButtonDefault>
                <ButtonPrimary onClick={onSubmit}>{isNew ? 'Add' : 'Update'}</ButtonPrimary>
            </div>
        </div>
    );
};

export default ValidationForm;

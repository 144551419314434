import ButtonIcon from '../buttons/ButtonIcon';
import classnames from 'classnames';
import ButtonsReorder from '../buttons/ButtonsReorder';

import React, { type ReactNode, useRef } from 'react';
import { isNullOrWhitespace } from '../../utils/guard';
import { CaretDown, CaretRight } from '@phosphor-icons/react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import type { TableColumnList } from './Table';

interface DragItem {
    index: number;
    id: string;
    type: string;
}

interface RowOrdering {
    canOrder: boolean;
    onOrder: (currentIndex: number, newIndex: number) => void;
}

interface Props<TItem> {
    rowIndex: number;
    columns: TableColumnList<TItem>;
    isSelected: boolean;
    renderExpandedRow: undefined | ((item: TItem) => ReactNode);
    item: TItem;
    ordering: RowOrdering;
    classes: string;
    onClick: () => void;
    totalItems: number;
    tableId: string | undefined;
}

const TableRow = <TItem,>({
    rowIndex,
    renderExpandedRow,
    columns,
    isSelected,
    item,
    ordering,
    classes,
    onClick,
    totalItems,
    tableId,
}: Props<TItem>) => {
    const ref = useRef<HTMLTableRowElement>(null);

    const { canOrder = false, onOrder } = ordering;

    const elementAccept = tableId ? `TABLE_ROW_${tableId}` : 'TABLE_ROW';

    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: elementAccept,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;

            const hoverIndex = rowIndex;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            onOrder(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        type: elementAccept,
        item: () => {
            return { index: rowIndex };
        },
    });

    if (canOrder) {
        drag(drop(ref));
    }

    return (
        <React.Fragment key={rowIndex}>
            <tr
                ref={ref}
                data-handler-id={handlerId}
                key={rowIndex}
                data-testid={`Row ${rowIndex + 1}`}
                className={classes}
                onClick={onClick}
                onKeyDown={onClick}
            >
                {renderExpandedRow && (
                    <td>
                        <ButtonIcon
                            className="btn-expand"
                            onClick={onClick}
                            title={isSelected ? 'Collapse row' : 'Expand row'}
                        >
                            {isSelected ? <CaretDown size={20} /> : <CaretRight size={20} />}
                        </ButtonIcon>
                    </td>
                )}
                {columns.map((column, cellIndex) => (
                    <td
                        className={classnames({
                            'generic-cell': isNullOrWhitespace(column?.cellClassName),
                            [column.cellClassName as string]: !isNullOrWhitespace(
                                column?.cellClassName,
                            ),
                            'flex-end-content': cellIndex === columns.length - 1 && canOrder,
                        })}
                        // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
                        key={cellIndex}
                    >
                        {cellIndex === columns.length - 1 && canOrder && (
                            <ButtonsReorder
                                totalRows={totalItems}
                                rowIndex={rowIndex}
                                onReorder={onOrder}
                            />
                        )}
                        {column.renderCell({
                            item,
                            rowIndex,
                            cellIndex,
                        })}
                    </td>
                ))}
            </tr>
            {renderExpandedRow && isSelected && (
                <tr
                    className="table-row-details"
                    data-testid="table-row-details"
                    key={`expanded-${rowIndex}`}
                >
                    <td className="table-data-details" colSpan={columns.length + 1}>
                        {renderExpandedRow(item)}
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
};

export default TableRow;

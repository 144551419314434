import type {
    TenantMinimalAPI,
    TenantResponseAPI,
    IntegrationAccountEnvironments,
    TenantRegistrationResponseAPI,
} from '../types/Tenant';
import { fetchAndParse, CheckStatus, downloadFile, parse, uploadFile } from '../utils/ajax';
import { formatTenantName, getTenantId } from '../utils/tenant';

export const getSubtenants = async (tenantId: string) => {
    const response = await fetch('/api/admin/1/tenant/subtenants', {
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return (await parse(await CheckStatus(response))) as TenantResponseAPI[];
};

export const provisionTenant = async (
    email: string,
    tenantName: string,
    firstName: string,
    lastName: string,
) => {
    const request = {
        tenantName,
        email,
        firstName,
        lastName,
    };

    const response = await fetch('/api/admin/1/provisioning', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
    });

    return (await parse(await CheckStatus(response))) as TenantRegistrationResponseAPI;
};

export const createSubtenant = async (tenantId: string, tenantName: string) => {
    const request = {
        developerName: tenantName,
        developerSummary: '',
    };

    const response = await fetch('/api/admin/1/tenant/subtenants', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: tenantId,
        },
        body: JSON.stringify(request),
    });

    return (await parse(await CheckStatus(response))) as TenantMinimalAPI;
};

export const getCurrentTenant = async (tenantId: string) => {
    const response = await fetch('/api/admin/1/tenant', {
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return (await parse(await CheckStatus(response))) as TenantResponseAPI;
};

export const updateCurrentTenant = async (tenantId: string, request: string) => {
    const response = await fetch('/api/admin/1/tenant', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ManyWhoTenant: tenantId,
        },
        body: request,
    });

    return (await parse(await CheckStatus(response))) as TenantResponseAPI;
};

export const exportTenant = (tenantId: string, tenantName: string) => {
    const formattedTenantName = formatTenantName(tenantName);
    const now = new Date();
    const year = now.getFullYear().toString();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const date = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
    const minute = now.getMinutes().toString().padStart(2, '0');
    const second = now.getSeconds().toString().padStart(2, '0');
    const dateTimeStamp = `${year}${month}${date}${hour}${minute}${second}`;

    return downloadFile(
        '/api/package/1/tenant',
        `${formattedTenantName}_${dateTimeStamp}.tenant`,
        tenantId,
    );
};

export const importTenant = ({
    tenantId,
    file,
    progressHandler,
    completeHandler,
    errorHandler,
}: {
    tenantId: string;
    file: File;
    progressHandler: (
        this: XMLHttpRequestUpload,
        ev: ProgressEvent<XMLHttpRequestEventTarget>,
    ) => unknown;
    completeHandler: (
        this: XMLHttpRequest,
        ev: ProgressEvent<XMLHttpRequestEventTarget>,
    ) => unknown;
    errorHandler: (
        this: XMLHttpRequest,
        ev: ProgressEvent<XMLHttpRequestEventTarget> | Event,
    ) => unknown;
}) =>
    uploadFile({
        url: '/api/package/1/tenant',
        sendAsJSON: true,
        tenantId,
        file,
        progressHandler,
        completeHandler,
        errorHandler,
    });

export const allowed = async (tenantId: string) => {
    const response = await fetch('/api/draw/1/tenant/allowed', {
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    await CheckStatus(response);
};

export const getAllIntegrationAccounts = (): Promise<string[]> =>
    fetchAndParse<string[]>({
        url: '/api/admin/1/tenant/integrationaccount',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const addIntegrationAccount = async (
    tenantId: string,
    username: string,
    accountId: string,
    apiKey: string,
) => {
    const response = await fetch('/api/admin/1/tenant/integrationaccount', {
        headers: {
            ManyWhoTenant: tenantId,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ username, accountId, apiKey }),
    });

    await CheckStatus(response);
};

export const validateIntegrationAccount = async (
    tenantId: string,
    username: string,
    accountId: string,
    apiKey: string,
) => {
    const response = await fetch('/api/admin/1/tenant/integrationaccount/validate', {
        headers: {
            ManyWhoTenant: tenantId,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ username, accountId, apiKey }),
    });

    return (await parse(await CheckStatus(response))) as boolean;
};

export const getAllIntegrationAccountEnvironments = async (
    tenantId: string,
): Promise<IntegrationAccountEnvironments> => {
    const response = await fetch('/api/admin/1/tenant/integrationaccount/environments', {
        headers: {
            ManyWhoTenant: tenantId,
        },
        method: 'GET',
    });

    return (await parse(await CheckStatus(response))) as IntegrationAccountEnvironments;
};

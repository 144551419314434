interface TruncateProps {
    text: string;
    maxLength: number;
}

/**
 * @param text the string to truncate with ...
 * @param maxLength the truncation length
 * @description Trims the text to a max length suffixed with ... if shorter than maxLength
 */
const Truncate = ({ text = '', maxLength = 300 }: TruncateProps) =>
    text.length > maxLength ? (
        <>
            <span title={text}>{text.slice(0, maxLength)}</span>
            <span>&hellip;</span>
        </>
    ) : (
        <>{text}</>
    );

export default Truncate;

import { useRef } from 'react';
import { connect } from 'react-redux';
import '../../../../../../css/graph/group-element.less';
import {
    setDraggingData as setDraggingDataAction,
    setHoveringGroupElementID as setHoveringGroupElementIDAction,
    setContextMenuData as setContextMenuDataAction,
} from '../../../../../js/actions/reduxActions/graphEditor';
import { GRAPH_ELEMENT_TYPES } from '../../../../constants';
import { useGraph } from '../../../../../js/components/graph/GraphProvider';
import { sharedGraphElementProps } from '../../../../../js/components/graph/elements/graphElementUtils';
import GroupElementBase from './GroupElementBase';
import type { GroupElementAPI } from '../../../../sources/graph';
import type { Dragging, GraphEditorStore, UseGraphForMapGroupElements } from '../../../../types';

const resizeCornerSize = 20;
const resizeThickness = 15;
const halfThickness = resizeThickness / 2;

export interface OtherProps {
    groupElement: GroupElementAPI;
    setHoveringGroupElementID: (id: string) => void;
    setDraggingData: (data: Dragging) => void;
    allParentsOpen: boolean;
    zoomLevel: number;
    openConfig: () => void;
    flowId: string;
    editingToken: string;
    openMetadataEditor: () => void;
    groupElements: GroupElementAPI[];
    zoomViewBox: () => void;
    graphElement: SVGElement;
}

interface Props extends OtherProps, GraphEditorStore {}

const GroupElement = (props: Props) => {
    const {
        groupElement,
        hoveringGroupElementId,
        setHoveringGroupElementID,
        draggingOverGroupElementId,
        setDraggingData,
        allParentsOpen,
        zoomLevel,
    } = props;

    const groupEl = useRef<SVGGElement>(null);
    const useGraphData: UseGraphForMapGroupElements = useGraph();

    // if this element doesn't have a group element, then don't render
    if (!groupElement) {
        return null;
    }

    // if one of this element's parents is closed then don't render this
    if (allParentsOpen === false) {
        return null;
    }

    const { id, x, y, height, width } = groupElement;

    /*
         XL     XR
        ┏━┵─────┶━┓
    YT  ┦         ┦
        │         │
    YB  ┧         ┧
        ┗━┵─────┶━┛
    */
    const xLeft = Math.min(resizeCornerSize, width / 2);
    const xRight = Math.max(width - resizeCornerSize, width / 2);
    const yTop = Math.min(resizeCornerSize, height / 2);
    const yBottom = Math.max(height - resizeCornerSize, height / 2);

    const resizeHandles = [
        /* ━━ Top */
        {
            side: 'top',
            cursor: 'n-resize',
            edges: [
                { x: xLeft, y: -halfThickness, width: xRight - xLeft, height: resizeThickness },
            ],
        },
        /* ━┓ Top Right */
        {
            side: 'top,right',
            cursor: 'ne-resize',
            edges: [
                {
                    x: xRight,
                    y: -halfThickness,
                    width: xLeft + halfThickness,
                    height: resizeThickness,
                },
                {
                    x: width - halfThickness,
                    y: halfThickness,
                    width: resizeThickness,
                    height: yTop - halfThickness,
                },
            ],
        },
        /* ┃  Right */
        {
            side: 'right',
            cursor: 'e-resize',
            edges: [
                {
                    x: width - halfThickness,
                    y: yTop,
                    width: resizeThickness,
                    height: yBottom - yTop,
                },
            ],
        },
        /* ━┛ Bottom Right */
        {
            side: 'bottom,right',
            cursor: 'se-resize',
            edges: [
                {
                    x: xRight,
                    y: height - halfThickness,
                    width: xLeft + halfThickness,
                    height: resizeThickness,
                },
                {
                    x: width - halfThickness,
                    y: yBottom,
                    width: resizeThickness,
                    height: yTop - halfThickness,
                },
            ],
        },
        /* ━━ Bottom */
        {
            side: 'bottom',
            cursor: 's-resize',
            edges: [
                {
                    x: xLeft,
                    y: height - halfThickness,
                    width: xRight - xLeft,
                    height: resizeThickness,
                },
            ],
        },
        /* ┗━ Bottom Left */
        {
            side: 'bottom,left',
            cursor: 'sw-resize',
            edges: [
                {
                    x: -halfThickness,
                    y: height - halfThickness,
                    width: xLeft + halfThickness,
                    height: resizeThickness,
                },
                {
                    x: -halfThickness,
                    y: yBottom,
                    width: resizeThickness,
                    height: yTop - halfThickness,
                },
            ],
        },
        /* ┃  Left */
        {
            side: 'left',
            cursor: 'w-resize',
            edges: [{ x: -halfThickness, y: yTop, width: resizeThickness, height: yBottom - yTop }],
        },
        /* ┏━ Top Left */
        {
            side: 'top,left',
            cursor: 'nw-resize',
            edges: [
                {
                    x: -halfThickness,
                    y: -halfThickness,
                    width: xLeft + halfThickness,
                    height: resizeThickness,
                },
                {
                    x: -halfThickness,
                    y: halfThickness,
                    width: resizeThickness,
                    height: yTop - halfThickness,
                },
            ],
        },
    ];

    return (
        <GroupElementBase
            groupRef={groupEl}
            groupElement={groupElement}
            draggingOver={draggingOverGroupElementId === id}
            height={height}
            width={width}
            zoomLevel={zoomLevel}
            {...sharedGraphElementProps({
                ...props,
                ...useGraphData,
                ...groupElement,
                canvasNotes: [],
                element: groupElement,
                dragType: GRAPH_ELEMENT_TYPES.group,
                hoveringElementId: hoveringGroupElementId,
                toggleNoteVisibility: null,
                setHoveringElementID: setHoveringGroupElementID,
            })}
            resizeHandles={resizeHandles.map(({ edges, side, cursor }, handleIndex) => (
                <g
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        setDraggingData({
                            dragType: GRAPH_ELEMENT_TYPES.resize,
                            elementId: id,
                            handleSide: side,
                            previousElementPosition: { x, y, width, height },
                            previousMousePosition: { x: e.clientX, y: e.clientY },
                        });
                    }}
                    style={{ cursor }}
                    className="resize-handle"
                    key={`${id}-${
                        // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
                        handleIndex
                    }`}
                >
                    {edges.map((edge, edgeIndex) => (
                        <rect
                            fill="transparent"
                            x={edge.x}
                            y={edge.y}
                            width={edge.width}
                            height={edge.height}
                            // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
                            key={`${id}-handle-${handleIndex}-edge-${edgeIndex}`}
                        />
                    ))}
                </g>
            ))}
        />
    );
};

export default connect(
    ({ graphEditor }: { graphEditor: GraphEditorStore }) => ({
        ...graphEditor,
    }),
    {
        setHoveringGroupElementID: setHoveringGroupElementIDAction,
        setDraggingData: setDraggingDataAction,
        setContextMenuData: setContextMenuDataAction,
    },
)(GroupElement);

import { connect, type ConnectedProps } from 'react-redux';
import SecurityIPSetting from '../../../ts/components/tenant/SecurityIPSetting';
import type { TenantResponseAPI } from '../../types/Tenant';

const IP_RANGE = {
    admin: 'authorizedAdminIPRanges',
    draw: 'authorizedDrawIPRanges',
    packaging: 'authorizedPackagingIPRanges',
    run: 'authorizedRunIPRanges',
};

const mapStateToProps = ({
    currentTenant,
}: { currentTenant: TenantResponseAPI | null | undefined }) => {
    const { securitySettings } = currentTenant || {};

    return {
        isRunRestrictedByIPRange: securitySettings?.isRunRestrictedByIPRange ?? false,
        isDrawRestrictedByIPRange: securitySettings?.isDrawRestrictedByIPRange ?? false,
        isAdminRestrictedByIPRange: securitySettings?.isAdminRestrictedByIPRange ?? false,
        isPackagingRestrictedByIPRange: securitySettings?.isPackagingRestrictedByIPRange ?? false,
        authorizedAdminIPRanges: securitySettings?.authorizedAdminIPRanges ?? [],
        authorizedDrawIPRanges: securitySettings?.authorizedDrawIPRanges ?? [],
        authorizedPackagingIPRanges: securitySettings?.authorizedPackagingIPRanges ?? [],
        authorizedRunIPRanges: securitySettings?.authorizedRunIPRanges ?? [],
    };
};

const connector = connect(mapStateToProps);

type SecurityProps = ConnectedProps<typeof connector>;

const Security = ({
    isRunRestrictedByIPRange = false,
    isDrawRestrictedByIPRange = false,
    isAdminRestrictedByIPRange = false,
    isPackagingRestrictedByIPRange = false,
    authorizedAdminIPRanges,
    authorizedDrawIPRanges,
    authorizedPackagingIPRanges,
    authorizedRunIPRanges,
}: SecurityProps) => {
    return (
        <div className="form-group">
            <SecurityIPSetting
                IPRange={IP_RANGE.run}
                isActive={isRunRestrictedByIPRange}
                settingPropertyName="isRunRestrictedByIPRange"
                rangeEntries={authorizedRunIPRanges}
                labelText="Restrict run access by IP address"
            />
            <SecurityIPSetting
                IPRange={IP_RANGE.draw}
                isActive={isDrawRestrictedByIPRange}
                settingPropertyName="isDrawRestrictedByIPRange"
                rangeEntries={authorizedDrawIPRanges}
                labelText="Restrict draw access by IP address"
            />
            <SecurityIPSetting
                IPRange={IP_RANGE.admin}
                isActive={isAdminRestrictedByIPRange}
                settingPropertyName="isAdminRestrictedByIPRange"
                rangeEntries={authorizedAdminIPRanges}
                labelText="Restrict admin access by IP address"
            />
            <SecurityIPSetting
                IPRange={IP_RANGE.packaging}
                isActive={isPackagingRestrictedByIPRange}
                settingPropertyName="isPackagingRestrictedByIPRange"
                rangeEntries={authorizedPackagingIPRanges}
                labelText="Restrict package access by IP address"
            />
        </div>
    );
};

export default connector(Security);

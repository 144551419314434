import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';
import translations from '../../../../translations';
import type { column } from '../../../../types';

const NUMBER_OF_ROWS = 5;

const Table = ({
    label,
    columns,
    isSearchable,
}: {
    label: string | null;
    columns: column[] | null;
    isSearchable?: boolean;
}) => {
    if (!columns) {
        return <p>{translations.PAGE_BUILDER_no_columns}</p>;
    }

    const columnsToDisplay = columns
        .filter((column) => column.isDisplayValue)
        .sort((a, b) => a.order - b.order)
        .map((column) => (
            <th key={column.typeElementPropertyId} title={column.label ?? ''}>
                {column.label}
            </th>
        ));

    const cellsToDisplay = columns.map((column) => (
        <td key={`cell-${column.typeElementPropertyId}`}>-</td>
    ));

    const rowsToDisplay = [...Array(NUMBER_OF_ROWS)].map((_, i) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
        <tr key={i}>{cellsToDisplay}</tr>
    ));

    return (
        <div data-testid="component-preview-table" className="component-preview-table">
            <PreviewComponentLabel labelText={label || ''} />
            {isSearchable ? (
                <div className="component-preview-input margin-bottom form-control-width">
                    <input placeholder="Search" type="text" />
                </div>
            ) : null}
            <table id="preview-table">
                <thead>
                    <tr>{columnsToDisplay}</tr>
                </thead>
                <tbody>{rowsToDisplay}</tbody>
            </table>
        </div>
    );
};

export default Table;

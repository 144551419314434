import { clamp } from 'ramda';
import type {
    Dimensions,
    FlowGraphResponse2API,
    GroupNode,
    LeafNode,
    MapElementType,
    Point,
} from '../../../types';
import { subtractPositions } from '../../graph/utils';
import { createEdges, generatePoints, getHeadRotation } from '../utils/edge';
import {
    createNode,
    createNodes,
    dragNodes,
    getBoundingBox,
    getSelectedNodes,
    isGroupNode,
    isLeafNode,
    updateMapElementCoords,
} from '../utils/node';
import { positionsAreEqual } from '../utils/position';
import {
    dragViewBox,
    getObjectById,
    getSelectedObjectIds,
    setSelectedObjects,
    snap,
} from '../utils/svg';
import type { AddMapElementPayload, FlowEditorState, MouseEventPayload } from './types';
import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { saveMapElement } from '../../../sources/graph';
import { fetchGraph } from './reducer';
import type { FlowConfigEditorType } from '../render/GraphConfigEditor';
import { guid } from '../../../utils/guid';
import { getByID } from '../../../utils';
import { calculateIfPointIsWithinBounds } from '../../../../js/components/graph/utils';

const getEditorType = (elementType: MapElementType): FlowConfigEditorType | null => {
    switch (elementType) {
        case 'step':
            return 'step';
        case 'input':
            return 'input';
        case 'modal':
            return 'modal';
        case 'decision':
            return 'decision';
        case 'operator':
            return 'operator';
        case 'message':
            return 'message';
        case 'wait':
            return 'wait';
        case 'database_load':
            return 'database_load';
        case 'database_save':
            return 'database_save';
        case 'database_delete':
            return 'database_delete';
        case 'subflow':
            return 'subflow';
        case 'group':
            return 'group';
        case 'swimlane':
            return 'swimlane';
        default:
            return null;
    }
};

export const onMouseDown = (state: FlowEditorState, payload: MouseEventPayload) => {
    state.focusedObjectId = payload.id;

    if (payload.id === 'selectionBox') {
        return;
    }

    // Calculate selected objects
    state.selectedObjectIds = getSelectedObjectIds(
        payload.id,
        payload.objectType,
        payload.ctrlKey,
        state.flowchart,
        state.selectedObjectIds,
    );

    // Set isSelected property on selected objects to they can render a selected indicator
    state.flowchart = setSelectedObjects(state.flowchart, state.selectedObjectIds);

    const selectedNodes = getSelectedNodes(state.selectedObjectIds, state.flowchart);
    state.flowchart.selectionBox = selectedNodes.length > 1 ? getBoundingBox(selectedNodes) : null;

    // Keep a snapshot of information required for calculations when dragging
    state.dragSnapshot = {
        newObjectType: null,
        flowchart: state.flowchart,
        objectId: payload.id,
        objectType: payload.objectType,
        dragStartPoint: payload.svgPosition,
        lastSnappedDragPoint: null,
    };
};

export const onMouseMove = (state: FlowEditorState, payload: MouseEventPayload) => {
    const { dragSnapshot } = state;
    const { svgPosition } = payload;

    if (dragSnapshot === null) {
        return;
    }

    const { objectType, dragStartPoint, lastSnappedDragPoint } = dragSnapshot;

    // The x,y difference between the drag starting point and the current cursor point
    const dragDifference = subtractPositions(svgPosition, dragStartPoint);

    if (objectType === 'viewBox') {
        dragViewBox(dragDifference, state);
    }

    if (objectType === 'groupNode' || objectType === 'leafNode') {
        const snappedCursorPoint: Point = {
            x: snap(svgPosition.x),
            y: snap(svgPosition.y),
        };

        // We only need to recalculate new node and edge positions if the cursor has moved to a new snapped point
        if (positionsAreEqual(lastSnappedDragPoint, snappedCursorPoint)) {
            return;
        }

        dragSnapshot.lastSnappedDragPoint = snappedCursorPoint;
        dragNodes(dragDifference, state);
    }

    if (objectType === 'port' && dragSnapshot.objectId) {
        const hoveredLeafNodeId = Object.keys(state.flowchart.leafNodes).find((key) =>
            calculateIfPointIsWithinBounds({
                point: svgPosition,
                bounds: state.flowchart.leafNodes[key],
            }),
        );

        let draggingEdge = state.flowchart.edges['new'];
        if (draggingEdge) {
            if (draggingEdge.nodeIds[1] === hoveredLeafNodeId) {
                // outcome end has not changed
                return;
            }
            draggingEdge.nodeIds[1] = hoveredLeafNodeId ?? null;
        } else {
            draggingEdge = state.flowchart.edges['new'] = {
                id: 'new',
                label: '',
                controlPoint: null,
                points: [],
                headRotation: 0,
                isSelected: false,
                nodeIds: [dragSnapshot.objectId, hoveredLeafNodeId ?? null],
            };
        }

        const returnsToSelf = dragSnapshot.objectId === hoveredLeafNodeId;
        const points = generatePoints(
            [
                state.flowchart.leafNodes[dragSnapshot.objectId],
                hoveredLeafNodeId
                    ? state.flowchart.leafNodes[hoveredLeafNodeId]
                    : { ...svgPosition, width: 0, height: 0 },
            ],
            draggingEdge.controlPoint,
            returnsToSelf,
        );
        const headRotation = getHeadRotation(points);

        draggingEdge.points = points;
        draggingEdge.headRotation = headRotation;
    }
};

export const onDoubleClick = (state: FlowEditorState) => {
    const focusedElement =
        getByID(state.focusedObjectId ?? '', state.mapElements) ??
        getByID(state.focusedObjectId ?? '', state.groupElements) ??
        getByID(
            state.focusedObjectId ?? '',
            state.mapElements.flatMap((mapEl) => mapEl.outcomes ?? []),
        );

    if (!focusedElement) {
        return;
    }

    if ('elementType' in focusedElement) {
        state.openEditorName = getEditorType(focusedElement.elementType) ?? state.openEditorName;
        return;
    }

    if ('controlPoints' in focusedElement) {
        state.openEditorName = 'outcome';
    }
};

export const onDelete = (state: FlowEditorState, deleteAllSelected: boolean) => {
    if (state.selectedObjectIds.length === 0) {
        return;
    }

    const isEdge =
        state.focusedObjectId !== null &&
        Object.hasOwn(state.flowchart.edges, state.focusedObjectId);

    if (isEdge) {
        // TODO: delete edges
        return;
    }

    if (deleteAllSelected) {
        // When deleteAllSelected flag is used
        // force selectionBox to be focused so that
        // when confirming the delete all selected objects get deleted
        state.focusedObjectId = 'selectionBox';
    }

    state.openEditorName = 'element_delete';
    state.dragSnapshot = null;
};

export const onMouseUp = async (
    state: FlowEditorState,
    _payload: MouseEventPayload,
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): Promise<FlowConfigEditorType | null> => {
    const isNewEdge = state.dragSnapshot?.objectType === 'port';

    if (isNewEdge) {
        if (state.flowchart.edges['new'].nodeIds[1] !== null) {
            return 'outcome';
        }

        return null;
    }

    const isNewElement = state.selectedObjectIds[0] === 'new';

    if (!isNewElement) {
        // Dragged existing element(s) - Nothing to do here
        return null;
    }

    // This is a newly dragged on element

    const elementType = state.dragSnapshot?.newObjectType;
    const selectedObject = getObjectById(state.flowchart, state.selectedObjectIds[0]);

    if (!(selectedObject && elementType)) {
        return null;
    }

    if ((elementType === 'return' || elementType === 'note') && state.flowId) {
        // Immediately save the new element and refresh the graph
        const node = selectedObject as LeafNode;
        await saveMapElement(
            {
                id: guid(),
                elementType,
                developerName: elementType === 'return' ? 'Return to Parent Flow' : 'Note',
                x: node.x,
                y: node.y,
                groupElementId: node.groupId,
            },
            state.flowId,
        );

        await dispatch(fetchGraph(state.flowId));

        return null;
    }

    if (isLeafNode(selectedObject) || isGroupNode(selectedObject)) {
        // Return the new element's editor type to signify that we need to open a configuration editor
        if (elementType === 'outcome') {
            return 'outcome';
        }
        return getEditorType(elementType);
    }

    return null;
};

export const onMouseUpFulfilled = (
    state: FlowEditorState,
    editorType: FlowConfigEditorType | null,
) => {
    state.dragSnapshot = null;

    if (editorType !== null) {
        state.openEditorName = editorType;
        return;
    }

    // Update map element's coordinates to match the moved flowchart node coords
    const { mapElements, groupElements } = updateMapElementCoords(
        state.mapElements,
        state.groupElements,
        state.flowchart,
    );

    delete state.flowchart.edges['new'];
    state.mapElements = mapElements;
    state.groupElements = groupElements;
};

export const onGraphResponse = (state: FlowEditorState, response: FlowGraphResponse2API) => {
    const leafNodes = createNodes<LeafNode>(response.mapElements, response.groupElements);
    const groupNodes = createNodes<GroupNode>(response.groupElements, response.groupElements);
    const edges = createEdges(response.mapElements, response.groupElements);

    state.flowId = response.id;

    // Filter out any selected object IDs that reference objects that no longer exist
    state.selectedObjectIds = state.selectedObjectIds.filter((selectedObjectId) => {
        return [...Object.keys(leafNodes), ...Object.keys(groupNodes), ...Object.keys(edges)].some(
            (id) => id === selectedObjectId,
        );
    });

    const selectedNodes = getSelectedNodes(state.selectedObjectIds, state.flowchart);
    const selectionBox = selectedNodes.length > 1 ? getBoundingBox(selectedNodes) : null;

    state.flowchart = {
        ...state.flowchart,
        selectionBox,
        leafNodes,
        groupNodes,
        edges,
    };

    state.mapElements = response.mapElements;
    state.groupElements = response.groupElements;
};

export const onResize = (state: FlowEditorState, { height, width }: Dimensions) => {
    state.flowchart.viewBox.height = height * state.flowchart.zoom;
    state.flowchart.viewBox.width = width * state.flowchart.zoom;
};

export const setZoom = (
    state: FlowEditorState,
    { amount, SVGPoint }: { amount: number; SVGPoint: Point },
) => {
    // if (props.contextMenu) {
    //     props.setContextMenuData(null);
    // }

    const { zoom, viewBox } = state.flowchart;

    const clampedWheelDelta = clamp(-500, 500, amount);

    const scaleDelta = clampedWheelDelta * -0.001 + 1;

    // if (isNullOrEmpty(x) || isNullOrEmpty(y)) {
    //     zoomTarget = calculateGraphCentre();
    // }

    const updatedViewBox = {
        x: viewBox.x - (SVGPoint.x - viewBox.x) * (scaleDelta - 1),
        y: viewBox.y - (SVGPoint.y - viewBox.y) * (scaleDelta - 1),
        width: viewBox.width * scaleDelta,
        height: viewBox.height * scaleDelta,
    };

    const updatedZoom = zoom * scaleDelta;

    if (viewBox.width > 100000 || viewBox.height > 100000) {
        return;
    }
    if (viewBox.width < 1 || viewBox.height < 1) {
        return;
    }

    state.flowchart.zoom = updatedZoom;
    state.flowchart.viewBox = updatedViewBox;
};

export const onAddElement = (state: FlowEditorState, payload: AddMapElementPayload) => {
    const { x, y, elementType } = payload;
    const id = 'new';

    const newNode = createNode({
        id,
        x,
        y,
        elementType: elementType,
        developerName: `New ${elementType}`,
        groupElementId: null,
    });

    // Adjust the node's coords so that it's centred under the mouse cursor
    newNode.x = x - newNode.width / 2;
    newNode.y = y - newNode.height / 2;

    if (newNode.nodeType === 'leaf') {
        state.flowchart.leafNodes[newNode.id] = newNode;
    }
    if (newNode.nodeType === 'group') {
        state.flowchart.groupNodes[newNode.id] = newNode;
    }

    state.focusedObjectId = id;
    // Make this node the only selection
    state.selectedObjectIds = [id];
    // Update isSelected property on objects
    state.flowchart = setSelectedObjects(state.flowchart, state.selectedObjectIds);

    state.dragSnapshot = {
        newObjectType: elementType,
        flowchart: state.flowchart,
        objectId: newNode.id,
        objectType: newNode.nodeType === 'group' ? 'groupNode' : 'leafNode',
        dragStartPoint: { x, y },
        lastSnappedDragPoint: null,
    };
};

export const onCloseConfig = (state: FlowEditorState) => {
    state.openEditorName = null;
    state.focusedObjectId = null;
};

export const onMouseUpOutsideGraph = (state: FlowEditorState) => {
    // Do not reset while editor is open
    if (state.dragSnapshot && state.openEditorName === null) {
        // Reset dragged position
        state.flowchart = state.dragSnapshot.flowchart;
        // Reset everything that onAddElement does
        delete state.flowchart.leafNodes['new'];
        delete state.flowchart.groupNodes['new'];
        delete state.flowchart.edges['new'];
        state.focusedObjectId = null;
        state.selectedObjectIds = [];
        state.dragSnapshot = null;
    }
};

import Draw from './Draw';
import Loader from './loader/Loader';

import { useAuth } from './AuthProvider';
import { useEffect } from 'react';

const Root = () => {
    const { initializeUser, user, tenant } = useAuth();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        initializeUser();
    }, []);

    if (!user || user?.refreshing) {
        return <Loader />;
    }

    return <Draw tenantId={tenant?.id} email={user?.email} />;
};
export default Root;

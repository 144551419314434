import actionTypes from './actionTypes';

import { addNotification } from './notification';
import { getFeatureFlags } from '../../../ts/sources/featureFlags';
import { NOTIFICATION_TYPES } from '../../../ts/constants';
import { indexBy, prop } from 'ramda';

export const fetchFeatureFlags = (tenantId) => async (dispatch) => {
    let results = [];

    dispatch({
        type: actionTypes.FEATURE_FLAGS_SET_LOADER,
        payload: true,
    });

    try {
        if (tenantId !== undefined) {
            results = await getFeatureFlags();
        }
    } catch ({ message }) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: `Error fetching feature flags: ${message}`,
                isPersistent: true,
            }),
        );
    } finally {
        dispatch({
            type: actionTypes.FEATURE_FLAGS_SET_ALL,
            payload: indexBy(prop('developerName'), results),
        });
    }
};

const getAttributeContent = (selector: string, valueName: string) => {
    const content = document.querySelector(selector)?.getAttribute('content');

    if (!content) {
        throw new Error(`Failed to get ${valueName}`);
    }

    return content;
};

export const ENGINE_API_URL = getAttributeContent('meta[name="engine-api-url"]', 'ENGINE_API_URL');

export const RUNTIME_URI = getAttributeContent('meta[name="runtime-uri"]', 'RUNTIME_URI');

export const OKTA_BASE_URL = getAttributeContent('meta[name="okta-base-url"]', 'OKTA_BASE_URL');

export const OKTA_CLIENT_ID = getAttributeContent('meta[name="okta-client-id"]', 'OKTA_CLIENT_ID');

export const HOSTS = {
    federal: 'us.flow-fed.boomi.com',
};

import { useEffect, useState } from 'react';
import { getValueList } from '../../../../../../ts/sources/value';
import Loader from '../../../../../../ts/components/loader/Loader';
import { useMapElement } from '../../contextProviders/MapElementProvider';
import { criteriaOptions } from '../../../../../../ts/config/rules';
import { CONTENT_TYPES } from '../../../../../../ts/constants';
import ButtonIcon from '../../../../../../ts/components/buttons/ButtonIcon';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import ValueSelectorModal from '../../../../../../ts/components/values/selector/ValueSelectorModal';
import classnames from 'classnames';

const BusinessRule = ({ rule, setRule, removeRule, hasSubmitted }) => {
    const { notifyError, container } = useMapElement();

    const [loadingError, setLoadingError] = useState(null);
    const [selectedLeftReference, setSelectedLeftReference] = useState(null);
    const [selectedRightReference, setSelectedRightReference] = useState(null);

    const validateCriteria = (leftReference, criteriaType, newRule) => {
        const allowedCriteriaList = calculateAllowedCriteria(leftReference);

        if (
            criteriaType &&
            allowedCriteriaList.map((entry) => entry.criteria).includes(criteriaType) === false
        ) {
            return {
                ...newRule,
                criteriaType: null,
            };
        }
        return newRule;
    };

    const validateRightReference = (leftReference, criteriaType, newRule) => {
        const allowedRightContentType = calculateAllowedRightContentType(
            leftReference,
            criteriaType,
        );
        const allowedRightTypeElementId = calculateAllowedRightTypeElementId(
            leftReference,
            criteriaType,
        );
        if (
            selectedRightReference &&
            // If there is a right reference selected
            (selectedRightReference?.contentType?.toUpperCase() !== allowedRightContentType ||
                // and the right reference is either the wrong contentType
                (allowedRightTypeElementId !== null &&
                    // or, if there is a type element filter applied
                    selectedRightReference.typeElementId !== allowedRightTypeElementId))
            // and the right reference doesn't match this
        ) {
            // then the right reference is not longer valid and must be cleared
            setSelectedRightReference(null);
            return {
                ...newRule,
                rightValueElementToReferenceId: null,
            };
        }
        return newRule;
    };

    const onLeftValueToChangeSelect = (value) => {
        setSelectedLeftReference(value);

        if (value) {
            let newRule = {
                ...rule,
                leftValueElementToReferenceId: {
                    id: value?.id,
                    typeElementPropertyId: value?.typeElementPropertyId,
                    command: null,
                },
            };

            // If the left value has a selection
            // validate that the criteria picked is still valid
            newRule = validateCriteria(value, newRule?.criteriaType, newRule);
            // validate that the right value picked is still valid
            newRule = validateRightReference(value, newRule?.criteriaType, newRule);

            setRule(newRule);
        } else {
            // If the left value is being cleared
            // then clear the criteria and clear the right value
            setSelectedRightReference(null);
            setRule({
                ...rule,
                leftValueElementToReferenceId: null,
                criteriaType: null,
                rightValueElementToReferenceId: null,
            });
        }
    };

    const onCriteriaChange = (selection) => {
        const newCriteriaType = selection?.target?.value;

        // validate that the right value picked is still valid
        const newRule = validateRightReference(selectedLeftReference, newCriteriaType, {
            ...rule,
            criteriaType: newCriteriaType,
        });

        setRule(newRule);
    };

    const onRightValueToChangeSelect = (value) => {
        setSelectedRightReference(value);
        setRule({
            ...rule,
            rightValueElementToReferenceId: {
                id: value?.id,
                typeElementPropertyId: value?.typeElementPropertyId,
                command: null,
            },
        });
    };

    const leftValueId = rule.leftValueElementToReferenceId?.id ?? null;
    const leftValuePropertyId = rule.leftValueElementToReferenceId?.typeElementPropertyId ?? null;
    const rightValueId = rule.rightValueElementToReferenceId?.id ?? null;
    const rightValuePropertyId = rule.rightValueElementToReferenceId?.typeElementPropertyId ?? null;

    const isLoadingLeftRule = selectedLeftReference === null && leftValueId !== null;
    const isLoadingRightRule = selectedRightReference === null && rightValueId !== null;
    const isLoading = isLoadingLeftRule || isLoadingRightRule;

    const convertSelectedIdsIntoReference = (
        valueId,
        propertyId,
        referenceSetter,
        valueReferences,
    ) => {
        if (valueId) {
            const reference = valueReferences.find(
                (vr) => vr.id === valueId && vr.typeElementPropertyId === propertyId,
            );

            if (reference) {
                referenceSetter(reference);
            } else {
                if (propertyId) {
                    throw new Error(
                        `Could not find value reference with ID: ${valueId} and property ID: ${propertyId}`,
                    );
                }
                throw new Error(`Could not find value reference with ID: ${valueId}`);
            }
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isLoading && loadingError === null) {
            // load left and right value info from ids given

            const ids = [];
            if (leftValueId) {
                ids.push(leftValueId);
            }
            if (rightValueId) {
                ids.push(rightValueId);
            }

            const getValueReferences = async () => {
                const responseValueReferences = await getValueList({
                    id: ids,
                }).catch((error) => {
                    notifyError(error);
                    setLoadingError(typeof error === 'string' ? error : error.message);
                });
                convertSelectedIdsIntoReference(
                    leftValueId,
                    leftValuePropertyId,
                    setSelectedLeftReference,
                    responseValueReferences,
                );
                convertSelectedIdsIntoReference(
                    rightValueId,
                    rightValuePropertyId,
                    setSelectedRightReference,
                    responseValueReferences,
                );
            };

            getValueReferences();
        }
    }, [leftValueId, selectedLeftReference, rightValueId, selectedRightReference, loadingError]);

    if (loadingError) {
        return (
            <ExAlertBanner type={AlertBannerType.ERROR} open>
                {loadingError}
            </ExAlertBanner>
        );
    }

    if (isLoading) {
        return <Loader message="Loading business rule..." />;
    }

    const calculateAllowedRightContentType = (leftValue, criteria) =>
        criteria === 'IS_EMPTY'
            ? // If the criteria type is "IS_EMPTY", then the filter should just be a contentType filter of boolean
              CONTENT_TYPES.boolean
            : // Otherwise, all filters should match the contentType of the left side
              leftValue?.contentType?.toUpperCase();

    const calculateAllowedRightTypeElementId = (leftValue, criteria) =>
        criteria === 'IS_EMPTY'
            ? // If the criteria type is "IS_EMPTY", then the filter should not have a type id filter
              null
            : [CONTENT_TYPES.list, CONTENT_TYPES.object].includes(
                    leftValue?.contentType.toUpperCase(),
                )
              ? // If the let value was an Object or List, then the type should be the same for this right hand side
                leftValue?.typeElementPropertyTypeElementId ?? leftValue?.typeElementId
              : // Otherwise no type filter
                null;

    const calculateAllowedCriteria = (leftValue) =>
        criteriaOptions[
            leftValue.parentContentType === 'ContentList'
                ? 'CONTENTLISTPROPERTY'
                : leftValue.contentType.toUpperCase()
        ];

    return (
        <div className="business-rule">
            <div className="left-value-selector" data-testid="leftValueSelector">
                <ValueSelectorModal
                    value={selectedLeftReference}
                    includeSystemValues={true}
                    onChangeAsValueReference={onLeftValueToChangeSelect}
                    container={container}
                />
                {hasSubmitted && !selectedLeftReference ? (
                    <span className="help-block error-state">Please select a value.</span>
                ) : null}
            </div>
            <div className={classnames('criteria-select', { hidden: !selectedLeftReference })}>
                <select
                    className="form-control"
                    value={rule?.criteriaType ?? ''}
                    onChange={onCriteriaChange}
                    placeholder="Select a criteria type"
                    data-testid="ruleCriteriaSelect"
                >
                    {selectedLeftReference
                        ? calculateAllowedCriteria(selectedLeftReference).map(
                              ({ label, criteria }) => (
                                  <option value={criteria} key={criteria}>
                                      {label}
                                  </option>
                              ),
                          )
                        : []}
                    {rule?.criteriaType ? null : (
                        <option value="" key="" disabled>
                            --Please Select--
                        </option>
                    )}
                </select>
                {hasSubmitted && !rule?.criteriaType ? (
                    <span className="help-block error-state">Please select a criteria.</span>
                ) : null}
            </div>
            <div
                className={classnames('right-value-selector', { hidden: !rule?.criteriaType })}
                data-testid="rightValueSelector"
            >
                <ValueSelectorModal
                    value={selectedRightReference}
                    includeSystemValues={true}
                    onChangeAsValueReference={onRightValueToChangeSelect}
                    contentType={calculateAllowedRightContentType(
                        selectedLeftReference,
                        rule?.criteriaType,
                    )}
                    typeElementId={calculateAllowedRightTypeElementId(
                        selectedLeftReference,
                        rule?.criteriaType,
                    )}
                    container={container}
                />
                {hasSubmitted && !selectedRightReference ? (
                    <span className="help-block error-state">Please select a value.</span>
                ) : null}
            </div>
            <ButtonIcon
                className="margin-left-small danger"
                glyph="Delete"
                aria-label="Delete Rule"
                title="Delete Rule"
                onClick={removeRule}
                iconClass="icon-medium"
            />
        </div>
    );
};

export default BusinessRule;

import type { Reducer } from 'react';

export type Breadcrumb<TId> = {
    id: TId;
    content: string;
    onClick: () => void;
};

export type BreadcrumbState<TId> = {
    trail: Breadcrumb<TId>[];
    activeItemId: TId;
};

export type BreadcrumbAction<TId> =
    | {
          type: typeof BREADCRUMB_ACTION_TYPES.push_breadcrumb;
          payload: {
              id: TId;
              content: string;
              onClick: () => void;
          };
      }
    | {
          type: typeof BREADCRUMB_ACTION_TYPES.set_active_breadcrumb;
          payload: {
              activeItemId: TId;
          };
      };

export const BREADCRUMB_ACTION_TYPES = {
    push_breadcrumb: 'push_breadcrumb',
    set_active_breadcrumb: 'set_active_breadcrumb',
} as const;

export const createBreadcrumbReducer = <TId extends string>() => {
    const breadcrumbReducer: Reducer<BreadcrumbState<TId>, BreadcrumbAction<TId>> = (
        state,
        { type, payload },
    ) => {
        switch (type) {
            case BREADCRUMB_ACTION_TYPES.push_breadcrumb: {
                const { id, content, onClick } = payload;
                if (!state.trail.find((crumb) => crumb.id === id)) {
                    const trail = [...state.trail, { id, content, onClick }];
                    return {
                        trail,
                        activeItemId: id,
                    };
                }
                return state;
            }
            case BREADCRUMB_ACTION_TYPES.set_active_breadcrumb: {
                const { activeItemId } = payload;
                const pointer = state.trail.findIndex((crumb) => crumb.id === activeItemId);
                if (pointer !== -1) {
                    const trail = state.trail.slice(0, pointer + 1);
                    return {
                        trail,
                        activeItemId,
                    };
                }
                return state;
            }
        }
    };

    return breadcrumbReducer;
};

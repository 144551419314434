import CopyableText from '../../../generic/CopyableText';
import FormGroup from '../../../generic/FormGroup';
import type { DispatchFlowConfiguration } from './reducer';

interface Props {
    isFormValid: boolean;
    hasSubmitted: boolean;
    flowId: string;
    developerName: string | null;
    dispatchFlowConfiguration: DispatchFlowConfiguration;
}

const Name = ({
    isFormValid,
    hasSubmitted,
    flowId,
    developerName,
    dispatchFlowConfiguration,
}: Props) => (
    <>
        <FormGroup
            htmlFor="properties-name-input"
            label="Name"
            isRequired
            isValid={isFormValid}
            showValidation={hasSubmitted}
            validationMessage={'This field is required.'}
        >
            <input
                id="properties-name-input"
                type="text"
                className="form-control"
                maxLength={150}
                value={developerName ?? ''}
                onChange={({ target }) => {
                    dispatchFlowConfiguration({
                        type: 'updateName',
                        payload: {
                            name: target.value,
                        },
                    });
                }}
                required
            />
        </FormGroup>
        <CopyableText
            wrapperClassName="margin-top margin-bottom"
            copyableText={flowId}
            textClassName="quiet"
            hasCopyButton={true}
            labelText="Flow ID: "
        />
    </>
);

export default Name;

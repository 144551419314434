import { useNavigate } from 'react-router-dom';
import { Plus, Trash } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    deleteFlow as deleteFlowAction,
    fetchAllFlows as fetchAllFlowsAction,
    setFlowLabels as setFlowLabelsAction,
} from '../../../../js/actions/reduxActions/flows';
import { TAB_TYPES } from '../../../constants';
import translations from '../../../translations';
import type { FlowResponseAPI, OrderDirection } from '../../../types';
import InlineInput from '../../inputs/InlineInput';
import { getLatest, setLatest } from '../../../sources/flow';

import { useAuth } from '../../AuthProvider';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import SearchInput from '../../generic/SearchInput';
import Sortable from '../../generic/Sortable';
import Table, { type TableColumnList } from '../../generic/Table';
import FooterButtons from '../../generic/modal/FooterButtons';
import GenericModal from '../../generic/modal/GenericModal';
import ComponentWithTooltip from '../../generic/tooltip/ComponentWithTooltip';
import Loader from '../../loader/Loader';
import FlowLabels from '../FlowLabels';
import FlowUsers from './FlowUsers';
import GettingStarted from './GettingStarted';
import Select, { type MultiValue } from 'react-select';
import { getSharedStyles } from '../../../utils/select';
import { isNullOrWhitespace } from '../../../utils/guard';
import { stringContains, stringReplace } from '../../../utils/string';
import { generateRouteUrl, generateTabKey } from '../../../utils/routing';
import { isTooltipRequired } from '../../../utils/display';

interface Props {
    flows: FlowResponseAPI[];
    isLoading: boolean;
    fetchAllFlows: (tenantId: string) => void;
    deleteFlow: (flowId: string, tenantId: string) => Promise<void>;
    setFlowLabels: (payload: { flow: FlowResponseAPI; labels: string[]; tenantId: string }) => void;
    isEditorActive: boolean;
}

const FlowList = ({
    flows,
    isLoading,
    fetchAllFlows,
    deleteFlow,
    setFlowLabels,
    isEditorActive,
}: Props) => {
    // Filtering and sorting flows
    const [searchTerms, setSearchTerms] = useState('');
    const [allFlowLabels, setAllFlowLabels] = useState<string[]>([]);
    const [filterLabels, setFilterLabels] = useState<string[]>([]);
    const [filteredFlows, setFilteredFlows] = useState(flows);
    const [sortBy, setSortBy] = useState<{
        property: keyof FlowResponseAPI;
        direction: OrderDirection;
    }>({
        property: 'dateModified',
        direction: 'DESC',
    });

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedFlow, setSelectedFlow] = useState<FlowResponseAPI | null>(null);

    const [descriptionEditorOpen, setDescriptionEditorOpen] = useState<string | null>(null);

    const { user, tenant } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        const getFlowLabels = () => {
            const flowLabels: string[] = [];

            for (const { tags } of flows) {
                for (const tag of tags) {
                    if (flowLabels.includes(tag)) {
                        continue;
                    }

                    flowLabels.push(tag);
                }
            }

            setAllFlowLabels(flowLabels);
        };

        let flowsToFilter = flows.slice();

        if (!isNullOrWhitespace(searchTerms)) {
            flowsToFilter = flowsToFilter.filter(
                (flow) =>
                    stringContains(flow.developerName, searchTerms, false) ||
                    stringContains(flow.developerSummary ?? '', searchTerms, false),
            );
        }

        if (filterLabels.length > 0) {
            flowsToFilter = flowsToFilter.filter(({ tags }) => {
                return filterLabels.every((tag) => tags.includes(tag));
            });
        }

        flowsToFilter.sort((a, b) => {
            const { property, direction } = sortBy;

            if (property === 'dateModified') {
                const dateModifiedA = new Date(a.dateModified).getTime();
                const dateModifiedB = new Date(b.dateModified).getTime();

                if (direction === 'ASC') {
                    return dateModifiedA - dateModifiedB;
                }
                return dateModifiedB - dateModifiedA;
            }

            if (property === 'developerName' || property === 'developerSummary') {
                const propertyA = a[property];
                const propertyB = b[property];

                if (direction === 'ASC') {
                    return (propertyA ?? '').localeCompare(propertyB ?? '');
                }
                return (propertyB ?? '').localeCompare(propertyA ?? '');
            }

            return 0;
        });

        setFilteredFlows(flowsToFilter);
        getFlowLabels();
    }, [filterLabels, flows, searchTerms, sortBy]);

    const checkFlowsHaveChanged = (
        currentFlows: FlowResponseAPI[],
        previousFlows: FlowResponseAPI[],
    ) => {
        // Compare number of flows
        if (currentFlows.length !== previousFlows.length) {
            return true;
        }

        // Compare order of flows
        for (let i = 0; i < currentFlows.length; ++i) {
            if (currentFlows[i].id !== previousFlows[i].id) {
                return true;
            }
        }

        return false;
    };

    useEffect(() => {
        if (!(tenant && isEditorActive)) {
            return;
        }

        fetchAllFlows(tenant.id);
    }, [fetchAllFlows, isEditorActive, tenant]);

    const onClickNewFlow = () => {
        if (!tenant) {
            return;
        }

        const route = generateRouteUrl({
            tabType: TAB_TYPES.newFlow,
            tenantId: tenant.id,
            options: {
                tabKey: generateTabKey(),
            },
        });

        if (!route) {
            return;
        }

        navigate(route);
    };

    const onClickEditFlow = (flow: FlowResponseAPI) => {
        if (!tenant) {
            return;
        }

        const route = generateRouteUrl({
            tabType: TAB_TYPES.flow,
            tenantId: tenant.id,
            options: { elementId: flow.id.id },
        });

        if (!route) {
            return;
        }

        navigate(route);
    };

    const onClickDeleteFlow = (flow: FlowResponseAPI) => {
        setShowDeleteModal(true);
        setSelectedFlow(flow);
    };

    const deleteFlowConfirmation = async () => {
        if (!(selectedFlow?.id.id && tenant)) {
            return;
        }

        await deleteFlow(selectedFlow.id.id, tenant.id);

        setShowDeleteModal(false);
        fetchAllFlows(tenant.id);
    };

    const getAllFlowFilterLabels = () => {
        return allFlowLabels.map((label) => ({
            value: label,
            label,
        }));
    };

    const updateFlowLabels = (labels: string[], flow: FlowResponseAPI) => {
        if (!tenant) {
            return;
        }

        setFlowLabels({
            flow,
            labels,
            tenantId: tenant.id,
        });
    };

    const handleSelectionChange = (option: MultiValue<{ value: string; label: string }>) => {
        if (option) {
            setFilterLabels(option.map((o) => o.label));
        } else {
            setFilterLabels([]);
        }
    };

    const updateFlowDescription = async (flowId: string, value: string) => {
        if (tenant?.id) {
            const flowData = await getLatest(flowId);
            const editedFlow = {
                ...flowData,
                developerSummary: value,
            };

            await setLatest(editedFlow);

            fetchAllFlows(tenant.id);
        }
    };

    const controlsBar = (
        <div className="flow-controls-bar">
            <div className="controls-left">
                <SearchInput
                    value={searchTerms}
                    onChange={(value) => setSearchTerms(value)}
                    placeholder={translations.HOME_flow_search_placeholder}
                />
                <div className="label-filter">
                    <Select
                        inputId="flow-label-filter"
                        options={getAllFlowFilterLabels()}
                        onChange={(selectedOption) => handleSelectionChange(selectedOption)}
                        isMulti={true}
                        styles={getSharedStyles<{ label: string; value: string }, true>()}
                        placeholder={translations.HOME_flow_filter_placeholder}
                        noOptionsMessage={() => translations.HOME_flow_filter_none}
                    />
                </div>
            </div>
            <div className="controls-right">
                <ButtonPrimary onClick={onClickNewFlow}>
                    <Plus /> {translations.HOME_new_flow}
                </ButtonPrimary>
            </div>
        </div>
    );

    const columns: TableColumnList<FlowResponseAPI> = [
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) => setSortBy({ property: 'developerName', direction })}
                    direction={sortBy.property === 'developerName' ? sortBy.direction : null}
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }) =>
                isTooltipRequired(item.developerName, 25) ? (
                    <ComponentWithTooltip
                        trigger={['hover', 'focus']}
                        showDelay={500}
                        fadeTime={0.2}
                        tooltipPlacement="top"
                        tooltipContent={item.developerName}
                        wrapperClass="width-expand-to-full"
                    >
                        <button
                            className="link-emulate overflow-ellipsis"
                            onClick={() => onClickEditFlow(item)}
                            type="button"
                        >
                            {item.developerName}
                        </button>
                    </ComponentWithTooltip>
                ) : (
                    <button
                        className="link-emulate overflow-ellipsis"
                        onClick={() => onClickEditFlow(item)}
                        type="button"
                    >
                        {item.developerName}
                    </button>
                ),
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    onSort={(direction) => setSortBy({ property: 'developerSummary', direction })}
                    direction={sortBy.property === 'developerSummary' ? sortBy.direction : null}
                >
                    {translations.COMMON_TABLE_description}
                </Sortable>
            ),
            renderCell: ({ item }) => (
                <InlineInput
                    defaultValue={item.developerSummary ?? ''}
                    saveInputValue={(value) => updateFlowDescription(item.id.id, value)}
                    editButtonTitle={`Edit ${item.developerName} description`}
                    confirmButtonTitle={`Confirm ${item.developerName} description`}
                    cancelButtonTitle={`Cancel ${item.developerName} description`}
                    isOpen={descriptionEditorOpen === item.id.id}
                    setIsOpen={(isOpen) => {
                        if (isOpen) {
                            setDescriptionEditorOpen(item.id.id);
                        } else {
                            setDescriptionEditorOpen(null);
                        }
                    }}
                />
            ),
            cellClassName: 'generic-cell-simple',
        },
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) => setSortBy({ property: 'dateModified', direction })}
                    direction={sortBy.property === 'dateModified' ? sortBy.direction : null}
                    defaultDirection={'ASC'}
                >
                    {translations.COMMON_TABLE_last_modified}
                </Sortable>
            ),
            renderCell: ({ item }) =>
                new Date(item.dateModified).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_users,
            renderCell: ({ item }) => <FlowUsers flowId={item.id.id} />,
            size: '12rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_labels,
            renderCell: ({ item }) => (
                <FlowLabels
                    currentLabels={item.tags}
                    allFlowLabels={allFlowLabels}
                    saveFlowLabels={(labels) => updateFlowLabels(labels, item)}
                />
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`${translations.COMMON_delete} ${item.developerName}`}
                        className="table-icon table-icon-delete"
                        onClick={() => onClickDeleteFlow(item)}
                        aria-label={`${translations.COMMON_delete} ${item.developerName}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    const onConfirmDeleteCancel = () => {
        setSelectedFlow(null);
        setShowDeleteModal(false);
    };

    const renderBody = () => {
        const confirmMessage = stringReplace(
            translations.HOME_delete_confirm,
            selectedFlow?.developerName ?? '',
        );

        return (
            <div className="container-fluid">
                <div className="message-line">{confirmMessage}</div>
                <div className="message-line">{translations.COMMON_cannot_be_undone}</div>
            </div>
        );
    };

    const renderFooter = () => (
        <FooterButtons
            confirm={deleteFlowConfirmation}
            confirmButtonText={translations.COMMON_delete}
            confirmButtonClasses="btn-danger"
            cancel={onConfirmDeleteCancel}
            cancelButtonText={translations.COMMON_cancel}
        />
    );

    const renderMainContent = () => {
        if (isLoading) {
            return (
                <>
                    {controlsBar}
                    <Loader message={translations.HOME_loading_flows} />
                </>
            );
        }
        if (flows.length > 0) {
            return (
                <>
                    {controlsBar}
                    <Table
                        items={filteredFlows}
                        columns={columns}
                        pagination={true}
                        resetComparator={checkFlowsHaveChanged}
                    />
                </>
            );
        }

        return <GettingStarted onNewFlowClick={onClickNewFlow} />;
    };

    return (
        <>
            {showDeleteModal ? (
                <GenericModal
                    className="confirm-modal"
                    onHide={onConfirmDeleteCancel}
                    renderHeader={() => (
                        <h4 className="modal-title">
                            {translations.FLOW_delete_confirmation_title}
                        </h4>
                    )}
                    renderBody={renderBody}
                    renderFooter={renderFooter}
                />
            ) : null}
            <h1>
                {translations.HOME_welcome_back}, {user?.firstName}
            </h1>
            <h4>{translations.HOME_heading}</h4>
            {renderMainContent()}
        </>
    );
};

const mapStateToProps = ({
    flows: { all, isLoading },
}: {
    flows: { all: FlowResponseAPI[]; isDeleting: boolean; isLoading: boolean };
}) => ({
    flows: all,
    isLoading,
});

const mapDispatchToProps = {
    deleteFlow: deleteFlowAction,
    fetchAllFlows: fetchAllFlowsAction,
    setFlowLabels: setFlowLabelsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowList);

import classNames from 'classnames';
import { ExIcon, ExIconButton, IconButtonType, IconVariant } from '@boomi/exosphere';
import { type ElementRef, useRef, useState } from 'react';
import { useDocumentClick } from '../../../hooks';
import { useDebugConfig } from './DebugConfigProvider';

const PanelPositionDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { setDebugConfig, debugConfig } = useDebugConfig();

    const dropDownRef = useRef<ElementRef<'div'>>(null);

    useDocumentClick(dropDownRef, () => setIsOpen(false));

    const classes = classNames('dropdown-box', 'flex', 'relative', {
        'is-open': isOpen,
    });

    return (
        <div className={classes}>
            <div className="flex margin-left" ref={dropDownRef}>
                <ExIconButton
                    onClick={() => setIsOpen(!isOpen)}
                    icon="More options"
                    type={IconButtonType.SECONDARY}
                    data-testid="debugger-dropdown"
                />
            </div>
            <div className="dropdown-list-wrapper">
                <div className={'list-unstyled dropdown-list'}>
                    <button
                        className="dropdown-link"
                        data-testid="position-bottom"
                        onClick={() => setDebugConfig({ ...debugConfig, position: 'BOTTOM' })}
                        type="button"
                    >
                        <ExIcon
                            className="icon-large dropdown-link-icon"
                            icon="Move down"
                            label="test"
                            variant={IconVariant.DEFAULT}
                        />
                        <div className="dropdown-content">Dock to the bottom</div>
                    </button>
                    <button
                        className="dropdown-link"
                        data-testid="position-left"
                        onClick={() => setDebugConfig({ ...debugConfig, position: 'LEFT' })}
                        type="button"
                    >
                        <ExIcon
                            className="icon-large dropdown-link-icon"
                            icon="Move left"
                            label="test"
                            variant={IconVariant.DEFAULT}
                        />
                        <div className="dropdown-content">Dock to the left</div>
                    </button>
                    <button
                        className="dropdown-link"
                        data-testid="position-right"
                        onClick={() => setDebugConfig({ ...debugConfig, position: 'RIGHT' })}
                        type="button"
                    >
                        <ExIcon
                            className="icon-large dropdown-link-icon"
                            icon="Move right"
                            label="test"
                            variant={IconVariant.DEFAULT}
                        />
                        <div className="dropdown-content">Dock to the right</div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PanelPositionDropdown;

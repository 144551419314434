import { type ReactNode, useState } from 'react';
import translations from '../../translations';

interface ShowHideProps {
    showInitially?: boolean;
    renderTrigger?: (isShowing: boolean) => ReactNode;
    children: ReactNode;
}

const ShowHide = ({ showInitially = false, renderTrigger, children }: ShowHideProps) => {
    const [isShowing, setIsShowing] = useState(showInitially);

    return isShowing ? (
        <>{children}</>
    ) : (
        <button
            className="btn-no-appearance-left"
            onClick={() => setIsShowing(!isShowing)}
            type="button"
        >
            {renderTrigger?.(isShowing) ?? (
                <span className="link-emulate">
                    {isShowing
                        ? translations.SHOW_HIDE_hide_button_label
                        : translations.SHOW_HIDE_show_button_label}
                </span>
            )}
        </button>
    );
};

export default ShowHide;

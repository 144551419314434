import { useState, useEffect } from 'react';
import FormGroup from '../../generic/FormGroup';
import { getValueList } from '../../../sources/value';

import { notifyError as notifyErrorAction } from '../../../../js/actions/reduxActions/notification';
import { connect } from 'react-redux';

import type { ServiceElementResponseAPI, ServiceAuthenticationType } from '../../../types/service';
import type { ValueElementIdReferenceAPI } from '../../../types';
import ValueSelectorModal from '../../values/selector/ValueSelectorModal';
import { isNullOrEmpty } from '../../../utils/guard';
import { decodeValueReference } from '../../../utils/flow';

interface ClientCertificateConfigurationProps {
    certificateReference: string | undefined | null;
    certificatePasswordReference: string | undefined | null;
    authenticationType: ServiceAuthenticationType;
    setNewServiceDetails: (data: ServiceElementResponseAPI) => void;
    serviceData: ServiceElementResponseAPI;
    notifyError: (error: string | Error) => void;
}

const ClientCertificateConfiguration = ({
    certificateReference,
    certificatePasswordReference,
    authenticationType,
    setNewServiceDetails,
    serviceData,
    notifyError,
}: ClientCertificateConfigurationProps) => {
    const [certificateValue, setCertificateValue] = useState<ValueElementIdReferenceAPI | null>(
        null,
    );
    const [certificatePasswordValue, setCertificatePasswordValue] =
        useState<ValueElementIdReferenceAPI | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        getValueReferences();
    }, [certificateReference, certificatePasswordReference]);

    const getValueReferences = async () => {
        const certValue = await convertValueReference(certificateReference);
        const pwValue = await convertValueReference(certificatePasswordReference);

        setCertificateValue(certValue);
        setCertificatePasswordValue(pwValue);
    };

    const convertValueReference = async (valueReferenceText: string | undefined | null) => {
        if (!valueReferenceText) {
            return null;
        }
        const { valueName, propertyName } = decodeValueReference(valueReferenceText);

        try {
            if (valueName === null) {
                throw Error(`A value could not be found from the reference: ${valueReferenceText}`);
            }

            const response = await getValueList({
                search: valueName,
            });
            const foundValue = response.find(
                (valueReference) =>
                    valueReference.developerName === valueName &&
                    valueReference.typeElementPropertyDeveloperName === propertyName,
            );
            if (foundValue) {
                return foundValue;
            }
            throw Error(`A value could not be found from the reference: ${valueReferenceText}`);
        } catch (error) {
            notifyError((error as Error).message);
            return null;
        }
    };

    const getValueReference = (value: ValueElementIdReferenceAPI | null): null | string => {
        if (!value) {
            return null;
        }
        const { developerName, typeElementPropertyDeveloperName } = value;
        return isNullOrEmpty(typeElementPropertyDeveloperName)
            ? `{![${developerName}]}`
            : `{![${developerName}].[${typeElementPropertyDeveloperName}]}`;
    };

    const onSelectCertificateData = (value: ValueElementIdReferenceAPI | null): void => {
        setCertificateValue(value);
        setNewServiceDetails({
            ...serviceData,
            httpAuthenticationClientCertificateReference: getValueReference(value),
        });
    };

    const onSelectCertificatePassword = (value: ValueElementIdReferenceAPI | null): void => {
        setCertificatePasswordValue(value);
        setNewServiceDetails({
            ...serviceData,
            httpAuthenticationClientCertificatePasswordReference: getValueReference(value),
        });
    };

    if (authenticationType !== 'CLIENT_CERTIFICATE') {
        return null;
    }

    return (
        <div data-testid="client-certificate-values">
            <div className="form-control-long">
                <FormGroup label="Enter Client Certificate Data">
                    <ValueSelectorModal
                        value={certificateValue}
                        onChangeAsValueReference={onSelectCertificateData}
                        contentType="ContentString"
                        container={null}
                        includeSystemValues={false}
                    />
                </FormGroup>
            </div>
            <div className="form-control-long">
                <FormGroup label="Enter Client Certificate Password">
                    <ValueSelectorModal
                        value={certificatePasswordValue}
                        onChangeAsValueReference={onSelectCertificatePassword}
                        contentType="ContentPassword"
                        container={null}
                        includeSystemValues={false}
                    />
                </FormGroup>
            </div>
        </div>
    );
};

export default connect(null, { notifyError: notifyErrorAction })(ClientCertificateConfiguration);

import { Trash } from '@phosphor-icons/react';
import { useState } from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import { changeSAMLSetting } from '../../../../js/actions/reduxActions/currentTenant';
import { notifyError } from '../../../../js/actions/reduxActions/notification';
import translations from '../../../translations';
import type { SecuritySettingsAPI } from '../../../types/Tenant';
import { stringReplace } from '../../../utils';
import { isNullOrWhitespace } from '../../../utils/guard';
import Table, { type TableColumnList } from '../../generic/Table';

const mapDispatchToProps = {
    changeSAMLSetting,
    notifyError,
};

const connector = connect(null, mapDispatchToProps);

type SAMLSettingsGroupsProps = {
    settings: SecuritySettingsAPI['samlSettings'];
} & ConnectedProps<typeof connector>;

const SAMLSettingsGroups = ({
    settings,
    changeSAMLSetting,
    notifyError,
}: SAMLSettingsGroupsProps) => {
    const [groupToAdd, updateGroupToAdd] = useState('');

    const groups = settings?.groupSettings?.groups ?? [];

    const onAddGroup = (newGroup: string) => {
        if (isNullOrWhitespace(newGroup)) {
            notifyError(translations.SAML_groups_invalid_group_name_error_message);
        } else if (groups.find((group) => group === newGroup)) {
            notifyError(
                stringReplace(translations.SAML_groups_duplicate_group_name_error_message, {
                    group: newGroup,
                }),
            );
        } else {
            changeSAMLSetting({
                settingPath: ['groupSettings', 'groups'],
                value: [...groups, newGroup],
            });
        }
    };

    const onRemoveGroup = (groupToRemove: string) => {
        changeSAMLSetting({
            settingPath: ['groupSettings', 'groups'],
            value: groups.filter((group) => group !== groupToRemove),
        });
    };

    const columns: TableColumnList<string> = [
        {
            renderHeader: () => translations.COMMON_TABLE_group_name,
            renderCell: ({ item }) => item,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={translations.SAML_groups_remove_group_button_label}
                        className="table-icon table-icon-delete"
                        aria-label={translations.SAML_groups_remove_group_button_label}
                        onClick={() => onRemoveGroup(item)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <div className="form-group">
            <div className="restriction-widget">
                <label htmlFor="saml-groups-label">{translations.SAML_groups_label}</label>
                <div className="flex">
                    <input
                        id="saml-groups-label"
                        className="form-control"
                        type="text"
                        onChange={(e) => updateGroupToAdd(e.target.value)}
                        placeholder={translations.SAML_groups_group_input_placeholder}
                    />
                    <button
                        className="btn btn-sm btn-success add-restriction"
                        onClick={() => onAddGroup(groupToAdd)}
                        type="button"
                    >
                        {translations.COMMON_add}
                    </button>
                </div>
                <Table wrapperClassName="margin-top" columns={columns} items={groups} />
                <p className="help-block">
                    {translations.SAML_groups_help_1}
                    <br />
                    {translations.SAML_groups_help_2}
                </p>
            </div>
        </div>
    );
};

export default connector(SAMLSettingsGroups);

import { type AnyAction, type ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { type Dispatch, type ReactNode, useRef } from 'react';
import { Provider } from 'react-redux';
import { reducer } from '../state/reducer';
import type { FlowEditorState } from '../state/types';

export interface FlowEditorDispatch {
    dispatch: ThunkDispatch<FlowEditorState, undefined, AnyAction> & Dispatch<AnyAction>;
}

interface Props {
    children: (props: FlowEditorDispatch) => ReactNode;
}

const FlowEditorStoreProvider = ({ children }: Props) => {
    // Each instance of FlowEditor needs it's own store.
    // Hold the store in a ref to keep a stable instance that will be destroyed on unmount.
    const storeRef = useRef(configureStore({ reducer }));

    return (
        <Provider store={storeRef.current}>
            {children({
                dispatch: storeRef.current.dispatch,
            })}
        </Provider>
    );
};

export default FlowEditorStoreProvider;

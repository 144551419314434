import { ELEMENT_EDGES, PAGE_ELEMENT_TYPES, CONTAINER_TYPE } from '../constants';
import type { ComposerElement } from '../../../types';
import type { ElementSelection } from './gui/composer/ComposerProvider';

export type ContainerType =
    | 'VERTICAL_FLOW'
    | 'HORIZONTAL_FLOW'
    | 'INLINE_FLOW'
    | 'GROUP'
    | 'CHARTS';

export type Edge = 'top' | 'bottom' | 'left' | 'right' | 'all';

export type PageElementType = 'CONTAINER' | 'COMPONENT';

const ELEMENT_BOUNDS_OFFSET = 25; // Pixels

export const shouldDisplayInline = (containerType: ContainerType): boolean =>
    containerType === CONTAINER_TYPE['column'] || containerType === CONTAINER_TYPE['inline'];

export const shouldDisplayBlock = (containerType: ContainerType): boolean =>
    containerType === CONTAINER_TYPE['row'] ||
    containerType === CONTAINER_TYPE['group'] ||
    containerType === CONTAINER_TYPE['chart'];

/**
 * @param hoverClientX number of pixels from the left edge of the element
 * @param hoverMiddleX horizontal middle of element
 * @param hoverClientY number of pixels from the top edge of the element
 * @param hoverMiddleY vertical middle of element
 * @description determine which edge of the element being hovered over gets highlighted
 */
const setElementBorder = (
    hoverClientX: number,
    hoverMiddleX: number,
    hoverClientY: number,
    hoverMiddleY: number,
    pageElements: ComposerElement[],
    parentId: string | null,
    containerType: ContainerType,
): Edge | null => {
    let edge = null;

    const offsetBottom = hoverMiddleY + ELEMENT_BOUNDS_OFFSET;
    const offsetTop = hoverMiddleY - ELEMENT_BOUNDS_OFFSET;

    // If the element has a parent we need to know it's element
    // type to determine whether to highlight the vertical or horizontal edges
    const currentElementsParent = pageElements.find((element) => element.id === parentId);

    if (
        // Mouse is hovering over to the right
        hoverClientX > hoverMiddleX &&
        shouldDisplayInline(
            currentElementsParent?.containerType
                ? currentElementsParent.containerType
                : containerType,
        )
    ) {
        edge = ELEMENT_EDGES['right'] as Edge;
    }

    if (
        // Mouse is hovering over to the left
        hoverClientX < hoverMiddleX &&
        shouldDisplayInline(
            currentElementsParent?.containerType
                ? currentElementsParent.containerType
                : containerType,
        )
    ) {
        edge = ELEMENT_EDGES['left'] as Edge;
    }

    if (
        // Mouse is hovering towards the top
        hoverClientY < hoverMiddleY &&
        shouldDisplayBlock(
            currentElementsParent?.containerType
                ? currentElementsParent.containerType
                : containerType,
        )
    ) {
        edge = ELEMENT_EDGES['top'] as Edge;
    }

    if (
        // Mouse is hovering towards the bottom
        hoverClientY > hoverMiddleY &&
        shouldDisplayBlock(
            currentElementsParent?.containerType
                ? currentElementsParent.containerType
                : containerType,
        )
    ) {
        edge = ELEMENT_EDGES['bottom'] as Edge;
    }

    // Mouse is hovering in the center
    if (
        (shouldDisplayInline(
            currentElementsParent?.containerType
                ? currentElementsParent.containerType
                : containerType,
        ) &&
            hoverClientX > ELEMENT_BOUNDS_OFFSET &&
            hoverClientX < hoverMiddleX * 2 - ELEMENT_BOUNDS_OFFSET) ||
        (hoverClientY < offsetBottom &&
            hoverClientY > offsetTop &&
            shouldDisplayBlock(
                currentElementsParent?.containerType
                    ? currentElementsParent.containerType
                    : containerType,
            ))
    ) {
        // Only indicate ability to drop into a container and not a component
        if (containerType) {
            edge = ELEMENT_EDGES['all'] as Edge;
        }
    }

    return edge;
};

export const dropPageElement = (
    didDrop: boolean,
    droppedPageElement: ComposerElement,
    pageElements: ComposerElement[],
    onDrop: (pageElement: ElementSelection) => void,
    id: string,
    parentId: string | null,
    order: number,
    pageElementType: PageElementType,
    edge: Edge | null,
) => {
    // We are only interested in the drop event
    // for the immediate element, not it's ancestors
    if (didDrop) {
        return undefined;
    }

    const {
        containerType: elementDroppedcontainerType,
        id: elementDroppedId,
        order: elementDroppedOrder,
        parentId: elementDroppedParentId,
        pageElementType: elementDroppedPageElementType,
        componentType: elementDroppedComponentType,
    } = droppedPageElement;

    const elementEdge = edge;

    // You cannot drop an element onto a page component
    if (
        elementEdge === ELEMENT_EDGES['all'] &&
        pageElementType === PAGE_ELEMENT_TYPES['component']
    ) {
        return undefined;
    }

    const elementChildren: string[] = [];

    const checkElementChildren = (droppedOnParentId: string | null) => {
        const pageElementParent = pageElements.find((f) => f.id === droppedOnParentId);
        if (pageElementParent) {
            elementChildren.push(pageElementParent.id as string);
            checkElementChildren(pageElementParent.parentId || null);
        }
    };

    checkElementChildren(parentId);

    // We only want to re-order if the element is not being
    // dropped on itself or any of it's ancestors
    if (
        elementDroppedId !== null &&
        elementDroppedId !== id &&
        !elementChildren.includes(elementDroppedId)
    ) {
        // If there is no element edge defined then
        // we want to nest the element within it
        if (elementEdge === ELEMENT_EDGES['all']) {
            const pageContainers = pageElements;

            // This is just so that if the container is dropped
            // into the main container, it will always be ordered last
            const newOrder = pageContainers.filter((container) => container.parentId === id).length;

            onDrop({
                id: elementDroppedId || null,
                targetId: id,
                order: newOrder,
                containerType: elementDroppedcontainerType,
                pageElementType: elementDroppedPageElementType || '',
                componentType: elementDroppedComponentType || '',
            });
        } else {
            // Re-ordering elements upwards
            if (elementEdge === ELEMENT_EDGES['top'] || elementEdge === ELEMENT_EDGES['left']) {
                if (elementDroppedOrder !== order - 1 || elementDroppedParentId !== parentId) {
                    onDrop({
                        id: elementDroppedId || null,
                        targetId: parentId,
                        order:
                            elementDroppedOrder < order && elementDroppedParentId === parentId
                                ? order - 1
                                : order,
                        containerType: elementDroppedcontainerType,
                        pageElementType: elementDroppedPageElementType || '',
                        componentType: elementDroppedComponentType || '',
                    });
                }
            }

            // Re-ordering elements downwards
            if (elementEdge === ELEMENT_EDGES['bottom'] || elementEdge === ELEMENT_EDGES['right']) {
                onDrop({
                    id: elementDroppedId || null,
                    targetId: parentId,
                    order:
                        elementDroppedOrder < order && elementDroppedParentId === parentId
                            ? order
                            : order + 1,
                    containerType: elementDroppedcontainerType,
                    pageElementType: elementDroppedPageElementType || '',
                    componentType: elementDroppedComponentType || '',
                });
            }
        }
    }

    return { name: 'EXISTING_COMPONENT' };
};

export const hoverPageElement = (
    draggedPageElement: ComposerElement,
    parentId: string,
    order: number,
    setEdge: (edge: Edge | null) => void,
    pageElements: ComposerElement[],
    containerType: ContainerType,
    clientOffsetX: number,
    clientOffsetY: number,
    boundingRectTop: number,
    boundingRectBottom: number,
    boundingRectRight: number,
    boundingRectLeft: number,
) => {
    const dragIndex = draggedPageElement.order;
    const hoverIndex = order;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex && draggedPageElement.parentId === parentId) {
        setEdge(null);
    }

    // Get vertical middle
    const hoverMiddleY = (boundingRectBottom - boundingRectTop) / 2;

    // Get horizontal middle
    const hoverMiddleX = (boundingRectRight - boundingRectLeft) / 2;

    // Get pixels to the top
    const hoverClientY = clientOffsetY - boundingRectTop;

    // Get pixels to the left
    const hoverClientX = clientOffsetX - boundingRectLeft;

    if (parentId) {
        const updatedEdge = setElementBorder(
            hoverClientX,
            hoverMiddleX,
            hoverClientY,
            hoverMiddleY,
            pageElements,
            parentId,
            containerType,
        );

        setEdge(updatedEdge);
    } else {
        setEdge(ELEMENT_EDGES['all'] as Edge);
    }
};

import { useRef } from 'react';
import '../../../../../css/expandable-area.less';
import '../../../../../css/wide-tile.less';
import translations from '../../../../ts/translations';
import EnvironmentsList from './EnvironmentsList';
import ReleasesList from './ReleasesList';
import { useEnvironments } from '../../../../ts/components/admin/environments/EnvironmentsProvider';

const Environments = () => {
    const { managingEnvironments, setIsManagingEnvironments } = useEnvironments();
    const modalContainerRef = useRef('');

    const editEnvironments = () => {
        setIsManagingEnvironments(!managingEnvironments);
    };

    return (
        <div ref={modalContainerRef} className="no-padding-top admin-page">
            <h1>{translations.ENVIRONMENT_name}</h1>
            <p>{translations.ENVIRONMENT_header}</p>
            <hr />
            <h3>{translations.ENVIRONMENT_releases_title}</h3>
            <ReleasesList modalContainerRef={modalContainerRef} />
            <hr />
            <h3>
                {translations.ENVIRONMENT_name}

                <button
                    className={
                        managingEnvironments
                            ? 'btn btn-danger float-right'
                            : 'btn btn-primary float-right'
                    }
                    onClick={() => {
                        editEnvironments();
                    }}
                    type="button"
                >
                    {managingEnvironments
                        ? translations.ENVIRONMENT_cancel
                        : translations.ENVIRONMENT_edit}
                </button>
            </h3>
            <EnvironmentsList modalContainerRef={modalContainerRef} />
        </div>
    );
};

export default Environments;

import { useEffect, useMemo } from 'react';
import ButtonAction from '../../../../../buttons/ButtonAction';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import {
    getAllPageConditionValues,
    getFormattedSummary,
    getIDsFromPageComponents,
    getIDsFromPageConditions,
} from './pageConditionsUtils';
import { isNullOrEmpty } from '../../../../../../utils/guard';
import { usePageCondition } from '../PageConditionsProvider';
import { usePageEditor } from '../../../PageEditorProvider';
import { NOTIFICATION_TYPES } from '../../../../../../constants';
import translations from '../../../../../../translations';
import { SUMMARY_CONTEXT } from '../../../../constants';
import Loader from '../../../../../loader/Loader';

const PageConditionItemList = ({ listContext }: { listContext: string }) => {
    const { state: pageBuilderState, addNotification } = usePageEditor();
    const { page } = pageBuilderState;
    const { pageConditions = [], pageComponents = [] } = page;
    const {
        state: pageConditionState,
        updateCondition,
        updateConditionIndex,
        updateRuleIndex,
        updateOperationIndex,
        deleteCondition,
        deleteRule,
        deleteOperation,
        updateRefreshValues,
        updateValues,
    } = usePageCondition();
    const { condition, refreshValues, values } = pageConditionState;

    // Fetching extra info for any values that are used in the page conditions/rule/operations.
    const fetchValues = async (valuesIDs: string[]) => {
        try {
            const allValues = await getAllPageConditionValues(valuesIDs);
            updateValues(allValues);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: `${translations.PAGE_BUILDER_summary_error}: ${(error as Error).message}`,
                isPersistent: true,
            });
            updateValues(null);
        } finally {
            updateRefreshValues(false);
        }
    };

    const title =
        listContext === SUMMARY_CONTEXT['CONDITIONS']
            ? 'Conditions'
            : listContext === SUMMARY_CONTEXT['RULES']
              ? 'Rules'
              : listContext === SUMMARY_CONTEXT['OPERATIONS']
                ? 'Operations'
                : null;

    // Gather all the bits we need to generate the summaries.
    // When adding or editing a rule/operation we can create new values so these should also be included.
    const componentsIDs = useMemo(() => getIDsFromPageComponents(pageComponents), [pageComponents]);
    const conditionsIDs = useMemo(() => getIDsFromPageConditions(pageConditions), [pageConditions]);
    const brandNewIDs = useMemo(
        () => getIDsFromPageConditions(condition !== null ? [condition] : null),
        [condition],
    );
    const combinedIDs = [...conditionsIDs, ...componentsIDs, ...brandNewIDs];
    const uniqueIDs = [...new Set(combinedIDs)] as string[];
    const data =
        listContext === SUMMARY_CONTEXT['CONDITIONS']
            ? pageConditions
            : listContext === SUMMARY_CONTEXT['RULES']
              ? condition?.pageRules
              : listContext === SUMMARY_CONTEXT['OPERATIONS']
                ? condition?.pageOperations
                : null;

    // Generating summaries is expensive so don't do it unless we have all the bits that we need.
    const basicDataIsAvailable = !!data?.length && !!pageComponents?.length && !!listContext;
    const valueDataIsAvailable = uniqueIDs?.length ? !refreshValues && !!values?.length : true;
    const summaries =
        basicDataIsAvailable && valueDataIsAvailable
            ? getFormattedSummary(data, values, pageComponents, listContext)
            : null;

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (uniqueIDs?.length && refreshValues) {
            fetchValues(uniqueIDs);
        }
    }, [condition]);

    const onEditItem = (index: number) => {
        switch (listContext) {
            case SUMMARY_CONTEXT['CONDITIONS']: {
                updateConditionIndex(index);

                if (pageConditions) {
                    updateCondition(pageConditions[index]);
                }
                break;
            }
            case SUMMARY_CONTEXT['RULES']: {
                updateRuleIndex(index);
                break;
            }
            case SUMMARY_CONTEXT['OPERATIONS']: {
                updateOperationIndex(index);
                break;
            }
        }
    };

    const onDeleteItem = (index: number) => {
        switch (listContext) {
            case SUMMARY_CONTEXT['CONDITIONS']: {
                deleteCondition(index);
                break;
            }
            case SUMMARY_CONTEXT['RULES']: {
                deleteRule(index);
                break;
            }
            case SUMMARY_CONTEXT['OPERATIONS']: {
                deleteOperation(index);
                break;
            }
        }
    };

    const renderNoData = () => <p className="group-message">No data</p>;

    const renderData = () => (
        <ul className="group-list">
            {summaries?.map((summary, index) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: Treat warnings as errors, fix later
                <li key={index} className="group-item">
                    <div className="item-actions">
                        <ButtonAction
                            className="btn-tertiary"
                            onClick={() => onEditItem(index)}
                            title="Edit"
                        >
                            <PencilSimple size={18} />
                        </ButtonAction>
                        <ButtonAction
                            className="btn-tertiary danger"
                            onClick={() => onDeleteItem(index)}
                            title="Delete"
                        >
                            <Trash size={18} />
                        </ButtonAction>
                    </div>
                    <div className="item-info">{summary}</div>
                </li>
            ))}
        </ul>
    );

    return (
        <div className="group">
            <h4 className="group-heading">{title}</h4>
            {isNullOrEmpty(data) ? renderNoData() : summaries?.length ? renderData() : <Loader />}
        </div>
    );
};

export default PageConditionItemList;

import { useEffect, useState } from 'react';
import {
    RELATIVE_DATETIME_UNITS,
    RELATIVE_DATE_UNITS,
    OPERATION_COMMANDS,
} from '../../../../../ts/components/flow/elementConfigurations/operator/constants';
import { CONTENT_TYPES } from '../../../../../ts/constants';
import { getType } from '../../../../../ts/sources/type';
import { getValue, getValueList } from '../../../../../ts/sources/value';
import { isNullOrEmpty } from '../../../../../ts/utils/guard';
import { getByID } from '../../../../../ts/utils/collection';
import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import Loader from '../../../../../ts/components/loader/Loader';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import { useOperator } from './OperatorProvider';
import ModalBody from '../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../ts/components/generic/modal/ModalFooter';
import ValueSelectorModal from '../../../../../ts/components/values/selector/ValueSelectorModal';

const SORT_DIRECTION = {
    ascending: 'ASCENDING',
    descending: 'DESCENDING',
};

export const getVisibleApplyCommands = (valueToApply) => {
    if (valueToApply === null) {
        return [];
    }

    const applyContentType = valueToApply?.contentType?.toUpperCase() ?? '';
    const nonFlaggedDateCommands = [
        OPERATION_COMMANDS.setEqual,
        OPERATION_COMMANDS.empty,
        OPERATION_COMMANDS.new,
    ];
    switch (applyContentType) {
        case CONTENT_TYPES.string:
            return [
                OPERATION_COMMANDS.setEqual,
                OPERATION_COMMANDS.empty,
                OPERATION_COMMANDS.new,
                OPERATION_COMMANDS.join,
            ];

        case CONTENT_TYPES.number:
            return [
                OPERATION_COMMANDS.setEqual,
                OPERATION_COMMANDS.empty,
                OPERATION_COMMANDS.new,
                OPERATION_COMMANDS.add,
                OPERATION_COMMANDS.subtract,
                OPERATION_COMMANDS.divide,
                OPERATION_COMMANDS.multiply,
                OPERATION_COMMANDS.modulo,
            ];

        case CONTENT_TYPES.list:
            return [
                OPERATION_COMMANDS.setEqual,
                OPERATION_COMMANDS.empty,
                OPERATION_COMMANDS.new,
                OPERATION_COMMANDS.update,
                OPERATION_COMMANDS.remove,
                OPERATION_COMMANDS.sort,
            ];

        case CONTENT_TYPES.datetime:
        case CONTENT_TYPES.date:
            return [...nonFlaggedDateCommands, OPERATION_COMMANDS.setRelativeDate];
        default:
            return [OPERATION_COMMANDS.setEqual, OPERATION_COMMANDS.empty, OPERATION_COMMANDS.new];
    }
};

export const getVisibleReferenceCommands = (valueToApply, showReferenceCommandSelector) => {
    if (valueToApply === null || showReferenceCommandSelector === false) {
        return [];
    }

    const applyContentType = valueToApply?.contentType?.toUpperCase() ?? '';

    switch (applyContentType) {
        case CONTENT_TYPES.number:
            return [OPERATION_COMMANDS.valueOf, OPERATION_COMMANDS.getLength];

        case CONTENT_TYPES.object:
            return [
                OPERATION_COMMANDS.valueOf,
                OPERATION_COMMANDS.getFirst,
                OPERATION_COMMANDS.getLast,
                OPERATION_COMMANDS.getNext,
            ];
        default:
            return [OPERATION_COMMANDS.valueOf];
    }
};

export const getValidReferenceContentType = (
    valueToApply,
    valueToApplyCommand,
    valueToReferenceCommand,
    showReferenceValueSelector,
) => {
    if (valueToApply === null || showReferenceValueSelector === false) {
        return null;
    }

    const applyContentType = valueToApply?.contentType?.toUpperCase() ?? '';

    switch (applyContentType) {
        case CONTENT_TYPES.number:
            return valueToReferenceCommand === OPERATION_COMMANDS.getLength.command
                ? CONTENT_TYPES.list
                : CONTENT_TYPES.number;

        case CONTENT_TYPES.object:
            return valueToReferenceCommand === OPERATION_COMMANDS.getFirst.command ||
                valueToReferenceCommand === OPERATION_COMMANDS.getLast.command ||
                valueToReferenceCommand === OPERATION_COMMANDS.getNext.command
                ? CONTENT_TYPES.list
                : CONTENT_TYPES.object;

        case CONTENT_TYPES.list:
            return valueToApplyCommand === OPERATION_COMMANDS.update.command ||
                valueToApplyCommand === OPERATION_COMMANDS.remove.command
                ? CONTENT_TYPES.object
                : CONTENT_TYPES.list;
        default:
            return applyContentType;
    }
};

export const getVisibleReferenceRelativeCommands = (showReferenceRelativeCommandSelector) => {
    if (showReferenceRelativeCommandSelector === false) {
        return [];
    }

    return [OPERATION_COMMANDS.add, OPERATION_COMMANDS.subtract];
};

const OperationDetails = () => {
    const { mapElement, onSwitchScreen, notifyError, setMapElement, setConfigTitle, container } =
        useMapElement();

    const { selectedOperationIndex, setSelectedOperationIndex } = useOperator();

    const operation = mapElement.operations?.[selectedOperationIndex] ?? null;

    /**
     * State values
     */

    const [isLoadingValues, setIsLoadingValues] = useState(operation !== null);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    // Value to apply state
    // The value object that the operation operates on
    const [valueToApply, setValueToApply] = useState(null);
    // The command (string) that will be applied to the value
    const [valueToApplyCommand, setValueToApplyCommand] = useState(
        operation?.valueElementToApplyId?.command ?? '',
    );

    // Value to reference state
    // The reference value object that's used to operate
    const [valueToReference, setValueToReference] = useState(null);
    // The command (string) that will be used to retrieve a value
    const [valueToReferenceCommand, setValueToReferenceCommand] = useState(
        operation?.valueElementToReferenceId?.command ?? '',
    );

    // Relative date operation state
    // The value which will be applied to value to reference before storing the result in value to apply
    const [valueToReferenceRelative, setValueToReferenceRelative] = useState(null);
    // The command (string) that will be used to retrieve a value to apply to the valueToReference
    const [valueToReferenceRelativeCommand, setValueToReferenceRelativeCommand] = useState(
        operation?.valueElementToReferenceRelativeId?.command ?? '',
    );
    // The the unit which will be used to operate on the relative date
    const [valueToReferenceRelativeUnit, setValueToReferenceRelativeUnit] = useState(
        operation?.valueElementToReferenceRelativeId?.relativeUnit ?? '',
    );

    // Sorting state
    const [sortDirection, setSortDirection] = useState(
        operation?.valueElementToApplySortDirection ?? SORT_DIRECTION.ascending,
    );
    // A list of all properties of the valueToApply's type
    const [sortProperties, setSortProperties] = useState([]);
    // The property of the valueToApply to sort by
    const [selectedSortProperty, setSelectedSortProperty] = useState(null);

    // The id of the type of the valueToReference
    const [referenceTypeId, setReferenceTypeId] = useState('');

    /**
     * Functions
     */

    // Checks the validity of the form
    const checkValid = () => {
        if (valueToApply === null || valueToApplyCommand === '') {
            return false;
        }

        if (
            valueToApplyCommand === OPERATION_COMMANDS.sort.command &&
            selectedSortProperty === null
        ) {
            return false;
        }

        if (
            valueToApplyCommand === OPERATION_COMMANDS.setEqual.command ||
            valueToApplyCommand === OPERATION_COMMANDS.remove.command ||
            valueToApplyCommand === OPERATION_COMMANDS.update.command ||
            valueToApplyCommand === OPERATION_COMMANDS.add.command ||
            valueToApplyCommand === OPERATION_COMMANDS.subtract.command ||
            valueToApplyCommand === OPERATION_COMMANDS.divide.command ||
            valueToApplyCommand === OPERATION_COMMANDS.multiply.command ||
            valueToApplyCommand === OPERATION_COMMANDS.join.command ||
            valueToApplyCommand === OPERATION_COMMANDS.modulo.command ||
            valueToApplyCommand === OPERATION_COMMANDS.setRelativeDate.command
        ) {
            if (valueToReference === null || valueToReferenceCommand === '') {
                return false;
            }
        }

        if (valueToApplyCommand === OPERATION_COMMANDS.setRelativeDate.command) {
            if (
                valueToReferenceRelative === null ||
                valueToReferenceRelativeCommand === '' ||
                valueToReferenceRelativeUnit === ''
            ) {
                return false;
            }
        }

        return true;
    };

    const onSave = () => {
        setHasSubmitted(true);
        if (checkValid()) {
            const operationToSave = {
                macroElementToExecuteDeveloperName: null,
                macroElementToExecuteId: null,
                valueElementToApplyDeveloperName: valueToApply?.developerName ?? null,
                valueElementToApplyContentType: valueToApply?.contentType ?? null,
                valueElementToApplyId: {
                    command: valueToApplyCommand ?? null,
                    id: valueToApply?.id ?? null,
                    typeElementPropertyId: valueToApply?.typeElementPropertyId ?? null,
                },
                valueElementToApplyPropertyDeveloperName:
                    valueToApply?.typeElementPropertyDeveloperName ?? null,
                valueElementToApplySortById: selectedSortProperty?.id ?? null,
                valueElementToApplySortByDeveloperName: selectedSortProperty?.developerName ?? null,
                valueElementToApplySortDirection: sortDirection,
                valueElementToReferenceDeveloperName: valueToReference?.developerName ?? null,
                valueElementToReferenceContentType: valueToReference?.contentType ?? null,
                valueElementToReferenceId: null,
                valueElementToReferencePropertyDeveloperName:
                    valueToReference?.typeElementPropertyDeveloperName ?? null,
            };

            if (valueToReference) {
                operationToSave.valueElementToReferenceId = {
                    command: valueToReferenceCommand ?? null,
                    id: valueToReference?.id ?? null,
                    typeElementPropertyId: valueToReference?.typeElementPropertyId ?? null,
                };
            }

            if (valueToReferenceRelative) {
                operationToSave.valueElementToReferenceRelativeDeveloperName =
                    valueToReferenceRelative.developerName;
                operationToSave.valueElementToReferenceRelativeContentType =
                    valueToReferenceRelative.contentType;
                operationToSave.valueElementToReferenceRelativePropertyDeveloperName =
                    valueToReferenceRelative.typeElementPropertyDeveloperName;
                operationToSave.valueElementToReferenceRelativeId = {
                    command: valueToReferenceRelativeCommand ?? null,
                    id: valueToReferenceRelative?.id ?? null,
                    typeElementPropertyId: valueToReferenceRelative?.typeElementPropertyId ?? null,
                    relativeUnit: valueToReferenceRelativeUnit,
                };
            }

            const index = selectedOperationIndex ?? mapElement.operations?.length ?? 0;
            const operations = [...(mapElement.operations ?? [])];
            operations[index] = operationToSave;

            setMapElement({
                ...mapElement,
                operations,
            });

            onSwitchScreen(screens.operator);
        }
    };

    const onCancel = () => {
        onSwitchScreen(screens.operator);
        setSelectedOperationIndex(null);
    };

    const onChangeValueToApply = (newValue) => {
        setValueToApply(newValue);

        if (newValue === undefined || newValue.id !== valueToApply?.id) {
            // Reset other form controls
            setValueToApplyCommand('');
            setValueToReference(null);
            setValueToReferenceCommand('');
        }
    };

    const onChangeApplyCommand = ({ target: { value } }) => {
        setValueToApplyCommand(value);
        setValueToReference(null);
        setValueToReferenceCommand('');
    };

    const onChangeReferenceCommand = ({ target: { value } }) => {
        setValueToReferenceCommand(value);
        setValueToReference(null);
        resetReferenceRelative();
    };

    const onChangeReferenceRelativeCommand = ({ target: { value } }) => {
        setValueToReferenceRelativeCommand(value);
    };

    const onChangeValueToReference = (value) => {
        setValueToReference(value);
        resetReferenceRelative();
    };

    const resetReferenceRelative = () => {
        setValueToReferenceRelativeCommand('');
        setValueToReferenceRelative(null);
        setValueToReferenceRelativeUnit('');
    };

    const OnChangeValueToReferenceRelativeUnit = ({ target: { value } }) => {
        setValueToReferenceRelativeUnit(value);
    };

    const onChangeSortProperty = ({ target: { value } }) => {
        const property = getByID(value, sortProperties);
        setSelectedSortProperty(property);
    };

    const onChangeSortDirection = ({ target: { value } }) => setSortDirection(value);

    const getRelativeTimeUnits = (referenceContentType) => {
        if (referenceContentType === CONTENT_TYPES.date) {
            return RELATIVE_DATE_UNITS;
        }
        return RELATIVE_DATETIME_UNITS;
    };

    /**
     * Computed values
     */

    // Only show the apply command selector when a value has been chosen because the options are dependant on the chosen value
    const showApplyCommandSelector = !isNullOrEmpty(valueToApply);
    // Only show sort selectors if if sort command is selected
    const showSortSelectors =
        valueToApplyCommand === OPERATION_COMMANDS.sort.command && sortProperties.length > 0;
    // Only show reference command selector when applicable apply commands are selected
    const showReferenceCommandSelector = [
        OPERATION_COMMANDS.setEqual.command,
        OPERATION_COMMANDS.remove.command,
        OPERATION_COMMANDS.add.command,
        OPERATION_COMMANDS.divide.command,
        OPERATION_COMMANDS.multiply.command,
        OPERATION_COMMANDS.modulo.command,
        OPERATION_COMMANDS.setRelativeDate.command,
    ].includes(valueToApplyCommand);
    // Only show reference value selector when a reference command has been selected
    const showReferenceValueSelector =
        showReferenceCommandSelector && valueToReferenceCommand !== '';
    // Only show the relative settings when all other settings have been chosen
    const showRelativeValueSettings =
        !isNullOrEmpty(valueToReference) &&
        valueToApplyCommand === OPERATION_COMMANDS.setRelativeDate.command;
    // Calculate the applicable command options
    const visibleApplyCommands = getVisibleApplyCommands(valueToApply);
    // Calculate the applicable reference commands
    const visibleReferenceCommands = getVisibleReferenceCommands(
        valueToApply,
        showReferenceCommandSelector,
    );
    // Calculate the applicable relative reference commands
    const visibleReferenceRelativeCommands =
        getVisibleReferenceRelativeCommands(showRelativeValueSettings);

    // The content type of the reference value - used to filter the options in the reference value selector
    const referenceContentType = getValidReferenceContentType(
        valueToApply,
        valueToApplyCommand,
        valueToReferenceCommand,
        showReferenceValueSelector,
    );
    // Get the time units which will be used for relative operations - differs between Datetime/Date
    const relativeTimeUnits = getRelativeTimeUnits(referenceContentType);

    /**
     * Effects
     */

    // Get the reference value's type id to pass into the value selector's filter
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // If the command is "Length Of" (GET_LENGTH) then any list is acceptable,
        // so we can clear the referenceTypeId.
        if (
            referenceContentType === CONTENT_TYPES.list &&
            valueToReferenceCommand === OPERATION_COMMANDS.getLength.command
        ) {
            setReferenceTypeId('');
            return;
        }

        (async () => {
            if (
                referenceContentType === CONTENT_TYPES.list ||
                referenceContentType === CONTENT_TYPES.object
            ) {
                try {
                    const valueResponse = await getValue(valueToApply.id);

                    setReferenceTypeId(valueResponse.typeElementId);
                } catch (error) {
                    notifyError(error);
                }
            } else {
                setReferenceTypeId('');
            }
        })();
    }, [valueToApplyCommand, valueToReferenceCommand, referenceContentType]);

    // Get the properties of the selected value for sorting purposes
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        (async () => {
            if (valueToApply !== null && valueToApplyCommand === OPERATION_COMMANDS.sort.command) {
                try {
                    const valueResponse = await getValue(valueToApply.id);

                    const type = await getType(valueResponse.typeElementId);

                    if (operation?.valueElementToApplySortById) {
                        const property = getByID(
                            operation.valueElementToApplySortById,
                            type.properties ?? [],
                        );
                        setSelectedSortProperty(property);
                    }

                    setSortProperties(type.properties);

                    if (operation?.valueElementToApplySortDirection) {
                        setSortDirection(operation.valueElementToApplySortDirection);
                    }
                } catch (error) {
                    notifyError(error);
                }
            } else {
                // Reset sort form controls
                setSortProperties([]);
                setSortDirection(SORT_DIRECTION.ascending);
                setSelectedSortProperty(null);
            }
        })();
    }, [valueToApply, valueToApplyCommand]);

    useEffect(() => {
        setConfigTitle(`Operation for: ${mapElement.developerName}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    // Get the full value reference objects (valueToApply and valueToReference)
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        (async () => {
            if (isLoadingValues) {
                const valueToApplyId = operation?.valueElementToApplyId?.id ?? null;
                const valueToReferenceId = operation?.valueElementToReferenceId?.id ?? null;
                const valueToReferenceRelativeId =
                    operation?.valueElementToReferenceRelativeId?.id ?? null;

                if (!(valueToApplyId || valueToReferenceId || valueToReferenceRelativeId)) {
                    setIsLoadingValues(false);
                    return;
                }

                const ids = [];
                if (valueToApplyId) {
                    ids.push(valueToApplyId);
                }
                if (valueToReferenceId) {
                    ids.push(valueToReferenceId);
                }
                if (valueToReferenceRelativeId) {
                    ids.push(valueToReferenceRelativeId);
                }

                try {
                    const responseValueReferences = await getValueList({
                        id: ids,
                    });

                    if (valueToApplyId) {
                        const propertyId =
                            operation?.valueElementToApplyId?.typeElementPropertyId ?? null;
                        const reference = responseValueReferences.find(
                            (vr) =>
                                vr.id === valueToApplyId && vr.typeElementPropertyId === propertyId,
                        );

                        setValueToApply(reference);
                    }
                    if (valueToReferenceId) {
                        const propertyId =
                            operation?.valueElementToReferenceId?.typeElementPropertyId ?? null;
                        const reference = responseValueReferences.find(
                            (vr) =>
                                vr.id === valueToReferenceId &&
                                vr.typeElementPropertyId === propertyId,
                        );

                        setValueToReference(reference);
                    }
                    if (valueToReferenceRelativeId) {
                        const propertyId =
                            operation?.valueElementToReferenceRelativeId?.typeElementPropertyId ??
                            null;
                        const reference = responseValueReferences.find(
                            (vr) =>
                                vr.id === valueToReferenceRelativeId &&
                                vr.typeElementPropertyId === propertyId,
                        );

                        setValueToReferenceRelative(reference);
                    }
                    setIsLoadingValues(false);
                } catch (error) {
                    notifyError(error);
                }
            }
        })();
    }, []);

    const renderBody = () => {
        if (isLoadingValues) {
            return <Loader />;
        }

        return (
            <>
                <FormGroup
                    label="Which Value do you want to change?"
                    isRequired
                    validationMessage="Please choose a Value"
                    isValid={valueToApply !== null}
                    showValidation={hasSubmitted}
                >
                    <ValueSelectorModal
                        value={valueToApply}
                        onChangeAsValueReference={onChangeValueToApply}
                        includeSystemValues={false}
                        container={container}
                    />
                </FormGroup>

                {showApplyCommandSelector && (
                    <FormGroup
                        label="How do you want to change the Value?"
                        isRequired
                        validationMessage="Please choose an option"
                        isValid={valueToApplyCommand !== ''}
                        showValidation={hasSubmitted}
                        htmlFor="valueToApplyCommand"
                    >
                        <select
                            className="form-control form-select form-control-width"
                            value={valueToApplyCommand}
                            onChange={onChangeApplyCommand}
                            data-testid="select-command"
                            id="valueToApplyCommand"
                        >
                            {valueToApplyCommand === '' && (
                                <option key="empty" value="">
                                    Please select
                                </option>
                            )}
                            {visibleApplyCommands.map((commandType) => (
                                <option key={commandType.command} value={commandType.command}>
                                    {commandType.display}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                )}

                {showSortSelectors && (
                    <>
                        <FormGroup
                            label="The property to use for this sort"
                            isRequired
                            validationMessage="Please choose a property"
                            isValid={selectedSortProperty !== null}
                            showValidation={hasSubmitted}
                        >
                            <select
                                className="form-control form-select form-control-width"
                                value={selectedSortProperty?.id ?? ''}
                                onChange={onChangeSortProperty}
                            >
                                {selectedSortProperty === null && (
                                    <option key="empty" value="">
                                        Please select
                                    </option>
                                )}
                                {sortProperties.map((property) => (
                                    <option key={property.id} value={property.id}>
                                        {property.developerName}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                        <FormGroup label="Sort direction">
                            <select
                                className="form-control form-select form-control-width"
                                value={sortDirection}
                                onChange={onChangeSortDirection}
                            >
                                <option value={SORT_DIRECTION.ascending}>Ascending</option>
                                <option value={SORT_DIRECTION.descending}>Descending</option>
                            </select>
                        </FormGroup>
                    </>
                )}

                <>
                    {showReferenceCommandSelector && (
                        <FormGroup
                            label="How do you want to retrieve the reference Value?"
                            isRequired
                            validationMessage="Please choose an option"
                            isValid={valueToReferenceCommand !== ''}
                            showValidation={hasSubmitted}
                            htmlFor="valueToReferenceSelect"
                        >
                            <select
                                id="valueToReferenceSelect"
                                className="form-control form-select form-control-width"
                                value={valueToReferenceCommand}
                                onChange={onChangeReferenceCommand}
                            >
                                {valueToReferenceCommand === '' && (
                                    <option key="empty" value="">
                                        Please select
                                    </option>
                                )}
                                {visibleReferenceCommands.map((commandType) => (
                                    <option key={commandType.command} value={commandType.command}>
                                        {commandType.display}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                    )}

                    {showReferenceValueSelector && (
                        <FormGroup
                            label="Which Value do you want to reference?"
                            isRequired
                            validationMessage="Please choose a Value"
                            isValid={valueToReference !== null}
                            showValidation={hasSubmitted}
                        >
                            <ValueSelectorModal
                                value={valueToReference}
                                onChangeAsValueReference={onChangeValueToReference}
                                includeSystemValues={true}
                                container={container}
                                contentType={referenceContentType}
                                typeElementId={referenceTypeId}
                            />
                        </FormGroup>
                    )}
                    {showRelativeValueSettings && (
                        <>
                            <FormGroup
                                label="What relative operation do you want to perform?"
                                isRequired
                                validationMessage="Please choose an option"
                                isValid={valueToReferenceRelativeCommand !== ''}
                                showValidation={hasSubmitted}
                                htmlFor="valueToReferenceRelativeCommand"
                            >
                                <select
                                    id="valueToReferenceRelativeCommand"
                                    className="form-control form-select form-control-width"
                                    value={valueToReferenceRelativeCommand}
                                    onChange={onChangeReferenceRelativeCommand}
                                >
                                    {valueToReferenceRelativeCommand === '' && (
                                        <option key="empty" value="">
                                            Please select
                                        </option>
                                    )}
                                    {visibleReferenceRelativeCommands.map((commandType) => (
                                        <option
                                            key={commandType.command}
                                            value={commandType.command}
                                        >
                                            {commandType.display}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                            <FormGroup
                                label="Which Value do you want to use for the relative unit?"
                                isRequired
                                validationMessage="Please choose a Value"
                                isValid={valueToReferenceRelative !== null}
                                showValidation={hasSubmitted}
                            >
                                <ValueSelectorModal
                                    value={valueToReferenceRelative}
                                    onChangeAsValueReference={setValueToReferenceRelative}
                                    includeSystemValues={false}
                                    container={container}
                                    contentType={CONTENT_TYPES.number}
                                />
                            </FormGroup>
                            <FormGroup
                                label="What unit is this value?"
                                isRequired
                                validationMessage="Please choose an option"
                                isValid={valueToReferenceRelativeUnit !== ''}
                                showValidation={hasSubmitted}
                                htmlFor="valueToReferenceRelativeUnit"
                            >
                                <select
                                    id="valueToReferenceRelativeUnit"
                                    className="form-control form-select form-control-width"
                                    value={valueToReferenceRelativeUnit}
                                    onChange={OnChangeValueToReferenceRelativeUnit}
                                >
                                    {valueToReferenceRelativeUnit === '' && (
                                        <option key="empty" value="">
                                            Please select
                                        </option>
                                    )}
                                    {relativeTimeUnits.map((unit) => (
                                        <option key={unit} value={unit}>
                                            {unit}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </>
                    )}
                </>
            </>
        );
    };

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>
                <ButtonDefault className="flex-child-right" onClick={onCancel}>
                    Cancel
                </ButtonDefault>
                <ButtonPrimary className="margin-left" onClick={onSave}>
                    {selectedOperationIndex === null ? 'Add' : 'Update'}
                </ButtonPrimary>
            </ModalFooter>
        </>
    );
};

export default OperationDetails;

import '../../../../css/page-breadcrumbs.less';

interface Breadcrumb {
    label: string;
    id: string;
    parent: string | null;
}

interface PageBreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
    navigateTo: (id: string) => void;
}

/**
 * Breadcrumbs UI component for navigation between pages
 *
 * @param breadcrumbs
 * @param navigateTo onClick function for navigation to a page specified by the breadcrumb
 */
const PageBreadcrumbs = ({ breadcrumbs, navigateTo }: PageBreadcrumbsProps) => {
    const renderBreadcrumb = ({ label, id }: Breadcrumb, index: number) => {
        const isFirst = index === 0;
        const isLast = index === breadcrumbs.length - 1;

        const crumb = isLast ? (
            <span>{label}</span>
        ) : (
            <button className="breadcrumbs-link" onClick={() => navigateTo(id)} type="button">
                {label}
            </button>
        );

        return (
            <li key={`${label}-${id}`} className="breadcrumbs-item">
                {!isFirst && <span>&nbsp;&gt;&nbsp;</span>}
                {crumb}
            </li>
        );
    };

    return (
        <nav>
            <ul className="breadcrumbs-list">
                {breadcrumbs.map((item, index) => renderBreadcrumb(item, index))}
            </ul>
        </nav>
    );
};

export default PageBreadcrumbs;

import classnames from 'classnames';
import { type ElementRef, useEffect, useRef, useState } from 'react';
import '../../../../css/user.less';
import TenantName from '../generic/TenantName';
import type { UserTenantResponseAPI } from '../../types';
import { getDateDisplay } from '../../utils/date';
import CopyableText from '../generic/CopyableText';
import translations from '../../translations';
import { ExIcon, IconVariant } from '@boomi/exosphere';
import { useDocumentClick } from '../../hooks';
import { formatTenantName } from '../../utils/tenant';
import { isNullOrEmpty } from '../../utils/guard';

interface TenantDropdown {
    tenant: UserTenantResponseAPI;
    openTenantSelector: () => void;
}

const TenantDropdown = ({
    tenant: { id, isSso, developerName, organization, expiresAt },
    openTenantSelector,
}: TenantDropdown) => {
    const [isOpen, setIsOpen] = useState(false);

    const changeTenantButtonRef = useRef<ElementRef<'button'>>(null);
    const dropDownRef = useRef<ElementRef<'div'>>(null);

    useDocumentClick(dropDownRef, () => setIsOpen(false));

    useEffect(() => {
        if (!isOpen || isSso) {
            return;
        }

        changeTenantButtonRef.current?.focus();
    }, [isOpen, isSso]);

    const handleChangeTenantClick = () => {
        setIsOpen(false);
        openTenantSelector();
    };

    const classes = classnames('dropdown-box', {
        'is-open': isOpen,
    });

    return (
        <div ref={dropDownRef} className={classes}>
            <button
                className="dropdown-btn"
                onClick={() => setIsOpen(!isOpen)}
                data-testid="user-dropdown-button"
                type="button"
            >
                <div className="header-dropdown">
                    <span className="user-dropdown-text">
                        <TenantName name={developerName} />
                    </span>
                    <ExIcon
                        className="icon-small dropdown-btn-icon"
                        icon="Down caret"
                        label="Expand"
                        variant={IconVariant.INVERSE}
                    />
                    {isSso && <span className="label label-info">SSO</span>}
                </div>
            </button>
            <div className="dropdown-list-wrapper">
                <div className="user-dropdown-content" data-testid="user-dropdown-content">
                    <CopyableText copyableText={formatTenantName(developerName)} />
                    {organization && (
                        <p className="list-group-item-text margin-top-small">{organization.name}</p>
                    )}
                    <div className="user-tenant-id quiet">
                        <CopyableText copyableText={id} hasCopyButton={true} />
                    </div>
                    {!isNullOrEmpty(expiresAt) && (
                        <p className="list-group-item-text quiet margin-top-small">
                            {`Tenant expires: ${getDateDisplay(expiresAt)}`}
                        </p>
                    )}
                    {!isSso && (
                        <>
                            <hr />
                            <ul className="list-unstyled">
                                <li>
                                    <button
                                        className="btn btn-info"
                                        ref={changeTenantButtonRef}
                                        onClick={handleChangeTenantClick}
                                        type="button"
                                    >
                                        {translations.HEADER_change_tenant}
                                    </button>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TenantDropdown;

import classNames from 'classnames';
import errorIcon from '../../../../img/Icons_Feedback_Error.svg';
import infoIcon from '../../../../img/Icons_Feedback_Info.svg';
import successIcon from '../../../../img/Icons_Feedback_Success.svg';
import warningIcon from '../../../../img/Icons_Feedback_Warning.svg';
import translations from '../../translations';
import type {
    Notification as NotificationObject,
    NotificationType,
} from '../../types/notification';

const iconMap: Record<NotificationType, string> = {
    success: successIcon,
    warning: warningIcon,
    error: errorIcon,
    info: infoIcon,
};

export type NotificationProps = NotificationObject &
    (
        | {
              isPersistent: true;
              onRemove: (id: string) => void;
          }
        | { isPersistent: false }
    );

const Notification = (props: NotificationProps) => {
    const { id, type, message, isPersistent } = props;

    const isStringMessage = typeof message === 'string';

    // Special case, this message could be long so lets add some formatting to make it easier to read
    const tenantExportMessage =
        isStringMessage && message.includes('No flow snapshot could be found for Flow')
            ? message
                  .split('No flow')
                  .slice(1)
                  .map((x) => <p key={x}>{`No flow ${x}`}</p>)
            : null;

    const classes = classNames(`toast-notification ${type}`, {
        'toast-wide': isStringMessage && message.length > 250,
    });

    return (
        <div className={classes}>
            <img src={iconMap[type]} width={24} height={24} alt="" />
            <div className="toast-notification-message">{tenantExportMessage ?? message}</div>
            {isPersistent ? (
                <button
                    className="toast-notification-dismiss"
                    onClick={() => props.onRemove(id)}
                    aria-label={translations.NOTIFICATION_dismiss_button_label}
                    type="button"
                >
                    ×
                </button>
            ) : null}
        </div>
    );
};

export default Notification;

import { ExIcon } from '@boomi/exosphere';
import type { TimeFilters } from '../../types/dashboard';
import translations from '../../translations';

export interface DateFilterItem {
    label: string;
    value: TimeFilters;
    tooltip: string;
}

interface Props {
    selectedDate: TimeFilters;
    setSelectedDate: (date: TimeFilters) => void;
    dateFilterItems: DateFilterItem[];
    setParentHovering?: ((isHovering: boolean) => void) | undefined;
}

const InsightsDateFilter = ({
    selectedDate,
    setSelectedDate,
    dateFilterItems,
    setParentHovering,
}: Props) => (
    <div className="insight-date-filter-wrapper">
        {dateFilterItems.map((df, index) => (
            <button
                key={`${df.value}-${index}`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setSelectedDate(df.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.stopPropagation();
                        setSelectedDate(df.value);
                    }
                }}
                onMouseEnter={() => (setParentHovering ? setParentHovering(false) : null)}
                onMouseLeave={() => (setParentHovering ? setParentHovering(true) : null)}
                className={`insights-date-filter-btn ${
                    selectedDate === df.value ? 'insights-date-filter-btn-active' : ''
                }`}
                title={df.tooltip}
                type="button"
            >
                {df.label}
            </button>
        ))}
        <button
            className="insights-date-filter-icon"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
            }}
            onMouseEnter={() => (setParentHovering ? setParentHovering(false) : null)}
            onMouseLeave={() => (setParentHovering ? setParentHovering(true) : null)}
            title={translations.DASHBOARD_filter_calendar_tooltip}
            tabIndex={-1}
            type="button"
        >
            <ExIcon icon="Calendar" label={translations.DASHBOARD_filter_calendar_tooltip} />
        </button>
    </div>
);

export default InsightsDateFilter;

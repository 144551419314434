import { clone } from 'ramda';
import { addPositions, subtractPositions } from '../../../ts/components/graph/utils';
import type { XYCoord, Point } from '../../types';

/** side of a map element */
export enum OffsetSide {
    Top = 'TOP',
    Bottom = 'BOTTOM',
    Left = 'LEFT',
    Right = 'RIGHT',
    None = 'NONE',
}

/** returns the opposite side to the given side */
export const reverseSide = (side: OffsetSide) => {
    switch (side) {
        case OffsetSide.Top:
            return OffsetSide.Bottom;
        case OffsetSide.Bottom:
            return OffsetSide.Top;
        case OffsetSide.Left:
            return OffsetSide.Right;
        case OffsetSide.Right:
            return OffsetSide.Left;
        default:
            return OffsetSide.None;
    }
};

interface Offset extends XYCoord {
    rotation: number;
}

// Rotation is based off the arrowhead polygon that points up /\
// And so rotation 0 means it goes up into the bottom offset by default
/** returns the outcome offset on the given side for an element of the given width and height */
export const getOffset = (width: number, height: number, side: OffsetSide): Offset => {
    switch (side) {
        case OffsetSide.Top:
            return { x: width / 2, y: 0, rotation: 180 };
        case OffsetSide.Bottom:
            return { x: width / 2, y: height, rotation: 0 };
        case OffsetSide.Left:
            return { x: 0, y: height / 2, rotation: 90 };
        case OffsetSide.Right:
            return { x: width, y: height / 2, rotation: -90 };
        default:
            return { x: 0, y: 0, rotation: 0 };
    }
};

/** Draw any straight lines where the control point is
 * directly in line with a side of the map element. */
export const drawControlPointStraightLines = (
    controlPoint: XYCoord,
    offsetMapElement: XYCoord,
    width: number,
    height: number,
) => {
    // 0╌╌1,2
    // the inside point (1) is on top of the control point (2) as there is no bend required
    // the outside point (0) is either the start or end of the outcome
    const outsidePoint: Partial<XYCoord> = {};
    let insidePoint: Partial<XYCoord> = {};
    let side: OffsetSide | null = null;
    // straight line between offsetMapElement and control point
    if (controlPoint.x >= offsetMapElement.x && controlPoint.x <= offsetMapElement.x + width) {
        // outcome goes straight vertically to/from a control point
        insidePoint = controlPoint;
        outsidePoint.x = controlPoint.x;
        if (controlPoint.y >= offsetMapElement.y) {
            outsidePoint.y = offsetMapElement.y + height;
            side = OffsetSide.Bottom;
        } else {
            outsidePoint.y = offsetMapElement.y;
            side = OffsetSide.Top;
        }
    } else if (
        controlPoint.y >= offsetMapElement.y &&
        controlPoint.y <= offsetMapElement.y + height
    ) {
        // outcome goes straight horizontally to/from a control point
        insidePoint = controlPoint;
        if (controlPoint.x >= offsetMapElement.x) {
            outsidePoint.x = offsetMapElement.x + width;
            side = OffsetSide.Right;
        } else {
            outsidePoint.x = offsetMapElement.x;
            side = OffsetSide.Left;
        }
        outsidePoint.y = controlPoint.y;
    }
    return { outsidePoint, insidePoint, side };
};

/** Some points may be empty after `drawControlPointStraightLines`.
 * Fill in those empty points with an elbow bend */
export const drawRemainingElbowBends = (
    controlPoint: XYCoord,
    offsetFromMapElement: XYCoord,
    offsetNextMapElement: XYCoord,
    offsetMapElement: XYCoord,
    width: number,
    height: number,
    nextHeight: number,
): { outsidePoint: XYCoord; insidePoint: XYCoord; side: OffsetSide } => {
    let side = null;
    // elbow bend to/from the control point
    if (controlPoint.y < offsetFromMapElement.y && controlPoint.y < offsetNextMapElement.y) {
        // In╌╌2,3
        //  ┊   │
        // Out  4
        // the control point is above both map elements
        const outsidePoint = addPositions(
            getOffset(width, height, OffsetSide.Top),
            offsetMapElement,
        );
        const insidePoint = {
            x: outsidePoint.x,
            y: controlPoint.y,
        };
        return { outsidePoint, insidePoint, side: OffsetSide.Top };
    }
    if (
        controlPoint.y > offsetFromMapElement.y + height &&
        controlPoint.y > offsetNextMapElement.y + nextHeight
    ) {
        // Out  4
        //  ┊   │
        // In╌╌2,3
        // the control point is below both map elements
        const outsidePoint = addPositions(
            getOffset(width, height, OffsetSide.Bottom),
            offsetMapElement,
        );
        const insidePoint = {
            x: outsidePoint.x,
            y: controlPoint.y,
        };
        return { outsidePoint, insidePoint, side: OffsetSide.Bottom };
    }
    // Out╌╌In
    //      ┊
    //     2,3──4
    // control point is to the left or right
    side = controlPoint.x < offsetMapElement.x ? OffsetSide.Left : OffsetSide.Right;
    const outsidePoint = addPositions(getOffset(width, height, side), offsetMapElement);
    const insidePoint = { x: controlPoint.x, y: outsidePoint.y };
    return { outsidePoint, insidePoint, side };
};

/**
 * If there is space between the elements: draw the shortest Z path between two opposite sides,
 * otherwise: draw a vertical or horizontal loop between the elements
 */
export const drawNoControlPointPath = (
    offsetFromMapElement: XYCoord,
    fromWidth: number,
    fromHeight: number,
    offsetNextMapElement: XYCoord,
    nextWidth: number,
    nextHeight: number,
) => {
    const fromLeft = offsetFromMapElement.x;
    const fromRight = offsetFromMapElement.x + fromWidth;
    const fromTop = offsetFromMapElement.y;
    const fromBottom = offsetFromMapElement.y + fromHeight;

    const nextLeft = offsetNextMapElement.x;
    const nextRight = offsetNextMapElement.x + nextWidth;
    const nextTop = offsetNextMapElement.y;
    const nextBottom = offsetNextMapElement.y + nextHeight;

    if (
        // the left/right sides are touching or the elements are overlapping
        // [next][from][next]
        (nextRight >= fromLeft &&
            nextLeft <= fromRight &&
            nextBottom > fromTop &&
            nextTop < fromBottom) ||
        // the corners are touching
        // [next]      [next]
        //       [from]
        // [next]      [next]
        ((nextRight === fromLeft || nextLeft === fromRight) &&
            (nextBottom === fromTop || nextTop === fromBottom))
    ) {
        // draw a top loop like this:
        // 1,2╌╌╌3
        //  ┊    ┊
        //  0    ┊
        //       4
        return drawAdjacentLoop(
            fromWidth,
            fromHeight,
            offsetFromMapElement,
            fromTop,
            nextTop,
            offsetNextMapElement,
            nextWidth,
            nextHeight,
            OffsetSide.Top,
        );
    }

    if (
        // the top/bottom sides are touching
        // [next]
        // [from]
        // [next]
        nextRight > fromLeft &&
        nextLeft < fromRight &&
        (nextBottom === fromTop || nextTop === fromBottom)
    ) {
        // draw a left loop like this:
        // 1,2╌╌0
        // ┊
        // 3╌╌4
        return drawAdjacentLoop(
            fromWidth,
            fromHeight,
            offsetFromMapElement,
            fromLeft,
            nextLeft,
            offsetNextMapElement,
            nextWidth,
            nextHeight,
            OffsetSide.Left,
        );
    }

    // draw the shortest valid path between two opposite sides
    const bestOffsets = [
        OffsetSide.Top,
        OffsetSide.Bottom,
        OffsetSide.Left,
        OffsetSide.Right,
    ].reduce(
        (
            currentBestOffsets: {
                distanceToOffset: number | null;
                startOffset: Offset;
                endOffset: Offset;
            },
            side: OffsetSide,
        ) => {
            const startOffset = addPositions(
                getOffset(fromWidth, fromHeight, side),
                offsetFromMapElement,
            );
            const endOffset = addPositions(
                getOffset(nextWidth, nextHeight, reverseSide(side)),
                offsetNextMapElement,
            );

            if (
                // ignore left side if endOffset is to the right of startOffset
                (side === OffsetSide.Left && endOffset.x >= startOffset.x) ||
                // ignore right side if endOffset is to the left of startOffset
                (side === OffsetSide.Right && endOffset.x <= startOffset.x)
            ) {
                return currentBestOffsets;
            }

            const difference = subtractPositions(endOffset, startOffset);
            // Pythagoras' theorem
            // |\
            // b c
            // |_a_\
            // a² + b² = c²
            // where a and b form a right angle and c is the hypotenuse of the triangle
            // c = √(a² + b²)
            const distanceToOffset = Math.sqrt(difference.x ** 2 + difference.y ** 2);
            if (
                currentBestOffsets.distanceToOffset === null ||
                currentBestOffsets.distanceToOffset > distanceToOffset
            ) {
                return { distanceToOffset, startOffset, endOffset };
            }
            return currentBestOffsets;
        },
        {
            distanceToOffset: null,
            startOffset: getOffset(0, 0, OffsetSide.None),
            endOffset: getOffset(0, 0, OffsetSide.None),
        },
    );

    const { startOffset, endOffset } = bestOffsets;

    // 0╌╌1
    //    ┊
    //    2╌╌3
    const midpoint: XYCoord = {
        x: startOffset.x + (endOffset.x - startOffset.x) / 2,
        y: startOffset.y + (endOffset.y - startOffset.y) / 2,
    };

    // draw elbow bend through the midpoint, finishing in line with the last point
    const isHorizontal =
        startOffset.rotation === getOffset(0, 0, OffsetSide.Left).rotation ||
        startOffset.rotation === getOffset(0, 0, OffsetSide.Right).rotation;
    const point1 = isHorizontal
        ? { x: midpoint.x, y: startOffset.y }
        : { x: startOffset.x, y: midpoint.y };
    const point2 = isHorizontal
        ? { x: midpoint.x, y: endOffset.y }
        : { x: endOffset.x, y: midpoint.y };

    return { points: [startOffset, point1, point2, endOffset], startOffset, endOffset };
};

/** Draw a C shape path towards the side of control point `cSide` */
export const drawCShapePath = (
    points: XYCoord[],
    offsetFromMapElement: XYCoord,
    fromWidth: number,
    fromHeight: number,
    offsetNextMapElement: XYCoord,
    nextWidth: number,
    nextHeight: number,
    cSide: OffsetSide,
) => {
    const newPoints = points;
    // 1╌╌2╌╌3
    // ┊     ┊
    // x     4
    // outcome starts at the middle side of the C shape
    newPoints[0] = addPositions(getOffset(fromWidth, fromHeight, cSide), offsetFromMapElement);

    // 1╌╌2╌╌3
    // ┊     ┊
    // 0     x
    // outcome ends at the middle side of the C shape
    newPoints[4] = addPositions(getOffset(nextWidth, nextHeight, cSide), offsetNextMapElement);

    // set axis that the C path follows in the middle and start
    const cMiddleAxis = cSide === OffsetSide.Top || cSide === OffsetSide.Bottom ? 'x' : 'y';
    const cStartAxis = cMiddleAxis === 'x' ? 'y' : 'x';

    // x──2╌╌3
    // │     ┊
    // 0     4
    // outcome goes from point 0 to in line with the control point
    newPoints[1][cStartAxis] = newPoints[2][cStartAxis];
    newPoints[1][cMiddleAxis] = newPoints[0][cMiddleAxis];

    // 1──2──x
    // │     │
    // 0     4
    // outcome goes from control point to in line with last point
    newPoints[3][cStartAxis] = newPoints[2][cStartAxis];
    newPoints[3][cMiddleAxis] = newPoints[4][cMiddleAxis];
    return { cSide, points: newPoints };
};

/** Draw a Z shape path between the map elements and through the control point. */
export const drawZShapePath = (
    points: XYCoord[],
    offsetFromMapElement: Offset,
    fromWidth: number,
    fromHeight: number,
    offsetNextMapElement: Offset,
    nextWidth: number,
    nextHeight: number,
) => {
    const newPoints = points;
    // because map elements are shorter than they are wide,
    // a Z shape will always be shorter than a └┐ shape

    // the side that the Z shape starts from
    const startSide = newPoints[2].x < offsetFromMapElement.x ? OffsetSide.Left : OffsetSide.Right;

    // x╌╌1
    //    2
    //    3╌╌4
    newPoints[0] = addPositions(getOffset(fromWidth, fromHeight, startSide), offsetFromMapElement);

    // 0╌╌1
    //    2
    //    3╌╌x
    const endSide = reverseSide(startSide);
    newPoints[4] = addPositions(getOffset(nextWidth, nextHeight, endSide), offsetNextMapElement);

    // 0──x
    //    2
    //    3╌╌4
    newPoints[1].x = newPoints[2].x;
    newPoints[1].y = newPoints[0].y;

    // 0──1
    //    2
    //    x──4
    newPoints[3].x = newPoints[2].x;
    newPoints[3].y = newPoints[4].y;
    return { startSide, endSide, points: newPoints };
};

/** Draw a loop back to the from element */
export const drawNoControlPointLoop = (
    offsetFromMapElement: Point,
    fromWidth: number,
    fromHeight: number,
) => {
    const loopWidth = 40;
    const loopHeight = 30;
    const side = OffsetSide.Top;
    const mockControlPoint = addPositions(
        getOffset(fromWidth, fromHeight, side),
        offsetFromMapElement,
    );
    mockControlPoint.x -= loopWidth;
    mockControlPoint.y -= loopHeight;
    return {
        ...drawControlPointLoop(
            mockControlPoint,
            offsetFromMapElement,
            fromWidth,
            fromHeight,
            side,
        ),
        side,
    };
};

/** Draw a loop back to the from element with a control point */
export const drawControlPointLoop = (
    controlPoint: XYCoord,
    offsetFromMapElement: Point,
    fromWidth: number,
    fromHeight: number,
    cSide: OffsetSide,
) => {
    const side = addPositions(getOffset(fromWidth, fromHeight, cSide), offsetFromMapElement);

    // vertical example:
    // 1───2
    // │   │
    // 0   3
    const points = [clone(side), controlPoint, clone(side), clone(side)];

    // flip x and y for loops coming out the left/right sides
    const isVertical = [OffsetSide.Top, OffsetSide.Bottom].includes(cSide);
    const x = isVertical ? 'x' : 'y';
    const y = isVertical ? 'y' : 'x';

    // 1───x
    // ┊   ┊
    // 0   3
    // same distance from the center as the control point, parallel with element side
    let distanceFromCenter = side[x] - controlPoint[x];
    if (distanceFromCenter === 0) {
        // minimum absolute distance of 5 to avoid loop going back on itself
        distanceFromCenter = 5;
        points[1][x] = side[x] - distanceFromCenter;
    }
    points[2][x] = side[x] + distanceFromCenter;
    points[2][y] = controlPoint[y];

    // 1───2
    // │   │
    // x   x
    // make start and end points line up with points 1 and 2
    points[0][x] = controlPoint[x];
    points[3][x] = points[2][x];

    return { points };
};

/** Draws a top or left loop between two elements with no space in between */
function drawAdjacentLoop(
    fromWidth: number,
    fromHeight: number,
    offsetFromMapElement: XYCoord,
    fromSide: number,
    nextSide: number,
    offsetNextMapElement: XYCoord,
    nextWidth: number,
    nextHeight: number,
    side: OffsetSide, // top or left
) {
    // left side example:
    // 1,2╌╌0
    // ┊
    // 3╌╌4
    // start at the middle of the from element side
    const point0 = addPositions(getOffset(fromWidth, fromHeight, side), offsetFromMapElement);
    // the next two points are the same: directly above or left of point 0
    // we allow a 30 unit space from the minimum side for the loop
    // x────0
    // ┊
    // 3╌╌4
    const loopAxisMin = Math.min(fromSide, nextSide) - 30;
    const axis = side === OffsetSide.Left ? 'x' : 'y';
    const point1 = { ...point0, [axis]: loopAxisMin };
    const point2 = point1;
    // fill the remaining points 3 and 4
    // 1,2──0
    // │
    // x──x
    const { outsidePoint: point4, insidePoint: point3 } = drawRemainingElbowBends(
        point2,
        offsetFromMapElement,
        offsetNextMapElement,
        offsetNextMapElement,
        nextWidth,
        nextHeight,
        nextHeight,
    );
    return {
        points: [point0, point1, point2, point3, point4],
        startOffset: getOffset(0, 0, side),
        endOffset: getOffset(0, 0, side),
    };
}

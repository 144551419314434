import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const APIEndpoints = (state = { endpoints: {}, isLoading: false }, { type, payload }) => {
    switch (type) {
        case actionTypes.FETCH_API_ENDPOINTS:
            return { ...state, isLoading: true };

        case actionTypes.SET_API_ENDPOINTS:
            return { ...state, endpoints: payload, isLoading: false };

        case actionTypes.SET_SELECTED_FLOW:
            return { ...state, selectedFlow: payload };

        default:
            return state;
    }
};

export default APIEndpoints;

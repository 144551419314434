import { CheckCircle, Question, Trash, WarningCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import { formatDistanceToNowStrict } from 'date-fns';
import { useEffect, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import '../../../../../css/local-runtimes.less';
import {
    deleteLocalRuntime,
    editLocalRuntime,
    getLocalRuntimes,
} from '../../../../js/actions/reduxActions/localRuntimes';
import { FLOW_ORG_PAGES } from '../../../constants/organization';
import translations from '../../../translations';
import { type RuntimeListResponse, RuntimeStatus } from '../../../types/runtime';
import { stringContains, stringReplace } from '../../../utils/string';
import SearchInput from '../../generic/SearchInput';
import Table, { type TableColumnList } from '../../generic/Table';
import ConfirmModal from '../../generic/modal/ConfirmModal';

type LocalRuntimeListProps = {
    navigateTo: (page: string) => void;
    container: HTMLElement | null | undefined;
} & ConnectedProps<typeof connector>;

const mapStateToProps = (state: {
    localRuntimes: {
        runtimeList: RuntimeListResponse[];
        isLoading: boolean;
        isDeleting: boolean;
    };
}) => ({
    localRuntimes: state.localRuntimes.runtimeList,
    isLoading: state.localRuntimes.isLoading,
    isDeleting: state.localRuntimes.isDeleting,
});

const mapDispatchToProps = {
    getLocalRuntimes,
    editLocalRuntime,
    deleteLocalRuntime,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const LocalRuntimeList = ({
    localRuntimes,
    isLoading,
    isDeleting,
    container,
    editLocalRuntime,
    getLocalRuntimes,
    deleteLocalRuntime,
    navigateTo,
}: LocalRuntimeListProps) => {
    const [searchFilter, setSearchFilter] = useState('');
    const [selectedRuntime, setSelectedRuntime] = useState<RuntimeListResponse | null>(null);

    useEffect(() => {
        getLocalRuntimes();
    }, [getLocalRuntimes]);

    useEffect(() => {
        const interval = setInterval(() => {
            getLocalRuntimes();
        }, 5_000);

        return () => {
            clearInterval(interval);
        };
    }, [getLocalRuntimes]);

    const filteredResults = localRuntimes.filter((runtime) =>
        stringContains(runtime.developerName, searchFilter, false),
    );

    const handleSearch = (searchTerm: string) => {
        setSearchFilter(searchTerm);
    };

    const handleDeleteConfirm = () => {
        deleteLocalRuntime(selectedRuntime);
        setSelectedRuntime(null);
    };

    const handleCancel = () => {
        setSelectedRuntime(null);
    };

    const handleCreateNew = () => {
        navigateTo(FLOW_ORG_PAGES.localRuntimeCreate);
    };

    const renderStatusCell = ({ item: { id, status } }: { item: RuntimeListResponse }) => {
        let icon: JSX.Element | string;
        switch (status) {
            case RuntimeStatus.Offline: {
                icon = (
                    <span
                        data-testid={`online-status-${id}`}
                        title={translations.LOCAL_RUNTIME_possible_offline_node_title_text}
                        className="table-icon-large"
                    >
                        <WarningCircle color="#F4C57F" />
                    </span>
                );
                break;
            }
            case RuntimeStatus.Unknown: {
                icon = (
                    <span
                        data-testid={`online-status-${id}`}
                        title={translations.LOCAL_RUNTIME_unknown_status_title_text}
                        className="table-icon-large"
                    >
                        <Question color="#8C8C8C" />
                    </span>
                );
                break;
            }
            case RuntimeStatus.Online: {
                icon = (
                    <span
                        data-testid={`online-status-${id}`}
                        title={translations.LOCAL_RUNTIME_online_status_title_text}
                        className="table-icon-large"
                    >
                        <CheckCircle color="#0EA076" />
                    </span>
                );
                break;
            }
            // Omitting default case because all enum variants are covered; if a new variant is added, icon will error when it's used, forcing developer to handle the variant
            case RuntimeStatus.Leader: {
                icon = '';
                break;
            }
        }

        const labelClasses = classNames(
            'label',
            status === RuntimeStatus.Online
                ? 'label-success'
                : status === RuntimeStatus.Offline
                  ? 'label-danger'
                  : 'label-default',
        );

        return (
            <div className="table-icon-text-wrapper" data-testid="runtime-status">
                {icon}
                <span className={labelClasses}>{status}</span>
            </div>
        );
    };

    const columns: TableColumnList<RuntimeListResponse> = [
        {
            renderHeader: () => translations.COMMON_TABLE_connection_name,
            renderCell: ({ item: runtime }) => (
                <button
                    className="link-emulate"
                    title={translations.LOCAL_RUNTIME_list_edit_runtime_button_label}
                    onClick={() => editLocalRuntime(runtime.id, navigateTo)}
                    aria-label={translations.LOCAL_RUNTIME_list_edit_runtime_button_label}
                    type="button"
                >
                    {runtime.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_version,
            renderCell: ({ item: runtime }) => {
                const version =
                    runtime.latestVersion || translations.LOCAL_RUNTIME_list_unknown_version;
                const labelClasses = classNames(
                    'label',
                    version === translations.LOCAL_RUNTIME_list_unknown_version
                        ? 'label-default'
                        : 'label-success',
                );

                return <span className={labelClasses}>{version}</span>;
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status,
            renderCell: renderStatusCell,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_last_seen,
            renderCell: ({ item: runtime }) => {
                const pingDateTime = runtime.lastPingAt
                    ? formatDistanceToNowStrict(new Date(runtime.lastPingAt), {
                          addSuffix: true,
                      })
                    : translations.LOCAL_RUNTIME_list_no_last_ping;
                return <span>{pingDateTime}</span>;
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_shared_tenants,
            renderCell: ({ item: runtime }) => runtime.numberOfTenants,
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item: runtime }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={translations.LOCAL_RUNTIME_list_delete_runtime_button_label}
                        className="table-icon table-icon-delete"
                        aria-label={translations.LOCAL_RUNTIME_list_delete_runtime_button_label}
                        onClick={() => setSelectedRuntime(runtime)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <h2 className="admin-heading-2" id="localRuntimes">
                {translations.LOCAL_RUNTIME_title}
            </h2>
            <p>{translations.LOCAL_RUNTIME_summary}</p>
            <div className="header-actions">
                <button onClick={handleCreateNew} className="btn btn-sm btn-primary" type="button">
                    {translations.LOCAL_RUNTIME_create_new_runtime_button_label}
                </button>
                <SearchInput value={searchFilter} onChange={handleSearch} />
            </div>
            <Table
                wrapperClassName="margin-top runtimes-table"
                items={filteredResults}
                columns={columns}
                isLoading={isLoading}
                rowKeyGenerator={(runtime) => runtime.id}
            />
            <ConfirmModal
                show={!!selectedRuntime || isDeleting}
                title={translations.LOCAL_RUNTIME_delete_modal_title}
                messages={[
                    stringReplace(translations.LOCAL_RUNTIME_delete_modal_message, {
                        connectionName: selectedRuntime?.developerName,
                    }),
                ]}
                buttonStyle="danger"
                buttonCaption={translations.COMMON_delete}
                onCancel={handleCancel}
                onConfirm={handleDeleteConfirm}
                isInProgress={isDeleting}
                container={container}
            />
        </>
    );
};

export default connector(LocalRuntimeList);

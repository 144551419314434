import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';
import { CRUD_OPERATION_TYPES } from '../common/constants';
import screens from '../common/screens';
import { DataActionProvider } from '../contextProviders/DataActionProvider';
import { MapElementProvider } from '../contextProviders/MapElementProvider';
import DeleteRouter from './DeleteRouter';

const Delete = (props) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.delete}
            elementType={MAP_ELEMENT_TYPES.databaseDelete}
        >
            <MapElementModal>
                <DataActionProvider
                    defaultScreen={screens.delete}
                    crudOperationType={CRUD_OPERATION_TYPES.delete}
                >
                    <DeleteRouter />
                </DataActionProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Delete;

import type { ComposerElement, ElementDrop } from '../../../../../types';
import { assocPath } from 'ramda';
import { removePageElement, onPageContainerDrop, onPageComponentDrop } from '../../../utils';
import type { ContainerType } from '../../dnd-utils';

export type ComposerAction =
    | {
          type: 'set_composer_elements';
          payload: ComposerElement[];
      }
    | {
          type: 'remove_composer_element';
          payload: { id: string };
      }
    | {
          type: 'drop_composer_container';
          payload: ElementDrop;
      }
    | {
          type: 'drop_composer_component';
          payload: ElementDrop;
      }
    | {
          type: 'set_composer_container_type';
          payload: { containerId: string; containerType: ContainerType };
      }
    | {
          type: 'set_composer_element';
          payload: { id: string; path: string[]; value: unknown };
      };

const ComposerReducer = (
    state: ComposerElement[] | [],
    action: ComposerAction,
): ComposerElement[] | [] => {
    switch (action.type) {
        case 'set_composer_elements':
            return action.payload;

        case 'remove_composer_element':
            return removePageElement(state, action.payload.id);

        case 'drop_composer_container':
            return onPageContainerDrop(
                state,
                action.payload.id,
                action.payload.parentId,
                action.payload.order,
                action.payload.containerType as ContainerType,
                action.payload.partialElement,
            );

        case 'drop_composer_component':
            return onPageComponentDrop(
                state,
                action.payload.id,
                action.payload.parentId,
                action.payload.order,
                action.payload.componentType as string,
                action.payload.partialElement,
            );

        case 'set_composer_container_type':
            return state.map((container) => {
                if (container.id === action.payload.containerId) {
                    return { ...container, containerType: action.payload.containerType };
                }
                return container;
            });

        case 'set_composer_element':
            return state.map((element) => {
                if (element.id === action.payload.id) {
                    return assocPath(action.payload.path, action.payload.value, element);
                }
                return element;
            });

        default:
            return state;
    }
};

export default ComposerReducer;

import { type ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchFeatureFlags } from '../../../js/actions/reduxActions/featureFlags';
import type { FeatureFlags } from '../../types';

import Loader from '../loader/Loader';

interface Props {
    isLoading: boolean;
    fetch: (tenantId: string) => Promise<void>;
    tenantId: string;
    children: ReactElement;
}

const FeatureFlagWrapper = ({ isLoading, fetch, tenantId, children }: Props) => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        fetch(tenantId);
    }, [tenantId]);
    return <>{isLoading ? <Loader /> : children}</>;
};

const mapStateToProps = ({ featureFlags }: { featureFlags: FeatureFlags }) => ({
    isLoading: featureFlags.isLoading,
});

export default connect(mapStateToProps, { fetch: fetchFeatureFlags })(FeatureFlagWrapper);

import { type ConnectedProps, connect } from 'react-redux';
import {
    acknowledgeRejectedTenantInvitation,
    cancelTenantInvitation,
    fetchSentTenantInvitations,
} from '../../../js/actions/reduxActions/organizations';
import type { OrganizationInvite } from '../../sources/organization';
import translations from '../../translations';
import InviteList from './InviteList';

type PendingTenantInviteListProps = {
    organizationId: string;
} & ConnectedProps<typeof connector>;

const mapStateToProps = ({
    organizationSentTenantInvitations,
}: { organizationSentTenantInvitations: OrganizationInvite[] }) => ({
    invitations: organizationSentTenantInvitations,
});

const mapDispatchToProps = {
    cancelInvitation: cancelTenantInvitation,
    acknowledgeRejection: acknowledgeRejectedTenantInvitation,
    fetchSentTenantInvitations,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const PendingTenantInviteList = ({
    cancelInvitation,
    acknowledgeRejection,
    fetchSentTenantInvitations,
    invitations,
    organizationId,
}: PendingTenantInviteListProps) => {
    const handleAcknowledge = (id: string) => {
        acknowledgeRejection({ invitedTenantId: id });
    };

    const handleCancel = (id: string) => {
        cancelInvitation({ invitedTenantId: id });
    };

    return (
        <InviteList
            caption={translations.FORG_tenant_invite_list_caption}
            invitations={invitations}
            organizationId={organizationId}
            loadInvitations={fetchSentTenantInvitations}
            onAcknowledge={handleAcknowledge}
            onCancel={handleCancel}
        />
    );
};

export default connector(PendingTenantInviteList);

import type { AppUser, EventTypesKeys, UserAPI } from '../../types';
import { AUDIT_TIME_PERIODS, PUBLIC_USER, EVENT_TYPES } from './constants';

/* Users may not have first or last name set when logged in via SSO
 *
 * @param user
 */
export const humanizeAuditUser = (user: AppUser) => {
    if (!user) {
        return 'NA';
    }

    if (user.id === PUBLIC_USER.id) {
        return `${user.firstName} ${user.lastName}`;
    }

    if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName} (${user.email})`;
    }

    if (user.lastName) {
        return `${user.lastName} (${user.email})`;
    }

    if (user.firstName) {
        return `${user.firstName} (${user.email})`;
    }

    // We always have an email because it's the login key, except Public users
    return user.email;
};

export const getTypeOptions = () => {
    return Object.entries(EVENT_TYPES).map(([key, value]) => ({
        label: value.name,
        value: key,
        logTemplate: value.logTemplate,
    }));
};

export const getPeriodOptions = () => {
    return Object.entries(AUDIT_TIME_PERIODS).map(([_key, value]) => ({
        label: value.label,
        value: value.value,
    }));
};

export const getUserOptions = (users: UserAPI[]) => {
    return users.map((user) => ({
        label: humanizeAuditUser(user),
        value: user ? user.id : null,
    }));
};

export const getEventType = (type: EventTypesKeys) => {
    if (type in EVENT_TYPES) {
        return EVENT_TYPES[type];
    }

    return {
        name: type,
        description: '',
    };
};

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addNotification } from '../../actions/reduxActions/notification';
import { NOTIFICATION_TYPES } from '../../../ts/constants';
import {
    getServiceInvokerRequest,
    getServiceInvokerRequestsByState,
} from '../../../ts/sources/serviceInvoker';
import Modal from '../../../ts/components/generic/modal/GenericModal';
import SyntaxHighlighter from '../../../ts/components/generic/SyntaxHighlighter';
import RootFaultsList from './RootFaultsList';
import { ExBadge } from '@boomi/exosphere';
import Table from '../../../ts/components/generic/Table';
import translations from '../../../ts/translations';
import { formatJSONContent } from '../../../ts/utils/json';

function renderStatus(attributes) {
    if (attributes.numberOfFailures > 0) {
        return (
            <ExBadge shape="round" color="red">
                Failed
            </ExBadge>
        );
    }
    return (
        <ExBadge shape="round" color="green">
            OK
        </ExBadge>
    );
}

const ServiceInvokerRequestList = ({ addNotification, tenantId, selectedState }) => {
    const [page, setPage] = useState(1);
    const pageSize = 50;
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState([]);
    const [content, setContent] = useState(null);
    const [showContent, setShowContent] = useState(false);

    async function refreshRequests(nextPage) {
        const params = {
            tenantId,
            stateId: selectedState.id,
            page: nextPage,
            pageSize,
        };

        setPage(nextPage);
        setLoading(true);

        try {
            const response = await getServiceInvokerRequestsByState(params);

            setRequests(response.items);
            setTotal(response._meta.total);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        } finally {
            setLoading(false);
        }
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        refreshRequests(1);
    }, [selectedState]);

    async function onShowFailures({ id: requestId }) {
        const params = {
            tenantId,
            requestId,
        };

        try {
            const { failures } = await getServiceInvokerRequest(params);
            setContent(failures ? JSON.stringify(failures) : '');
            setShowContent(true);
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        } finally {
            setLoading(false);
        }
    }

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_occurred_at_utc,
            renderCell: ({ item }) =>
                new Date(item.createdAt).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'medium',
                }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_uri,
            renderCell: ({ item }) => item.uri,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_method,
            renderCell: ({ item }) => item.method,
            size: '100px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status,
            renderCell: ({ item }) => renderStatus(item.attributes),
            size: '100px',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <>
                    <button
                        className="link-emulate"
                        onClick={() => onShowFailures(item)}
                        type="button"
                    >
                        Failures
                    </button>
                </>
            ),
            size: '10rem',
        },
    ];

    return (
        <div>
            <h1>Connector Log Events</h1>
            <Table
                columns={columns}
                items={requests}
                isLoading={loading}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: (nextPage) => refreshRequests(nextPage),
                }}
                rowClassName={() => 'generic-row generic-row-tall'}
            />
            <h1>Root Faults</h1>
            <RootFaultsList selectedState={selectedState} />
            <Modal
                className="metadata-editor"
                show={showContent}
                onHide={() => setShowContent(false)}
                title="Failures"
                renderBody={() => (
                    <SyntaxHighlighter content={formatJSONContent(content ?? '')} language="json" />
                )}
                renderFooter={() => (
                    <div className="flex">
                        <div className="flex-child-right">
                            <button
                                className="btn btn-sm btn-default"
                                onClick={() => setShowContent(false)}
                                type="button"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

const mapDispatchToProps = {
    addNotification,
};

export default connect(null, mapDispatchToProps)(ServiceInvokerRequestList);

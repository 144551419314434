import CodeEditor from './CodeEditor';

interface SyntaxHighlighterProps {
    content: string;
    language: string;
}

const SyntaxHighlighter = ({ content, language }: SyntaxHighlighterProps) => {
    return <CodeEditor mode={language} value={content} readOnly />;
};

export default SyntaxHighlighter;

import DataActionConfiguration from '../common/dataActions/DataActionConfiguration';
import OutcomeDetails from '../common/outcomes/OutcomeDetails';
import ListenerDetails from '../common/listeners/ListenerDetails';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import SaveConfiguration from './SaveConfiguration';
import ScreenRouter from '../../../../../ts/components/generic/ScreenRouter';

const Save = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        onReturnToDefaultScreen,
        setSelectedOutcomeIndex,
    } = useMapElement();

    return (
        <ScreenRouter currentScreen={currentScreen}>
            <SaveConfiguration screen={screens.save} />
            <DataActionConfiguration screen={screens.dataAction} />
            <OutcomeDetails
                screen={screens.outcome}
                outcome={mapElement?.outcomes?.[selectedOutcomeIndex]}
                onSave={onReturnToDefaultScreen}
                onCancel={() => {
                    onReturnToDefaultScreen();
                    setSelectedOutcomeIndex(null);
                }}
            />
            <ListenerDetails screen={screens.listener} />
        </ScreenRouter>
    );
};

export default Save;

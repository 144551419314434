import { useEffect, useState } from 'react';
import translations from '../../../../../../../../translations';
import { isNullOrEmpty } from '../../../../../../../../utils/guard';
import FormGroup from '../../../../../../../generic/FormGroup';
import { COMPONENT_CONFIGURATION_LABELS } from '../../../../../../constants';
import { usePageEditor } from '../../../../../PageEditorProvider';
import ConfigSection from '../../ConfigSection';
import ConfirmModal from '../../../../../../../generic/modal/ConfirmModal';
import type {
    ValueElementIdAPI,
    ObjectDataRequest,
    ValueElementIdReferenceAPI,
    ContentType,
    PageComponentValidationAPI,
} from '../../../../../../../../types';
import ValueSelectorModal from '../../../../../../../values/selector/ValueSelectorModal';

interface Props {
    required: boolean;
    selectedValue: ValueElementIdAPI | null;
    setSelectedValue: (value: ValueElementIdAPI | null) => void;
    dataSourceList: ValueElementIdAPI | null;
    dataSourceConnector: ObjectDataRequest | null;
    typeElementId: string | null;
    isMultiSelect: boolean;
    validations: PageComponentValidationAPI[] | null;
    stateValueContentTypeFilter: string | null;
}

const StateValueConfig = ({
    required,
    selectedValue,
    setSelectedValue,
    dataSourceList,
    dataSourceConnector,
    typeElementId,
    isMultiSelect,
    validations,
    stateValueContentTypeFilter,
}: Props) => {
    const { addNotification, container } = usePageEditor();
    const [typeElementIdFilter, setTypeElementIdFilter] = useState(typeElementId || null);
    const [valueNeedingConfirmation, setValueNeedingConfirmation] =
        useState<ValueElementIdAPI | null>(null);

    const onSelectValue = (value: ValueElementIdReferenceAPI | null) => {
        const valueSelection = value
            ? {
                  id: value.id,
                  typeElementPropertyId: value.typeElementPropertyId,
                  command: null,
              }
            : null;

        if (validations === null || validations.length === 0) {
            setSelectedValue(valueSelection);
        } else {
            setValueNeedingConfirmation(valueSelection);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (dataSourceConnector?.typeElementId) {
            // If the component is configured to make an objectData request
            // then we already have the type element ID
            setTypeElementIdFilter(dataSourceConnector.typeElementId);
        }

        if (!(dataSourceList?.id || dataSourceConnector?.typeElementId || typeElementId)) {
            // Reset the type element filter if the component has no data source
            setTypeElementIdFilter(null);
        }
    }, [dataSourceList, dataSourceConnector, addNotification, typeElementId]);

    // If there is a type element to filter by
    // then the user will want to save component state to an object value
    const contentTypeFilter =
        typeElementIdFilter && !isMultiSelect
            ? 'ContentObject'
            : typeElementIdFilter && isMultiSelect
              ? 'ContentList'
              : stateValueContentTypeFilter;

    return (
        <ConfigSection title="State" dataTestId="state-section">
            <FormGroup
                label={COMPONENT_CONFIGURATION_LABELS['STATE_VALUE']}
                htmlFor="state-value"
                isValid={!isNullOrEmpty(selectedValue)}
                validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
                showValidation={required}
                isRequired={required}
            >
                <ValueSelectorModal
                    value={selectedValue}
                    onChangeAsValueReference={onSelectValue}
                    contentType={contentTypeFilter as ContentType}
                    typeElementId={typeElementIdFilter}
                    includeSystemValues={false}
                    container={container}
                />
            </FormGroup>
            <ConfirmModal
                show={valueNeedingConfirmation !== null}
                title={translations.PAGE_BUILDER_state_value_modal_title}
                messages={[translations.PAGE_BUILDER_state_value_modal_message]}
                buttonStyle="danger"
                buttonCaption="OK"
                onCancel={() => setValueNeedingConfirmation(null)}
                onConfirm={() => {
                    setSelectedValue(valueNeedingConfirmation);
                    setValueNeedingConfirmation(null);
                }}
                container={container}
            />
        </ConfigSection>
    );
};

export default StateValueConfig;

import {
    getTenants,
    getUsers,
    sendTenantInvite,
    rejectTenantInvite,
    acknowledgeRejectedTenantInvite,
    cancelTenantInvite,
    getSentTenantInvites,
    sendUserInvite,
    cancelUserInvite,
    acknowledgeRejectedUserInvite,
    getSentUserInvites,
    getReceivedTenantInvites,
    getReceivedUserInvites,
} from '../../../ts/sources/organization';
import { addNotification } from './notification';
import actionTypes from './actionTypes';
import { NOTIFICATION_TYPES } from '../../../ts/constants';

export const fetchTenants = () => async (dispatch) => {
    try {
        const tenants = await getTenants();

        dispatch({
            type: actionTypes.ORGANIZATION_TENANTS_SET_ALL,
            payload: tenants,
        });
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const fetchUsers = () => async (dispatch) => {
    try {
        const users = await getUsers();

        dispatch({
            type: actionTypes.ORGANIZATION_USERS_SET_ALL,
            payload: users,
        });
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const fetchReceivedTenantInvitations = () => async (dispatch) => {
    try {
        const receivedTenantInvitations = await getReceivedTenantInvites();

        dispatch({
            type: actionTypes.ORGANIZATION_SET_RECEIVED_TENANT_INVITATIONS,
            payload: {
                receivedInvitations: receivedTenantInvitations,
            },
        });
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const fetchReceivedUserInvitations = () => async (dispatch) => {
    try {
        const receivedUserInvitations = await getReceivedUserInvites();

        dispatch({
            type: actionTypes.ORGANIZATION_SET_RECEIVED_USER_INVITATIONS,
            payload: {
                receivedInvitations: receivedUserInvitations,
            },
        });
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const fetchSentTenantInvitations = () => async (dispatch) => {
    try {
        const sentInvitations = await getSentTenantInvites();

        dispatch({
            type: actionTypes.ORGANIZATION_SET_SENT_TENANT_INVITATIONS,
            payload: {
                sentInvitations,
            },
        });
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const fetchSentUserInvitations = () => async (dispatch) => {
    try {
        const sentInvitations = await getSentUserInvites();

        dispatch({
            type: actionTypes.ORGANIZATION_SET_SENT_USER_INVITATIONS,
            payload: {
                sentInvitations,
            },
        });
    } catch (err) {
        dispatch(
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: err.message,
                isPersistent: true,
            }),
        );
    }
};

export const sendTenantInvitation =
    ({ inviteTenantData }) =>
    async (dispatch) => {
        try {
            await sendTenantInvite({
                invitationTenantName: inviteTenantData.developerName,
                invitationTenantId: inviteTenantData.id,
            });

            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.success,
                    message: 'Invitation was sent',
                }),
            );

            dispatch(fetchReceivedTenantInvitations());
            dispatch(fetchSentTenantInvitations());
        } catch (err) {
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: err.message,
                    isPersistent: true,
                }),
            );
        }
    };

export const sendUserInvitation =
    ({ inviteUserData }) =>
    async (dispatch) => {
        try {
            await sendUserInvite({
                invitationEmail: inviteUserData.email,
                invitationUserId: inviteUserData.id,
            });

            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.success,
                    message: 'Invitation was sent',
                }),
            );

            dispatch(fetchReceivedUserInvitations());
            dispatch(fetchSentUserInvitations());
        } catch (err) {
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: err.message,
                    isPersistent: true,
                }),
            );
        }
    };

export const rejectTenantInvitation =
    ({ organizationId }) =>
    async (dispatch) => {
        try {
            await rejectTenantInvite({
                organizationId,
            });

            dispatch(fetchReceivedTenantInvitations());
        } catch (err) {
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: err.message,
                    isPersistent: true,
                }),
            );
        }
    };

export const cancelTenantInvitation =
    ({ invitedTenantId }) =>
    async (dispatch) => {
        try {
            await cancelTenantInvite({
                invitedTenantId,
            });

            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.success,
                    message: 'Invitation was canceled',
                }),
            );

            dispatch(fetchReceivedTenantInvitations());
            dispatch(fetchSentTenantInvitations());
        } catch (err) {
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: err.message,
                    isPersistent: true,
                }),
            );
        }
    };

export const acknowledgeRejectedTenantInvitation =
    ({ invitedTenantId }) =>
    async (dispatch) => {
        try {
            await acknowledgeRejectedTenantInvite({
                invitedTenantId,
            });

            dispatch(fetchReceivedTenantInvitations());
            dispatch(fetchSentTenantInvitations());
        } catch (err) {
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: err.message,
                    isPersistent: true,
                }),
            );
        }
    };

export const cancelUserInvitation =
    ({ invitedUserId }) =>
    async (dispatch) => {
        try {
            await cancelUserInvite({
                invitedUserId,
            });

            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.success,
                    message: 'Invitation was canceled',
                }),
            );

            dispatch(fetchReceivedUserInvitations());
            dispatch(fetchSentUserInvitations());
        } catch (err) {
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: err.message,
                    isPersistent: true,
                }),
            );
        }
    };

export const acknowledgeRejectedUserInvitation =
    ({ invitedUserId }) =>
    async (dispatch) => {
        try {
            await acknowledgeRejectedUserInvite({
                invitedUserId,
            });

            dispatch(fetchReceivedUserInvitations());
            dispatch(fetchSentUserInvitations());
        } catch (err) {
            dispatch(
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: err.message,
                    isPersistent: true,
                }),
            );
        }
    };

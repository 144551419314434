import { Editor as ReactTinyMCEEditor } from '@tinymce/tinymce-react';
import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const RichText = ({ label, isRequired }: { label: string; isRequired: boolean }) => {
    return (
        <div data-testid="component-preview-richtext" className="component-preview-richtext">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            {/* FLOW-5935: Wrapping the TinyMCE editor into another div keeps it from jumping above the label. */}
            <div>
                <ReactTinyMCEEditor
                    licenseKey="gpl"
                    init={{
                        skin: false,
                        importcss_append: true,
                    }}
                />
            </div>
        </div>
    );
};

export default RichText;

import { ExEmptyState } from '@boomi/exosphere';
import type { DebugTabId } from '../../../../../types';
import { useDebug } from '../../DebugProvider';
import RootFaultsAccordion from './RootFaultsAccordion';

const RootFaults = (_: { screen?: DebugTabId }) => {
    const { callStack: callStackItems } = useDebug();

    const selectedCallStackItem = callStackItems.length
        ? callStackItems.find((callStackItem) => callStackItem.isSelected)
        : null;

    const rootFaults = selectedCallStackItem ? selectedCallStackItem.rootFaults : null;

    return (
        <div data-testid="root-faults" className="full-height flex align-center call-stack-outer">
            {rootFaults ? (
                <div className="debug-tabs-inner padding">
                    <RootFaultsAccordion rootFaults={rootFaults} />
                </div>
            ) : (
                <div className="center">
                    <ExEmptyState
                        label="There are no root faults to display"
                        text="Everything seems fine."
                        icon="Error"
                    />
                </div>
            )}
        </div>
    );
};

export default RootFaults;

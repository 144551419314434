import { Check, PencilSimple, X } from '@phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import ButtonIcon from '../buttons/ButtonIcon';
import './css/inline-input.less';

interface Props {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    defaultValue: string;
    saveInputValue: (inputValue: string) => Promise<void>;
    editButtonTitle?: string;
    confirmButtonTitle?: string;
    cancelButtonTitle?: string;
}

const InlineInput = ({
    isOpen,
    setIsOpen,
    defaultValue,
    saveInputValue,
    editButtonTitle = 'Edit',
    confirmButtonTitle = 'Confirm',
    cancelButtonTitle = 'Cancel',
}: Props) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [inputValue, setInputValue] = useState('');
    const [showEditButtonMouse, setShowEditButtonMouse] = useState(false);
    const [showEditButtonKeyboard, setShowEditButtonKeyboard] = useState(false);

    const toggleEditButton = (isEditing: boolean) => {
        setShowEditButtonMouse(isEditing);
        setShowEditButtonKeyboard(isEditing);
    };

    // Handles if the user opens another InlineEditor
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isOpen) {
            setInputValue(defaultValue);
            toggleEditButton(false);
        }
    }, [isOpen]);

    useEffect(() => {
        setInputValue(defaultValue);
    }, [defaultValue]);

    const finishEditing = async () => {
        if (inputValue !== defaultValue) {
            await saveInputValue(inputValue);
        }

        setIsOpen(false);
        toggleEditButton(false); // This gets called here too so the edit button doesn't flash.
    };

    const cancelEditing = () => {
        setInputValue(defaultValue);
        setIsOpen(false);
        toggleEditButton(false); // This gets called here too so the edit button doesn't flash.
    };

    return isOpen ? (
        <div className="inline-input-wrapper">
            <div className="inline-input">
                <textarea
                    className="form-control inline-textarea"
                    maxLength={255}
                    rows={5}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    ref={textareaRef}
                    onFocus={(e) =>
                        // This is required to set the cursor to the end of the text after a focus.
                        e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length,
                        )
                    }
                    name={inputValue}
                />
                <div className="inline-button-wrapper">
                    <button
                        className="inline-input-button-editing"
                        onClick={finishEditing}
                        title={confirmButtonTitle}
                        type="button"
                    >
                        <Check />
                    </button>
                    <button
                        className="inline-input-button-editing"
                        onClick={cancelEditing}
                        title={cancelButtonTitle}
                        type="button"
                    >
                        <X />
                    </button>
                </div>
            </div>
        </div>
    ) : (
        <div
            className="inline-input-wrapper"
            onMouseEnter={() => setShowEditButtonMouse(true)}
            onMouseLeave={() => setShowEditButtonMouse(false)}
            onFocus={() => setShowEditButtonKeyboard(true)}
            onBlur={() => setShowEditButtonKeyboard(false)}
        >
            <div className="inline-input-static overflow-ellipsis" title={inputValue}>
                {inputValue}
            </div>
            <ButtonIcon
                className="inline-input-button"
                onClick={() => {
                    setIsOpen(true);

                    // requestAnimationFrame so this happens on the next render cycle, after the textarea has appeared.
                    requestAnimationFrame(() => {
                        if (textareaRef.current) {
                            textareaRef.current.focus();
                        }
                    });
                }}
                title={editButtonTitle}
            >
                {showEditButtonMouse || showEditButtonKeyboard ? <PencilSimple /> : null}
            </ButtonIcon>
        </div>
    );
};

export default InlineInput;

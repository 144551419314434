import type { column } from '../../../../types';
import FileUpload from './FileUpload';
import Table from './Table';

const Files = ({
    label,
    columns,
    isRequired,
}: {
    label: string;
    columns: column[];
    isRequired: boolean;
}) => {
    return (
        <div data-testid="component-preview-files">
            <FileUpload label={label} isRequired={isRequired} />
            <Table label={null} columns={columns} />
        </div>
    );
};

export default Files;

import { useState } from 'react';
import translations from '../../../../../ts/translations';
import { isNullOrWhitespace } from '../../../../../ts/utils/guard';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import HTMLInput from '../../../../../ts/components/generic/HTMLEditor/HTMLInput';
import Footer from '../common/Footer';
import ListenerList from '../common/listeners/ListenerList';
import MessageActionsList from '../common/messageActions/MessageActionsList';
import NameInput from '../common/NameInput';
import OutcomeList from '../common/outcomes/OutcomeList';
import { useMapElement } from '../contextProviders/MapElementProvider';
import ModalBody from '../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../ts/components/generic/modal/ModalFooter';

const MessageConfiguration = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const {
        mapElement,
        onUpdateName,
        onUpdateComments,
        onUpdateStatusMessage,
        onUpdateNotAuthorizedMessage,
        onSaveMapElement,
        onClose,
    } = useMapElement();

    const isFormValid = !isNullOrWhitespace(mapElement?.developerName);

    const onSave = () => {
        setHasSubmitted(true);

        if (isFormValid) {
            onSaveMapElement(mapElement);
            return;
        }
    };

    const renderBody = () => (
        <>
            <NameInput
                isValid={isFormValid}
                showValidation={hasSubmitted}
                id="message-name"
                name={mapElement.developerName ?? ''}
                onUpdateName={onUpdateName}
            />
            <MessageActionsList />
            <OutcomeList />
            <ListenerList />
            <h4>Feedback</h4>
            <FormGroup
                label="Message to show users that are waiting for this Message to complete"
                labelId="waitingMessage"
            >
                <HTMLInput
                    contentValue={mapElement.statusMessage}
                    onChange={(userContent) => onUpdateStatusMessage(userContent)}
                    hasLinkPlugin={true}
                    hasImagePlugin={true}
                    hasValuePickerPlugin={true}
                    height={250}
                />
            </FormGroup>
            <FormGroup
                label="Message to show users that are not authorized to take action"
                labelId="notAuthorizedMessage"
            >
                <HTMLInput
                    contentValue={mapElement.notAuthorizedMessage}
                    onChange={(userContent) => onUpdateNotAuthorizedMessage(userContent)}
                    hasLinkPlugin={true}
                    hasImagePlugin={true}
                    hasValuePickerPlugin={true}
                    height={250}
                />
            </FormGroup>
            <FormGroup htmlFor="message-comments" label="Comments about this Message">
                <textarea
                    id="message-comments"
                    value={mapElement.developerSummary ?? ''}
                    className="form-control form-textarea"
                    onChange={({ target: { value } }) => onUpdateComments(value)}
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => {
        return (
            <Footer
                save={onSave}
                cancel={onClose}
                saveButtonText={translations.GRAPH_config_panel_save}
            />
        );
    };

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default MessageConfiguration;

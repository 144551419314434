import type { ButtonProps } from '../../types';
import Loader from '../loader/Loader';

interface Props extends ButtonProps {
    isLoading: boolean;
}

const ButtonLoading = ({ className, isLoading, onClick, children, ...otherProps }: Props) => (
    <button className={className} disabled={isLoading} onClick={onClick} {...otherProps}>
        {isLoading ? <Loader small /> : null}
        {children}
    </button>
);

export default ButtonLoading;

const screens = {
    openApi: 'openApi',
    openApiDetails: 'openApiDetails',
    wait: 'wait',
    step: 'step',
    page: 'page',
    decision: 'decision',
    delete: 'delete',
    message: 'message',
    save: 'save',
    load: 'load',
    dataAction: 'dataAction',
    filter: 'filter',
    criteria: 'criteria',
    outcome: 'outcome',
    navigationOverrides: 'navigationOverrides',
    listener: 'listener',
    messageActions: 'messageActions',
    operator: 'operator',
    operationDetails: 'operationDetails',
    macroDetails: 'macroDetails',
    subflow: 'subflow',
    process: 'process',
    processDetails: 'processDetails',
    processProperties: 'processProperties',
};

export default screens;

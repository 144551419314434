import { useEffect, useState } from 'react';
import SearchInput from '../generic/SearchInput';
import { getSubtenants } from '../../sources/tenant';
import translations from '../../translations';
import type { TenantResponseAPI } from '../../types/Tenant';
import Table, { type TableColumnList } from '../generic/Table';
import TenantName from '../generic/TenantName';
import { TENANT_VIEWS } from '../../constants';

interface SubtenantListProps {
    tenantId: string;
    navigateTo: (view: keyof typeof TENANT_VIEWS) => void;
}

const subtenantColumns: TableColumnList<TenantResponseAPI> = [
    {
        renderHeader: () => translations.COMMON_TABLE_name,
        renderCell: ({ item }) => <TenantName name={item?.developerName ?? ''} />,
    },
    {
        renderHeader: () => translations.COMMON_TABLE_summary,
        renderCell: ({ item }) => <div>{item.developerSummary}</div>,
    },
];

const SubtenantList = ({ tenantId, navigateTo }: SubtenantListProps) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [subtenants, setSubtenants] = useState<TenantResponseAPI[]>([]);
    const [query, setQuery] = useState('');
    const [paging, setPaging] = useState({
        page: 1,
        pageSize: 20,
        total: 0,
    });

    const onSearch = (query: string) => {
        setQuery(query);
    };

    const onPage = (pageNumber: number) => {
        setPaging({
            ...paging,
            page: pageNumber,
        });
    };

    useEffect(() => {
        if (isInitialized) {
            return;
        }

        const fetchSubtenants = async () => {
            const subtenants = await getSubtenants(tenantId);
            setSubtenants(subtenants);

            const newPaging = { ...paging, total: subtenants.length };
            setPaging(newPaging);

            setIsInitialized(true);
        };

        fetchSubtenants();
    }, [paging, tenantId, isInitialized]);

    const pageStart = paging.pageSize * (paging.page - 1);
    const pageEnd = pageStart + paging.pageSize;

    const queriedTenants = subtenants.filter(
        ({ developerName }) => !query || developerName?.toLowerCase().includes(query.toLowerCase()),
    );

    const visibleTenants = queriedTenants.slice(pageStart, pageEnd);

    return (
        <>
            <div className="flex">
                <button
                    onClick={() => navigateTo(TENANT_VIEWS.newSubtenant)}
                    className="btn btn-sm btn-success"
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    {' New Subtenant'}
                </button>
                <SearchInput value={query} onChange={onSearch} className="margin-left" />
            </div>
            <Table
                wrapperClassName="margin-top"
                columns={subtenantColumns}
                items={visibleTenants}
                pagination={{
                    page: paging.page,
                    total: queriedTenants.length,
                    pageSize: paging.pageSize,
                    changePage: onPage,
                }}
            />
        </>
    );
};

export default SubtenantList;

import { macroSource } from '../../../ts/sources/macro';
import translations from '../../translations';
import type { AddNotification, MacroElementResponseAPI } from '../../types';
import EditorActionsPanel from '../shared/editor/EditorActionsPanel';
import EditorDocumentSelect from '../shared/editor/EditorDocumentSelect';
import EditorHeader from '../shared/editor/EditorHeader';
import './macro-editor.less';
import type { SingleValue } from 'react-select';

interface SelectOption {
    value: string;
    label: string;
}

const MacroEditorHeader = ({
    macros,
    selected,
    setLoading,
    setMacros,
    setSelected,
    setUnsavedChanges,
    setIsNewMacro,
    alertNoMacroSelected,
    setShowDeleteConfirmation,
    saveMacro,
    addNotification,
}: {
    macros: MacroElementResponseAPI[];
    setMacros: React.Dispatch<React.SetStateAction<MacroElementResponseAPI[]>>;
    selected: MacroElementResponseAPI | null;
    setSelected: React.Dispatch<React.SetStateAction<MacroElementResponseAPI | null>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    setIsNewMacro: React.Dispatch<React.SetStateAction<boolean>>;
    setShowDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    saveMacro: (macroToSave: MacroElementResponseAPI) => Promise<void>;
    addNotification: AddNotification;
    alertNoMacroSelected: () => void;
}) => {
    const selectOptions = macros.map(({ id, developerName }) => {
        if (!developerName) {
            throw new Error(`Macro with id ${id} has no developer name`);
        }

        return {
            value: id,
            label: developerName,
        };
    });

    const refreshMacro = async (macroId: string) => {
        try {
            setLoading(true);

            const refreshed = await macroSource.get(macroId);

            const updatedMacros = macros.map((stored) =>
                stored.id === macroId ? refreshed : stored,
            );

            setMacros(updatedMacros);
            setSelected(refreshed);
            setUnsavedChanges(false);
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).toString(),
            });
        } finally {
            setLoading(false);
        }
    };

    const selectedOption = selectOptions.find(({ value }) => value === selected?.id) ?? {
        label: translations.MACRO_macro_select_default_option_label,
        value: '',
    };

    const handleNewClick = () => setIsNewMacro(true);

    const handleRefreshClick = () => {
        if (!selected) {
            alertNoMacroSelected();
            return;
        }
        refreshMacro(selected.id);
    };

    const handleDeleteClick = () => {
        if (!selected) {
            alertNoMacroSelected();
            return;
        }

        setShowDeleteConfirmation(true);
    };

    const handleSaveClick = () => {
        if (!selected) {
            alertNoMacroSelected();
            return;
        }
        saveMacro(selected);
    };

    const handleSelectionChange = async (option: SingleValue<SelectOption | undefined> | null) => {
        if (!option?.value) {
            return;
        }

        const selectedMacro = macros.find(({ id }) => id === option.value);

        if (!selectedMacro) {
            return;
        }

        // fetch the macro individually as the code is not returned in the bulk endpoint
        const fullSelectedMacro = await macroSource.get(selectedMacro.id);

        setSelected(fullSelectedMacro);
        setUnsavedChanges(false);
    };

    return (
        <EditorHeader className="macro-editor-header">
            <EditorDocumentSelect
                className="macro-select"
                handleChange={handleSelectionChange}
                id="macro-select"
                label={translations.MACRO_macro_select_label}
                options={selectOptions}
                value={selectedOption}
            />
            <EditorActionsPanel
                className="macro-actions"
                onNew={handleNewClick}
                onSave={handleSaveClick}
                onRefresh={handleRefreshClick}
                onDelete={handleDeleteClick}
            />
        </EditorHeader>
    );
};

export default MacroEditorHeader;

import type { OutcomeEventsResponseApi } from '../../../sources/outcomeinsights';
import type { MapElement, Outcome } from '../../../types';

interface Ratio {
    isLoading: boolean;
    ratioPercentage: number | null;
    hex: string | null;
}

interface Ratios {
    [outcomeId: string]: Ratio;
}

export const initializeTrafficRatio = (
    mapElementId: string,
    mapElements: Pick<MapElement, 'id' | 'developerName' | 'outcomes'>[],
    existingRatios: Ratios,
): Ratios => {
    const mapElement = mapElements.find(
        (mapElement) => mapElement.id === mapElementId,
    ) as MapElement;

    if (mapElement.outcomes) {
        return mapElement.outcomes.reduce((ratios: Ratios, outcome: Outcome) => {
            ratios[outcome.id] = { isLoading: true, ratioPercentage: null, hex: null };
            return ratios;
        }, existingRatios);
    }

    return {};
};

const getPercentage = (partialValue: number, totalValue: number): number =>
    partialValue === 0 && totalValue === 0 ? 0 : (100 * partialValue) / totalValue;

export const calculateRatioPercentages = (
    events: OutcomeEventsResponseApi[],
    outcomes: Outcome[],
): Ratios => {
    const totals: number[] = events.map((ev) =>
        ev.currentPeriodEvents.reduce((total, event) => total + event.count, 0),
    );

    const sum: number = totals.reduce((sum, total) => sum + total, 0);

    const results = totals.reduce((ratios: Ratios, total, i) => {
        const ratioPercentage = Math.round(getPercentage(total, sum));
        ratios[outcomes[i].id] = {
            isLoading: false,
            ratioPercentage,
            hex: null,
        };
        return ratios;
    }, {});

    const colourScale = [
        '#6600FF',
        '#6633FF',
        '#6666FF',
        '#6699FF',
        '#FFCC33',
        '#FFCC00',
        '#FF9900',
        '#FF6600',
        '#FF3300',
        '#FF0000',
    ];

    for (const property in results) {
        const colourIndex = Math.floor((results[property].ratioPercentage as number) / 10);
        results[property].hex = colourIndex === 0 ? '#000000' : colourScale[colourIndex - 1];
    }

    return results;
};

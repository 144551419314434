import { useEffect, useState } from 'react';
import FormGroup from '../../../../../../generic/FormGroup';
import { isNullOrEmpty } from '../../../../../../../utils/guard';
import { stringReplace } from '../../../../../../../utils/string';
import translations from '../../../../../../../translations';
import ButtonPrimary from '../../../../../../buttons/ButtonPrimary';
import {
    COMPONENT_PROPERTIES as componentProperties,
    TOGGLE_TRIGGER_OPTIONS as toggleTriggerOptions,
    TOGGLE_CATEGORY_OPTIONS as toggleCategoryOptions,
} from '../../../../../constants';
import ButtonDefault from '../../../../../../buttons/ButtonDefault';
import { usePageEditor } from '../../../../PageEditorProvider';
import { CONTENT_TYPES_LIST, SYSTEM_VALUES } from '../../../../../../../constants';
import { usePageCondition } from '../../PageConditionsProvider';
import {
    getPageComponentOptions,
    getRuleCriteriaOptions,
    getSelectedComponent,
    getSelectedProperty,
    updateComponent,
    updateProperty,
    updateValue,
    checkCriteriaCompatibility,
    getRuleContentTypes,
    checkContentTypeCompatibility,
    getCriteriaPhrase,
    getRuleTriggerOption,
    getItemCategoryOption,
    isRuleConfigured,
    checkForNewIDs,
} from '../../common/pageConditionsUtils';
import RadioToggle from '../../common/RadioToggle';
import ComponentOrValueConfig from '../../common/ComponentOrValueConfig';

import { defaultPageRuleConfig } from '../../../../../templates';
import Select from 'react-select';
import { getSharedStyles } from '../../../../../../../utils/select';
import type {
    ContentType,
    CriteriaType,
    ValueElementIdReferenceAPI,
} from '../../../../../../../types';

const PageConditionRuleConfig = () => {
    const { state: pageBuilderState, container } = usePageEditor();
    const { page } = pageBuilderState;
    const { pageComponents } = page;
    const {
        state: pageConditionState,
        updateRuleIndex,
        applyRule,
        updateRefreshValues,
    } = usePageCondition();
    const { values, condition, ruleIndex } = pageConditionState;
    const rule =
        ruleIndex !== null && ruleIndex >= 0 && condition?.pageRules
            ? condition.pageRules[ruleIndex]
            : defaultPageRuleConfig;

    // Storing all the bits that we plan to edit in local state.
    const [ruleConfig, setRuleConfig] = useState(rule);
    // Using local copy of the values, so I only trigger re-renders of this component when there are changes
    // (mainly to do the contentType error checking). Also, since I can cancel my rule edits at any time
    // I don't want to trigger the update of the values in the context until the edits have been applied (saved).
    const [localValues, setLocalValues] = useState<Partial<ValueElementIdReferenceAPI>[] | null>(
        values ?? [],
    );

    // Used for showing input validation!
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [triggerSelector, setTriggerSelector] = useState(toggleTriggerOptions['CHANGE']);
    const [leftSelector, setLeftSelector] = useState(toggleCategoryOptions['COMPONENT']);
    const [rightSelector, setRightSelector] = useState(toggleCategoryOptions['COMPONENT']);
    const [leftContentTypeFilter, setLeftContentTypeFilter] = useState<
        ContentType | null | undefined
    >(null);
    const [leftContentTypeCriteriaOptions, setLeftContentTypeCriteriaOptions] = useState<
        { value: CriteriaType; label: string }[]
    >([]);
    const [incompatibleCriteriaMsg, setIncompatibleCriteriaMsg] = useState<string | null>(null);
    const [incompatibleContentTypesMsg, setIncompatibleContentTypesMsg] = useState<string | null>(
        null,
    );

    // Preselect the radio buttons on initial load.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const ruleTrigger = getRuleTriggerOption(ruleConfig?.left);
        const ruleLeftCategory = getItemCategoryOption(ruleConfig?.left, false);
        const ruleRightCategory = getItemCategoryOption(ruleConfig?.right, false);

        ruleTrigger && setTriggerSelector(ruleTrigger);
        ruleLeftCategory && setLeftSelector(ruleLeftCategory);
        ruleRightCategory && setRightSelector(ruleRightCategory);
    }, []);

    // When the rule is edited and/or a new value is introduced we need to go over and update some things.
    useEffect(() => {
        // Figure out the left and right content types, so we can...
        const { leftContentType, rightContentType } = getRuleContentTypes(
            ruleConfig,
            localValues,
            pageComponents,
        );

        // ...apply a filter when selecting the right value.
        setLeftContentTypeFilter(leftContentType);

        // ...set the criteria options based on left content type.
        setLeftContentTypeCriteriaOptions(getRuleCriteriaOptions(leftContentType));

        // ...catch and warn about incompatible content types before the rule is saved.
        const isIncompatibleContentTypes = checkContentTypeCompatibility(
            leftContentType,
            rightContentType,
            ruleConfig?.criteriaType,
        );

        const contentTypeErrorMsg = isIncompatibleContentTypes
            ? stringReplace(
                  translations.PAGE_BUILDER_page_conditions_rule_invalid_content_types_message,
                  {
                      contentTypeOne:
                          CONTENT_TYPES_LIST?.find(
                              (item) => item.key === leftContentType?.toUpperCase(),
                          )?.label ?? leftContentType,
                      contentTypeTwo:
                          CONTENT_TYPES_LIST?.find(
                              (item) => item.key === rightContentType?.toUpperCase(),
                          )?.label ?? rightContentType,
                      criteria: getCriteriaPhrase(ruleConfig?.criteriaType),
                  },
              )
            : null;

        setIncompatibleContentTypesMsg(contentTypeErrorMsg);

        // ...catch and warn about incompatible rule criteria.
        const isIncompatibleCriteria =
            ruleConfig?.criteriaType && leftContentType
                ? !checkCriteriaCompatibility(ruleConfig?.criteriaType, leftContentType)
                : false;

        const criteriaErrorMsg = isIncompatibleCriteria
            ? stringReplace(translations.PAGE_BUILDER_page_conditions_invalid_criteria_message, {
                  contentType:
                      CONTENT_TYPES_LIST?.find(
                          (item) => item.key === leftContentType?.toUpperCase(),
                      )?.label ?? leftContentType,
                  criteriaType: getCriteriaPhrase(ruleConfig?.criteriaType),
              })
            : null;

        setIncompatibleCriteriaMsg(criteriaErrorMsg);
    }, [ruleConfig, localValues, pageComponents]);

    const selectedLeftComponent = getSelectedComponent(ruleConfig?.left, pageComponents);
    const selectedLeftProperty = getSelectedProperty(ruleConfig.left);
    const selectedLeftValue = ruleConfig?.left?.valueElementToReferenceId;

    const selectedRightComponent = getSelectedComponent(ruleConfig?.right, pageComponents);
    const selectedRightProperty = getSelectedProperty(ruleConfig?.right);
    const selectedRightValue = ruleConfig?.right?.valueElementToReferenceId;

    const getSelectedCriteria = () =>
        leftContentTypeCriteriaOptions.find((rc) => rc.value === ruleConfig?.criteriaType) || null;

    const onChangeTrigger = (selected: string) => {
        const isValue = ruleConfig?.left?.metadataType === 'VALUE';
        if (ruleConfig?.left) {
            const resetLeft =
                selected === toggleTriggerOptions['LOAD']
                    ? {
                          ...ruleConfig.left,
                          // going to when page loads...
                          // if there is a value already defined under IF, we should probably just keep it (?)
                          ...(!isValue && { metadataType: null }),
                          // but we can reset the component
                          pageObjectReferenceDeveloperName: '',
                          pageObjectReferenceId: null,
                      }
                    : {
                          ...ruleConfig.left,
                          // going to when component changes...
                          // if there is a value already defined under IF, we should probably just keep it (?)
                          ...(!isValue && { metadataType: null }),
                          // also keep any preconfigured component
                      };

            setRuleConfig({
                ...ruleConfig,
                left: resetLeft,
            });
            setTriggerSelector(selected);
        }
    };

    const onChangeLeft = (selected: string) => {
        if (ruleConfig?.left) {
            const resetLeft =
                selected === toggleCategoryOptions['COMPONENT']
                    ? {
                          // if we pick component here, then we can't have a value + page load anymore!
                          ...ruleConfig.left,
                          metadataType: null,
                          typeElementPropertyId: null,
                          valueElementToReferenceId: null,
                      }
                    : {
                          // we don't want to reset the component since we could be using
                          // "when component changes", so only update the property (metadataType)
                          ...ruleConfig.left,
                          metadataType: 'VALUE',
                      };

            setRuleConfig({
                ...ruleConfig,
                criteriaType: null,
                left: resetLeft,
            });
            setLeftSelector(selected);
        }
    };

    const onChangeRight = (selected: string) => {
        if (ruleConfig?.right) {
            // When using the toggle to switch between "component" or "value" reset any config related to the other one.
            const resetRight =
                selected === toggleCategoryOptions['COMPONENT']
                    ? {
                          ...ruleConfig.right,
                          metadataType: null,
                          typeElementPropertyId: null,
                          valueElementToReferenceId: null,
                      }
                    : {
                          ...ruleConfig.right,
                          metadataType: 'VALUE',
                          pageObjectReferenceDeveloperName: '',
                          pageObjectReferenceId: null,
                      };

            setRuleConfig({
                ...ruleConfig,
                right: resetRight,
            });
            setRightSelector(selected);
        }
    };

    const onChangeCriteria = (selected: CriteriaType) => {
        // If IS_EMPTY preconfigure the right part to $True,
        // but the user can still pick anything else if they want.
        if (selected === 'IS_EMPTY') {
            setRuleConfig({
                ...ruleConfig,
                criteriaType: selected,
                right: {
                    ...ruleConfig?.right,
                    metadataType: 'VALUE',
                    typeElementPropertyId: null,
                    valueElementToReferenceId: {
                        command: null,
                        id: SYSTEM_VALUES.true.id, // $True
                        typeElementPropertyId: null,
                    },
                    // clear the rest
                    pageObjectReferenceDeveloperName: '',
                    pageObjectReferenceId: null,
                },
            });

            // Make sure the VALUE radio is now selected.
            setRightSelector(toggleCategoryOptions['VALUE']);

            if (localValues && !localValues?.find((value) => value.id === SYSTEM_VALUES.true.id)) {
                setLocalValues([
                    ...localValues,
                    {
                        id: SYSTEM_VALUES.true.id,
                        developerName: '$True',
                        contentType: 'ContentBoolean',
                        elementType: 'VARIABLE',
                    },
                ]);
            }
        } else {
            setRuleConfig({
                ...ruleConfig,
                criteriaType: selected,
            });
        }
    };

    const onCancel = () => {
        updateRuleIndex(null);
        setHasSubmitted(false);
    };

    const onApply = () => {
        setHasSubmitted(true);

        if (isRuleConfigured(ruleConfig)) {
            // Check if we have any new values.
            updateRefreshValues(checkForNewIDs(condition, ruleConfig));

            // Save the new/updated rule into the context.
            applyRule(ruleConfig);
            onCancel();
        }
    };

    return (
        <div className="sidebar-mini-editor" data-testid="rule-editor">
            <h4 className="sidebar-section-heading">
                {ruleIndex !== null && ruleIndex >= 0 ? 'Edit rule' : 'New rule'}
            </h4>

            <h4 className="sidebar-section-heading">WHEN</h4>

            {/* Pick between component change or page load */}
            <RadioToggle
                options={toggleTriggerOptions}
                selectedOption={triggerSelector}
                updateSelectedOption={onChangeTrigger}
            />

            {/* LEFT COMPONENT */}
            {triggerSelector === toggleTriggerOptions['CHANGE'] && (
                <ComponentOrValueConfig
                    selectedConfigType="ONLY_COMPONENT"
                    componentOptions={getPageComponentOptions(pageComponents)}
                    selectedComponent={selectedLeftComponent || null}
                    updateSelectedComponent={(newComponent) => {
                        setRuleConfig({
                            ...ruleConfig,
                            left: ruleConfig.left
                                ? updateComponent(ruleConfig.left, newComponent)
                                : null,
                        });
                    }}
                    hasSubmitted={hasSubmitted}
                    identifier="left"
                    container={container}
                />
            )}

            <h4 className="sidebar-section-heading">IF</h4>

            {/* Pick between component or value */}
            {triggerSelector === toggleTriggerOptions['CHANGE'] && (
                <RadioToggle
                    options={toggleCategoryOptions}
                    selectedOption={leftSelector}
                    updateSelectedOption={onChangeLeft}
                />
            )}

            {/* LEFT COMPONENT PROPERTY (metadata type) */}
            {triggerSelector === toggleTriggerOptions['CHANGE'] &&
                leftSelector === toggleCategoryOptions['COMPONENT'] && (
                    <ComponentOrValueConfig
                        selectedConfigType="ONLY_PROPERTY"
                        propertyOptions={componentProperties}
                        selectedProperty={selectedLeftProperty || null}
                        updateSelectedProperty={(newProperty) => {
                            setRuleConfig({
                                ...ruleConfig,
                                ...(!newProperty && { criteriaType: null }),
                                left: ruleConfig.left
                                    ? updateProperty(ruleConfig.left, newProperty)
                                    : null,
                            });
                        }}
                        hasSubmitted={hasSubmitted}
                        identifier="left"
                    />
                )}

            {/* LEFT VALUE */}
            {(triggerSelector === toggleTriggerOptions['LOAD'] ||
                leftSelector === toggleCategoryOptions['VALUE']) && (
                <ComponentOrValueConfig
                    selectedConfigType="VALUE"
                    selectedValue={selectedLeftValue || null}
                    updateSelectedValue={(newValue) => {
                        setRuleConfig({
                            ...ruleConfig,
                            ...(!newValue && { criteriaType: null }),
                            left: ruleConfig.left ? updateValue(ruleConfig.left, newValue) : null,
                        });

                        // If a new value is selected then we should...
                        if (newValue && localValues !== null) {
                            // ...add it to our local values while we are still editing
                            setLocalValues([...localValues, newValue]);
                        }
                    }}
                    hasSubmitted={hasSubmitted}
                    identifier="left"
                />
            )}

            {/* CRITERIA */}
            <FormGroup
                labelId="left-criteria-label"
                label="Criteria"
                htmlFor="left-criteria"
                isValid={!isNullOrEmpty(ruleConfig?.criteriaType)}
                validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
                showValidation={hasSubmitted}
                isRequired
            >
                <Select
                    inputId="left-criteria"
                    className="select-field"
                    styles={getSharedStyles<{ label: string; value: string }>()}
                    options={leftContentTypeCriteriaOptions}
                    onChange={(selectedCriteria) =>
                        onChangeCriteria(selectedCriteria?.value as CriteriaType)
                    }
                    placeholder="Select a criteria"
                    value={getSelectedCriteria()}
                    noOptionsMessage={() => 'No results found'}
                />
            </FormGroup>

            {incompatibleCriteriaMsg && (
                <span className="help-block error-state" data-testid="rule-criteria-error">
                    {translations.PAGE_BUILDER_page_conditions_invalid_criteria}
                    <br />
                    {incompatibleCriteriaMsg}
                </span>
            )}

            {/* RIGHT */}
            <>
                <RadioToggle
                    options={toggleCategoryOptions}
                    selectedOption={rightSelector}
                    updateSelectedOption={onChangeRight}
                />

                <ComponentOrValueConfig
                    selectedConfigType={rightSelector}
                    componentOptions={getPageComponentOptions(pageComponents)}
                    propertyOptions={componentProperties}
                    selectedComponent={selectedRightComponent || null}
                    selectedProperty={selectedRightProperty || null}
                    selectedValue={selectedRightValue || null}
                    updateSelectedComponent={(newComponent) =>
                        setRuleConfig({
                            ...ruleConfig,
                            right: ruleConfig?.right
                                ? updateComponent(ruleConfig.right, newComponent)
                                : null,
                        })
                    }
                    updateSelectedProperty={(newProperty) =>
                        setRuleConfig({
                            ...ruleConfig,
                            right: ruleConfig?.right
                                ? updateProperty(ruleConfig?.right, newProperty)
                                : null,
                        })
                    }
                    updateSelectedValue={(newValue) => {
                        setRuleConfig({
                            ...ruleConfig,
                            right: ruleConfig?.right
                                ? updateValue(ruleConfig?.right, newValue)
                                : null,
                        });

                        // If a new value is selected then we should...
                        if (newValue && localValues !== null) {
                            // ...add it to our local values while we are still editing
                            setLocalValues([...localValues, newValue]);
                        }
                    }}
                    hasSubmitted={hasSubmitted}
                    identifier="right"
                    // Applying a filter so only compatible content types can be picked.
                    // If IS EMPTY is selected then default to 'ContentBoolean'.
                    contentTypeFilter={
                        ruleConfig?.criteriaType === 'IS_EMPTY'
                            ? 'ContentBoolean'
                            : leftContentTypeFilter
                    }
                />
            </>

            {incompatibleContentTypesMsg && (
                <span className="help-block error-state" data-testid="rule-type-error">
                    {translations.PAGE_BUILDER_page_conditions_invalid_content_types}
                    <br />
                    {incompatibleContentTypesMsg}
                </span>
            )}

            <br />

            <footer className="sidebar-mini-editor-footer">
                <ButtonDefault onClick={onCancel} title="Cancel rule">
                    Cancel
                </ButtonDefault>
                <ButtonPrimary
                    onClick={onApply}
                    title="Apply rule"
                    disabled={!!incompatibleCriteriaMsg || !!incompatibleContentTypesMsg}
                >
                    Apply
                </ButtonPrimary>
            </footer>
        </div>
    );
};

export default PageConditionRuleConfig;

import { pathOr, pick } from 'ramda';
import { useState } from 'react';
import Dependents from '../../Dependents';
import Loader from '../../../../ts/components/loader/Loader';
import Breadcrumb from '../../../components/Breadcrumb';
import type { ServiceElementFeatures, ServiceElementResponseAPI } from '../../../types/service';
import { isNullOrEmpty } from '../../../utils/guard';
import type { ServiceViews } from '../ServiceConstants';
import type { SaveService, SwitchToRefreshServiceConfig } from '../ServiceController';
import { useServices } from '../contextProviders/ServicesProvider';
import ConfigurationList from './ConfigurationList';
import ExistingActions from './ExistingActions';
import ExistingTypes from './ExistingTypes';
import FeatureList from './FeatureList';
import ServiceDetails from './ServiceDetails';

export interface ServiceEditorProps {
    tenantId: string;
    switchView: (view: ServiceViews) => void;
    switchToRefreshServiceConfig: SwitchToRefreshServiceConfig;
    saveService: SaveService;
    currentServiceData: ServiceElementResponseAPI;
}

const ServiceEditor = ({
    tenantId,
    switchView,
    switchToRefreshServiceConfig,
    saveService,
    currentServiceData,
}: ServiceEditorProps) => {
    const [hasSubmitted, updateHasSubmitted] = useState<boolean>(false);
    const { breadcrumbs, servicesState } = useServices();
    const { isLoading } = servicesState;
    const [serviceData, setServiceData] = useState<ServiceElementResponseAPI>(currentServiceData);

    const [configurationListItems, setConfigurationListItems] = useState(
        currentServiceData.configurationValues,
    );

    const {
        id,
        developerName,
        uri,
        httpAuthenticationUsername,
        httpAuthenticationPassword,
        httpAuthenticationClientCertificateReference,
        httpAuthenticationClientCertificatePasswordReference,
        developerSummary,
        identityProviderId,
    } = serviceData;

    const serviceFeatures: ServiceElementFeatures = pick(
        [
            'providesAutoBinding',
            'providesDatabase',
            'providesFiles',
            'providesIdentity',
            'providesLocation',
            'providesLogic',
            'providesSocial',
            'providesViews',
        ],
        currentServiceData,
    );

    const checkRequiredFields = () => ({
        developerName: developerName || null,
        uri: uri || null,
    });

    const onSave = () => {
        updateHasSubmitted(true);
        const isFormValid = Object.values(checkRequiredFields()).every(
            (field) => !isNullOrEmpty(field),
        );

        if (isFormValid) {
            saveService(
                developerName,
                uri,
                httpAuthenticationUsername,
                httpAuthenticationPassword,
                httpAuthenticationClientCertificateReference,
                httpAuthenticationClientCertificatePasswordReference,
                developerSummary,
                configurationListItems,
                identityProviderId,
            );
        }
    };

    const featuresSection = (
        <>
            <h2>Features</h2>
            <FeatureList serviceFeatures={serviceFeatures} />
        </>
    );
    const typesAndActionsSection = (
        <div className="flex-grow">
            <ExistingActions actions={pathOr([], ['actions'], currentServiceData)} />
            <ExistingTypes
                typeElements={pathOr([], ['install', 'typeElements'], currentServiceData)}
            />
        </div>
    );
    const primaryButton = (
        <>
            <button type="button" className="btn btn-primary outcome" onClick={onSave}>
                {'Save Connector'}
            </button>
            <button
                type="button"
                className="btn btn-success outcome"
                onClick={() =>
                    switchToRefreshServiceConfig(
                        developerName,
                        uri,
                        httpAuthenticationUsername,
                        httpAuthenticationPassword,
                        developerSummary,
                        serviceData.id,
                        httpAuthenticationClientCertificateReference,
                        httpAuthenticationClientCertificatePasswordReference,
                        identityProviderId,
                    )
                }
            >
                {'Refresh Connector Configuration Data'}
            </button>
        </>
    );

    // initially set loading while fetching each value individually
    const configurationList = (
        <ConfigurationList
            id={id}
            serviceName={serviceData.developerName ?? ''}
            configurationValues={configurationListItems ?? []}
            setConfigurationValues={setConfigurationListItems}
            url={uri}
            username={httpAuthenticationUsername}
            password={httpAuthenticationPassword}
            tenantId={tenantId}
            httpAuthenticationClientCertificateReference={
                httpAuthenticationClientCertificateReference
            }
            httpAuthenticationClientCertificatePasswordReference={
                httpAuthenticationClientCertificatePasswordReference
            }
        />
    );

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="full-height flex-column">
            <div className="admin-page">
                <div className="margin-bottom" data-testid="connector-breadcrumbs">
                    <Breadcrumb trail={breadcrumbs.trail} activeItemId={breadcrumbs.activeItemId} />
                </div>
                <h1>{`Connector: ${serviceData.developerName}`}</h1>
                <ServiceDetails
                    service={serviceData}
                    onChange={setServiceData}
                    hasSubmitted={hasSubmitted}
                />
                {featuresSection}
                {configurationList}
                {typesAndActionsSection}
                <Dependents id={id} />
            </div>
            <div className="admin-footer">
                <button
                    type="button"
                    className="btn btn-default outcome"
                    onClick={() => switchView(breadcrumbs.trail[breadcrumbs.trail.length - 2].id)}
                >
                    {'Back'}
                </button>
                {primaryButton}
            </div>
        </div>
    );
};

export default ServiceEditor;

import { connect } from 'react-redux';
import translations from '../../translations';
import TranslationExport from './TranslationExport';
import TranslationImport from './TranslationImport';
import {
    notifyError as notifyErrorAction,
    notifySuccess as notifySuccessAction,
} from '../../../js/actions/reduxActions/notification';
import type { FlowResponseAPI } from '../../types';

interface Props {
    flows: FlowResponseAPI[];
    notifyError: (error: unknown) => void;
    notifySuccess: (message: unknown) => void;
    container: HTMLElement | null | undefined;
    setIsProcessing: (isProcessing: boolean) => void;
}

const TranslationBase = ({
    flows,
    notifyError,
    notifySuccess,
    container,
    setIsProcessing,
}: Props) => {
    return (
        <>
            <h2>{translations.TRANSLATIONS_main_header}</h2>
            <TranslationExport
                flows={flows}
                notifyError={notifyError}
                setIsProcessing={setIsProcessing}
            />
            <TranslationImport
                notifyError={notifyError}
                notifySuccess={notifySuccess}
                container={container}
                setIsProcessing={setIsProcessing}
            />
        </>
    );
};

const mapStateToProps = ({ flows: { all } }: { flows: { all: FlowResponseAPI[] } }) => ({
    flows: all,
});

const mapDispatchToProps = {
    notifyError: notifyErrorAction,
    notifySuccess: notifySuccessAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TranslationBase);

export const decodeValueReference = (
    referenceText: string,
): { valueName: string | null; propertyName: string | null } => {
    // Found by pattern {![ text ]
    const re1 = /{!\[(.*?)].*/;
    const valueName = re1.test(referenceText) ? referenceText.replace(re1, '$1') : null;

    // Found by pattern .[ text ]
    const re2 = /.*\.\[(.*?)].*/;
    const propertyName = re2.test(referenceText) ? referenceText.replace(re2, '$1') : null;

    return {
        valueName,
        propertyName,
    };
};

import {
    ExChart,
    PillColor,
    ExLoader,
    LoaderVariant,
    LoaderSize,
    ExBadge,
    BadgeShape,
} from '@boomi/exosphere';
import { type ReactElement, useEffect, useState } from 'react';
import {
    getOutcomeEvents,
    type OutcomeEventsResponseApi,
    type TimePeriod,
    type OutcomeEvent,
} from '../../../sources/outcomeinsights';
import type { AddNotification } from '../../../types';

const Loader = ({
    children,
    outcomeId,
    isLoading,
    size,
    testId,
}: {
    children: ReactElement;
    outcomeId: string | undefined;
    isLoading: boolean;
    size: LoaderSize;
    testId: string;
}) => {
    if (!outcomeId || isLoading) {
        return <ExLoader data-testid={testId} size={size} variant={LoaderVariant.GENERAL_LOADER} />;
    }

    return children;
};

interface Props {
    outcomeId: string | undefined;
    outcomeName: string | undefined;
    mapElementId: string;
    flowId: string;
    timePeriod: TimePeriod;
    addNotification: AddNotification;
}

interface Event {
    x: number | string;
    y: number;
    z: string;
}

const formatTimeTo12HourNotation = (timestamp: number): string => {
    const date_obj = new Date(timestamp * 1000);
    return date_obj
        .toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'UTC',
        })
        .toLowerCase();
};

const getHours = (timestamp: number): string => {
    return formatTimeTo12HourNotation(timestamp);
};

const getDay = (timestamp: number): string => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(timestamp * 1000);
    return days[date.getUTCDay()];
};

const getDate = (timestamp: number): string => {
    const getOrdinalDate = (date: number) => {
        if (date > 3 && date < 21) {
            return `${date}th`;
        }
        switch (date % 10) {
            case 1:
                return `${date}st`;
            case 2:
                return `${date}nd`;
            case 3:
                return `${date}rd`;
            default:
                return `${date}th`;
        }
    };

    const date = new Date(timestamp * 1000);
    return getOrdinalDate(date.getUTCDate());
};

const formatXaxisLabels = {
    day: getHours,
    week: getDay,
    month: getDate,
};

const legendFormatCurrentPeriod = {
    day: 'Today',
    week: 'This Week',
    month: 'This Month',
};

const legendFormatPreviousPeriod = {
    day: 'Yesterday',
    week: 'Last Week',
    month: 'Last Month',
};

export const formatDataPoint = (
    event: OutcomeEvent,
    timePeriod: TimePeriod,
    isCurrentPeriod: boolean,
) => ({
    x: formatXaxisLabels[timePeriod](event.timestamp),
    y: event.count,
    z: isCurrentPeriod
        ? legendFormatCurrentPeriod[timePeriod]
        : legendFormatPreviousPeriod[timePeriod],
});

const OutcomeInsightsChart = ({
    outcomeId,
    outcomeName,
    mapElementId,
    flowId,
    timePeriod,
    addNotification,
}: Props) => {
    const [eventsToDisplay, setEventsToDisplay] = useState<OutcomeEventsResponseApi>({
        currentPeriodEvents: [],
        previousPeriodEvents: [],
    });

    const [isLoading, setIsLoading] = useState(false);

    const tooltipSubheader = {
        day: 'daily',
        week: 'weekly',
        month: 'monthly',
    };

    const totalEventsCurrent = eventsToDisplay
        ? eventsToDisplay.currentPeriodEvents.reduce((acc, curr) => acc + curr.count, 0)
        : 0;

    const totalEventsPrevious = eventsToDisplay
        ? eventsToDisplay.previousPeriodEvents.reduce((acc, curr) => acc + curr.count, 0)
        : 0;

    const difference = Math.abs(totalEventsCurrent - totalEventsPrevious);

    const currentPeriod: Event[] = eventsToDisplay
        ? eventsToDisplay.currentPeriodEvents.map((event) =>
              formatDataPoint(event, timePeriod, true),
          )
        : [];

    const previousPeriod: Event[] = eventsToDisplay
        ? eventsToDisplay.previousPeriodEvents.map((event) =>
              formatDataPoint(event, timePeriod, false),
          )
        : [];

    const currentPeriodLastIndex = currentPeriod.length;
    const sliceOfPreviousPeriod = previousPeriod.slice(currentPeriodLastIndex);
    const slicedPeriodReset = sliceOfPreviousPeriod.map((slice) => ({
        ...slice,
        y: 0,
        z: legendFormatCurrentPeriod[timePeriod],
    }));

    const result = [...currentPeriod, ...slicedPeriodReset];

    const data = [...previousPeriod, ...result];

    // Exosphere charts are not responsive
    const width = document.getElementById('outcome-insights')?.getBoundingClientRect().width || 800;

    const customization = {
        [legendFormatCurrentPeriod[timePeriod]]: {
            color: '#107a86',
        },
        [legendFormatPreviousPeriod[timePeriod]]: {
            color: '#de6854',
        },
    };

    const config = {
        type: 'line-graph',
        scaleX: {
            type: 'ordinal',
        },
        width,
        height: 320,
        data,
        showLegends: true,
        tooltip: {
            subheader: `Comparing ${tooltipSubheader[timePeriod]} events`,
        },
        customization,
    };

    const fetchOutcomeEvents = async () => {
        if (outcomeId) {
            setIsLoading(true);
            try {
                const events = await getOutcomeEvents({
                    payload: {
                        timePeriod,
                        outcome: outcomeId,
                        mapElement: mapElementId,
                        flow: flowId,
                    },
                });
                setEventsToDisplay(events);
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).toString(),
                    isPersistent: true,
                });
            } finally {
                setIsLoading(false);
            }
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        fetchOutcomeEvents();
    }, [timePeriod, outcomeId]);

    const hasDiffIncreased =
        totalEventsCurrent >= totalEventsPrevious || totalEventsCurrent === totalEventsPrevious;

    const pillProps = {
        icon:
            hasDiffIncreased && difference > 0
                ? 'direction-caret-up'
                : difference === 0
                  ? ''
                  : 'direction-caret-down',
        color:
            hasDiffIncreased && difference > 0
                ? PillColor.GREEN
                : difference === 0
                  ? PillColor.GRAY
                  : PillColor.RED,
        shape: BadgeShape.ROUND,
    };

    return (
        <>
            <Loader
                outcomeId={outcomeId}
                isLoading={isLoading}
                size={LoaderSize.SMALL}
                testId="heading-loader"
            >
                <div className="flex align-center gap-x-large">
                    <h4 className="flex-grow">
                        Trend of events for outcome &quot;{outcomeName}&quot; over time (UTC)
                    </h4>
                    <div className="flex align-center nowrap">
                        <h5>Total events:</h5>
                        <span data-testid="total" className="margin-left-sml total-events">
                            {totalEventsCurrent}
                        </span>
                        <ExBadge data-testid="difference" {...pillProps}>
                            {difference}
                        </ExBadge>
                    </div>
                </div>
            </Loader>

            <Loader
                outcomeId={outcomeId}
                isLoading={isLoading}
                size={LoaderSize.XLARGE}
                testId="chart-loader"
            >
                <div className="flex">
                    <ExChart options={config} />
                </div>
            </Loader>
        </>
    );
};
export default OutcomeInsightsChart;

import { NOTIFICATION_TYPES } from '../../../ts/constants';
import actionTypes from './actionTypes';
import { guid } from '../../../ts/utils/guid';
import { tryGetErrorMessage } from '../../../ts/utils';

const addId = (notification) => ({
    ...notification,
    id: guid(),
});

export const removeAllNotifications = () => ({
    type: actionTypes.REMOVE_ALL_NOTIFICATIONS,
});

export const removeNotification = (id) => ({
    type: actionTypes.NOTIFICATION_REMOVE,
    payload: id,
});

export const addNotification = (notification) => (dispatch) => {
    const newNotification = addId(notification);

    if (!newNotification.isPersistent) {
        setTimeout(() => dispatch(removeNotification(newNotification.id)), 5000);
    }

    dispatch({
        type: actionTypes.NOTIFICATION_ADD,
        payload: {
            ...newNotification,
        },
    });
};

export const notifyError = (error) => (dispatch) => {
    dispatch(
        addNotification({
            type: NOTIFICATION_TYPES.error,
            isPersistent: true,
            message: tryGetErrorMessage(error),
        }),
    );
};

export const notifySuccess = (message) => (dispatch) => {
    dispatch(
        addNotification({
            type: NOTIFICATION_TYPES.success,
            isPersistent: false,
            message,
        }),
    );
};

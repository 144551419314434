import classNames from 'classnames';

const Loader = ({ message, small = false }: { message?: string; small?: boolean }) => {
    const containerClasses = classNames({
        'wait-container': true,
        small: small === true,
    });

    return (
        <div data-testid="loader" className={containerClasses}>
            <div className="wait-spinner" />
            <span className="wait-message">{message}</span>
        </div>
    );
};

export default Loader;

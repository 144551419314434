import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

interface Props {
    attributes: { [key: string]: string };
    width: number;
    height: number;
    label: string;
}

const Iframe = ({ attributes, width, height, label }: Props) => {
    const widthDisplay = typeof width === 'number' && width > 0 ? `${width}px` : '100%';
    const heightDisplay = typeof height === 'number' ? `${height}px` : '0px';

    const source = attributes?.['$src'];
    const sourceIsString = typeof source === 'string';

    return (
        <div data-testid="component-preview-iframe">
            <PreviewComponentLabel labelText={label} />
            <div className="component-preview-iframe-container">
                <div className="component-preview-iframe-header">
                    {sourceIsString && <span>{`${source} `}</span>}
                    <span className="quiet">
                        <i>
                            ({widthDisplay} x {heightDisplay})
                        </i>
                    </span>
                </div>
                <div className="component-preview-iframe-skeleton">
                    <span />
                    <span />
                    <span />
                </div>
            </div>
        </div>
    );
};
export default Iframe;

import { getValue, saveValue } from '../../../sources/value';
import { SYSTEM_VALUE_IDS } from '../../../constants/system';
import { useEffect, useState } from 'react';
import ValueEditor, { isDuplicateValueByName, isValid } from './ValueEditor';
import {
    AlertBannerType,
    ButtonFlavor,
    ButtonType,
    ExAlertBanner,
    ExButton,
    ExLoader,
} from '@boomi/exosphere';
import translations from '../../../translations';
import GenericModal from '../../generic/modal/GenericModal';
import type { ContentType, ValueElementResponseAPI } from '../../../types';
import { stringReplace } from '../../../utils/string';

interface Props {
    id?: string | undefined | null;
    title: string;
    container: HTMLElement | null | undefined;
    onCancel: () => void;
    onSave: (value: ValueElementResponseAPI) => void;
    startingValueName?: string | undefined;
    startingValueContentType?: ContentType | undefined;
    startingValueTypeElementId?: string | undefined | null;
}

const initialValue = {
    id: '',
    developerName: '',
    developerSummary: '',
    isEncrypted: false,
    isVersionless: false,
    isSecret: false,
    access: 'PRIVATE',
    contentType: 'ContentString',
    contentFormat: '',
    defaultContentValue: null,
    defaultObjectData: [],
    initializationOperations: [],
    typeElementId: null,
    typeElementDeveloperName: null,
    updateByName: false,
    dateCreated: '',
    dateModified: '',
    elementType: 'VARIABLE',
    whoCreated: null,
    whoModified: null,
    whoOwner: null,
    hasValue: false,
};

const ValueEditorModal = ({
    id,
    title,
    container,
    startingValueName,
    startingValueContentType,
    startingValueTypeElementId,
    onCancel,
    onSave,
}: Props) => {
    const [value, setValue] = useState<ValueElementResponseAPI>({
        ...initialValue,
        developerName: startingValueName || '',
        contentType: startingValueContentType || 'ContentString',
        typeElementId: startingValueTypeElementId || null,
    });
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [duplicateWarning, setDuplicateWarning] = useState<boolean>(false);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Requires dedicated refactor
    useEffect(() => {
        const load = async () => {
            if (!id) {
                return;
            }

            setSaving(true);

            try {
                setValue(await getValue(id));
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setSaving(false);
            }
        };

        load();
    }, []);

    const onSubmit = async () => {
        setError(null);
        setDuplicateWarning(false);

        if (SYSTEM_VALUE_IDS.includes(value.id)) {
            setError(translations.VALUE_EDITOR_system_value_error);
        } else {
            setHasSubmitted(true);

            if (isValid(value)) {
                setSaving(true);

                try {
                    if (await isDuplicateValueByName(value)) {
                        setDuplicateWarning(true);
                        return;
                    }
                } catch (error) {
                    setError((error as Error).message);
                } finally {
                    setSaving(false);
                }

                await onSaveValue();
            }
        }
    };

    const onSaveValue = async () => {
        setSaving(true);

        try {
            const response = await saveValue(value);
            onSave(response);
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setSaving(false);
        }
    };

    return (
        <GenericModal
            onHide={onCancel}
            className="value-editor-modal"
            title={title}
            renderBody={() => {
                return (
                    <>
                        {error ? (
                            <ExAlertBanner type={AlertBannerType.ERROR} open={true}>
                                {error}
                            </ExAlertBanner>
                        ) : null}
                        {duplicateWarning ? (
                            <ExAlertBanner type={AlertBannerType.ERROR} open={true}>
                                {stringReplace(
                                    translations.VS_duplicate_value_1,
                                    value.developerName.trim(),
                                )}
                            </ExAlertBanner>
                        ) : null}
                        {value ? (
                            <ValueEditor
                                value={value}
                                onChange={setValue}
                                showValidation={hasSubmitted}
                            />
                        ) : null}
                        {saving ? <ExLoader /> : null}
                    </>
                );
            }}
            renderFooter={() => {
                return (
                    <>
                        <ExButton
                            onClick={onCancel}
                            flavor={ButtonFlavor.BRANDED}
                            type={ButtonType.SECONDARY}
                        >
                            {translations.COMMON_cancel}
                        </ExButton>
                        <ExButton
                            onClick={onSubmit}
                            flavor={ButtonFlavor.BRANDED}
                            type={ButtonType.PRIMARY}
                        >
                            {translations.COMMON_save}
                        </ExButton>
                    </>
                );
            }}
            container={container}
        />
    );
};

export default ValueEditorModal;

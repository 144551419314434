import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const typeElements = (types = { isLoading: false, types: [] }, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_TYPES:
            return {
                ...types,
                types: payload,
                isLoading: false,
            };

        case actionTypes.LOADED_TYPES:
            return {
                ...types,
                isLoading: false,
            };

        case actionTypes.LOADING_TYPES:
            return {
                ...types,
                isLoading: true,
            };

        default:
            return types;
    }
};

export default typeElements;

import { pathOr } from 'ramda';
import store from '../../js/store/store';
import type { FeatureFlags } from '../types';

export const getFlag = (flagName: string) => {
    // Did not want this inside an action, as want to avoid the impact of
    // every component that already calls getFlag needing to be wrapped in a connect.
    const { flags } = store.getState().featureFlags as FeatureFlags;
    return pathOr(false, ['enabled'], flags[flagName]);
};

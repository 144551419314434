import { sortPropertyByTypeElementDeveloperName } from '../../sources/type';
import type { TypeElementResponseAPI, TypeMappingAutoAPI } from '../../types';
import PropertyDetails from './PropertyDetails';

type Types = Record<string, TypeElementResponseAPI>;

interface Props {
    sourceTypeElementId: string;
    types: Types;
    mapping: TypeMappingAutoAPI | null | undefined;
}

const AutoMappingType = ({ sourceTypeElementId, types, mapping }: Props) => {
    const sourceType = types[sourceTypeElementId];

    return (
        <ol key={sourceType.id}>
            {sourceType.properties.sort(sortPropertyByTypeElementDeveloperName).map((property) => {
                const mappedProperty = mapping?.properties.find(
                    (p) => p.sourceTypeElementEntryId === property.id,
                );

                return (
                    <li key={property.id}>
                        <PropertyDetails
                            property={property}
                            types={types}
                            isMatched={!!mappedProperty}
                            showMatch={true}
                        />
                        {property.typeElementId ? (
                            <AutoMappingType
                                sourceTypeElementId={property.typeElementId}
                                types={types}
                                mapping={mappedProperty?.children}
                            />
                        ) : null}
                    </li>
                );
            })}
        </ol>
    );
};

export default AutoMappingType;

import '../../../../css/buttons.less';
import type { ButtonProps } from '../../types';

const ButtonPrimary = ({ onClick, testId, className, children, ...otherProps }: ButtonProps) => (
    <button
        type="button"
        onClick={onClick}
        data-testid={testId}
        className={`btn btn-primary ${className ?? ''}`}
        {...otherProps}
    >
        {children}
    </button>
);

export default ButtonPrimary;

import { type ConnectedProps, connect } from 'react-redux';
import { fetchReceivedTenantInvitations } from '../../../js/actions/reduxActions/organizations';
import {
    type OrganizationInvite,
    acceptTenantInvite,
    rejectTenantInvite,
} from '../../sources/organization';
import translations from '../../translations';
import { formatTenantName, stringReplace } from '../../utils';
import { useAuth } from '../AuthProvider';
import Loader from '../loader/Loader';
import ReceivedInvites from './ReceivedInvites';

type CurrentTenantInviteList = ConnectedProps<typeof connector>;

const mapStateToProps = (state: {
    organizationReceivedTenantInvitations: OrganizationInvite[];
}) => ({
    invitations: state.organizationReceivedTenantInvitations,
});

const mapDispatchToProps = {
    fetchReceivedTenantInvitations,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const CurrentTenantInviteList = ({
    invitations,
    fetchReceivedTenantInvitations,
}: CurrentTenantInviteList) => {
    const { tenant, fetchUser } = useAuth();

    const matchTenantInvite = (invite: OrganizationInvite) => {
        if (!tenant?.id) {
            return false;
        }

        if (invite.type !== 'tenant') {
            return false;
        }

        if (invite.tenant.id !== tenant.id) {
            return false;
        }

        return true;
    };

    const handleAccept = async (id: string) => {
        await acceptTenantInvite({
            organizationId: id,
        });
    };

    const handleReject = async (id: string) => {
        await rejectTenantInvite({
            organizationId: id,
        });
    };

    const handleRefresh = async () => {
        await Promise.all([fetchUser(), fetchReceivedTenantInvitations()]);
    };

    if (!tenant) {
        return <Loader />;
    }

    return (
        <>
            <p className="description">{translations.FORG_tenant_invitations_description}</p>
            <ReceivedInvites
                caption={stringReplace(translations.FORG_received_tenant_invites_table_caption, {
                    tenantName: formatTenantName(tenant.developerName),
                })}
                invitations={invitations}
                loadInvitations={fetchReceivedTenantInvitations}
                matchInvite={matchTenantInvite}
                onAccept={handleAccept}
                onReject={handleReject}
                onRefresh={handleRefresh}
            />
        </>
    );
};

export default connector(CurrentTenantInviteList);

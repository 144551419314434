import { concat, lensPath, pathOr, set, assocPath, append } from 'ramda';
import actionTypes from '../actions/reduxActions/actionTypes';

export const changeSecuritySetting = ({ tenant, settingName, value }) =>
    assocPath(['securitySettings', settingName], value, tenant);

export const changeSAMLSetting = ({ tenant, settingPath, value }) =>
    assocPath(concat(['securitySettings', 'samlSettings'], settingPath), value, tenant);

export const changeOTLPSetting = ({ tenant, settingPath, value }) =>
    assocPath(concat(['observabilitySettings'], settingPath), value, tenant);

export const updateDeveloperName = ({ tenant, developerName }) => ({
    ...tenant,
    developerName,
});

export const updateSummary = ({ tenant, developerSummary }) => ({
    ...tenant,
    developerSummary,
});

export const updateIPEntry = ({ tenant, IPRange, index, rangeEntry }) => {
    const entryPath = ['securitySettings', IPRange, index];
    const entryLens = lensPath(entryPath);
    const currentEntry = pathOr(false, entryPath, tenant);

    if (currentEntry) {
        // Replace existing entry
        return set(entryLens, rangeEntry, tenant);
    }

    // Entry doesn't exist yet
    const rangePath = ['securitySettings', IPRange];
    const IPRangeLens = lensPath(rangePath);
    const currentIPRange = pathOr([], rangePath, tenant); // range may not exist
    const updatedIPRange = [...currentIPRange, rangeEntry];

    return set(IPRangeLens, updatedIPRange, tenant);
};

export const addIPRange = ({ tenant, IPRange, rangeEntry }) => {
    const rangeEntries = pathOr([], ['securitySettings', IPRange], tenant);

    const newRangeEntries = append(rangeEntry, rangeEntries);

    return assocPath(['securitySettings', IPRange], newRangeEntries, tenant);
};

export const removeIPRange = ({ tenant, IPRange, index }) => {
    const newRangeEntries = tenant.securitySettings[IPRange].filter(
        (_, currentIndex) => index !== currentIndex,
    );

    const IPRangeLens = lensPath(['securitySettings', IPRange]);

    return set(IPRangeLens, newRangeEntries, tenant);
};

export const updateServiceInvokerLogging = ({ tenant, isActive }) =>
    assocPath(['tenantSettings', 'disableServiceInvokerLogging'], isActive === false, tenant);

export const updateReportingEndpoint = ({ tenant, endpoint }) =>
    assocPath(['stateSettings', 'endpoint'], endpoint, tenant);

export const updateEnvironmentSetting = ({ tenant, isActive }) =>
    assocPath(['tenantSettings', 'environments'], isActive, tenant);

export const updateThemeSetting = ({ tenant, isActive }) =>
    assocPath(['tenantSettings', 'themes'], isActive, tenant);

export const addIntegrationAccount = ({ tenant, username, accountId, apiKey }) =>
    assocPath(
        ['tenantSettings', 'integrationAccounts'],
        tenant.tenantSettings.integrationAccounts.concat([
            { username, accountId, apiKey: apiKey.substring(0, 6) },
        ]),
        tenant,
    );

export const removeIntegrationAccount = ({ tenant, accountId }) =>
    assocPath(
        ['tenantSettings', 'integrationAccounts'],
        tenant.tenantSettings.integrationAccounts.filter(
            (account) => account.accountId !== accountId,
        ),
        tenant,
    );

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const currentTenant = (tenant = null, { type, payload }) => {
    switch (type) {
        case actionTypes.CURRENT_TENANT_SET:
            return payload;

        case actionTypes.CURRENT_TENANT_SECURITY_SETTING_UPDATE:
            return changeSecuritySetting({ tenant, ...payload });

        case actionTypes.CURRENT_TENANT_SAML_SETTING_UPDATE:
            return changeSAMLSetting({ tenant, ...payload });

        case actionTypes.CURRENT_TENANT_OTLP_SETTING_UPDATE:
            return changeOTLPSetting({ tenant, ...payload });

        case actionTypes.CURRENT_TENANT_DEVELOPER_NAME_UPDATE:
            return updateDeveloperName({ tenant, developerName: payload });

        case actionTypes.CURRENT_TENANT_SUMMARY_UPDATE:
            return updateSummary({ tenant, developerSummary: payload });

        case actionTypes.CURRENT_TENANT_IP_ENTRY_CONFIRM:
            return updateIPEntry({ tenant, ...payload });

        case actionTypes.CURRENT_TENANT_IP_RANGE_ADD:
            return addIPRange({ tenant, ...payload });

        case actionTypes.CURRENT_TENANT_IP_RANGE_REMOVE:
            return removeIPRange({ tenant, ...payload });

        case actionTypes.CURRENT_TENANT_SERVICE_INVOKER_LOGGING_UPDATE:
            return updateServiceInvokerLogging({ tenant, isActive: payload });

        case actionTypes.CURRENT_TENANT_REPORTING_ENDPOINT_UPDATE:
            return updateReportingEndpoint({ tenant, endpoint: payload });

        case actionTypes.CURRENT_TENANT_ENVIRONMENT_SETTING_UPDATE:
            return updateEnvironmentSetting({ tenant, isActive: payload });

        case actionTypes.CURRENT_TENANT_THEME_SETTING_UPDATE:
            return updateThemeSetting({ tenant, isActive: payload });

        case actionTypes.CURRENT_TENANT_INTEGRATION_ACCOUNT_ADD:
            return addIntegrationAccount({ tenant, ...payload });

        case actionTypes.CURRENT_TENANT_INTEGRATION_ACCOUNT_REMOVE:
            return removeIntegrationAccount({ tenant, accountId: payload });

        default:
            return tenant;
    }
};

export default currentTenant;

import actionTypes from './actionTypes';
import { addNotification } from './notification';

export const fetchAPIEndpoints = () => async (dispatch) => {
    let endpoints = {};

    dispatch({
        type: actionTypes.FETCH_API_ENDPOINTS,
    });

    try {
        const swaggerDefinitionResponse = await fetch(
            `${window.location.protocol}//${window.location.host}/swagger/v1/swagger.json`,
        ).then((response) => {
            if (response.ok) {
                return response;
            }
            // fallback to production definition
            return fetch('https://flow.manywho.com/swagger/v1/swagger.json');
        });

        const definition = await swaggerDefinitionResponse.json();

        endpoints = definition.paths;
    } catch (_e) {
        dispatch(
            addNotification({
                type: 'error',
                message: 'Cannot retrieve API definition',
            }),
        );
    }

    return dispatch({
        type: actionTypes.SET_API_ENDPOINTS,
        payload: endpoints,
    });
};

export const setFlow = (id, versionId, name, editingToken) => (dispatch) =>
    dispatch({
        type: actionTypes.SET_SELECTED_FLOW,
        payload: { id, versionId, name, editingToken },
    });

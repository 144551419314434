const NavigationIcon = ({ size = '1em', ...otherProps }) => (
    // biome-ignore lint/a11y/noSvgWithoutTitle: Including a title breaks tests, requires dedicated refactor
    <svg
        width={size}
        height={size}
        {...otherProps}
        viewBox="0 0 25 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.65 13.2584H1.35V16.5584H23.65V13.2584ZM0 11.9084V17.9084H24C24.5523 17.9084 25 17.4607 25 16.9084V12.9084C25 12.3561 24.5523 11.9084 24 11.9084H0Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.35 1.35V11.45H3.65V1.35H1.35ZM1 0C0.447715 0 0 0.447716 0 1V12.8H5V1C5 0.447715 4.55228 0 4 0H1Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.35 1.35V6.65H10.65V1.35H8.35ZM8 0C7.44772 0 7 0.447715 7 1V7C7 7.55229 7.44772 8 8 8H11C11.5523 8 12 7.55229 12 7V1C12 0.447715 11.5523 0 11 0H8Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 17L4 13L5 13L5 17L4 17Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 17L8 13L9 13L9 17L8 17Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 17L12 13L13 13L13 17L12 17Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 17L16 13L17 13L17 17L16 17Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 17L20 13L21 13L21 17L20 17Z"
            fill="currentColor"
        />
    </svg>
);

export default NavigationIcon;

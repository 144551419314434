import type { PageComponentPreviewProps } from '../../../../../types';
import PreviewComponentLabel from '../previewComponentLabel/PreviewComponentLabel';
import { Signature } from '@phosphor-icons/react';

const SignaturePad = ({ label, isRequired }: PageComponentPreviewProps) => {
    return (
        <div
            data-testid="component-preview-signature-pad"
            className="component-preview-signature-pad"
        >
            <PreviewComponentLabel
                className="component-preview-signature-pad-label"
                labelText={label}
                isRequired={isRequired}
            />
            <div
                data-testid="component-preview-signature-pad-mock"
                className="component-preview-signature-pad-mock"
            >
                <Signature
                    data-testid="component-preview-signature-pad-mock-icon"
                    className="component-preview-signature-pad-mock-icon"
                    weight="thin"
                    size={192}
                />
            </div>
        </div>
    );
};

export default SignaturePad;

import { useState } from 'react';
import { HOSTS } from '../../ts/constants';
import { Routes, Route } from 'react-router-dom';
import GovernmentPolicy from './authentication/GovernmentPolicy';

import Tooling from './Tooling';
import SsoLoggedOut from './SsoLoggedOut';
import ProvisionTenant from './tenant/ProvisionTenant';

import '../../../css/global-styles.less';
import Loader from './loader/Loader';

interface Props {
    email: string | undefined;
    tenantId?: string | undefined;
}

/**
 * The Draw component contains the base Router for the application. This enables the following url
 * routing hierarchy:
 *  - /
 *      - /:tenantId
 *          - /:tenantId/:adminTab
 *          - /:tenantId/flows/:flowId
 *          - /:tenantId/pages/:pageId
 */

const Draw = ({ email, tenantId }: Props) => {
    const [isGovernmentPolicyAccepted, setIsGovernmentPolicyAccepted] = useState(false);
    const isGovernmentUrl = window.location.hostname === HOSTS.federal;

    const acceptGovernmentPolicy = () => setIsGovernmentPolicyAccepted(true);

    if (!email) {
        if (isGovernmentPolicyAccepted === false && isGovernmentUrl) {
            return <GovernmentPolicy acceptGovernmentPolicy={acceptGovernmentPolicy} />;
        }

        return (
            <Routes>
                <Route path="/logged-out" element={<SsoLoggedOut />} />
                <Route
                    path="/user-not-found"
                    element={<ProvisionTenant container={document.getElementById('root')} />}
                />
                <Route path="/*" element={<Loader />} />
            </Routes>
        );
    }

    return (
        <>
            <Routes>
                <Route path="/*" element={<Tooling currentLoggedInTenantId={tenantId} />} />
            </Routes>
            <div id="generic-modal" />
        </>
    );
};

export default Draw;

interface Props {
    error: string | null;
    retry: () => void;
    environmentsEnabled: boolean;
    isMCR: boolean;
}

const ActivateFailed = ({ error, retry, environmentsEnabled, isMCR }: Props) => {
    return (
        <>
            {isMCR ? (
                <h3>Acknowledgement not received from MCR node</h3>
            ) : (
                <h3>{environmentsEnabled ? 'Build Failed' : 'Publish Failed'}</h3>
            )}
            <p>{error}</p>
            <button className="btn btn-primary" onClick={retry} type="button">
                Retry
            </button>
        </>
    );
};

export default ActivateFailed;

import { CheckStatus, parse } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';

export interface AnomalyResponse {
    tenantId: string;
    environmentId: string;
    flowId: string;
    flowVersionId: string;
    flowName: string;
    stateId: string;
    dateTime: Date;
    isAnomalous: boolean;
    anomalyType: string;
    eventValue: number;
    deviation: number;
    mapElementId: string;
    mapElementName: string;
    latestErrorMessage?: string;
    latestErrorStatusCode?: number;
    latestErrorUri?: string;
}

export interface LimitReachedError {
    wasLimitReached: boolean;
    message: string;
}

export const getAnomalyEvents = async () => {
    const response = await fetch('/api/anomalyDetection', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
    });

    if (response.status === 400) {
        const message = response.statusText;

        if (message.startsWith('Anomaly Detection calculations have been cancelled')) {
            throw {
                wasLimitReached: true,
                message,
            } as LimitReachedError;
        }
    }

    return (await parse(await CheckStatus(response))) as AnomalyResponse[];
};

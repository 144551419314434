import { addPositions } from '../../../../ts/components/graph/utils';
import {
    GRAPH_ELEMENT_TYPES,
    MAP_ELEMENT_CONFIGS,
    MAP_ELEMENT_TYPES,
    NOTIFICATION_TYPES,
} from '../../../../ts/constants';
import { getByID } from '../../../../ts/utils/collection';
import { safeToLower } from '../../../../ts/utils/string';
import {
    getParentOffset,
    getParents,
    hasSelectedAncestors,
    highlightMapElement,
    transformPositionToScreen,
    zoomToFit,
} from '../utils';
import { getElementStyles } from './elementStyles';

export const sharedGraphElementProps = ({
    canvasNotes,
    developerName,
    dragType,
    dragging,
    editingToken,
    element,
    elementType,
    flowId,
    groupElements,
    hoveringElementId,
    id,
    openConfig,
    openMetadataEditor,
    searchQueries,
    selectedElementIds,
    setDraggingData,
    setHoveringElementID,
    setSelectedElementIds,
    setSelectedOutcome,
    toggleNoteVisibility,
    toggleSelectedElementId,
    highlightedElementIds,
    setHighlightedElementIds,
    highlightGroupElement,
    x,
    y,
    addNotification,
    setDynamicFocus,
    contextMenu,
    setContextMenuData,
    zoomViewBox,
    graphElement,
    autoArrangeGroup,
}) => {
    let parents = [];
    try {
        parents = getParents(element, groupElements);
    } catch (err) {
        addNotification({
            type: NOTIFICATION_TYPES.error,
            message: err.message,
            isPersistent: true,
        });
    }
    const parentOffset = getParentOffset(parents);
    const noteContent = getByID(id, canvasNotes)?.meta?.userContent;
    const isOutOfSearch =
        highlightedElementIds.length > 0
            ? !highlightedElementIds.includes(id)
            : searchQueries[flowId] &&
              !safeToLower(developerName).includes(searchQueries[flowId]) &&
              !(noteContent && safeToLower(noteContent).includes(searchQueries[flowId]));

    return {
        startDrag: (e, usingKeyboard = false) => {
            if (usingKeyboard && e.ctrlKey) {
                // This element is being unselected
                if (selectedElementIds.includes(id)) {
                    // Set dragging elementId to null if it's already the dragging element and there are other elements left selected.
                    if (dragging?.elementId === id && selectedElementIds.length > 1) {
                        setDraggingData({
                            dragType,
                            elementId: null,
                            previousElementPosition: null,
                            previousMousePosition: null,
                            usingKeyboard,
                        });
                    }
                    // If this element is the only one selected then simply set dragging data to null
                    else {
                        setDraggingData(null);
                    }

                    return;
                }
                const selectIds = [...selectedElementIds, id]; // startDrag doesn't happen before onSelect so simulate the selection.
                // Don't start dragging if any of this elements ancestors up the chain are also selected.
                if (hasSelectedAncestors(element, groupElements, selectIds)) {
                    return;
                }
            }
            // If the user is using with the mouse and not left clicking then don't affect dragging as we only change selection when they left click
            // Also prevent the user from starting keyboard dragging if they intended to open metadata
            if ((!usingKeyboard && e.button !== 0) || e.shiftKey) {
                return;
            }
            // If the user is left clicking,
            // and holding alt,
            // then this is the shortcut to highlight this element
            if (e.button === 0 && e.altKey) {
                dragType === GRAPH_ELEMENT_TYPES.map
                    ? highlightMapElement(element, setHighlightedElementIds)
                    : highlightGroupElement(element);
                return;
            }
            setDraggingData({
                dragType,
                elementId: id,
                previousElementPosition: { x, y },
                previousMousePosition: { x: e.clientX, y: e.clientY },
                usingKeyboard,
            });
        },
        onSelect: (e, selectedElementIds) => {
            setDynamicFocus(id);
            setSelectedOutcome();
            // Open metadata editor
            e.shiftKey
                ? openMetadataEditor(id, dragType)
                : // If you press control, multi select/deselect
                  e.ctrlKey
                  ? toggleSelectedElementId(id, selectedElementIds)
                  : // If you are moving this element, not just clicking, don't alter selections
                    (dragging?.hasMovedEnough && selectedElementIds.includes(id)) ||
                      (selectedElementIds.includes(id) && e.button === 2)
                    ? null
                    : // If you are just clicking on this element, select it and only it
                      setSelectedElementIds([id]);
        },
        onCancelDrag: () => setDraggingData(null),
        onOver: () => setHoveringElementID(id),
        onOut: () => setHoveringElementID(null),
        isSelected: selectedElementIds.includes(id),
        selectedElementIds: selectedElementIds,
        isHovered: hoveringElementId === id,
        isDragging: dragging && dragging.elementId === id,
        id,
        x: x + parentOffset.x,
        y: y + parentOffset.y,
        keyboardDragging: dragging?.usingKeyboard,
        isOutOfSearch,
        developerName,
        onDelete:
            safeToLower(elementType) === MAP_ELEMENT_TYPES.start
                ? // Starts cannot be deleted
                  undefined
                : () =>
                      openConfig(MAP_ELEMENT_CONFIGS.multiDelete, {
                          elementId: id,
                      }),
        elementType,
        flowId,
        editingToken,
        onDoubleClick: (event, usingKeyboard = false) => {
            if (usingKeyboard && selectedElementIds.length > 1) {
                addNotification({
                    type: 'error',
                    message:
                        'Cannot open elements while multi-select is in use. Press Escape to clear selection.',
                    isPersistent: true,
                });
                return;
            }
            if (
                [MAP_ELEMENT_TYPES.return, MAP_ELEMENT_TYPES.start].includes(
                    safeToLower(elementType),
                ) ||
                event.shiftKey
            ) {
                return;
            }

            if (safeToLower(elementType) === MAP_ELEMENT_TYPES.note && !event.ctrlKey) {
                toggleNoteVisibility(id);
                return;
            }

            openConfig(elementType, {
                id,
                elementType,
                x,
                y,
            });
        },
        onToggleContextMenu: (cancel = false) => {
            // Context menu isn't open or it's open on a different element.
            // Optional param for closing the context menu regardless of anything else.
            if (!cancel && (!contextMenu || contextMenu.activeElement.id !== id)) {
                const isMapElement = dragType === GRAPH_ELEMENT_TYPES.map;
                const groupElementIds = getParents(groupElements).map(({ id }) => id);

                if (isMapElement) {
                    zoomToFit(
                        [element],
                        groupElements,
                        graphElement,
                        zoomViewBox,
                        null,
                        groupElementIds,
                    );
                } else {
                    groupElementIds.push(element.id);
                    zoomToFit([], groupElements, graphElement, zoomViewBox, null, groupElementIds);
                }

                // Need to wait for the graph to move to the element before calculating the position.
                requestAnimationFrame(() => {
                    const elementStyle = getElementStyles(elementType);
                    const { width } = isMapElement ? elementStyle.mapElement : element;

                    let position;
                    if (element.groupElementId) {
                        const parentOffset = getParentOffset(getParents(element, groupElements));
                        position = addPositions(element, parentOffset);
                    }
                    const tranformedPosition = transformPositionToScreen(graphElement, {
                        x: (position?.x ?? x) + width,
                        y: position?.y ?? y,
                    });

                    setContextMenuData({
                        show: true,
                        x: tranformedPosition.x,
                        y: tranformedPosition.y,
                        activeElement: {
                            id,
                            type: dragType,
                        },
                    });
                });
            }
            // Context menu is open on this element
            else {
                setContextMenuData(null);
            }
        },
        onAutoArrangeGroup: () => autoArrangeGroup({ groupId: id, graphElement, zoomViewBox }),
    };
};

import ConnectorDropdown from '../../../../../../../generic/ConnectorDropdown';
import Loader from '../../../../../../../loader/Loader';
import translations from '../../../../../../../../translations';
import { NOTIFICATION_TYPES } from '../../../../../../../../constants';
import FormGroup from '../../../../../../../generic/FormGroup';
import { usePageEditor } from '../../../../../PageEditorProvider';
import ConfigSection from '../../ConfigSection';
import { useState } from 'react';
import OpenApiPathDropdown from '../../../../../../../generic/OpenApiPathDropdown';
import { getFlag } from '../../../../../../../../utils/flags';

interface Props {
    updateConnector: (connectorId: string | null) => void;
    updateResourcePath: (resourcePath: string) => void;
    updatePath: (path: string | null) => void;
    resourcePath: string | null;
    selectedConnectorId: string | null;
    selectedPath: string | null;
}

const FileDataSourceConfig = ({
    updateConnector,
    updateResourcePath,
    updatePath,
    resourcePath,
    selectedConnectorId,
    selectedPath,
}: Props) => {
    const { addNotification, isLoading } = usePageEditor();
    const [isOpenApi, setIsOpenApi] = useState(false);
    const featureFlagEnabled = getFlag('OAPI2');

    const onError = (message: unknown) => {
        addNotification({
            type: NOTIFICATION_TYPES.error,
            message: message as string,
            isPersistent: true,
        });
    };

    return (
        <ConfigSection dataTestId="file-data-source-config" title="Data source">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <FormGroup
                        label={translations.PAGE_BUILDER_file_connector_id_label}
                        htmlFor="connector-dropdown"
                    >
                        <ConnectorDropdown
                            selectedConnectorId={selectedConnectorId}
                            onChange={(e) => {
                                updateConnector(e?.value ?? null);
                                updatePath(null);
                            }}
                            onError={onError}
                            isOpenApi={setIsOpenApi}
                        />
                    </FormGroup>
                    {featureFlagEnabled && isOpenApi && (
                        <FormGroup
                            label={translations.PAGE_BUILDER_file_path_label}
                            htmlFor="path-dropdown"
                        >
                            <OpenApiPathDropdown
                                selectedConnectorId={selectedConnectorId}
                                onChange={(e) => updatePath(e?.value ?? null)}
                                onError={onError}
                                selectedPath={selectedPath}
                            />
                        </FormGroup>
                    )}
                    <FormGroup
                        label={translations.PAGE_BUILDER_file_resource_path_label}
                        htmlFor="resource-path"
                    >
                        <input
                            className="form-control"
                            id="resource-path"
                            type="text"
                            value={resourcePath || ''}
                            onChange={({ target }) => updateResourcePath(target.value)}
                        />
                    </FormGroup>
                </>
            )}
        </ConfigSection>
    );
};

export default FileDataSourceConfig;

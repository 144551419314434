import type { ReactElement } from 'react';
import { createPortal } from 'react-dom';

interface Props {
    children: ReactElement | ReactElement[];
    wrapperId: string;
}

const Portal = ({ children, wrapperId }: Props) => {
    return createPortal(children, document.getElementById(wrapperId) as HTMLElement);
};

export default Portal;

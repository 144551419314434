import classnames from 'classnames';

import { ELEMENT_EDGES } from '../../../../constants';
import { ArrowFatLeft, ArrowFatRight, ArrowFatUp, ArrowFatDown } from '@phosphor-icons/react';
import type { Edge } from '../../../dnd-utils';

const InnerArrows = ({ edge, isOverCurrent }: { edge: Edge | null; isOverCurrent: boolean }) => {
    const topClasses = classnames({
        'page-element-arrow': true,
        'page-element-arrow-top': true,
        'page-element-arrow-top-inner': edge === ELEMENT_EDGES['all'] && isOverCurrent,
        active: edge === ELEMENT_EDGES['all'] && isOverCurrent,
    });

    const bottomClasses = classnames({
        'page-element-arrow': true,
        'page-element-arrow-bottom': true,
        'page-element-arrow-bottom-inner': edge === ELEMENT_EDGES['all'] && isOverCurrent,
        active: edge === ELEMENT_EDGES['all'] && isOverCurrent,
    });

    const leftClasses = classnames({
        'page-element-arrow': true,
        'page-element-arrow-left': true,
        'page-element-arrow-left-inner': edge === ELEMENT_EDGES['all'] && isOverCurrent,
        active: edge === ELEMENT_EDGES['all'] && isOverCurrent,
    });

    const rightClasses = classnames({
        'page-element-arrow': true,
        'page-element-arrow-right': true,
        'page-element-arrow-right-inner': edge === ELEMENT_EDGES['all'] && isOverCurrent,
        active: edge === ELEMENT_EDGES['all'] && isOverCurrent,
    });

    return (
        <>
            <ArrowFatDown weight="fill" className={topClasses} />
            <ArrowFatLeft weight="fill" className={rightClasses} />
            <ArrowFatRight weight="fill" className={leftClasses} />
            <ArrowFatUp weight="fill" className={bottomClasses} />
        </>
    );
};

export default InnerArrows;

import type { AddNotification } from '../../types';
import AssetManager from './AssetManager';

interface Props {
    addNotification: AddNotification;
}

const AssetTab = ({ addNotification }: Props) => (
    <div className="admin-page">
        <h1>Assets</h1>
        <AssetManager addNotification={addNotification} />
    </div>
);

export default AssetTab;

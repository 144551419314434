import { assocPath, clone, dissoc, dissocPath, move, path } from 'ramda';
import { useEffect, useState } from 'react';
import { isNullOrEmpty } from '../../../../../../ts/utils/guard';
import { guid } from '../../../../../../ts/utils/guid';
import ButtonDefault from '../../../../../../ts/components/buttons/ButtonDefault';
import ButtonIcon from '../../../../../../ts/components/buttons/ButtonIcon';
import ButtonPrimary from '../../../../../../ts/components/buttons/ButtonPrimary';
import Glyphicon from '../../../../../../ts/components/generic/Glyphicon';
import Modal from '../../../../../../ts/components/generic/modal/GenericModal';
import BusinessRule from './BusinessRule';
import '../../../../../../../css/rule/rule.less';

const BusinessRules = ({ container, comparison, setComparison }) => {
    const createTempId = (object) => ({ ...object, tempId: guid() });

    const createTempIds = (comparison) =>
        createTempId({
            ...comparison,
            comparisons:
                comparison?.comparisons?.map((comparison) => createTempIds(comparison)) || null,
            rules: comparison?.rules?.map((rule) => createTempId(rule)) || null,
        });

    const removeTempId = (object) => dissoc('tempId', object);

    const removeTempIds = (comparison) =>
        removeTempId({
            ...comparison,
            comparisons:
                comparison?.comparisons?.map((comparison) => removeTempIds(comparison)) || null,
            rules: comparison?.rules?.map((rule) => removeTempId(rule)) || null,
        });

    const ruleShape = createTempId({
        criteriaType: null,
        criteriaTypeFriendly: null,
        leftValueElementToReferenceDeveloperName: null,
        leftValueElementToReferenceId: {
            command: null,
            id: null,
            typeElementPropertyId: null,
        },
        rightValueElementToReferenceDeveloperName: null,
        rightValueElementToReferenceId: {
            command: null,
            id: null,
            typeElementPropertyId: null,
        },
    });

    const comparisonShape = createTempId({
        comparisons: null,
        comparisonType: 'AND',
        order: 0,
        rules: null,
    });

    const [editedComparison, setEditingComparison] = useState(
        createTempIds(comparison ?? comparisonShape),
    );

    const [showBusinessRules, setShowBusinessRules] = useState(false);

    const [hasSubmitted, setHasSubmitted] = useState(false);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // We don't want all fields to be validated
        // when adding and removing rules.
        if (hasSubmitted) {
            setHasSubmitted(false);
        }
    }, [editedComparison]);

    const onSave = () => {
        setHasSubmitted(true);

        const isValid = (checkingComparison) => {
            // A comparison with no rules and no deeper comparisons is not allowed
            if (
                isNullOrEmpty(checkingComparison?.rules) &&
                isNullOrEmpty(checkingComparison?.comparisons)
            ) {
                return false;
            }

            // Check if all the business rules have been filled out
            const rulesValidity = checkingComparison.rules
                ? checkingComparison.rules
                      .map(
                          (rule) =>
                              rule.criteriaType &&
                              rule.leftValueElementToReferenceId?.id &&
                              rule.rightValueElementToReferenceId?.id,
                      )
                      .every((r) => r)
                : // If there are no rules, then this is allowed, as they have comparisons
                  true;

            if (isNullOrEmpty(checkingComparison?.comparisons)) {
                return rulesValidity;
            }

            // If there are sub-comparisons, then each of them must be checked too
            return (
                rulesValidity &&
                checkingComparison?.comparisons
                    ?.map((comparison) => isValid(comparison))
                    .every((c) => c)
            );
        };

        const noBusinessRules =
            isNullOrEmpty(editedComparison.rules) && isNullOrEmpty(editedComparison.comparisons);

        if (noBusinessRules) {
            setShowBusinessRules(false);
            // They've set no rules or comparisons, so we should set the whole comparison to null
            setComparison(null);
            return;
        }

        if (isValid(editedComparison)) {
            setShowBusinessRules(false);
            // Remove the temporary added ids before setting the comparison
            setComparison(removeTempIds(editedComparison));
        }
    };
    const onCancel = () => {
        setShowBusinessRules(false);
        setEditingComparison(comparison);
    };

    const renderComparisons = (comparisonPath = []) => {
        // comparisonPath starts as [], then goes ['comparisons', index, 'comparisons', index... and so on]

        // Our index is the last item in the comparisonPath
        const comparisonIndex =
            comparisonPath.length > 0 ? comparisonPath[comparisonPath.length - 1] : 0;
        // Using "path()" with all of our comparisonPath except the last index, will give us our siblings in editedComparison
        const numberOfSiblings =
            comparisonPath.length > 0
                ? path(comparisonPath.slice(0, -1), editedComparison).length
                : 0;

        const moveComparisonGroup = (movement) => {
            const parentPath = comparisonPath.slice(0, -2);
            const parent = path(parentPath, editedComparison);
            const newParent = {
                ...parent,
                comparisons: move(comparisonIndex, comparisonIndex + movement, parent.comparisons),
            };

            setEditingComparison(assocPath(parentPath, newParent, editedComparison));
        };
        const moveUp = () => moveComparisonGroup(-1);
        const moveDown = () => moveComparisonGroup(1);

        const renderingComparison = clone(path(comparisonPath, editedComparison));

        const showEmptyRuleValidation = () => {
            // If no rules are set, and the user tried to submit
            if (isNullOrEmpty(renderingComparison?.rules) && hasSubmitted) {
                // If this is the root comparison,
                // Or they have set deeper comparisons
                if (
                    isNullOrEmpty(comparisonPath) ||
                    isNullOrEmpty(renderingComparison?.comparisons) === false
                ) {
                    // Then no business rules is okay
                    return null;
                }
                // or they are trying to save a sub comparison with no rules, which is not allowed
                return (
                    <span className="help-block error-state">
                        Please add rules to this comparison group, or delete it.
                    </span>
                );
            }

            return null;
        };

        return (
            <div
                key={renderingComparison?.tempId || 'initial-comparison'}
                className="comparison-group"
            >
                <div className="btn-group btn-group-toggle">
                    <select
                        data-testid="rulesComparisonType"
                        className="form-control form-select form-control-width"
                        value={renderingComparison?.comparisonType}
                        onChange={({ target: { value } }) =>
                            setEditingComparison(
                                assocPath(
                                    comparisonPath.splice(-2),
                                    { ...renderingComparison, comparisonType: value },
                                    editedComparison,
                                ),
                            )
                        }
                    >
                        <option value={'OR'} key={'OR'}>
                            Any
                        </option>
                        <option value={'AND'} key={'AND'}>
                            All
                        </option>
                    </select>
                </div>
                {
                    // You can move up if you're not the first entry
                    comparisonIndex > 0 ? (
                        <ButtonIcon
                            className="margin-left"
                            glyph="Up arrow"
                            aria-label="Move Comparison Group Up"
                            title="Move Comparison Group Up"
                            onClick={moveUp}
                            iconClass="icon-medium"
                        />
                    ) : null
                }
                {
                    // You can move down if there are more siblings than your position (position = comparisonIndex + 1)
                    comparisonIndex + 1 < numberOfSiblings ? (
                        <ButtonIcon
                            className="margin-left"
                            glyph="Down arrow"
                            aria-label="Move Comparison Group Down"
                            title="Move Comparison Group Down"
                            onClick={moveDown}
                            iconClass="icon-medium"
                        />
                    ) : null
                }
                {
                    // You can only delete the sub-comparison groups, not the root
                    comparisonPath.length > 0 ? (
                        <ButtonIcon
                            className="margin-left danger"
                            glyph="Delete"
                            aria-label="Delete Comparison Group"
                            title="Delete Comparison Group"
                            onClick={() =>
                                setEditingComparison(dissocPath(comparisonPath, editedComparison))
                            }
                            iconClass="icon-medium"
                        />
                    ) : null
                }
                <div className="comparison-new-item">
                    {renderingComparison?.rules?.map((rule, index) => (
                        <BusinessRule
                            key={rule.tempId}
                            rule={rule}
                            setRule={(newRule) =>
                                setEditingComparison(
                                    assocPath(
                                        [...comparisonPath, 'rules', index],
                                        newRule,
                                        editedComparison,
                                    ),
                                )
                            }
                            removeRule={() =>
                                setEditingComparison(
                                    dissocPath(
                                        [...comparisonPath, 'rules', index],
                                        editedComparison,
                                    ),
                                )
                            }
                            hasSubmitted={hasSubmitted}
                        />
                    ))}
                    {showEmptyRuleValidation()}
                    <div className="margin-bottom margin-top">
                        <ButtonDefault
                            title="New Rule"
                            onClick={() => {
                                const rules = renderingComparison?.rules || [];
                                rules.push(ruleShape);
                                setEditingComparison(
                                    assocPath(
                                        comparisonPath,
                                        { ...renderingComparison, rules },
                                        editedComparison,
                                    ),
                                );
                            }}
                        >
                            New Rule
                        </ButtonDefault>
                        <ButtonDefault
                            title="New Comparison Group"
                            onClick={() => {
                                const comparisons = renderingComparison?.comparisons || [];
                                comparisons.push(comparisonShape);
                                setEditingComparison(
                                    assocPath(
                                        comparisonPath,
                                        { ...renderingComparison, comparisons },
                                        editedComparison,
                                    ),
                                );
                            }}
                        >
                            New Comparison Group
                        </ButtonDefault>
                    </div>
                    {renderingComparison?.comparisons?.map((_, index) =>
                        renderComparisons([...comparisonPath, 'comparisons', index]),
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="form-group">
            <Modal
                title="Business Rules"
                renderBody={renderComparisons}
                renderFooter={() => (
                    <>
                        <ButtonDefault title="Cancel" onClick={onCancel}>
                            Cancel
                        </ButtonDefault>
                        <ButtonPrimary title="Save" onClick={onSave}>
                            Save
                        </ButtonPrimary>
                    </>
                )}
                container={container}
                show={showBusinessRules}
                dialogClassName="modal-large map-element-config-modal"
                onHide={onCancel}
            />
            <ButtonPrimary title="Create Business Rules" onClick={() => setShowBusinessRules(true)}>
                <Glyphicon glyph={comparison ? 'pencil' : 'plus'} />
                {comparison ? 'Edit' : 'Create'} Business Rules
            </ButtonPrimary>
        </div>
    );
};

export default BusinessRules;

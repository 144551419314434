import { useEffect, useState } from 'react';
import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const Presentation = ({
    content,
    label,
    isRequired,
}: {
    content: string;
    label: string;
    isRequired: boolean;
}) => {
    const [presentationContent, setPresentationContent] = useState<string>('');
    useEffect(() => {
        if (content) {
            setPresentationContent(
                content
                    .replace(/&quot;/g, '"')
                    .replace(/&#39;/g, "'")
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>')
                    .replace(/&amp;/g, '&'),
            );
        }
    }, [content]);

    return (
        <div data-testid="component-preview-presentation" className="component-preview-input">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            {/* biome-ignore lint/security/noDangerouslySetInnerHtml: No alternative */}
            <div dangerouslySetInnerHTML={{ __html: presentationContent }} />
        </div>
    );
};

export default Presentation;

import {
    AlertBannerType,
    ButtonFlavor,
    ButtonType,
    ExAlertBanner,
    ExButton,
    ExEmptyState,
    ExLoader,
} from '@boomi/exosphere';
import { type FormEvent, useCallback, useEffect, useState } from 'react';
import type { ContentType, ValueElementIdReferenceAPI } from '../../../types';
import { getValueList } from '../../../sources/value';
import translations from '../../../translations';
import { addRecentValue, getRecentValues } from './value-selector-utils';
import ValueSelectorFilter, { type ValueSelectorFilters } from './ValueSelectorFilter';
import debounce from 'lodash.debounce';
import ValueSelectorValuesItem from './ValueSelectorValuesItem';

interface Props {
    contentType?: ContentType | undefined;
    typeElementId?: string | undefined | null;
    includeSystemValues: boolean;
    onChange: (value: ValueElementIdReferenceAPI) => void;
    onEdit: (value: ValueElementIdReferenceAPI) => void;
    onCreate: () => void;
}

const ValueSelectorValues = ({
    contentType,
    typeElementId,
    includeSystemValues,
    onChange,
    onEdit,
    onCreate,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [values, setValues] = useState<ValueElementIdReferenceAPI[]>([]);
    const [recentValues, setRecentValues] = useState<ValueElementIdReferenceAPI[]>([]);
    const [filter, setFilter] = useState<ValueSelectorFilters>({
        access: null,
        contentType: contentType ? [contentType] : [],
        typeElementId: typeElementId ? typeElementId : null,
        limit: 50,
        offset: 0,
        search: '',
    });
    const [error, setError] = useState<string | null>(null);

    const load = async (_filter: ValueSelectorFilters, replaceValues = true) => {
        setLoading(true);

        try {
            const results = await Promise.all([
                getValueList({
                    search: _filter.search,
                    limit: _filter.limit,
                    offset: _filter.offset,
                    access: _filter.access ? [_filter.access] : [],
                    contentType: _filter.contentType,
                    typeElementId: _filter.typeElementId ? [_filter.typeElementId] : [],
                    includeSystemValues,
                }),
                getValueList({
                    search: _filter.search,
                    limit: _filter.limit,
                    offset: _filter.offset,
                    access: _filter.access ? [_filter.access] : [],
                    contentType: _filter.contentType,
                    typeElementId: _filter.typeElementId ? [_filter.typeElementId] : [],
                    includeSystemValues,
                    id: getRecentValues().map((value) => value.id as string),
                }),
            ]);

            if (replaceValues) {
                setValues(results[0]);
            } else {
                setValues([...values, ...results[0]]);
            }

            setRecentValues(
                getRecentValues()
                    .map((value) =>
                        results[1].find(
                            (recentValue) =>
                                recentValue.id === value.id &&
                                recentValue.typeElementPropertyId === value.typeElementPropertyId,
                        ),
                    )
                    .filter((value) => !!value) as ValueElementIdReferenceAPI[],
            );
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const initialFilter = {
            access: null,
            contentType: contentType ? [contentType] : [],
            typeElementId: typeElementId ? typeElementId : null,
            limit: 50,
            offset: 0,
            search: '',
        };

        load(initialFilter);
    }, []);

    const debouncedLoad = useCallback(debounce(load, 750), []);

    const onFilterChange = (value: ValueSelectorFilters) => {
        const newFilter = {
            ...value,
            offset: 0,
        };

        setFilter(newFilter);
        load(newFilter);
    };

    const onSearchChange = (e: FormEvent<HTMLInputElement>) => {
        const newFilter = {
            ...filter,
            search: e.currentTarget.value,
            offset: 0,
        };

        // Set the filter in real time so the search input updates but debounce the actual load to reduce engine calls.
        setFilter(newFilter);
        debouncedLoad(newFilter);
    };

    const onValueClick = (value: ValueElementIdReferenceAPI) => {
        addRecentValue(value);
        onChange(value);
    };

    const onMoreResultsClick = () => {
        const newFilter = {
            ...filter,
            offset: filter.offset + filter.limit,
        };

        setFilter(newFilter);
        load(newFilter, false);
    };

    const renderValue = (value: ValueElementIdReferenceAPI) => {
        return (
            <ValueSelectorValuesItem
                value={value}
                sourceTypeElementId={typeElementId}
                onClick={onValueClick}
                onEdit={onEdit}
                key={`${value.id || ''}_${value.typeElementPropertyId || ''}`}
            />
        );
    };

    const hasMoreResults = values.length === filter.limit;

    return (
        <>
            <div className="value-selector-values-header">
                <div className="value-selector-values-search">
                    <input
                        data-testid="value-selector-values-search"
                        aria-label="Search"
                        className="form-control"
                        value={filter.search}
                        onChange={onSearchChange}
                        type="search"
                        autoFocus={true}
                    />
                    <span className="search-splitter">or</span>
                    <ExButton
                        onClick={onCreate}
                        flavor={ButtonFlavor.BRANDED}
                        type={ButtonType.PRIMARY}
                    >
                        {translations.VALUE_SELECTOR_create_new}
                    </ExButton>
                </div>
            </div>
            <div className="overflow-y-auto modal-scroller">
                <ValueSelectorFilter value={filter} onChange={onFilterChange} />
                {error ? (
                    <ExAlertBanner type={AlertBannerType.ERROR} open data-testid="alert">
                        {error}
                    </ExAlertBanner>
                ) : null}
                {loading ? (
                    <ExLoader />
                ) : !loading && values.length > 0 ? (
                    <div className="value-selector-values-list">
                        <h5>{translations.VALUE_SELECTOR_recent}</h5>
                        <ul className="recent-value-list">{recentValues.map(renderValue)}</ul>
                        <hr className="recent-separator" />
                        <ul>{values.map(renderValue)}</ul>
                        {hasMoreResults ? (
                            <button
                                onClick={onMoreResultsClick}
                                className="value-selector-values-more-results"
                                type="button"
                            >
                                {translations.VALUE_SELECTOR_more_results}
                            </button>
                        ) : null}
                    </div>
                ) : (
                    <ExEmptyState />
                )}
            </div>
        </>
    );
};

export default ValueSelectorValues;

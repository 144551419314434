import translations from '../../translations';
import { REGIONS } from '../masthead/constants';
import DropdownList from './DropdownList';
import DropdownButton from './headerDropdown/DropdownButton';
import DropdownListLink from './headerDropdown/DropdownListLink';

const platformDomain = 'https://flow.boomi.com';
const legacyPlatformDomain = 'https://flow.manywho.com';

const RegionSwitcher = () => {
    const buttonContents = <DropdownButton buttonText={translations.REGION_SWITCHER_button_text} />;

    return (
        <DropdownList alignRight buttonContents={buttonContents}>
            {Object.values(REGIONS).map((region) => {
                const { name, url, summary } = region;
                const isGlobalRegion = name === REGIONS.us.name;
                const host = `${window.location.protocol}//${window.location.hostname}`;

                const isSelected =
                    url === host ||
                    (isGlobalRegion && host === platformDomain) ||
                    (isGlobalRegion && host === legacyPlatformDomain);

                return (
                    <DropdownListLink
                        key={name}
                        linkText={name}
                        linkSummary={summary}
                        linkHref={url}
                        hasIcon={false}
                        isSelected={isSelected}
                    />
                );
            })}
        </DropdownList>
    );
};

export default RegionSwitcher;

import classnames from 'classnames';
import {
    type ChangeEventHandler,
    type ComponentProps,
    type LegacyRef,
    type ReactNode,
    forwardRef,
} from 'react';
import '../../../../css/selectbox.less';

interface GenericSelectBox extends ComponentProps<'select'> {
    onFilter: ChangeEventHandler<HTMLSelectElement>;
    selected: string | number;
    children?: ReactNode;
    required?: boolean;
}

/**
 * @param onFilter Callback
 * @param selected The default value used by the selectbox
 * @param children Select options
 * @param required Whether to display a asterisk to mark field as required
 * @description A SFC component that renders a selectbox
 */
const GenericSelectBox = (
    { onFilter, selected, children, required = false, ...attributes }: GenericSelectBox,
    ref: LegacyRef<HTMLSelectElement>,
) => (
    <div
        className={classnames('form-group', {
            required,
        })}
    >
        <label className="control-label">
            <select
                ref={ref}
                defaultValue={selected}
                onChange={onFilter}
                className="form-control form-select"
                data-testid="form-select"
                {...attributes}
            >
                {children}
            </select>
        </label>
    </div>
);

export default forwardRef(GenericSelectBox);

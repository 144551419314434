import { useEffect, useState } from 'react';
import PageConditionRuleConfig from './pageConditionRuleConfig/PageConditionRuleConfig';
import PageConditionOperationConfig from './pageConditionOperationConfig/PageConditionOperationConfig';
import FormGroup from '../../../../../generic/FormGroup';
import { isNullOrEmpty } from '../../../../../../utils/guard';
import translations from '../../../../../../translations';
import { usePageEditor } from '../../../PageEditorProvider';
import ButtonDefault from '../../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../../buttons/ButtonPrimary';
import ConfigSection from '../../composer/config-panel/ConfigSection';
import PageConditionItemList from '../common/PageConditionItemList';
import { NOTIFICATION_TYPES } from '../../../../../../constants';
import { usePageCondition } from '../PageConditionsProvider';
import Portal from '../common/Portal';
import { SUMMARY_CONTEXT } from '../../../../constants';
import Select from 'react-select';
import { getSharedStyles } from '../../../../../../utils/select';
import type { PageCondition } from '../../../../../../types';

const ruleComparisonOptions = [
    { label: 'AND', value: 'AND' },
    { label: 'OR', value: 'OR' },
];

const getInitialComparison = (condition: PageCondition | null) => {
    const comparison = condition?.comparisonType;
    return comparison
        ? ruleComparisonOptions.find((option) => option.value === comparison)
        : ruleComparisonOptions[0];
};

const PageConditionConfig = () => {
    const { state: pageBuilderState, addNotification } = usePageEditor();
    const { tab } = pageBuilderState;
    const {
        state: pageConditionState,
        updateConditionIndex,
        updateRuleIndex,
        updateOperationIndex,
        updateComparison,
        applyCondition,
    } = usePageCondition();
    const { condition, conditionIndex, ruleIndex, operationIndex } = pageConditionState;
    const footerPortalId = `sidebar-right-footer-portal-${tab?.key}`;

    const [ruleComparison, setRuleComparison] = useState<
        { label: string; value: string } | undefined | null
    >(getInitialComparison(condition));

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (ruleComparison) {
            updateComparison(ruleComparison.value);
        }
    }, [ruleComparison]);

    const onCancel = () => {
        updateConditionIndex(null);
        updateRuleIndex(null);
        updateOperationIndex(null);
    };

    const onApply = () => {
        // Check that we got at least one rule and one operation.
        const hasRules = condition?.pageRules?.length;
        const hasOperations = condition?.pageOperations?.length;

        if (hasRules && hasOperations) {
            applyCondition();
            onCancel();
        } else {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: translations.PAGE_BUILDER_page_condition_not_configured_message,
                isPersistent: false,
            });
        }
    };

    const onAddNewRule = () => updateRuleIndex(-1);
    const onAddNewOperation = () => updateOperationIndex(-1);

    return (
        <ConfigSection dataTestId="condition-editor">
            <h4 className="sidebar-section-heading">
                {conditionIndex !== null && conditionIndex >= 0
                    ? 'Edit condition'
                    : 'New condition'}
            </h4>
            {/* RULES */}
            {ruleIndex === null ? (
                <>
                    <PageConditionItemList listContext={SUMMARY_CONTEXT['RULES']} />
                    <ButtonDefault
                        className="align-left"
                        onClick={onAddNewRule}
                        title="Add new page condition operation"
                    >
                        Add rule
                    </ButtonDefault>
                </>
            ) : (
                <PageConditionRuleConfig />
            )}
            {/* RULE COMPARISON */}
            <FormGroup
                label="Combine page rules using"
                htmlFor="rule-comparison-type"
                isValid={!isNullOrEmpty(ruleComparison)}
                validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
                showValidation={true}
                isRequired
            >
                <Select
                    inputId="rule-comparison-type"
                    className="select-field"
                    styles={getSharedStyles<{ label: string; value: string } | undefined | null>()}
                    options={ruleComparisonOptions}
                    onChange={(selectedComparison) => setRuleComparison(selectedComparison)}
                    placeholder="Available options"
                    value={ruleComparison}
                    noOptionsMessage={() => 'No results found'}
                />
            </FormGroup>
            <br />
            {/* OPERATIONS */}
            {operationIndex === null ? (
                <>
                    <PageConditionItemList listContext={SUMMARY_CONTEXT['OPERATIONS']} />
                    <ButtonDefault
                        className="align-left"
                        onClick={onAddNewOperation}
                        title="Add new page condition operation"
                    >
                        Add operation
                    </ButtonDefault>
                </>
            ) : (
                <PageConditionOperationConfig />
            )}

            <br />

            <Portal wrapperId={footerPortalId}>
                <footer className="sidebar-section-footer">
                    <ButtonDefault onClick={onCancel} title="Cancel condition">
                        Cancel
                    </ButtonDefault>
                    <ButtonPrimary onClick={onApply} title="Apply condition">
                        Apply
                    </ButtonPrimary>
                </footer>
            </Portal>
        </ConfigSection>
    );
};

export default PageConditionConfig;

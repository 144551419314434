import { Warning, Trash } from '@phosphor-icons/react';
import { usePageEditor } from '../../../../PageEditorProvider';
import { useComposer } from '../../ComposerProvider';

import translations from '../../../../../../../translations';
import classNames from 'classnames';
import ButtonIcon from '../../../../../../buttons/ButtonIcon';
import RuntimeMarker from '../../misc/RuntimeMarker';
import type { LegacyRef } from 'react';
import type { ComposerElement, ListFilter, PreviewMode } from '../../../../../../../types';
import { componentDataSourceFilter, isComponentConfigured } from '../../../../../utils';

interface Props {
    config: ComposerElement;
    classes: string;
    drag: LegacyRef<HTMLDivElement> | null;
    id: string;
    parentId: string | null | undefined;
    preview: PreviewMode | null;
}

const PageComponent = ({ config, classes, drag, id, preview }: Props) => {
    const { componentType, developerName } = config;
    const { setElementToDelete } = useComposer();
    const { getComponent } = usePageEditor();
    const { renderFn, ui, runtimeSupport } = getComponent(componentType?.toUpperCase() as string);

    const isComponentConfigValid = isComponentConfigured(config, getComponent);

    const { isConfigured: isComponentFilterConfigValid } = componentDataSourceFilter(
        config?.objectDataRequest?.listFilter as ListFilter,
    );

    const headerClasses = classNames({
        'page-container-header': true,
        preview: !!preview,
    });

    const componentTypeLabel = ui.caption;
    const componentTitle = developerName
        ? `${componentTypeLabel}: ${developerName}`
        : `New ${componentTypeLabel}`;

    const isDraggable = !preview;

    return (
        <div
            id={id}
            role="button"
            ref={isDraggable ? drag : null}
            tabIndex={-1}
            className="page-container-inner"
        >
            <div className={headerClasses}>
                <span title={componentTitle} className="page-element-title">
                    {componentTitle}
                </span>
                <RuntimeMarker runtime={runtimeSupport} miniMarker={false} />
                <div className="container-header-actions flex-child-right">
                    <ButtonIcon
                        className="header-icon-button"
                        title="Delete Component"
                        onClick={() => setElementToDelete({ targetId: id })}
                    >
                        <Trash size={20} weight="fill" />
                    </ButtonIcon>
                </div>
            </div>
            <div className={classes}>{renderFn({ ...config })}</div>
            {!(isComponentConfigValid && isComponentFilterConfigValid) && (
                <mark
                    title={translations.PAGE_BUILDER_unconfigured_component}
                    className="config-warning"
                    data-testid="mark"
                >
                    <Warning weight="fill" />
                </mark>
            )}
        </div>
    );
};

export default PageComponent;

import { fetchAndParse, filterToQuery } from '../../ts/utils/ajax';
import type { Filter, ItemCollectionResponse, Page, PageElementResponseAPI } from '../types';
import { getTenantId } from '../utils/tenant';

export const savePage = (request: Page) =>
    fetchAndParse<Page>({
        url: '/api/draw/1/element/page',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: JSON.stringify(request),
    });

export const getPage = (id: string) =>
    fetchAndParse<Page>({
        url: `/api/draw/1/element/page/${id}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

export const getAllPages = () => {
    return fetchAndParse<PageElementResponseAPI[]>({
        url: '/api/draw/1/element/page',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const getPageList = (filter: Filter) => {
    const query = filterToQuery(filter);

    return fetchAndParse<ItemCollectionResponse<PageElementResponseAPI>>({
        url: `/api/draw/2/element/page${query}`,
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const deletePage = (id: string) =>
    fetchAndParse<void>({
        url: `/api/draw/1/element/page/${id}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });

import { CONTENT_TYPES } from '../../../../constants';

export const OPERATION_COMMANDS = {
    valueOf: {
        command: 'VALUE_OF',
        display: 'Value Of',
        contentTypes: [
            CONTENT_TYPES.string,
            CONTENT_TYPES.password,
            CONTENT_TYPES.content,
            CONTENT_TYPES.encrypted,
            CONTENT_TYPES.number,
            CONTENT_TYPES.datetime,
            CONTENT_TYPES.date,
            CONTENT_TYPES.boolean,
            CONTENT_TYPES.list,
            CONTENT_TYPES.object,
        ],
    },
    getLength: {
        command: 'GET_LENGTH',
        display: 'Length Of',
        contentTypes: [
            CONTENT_TYPES.list,
            CONTENT_TYPES.string,
            CONTENT_TYPES.password,
            CONTENT_TYPES.content,
            CONTENT_TYPES.encrypted,
        ],
    },
    getFirst: {
        command: 'GET_FIRST',
        display: 'First Record Of',
        contentTypes: [CONTENT_TYPES.list],
    },
    getLast: {
        command: 'GET_LAST',
        display: 'Last Record Of',
        contentTypes: [CONTENT_TYPES.list],
    },
    getNext: {
        command: 'GET_NEXT',
        display: 'Next Record Of',
        contentTypes: [CONTENT_TYPES.list],
    },
    setEqual: {
        command: 'SET_EQUAL',
        display: 'Set Equal To',
        contentTypes: [
            CONTENT_TYPES.string,
            CONTENT_TYPES.password,
            CONTENT_TYPES.content,
            CONTENT_TYPES.encrypted,
            CONTENT_TYPES.number,
            CONTENT_TYPES.datetime,
            CONTENT_TYPES.date,
            CONTENT_TYPES.boolean,
            CONTENT_TYPES.list,
            CONTENT_TYPES.object,
        ],
    },
    setRelativeDate: {
        command: 'SET_RELATIVE',
        display: 'Set Relative To',
        contentTypes: [CONTENT_TYPES.datetime, CONTENT_TYPES.date],
    },
    // Not yet supported - requires additional criteria
    filter: {
        command: 'FILTER',
        display: 'Filter',
    },
    remove: {
        command: 'REMOVE',
        display: 'Remove',
        contentTypes: [CONTENT_TYPES.list, CONTENT_TYPES.object],
    },
    update: {
        command: 'ADD',
        display: 'Update',
        contentTypes: [CONTENT_TYPES.list, CONTENT_TYPES.object],
    },
    add: {
        command: 'ADD',
        display: 'Add',
        contentTypes: [CONTENT_TYPES.number],
    },
    subtract: {
        command: 'REMOVE',
        display: 'Subtract',
        contentTypes: [CONTENT_TYPES.number],
    },
    divide: {
        command: 'DIVIDE',
        display: 'Divide',
        contentTypes: [CONTENT_TYPES.number],
    },
    multiply: {
        command: 'MULTIPLY',
        display: 'Multiply',
        contentTypes: [CONTENT_TYPES.number],
    },
    modulo: {
        command: 'MODULO',
        display: 'Modulo',
        contentTypes: [CONTENT_TYPES.number],
    },
    join: {
        command: 'ADD',
        display: 'Join',
        contentTypes: [
            CONTENT_TYPES.string,
            CONTENT_TYPES.password,
            CONTENT_TYPES.content,
            CONTENT_TYPES.encrypted,
        ],
    },
    new: {
        command: 'NEW',
        display: 'Create New',
        contentTypes: [
            CONTENT_TYPES.string,
            CONTENT_TYPES.password,
            CONTENT_TYPES.content,
            CONTENT_TYPES.encrypted,
            CONTENT_TYPES.number,
            CONTENT_TYPES.datetime,
            CONTENT_TYPES.date,
            CONTENT_TYPES.boolean,
            CONTENT_TYPES.list,
            CONTENT_TYPES.object,
        ],
    },
    empty: {
        command: 'EMPTY',
        display: 'Empty',
        contentTypes: [
            CONTENT_TYPES.string,
            CONTENT_TYPES.password,
            CONTENT_TYPES.content,
            CONTENT_TYPES.encrypted,
            CONTENT_TYPES.number,
            CONTENT_TYPES.datetime,
            CONTENT_TYPES.date,
            CONTENT_TYPES.boolean,
            CONTENT_TYPES.list,
            CONTENT_TYPES.object,
        ],
    },
    sort: {
        command: 'SORT',
        display: 'Sort',
        contentTypes: [CONTENT_TYPES.list],
    },
};

export const TIME_UNITS = {
    SECONDS: 'Second(s)',
    MINUTES: 'Minutes(s)',
    HOURS: 'Hour(s)',
    DAYS: 'Day(s)',
    WEEKS: 'Week(s)',
    MONTHS: 'Month(s)',
    YEARS: 'Year(s)',
};

export const RELATIVE_DATETIME_UNITS = [
    TIME_UNITS.SECONDS,
    TIME_UNITS.MINUTES,
    TIME_UNITS.HOURS,
    TIME_UNITS.DAYS,
    TIME_UNITS.WEEKS,
    TIME_UNITS.MONTHS,
    TIME_UNITS.YEARS,
];

export const RELATIVE_DATE_UNITS = [
    TIME_UNITS.DAYS,
    TIME_UNITS.WEEKS,
    TIME_UNITS.MONTHS,
    TIME_UNITS.YEARS,
];

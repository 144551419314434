import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import '../../../../css/graph/graph-context-menu.less';
import {
    editNote as editNoteAction,
    toggleNoteVisibility as toggleNoteVisibilityAction,
} from '../../actions/reduxActions/canvasNotes';
import { setContextMenuData as setContextMenuDataAction } from '../../actions/reduxActions/graphEditor';
import { GRAPH_ELEMENT_TYPES, MAP_ELEMENT_CONFIGS, MAP_ELEMENT_TYPES } from '../../../ts/constants';
import { getMapElement } from '../../../ts/sources/graph';
import translations from '../../../ts/translations';
import { getByID } from '../../../ts/utils/collection';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import { useGraph } from './GraphProvider';
import { snapPosition } from './utils';
import GraphContextMenuItem from './GraphContextMenuItem';
import {
    ArrowsClockwise,
    Clipboard,
    Copy,
    NotePencil,
    PencilSimple,
    Plus,
    Trash,
    ChartLine,
    Percent,
} from '@phosphor-icons/react';
import { getFlag } from '../../../ts/utils/flags';

const GraphContextMenu = memo(
    ({
        contextMenu,
        openConfig,
        editingToken,
        flowId,
        setContextMenuData,
        openMetadataEditor,
        clipboard,
        calculateSVGPositionFromXY,
        canvasNotes,
        editNote,
        toggleNoteVisibility,
        graphElement,
    }) => {
        const {
            mapElements,
            groupElements,
            selectedElementIds,
            selectedOutcomeId,
            copyElement,
            pasteElement,
            saveMapElement,
            contextMenuElement,
            onTab,
            setContextMenuTabIndex,
            setInsightsConfigView,
            calculateTrafficRatios,
        } = useGraph();

        const { show, x, y, activeElement } = contextMenu || {};

        const [menuPosition, setMenuPosition] = useState(null);
        const menuRef = useRef(null);

        const calculateMenuPosition = () => {
            const graphRect = graphElement.getBoundingClientRect();
            const menuRect = menuRef.current.getBoundingClientRect();

            const tooLow = y + menuRect.height > graphRect.bottom;
            const tooFar = x + menuRect.width > graphRect.right;

            const top = tooLow ? y - menuRect.height : y;
            const left = tooFar ? x - menuRect.width : x;

            setMenuPosition({ top, left });
        };

        useEffect(() => {
            if (!isNullOrEmpty(activeElement?.id)) {
                // The context menu has been triggered again while an option has been focused: close the menu,
                // reset the state in the provider that tracks menu index and focus the element that the menu was opened on.
                const handleContextMenu = (event) => {
                    if (event.target.classList.contains('graph-menu-item')) {
                        event.preventDefault();
                        setContextMenuTabIndex(-1);
                        document.getElementById(activeElement.id)?.focus();
                        setContextMenuData(null);
                    }
                };
                document.addEventListener('contextmenu', handleContextMenu);

                return () => document.removeEventListener('contextmenu', handleContextMenu);
            }
        }, [activeElement, setContextMenuTabIndex, setContextMenuData]);

        // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
        useLayoutEffect(() => {
            if (show) {
                calculateMenuPosition();
            }
        }, [show]);

        contextMenuElement.current = activeElement;

        if (!show || isNullOrEmpty(x) || isNullOrEmpty(y)) {
            return null;
        }
        const { id, outcomeMapElementId, type } = activeElement;

        const onKeyDown = (event) => {
            if (event.key === 'Tab') {
                onTab(event);
            }
        };

        const copyOption = () => (
            <GraphContextMenuItem
                id="context-menu-option-copy"
                key={translations.GRAPH_action_copy}
                label={translations.GRAPH_action_copy}
                icon={<Copy />}
                shortcut={translations.GRAPH_action_copy_shortcut}
                onClick={() => {
                    copyElement(selectedElementIds, selectedOutcomeId);
                    setContextMenuData(null);
                }}
                onKeyDown={onKeyDown}
            />
        );

        const pasteOption = () => (
            <GraphContextMenuItem
                id="context-menu-option-paste"
                key={translations.GRAPH_action_paste}
                label={translations.GRAPH_action_paste}
                icon={<Clipboard />}
                shortcut={translations.GRAPH_action_paste_shortcut}
                onClick={() => {
                    pasteElement({
                        pastePosition: snapPosition(calculateSVGPositionFromXY({ x, y })),
                    });
                    setContextMenuData(null);
                }}
                isDisabled={isNullOrEmpty(clipboard)}
                onKeyDown={onKeyDown}
            />
        );

        const deleteOption = (onClick) => (
            <GraphContextMenuItem
                id="context-menu-option-delete"
                key={translations.GRAPH_action_delete}
                label={translations.GRAPH_action_delete}
                icon={<Trash />}
                shortcut={translations.GRAPH_action_delete_shortcut}
                onClick={onClick}
                onKeyDown={onKeyDown}
            />
        );

        const editOption = (onClick) => (
            <GraphContextMenuItem
                id="context-menu-option-edit"
                key={translations.GRAPH_action_edit}
                label={translations.GRAPH_action_edit}
                icon={<PencilSimple />}
                shortcut={translations.GRAPH_action_edit_shortcut}
                onClick={onClick}
                onKeyDown={onKeyDown}
            />
        );

        const editMetadataOption = () => (
            <GraphContextMenuItem
                id="context-menu-option-edit-meta"
                key={translations.GRAPH_action_edit_metadata}
                label={translations.GRAPH_action_edit_metadata}
                icon={<NotePencil />}
                shortcut={translations.GRAPH_action_edit_metadata_shortcut}
                onClick={() => {
                    openMetadataEditor(id, type);
                    setContextMenuData(null);
                }}
                onKeyDown={onKeyDown}
            />
        );

        const createOutcomeOption = (elementType) => (
            <GraphContextMenuItem
                id="context-menu-option-create-outcome"
                key={translations.GRAPH_config_panel_create_outcome}
                label={translations.GRAPH_config_panel_create_outcome}
                icon={<Plus />}
                shortcut={translations.GRAPH_action_no_shortcut}
                onClick={() => {
                    if (elementType !== MAP_ELEMENT_TYPES.note) {
                        openConfig(MAP_ELEMENT_CONFIGS.addOutcome, {
                            id,
                            elementType,
                            sourceMapElementId: id,
                        });
                        setContextMenuData(null);
                    }
                }}
                onKeyDown={onKeyDown}
            />
        );

        const resetOutcomeOption = (mapElementId) => (
            <GraphContextMenuItem
                id="context-menu-option-reset-outcome"
                key={translations.GRAPH_action_reset_outcome}
                label={translations.GRAPH_action_reset_outcome}
                icon={<ArrowsClockwise />}
                shortcut={translations.GRAPH_action_no_shortcut}
                onClick={async () => {
                    // fetch the map element alone to avoid saving partial data
                    const controlPointMapElement = await getMapElement(
                        mapElementId,
                        flowId,
                        editingToken,
                    );

                    const controlPointOutcome = getByID(id, controlPointMapElement.outcomes);
                    controlPointOutcome.controlPoints = null;
                    saveMapElement(controlPointMapElement);
                    setContextMenuData(null);
                }}
                onKeyDown={onKeyDown}
            />
        );

        const viewInsightsOption = () =>
            getFlag('IOE') ? (
                <GraphContextMenuItem
                    id="context-menu-option-view-insights"
                    key={translations.GRAPH_action_view_insights}
                    label={translations.GRAPH_action_view_insights}
                    icon={<ChartLine />}
                    shortcut={translations.GRAPH_action_view_insights_shortcut}
                    onClick={() => {
                        setInsightsConfigView({ mapElementId: id });
                        setContextMenuData(null);
                    }}
                    onKeyDown={onKeyDown}
                />
            ) : null;

        const viewTrafficRatioOption = () =>
            getFlag('IOE') ? (
                <GraphContextMenuItem
                    id="context-menu-option-view-traffic-ratio"
                    key={translations.GRAPH_action_view_traffic_ratio}
                    label={translations.GRAPH_action_view_traffic_ratio}
                    icon={<Percent />}
                    shortcut={translations.GRAPH_action_view_traffic_ratio_shortcut}
                    onClick={() => {
                        calculateTrafficRatios(id);
                        setContextMenuData(null);
                    }}
                    onKeyDown={onKeyDown}
                />
            ) : null;

        const elementCanHaveOutcomes = (elementType) => {
            if (
                elementType === MAP_ELEMENT_TYPES.note ||
                elementType === MAP_ELEMENT_TYPES.return
            ) {
                return false;
            }

            return true;
        };

        let items = [];

        switch (type) {
            case GRAPH_ELEMENT_TYPES.map: {
                const element = getByID(id, mapElements);
                const { elementType } = element;

                // Start element should have the ability to create outcomes for accessibility purposes.
                if (elementType.toLowerCase() === MAP_ELEMENT_TYPES.start) {
                    items = [createOutcomeOption(elementType)];
                    break;
                }

                items = [
                    elementCanHaveOutcomes(elementType) ? createOutcomeOption(elementType) : null,
                    editOption(() => {
                        if (elementType === MAP_ELEMENT_TYPES.note) {
                            const note = getByID(id, canvasNotes);
                            if (note.readOnly) {
                                if (!note.isOpen) {
                                    toggleNoteVisibility(id);
                                }
                                editNote(id);
                            }
                        } else {
                            openConfig(elementType, {
                                id,
                                elementType,
                                x,
                                y,
                            });
                        }
                        setContextMenuData(null);
                    }),
                    editMetadataOption(),
                    viewInsightsOption(),
                    viewTrafficRatioOption(),
                    copyOption(),
                    deleteOption(() => {
                        openConfig(MAP_ELEMENT_CONFIGS.multiDelete, {
                            elementId: id,
                        });
                        setContextMenuData(null);
                    }),
                ];

                break;
            }
            case GRAPH_ELEMENT_TYPES.group: {
                const element = getByID(id, groupElements);
                const { elementType } = element;
                items = [
                    editOption(() => {
                        openConfig(elementType, {
                            id,
                            elementType,
                            x,
                            y,
                        });
                        setContextMenuData(null);
                    }),
                    editMetadataOption(),
                    copyOption(),
                    deleteOption(() => {
                        openConfig(MAP_ELEMENT_CONFIGS.multiDelete, {
                            elementId: id,
                        });
                        setContextMenuData(null);
                    }),
                ];
                break;
            }
            case GRAPH_ELEMENT_TYPES.outcome: {
                const mapElement = getByID(outcomeMapElementId, mapElements);
                const outcome = getByID(id, mapElement.outcomes);
                items = [
                    editOption(() => {
                        openConfig(MAP_ELEMENT_TYPES.outcome, {
                            id: mapElement.id,
                            nextMapElementId: outcome.nextMapElementId,
                            outcomeId: id,
                        });
                        setContextMenuData(null);
                    }),
                    resetOutcomeOption(mapElement.id),
                    deleteOption(() => {
                        openConfig(MAP_ELEMENT_CONFIGS.multiDelete, {
                            elementId: mapElement.id,
                            outcomeId: id,
                        });
                        setContextMenuData(null);
                    }),
                ];
                break;
            }
            default: {
                items = [copyOption(), pasteOption()];
                break;
            }
        }

        return (
            <nav ref={menuRef} style={menuPosition} className="graph-context-menu">
                <section>{items}</section>
            </nav>
        );
    },
);

export default connect(
    ({ graphEditor: { contextMenu, clipboard }, canvasNotes }) => ({
        contextMenu,
        clipboard,
        canvasNotes,
    }),
    {
        setContextMenuData: setContextMenuDataAction,
        editNote: editNoteAction,
        toggleNoteVisibility: toggleNoteVisibilityAction,
    },
)(GraphContextMenu);

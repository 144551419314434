import translations from '../translations';
import { LoginContainer } from './authentication/LoginContainer';

const SsoLoggedOut = () => {
    return (
        <LoginContainer>
            <h2 className="logout-message">{translations.AUTH_sso_logged_out}</h2>
        </LoginContainer>
    );
};
export default SsoLoggedOut;

import type {
    Breakpoint as BreakpointType,
    ValueElementIdReferenceAPI,
    BreakpointOperator,
} from '../../../../../types';
import { BREAKPOINT_OPERATORS } from '../../../../../constants/debugger';
import {
    ExIcon,
    ExIconButton,
    ExStructuredListBody,
    ExStructuredListCol,
    ExStructuredListRow,
    IconButtonFlavor,
} from '@boomi/exosphere';
import { getValueName, getValueType } from '../../../../values/selector/value-selector-utils';
import classNames from 'classnames';

interface Props {
    breakpoint: BreakpointType;
    edit: (breakpoint: BreakpointType) => void;
    removeBreakpoint: (id: string) => void;
    isHit: boolean;
}

const Breakpoint = ({ breakpoint, edit, removeBreakpoint, isHit }: Props) => {
    const { id, value, operator, expectedContentValue } = breakpoint;

    const selectedOperator = Object.values(BREAKPOINT_OPERATORS).find(
        (rule) => rule.criteria === operator,
    ) as BreakpointOperator;

    const isHitClasses = classNames({
        'break-points-hit': isHit,
    });

    return (
        <ExStructuredListBody key={id}>
            <ExStructuredListRow>
                <ExStructuredListCol className="short-col">
                    <ExIcon
                        data-testid={`${id}-is-hit`}
                        className={isHitClasses}
                        icon="stop-filled"
                    />
                </ExStructuredListCol>
                <ExStructuredListCol>
                    <div className="margin-bottom-small">
                        {getValueName(value as ValueElementIdReferenceAPI)}
                    </div>
                    <div>{getValueType(value as ValueElementIdReferenceAPI)}</div>
                </ExStructuredListCol>
                <ExStructuredListCol>{selectedOperator.label}</ExStructuredListCol>
                <ExStructuredListCol>{expectedContentValue}</ExStructuredListCol>
                <ExStructuredListCol>
                    <div className="flex">
                        <div className="flex gap-small flex-child-right">
                            <ExIconButton
                                data-testid={`edit-${id}`}
                                icon="Edit"
                                onClick={() => edit(breakpoint)}
                            />
                            <ExIconButton
                                data-testid={`remove-${id}`}
                                flavor={IconButtonFlavor.RISKY}
                                icon="trash"
                                onClick={() => removeBreakpoint(id)}
                            />
                        </div>
                    </div>
                </ExStructuredListCol>
            </ExStructuredListRow>
        </ExStructuredListBody>
    );
};

export default Breakpoint;

import PreviewComponentLabel from './previewComponentLabel/PreviewComponentLabel';

const Checkbox = ({ label, isRequired }: { label: string; isRequired: boolean }) => {
    return (
        <div
            data-testid="component-preview-checkbox"
            className="component-preview-input component-preview-checkbox"
        >
            <div className="form-group">
                <input type="checkbox" />
                <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            </div>
        </div>
    );
};

export default Checkbox;

const storagePrefix = 'boomi_flow_';

/**
 *
 * @param name The name of the item to retrieve
 *
 * @description Retrieves an item from the given storage object
 * @returns The item from storage
 */
export const getItem = <TItem>(name: string) => {
    try {
        const item = localStorage.getItem(`${storagePrefix}${name}`);

        if (!item) {
            return null;
        }

        return JSON.parse(item) as TItem;
    } catch {
        return null;
    }
};

/**
 *
 * @param name The name of the item to add to storage
 * @param value The value to add to storage
 *
 * @description Sets an item in the given storage object
 * @return The item added to storage
 */
export const setItem = <TItem>(name: string, value: unknown) => {
    try {
        const item = JSON.stringify(value);

        localStorage.setItem(`${storagePrefix}${name}`, item);

        return getItem<TItem>(name);
    } catch {
        return null;
    }
};

/**
 *
 * @param name The name of the item to remove
 *
 * @description Removes an item from the given storage object
 * @returns The removed item
 */
export const removeItem = <TItem = unknown>(name: string) => {
    try {
        const item = getItem<TItem>(name);

        localStorage.removeItem(`${storagePrefix}${name}`);

        return item;
    } catch {
        return null;
    }
};

import { createContext, useReducer, useContext, type ReactNode } from 'react';
import { isNil, isEmpty, lensPath, set } from 'ramda';
import { usePageEditor } from '../../PageEditorProvider';
import {
    pageConditionReducer,
    type PageConditionsState,
    type PageConditionsAction,
} from './PageConditionsReducer';
import type {
    ValueElementIdReferenceAPI,
    PageCondition,
    PageRule,
    PageOperation,
    Confirmation,
} from '../../../../../types';

import translations from '../../../../../translations';

interface PageConditionsProviderContext {
    state: PageConditionsState;
    updateCondition: (payload: PageCondition) => void;
    updateConditionIndex: (payload: number | null) => void;
    updateRuleIndex: (payload: number | null) => void;
    updateOperationIndex: (payload: number | null) => void;
    updateComparison: (payload: string) => void;
    updateRules: (payload: PageRule[]) => void;
    updateOperations: (payload: PageOperation[]) => void;
    deleteRule: (index: number) => void;
    deleteOperation: (index: number) => void;
    deleteCondition: (index: number) => void;
    applyRule: (rule: PageRule) => void;
    applyOperation: (operation: PageOperation) => void;
    applyCondition: () => void;
    updateValues: (payload: ValueElementIdReferenceAPI[] | null) => void;
    updateRefreshValues: (payload: boolean) => void;
}

const Context = createContext<PageConditionsProviderContext | undefined>(undefined);

interface PageConditionsProviderProps {
    initialState?: PageConditionsState | null | undefined;
    children: ReactNode;
}

const defaultState = {
    conditionIndex: null,
    ruleIndex: null,
    operationIndex: null,
    condition: null,
    confirmation: null,
    values: null,
    refreshValues: true,
};

const PageConditionProvider = ({ initialState, children }: PageConditionsProviderProps) => {
    const {
        state: pageBuilderState,
        setPageConditions,
        toggleUnsavedChanges,
        setModifiedPageConditions,
        container,
    } = usePageEditor();
    const { pageConditions } = pageBuilderState.page;

    // Helps with testing as it gives the option to pass in some customized initial state.
    const combinedInitialState =
        isNil(initialState) || isEmpty(initialState)
            ? defaultState
            : {
                  ...defaultState,
                  ...initialState,
              };
    const [state, dispatch] = useReducer(pageConditionReducer, combinedInitialState);

    const update = (action: PageConditionsAction) => dispatch(action);

    // Custom actions for handling the state stored in the context.
    const updateConfirmation = (payload: Confirmation | null) =>
        update({ type: 'update_confirmation', payload });

    const updateRefreshValues = (payload: boolean) => update({ type: 'refresh_values', payload });
    const updateValues = (payload: ValueElementIdReferenceAPI[] | null) =>
        update({ type: 'update_values', payload });

    const updateCondition = (payload: PageCondition) =>
        update({ type: 'update_condition', payload });

    const updateConditionIndex = (payload: number | null) =>
        update({ type: 'update_condition_index', payload });

    const updateRuleIndex = (payload: number | null) =>
        update({ type: 'update_rule_index', payload });

    const updateOperationIndex = (payload: number | null) =>
        update({ type: 'update_operation_index', payload });

    const updateComparison = (payload: string) => update({ type: 'update_comparison', payload });

    const updateRules = (payload: PageRule[]) => update({ type: 'update_rules', payload });

    const updateOperations = (payload: PageOperation[]) =>
        update({ type: 'update_operations', payload });

    const deleteRule = (index: number) => {
        updateConfirmation({
            show: true,
            title: translations.PAGE_BUILDER_delete_page_condition_rule_title,
            messages: [translations.PAGE_BUILDER_delete_page_condition_rule_message],
            buttonStyle: 'danger',
            buttonCaption: 'Delete',
            onCancel: () => updateConfirmation(null),
            onConfirm: () => {
                const { condition } = state;
                const conditionRuleRemoved = condition?.pageRules?.filter(
                    (_, i) => i !== index,
                ) as PageRule[];
                updateRules(conditionRuleRemoved);
                updateRuleIndex(null);
                updateConfirmation(null);
                setModifiedPageConditions(true);
            },
            container,
        });
    };

    const deleteOperation = (index: number) => {
        updateConfirmation({
            show: true,
            title: translations.PAGE_BUILDER_delete_page_condition_operation_title,
            messages: [translations.PAGE_BUILDER_delete_page_condition_operation_message],
            buttonStyle: 'danger',
            buttonCaption: 'Delete',
            onCancel: () => updateConfirmation(null),
            onConfirm: () => {
                const { condition } = state;
                const conditionOperationRemoved = condition?.pageOperations?.filter(
                    (_, i) => i !== index,
                ) as PageOperation[];
                updateOperations(conditionOperationRemoved);
                updateOperationIndex(null);
                updateConfirmation(null);
                setModifiedPageConditions(true);
            },
            container,
        });
    };

    const deleteCondition = (index: number) => {
        updateConfirmation({
            show: true,
            title: translations.PAGE_BUILDER_delete_page_condition_title,
            messages: [translations.PAGE_BUILDER_delete_page_condition_message],
            buttonStyle: 'danger',
            buttonCaption: 'Delete',
            onCancel: () => updateConfirmation(null),
            onConfirm: () => {
                const pageConditionRemoved = pageConditions?.filter(
                    (_, i) => i !== index,
                ) as PageCondition[];
                setPageConditions(pageConditionRemoved);
                updateConditionIndex(null);
                updateConfirmation(null);
                // Once the condition is deleted, this becomes a page metadata change,
                // so the page has to be saved now.
                setModifiedPageConditions(false);
                toggleUnsavedChanges(true);
            },
            container,
        });
    };

    const applyRule = (rule: PageRule) => {
        const { condition, ruleIndex } = state;

        if (ruleIndex !== null && ruleIndex >= 0) {
            // update the [ruleIndex]th rule
            const path = lensPath([ruleIndex]);
            updateRules(set(path, rule, condition?.pageRules) as PageRule[]);
        } else {
            // add a new rule
            updateRules([...(condition?.pageRules as PageRule[]), rule]);
        }
        setModifiedPageConditions(true);
    };

    const applyOperation = (operation: PageOperation) => {
        const { condition, operationIndex } = state;

        if (operationIndex !== null && operationIndex >= 0) {
            // update the [operationIndex]th operation
            const path = lensPath([operationIndex]);
            updateOperations(set(path, operation, condition?.pageOperations) as PageOperation[]);
        } else {
            // add a new operation
            updateOperations([...(condition?.pageOperations as PageOperation[]), operation]);
        }
        setModifiedPageConditions(true);
    };

    const applyCondition = () => {
        const { condition, conditionIndex } = state;
        // Page conditions can start out as null so make sure we got at least an empty array in that case.
        const currentPageConditions = isNil(pageConditions) ? [] : pageConditions;

        if (conditionIndex !== null && conditionIndex >= 0) {
            // update the [conditionIndex]th operation
            const path = lensPath([conditionIndex]);
            setPageConditions(set(path, condition, currentPageConditions) as PageCondition[]);
        } else {
            // add a new condition
            setPageConditions([...currentPageConditions, condition] as PageCondition[]);
        }

        // Once the new/updated condition is applied, this becomes a page metadata change,
        // so the page has to be saved now.
        setModifiedPageConditions(false);
        toggleUnsavedChanges(true);
    };

    const contextValue = {
        state,
        updateCondition,
        updateConditionIndex,
        updateRuleIndex,
        updateOperationIndex,
        updateComparison,
        updateRules,
        updateOperations,
        deleteRule,
        deleteOperation,
        deleteCondition,
        applyRule,
        applyOperation,
        applyCondition,
        updateValues,
        updateRefreshValues,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const usePageCondition = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('No page conditions context!');
    }
    return context;
};

export { PageConditionProvider, usePageCondition };

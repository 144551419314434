import translations from '../../../../../translations';
import type { PageComponentPreviewProps } from '../../../../../types/element';
import PreviewComponentLabel from '../previewComponentLabel/PreviewComponentLabel';

const PaymentCard = ({ label, isRequired, isEditable }: PageComponentPreviewProps) => {
    return (
        <div
            data-testid="component-preview-payment-card"
            className="component-preview-payment-card"
        >
            <PreviewComponentLabel
                className="component-preview-payment-card-label"
                labelText={label}
                isRequired={isRequired}
            />
            <div className="component-preview-payment-card-grid-container">
                <div className="component-preview-payment-card-input-container component-preview-payment-card-card-number-container">
                    <span className="component-preview-preview-payment-card-card-number-label">
                        {translations.PAGE_BUILDER_payment_card_card_number_input_label}
                    </span>
                    <input
                        tabIndex={-1}
                        className="component-preview-preview-payment-card-card-number-input"
                        disabled={!isEditable}
                    />
                </div>
                <div className="component-preview-payment-card-input-container payment-card-expiry-date-container">
                    <span className="component-preview-preview-payment-card-expiry-date-label">
                        {translations.PAGE_BUILDER_payment_card_expiry_date_input_label}
                    </span>
                    <input
                        tabIndex={-1}
                        className="component-preview-preview-payment-card-expiry-date-input"
                        disabled={!isEditable}
                    />
                </div>
                <div className="component-preview-payment-card-input-container component-preview-payment-card-security-code-container">
                    <span className="component-preview-preview-payment-card-security-code-label">
                        {translations.PAGE_BUILDER_payment_card_security_code_input_label}
                    </span>
                    <input
                        tabIndex={-1}
                        className="component-preview-preview-payment-card-security-code-input"
                        disabled={!isEditable}
                    />
                </div>
                <div className="component-preview-payment-card-input-container component-preview-payment-card-name-on-card-container">
                    <span className="component-preview-preview-payment-card-name-on-card-label">
                        {translations.PAGE_BUILDER_payment_card_name_on_card_input_label}
                    </span>
                    <input
                        tabIndex={-1}
                        className="component-preview-preview-payment-card-name-on-card-input"
                        disabled={!isEditable}
                    />
                </div>
                <div className="component-preview-payment-card-input-container component-preview-payment-card-postal-code-container">
                    <span className="component-preview-preview-payment-card-postal-code-label">
                        {translations.PAGE_BUILDER_payment_card_postal_code_input_label}
                    </span>
                    <input
                        tabIndex={-1}
                        className="component-preview-preview-payment-card-postal-code-input"
                        disabled={!isEditable}
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentCard;

import { Trash } from '@phosphor-icons/react';
import Table, { type TableColumnList } from '../generic/Table';
import translations from '../../translations';
import ButtonPrimary from '../buttons/ButtonPrimary';
import AddIntegrationAccountModal from './AddIntegrationAccountModal';
import { addNotification as addNotificationAction } from '../../../js/actions/reduxActions/notification';
import {
    removeIntegrationAccount,
    addIntegrationAccount,
} from '../../../js/actions/reduxActions/currentTenant';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import type { TenantIntegrationAccountAPI, TenantResponseAPI } from '../../types/Tenant';
import type { AddNotification } from '../../types';

interface Props {
    tenantId?: string | null;
    integrationAccounts?: TenantIntegrationAccountAPI[] | null;
    removeIntegrationAccount: (accountId: string) => void;
    addIntegrationAccount: (username: string, accountId: string, apiKey: string) => void;
    addNotification: AddNotification;
}

const IntegrationAccounts = ({
    tenantId,
    integrationAccounts,
    removeIntegrationAccount,
    addIntegrationAccount,
    addNotification,
}: Props) => {
    const [showAddModal, setShowAddModal] = useState(false);

    // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
    const bullets = new Array(6).map((_, i) => <React.Fragment key={i}>&bull;</React.Fragment>);

    const columns: TableColumnList<TenantIntegrationAccountAPI> = [
        {
            renderHeader: () => translations.TENANT_integration_accounts_username,
            renderCell: ({ item }) => item.username,
        },
        {
            renderHeader: () => translations.TENANT_integration_accounts_account_id,
            renderCell: ({ item }) => item.accountId,
        },
        {
            renderHeader: () => translations.TENANT_integration_accounts_api_key,
            renderCell: ({ item }) => (
                <React.Fragment key={item.accountId}>
                    {item.apiKey}
                    {bullets}
                </React.Fragment>
            ),
        },
        {
            renderHeader: () => 'Actions',
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={translations.COMMON_delete}
                        className="table-icon table-icon-delete"
                        onClick={() => {
                            removeIntegrationAccount(item.accountId);
                        }}
                        data-testid="integration-account-delete"
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    const onAdd = () => setShowAddModal(true);
    const onClose = () => setShowAddModal(false);

    const onAdded = (username: string, accountId: string, apiKey: string) => {
        setShowAddModal(false);
        addIntegrationAccount(username, accountId, apiKey);
    };

    return (
        <>
            <ButtonPrimary onClick={onAdd}>
                {translations.TENANT_integration_accounts_add}
            </ButtonPrimary>
            {integrationAccounts ? (
                <Table<TenantIntegrationAccountAPI>
                    tableClass="generic-table margin-top"
                    items={integrationAccounts}
                    columns={columns}
                />
            ) : null}
            {tenantId ? (
                <AddIntegrationAccountModal
                    tenantId={tenantId}
                    show={showAddModal}
                    onAdded={onAdded}
                    onClose={onClose}
                    addNotification={addNotification}
                />
            ) : null}
        </>
    );
};

const mapStateToProps = ({ currentTenant }: { currentTenant: TenantResponseAPI }) => ({
    tenantId: currentTenant?.id,
    integrationAccounts: currentTenant?.tenantSettings?.integrationAccounts,
});

const mapDispatchToProps = {
    addNotification: addNotificationAction,
    removeIntegrationAccount,
    addIntegrationAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationAccounts);

import type { ComposerElement } from '../../../../../../types';
import ButtonDefault from '../../../../../buttons/ButtonDefault';
import { usePageEditor } from '../../../PageEditorProvider';

interface Props {
    elementToConfigure: ComposerElement | null | undefined;
    onBack: (parentId: string) => void;
    children: string;
}

const ElementConfigHeader = ({ elementToConfigure, onBack, children }: Props) => {
    const { getComponent } = usePageEditor();

    if (!elementToConfigure) {
        return null;
    }

    const componentType = elementToConfigure.componentType;
    const component = componentType ? getComponent(componentType) : null;
    const canGoBack = component?.ui?.canGoBack && elementToConfigure.parentId;
    const heading = component?.ui?.heading;

    return (
        <div className="flex">
            <h2 className="sidebar-main-heading">{heading ?? children}</h2>
            {canGoBack ? (
                <ButtonDefault
                    className="flex-child-right"
                    title="Back"
                    onClick={() => onBack(elementToConfigure.parentId as string)}
                >
                    Back
                </ButtonDefault>
            ) : null}
        </div>
    );
};

export default ElementConfigHeader;

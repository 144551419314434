import { useEffect, useState } from 'react';
import type { OrganizationInvite } from '../../sources/organization';
import translations from '../../translations';
import Table, { type TableColumnList } from '../generic/Table';
import { notifyError } from '../../../js/actions/reduxActions/notification';
import { connect, type ConnectedProps } from 'react-redux';

type ReceivedInvitesProps = {
    caption: string;
    invitations: OrganizationInvite[];
    loadInvitations: () => Promise<void>;
    matchInvite: (invite: OrganizationInvite) => boolean;
    onAccept: (id: string) => Promise<void>;
    onReject: (id: string) => Promise<void>;
    onRefresh: () => Promise<unknown>;
} & ConnectedProps<typeof connector>;

const dateTimerFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium',
    timeStyle: 'short',
});

const mapDispatchToProps = {
    notifyError,
};

const connector = connect(null, mapDispatchToProps);

const ReceivedInvites = ({
    caption,
    invitations,
    loadInvitations,
    matchInvite,
    onAccept,
    onReject,
    onRefresh,
}: ReceivedInvitesProps) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchInvitations = async () => {
            setIsLoading(true);
            await loadInvitations();
            setIsLoading(false);
        };

        fetchInvitations();
    }, [loadInvitations]);

    const handleAccept = async (id: string) => {
        try {
            setIsLoading(true);

            await onAccept(id);
            await onRefresh();
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleReject = async (id: string) => {
        try {
            setIsLoading(true);

            await onReject(id);
            await onRefresh();
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const pendingInvitations = invitations.filter((item) => {
        const isMatch = matchInvite(item);
        const isActionable =
            item.status !== 'accepted' &&
            item.status !== 'rejected' &&
            item.status !== 'canceled' &&
            item.status !== 'acknowledged';
        return isMatch && isActionable;
    });

    const columns: TableColumnList<OrganizationInvite> = [
        {
            renderHeader: () => translations.COMMON_TABLE_organization,
            renderCell: ({ item: invite }) => (
                <span title={`ID: ${invite.organization.id}`}>{invite.organization.name}</span>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status,
            renderCell: ({ item: invite }) => <span className="capitalize">{invite.status}</span>,
            size: '20%',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_date_sent,
            renderCell: ({ item: invite }) => dateTimerFormatter.format(new Date(invite.invitedAt)),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item: invite }) => (
                <div className="action-btn-wrapper">
                    <button
                        className="btn btn-primary"
                        onClick={() => handleAccept(invite.organization.id)}
                        type="button"
                    >
                        {translations.FORG_received_invite_list_accept_invite}
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => handleReject(invite.organization.id)}
                        type="button"
                    >
                        {translations.FORG_received_invite_list_reject_invite}
                    </button>
                </div>
            ),
            size: '11rem',
        },
    ];

    return (
        <Table
            caption={caption}
            wrapperClassName="margin-top margin-bottom-large"
            isLoading={isLoading}
            columns={columns}
            items={pendingInvitations}
            pagination={true}
        />
    );
};

export default connector(ReceivedInvites);

import type { XYCoord } from '../../types';

/** Adds x and y properties of `pos2` to `pos1` */
export const addPositions = <T1 extends XYCoord, T2 extends XYCoord>(pos1: T1, pos2: T2): T1 => ({
    ...pos1,
    x: pos1.x + pos2.x,
    y: pos1.y + pos2.y,
});

/** Subtracts x and y properties of `pos2` from `pos1` */
export const subtractPositions = <T1 extends XYCoord, T2 extends XYCoord>(
    pos1: T1,
    pos2: T2,
): T1 => ({
    ...pos1,
    x: pos1.x - pos2.x,
    y: pos1.y - pos2.y,
});

import type { MapElementType, NodeStyle } from '../../../types';

const BASE_NODE_STYLES = {
    height: 30,
    width: 120,
    borderRadius: 5,
    borderWidth: 5,
};

export const NODE_STYLES: Record<MapElementType, NodeStyle> = {
    START: {
        ...BASE_NODE_STYLES,
        width: 60,
        color: '#9BD5B9',
        borderRadius: 12,
        iconName: 'Flag',
    },
    input: {
        ...BASE_NODE_STYLES,
        color: '#3392DB',
        iconName: 'Layout',
    },
    database_load: {
        ...BASE_NODE_STYLES,
        color: '#FA4B6D',
        iconName: 'DownloadSimple',
    },
    message: {
        ...BASE_NODE_STYLES,
        color: '#235A41',
        iconName: 'EnvelopeSimple',
    },
    operator: {
        ...BASE_NODE_STYLES,
        color: '#157E56',
        iconName: 'MathOperations',
    },
    group: {
        ...BASE_NODE_STYLES,
        color: '#B49BC9',
        iconName: 'GroupIcon',
    },
    note: {
        ...BASE_NODE_STYLES,
        color: '#A580BA',
        iconName: 'Note',
    },
    database_delete: {
        ...BASE_NODE_STYLES,
        color: '#8A3243',
        iconName: 'Trash',
    },
    step: {
        ...BASE_NODE_STYLES,
        color: '#67ABE1',
        iconName: 'Browser',
    },
    modal: {
        ...BASE_NODE_STYLES,
        color: '#135097',
        iconName: 'Browsers',
    },
    decision: {
        ...BASE_NODE_STYLES,
        color: '#0EA076',
        iconName: 'DecisionIcon',
    },
    wait: {
        ...BASE_NODE_STYLES,
        color: '#234735',
        iconName: 'Clock',
    },
    database_save: {
        ...BASE_NODE_STYLES,
        color: '#C73D58',
        iconName: 'FloppyDiskBack',
    },
    subflow: {
        ...BASE_NODE_STYLES,
        color: '#808080',
        iconName: 'SubflowIcon',
    },
    return: {
        ...BASE_NODE_STYLES,
        color: '#ABABAB',
        iconName: 'KeyReturn',
    },
    swimlane: {
        ...BASE_NODE_STYLES,
        color: '#EFA037',
        iconName: 'IdentificationBadge',
    },
    process: { ...BASE_NODE_STYLES, color: '#234735', iconName: 'ProcessIcon' },
    openapi: { ...BASE_NODE_STYLES, color: '#4F1C26', iconName: 'OpenApiIcon' },
};

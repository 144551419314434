import RuleSummary from './RuleSummary';
import OperationSummary from './OperationSummary';
import React from 'react';
import type { OperationSummaryData, RuleSummaryData } from '../../../../../../types';

interface Props {
    comparisonType: string | null;
    ruleData: RuleSummaryData[];
    operationData: OperationSummaryData[];
}

const ConditionSummary = ({ comparisonType, ruleData, operationData }: Props) => {
    const ruleSummaries = ruleData.map((rule, index) => {
        return index >= 1 ? (
            // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
            <React.Fragment key={index}>
                <span className="summary-keyword"> {comparisonType || 'AND'}</span>{' '}
                <RuleSummary {...rule} />
            </React.Fragment>
        ) : (
            // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
            <RuleSummary key={index} {...rule} />
        );
    });

    const operationSummaries = operationData.map((operation, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: No alternative to index
        <OperationSummary key={index} {...operation} />
    ));

    return (
        <div className="formatted-condition-summary" data-testid="formatted-condition-summary">
            {ruleSummaries}
            <span className="summary-keyword">THEN</span>
            <br />
            {operationSummaries}
        </div>
    );
};

export default ConditionSummary;

const Input = () => (
    <div className="page-component-input page-component">
        <label htmlFor="input" className="label">
            What is your name?
        </label>
        <input data-testid="page-component-input" className="input" id="input" type="text" />
    </div>
);

export default Input;

import type { BreakpointOperator } from '../types';

export const BREAKPOINT_OPERATORS: {
    [key: string]: BreakpointOperator;
} = {
    equals: { criteria: 'EQUAL', label: 'Equals' },
    notEquals: { criteria: 'NOT_EQUAL', label: 'Not equal' },
    greaterThan: { criteria: 'GREATER_THAN', label: 'Greater than' },
    greaterThanEqualTo: { criteria: 'GREATER_THAN_OR_EQUAL', label: 'Greater than or equal to' },
    lessThan: { criteria: 'LESS_THAN', label: 'Less than' },
    lessThanEqualTo: { criteria: 'LESS_THAN_OR_EQUAL', label: 'Less than or equal to' },
};

export const CONTENT_TYPE_TO_RULES: { [key: string]: string[] } = {
    ContentString: [
        'EQUAL',
        'NOT_EQUAL',
        'GREATER_THAN',
        'GREATER_THAN_OR_EQUAL',
        'LESS_THAN',
        'LESS_THAN_OR_EQUAL',
    ],
    ContentNumber: [
        'EQUAL',
        'NOT_EQUAL',
        'GREATER_THAN',
        'GREATER_THAN_OR_EQUAL',
        'LESS_THAN',
        'LESS_THAN_OR_EQUAL',
    ],
    ContentBoolean: ['EQUAL'],
    ContentDate: [
        'EQUAL',
        'NOT_EQUAL',
        'GREATER_THAN',
        'GREATER_THAN_OR_EQUAL',
        'LESS_THAN',
        'LESS_THAN_OR_EQUAL',
    ],
    ContentDateTime: [
        'EQUAL',
        'NOT_EQUAL',
        'GREATER_THAN',
        'GREATER_THAN_OR_EQUAL',
        'LESS_THAN',
        'LESS_THAN_OR_EQUAL',
    ],
    ContentContent: [
        'EQUAL',
        'NOT_EQUAL',
        'GREATER_THAN',
        'GREATER_THAN_OR_EQUAL',
        'LESS_THAN',
        'LESS_THAN_OR_EQUAL',
    ],
    ContentCode: [
        'EQUAL',
        'NOT_EQUAL',
        'GREATER_THAN',
        'GREATER_THAN_OR_EQUAL',
        'LESS_THAN',
        'LESS_THAN_OR_EQUAL',
    ],
};

import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { deleteElements, getFlowGraph2 } from '../../../sources/graph';
import type { MouseEventPayload, FlowEditorState, AddMapElementPayload } from './types';
import type { Dimensions, FlowGraphResponse2API, Point } from '../../../types';
import {
    onAddElement,
    onCloseConfig,
    onDelete,
    onDoubleClick,
    onGraphResponse,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseUpFulfilled,
    onMouseUpOutsideGraph,
    onResize,
    setZoom,
} from './eventHandlers';
import type { FlowConfigEditorType } from '../render/GraphConfigEditor';
import store from '../../../../js/store/store';
import { notifyError } from '../../../../js/actions/reduxActions/notification';
import type { AnyAction } from 'redux';

const initialState: FlowEditorState = {
    flowId: null,
    mapElements: [],
    groupElements: [],
    selectedObjectIds: [],
    focusedObjectId: null,
    dragSnapshot: null,
    flowchart: {
        zoom: 0.6,
        viewBox: { x: 0, y: 0, width: 0, height: 0 },
        leafNodes: {},
        groupNodes: {},
        edges: {},
        selectionBox: null,
        selectionMarquee: null,
    },
    openEditorName: null,
};

/**
 * Async thunk actions
 */
export const fetchGraph = createAsyncThunk<FlowGraphResponse2API, string>(
    'flow/fetch',
    getFlowGraph2,
);
export const onMouseUpAction = createAsyncThunk<FlowConfigEditorType | null, MouseEventPayload>(
    'flowchart/mouseup',
    async (payload, thunkAPI) => {
        const state = thunkAPI.getState() as FlowEditorState;
        const dispatch = thunkAPI.dispatch;
        const editorToShow = await onMouseUp(state, payload, dispatch);
        return editorToShow;
    },
);
export const onConfirmDeleteAction = createAsyncThunk<void, string>(
    'flowchart/leafNode/delete',
    async (objectId, thunkAPI) => {
        const { getState, dispatch } = thunkAPI;
        const state = getState() as FlowEditorState;

        if (!state.flowId) {
            return;
        }

        const elementIdsToDelete =
            objectId === 'selectionBox' ? state.selectedObjectIds : [objectId];

        const mapElementIds: string[] = [];
        const groupElementIds: string[] = [];

        elementIdsToDelete.forEach((id) => {
            const isGroupElementId = state.flowchart.groupNodes[id] !== undefined;

            if (isGroupElementId) {
                groupElementIds.push(id);
            } else {
                mapElementIds.push(id);
            }
        });

        try {
            await deleteElements(state.flowId, {
                mapElementIds,
                groupElementIds,
            });
            await dispatch(fetchGraph(state.flowId));
            dispatch(onCloseConfigAction());
        } catch (error) {
            store.dispatch(notifyError(error) as unknown as AnyAction);
        }
    },
);

/**
 * Synchronous actions
 */
export const onSVGResizeAction = createAction<Dimensions>('flowchart/resize');
export const onWheelAction = createAction<{ amount: number; SVGPoint: Point }>('flowchart/wheel');
export const onMouseDownAction = createAction<MouseEventPayload>('flowchart/mousedown');
export const onMouseMoveAction = createAction<MouseEventPayload>('flowchart/mousemove');
export const onDoubleClickAction = createAction('flowchart/doubleclick');
export const onDeleteAction = createAction<boolean>('flowchart/delete');
export const onAddElementAction = createAction<AddMapElementPayload>('mapElement/add');
export const onCloseConfigAction = createAction('config/close');
export const onMouseUpOutsideGraphAction = createAction('outsideGraph/mouseup');

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(onSVGResizeAction, (state, action) => {
            onResize(state, action.payload);
        })
        .addCase(fetchGraph.fulfilled, (state, action) => {
            onGraphResponse(state, action.payload);
        })
        .addCase(onMouseDownAction, (state, action) => {
            onMouseDown(state, action.payload);
        })
        .addCase(onMouseMoveAction, (state, action) => {
            onMouseMove(state, action.payload);
        })
        .addCase(onDoubleClickAction, (state) => {
            onDoubleClick(state);
        })
        .addCase(onDeleteAction, (state, action) => {
            onDelete(state, action.payload);
        })
        .addCase(onMouseUpAction.fulfilled, (state, action) => {
            onMouseUpFulfilled(state, action.payload);
        })
        .addCase(onAddElementAction, (state, action) => {
            onAddElement(state, action.payload);
        })
        .addCase(onCloseConfigAction, (state) => {
            onCloseConfig(state);
        })
        .addCase(onWheelAction, (state, action) => {
            setZoom(state, action.payload);
        })
        .addCase(onMouseUpOutsideGraphAction, (state) => {
            onMouseUpOutsideGraph(state);
        });
});

import actionTypes from '../actions/reduxActions/actionTypes';

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const notifications = (state = [], { type, payload }) => {
    switch (type) {
        case actionTypes.NOTIFICATION_ADD:
            return [...state, payload];

        case actionTypes.NOTIFICATION_REMOVE:
            return state.filter((notification) => notification.id !== payload);

        case actionTypes.REMOVE_ALL_NOTIFICATIONS:
            return [];

        default:
            return state;
    }
};

export default notifications;

import { type KeyboardEventHandler, useEffect, useState } from 'react';
import Modal from '../generic/modal/GenericModal';
import { RUNTIME_URI } from '../../constants';
import { getDevelopmentEnvironment } from '../../sources/environments';
import { snapshotFlow } from '../../sources/build';
import ClipboardInput from '../inputs/ClipboardInput';
import Loader from '../loader/Loader';
import FormGroup from '../generic/FormGroup';
import ExistingTranslationsSelect from '../translations/ExistingTranslationsSelect';
import type { CultureApi } from '../../types/translation';
import classnames from 'classnames';
import { useRun } from '../graph/RunProvider';
import type { NotifyError } from '../../types';
import { getTenantId } from '../../utils/tenant';

interface Props {
    flowId: string;
    onClose: () => void;
    container?: HTMLElement | null;
    environmentsEnabled: boolean;
    themesEnabled: boolean;
    notifyError: NotifyError;
}

const Run = ({
    flowId,
    onClose,
    container,
    environmentsEnabled,
    themesEnabled,
    notifyError,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [flowVersionId, setFlowVersionId] = useState<string | null>(null);
    const [culture, setCulture] = useState<CultureApi | undefined>(undefined);
    const [reportingMode, setReportingMode] = useState<string | undefined>(undefined);
    const [developmentEnvironmentId, setDevelopmentEnvironmentId] = useState<string | null>(null);
    const runOption = themesEnabled ? 'Theme' : 'Player';

    const tenantId = getTenantId();

    const { player, theme, setPlayer, setTheme, players, themes, getPlayers, getThemes } = useRun();

    const build = async () => {
        setLoading(true);
        setError(null);

        try {
            const [snapshot] = await Promise.all([
                snapshotFlow({ flowId, comment: null }),
                getPlayers(),
                getThemes(),
            ]);

            setFlowVersionId(snapshot.id.versionId);

            if (environmentsEnabled) {
                const environment = await getDevelopmentEnvironment();
                setDevelopmentEnvironmentId(environment.id);
            }
        } catch (e) {
            setError((e as Error).message);
        } finally {
            setLoading(false);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        build();
    }, []);

    const onClickDebug = () => {
        window.open(`${getUrl()}&mode=DEBUG`);
        onClose();
    };

    const onClickDebugStepThrough = () => {
        window.open(`${getUrl()}&mode=DEBUG_STEPTHROUGH`);
        onClose();
    };

    const onKeydown: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (!loading) {
            let runMode = 'none';

            if (e.keyCode === 13) {
                // Enter opens the Flow
                runMode = '';
            }

            if (e.keyCode === 68 && e.ctrlKey) {
                // Ctrl+D opens the Flow with Debug on
                runMode = '&mode=DEBUG';
            }

            if (e.keyCode === 83 && e.ctrlKey) {
                // Ctrl+S opens the Flow with Debug Stepthrough on
                runMode = '&mode=DEBUG_STEPTHROUGH';
            }

            if (runMode !== 'none') {
                if (window) {
                    window.open(`${getUrl()}${runMode}`, '_blank');
                }
                e.preventDefault();
            }
        }

        return false;
    };

    const getUrl = () => {
        let url =
            runOption === 'Player'
                ? `${RUNTIME_URI}/${tenantId}/play/${player}?`
                : `${RUNTIME_URI}/${tenantId}/play/theme/${theme}?`;

        const parameters = [`flow-id=${flowId}`];

        if (flowVersionId) {
            parameters.push(`flow-version-id=${flowVersionId}`);
        }

        if (environmentsEnabled && developmentEnvironmentId) {
            parameters.push(`environment-id=${developmentEnvironmentId}`);
        }

        if (reportingMode) {
            parameters.push(`reporting-mode=${reportingMode}`);
        }

        if (culture && !culture.isDefault) {
            // New cultures will have a null brand, country, language and variant, for these
            // we want to stick the code e.g. en-US in the url as its nicer
            if (culture?.brand || culture?.country || culture?.language || culture?.variant) {
                parameters.push(`culture=${culture.id}`);
            } else {
                parameters.push(`culture=${culture.code}`);
            }
        }

        url += parameters.join('&');

        return url;
    };

    return (
        <Modal
            show
            onHide={onClose}
            container={container}
            className="config-modal"
            title="Run"
            renderBody={() => {
                if (error) {
                    return (
                        <div className="text-center">
                            <h3>Build Failed</h3>
                            <p>{error}</p>
                            <button className="btn btn-primary" onClick={build} type="button">
                                Retry
                            </button>
                        </div>
                    );
                }
                if (loading) {
                    return <Loader />;
                }
                return (
                    <div role="presentation" onKeyDown={onKeydown}>
                        {runOption === 'Theme' && (
                            <FormGroup label="Theme" htmlFor="run-select-theme">
                                <select
                                    className="form-control form-select"
                                    id="run-select-theme"
                                    value={theme}
                                    onChange={(e) => {
                                        setTheme(e.target.value);
                                    }}
                                >
                                    {themes.map((theme) => (
                                        <option key={theme} value={theme}>
                                            {theme}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        )}
                        {runOption === 'Player' && (
                            <FormGroup label="Player" htmlFor="run-select-player">
                                <select
                                    className="form-control form-select"
                                    id="run-select-player"
                                    value={player}
                                    onChange={(e) => {
                                        setPlayer(e.target.value);
                                    }}
                                >
                                    {players.map((player) => (
                                        <option key={player} value={player}>
                                            {player}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        )}
                        <FormGroup
                            label="Reporting Mode"
                            htmlFor="run-select-reporting"
                            className="margin-top"
                        >
                            <select
                                className="form-control form-select"
                                id="run-select-reporting"
                                value={reportingMode}
                                onChange={(e) => {
                                    setReportingMode(e.target.value);
                                }}
                            >
                                <option value="">None</option>
                                <option value="PATH">Path</option>
                                <option value="PATH_AND_VALUES">Path & Values</option>
                            </select>
                        </FormGroup>
                        <ExistingTranslationsSelect
                            value={culture?.id}
                            onChange={setCulture}
                            flowId={flowId}
                            onError={notifyError}
                            margin={true}
                        />
                        <FormGroup
                            label="Url"
                            htmlFor="run-select-reporting"
                            className="margin-top"
                        >
                            <ClipboardInput value={getUrl()} buttonTitle="Copy URL" />
                        </FormGroup>
                    </div>
                );
            }}
            renderFooter={() => (
                <>
                    <a
                        className={classnames('btn btn-success', { disabled: loading })}
                        href={getUrl()}
                        target="_blank"
                        rel="noopener noreferrer"
                        id="run-flow"
                    >
                        {'Run'}
                    </a>
                    <button
                        className={classnames('btn btn-primary', { disabled: loading })}
                        onClick={onClickDebug}
                        type="button"
                    >
                        {'Debug'}
                    </button>
                    <button
                        className={classnames('btn btn-success', { disabled: loading })}
                        onClick={onClickDebugStepThrough}
                        type="button"
                    >
                        {'Debug Step-Through'}
                    </button>
                </>
            )}
        />
    );
};

export default Run;

import FormGroup from '../../../generic/FormGroup';
import type { DispatchFlowConfiguration } from './reducer';

interface Props {
    developerSummary: string | null;
    dispatchFlowConfiguration: DispatchFlowConfiguration;
}

const Comments = ({ developerSummary, dispatchFlowConfiguration }: Props) => (
    <FormGroup htmlFor="properties-comment-input" label="Description">
        <textarea
            id="properties-comment-input"
            className="form-control"
            maxLength={255}
            rows={5}
            value={developerSummary ?? ''}
            onChange={({ target }) => {
                dispatchFlowConfiguration({
                    type: 'updateSummary',
                    payload: {
                        developerSummary: target.value,
                    },
                });
            }}
            required
        />
    </FormGroup>
);

export default Comments;

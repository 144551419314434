import ButtonPrimary from '../../../buttons/ButtonPrimary';
import Glyphicon from '../../../generic/Glyphicon';
import Table from '../../../generic/Table';
import type { DynamicProcessProperty, ValueElementIdAPI } from '../../../../types';
import translations from '../../../../translations';
import { Trash } from '@phosphor-icons/react';
import ValueSelectorModal from '../../../values/selector/ValueSelectorModal';

interface Props {
    value: DynamicProcessProperty[];
    onChange: (value: DynamicProcessProperty[]) => void;
    container: HTMLElement | null;
}

const DynamicProcessPropertiesTable = ({ value, onChange, container }: Props) => {
    const onSetPropertyName = (name: string, index: number) => {
        const property = value[index];

        if (property) {
            property.name = name;
        }
        onChange([...value]);
    };

    const onSetPropertyValue = (propertyValue: ValueElementIdAPI | null, index: number) => {
        const property = value[index];

        if (property && property.value !== propertyValue) {
            if (propertyValue) {
                property.value = propertyValue;
            } else {
                property.value = null;
            }

            onChange([...value]);
        }
    };

    const onRemoveProperty = (index: number) => {
        value.splice(index, 1);
        onChange([...value]);
    };

    const onAddProperty = () => {
        onChange([
            ...value,
            {
                name: 'new property',
                value: null,
            },
        ]);
    };

    const columns = [
        {
            renderHeader: () => translations.PROCESS_dynamic_process_property_column_key,
            renderCell: ({
                item,
                rowIndex,
            }: {
                item: DynamicProcessProperty;
                rowIndex: number;
            }) => (
                <input
                    value={item.name}
                    onChange={({ target: { value } }) => onSetPropertyName(value, rowIndex)}
                    className="form-control"
                    type="text"
                    data-testid={`dynamic-process-property-${rowIndex}`}
                />
            ),
        },
        {
            renderHeader: () => translations.PROCESS_dynamic_process_property_column_value,
            renderCell: ({
                item,
                rowIndex,
            }: {
                item: DynamicProcessProperty;
                rowIndex: number;
            }) => (
                <ValueSelectorModal
                    includeSystemValues={true}
                    container={container}
                    value={item.value}
                    onChange={(value: ValueElementIdAPI | null) =>
                        onSetPropertyValue(value, rowIndex)
                    }
                />
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({
                item,
                rowIndex,
            }: {
                item: DynamicProcessProperty;
                rowIndex: number;
            }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`${translations.COMMON_delete} ${item.name}`}
                        className="table-icon table-icon-delete"
                        onClick={() => onRemoveProperty(rowIndex)}
                        aria-label={`${translations.COMMON_delete} ${item.name}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <h4>{translations.PROCESS_dynamic_process_property_heading}</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onAddProperty}>
                    <Glyphicon glyph="plus" />
                    {translations.PROCESS_dynamic_process_property_add}
                </ButtonPrimary>
            </div>
            <Table<DynamicProcessProperty>
                columns={columns}
                tableClass="generic-table margin-top margin-bottom"
                items={value}
                testId="dynamic-process-properties"
            />
        </>
    );
};

export default DynamicProcessPropertiesTable;

import translations from '../../../../../translations';
import type { PageComponentPreviewProps } from '../../../../../types/element';
import PreviewComponentLabel from '../previewComponentLabel/PreviewComponentLabel';

const Tiles = ({ label, columns, isRequired, isSearchable }: PageComponentPreviewProps) => {
    const sortedFilteredColumns = columns
        ?.filter((column) => column.isDisplayValue)
        ?.sort((a, b) => a.order - b.order);

    if (!sortedFilteredColumns || sortedFilteredColumns.length === 0) {
        return <p>{translations.PAGE_BUILDER_no_columns}</p>;
    }

    const title = sortedFilteredColumns[0]?.typeElementPropertyDeveloperName;
    const description = sortedFilteredColumns[1]?.typeElementPropertyDeveloperName;
    const footerColumns = sortedFilteredColumns.slice(2);

    return (
        <div data-testid="component-preview-tiles" className="component-preview-tiles">
            <PreviewComponentLabel labelText={label} isRequired={isRequired} />
            {isSearchable ? (
                <div className="component-preview-input margin-bottom form-control-width">
                    <input placeholder="Search" type="text" />
                </div>
            ) : null}
            <div className="preview-tiles">
                <h2>{title}</h2>
                <div className="body">{description}</div>
                {footerColumns.map(
                    ({ label, typeElementPropertyDeveloperName, typeElementPropertyId }) => (
                        <div key={typeElementPropertyId}>
                            {label?.trim() && <>{label}: </>}
                            {typeElementPropertyDeveloperName}
                        </div>
                    ),
                )}
            </div>
        </div>
    );
};

export default Tiles;

import GenericModal from '../../../../generic/modal/GenericModal';
import ButtonDefault from '../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import { useCallback, useRef, useState } from 'react';
import { CONTENT_TYPES_LIST } from '../../../../../constants';
import type {
    ValueElementIdReferenceAPI,
    ComponentRegistry,
    ComposerElement,
    TypeElementResponseAPI,
} from '../../../../../types';
import Table from '../../preview/Table';
import SearchInput from '../../../../generic/SearchInput';
import { stringContains, stringReplace } from '../../../../../utils/string';
import { COMPONENT_TYPE, PAGE_ELEMENT_TYPES } from '../../../constants';
import { getValidProperties, invalidContentTypesString } from './constants';
import Loader from '../../../../loader/Loader';
import translations from '../../../../../translations';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import { usePageEditor } from '../../PageEditorProvider';
import { useComposer } from '../composer/ComposerProvider';
import { getValueName } from '../../../../values/selector/value-selector-utils';

const ComponentSuggestColumns = ({
    selectedComponent,
    goToOptionsScreen,
    type,
    mainContainer,
    order,
}: {
    selectedComponent: ComponentRegistry;
    goToOptionsScreen: () => void;
    type: TypeElementResponseAPI | null;
    mainContainer: string;
    order: number;
}) => {
    const { state, container, setStopComponentSuggest } = usePageEditor();
    const { onPageElementDrop } = useComposer();
    const componentSuggestValue = state.componentSuggestValue as ValueElementIdReferenceAPI;

    const checkboxRef = useRef<HTMLInputElement>();

    const validColumns = getValidProperties(type);
    const wereAnyColumnsObjectList =
        type?.properties?.length && type?.properties?.length > validColumns.length;

    const [checkedColumns, setCheckedColumns] = useState<string[]>(
        validColumns?.map((prop) => prop.id) ?? [],
    );
    const manageSetCheckedColumns = (newColumns: string[]) => {
        if (checkboxRef && checkboxRef.current && checkboxRef.current.indeterminate !== undefined) {
            checkboxRef.current.indeterminate =
                newColumns?.length > 0 && newColumns?.length !== validColumns?.length;
        }
        setCheckedColumns(newColumns);
    };

    const selectedProperties = (validColumns
        ?.filter((property) => checkedColumns.includes(property.id))
        ?.map((property, index) => ({
            isDisplayValue: true,
            label: property.developerName,
            order: index,
            typeElementPropertyDeveloperName: property.developerName,
            typeElementPropertyId: property.id,
        })) ?? []) as ComposerElement['columns'];

    const [columnSearch, setColumnSearch] = useState<string>('');
    const toggleColumn = (id: string) =>
        checkedColumns.includes(id)
            ? manageSetCheckedColumns(checkedColumns.filter((column) => column !== id))
            : manageSetCheckedColumns([...checkedColumns, id]);

    const createMultiColumnsComponent = () => {
        let partialElement = {} as Partial<ComposerElement>;

        partialElement = {
            developerName: `"${getValueName(componentSuggestValue)}"`,
            valueElementDataBindingReferenceId: {
                id: componentSuggestValue.id,
                typeElementPropertyId: componentSuggestValue.typeElementPropertyId as string,
                command: null,
                relativeUnit: null,
            },
            columns: selectedProperties,
        };

        onPageElementDrop({
            id: null,
            targetId: mainContainer,
            order,
            pageElementType: PAGE_ELEMENT_TYPES['component'],
            componentType: selectedComponent.type.toLowerCase(),
            select: true,
            partialElement,
        });
        setStopComponentSuggest();
    };

    return (
        <GenericModal
            container={container}
            show
            className="config-modal component-suggestion-modal"
            onHide={setStopComponentSuggest}
            title={translations.COMPONENT_SUGGEST_multiple_columns_view_title}
            animation={false}
            bodyClassName="flex"
            // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
            renderBody={useCallback(() => {
                if (type === null) {
                    return <Loader />;
                }

                if (validColumns.length === 0) {
                    return (
                        <>
                            {stringReplace(
                                translations.COMPONENT_SUGGEST_list_has_no_valid_properties as string,
                                invalidContentTypesString,
                            )}
                        </>
                    );
                }

                const suggestColumns = validColumns
                    ?.filter((property) =>
                        stringContains(property?.developerName, columnSearch, false),
                    )
                    ?.map((property) => (
                        <div
                            className="suggest-column"
                            key={property.id}
                            title={property.developerName}
                        >
                            <input
                                type="checkbox"
                                id={property.id}
                                onChange={() => toggleColumn(property.id)}
                                checked={checkedColumns.includes(property.id)}
                            />
                            <label htmlFor={property.id}>
                                <span className="column-name">{property.developerName}</span>
                                <span className="suggest-info">
                                    {CONTENT_TYPES_LIST?.find(
                                        (item) => item.key === property.contentType?.toUpperCase(),
                                    )?.label ?? ''}
                                </span>
                            </label>
                        </div>
                    ));

                const areAllSelected = checkedColumns.length === validColumns?.length;
                const selectDeselectText = areAllSelected ? 'De-select All' : 'Select All';

                return (
                    <div className="suggest-columns-preview">
                        <div className="left-section">
                            {wereAnyColumnsObjectList && (
                                <ExAlertBanner
                                    className="margin-bottom"
                                    type={AlertBannerType.INFO}
                                    open
                                >
                                    {invalidContentTypesString} properties have been removed
                                </ExAlertBanner>
                            )}
                            <SearchInput
                                value={columnSearch}
                                onChange={(value) => setColumnSearch(value)}
                                placeholder={'Search for columns'}
                            />
                            <div className="select-all-deselect-all" title={selectDeselectText}>
                                <input
                                    type="checkbox"
                                    id="select-all-deselect-all"
                                    onChange={() => {
                                        setColumnSearch('');
                                        if (areAllSelected) {
                                            manageSetCheckedColumns([]);
                                            return;
                                        }
                                        manageSetCheckedColumns(
                                            validColumns?.map((prop) => prop.id) ?? [],
                                        );
                                    }}
                                    checked={areAllSelected}
                                    ref={checkboxRef as React.LegacyRef<HTMLInputElement>}
                                />
                                <label htmlFor="select-all-deselect-all">
                                    {selectDeselectText}
                                </label>
                            </div>
                            <div className="suggest-columns">{suggestColumns}</div>
                        </div>
                        {selectedComponent.type === COMPONENT_TYPE['TABLE'] && (
                            <div className="suggest-preview">
                                <Table
                                    columns={
                                        selectedProperties && selectedProperties?.length > 0
                                            ? selectedProperties
                                            : [
                                                  {
                                                      typeElementPropertyId: '1',
                                                      isDisplayValue: true,
                                                      label: 'Preview Column',
                                                      typeElementPropertyDeveloperName: null,
                                                      boundTypeElementPropertyId: null,
                                                      typeElementPropertyToDisplayId: null,
                                                      componentType: null,
                                                      order: 0,
                                                      isBound: false,
                                                      isEditable: true,
                                                  },
                                              ]
                                    }
                                    label="Preview Table"
                                    isSearchable={false}
                                />
                            </div>
                        )}
                    </div>
                );
            }, [type, checkedColumns, columnSearch])}
            renderFooter={() => (
                <>
                    <ButtonDefault title="Back" onClick={goToOptionsScreen}>
                        Back
                    </ButtonDefault>
                    <ButtonPrimary
                        onClick={createMultiColumnsComponent}
                        title={`Create ${selectedComponent.ui.caption} component`}
                        disabled={validColumns.length === 0}
                    >
                        Create {selectedComponent.ui.caption} component
                    </ButtonPrimary>
                </>
            )}
        />
    );
};

export default ComponentSuggestColumns;

import GenericModal from '../../../../generic/modal/GenericModal';
import ButtonDefault from '../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import { useRef, useState } from 'react';
import { CONTAINER_TYPE, PAGE_ELEMENT_TYPES } from '../../../constants';
import type {
    ValueElementIdReferenceAPI,
    ComponentRegistry,
    ComposerElement,
    TypeElementResponseAPI,
} from '../../../../../types';
import Select, { type SingleValue } from 'react-select';
import { getNumberProperties, getValidProperties, invalidContentTypesString } from './constants';
import Loader from '../../../../loader/Loader';
import translations from '../../../../../translations';
import { stringReplace } from '../../../../../utils/string';
import { guid } from '../../../../../utils/guid';
import FormGroup from '../../../../generic/FormGroup';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import { usePageEditor } from '../../PageEditorProvider';
import { useComposer } from '../composer/ComposerProvider';
import { getValueName } from '../../../../values/selector/value-selector-utils';
import { getSharedStyles } from '../../../../../utils/select';

interface SelectOption {
    label: string;
    value: string;
}

const ComponentSuggestCharts = ({
    selectedComponent,
    goToOptionsScreen,
    type,
    mainContainer,
    order,
}: {
    selectedComponent: ComponentRegistry;
    goToOptionsScreen: () => void;
    type: TypeElementResponseAPI | null;
    mainContainer: string;
    order: number;
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const { state, container, setStopComponentSuggest } = usePageEditor();
    const { onPageElementDrop } = useComposer();
    const componentSuggestValue = state.componentSuggestValue as ValueElementIdReferenceAPI;

    const validProperties = getValidProperties(type);
    const numberProperties = getNumberProperties(type);
    const wereAnyColumnsObjectList = (type?.properties?.length as number) > validProperties.length;

    const [selectedLabelProperty, setSelectedLabelProperty] = useState<SingleValue<SelectOption>>({
        value: validProperties?.[0]?.id ?? '',
        label: validProperties?.[0]?.developerName ?? '',
    });
    const [selectedValueProperty, setSelectedValueProperty] = useState<SingleValue<SelectOption>>({
        value: numberProperties?.[0]?.id ?? '',
        label: numberProperties?.[0]?.developerName ?? '',
    });

    const createChartsComponent = () => {
        if (!(selectedLabelProperty && selectedValueProperty)) {
            return;
        }

        let partialElement = {} as Partial<ComposerElement>;

        partialElement = {
            developerName: `"${getValueName(componentSuggestValue)}" Dataset`,
            valueElementDataBindingReferenceId: {
                id: componentSuggestValue.id,
                typeElementPropertyId: componentSuggestValue.typeElementPropertyId as string,
                command: null,
                relativeUnit: null,
            },
            columns: [
                {
                    isDisplayValue: true,
                    order: 0,
                    typeElementPropertyDeveloperName: selectedLabelProperty?.label,
                    typeElementPropertyId: selectedLabelProperty?.value,
                    boundTypeElementPropertyId: null,
                    isBound: false,
                    isEditable: false,
                    componentType: null,
                    typeElementPropertyToDisplayId: null,
                },
                {
                    isDisplayValue: true,
                    order: 1,
                    typeElementPropertyDeveloperName: selectedValueProperty?.label,
                    typeElementPropertyId: selectedValueProperty?.value,
                    boundTypeElementPropertyId: null,
                    isBound: false,
                    isEditable: false,
                    componentType: null,
                    typeElementPropertyToDisplayId: null,
                },
            ],
        };

        const newContainerGuid = guid();

        // For charts, we need to make a page container
        onPageElementDrop({
            id: newContainerGuid,
            targetId: mainContainer,
            order,
            pageElementType: PAGE_ELEMENT_TYPES['container'],
            containerType: CONTAINER_TYPE['chart'],
            edit: true,
            select: true,
            partialElement: {
                developerName: `"${getValueName(componentSuggestValue)}"`,
            },
        });
        // then a page component in that container
        onPageElementDrop({
            id: null,
            targetId: newContainerGuid,
            order: 0,
            pageElementType: PAGE_ELEMENT_TYPES['component'],
            componentType: selectedComponent.type.toLowerCase(),
            edit: false,
            select: false,
            partialElement,
        });
        setStopComponentSuggest();
    };

    return (
        <GenericModal
            container={container}
            ref={ref}
            show
            className="config-modal component-suggestion-modal"
            onHide={setStopComponentSuggest}
            title={translations.COMPONENT_SUGGEST_multiple_columns_view_title}
            animation={false}
            renderBody={() => {
                if (type === null) {
                    return <Loader />;
                }

                if (validProperties.length === 0) {
                    return (
                        <>
                            {stringReplace(
                                translations.COMPONENT_SUGGEST_list_has_no_valid_properties as string,
                                invalidContentTypesString,
                            )}
                        </>
                    );
                }

                if (numberProperties.length === 0) {
                    return <>{translations.COMPONENT_SUGGEST_list_has_no_number_properties}</>;
                }

                return (
                    <div className="suggest-charts">
                        {wereAnyColumnsObjectList && (
                            <ExAlertBanner
                                className="margin-bottom"
                                type={AlertBannerType.INFO}
                                open
                            >
                                {invalidContentTypesString} properties have been removed
                            </ExAlertBanner>
                        )}
                        <FormGroup label="Label column" htmlFor="label-column" isRequired>
                            <Select
                                styles={getSharedStyles<{ label: string; value: string }>()}
                                inputId="label-column"
                                options={validProperties.map(({ id, developerName }) => ({
                                    label: developerName,
                                    value: id,
                                }))}
                                onChange={(option: SingleValue<SelectOption>) =>
                                    setSelectedLabelProperty(option)
                                }
                                placeholder="Select a property to render"
                                noOptionsMessage={() => 'No results found'}
                                required={true}
                                value={selectedLabelProperty}
                                menuPosition="fixed"
                                menuPortalTarget={ref?.current}
                            />
                        </FormGroup>
                        <FormGroup label="Value column" htmlFor="value-column" isRequired>
                            <Select
                                styles={getSharedStyles<{ label: string; value: string }>()}
                                inputId="value-column"
                                options={numberProperties.map(({ id, developerName }) => ({
                                    label: developerName,
                                    value: id,
                                }))}
                                onChange={(option) => setSelectedValueProperty(option)}
                                placeholder="Select a property to render"
                                noOptionsMessage={() => 'No results found'}
                                required={true}
                                value={selectedValueProperty}
                                menuPosition="fixed"
                                menuPortalTarget={ref?.current}
                            />
                            <span className="help-block">
                                Only Number properties are valid for the value column.
                            </span>
                        </FormGroup>
                    </div>
                );
            }}
            renderFooter={() => (
                <>
                    <ButtonDefault title="Back" onClick={goToOptionsScreen}>
                        Back
                    </ButtonDefault>
                    <ButtonPrimary
                        onClick={createChartsComponent}
                        title={`Create ${selectedComponent.ui.caption} component`}
                        disabled={validProperties.length === 0 || numberProperties.length === 0}
                    >
                        Create {selectedComponent.ui.caption} component
                    </ButtonPrimary>
                </>
            )}
        />
    );
};

export default ComponentSuggestCharts;

const DecisionIcon = ({ className = '', size = '1em', ...otherProps }) => (
    // biome-ignore lint/a11y/noSvgWithoutTitle: Including a title breaks tests, requires dedicated refactor
    <svg
        className={className}
        width={size}
        height={size}
        {...otherProps}
        viewBox="0 -2 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.9375 6.90625C8.9375 5.78442 8.02808 4.875 6.90625 4.875C5.78442 4.875 4.875 5.78442 4.875 6.90625C4.875 8.02808 5.78442 8.9375 6.90625 8.9375C8.02808 8.9375 8.9375 8.02808 8.9375 6.90625ZM6.90625 3.25C8.92554 3.25 10.5625 4.88696 10.5625 6.90625C10.5625 8.92554 8.92554 10.5625 6.90625 10.5625C4.88696 10.5625 3.25 8.92554 3.25 6.90625C3.25 4.88696 4.88696 3.25 6.90625 3.25Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 18.2941C20 18.684 19.5075 19 18.9 19L17.8 19C16.633 19 15.5139 18.7025 14.6888 18.173C13.8637 17.6435 13.6246 16.9253 13.6246 16.1765L13.6246 13L13.6246 9.82347C13.6246 9.44906 13.4126 9.26475 13 9C12.5874 8.73525 11.7835 8.41178 11.2 8.41176L10.1 8.41176C9.49249 8.41176 9 8.09573 9 7.70588C9 7.31603 9.49249 7 10.1 7L11.2 7C12.3669 7.00001 13.4861 7.29749 14.3112 7.827C15.1363 8.3565 15.4998 9.07465 15.4998 9.82347L15.4998 13L15.5 16.1765C15.5 16.5509 15.8319 16.91 16.2444 17.1747C16.657 17.4395 17.2165 17.5882 17.8 17.5882L18.9 17.5882C19.5075 17.5882 20 17.9043 20 18.2941Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1863 15.9855C18.4337 15.7371 18.8356 15.7362 19.084 15.9836L20.9924 17.8838C21.2408 18.1311 21.2417 18.5331 20.9943 18.7815L19.0941 20.6899C18.8468 20.9383 18.4449 20.9392 18.1964 20.6918C17.948 20.4444 17.9472 20.0425 18.1945 19.7941L19.6468 18.3355L18.1883 16.8832C17.9398 16.6359 17.939 16.2339 18.1863 15.9855Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1863 4.19235C18.4337 3.94393 18.8356 3.94306 19.084 4.19042L20.9924 6.09062C21.2408 6.33797 21.2417 6.73988 20.9943 6.98831L19.0941 8.8967C18.8468 9.14512 18.4449 9.14599 18.1964 8.89863C17.948 8.65127 17.9472 8.24936 18.1945 8.00094L19.6468 6.54236L18.1883 5.09005C17.9398 4.84269 17.939 4.44078 18.1863 4.19235Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 6.45C20 6.86421 19.5075 7.2 18.9 7.2L10.1 7.2C9.49249 7.2 9 6.86421 9 6.45C9 6.03578 9.49249 5.7 10.1 5.7L18.9 5.7C19.5075 5.7 20 6.03578 20 6.45Z"
            fill="currentColor"
        />
    </svg>
);

export default DecisionIcon;
